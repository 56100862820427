<template>
  <div>
    <SidebarContainer>
      <template #default>
        <div
          class="app-setting-placeholder h-100 d-flex flex-column justify-content-center bg-white align-items-center"
        >
          <h3 class="page-name text-primary-danger">SELECTED TEMPLATES</h3>

          <div class="mt-3">
            <p>
              Items Selected:
              <span class="font-weight-bold text-primary-danger">{{ items.length }}</span>
            </p>
          </div>
        </div>
      </template>
      <template #buttons>
        <Button theme="secondary" icon="arrow_right_alt" @click.native="deselectAll">{{
          items.length <= 0 ? 'Cancel' : 'Deselect All'
        }}</Button>
        <Button
          theme="secondary"
          icon="arrow_right_alt"
          @click="openChangeTemplateFolderModal"
          :disabled="items.length <= 0"
          >Move Templates</Button
        >
        <Button
          theme="secondary"
          icon="delete_outline"
          @click.native="deleteAllFiles()"
          :disabled="items.length <= 0"
          >Delete Templates</Button
        >
      </template>
    </SidebarContainer>
    <MultipleTemplateDeleteModal
      v-if="showDeleteModal"
      :templates="items"
      @onClose="showDeleteModal = false"
      @onDelete="handleDeleted"
    />
    <ChangeTemplateFolderModal
      v-if="showChangeTemplateFolderModal"
      :templates="items"
      @closeModal="showChangeTemplateFolderModal = false"
      @onFolderUpdate="$emit('onFolderUpdate')"
    />
  </div>
</template>

<script>
  import MultipleTemplateDeleteModal from '@/components/templates/MultipleTemplateDeleteModal.vue';
  import SidebarContainer from '@/components/common/SidebarContainer.vue';
  import Button from '@/components/common/Button.vue';
  import ChangeTemplateFolderModal from '@/components/templates/ChangeTemplateFolderModal.vue';

  export default {
    name: 'MultiSelectSettings',

    components: {
      SidebarContainer,
      Button,
      MultipleTemplateDeleteModal,
      ChangeTemplateFolderModal,
    },

    props: {
      items: {
        type: Array,
        default: () => [],
      },
    },

    data() {
      return {
        showDeleteModal: false,
        showChangeTemplateFolderModal: false,
      };
    },

    methods: {
      deleteAllFiles() {
        this.showDeleteModal = true;
      },
      deleteAllFolders() {
        this.showDeleteModal = true;
      },
      deselectAll() {
        this.$emit('onClose');
      },
      handleDeleted() {
        this.showDeleteModal = false;
        this.$emit('onDelete');
      },
      openChangeTemplateFolderModal() {
        this.showChangeTemplateFolderModal = true;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .btn {
    width: 200px;
  }

  .btn-transparent-blue {
    margin-bottom: 1rem;
  }

  p {
    font-size: 0.9375rem;
  }
</style>
