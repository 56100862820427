<template>
  <div class="app-wrapper" id="full-body">
    <LeftSidebar />
    <div class="main-body">
      <slot />
    </div>
  </div>
</template>

<script>
  import NavBar from '@/components/NavBar.vue';
  import LeftSidebar from '@/components/LeftSidebar.vue';

  export default {
    name: 'FullLayout',

    components: {
      NavBar,
      LeftSidebar,
    },
  };
</script>
<style lang="scss" scoped>
  .app-wrapper {
    display: grid;
    grid-template-columns: 256px auto;

    @media screen and (max-width: 800px) {
      grid-template-columns: 114px auto;
    }
  }
</style>
