<template>
  <div class="sidebar-dropdown">
    <div class="sidebar-dropdown-handle" :class="[{ isOpen }]" @click="toggleState">
      <span class="title">{{ title }}</span>
      <span class="icon">
        <i class="material-icons-outlined align-middle button">
          {{ isOpen ? 'expand_less' : 'expand_more' }}
        </i>
      </span>
    </div>
    <div class="sidebar-dropdown-body" v-if="isOpen">
      <slot v-if="!items" />
      <div v-else>
        <div v-for="item in items" class="info-item" :key="item.name">
          <span v-if="item.value"
            >{{ item.name }}: <span class="value">{{ item.value }}</span></span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['title', 'defaultOpen', 'items'],
    data() {
      return {
        isOpen: this.defaultOpen || false,
      };
    },

    methods: {
      toggleState() {
        this.isOpen = !this.isOpen;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .sidebar-dropdown {
    width: 100%;
    display: flex;
    flex-direction: column;

    .sidebar-dropdown-handle {
      cursor: pointer;
      user-select: none;
      display: flex;
      justify-content: space-between;
      &.isOpen {
        .icon {
          color: #e3003a;
        }
      }
      .title {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;

        color: #333332;
      }

      .icon {
        color: #333332;
      }
    }

    .sidebar-dropdown-body {
      padding: 12px 0;
      padding-bottom: 16px;
      border-bottom: 2px solid #f0f0f0;
      .info-item {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #6a6b6a;
        .value {
          color: #333332;
        }
      }
    }
  }
</style>
