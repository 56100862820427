<template>
  <div>
    <SidebarContainer>
      <template #default>
        <div class="setting-content-item">
          <div class="content-preview">
            <div
              class="preview-body scrollbar"
              v-if="
                item.content_type !== 'folder' &&
                ['video', 'image', 'pdf'].includes(getSimpleType(item.item_type))
              "
            >
              <img :src="item.img" alt="preview" v-if="getSimpleType(item.item_type) === 'image'" />

              <video
                v-else-if="getSimpleType(item.item_type) === 'video'"
                muted
                preload="metadata"
                controls
                style="object-fit: fill"
                class="w-100 h-100"
                id="right-sidebar-preview-video"
              >
                <source :src="`${getEncodedURL(baseUrl, item.item_url)}`" :type="item.item_type" />
              </video>

              <PdfView
                v-else-if="item.item_type.includes('pdf')"
                :pdfImages="item.pdf_content.thumbnail_url"
              />
            </div>

            <div class="preview-body scrollbar" v-else-if="item?.item_type?.startsWith('app')">
              <RssViewer
                v-if="item.item_type === 'app/rss'"
                class="w-100 h-100"
                :setting="rssAppSetting"
                isPreview
              />
              <TableAppWebView
                v-else-if="item.item_type === 'app/table'"
                class="w-100 h-100"
                :settings="item"
              />
              <TimeTableViewer
                v-else-if="item.item_type === 'app/trafiklab'"
                :viewerStyles="item.viewerStyles"
                :timetable="item.timetable"
                :name="item.name"
                :app-id="item.app_id"
              />
              <WeatherView v-else-if="item.item_type === 'app/weather'" :app="item" isPreview />
            </div>
            <div class="preview-footer">
              <div class="type">
                <i class="material-icons-outlined align-middle"> crop_original </i>
                <span v-if="item.item_type === CONTENT_TYPE_TABLE_APP" class="name">{{
                  CONTENT_TYPE_TENANT
                }}</span>
                <span v-else class="name">{{
                  item.item_type ? getSimpleType(item.item_type) : getSimpleType(item.type)
                }}</span>
              </div>
              <!-- <div class="expand">
                <i class="material-icons-outlined align-middle button"> fullscreen </i>
              </div> -->
            </div>
          </div>

          <div class="content-body">
            <Input label="File Name" :value="item.name" id="name">
              <span style="font-size: 14px">{{ item.name }}</span>
            </Input>
            <!-- TODO: add 'created,resolution,path'  -->
            <SidebarDropdown
              title="Info"
              :default-open="true"
              :items="[
                {
                  name: 'Size',
                  value: item.size ? humanFileSize(item.size) : false,
                },
                {
                  name: 'Last Modified',
                  value: item.last_modified ? formattedDate(item.last_modified) : false,
                },
                {
                  name: 'Path',
                  value: item.path ? item.path : false,
                },
              ]"
            />
          </div>
        </div>
      </template>

      <template #buttons>
        <Button theme="secondary" icon="arrow_right_alt" @click.native="showDirectoryModal = true">
          Move File
        </Button>
        <Button theme="secondary" icon="delete_outline" @click.native="handleDelete">
          Delete File
        </Button>
      </template>
    </SidebarContainer>

    <ContentDeleteModal
      :content="item"
      v-if="showDeleteModal"
      @onDelete="$emit('onDelete')"
      @closeModal="showDeleteModal = false"
    />

    <DirectoryChangeModal
      v-if="showDirectoryModal"
      :content="item"
      @closeModal="showDirectoryModal = false"
      @onUpdate="$emit('onUpdate')"
    />
  </div>
</template>

<script>
  import Input from '@/components/common/Input.vue';
  import SidebarContainer from '@/components/common/SidebarContainer.vue';
  import SidebarDropdown from '@/components/common/SidebarDropdown.vue';
  import Button from '@/components/common/Button.vue';
  import TimeTableViewer from '@/components/common/timetable/TimeTableViewer.vue';
  import PdfView from '@/components/content/PdfView.vue';
  import RssViewer from '@/components/rssViewer/Index.vue';
  import TableAppWebView from '@/components/tableApp/TableAppWebView.vue';
  import WeatherView from '@/components/apps/weather/WeatherView.vue';
  import ContentDeleteModal from '@/components/content/ContentDeleteModal.vue';
  import DirectoryChangeModal from '@/components/content/DirectoryChangeModal.vue';

  import { humanFileSize } from '@/helpers/filters';
  import { simpleTypeMixin } from '@/helpers';
  import { formattedDate, getRSSAppSettings } from '@/helpers/utils';
  import { CONTENT_TYPE_TABLE_APP, CONTENT_TYPE_TENANT } from '@/constant/const';

  export default {
    props: ['item'],

    mixins: [simpleTypeMixin],

    components: {
      DirectoryChangeModal,
      ContentDeleteModal,
      SidebarContainer,
      Input,
      SidebarDropdown,
      Button,
      TimeTableViewer,
      PdfView,
      RssViewer,
      TableAppWebView,
      WeatherView,
    },

    data() {
      return {
        showDeleteModal: false,
        showDirectoryModal: false,
        CONTENT_TYPE_TENANT,
        CONTENT_TYPE_TABLE_APP,
      };
    },

    computed: {
      rssAppSetting() {
        return getRSSAppSettings(this.item);
      },
    },

    methods: {
      formattedDate,
      humanFileSize,
      handleDelete() {
        this.showDeleteModal = true;
      },
    },
  };
</script>

<style lang="scss">
  .setting-content-item {
    .content-preview {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-bottom: 20px;

      .preview-body {
        display: flex;
        flex-direction: column;
        height: 300px;
        min-height: 190px;
        overflow: auto;
      }

      img {
        width: 100%;
      }

      .preview-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .type {
          display: flex;
          gap: 4px;

          i {
            font-size: 20px;
            line-height: 21px;
            color: #6a6b6a;
          }

          .name {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: #333332;
            text-transform: capitalize;
          }
        }

        .expand {
          i {
            font-size: 20px;
            line-height: 21px;
            color: #6a6b6a;
          }
        }
      }
    }

    .content-body {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
  }
</style>
