<template>
  <div class="input-group color-picker" :class="{ disabled }">
    <div class="input-group-prepend">
      <span class="" :style="{ backgroundColor: value }"></span>
    </div>

    <input
      readonly
      :disabled="disabled"
      type="text"
      class="form-control color-text"
      :class="{ disabled }"
      :id="inputElId"
      :value="value"
      @click.prevent="toggleColorPicker"
    />

    <sketch
      v-if="showColorPicker"
      class="picker"
      :value="value"
      @input="updateColor"
      v-click-outside="closeColorPicker"
    />
  </div>
</template>

<script>
  import { Sketch } from 'vue-color';
  import ClickOutside from 'vue-click-outside';

  export default {
    name: 'ColorPicker',

    components: {
      Sketch,
    },

    props: {
      disabled: {
        type: Boolean,
        default: false,
      },
      value: String,
      inputElId: {
        required: false,
        defaultValue: Date.now() + '',
        type: String,
      },
    },

    data() {
      return {
        showColorPicker: false,
      };
    },

    methods: {
      toggleColorPicker() {
        if (this.disabled) return;

        this.showColorPicker = !this.showColorPicker;
      },
      updateColor({ hex }) {
        this.$emit('input', hex);
      },
      closeColorPicker() {
        this.showColorPicker = false;
      },
    },

    mounted() {
      // prevent click outside event with popupItem.
      this.popupItem = this.$el;
    },

    directives: {
      ClickOutside,
    },
  };
</script>

<style lang="scss" scoped>
  .color-picker {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    top: 0;
    left: 0;
    padding: 1px 8px;
    gap: 8px;
    cursor: pointer;

    :hover {
      text-decoration: underline;
    }

    &.disabled,
    &.disabled :hover {
      cursor: auto;
      text-decoration: none;
    }

    .input-group-prepend span {
      width: 24px;
      height: 24px;
      border-radius: 4px;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
    }

    .form-control[readonly] {
      background-color: transparent;
      border: none;

      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $primaryText;
      padding: 0;

      &:focus {
        box-shadow: none;
      }
    }

    input.color-text {
      cursor: pointer;

      &.disabled {
        cursor: auto;
      }
    }

    .picker {
      position: absolute;
      top: 40px;
      z-index: 20;
    }
  }
</style>
