<template>
  <Modal
    @closeModal="closeModal"
    v-show="showModal"
    :isSaving="isLoading"
    title="Layout Template Confirmation"
    :okFunction="createLayoutTemplate"
  >
    <template v-slot:body>
      <div class="modal-description">
        Are you sure you want to create a new layout template from this layout?
      </div>
    </template>
  </Modal>
</template>

<script>
  import Modal from '@/components/common/Modal.vue';
  import { apiCreateLayoutTemplate } from '@/api/layouts';

  export default {
    name: 'CreateTemplateLayoutModal',

    components: { Modal },

    props: {
      layoutId: {
        type: String,
        required: true,
      },
    },

    data() {
      return {
        showModal: false,
        isLoading: false,
      };
    },

    mounted() {
      this.showModal = true;
    },

    methods: {
      async createLayoutTemplate() {
        this.isLoading = true;

        try {
          const successful = await apiCreateLayoutTemplate(this.layoutId);

          if (successful) {
            this.$toasted.global.general_success({
              message: 'Layout Template created',
            });

            this.closeModal(true);
          }
        } catch (error) {
          this.$toasted.global.general_error({
            message: String(error),
          });
        }

        this.isLoading = false;
      },

      closeModal() {
        this.showModal = false;
        this.$emit('closeModal');
      },
    },
  };
</script>
