<template>
  <div
    v-if="!!selectedWidget"
    v-show="!isWidgetMoving && show"
    class="designer-toolbar"
    :style="style"
  >
    <div class="toolbar-group">
      <div class="styled-button" title="Move Up" @click="!isMoveUpDisabled && changeZIndex(1)">
        <i class="material-icons" :class="{ disabled: isMoveUpDisabled }"> keyboard_arrow_up </i>
      </div>
      <div class="styled-button" title="Move Down" @click="!isMoveDownDisabled && changeZIndex(-1)">
        <i class="material-icons" :class="{ disabled: isMoveDownDisabled }">
          keyboard_arrow_down
        </i>
      </div>
      <div class="styled-button" @click="deleteWidget" title="Delete">
        <i class="material-icons-outlined"> delete </i>
      </div>
      <div
        class="styled-button"
        @click="updateLock"
        :title="isWidgetLocked ? 'Unlock position' : 'Lock position'"
      >
        <i class="material-icons-outlined" :class="isWidgetLocked ? 'lock' : 'unlock'">
          {{ isWidgetLocked ? 'lock_open' : 'lock' }}
        </i>
      </div>
      <div
        v-show="isDuplicateSupported"
        class="styled-button"
        title="Duplicate"
        @click="() => duplicateWidget()"
      >
        <i class="material-icons-outlined">content_copy</i>
      </div>
    </div>
  </div>
</template>

<script>
  import { REFRESH_LAYOUT_STATUS } from '@/config/constants';
  import {
    LAYOUT_DESIGNER_DUPLICATE_WIDGET,
    LAYOUT_DESIGNER_SET_ZINDEX,
    LAYOUT_DESIGNER_UPDATE_WIDGET,
  } from '@/store/actions/layoutDesigner';
  import { WIDGET_TYPES } from '@/models/layoutDesigner';
  import { duplicateLayoutTextWidget } from '@/helpers/widgets/textWidget/textWidget';

  export default {
    name: 'DesignerToolbar',

    inject: ['deleteWidget'],

    props: {
      selectedWidget: {
        type: Object,
        default: () => null,
      },
      show: { type: Boolean, default: true },
    },

    computed: {
      widgetType() {
        if (
          this.selectedWidget.itemType === WIDGET_TYPES.TEXT ||
          this.selectedWidget.object?.type === WIDGET_TYPES.TEXT
        ) {
          return WIDGET_TYPES.TEXT;
        }

        return null;
      },

      isDuplicateSupported() {
        return this.widgetType === WIDGET_TYPES.TEXT;
      },

      widgetsByZIndex() {
        return this.$store.getters.getWidgetsByZIndex;
      },

      widgetCurrentIndex() {
        return this.widgetsByZIndex.findIndex(
          (widget) => this.selectedWidget.assoc_id === widget.assoc_id,
        );
      },

      isMoveUpDisabled() {
        return this.widgetCurrentIndex === this.widgetsByZIndex.length - 1;
      },

      isMoveDownDisabled() {
        return this.widgetCurrentIndex === 0;
      },

      widgetPosition() {
        return this.selectedWidget?.position[this.rotation] || { x: 0, y: 0 };
      },

      rotation() {
        return this.$store.getters.getLayoutRotation;
      },

      isWidgetMoving() {
        return (
          this.$store.state.layoutDesigner.refreshingLayoutStatus !== REFRESH_LAYOUT_STATUS.STAND_BY
        );
      },

      isWidgetLocked() {
        return !!this.selectedWidget.position[this.rotation].isLocked;
      },

      style() {
        return {
          left: `calc(${`${this.widgetPosition.x}% + ${this.widgetPosition.width / 2}%`})`,
          top: `calc(${`${this.widgetPosition.y}% - 48px`})`,
        };
      },
    },

    methods: {
      duplicateWidget() {
        if (this.widgetType === WIDGET_TYPES.TEXT) {
          const newWidget = duplicateLayoutTextWidget(this.selectedWidget);

          this.$store.commit(LAYOUT_DESIGNER_DUPLICATE_WIDGET, newWidget);
        }
      },

      async changeZIndex(value) {
        const widgetToMove = this.widgetsByZIndex[this.widgetCurrentIndex + value];

        let oldIndex = this.selectedWidget.position[this.rotation].zIndex;
        let newIndex = widgetToMove.position[this.rotation].zIndex;

        if (newIndex === oldIndex && newIndex > 0) {
          newIndex = newIndex + value;
        }

        if (newIndex === oldIndex && oldIndex <= 0) {
          oldIndex = value > 0 ? 0 : 1;
          newIndex = value > 0 ? 1 : 0;
        }

        const widgetToMoveUpdated = {
          ...widgetToMove,
          position: {
            ...widgetToMove.position,
            [this.rotation]: {
              ...widgetToMove.position[this.rotation],
              zIndex: oldIndex,
            },
          },
        };

        const currentWidget = {
          ...this.selectedWidget,
          position: {
            ...this.selectedWidget.position,
            [this.rotation]: {
              ...this.selectedWidget.position[this.rotation],
              zIndex: newIndex,
            },
          },
        };

        this.$store.commit(LAYOUT_DESIGNER_SET_ZINDEX, [widgetToMoveUpdated, currentWidget]);
      },

      updateLock() {
        const updatedWidget = {
          ...this.selectedWidget,
          position: {
            ...this.selectedWidget.position,
            [this.rotation]: {
              ...this.selectedWidget.position[this.rotation],
              isLocked: !this.isWidgetLocked,
            },
          },
        };

        this.$store.commit(LAYOUT_DESIGNER_UPDATE_WIDGET, { widget: updatedWidget });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .styled-button {
    display: flex;
    padding: 0;
    width: 32px;
    height: 32px;
    max-width: 32px;
    max-height: 32px;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 1px solid transparent;
    background-color: white;
    transition: all 0.2s ease-in-out;

    &:hover {
      background: #f1f1f1;
    }

    &:active {
      background-color: $borderGrey;
    }
  }

  .designer-toolbar {
    z-index: 1200;
    background-color: #fff;
    color: $iconsBlack;
    position: absolute;
    padding: 4px 8px;
    height: 40px;
    border-radius: 4px;
    transform: translateX(-50%);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  }

  .toolbar-group {
    display: flex;
    gap: 4px;
  }
</style>
