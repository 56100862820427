<template>
  <div class="default-card card rounded-0 border-0">
    <!--    <div class="hovereffect">-->
    <!--      <img :src="item.img" class="card-img-top"-->
    <!--           :alt="item.name" :width="THUMBNAIL_WIDTH" :height="THUMBNAIL_HEIGHT">-->

    <!--      <div class="overlay"></div>-->
    <!--    </div>-->

    <div class="card-body cursor-pointer">
      <div class="d-flex">
        <img :src="item.img" class="card-img-top mr-3"
             :alt="item.name" :width="THUMBNAIL_WIDTH" :height="THUMBNAIL_HEIGHT">
        <div>
          <h5 class="card-title mb-2">{{ item.name }}</h5>
          <p class="card-text mb-0">{{ item.desc }}</p>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {THUMBNAIL_WIDTH, THUMBNAIL_HEIGHT} from "../../config/constants";

export default {
  name: 'AppCard',
  props: {
    item: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      THUMBNAIL_WIDTH,
      THUMBNAIL_HEIGHT
    };
  },

  computed: {
    desc() {
      return this.item.desc || this.itemDesc;
    }
  }
};
</script>

<style lang="scss" scoped>
.default-card {
  background-color: #ffffff;

  .card-img-top {
    height: 103px;
    width: 103px;
  }
  .card-text {
    font-size: 14px;
    font-family: Poppins;
    color: #6A6B6A;
    line-height: 21px;
  }
}
</style>
