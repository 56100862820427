import axios from 'axios';
import config from '@/config';
import { ScreenGroupLayout } from '@/types/api/screenGroupLayout';

export async function apiGetScreenGroupLayout(
  screenGroupId: number,
  layoutId: string,
): Promise<ScreenGroupLayout> {
  const response = await axios.get(
    `${config.apiUrl}userapi/screen-group-layout/${screenGroupId}/${layoutId}`,
  );
  return response.data;
}
