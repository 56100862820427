<template>
  <div class="layer-widget-panel scrollbar">
    <div class="form-group template-input name">
      <label for="template-name">Name</label>
      <input
        class="form-control"
        type="text"
        id="template-name"
        disabled
        placeholder="Name"
        v-model="value.object.name"
      />
    </div>

    <div class="measurements-container">
      <div class="measurements">
        <div class="form-group hidden position">
          <div class="form-row">
            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">X</span>
              </div>
              <input
                type="number"
                class="form-control"
                id="layer-x"
                v-model="xPosition"
                :disabled="isWidgetLocked"
              />
            </div>

            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">Y</span>
              </div>
              <input
                type="number"
                class="form-control"
                v-model="yPosition"
                :disabled="isWidgetLocked"
              />
            </div>
          </div>
        </div>

        <div class="form-group hidden size">
          <div class="form-row">
            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">W</span>
              </div>
              <input
                type="number"
                class="form-control"
                id="layer-width"
                v-model="width"
                :disabled="!value.position[rotation].allowResizing || isWidgetLocked"
              />
            </div>

            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">H</span>
              </div>
              <input
                type="number"
                class="form-control"
                v-model="height"
                :disabled="!value.position[rotation].allowResizing || isWidgetLocked"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="extra-icons">
        <LockIcon :locked="isWidgetLocked" />

        <PixelIcon :usePixels="usePixels" @clicked="changeValuesMode" />
      </div>
    </div>

    <div class="form-group">
      <div class="custom-control custom-switch check">
        <input
          type="checkbox"
          class="custom-control-input"
          id="resizing-checkbox"
          :disabled="isTemplateTallerThanLayout || isTemplateWiderThanLayout"
          v-model="value.position[rotation].allowResizing"
          @input="onAllowResizing"
        />
        <label class="custom-control-label" for="resizing-checkbox">Allow resizing</label>
      </div>
    </div>

    <button type="button" class="button-2-secondary" @click="openTemplateBrowser">
      <i class="material-icons-outlined align-middle">photo_size_select_large</i>
      Replace Template
    </button>

    <button
      type="button"
      class="button-2-secondary"
      @click="
        $router.push({
          name: 'Template',
          params: { templateId: value.object.template_id },
          query: { layout: layout.layout_id },
        })
      "
    >
      <i class="material-icons-outlined align-middle">edit</i>
      Edit Template
    </button>

    <TemplateFolderBrowserModal
      ref="template_folder_model"
      @attachTemplate="replaceTemplate"
      :usedTemplatesIds="usedTemplatesIds"
    />
  </div>
</template>

<script>
  import PixelIcon from '@/components/layoutDesigner/editor/settingPanel/PixelIcon.vue';
  import LockIcon from '@/components/layoutDesigner/editor/settingPanel/LockIcon.vue';
  import TemplateFolderBrowserModal from '@/components/layoutDesigner/editor/TemplateFolderBrowserModal.vue';

  import widgetMixin from '@/components/layoutDesigner/editor/settingPanel/widgetMixin';
  import {
    LAYOUT_DESIGNER_UPDATE_WIDGET,
    LAYOUT_REPLACE_CHILD_ITEM,
  } from '@/store/actions/layoutDesigner';
  import { WIDGET_TYPES } from '@/models/layoutDesigner';
  import { getUsedTemplateIds } from '@/helpers/mixins';

  export default {
    name: 'TemplateWidgetPanel',

    mixins: [widgetMixin],

    components: {
      PixelIcon,
      LockIcon,
      TemplateFolderBrowserModal,
    },

    data() {
      return {
        showTemplateBrowser: false,
      };
    },

    watch: {
      isTemplateTallerThanLayout(value) {
        if (value) {
          this.resizeTemplate(true, true);
        }
      },

      isTemplateTallerThanLayout(value) {
        if (value) {
          this.resizeTemplate(true, true);
        }
      },
    },

    computed: {
      layoutResolution() {
        return this.$store.getters.getResolution;
      },

      layout() {
        return this.$store.state.layoutDesigner.layout;
      },

      isTemplateTallerThanLayout() {
        const { width: canvasWidth, height: canvasHeight } = this.$store.getters.getResolution;
        const { height } = this.value.object.settings;
        const layoutHeight = this.rotation === 'horizontal' ? canvasHeight : canvasWidth;

        return height > layoutHeight;
      },

      isTemplateWiderThanLayout() {
        const { width: canvasWidth, height: canvasHeight } = this.$store.getters.getResolution;
        const { width } = this.value.object.settings;
        const layoutWidth = this.rotation === 'horizontal' ? canvasWidth : canvasHeight;

        return width > layoutWidth;
      },

      usedTemplatesIds() {
        const layoutWidgets = this.$store.state.layoutDesigner.widgets;
        const usedTemplates = getUsedTemplateIds(layoutWidgets);

        return usedTemplates;
      },
    },

    methods: {
      onAllowResizing(checkboxEvent) {
        const activateResizing = checkboxEvent.target.checked;

        this.resizeTemplate(activateResizing);
      },

      async resizeTemplate(activateResizing, forceResize = false) {
        let newPositionData = {
          allowResizing: activateResizing,
        };

        if (!activateResizing || forceResize) {
          const { width: canvasWidth, height: canvasHeight } = this.$store.getters.getResolution;
          const { width, height } = this.value.object.settings;

          const layoutWidth = this.rotation === 'horizontal' ? canvasWidth : canvasHeight;
          const layoutHeight = this.rotation === 'horizontal' ? canvasHeight : canvasWidth;

          newPositionData = {
            ...newPositionData,
            widthPixels: this.isTemplateWiderThanLayout ? layoutWidth : width,
            heightPixels: this.isTemplateTallerThanLayout ? layoutHeight : height,
            width: this.isTemplateTallerThanLayout ? 100 : (width / layoutWidth) * 100,
            height: this.isTemplateTallerThanLayout ? 100 : (height / layoutHeight) * 100,
          };
        }

        const position = {
          ...this.value.position,
          [this.rotation]: {
            ...this.value.position[this.rotation],
            ...newPositionData,
          },
        };

        this.$store.commit(LAYOUT_DESIGNER_UPDATE_WIDGET, { widget: { ...this.value, position } });
      },

      openTemplateBrowser() {
        this.$refs.template_folder_model.open();
      },

      async replaceTemplate(newTemplate) {
        if (!newTemplate) return;

        const widget = {
          ...this.value,
          object: {
            ...this.value.object,
            ...newTemplate,
            type: WIDGET_TYPES.TEMPLATE,
            item_id: newTemplate.template_id,
            item_type: newTemplate.type,
          },
          position: this.calculatePositionAttribute(newTemplate, this.value.position),
        };

        await this.$store.dispatch(LAYOUT_REPLACE_CHILD_ITEM, {
          widget,
          newItemId: newTemplate.template_id,
        });
      },

      calculatePositionAttribute(template, position) {
        const { width: layoutWidth, height: layoutHeight } = this.$store.getters.getResolution;
        const { width: templateWidth, height: templateHeight } = template.settings;

        const widgetOverflowsHorizontalLayout =
          templateWidth > layoutWidth || templateHeight > layoutHeight;
        const widgetOverflowsVerticalLayout =
          templateWidth > layoutHeight || templateHeight > layoutWidth;

        const defaultPosition = {
          x: 0,
          y: 0,
          xPixels: 0,
          yPixels: 0,
          usePixels: true,
        };

        const horizontalWidth = templateWidth > layoutWidth ? layoutWidth : templateWidth;
        const horizontalHeight = templateHeight > layoutHeight ? layoutHeight : templateHeight;
        const verticalWidth = templateWidth > layoutHeight ? layoutHeight : templateWidth;
        const verticalHeight = templateHeight > layoutWidth ? layoutWidth : templateHeight;

        return {
          horizontal: {
            ...position['horizontal'],
            ...defaultPosition,
            allowResizing: widgetOverflowsHorizontalLayout,
            widthPixels: horizontalWidth,
            heightPixels: horizontalHeight,
            width: (horizontalWidth * 100) / this.layoutResolution.width,
            height: (horizontalHeight * 100) / this.layoutResolution.height,
            usePixels: true,
          },
          vertical: {
            ...position['vertical'],
            ...defaultPosition,
            allowResizing: widgetOverflowsVerticalLayout,
            widthPixels: verticalWidth,
            heightPixels: verticalHeight,
            width: (verticalWidth * 100) / this.layoutResolution.height,
            height: (verticalHeight * 100) / this.layoutResolution.width,
            usePixels: true,
          },
        };
      },
    },
  };
</script>

<style lang="scss" scoped>
  .layer-widget-panel {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: 0px;
    padding-right: 10px;

    .sub-section-title {
      padding-top: 4px;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      width: 100%;
    }

    .button-2-secondary {
      margin: 24px 0;
      width: 100%;
    }
  }

  .check {
    margin-top: 16px;
  }
</style>
