<template>
    <div class="row">
        <form @submit.prevent="submitInquiry" class="col-md-10 offset-md-1" id="contact-us">
            <fieldset :disabled="sendingMessageStatus === 'sending'">
            <h1 class="header-text">CONTACT US</h1>
            <div class="row page-content d-flex justify-content-center">
                <div class="col col-md-5 col-lg-4">
                    <div class="form-label-group mb-3">
                        <input v-model="email" type="email" required class="form-control form-control-lg"
                               placeholder="Email">
                    </div>
                </div>
            </div>
            <div class="row d-flex justify-content-center">
                <div class="col col-md-5 col-lg-4">
                    <div class="form-label-group mb-3">
                        <input v-model="subject" type="text" required class="form-control form-control-lg"
                               placeholder="Subject">
                    </div>
                </div>
            </div>
            <div class="row d-flex justify-content-center">
                <div class="col col-md-5 col-lg-4">
                    <div class="form-label-group mb-3">
                        <textarea v-model="message" required class="form-control form-control-lg" rows="5" id="message"
                                  placeholder="Message"></textarea>
                    </div>
                </div>
            </div>
            <div class="row d-flex justify-content-center">
                <div class="col col-md-5 col-lg-4">
                    <button type="submit" class="btn btn-primary btn-submit">Submit</button>
                </div>
            </div>
            </fieldset>
            <div class="row contact-info d-flex justify-content-center">
                <div class="col col-12">
                    <hr class="call-us-divider"/>
                </div>
                <div class="col col-md-8">
                    <div class="row d-flex justify-content-around">
                        <div>
                            <img class="call-us-image" src="../assets/img/home/call-us-phone.png"/>
                            <div class="call-us-feature">
                                <h4>Phone</h4>
                                <p class="modal-text">+464 016 11 00</p>
                            </div>
                        </div>
                        <div>
                            <img class="call-us-image" src="../assets/img/home/call-us-clock.png"/>
                            <div class="call-us-feature">
                                <h4>Working hours</h4>
                                <p class="working-hours-text">
                                    Monday to Friday from 9:00 to 17:00<br/>
                                    Saturday from 9:00 to 12:00<br/>
                                    Sunday is not working day
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </form>
    </div>
</template>

<script>
  import {CONTACT_US_REQUEST} from '../store/actions/misc'
  import {mapState} from 'vuex'

  export default {
    name: 'ContactUs',
    computed: {
      ...mapState({
        sendingMessageStatus: state => state.misc.sendingMessageStatus,
      })
    },
    data() {
      return {
        email: '',
        subject: '',
        message: ''
      }
    },
    methods: {
      submitInquiry() {
        const {email, subject, message} = this;
        const updateArgs = {
          email,
          subject,
          message
        };

        this.$store.dispatch(CONTACT_US_REQUEST, updateArgs).then((resp) => {
          this.$toasted.global.general_success({
            message: 'Your inquiry submitted successfully'
          });
          this.resetForm();
        }).catch((error) => {
          this.$toasted.global.general_error({
            message: String(error)
          })
        })
      },
      resetForm(){
        this.email = '';
        this.subject = '';
        this.message = '';
      }
    },


  }
</script>

<style lang="scss" scoped>
    #contact-us {
        padding-top: 5rem;
        text-align: center;

        .page-content {
            padding-top: 4rem;
        }

        .btn-submit {
            width: 100%;
            padding: 0.5rem 0;
        }

        .contact-info {
            padding-top: 15rem;
            padding-bottom: 5rem;
        }

        .call-us-image {
            float: left;
        }

        .call-us-feature {
            float: left;
            text-align: left;
            padding-left: 1rem;
        }

        .call-us-phone {
            padding-top: 1.5rem;
        }

        .working-hours-text {
            font-weight: 600;
        }

        .call-us-divider {
            border: 0;
            clear: both;
            display: block;
            width: 95%;
            background-color: lightgray;
            height: 1px;
            margin-bottom: 2rem;
        }

    }
</style>
