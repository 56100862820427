<template>
  <div class="scheduler-container">
    <div class="description">Pick a name for your new widget.</div>

    <label for="widget-name" class="w-100">
      Give your widget a name
      <input type="text" id="widget-name" placeholder="" v-model="name" class="form-control" />
    </label>
    <!-- <label for="mediaFolders" class="w-100">
      In what folder do you want to save the widget?
      <select id="mediaFolders" v-model="folderSelected" class="form-control">
        <option :value="{ name: 'Main Folder', path: '/' }">Main Folder</option>
        <option v-for="(folder, index) in folders" :key="index" :value="folder">
          {{ folder.name }}
        </option>
      </select>
    </label> -->
  </div>
</template>

<script>
  import Scheduler from '@/components/common/scheduler/Scheduler.vue';
  import { CONTENT_REQUEST } from '@/store/actions/content';
  export default {
    name: 'TableSchedule',
    components: {
      Scheduler,
    },
    props: {
      defaultData: {
        type: Object,
        default: {
          type: Object,
          default: {
            name: '',
            folder: null,
            schedule: {
              available: 'always',
            },
          },
        },
      },
    },
    data() {
      return {
        name: '',
        folderSelected: {
          name: 'Main Folder',
          path: '/',
        },
        schedulerData: {
          available: 'always',
        },
      };
    },

    created() {
      if (this.defaultData) {
        this.name = this.defaultData?.name;
        this.folderSelected = this.defaultData?.folder || {
          name: 'Main Folder',
          path: '/',
        };
        this.schedulerData = this.defaultData?.schedule || {
          available: 'always',
        };
      }

      if (this.$store.getters.getFolders.length === 0) {
        this.getFolders();
      }
    },

    watch: {
      defaultData(val) {
        this.name = val?.name;
        this.folderSelected = val?.folder;
        this.schedulerData = val?.schedule;
      },
      name() {
        this.onChange();
      },
      folderSelected() {
        this.onChange();
      },
      schedulerData: {
        handler() {
          this.onChange();
        },
        deep: true,
      },
    },

    computed: {
      folders() {
        return this.$store.getters.getFolders;
      },
    },

    methods: {
      async getFolders() {
        await this.$store.dispatch(CONTENT_REQUEST);
      },
      onChange() {
        this.$emit('onChange', this.formattedData());
      },
      save() {
        if (!this.name) {
          this.$toasted.global.general_error({ message: 'Name is required.' });
          return;
        }
        if (!this.folderSelected) {
          this.$toasted.global.general_error({ message: 'Folder is required' });
          return;
        }
        this.$emit('onSave', this.formattedData());
      },
      formattedData() {
        return {
          schedule: this.schedulerData,
          folder: this.folderSelected,
          name: this.name,
        };
      },
    },
  };
</script>

<style scoped></style>
