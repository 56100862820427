<template>
  <div>
    <div class="back-wrapper">
      <div
        class="back"
        @click="$emit('goBack')"
        v-if="!selectedWidget || verifyLock(selectedWidget)"
      >
        <i class="material-icons-outlined align-middle">arrow_back</i>
        <div class="text" v-if="screen">
          Back to <b>{{ screen.name }}</b> Screen
        </div>
        <div class="text" v-else>Back</div>
      </div>

      <div class="back" v-else @click="goBack">
        <i class="material-icons-outlined align-middle">arrow_back</i>
        <div class="text">Back</div>
      </div>

      <DeleteOverrideButton v-if="showOverrideResetButton" @deleteOverride="deleteOverride" />
    </div>

    <div class="sidebar-section">
      <div class="section-title">
        <i class="material-icons-outlined align-middle">photo_size_select_large</i>
        <span class="title">Template</span>
      </div>
      <div v-if="!selectedWidget || verifyLock(selectedWidget)" class="content-section">
        <div class="layers">
          <div
            class="layer"
            v-for="(layer, index) in templateChildren"
            :key="index"
            :title="verifyLock(layer) ? 'Locked - It has overrides at group level' : ''"
            @click="selectWidget(layer)"
          >
            <i class="material-icons-outlined">{{ getIconType(layer) }}</i>
            <span> {{ getWidgetName(layer) }}</span>
            <i v-if="verifyLock(layer)" class="material-icons-outlined align-middle right">
              lock
            </i>
            <i v-else class="material-icons-outlined align-middle right">chevron_right</i>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isLoadingWidgets" class="wrapper">
      <Loader />
    </div>
    <template v-if="selectedWidget && !verifyLock(selectedWidget)">
      <template>
        <div v-if="isLayerSwapable(selectedWidget)" class="swapables">
          <div class="info">
            <i class="material-icons-outlined">{{ getIconType(selectedWidget) }}</i>
            <span class="text-capitalize">
              {{ getTitleFromType(selectedWidget.itemType) }}
              <span v-if="selectedWidget.itemType !== 'layer'">Layer</span>
            </span>
          </div>

          <div
            v-if="!['table', 'richtext'].includes(getWidgetType(selectedWidget))"
            class="widget-preview-container"
          >
            <WidgetNode
              class="widget-preview"
              :node="{
                id: getWidgetType(selectedWidget) || 'playlist',
                data: selectedWidget,
              }"
            />
          </div>

          <TableContent
            v-if="getWidgetType(selectedWidget) === 'table'"
            ref="TableContent"
            :rows="selectedWidget.object.rows"
            :columns="selectedWidget.object.columns"
            @onSave="saveTableContent"
          />

          <BaseButton
            v-if="!['table', 'richtext', 'layer'].includes(getWidgetType(selectedWidget))"
            variant="outline"
            icon="insert_photo"
            @click="openContentBrowser"
          >
            Replace
          </BaseButton>

          <RichTextEditor
            v-if="getWidgetType(selectedWidget) === 'richtext'"
            ref="TemplateRichTextEditor"
            @save="saveLayerData"
            :widget="selectedWidget"
          />
        </div>

        <template
          v-else-if="
            getWidgetType(selectedWidget) === 'text' || selectedWidget.itemType === 'layer'
          "
        >
          <div class="info">
            <i class="material-icons-outlined">{{ getIconType(selectedWidget) }}</i>
            <span class="text-capitalize">{{ getTitleFromType(selectedWidget.itemType) }}</span>
          </div>

          <template v-if="getWidgetType(selectedWidget) === 'text'">
            <BaseInput label="Heading" v-model="selectedWidget.object.headerText" />

            <BaseInput label="Body">
              <textarea
                class="form-control"
                v-model="selectedWidget.object.userText"
                :rows="3"
                :placeholder="selectedWidget.object.userText"
              />
            </BaseInput>
          </template>

          <template v-if="selectedWidget.itemType === 'layer'">
            <div class="form-group">
              <div class="custom-control custom-switch layouts">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="text-ticker"
                  v-model="selectedWidget.object.useLink"
                />
                <label class="custom-control-label" for="text-ticker">Use Link</label>
              </div>
            </div>

            <div v-if="selectedWidget.object.useLink" class="form-group layout-input link">
              <label for="layout-name">Link to website</label>
              <input
                class="form-control"
                type="text"
                id="layout-link"
                maxlength="260"
                placeholder="http://..."
                v-model="selectedWidget.object.link"
              />
            </div>
          </template>
        </template>

        <!-- Playlist -->
        <template v-else-if="selectedWidget?.type === 'playlist'">
          <PlaylistsItems
            v-if="selectedWidget"
            ref="PlaylistItems"
            :playlist="selectedWidget.object"
            @goBackButton="goBack"
            @playlistItemsUpdated="handlePlaylistItemsUpdate"
            @contentChange="onContentChange"
          />
        </template>

        <!-- The content is not editable -->
        <template v-else>
          <Tip title="No edit options" class="bg-gray">The selected layer is not editable</Tip>
        </template>
      </template>

      <!-- Swapables -->

      <ContentBrowserModal
        ref="contentBrowserModal"
        :filterItemTypes="contentBrowserFilters"
        :isSaving="replacingContent"
        @submitted="handleContentSubmit"
        class="skip-overlay"
      />
    </template>

    <div class="overrides-footer">
      <BaseButton
        v-if="
          ['text', 'layer'].includes(getWidgetType(selectedWidget)) && !verifyLock(selectedWidget)
        "
        icon="save"
        @click="saveLayerData"
        :loading="isSaving"
      >
        Save Changes
      </BaseButton>

      <BaseButton
        v-else-if="getWidgetType(selectedWidget) === 'table' && !verifyLock(selectedWidget)"
        icon="save"
        @click="$refs.TableContent.save()"
        :loading="isSaving"
      >
        Save Changes
      </BaseButton>
    </div>
  </div>
</template>

<script>
  import Tip from '@/components/Tip.vue';
  import PlaylistsItems from '@/components/screens/PlaylistsItems.vue';
  import ContentBrowserModal from '@/components/content/ContentBrowserModal.vue';
  import WidgetNode from '@/components/templates/viewer/templateWidgets/WidgetNode.vue';
  import Loader from '@/components/common/Loader.vue';
  import TableContent from '@/components/apps/table/TableContent.vue';
  import RichTextEditor from '@/components/screens/screen/richTextEditor/RichTextEditor.vue';
  import DeleteOverrideButton from '@/components/screens/screen/DeleteOverrideButton.vue';

  import { SCREEN_ADD_LAYOUT, SCREENS_RESTART } from '@/store/actions/screens';
  import { APP_TYPES, WIDGET_TYPES } from '@/models/layoutDesigner';

  import {
    apiGetTemplateChildren,
    apiCreateScreenGroupTemplateItemOverride,
    apiCreateScreenTemplateItemOverride,
    apiGetScreenTemplateChildren,
    apiScreenDeleteTemplateItemOverride,
    apiScreenGroupDeleteTemplateItemOverride,
    apiScreenGroupUpdateTemplateItemOverride,
    apiScreenUpdateTemplateItemOverride,
    apiGetScreenGroupTemplateChildren,
  } from '@/api/templates';

  import { formatTemplates, getWidgetIcons, simpleTypeMixin } from '@/helpers/mixins';
  import { PublicationType } from '@/types/api/screens';

  export default {
    name: 'TemplateOverrideSettings',

    inject: ['handleContentChange'],

    props: {
      screen: {
        type: Object,
        default: () => null,
      },

      template: {
        type: Object,
        default: () => null,
      },

      playlist: {
        type: Object,
        default: () => null,
      },
    },

    components: {
      PlaylistsItems,
      ContentBrowserModal,
      WidgetNode,
      Tip,
      Loader,
      TableContent,
      RichTextEditor,
      DeleteOverrideButton,
    },

    mixins: [simpleTypeMixin],

    data() {
      return {
        isSaving: false,
        isLoadingAccess: false,
        isLoadingWidgets: false,
        replacingContent: false,
        screenLayout: null,
        layouts: [],
        selectedWidget: null,
        templateChildren: [],
      };
    },

    created() {
      this.getWidgets();
    },

    computed: {
      showOverrideResetButton() {
        if (!this.selectedWidget) return false;

        const { override } = this.selectedWidget.object;

        if (!override) return false;

        return (this.groupId && !!override.screen_group) || (!this.groupId && !!override.screen);
      },

      isEditingScreen() {
        return !!this.$route.params?.screenId;
      },

      shouldCreateGroupOverride() {
        return (
          !this.isEditingScreen &&
          this.selectedWidget?.object?.override &&
          this.selectedWidget?.object?.override?.screen_group === null
        );
      },

      layout() {
        return this.$store.state.player.layout;
      },

      lockLayers() {
        return this.isEditingScreen && !!this.screen?.screen_group;
      },

      groupId() {
        return this.$route.params?.groupId;
      },

      contentBrowserFilters() {
        let item_type = this.selectedWidget?.object?.item_type;
        if (!item_type) return [];
        if (item_type.startsWith('image')) return ['image'];

        if (item_type.startsWith('video')) return ['video'];

        if (item_type.startsWith('app')) return item_type === 'app/pdf' ? ['pdf'] : ['app'];
      },
    },

    methods: {
      async onContentChange() {
        await this.handleContentChange();
      },

      async getWidgets(silent = false) {
        try {
          const { screenId, groupId } = this.$store.state.player;
          this.isLoadingWidgets = !silent;
          this.widgets = [];

          let response;

          if (groupId) {
            response = await apiGetScreenGroupTemplateChildren({
              groupId,
              playlistId: this.playlist?.playlist_id,
              templateId: this.template.template_id,
              layoutId: this.layout?.layout_id,
            });
          } else if (screenId) {
            response = await apiGetScreenTemplateChildren(
              this.template.template_id,
              screenId,
              this.playlist?.playlist_id,
            );
          } else {
            response = await apiGetTemplateChildren(this.template.template_id);
          }

          const formatedWidgets = formatTemplates(response.data);

          this.templateChildren = formatedWidgets;
        } catch (error) {
          console.log('getWidgets ~ error:', error);
          this.$toasted.global.general_error({
            message: 'Not able to load Template Preview',
          });
        }

        this.isLoadingWidgets = false;
      },

      getWidgetName(layer) {
        return layer.object.name || this.getSimpleType(layer.object.item_type).toUpperCase();
      },

      getWidget(layerId) {
        return this.templateChildren.find((layer) => layer.assoc_id === layerId);
      },

      goBack() {
        this.selectWidget(null);
      },

      async saveScreenData() {
        this.isSaving = true;
        try {
          // Check if layout is changed
          if (this.screenLayout !== this.layout.layout_id) {
            await this.$store.dispatch(SCREEN_ADD_LAYOUT, {
              screenId: this.screen.id,
              layoutId: this.screenLayout,
            });
          }

          await this.getWidgets(true);
          await this.handleContentChange();

          // Show success message
          this.$toasted.global.general_success({
            message: 'Screen saved successfully',
          });
        } catch (err) {
          this.$toasted.global.general_error({
            message: 'Error while saving screen',
          });
        }
        this.isSaving = false;
      },

      async handleContentSubmit(files) {
        this.replacingContent = true;
        let item = files[0];

        try {
          const isObjectAnOverride = this.selectedWidget.object.override && this.createOverride;

          isObjectAnOverride && !this.shouldCreateGroupOverride
            ? await this.updateOverride(item.item_id)
            : await this.createOverride(item.item_id);

          await this.getWidgets(true);
          await this.handleContentChange();

          this.$toasted.global.general_success({
            message: 'The content was replaced successfully',
          });
        } catch (e) {
          this.$toasted.global.general_error({
            message: String(e),
          });
        }
        this.replacingContent = false;
        this.closeContentBrowser();
        this.selectedWidget = null;
      },

      handlePlaylistItemsUpdate() {
        // check if we are in ScreenView or ScreenGroupView
        if (this.isEditingScreen) {
          this.$store.dispatch(SCREENS_RESTART, {
            publicationType: PublicationType.ScreenPublication,
            screen_ids: this.screens.map((screen) => screen.id),
          });
        } else {
          this.$store.dispatch(SCREENS_RESTART, {
            publicationType: PublicationType.ScreenGroupPublication,
            screen_group_id: this.groupId,
            layout_id: this.layout.layout_id,
          });
        }
      },

      async saveLayerData() {
        this.isSaving = true;

        const type = this.getWidgetType(this.selectedWidget);
        const config = this.selectedWidget.object;

        if (type === 'text' || type === 'layer') {
          await this.saveTextLayer(config);
        } else if (type === 'richtext') {
          const htmlText = this.$refs.TemplateRichTextEditor.getRichText();
          await this.saveTextLayer(
            // deprecate base text size by setting it to minus one
            { ...config, userText: htmlText, textSize: -1 },
            'RichText layer saved successfully',
          );
        }

        await this.handleContentChange();

        this.isSaving = false;
      },

      async saveTableContent(tableData) {
        const config = { ...this.selectedWidget.object, ...tableData };

        try {
          if (!this.selectedWidget.object.override && !this.shouldCreateGroupOverride) {
            await this.createOverride(this.selectedWidget.object.item_id, config);
          } else {
            await this.updateOverride(this.selectedWidget.object.item_id, config);
          }

          this.$toasted.global.general_success({
            message: 'Table saved successfully',
          });
        } catch (error) {
          this.$toasted.global.general_error({
            message: String(error),
          });
          console.log('saveTableContent error', error);
        }
      },

      async saveTextLayer(config, message = 'Text layer saved successfully') {
        try {
          if (!this.selectedWidget.object.override || this.shouldCreateGroupOverride) {
            await this.createOverride(this.selectedWidget.object.item_id, config);
          } else {
            await this.updateOverride(this.selectedWidget.object.item_id, config);
          }

          await this.getWidgets(true);
          this.selectWidget(null);

          this.$toasted.global.general_success({ message });
        } catch (error) {
          this.$toasted.global.general_error({
            message: String(error),
          });
          console.log('saveTextLayer error', error);
        }
      },

      async createOverride(newItemId, overrideData = {}) {
        try {
          const data = {
            itemId: this.selectedWidget.object.item_id,
            newItemId,
            overrideConfig: overrideData,
            templateId: this.template.template_id,
          };

          this.isEditingScreen
            ? await apiCreateScreenTemplateItemOverride({
                ...data,
                screenId: this.screen.id,
                playlistId: this.playlist?.playlist_id,
              })
            : await apiCreateScreenGroupTemplateItemOverride({
                ...data,
                groupId: this.groupId,
                playlistId: this.playlist?.playlist_id,
                layoutId: this.layout?.layout_id,
              });
        } catch (error) {
          console.log('createOverride error', error);
          throw error;
        }
      },

      async updateOverride(newItemId, overrideData = {}) {
        try {
          const data = {
            overrideId: this.selectedWidget.object.override.id,
            itemId: this.selectedWidget.object.item_id,
            newItemId,
            overrideConfig: { ...overrideData, override: undefined },
            templateId: this.template.template_id,
          };

          this.isEditingScreen
            ? await apiScreenUpdateTemplateItemOverride(data, this.playlist?.playlist_id)
            : await apiScreenGroupUpdateTemplateItemOverride(data, this.playlist?.playlist_id);
        } catch (error) {
          console.log('updateOverride error', error);
          throw error;
        }
      },

      async deleteOverride() {
        try {
          const {
            screen: screenId,
            screen_group: groupId,
            id: overrideId,
          } = this.selectedWidget.object.override;

          !!groupId
            ? await apiScreenGroupDeleteTemplateItemOverride(groupId, overrideId)
            : await apiScreenDeleteTemplateItemOverride(screenId, overrideId);

          await this.getWidgets(true);
          await this.handleContentChange();
          this.selectWidget(null);

          this.$toasted.global.general_success({
            message: 'Override removed',
          });
        } catch (error) {
          this.$toasted.global.general_error({
            message: String(error),
          });
        }

        this.isLoading = false;
      },

      getWidgetType(layer) {
        if (!layer) return;

        if (layer.type === 'template') {
          return 'template';
        }

        if (layer.type !== 'playlist') {
          return this.getSimpleType(layer.object.item_type);
        }

        return 'playlist';
      },

      isLayerSwapable(layer) {
        if (!layer) return;

        const { VIDEO, IMAGE, PDF, RICH_TEXT } = WIDGET_TYPES;
        const { RSS, TABLE, TRAFIKLAB, WEATHER } = APP_TYPES;

        const swapables = [VIDEO, IMAGE, PDF, RSS, TABLE, TRAFIKLAB, WEATHER, RICH_TEXT];
        const layerType = this.getWidgetType(layer);

        return swapables.includes(layerType);
      },

      openContentBrowser() {
        this.$refs.contentBrowserModal.open();
      },

      closeContentBrowser() {
        this.$refs.contentBrowserModal.close();
      },

      openPlaylistAddItem() {
        this.$refs.PlaylistItems.openPlaylistContentModal();
      },

      selectWidget(widget) {
        this.selectedWidget = widget;
      },

      verifyLock(widget) {
        if (!widget) return false;

        const isWidgetOverriddenByGroup = !!widget.object.override?.screen_group;

        return this.lockLayers && isWidgetOverriddenByGroup;
      },

      getIconType(widget) {
        return getWidgetIcons(widget.itemType || widget.object.type);
      },
    },

    provide() {
      return {
        template: () => this.template,
        isTemplateOnPlaylist: () => !!this.playlist?.playlist_id,
      };
    },
  };
</script>

<style lang="scss" scoped>
  .back-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .layers {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
    padding-top: 8px;

    .router-link {
      text-decoration: none;
    }

    .layer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      color: $primaryText;
      width: 100%;
      background: #fff;
      border: 1px solid $backgroundGrey3;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
      border-radius: 8px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      padding: 8px 16px;

      &:hover {
        cursor: pointer;
      }

      span {
        flex: 1;
        max-width: 95%;
        overflow: hidden;
        position: relative;

        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        // gradient right
        &:after {
          content: '';
          position: absolute;
          right: 0;
          width: 25px;
          height: 100%;
          background: #000;
          background: linear-gradient(to right, rgba(255, 255, 255, 0), #fff 100%);
        }
      }

      i.right {
        transition: all 0.2s ease-in-out;
        opacity: 0;
        transform: translateX(-10px) scale(0.4);
        font-size: 24px;
        color: $primaryText;
      }

      &:hover {
        background-color: $backgroundGrey3;
        i.right {
          opacity: 1;
          transform: translateX(5px) scale(1);
        }
      }
    }
  }

  .bg-gray {
    background-color: #f4f4f4;
  }

  .back {
    display: flex;
    align-items: center;
    gap: 8px;
    padding-bottom: 16px;
    cursor: pointer;
    user-select: none;

    i {
      font-size: 22px;
    }
    .text {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $secondaryText;

      b {
        color: $primaryText;
      }
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .widget-preview-container {
    width: 100%;
    overflow: hidden;
    .widget-preview {
      position: relative !important;
      height: 100% !important;
      width: 100% !important;
      top: 0 !important;
      left: 0 !important;
    }
  }

  .info {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    margin: 12px 0;

    i {
      margin-right: 10px;
    }
  }

  .form-control {
    border: 2px solid #e8e8e8;
    border-radius: 8px;
    padding: 8px 16px;
    font-size: 16px;
    line-height: 24px;
    color: $primaryText;
    background-color: #fff;
    width: 100%;
    height: 100%;
    &:focus {
      border-color: #000000;
      box-shadow: none;
    }
  }

  .section-title {
    display: flex;
    align-items: center;
    font-size: 16px;
    gap: 10px;
    margin: 16px 8px;

    i {
      color: $primaryText;
    }

    &.title {
      margin-bottom: 24px;
    }
  }

  .overrides-footer {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }

  .swapables {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
</style>
