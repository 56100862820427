export const emptyTemplateImage =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAACgCAYAAACMoXt9AAAAAXNSR0IArs4c6QAAAgxJREFUeF7t0kENACEAA8HDAf5V4oALIpgPWwObTDr23vtr1wXGgV5rXQ+9HJhzfkGDBwQNkE8i6KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr06KCRAMr0aA2Nek9nfm/3WF0e8ENNAAAAAElFTkSuQmCC';

export const TEMPLATE_DEFAULT_DATA = {
  image: emptyTemplateImage,
  width: 200,
  height: 200,
  backgroundColor: '#FFFFFF',
  opacity: 100,
  grid: {
    x: 20,
    y: 20,
  },
};
