<template>
  <form v-show="showModal" ref="organisationForm" @submit.prevent="createOrganisation">
    <Modal
      @closeModal="closeModal"
      :isSaving="isSaving"
      title="Create Organisation"
      :okFunction="createOrganisation"
      :modalStyles="{ width: '400px' }"
      submitForm
      avoidClickOutside
    >
      <template v-slot:body>
        <div class="group-body">
          <div class="form-group">
            <label for="organisationName">
              <div class="label">Organisation Name</div>
              <input
                type="text"
                name="organisationName"
                id="organisationName"
                required
                minlength="3"
                maxlength="40"
                v-model="organisationName"
                placeholder="Set organisation's name"
              />
            </label>
          </div>

          <div class="form-group">
            <label for="contactEmail">
              <div class="label">Contact Email</div>
              <input
                type="email"
                ref="email"
                name="contactEmail"
                id="contactEmail"
                required
                maxlength="60"
                v-model="contactEmail"
                placeholder="Enter organisation contact email"
              />
            </label>
          </div>

          <div class="form-group">
            <label for="contactName">
              <div class="label">Contact Person Full Name</div>
              <input
                type="text"
                name="contactName"
                id="contactName"
                required
                minlength="3"
                maxlength="40"
                v-model="contactName"
                placeholder="Enter organisation contact email"
              />
            </label>
          </div>
        </div>
      </template>
    </Modal>
  </form>
</template>

<script>
  import Modal from '@/components/common/Modal.vue';
  import { ADMIN_ORGANISATIONS_GET, ADMIN_ORGANISATION_PUT } from '@/store/actions/organisation';

  export default {
    name: 'CreateOrganisationModal',

    components: { Modal },

    data() {
      return {
        showModal: false,
        isSaving: false,
        organisationName: '',
        contactEmail: '',
        contactName: '',
      };
    },

    mounted() {
      this.showModal = true;
    },

    methods: {
      isValid() {
        let isValid = true;

        if (!this.organisationName) {
          this.$toasted.global.general_error({ message: 'Organisation name is required' });
          isValid = false;
        }
        const mailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

        if (!this.contactEmail || !this.contactEmail.match(mailPattern)) {
          this.$toasted.global.general_error({ message: 'Contact mail is not valid' });
          isValid = false;
        }
        return isValid;
      },
      async createOrganisation() {
        if (!this.isValid()) {
          return;
        }
        this.isSaving = true;
        try {
          const data = {
            name: this.organisationName,
            email: this.contactEmail,
            contactName: this.contactName,
          };

          const [successful, error] = await this.$store.dispatch(ADMIN_ORGANISATION_PUT, data);

          if (!successful) {
            throw new Error(error?.response?.data?.message ?? 'Ups, something went wrong');
          }

          this.$toasted.global.general_success({
            message: 'Organisation created successfully',
          });

          await this.$store.dispatch(ADMIN_ORGANISATIONS_GET);
          this.closeModal();
        } catch (error) {
          this.$toasted.global.general_error({
            message: String(error),
          });
        }
        this.isSaving = false;
      },

      closeModal() {
        this.showModal = false;
        this.$emit('closeModal');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .group-body {
    display: grid;
    row-gap: 24px;
    column-gap: 16px;

    .form-group {
      width: 100%;
      margin-bottom: 0px;

      label {
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
        gap: 8px;

        font-weight: 500;
        line-height: 24px;

        .label {
          display: flex;
          gap: 8px;
          align-items: center;

          font-size: 14px;
        }

        input,
        select {
          padding: 8px 16px;

          border: 2px solid $borderGrey;
          border-radius: 8px;
          font-size: 16px;
        }
      }
    }

    .description {
      grid-column: 1/3;
    }
  }
</style>
