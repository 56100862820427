<template>
  <div class="content-browser-modal modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <i
            v-if="currentFolderPath.length > 0"
            class="material-icons align-middle"
            @click="returnToPreviousFolder"
          >
            arrow_back
          </i>

          <h5 class="modal-title">{{ title }}</h5>

          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click.prevent="close"
          >
            <i class="material-icons align-middle">close</i>
          </button>
        </div>

        <div class="modal-body scrollbar">
          <CardGrid
            class="flex-grow-1"
            v-show="true"
            @select="onCardClicked"
            :activeItems="selectedItems"
            :items="filteredTemplates"
            :multi-select-mode="false"
          />
        </div>

        <div class="modal-footer">
          <button type="button" class="button-2-secondary" @click.prevent="close">
            {{ $lang.common.cancel }}
          </button>

          <button
            @click="isTemplateForTiles ? createTile() : createTemplate()"
            type="button"
            class="button-2-primary"
            :disabled="!selectedTemplate"
          >
            {{ $lang.common.save }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import CardGrid from '@/components/content/CardGrid.vue';
  import { mapState } from 'vuex';
  import { GET_TEMPLATES_FOLDER } from '@/store/actions/templateDesigner';
  import { CONTENT_TYPE_FOLDER } from '@/constant/const';

  export default {
    name: 'TemplateFolderBrowserModal',

    components: {
      CardGrid,
    },

    props: {
      isTemplateForTiles: {
        type: Boolean,
        default: false,
      },
      usedTemplatesIds: {
        type: Array,
        default: () => [],
      },
    },

    data() {
      return {
        currentFolderPath: [],
        title: 'Templates Browser',
        currentTemplates: [],
        selectedItems: [],
      };
    },

    mounted() {
      this.getTemplates();
    },

    computed: {
      ...mapState({
        templates: (state) => {
          return state.templateDesigner.templatesFolder;
        },
      }),
      selectedTemplate() {
        if (this.selectedItems.length > 0) {
          return this.filteredTemplates[this.selectedItems[0]];
        }
        return null;
      },
      filteredTemplates() {
        return this.currentTemplates.map((template) => {
          return {
            ...template,
            selectable: this.isTemplateForTiles
              ? true
              : !this.usedTemplatesIds.includes(template.template_id),
          };
        });
      },
    },

    methods: {
      async createTemplate() {
        if (!this.selectedTemplate.selectable) {
          return;
        }

        this.$emit('attachTemplate', this.selectedTemplate);
        this.close();
      },

      async createTile() {
        this.$emit('createTile', this.selectedTemplate);
        this.close();
      },

      async getTemplates() {
        await this.$store.dispatch(GET_TEMPLATES_FOLDER, {});
      },

      close() {
        this.$emit('close');
        $(this.$el).modal('hide');
      },

      open() {
        this.showRootContentPage();
        $(this.$el).modal('show');
      },

      onCardClicked({ event, index }) {
        if (this.currentTemplates[index].content_type === CONTENT_TYPE_FOLDER) {
          const folder = this.currentTemplates[index];
          this.currentTemplates = folder.contents;
          this.currentFolderPath.push(folder.name);
          this.selectedItems = [];
        } else {
          if (
            !this.isTemplateForTiles &&
            this.usedTemplatesIds.includes(this.currentTemplates[index].template_id)
          ) {
            return;
          }
          this.selectedItems = [index];
        }
      },
      returnToPreviousFolder() {
        this.selectedItems = [];
        if (this.currentFolderPath.length === 1) {
          this.showRootContentPage();
        } else if (this.currentFolderPath.length > 1) {
          this.goBack(this.currentFolderPath.length - 2);
        }
      },
      showRootContentPage() {
        this.currentFolderPath = [];
        this.selectedItems = [];
        this.currentTemplates = this.templates;
      },
      goBack(index) {
        const folderName = this.currentFolderPath[index];
        const cat = this.templates.find((temp) => {
          return temp.content_type === CONTENT_TYPE_FOLDER && temp.name === folderName;
        });
        if (cat) {
          this.currentTemplates = cat.contents;
        } else {
          this.currentTemplates = [];
        }
        this.currentFolderPath.pop();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .modal-dialog {
    min-width: min(90vw, 742px);
    z-index: 2000;

    .modal-header {
      display: flex;
      align-items: center;
      background-color: #fff;
      color: $primaryText;
      font-size: 20px;
      gap: 20px;
      padding: 24px 0 16px;
      margin: 0 32px;
      border-bottom: 2px solid $lineGrey;
      line-height: 32px;
      font-weight: bold;

      h5 {
        font-family: inherit;
      }

      & > i {
        cursor: pointer;
      }
    }

    .modal-footer {
      justify-content: flex-end;
      display: flex;
      gap: 16px;
      padding: 24px 32px;
    }
    .button-2-primary[disabled] {
      opacity: 0.5;
    }

    .modal-content {
      border-radius: 0;
      padding: 0;
      border: none;
      min-height: min(640px, 80vh);

      .modal-body {
        margin-top: 16px;
        padding: 0 10px 16px 10px;
        max-height: calc(474px - 166px);
        min-height: min(474px, calc(80vh - 180px));
        min-width: 80vw;
        overflow-y: auto;
        padding: 16px 32px;
      }
    }
  }
</style>
