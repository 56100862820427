<template>
  <div class="layer-widget-panel scrollbar">
    <div class="form-group template-input name">
      <label for="template-name">Name</label>
      <input
        class="form-control"
        type="text"
        id="template-name"
        maxlength="40"
        placeholder="Name"
        :disabled="isWidgetLockedByOverride"
        v-model="value.object.name"
      />
    </div>

    <div class="measurements-container">
      <div class="measurements">
        <div class="form-group hidden position">
          <div class="form-row">
            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">X</span>
              </div>
              <input
                type="number"
                class="form-control"
                id="layer-x"
                v-model="xPosition"
                :disabled="isWidgetLocked"
              />
            </div>

            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">Y</span>
              </div>
              <input
                type="number"
                class="form-control"
                v-model="yPosition"
                :disabled="isWidgetLocked"
              />
            </div>
          </div>
        </div>

        <div class="form-group hidden size">
          <div class="form-row">
            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">W</span>
              </div>
              <input
                type="number"
                class="form-control"
                id="layer-width"
                v-model="width"
                :disabled="isWidgetLocked"
              />
            </div>

            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">H</span>
              </div>
              <input
                type="number"
                class="form-control"
                v-model="height"
                :disabled="isWidgetLocked"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="extra-icons">
        <LockIcon :locked="isWidgetLocked" />

        <PixelIcon :usePixels="usePixels" @clicked="changeValuesMode" />
      </div>
    </div>

    <div class="sub-section-title">Background</div>

    <div class="form-group hidden">
      <div class="form-row background-css">
        <color-picker
          class="col"
          input-el-id="layer-background-color"
          :disabled="isWidgetLockedByOverride"
          v-model="value.object.backgroundColor"
        />

        <div class="input-group col">
          <div class="input-label">
            <span class="input-group-text">
              <i class="material-icons-outlined align-middle">opacity</i>
            </span>
          </div>
          <input
            type="number"
            class="form-control"
            id="layer-opacity"
            :disabled="isWidgetLockedByOverride"
            v-model="value.object.opacity"
          />
        </div>
      </div>
    </div>

    <div class="form-group ticker">
      <div class="custom-control custom-switch templates">
        <input
          type="checkbox"
          class="custom-control-input"
          id="text-ticker"
          :disabled="isWidgetLockedByOverride"
          v-model="value.object.useLink"
        />
        <label class="custom-control-label" for="text-ticker">Use Link</label>
      </div>
    </div>

    <div v-if="value.object.useLink" class="form-group template-input link">
      <label for="template-name">Link to website</label>
      <input
        class="form-control"
        type="text"
        id="template-link"
        maxlength="260"
        placeholder="http://..."
        :disabled="isWidgetLockedByOverride"
        v-model="value.object.link"
      />
    </div>

    <div v-if="value.object.useLink" class="form-group layout-input time">
      <label for="layer-time">Refresh every</label>
      <div class="time-container">
        <input class="form-control" type="number" id="layer-time" min="0" v-model="refreshTime" />

        <select
          class="custom-select"
          id="layer-time-unit"
          :disabled="isWidgetLockedByOverride"
          v-model="value.object.timeUnit"
          style="zoom: 1.1; height: 38px"
        >
          <option :value="TIME_UNITS.SECONDS.value" style="zoom: 1.1">
            {{ TIME_UNITS.SECONDS.title }}
          </option>
          <option :value="TIME_UNITS.MINUTES.value" style="zoom: 1.1">
            {{ TIME_UNITS.MINUTES.title }}
          </option>
          <option default :value="TIME_UNITS.HOURS.value" style="zoom: 1.1">
            {{ TIME_UNITS.HOURS.title }}
          </option>
          <option :value="TIME_UNITS.DAYS.value" style="zoom: 1.1">
            {{ TIME_UNITS.DAYS.title }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
  import ColorPicker from '@/components/ColorPicker';
  import PixelIcon from '@/components/layoutDesigner/editor/settingPanel/PixelIcon.vue';
  import LockIcon from '@/components/layoutDesigner/editor/settingPanel/LockIcon.vue';

  import ContentSelector from '@/components/templates/editor/ContentSelector';
  import ImageMode from '@/components/templates/editor/settingPanel/formControls/ImageMode';

  import widgetMixin from '@/components/templates/editor/settingPanel/widgetMixin';
  import { restrictToBounds } from '@/helpers/draggable';

  import { WIDGET_CONTENT_TYPE } from '@/models/layoutDesigner';

  import { TIME_UNITS } from '@/constant/const';

  export default {
    name: 'LayerWidgetPanel',

    mixins: [widgetMixin],

    components: {
      ColorPicker,
      ContentSelector,
      ImageMode,
      PixelIcon,
      LockIcon,
    },

    data() {
      return {
        TIME_UNITS,
      };
    },

    mounted() {
      if (!this.value.object.refreshTime && this.value.object.refreshTime !== 0) {
        this.$set(this.value.object, 'refreshTime', 24);
      }

      if (!this.value.object.timeUnit) {
        this.$set(this.value.object, 'timeUnit', 'hours');
      }
    },

    computed: {
      hasImageContent() {
        return this.value.object.contentType === WIDGET_CONTENT_TYPE.IMAGE;
      },

      refreshTime: {
        get() {
          return this.value.object.refreshTime && this.value.object.refreshTime <= 0
            ? 0
            : this.value.object.refreshTime;
        },
        set(value = 0) {
          const refreshTime = restrictToBounds(!!value ? value : 0, 0, Infinity);
          this.$set(this.value.object, 'refreshTime', Number(refreshTime));
          this.$forceUpdate();
        },
      },
    },
  };
</script>

<style lang="scss" scoped>
  .layer-widget-panel {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: 0px;
    padding-right: 10px;

    .sub-section-title {
      padding-top: 4px;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      width: 100%;
    }
  }

  .ticker {
    margin-top: 16px;
  }

  .time-container {
    display: flex;
    gap: 16px;
  }
</style>
