module.exports = {
  welcome: 'Welcome',
  to: 'to',
  activities_title : 'Activities',
  courts_title: 'Courts',
  book_with: 'Book with',
  members: 'Members',
  playing_now: 'Playing right now',
  vacant_times: 'Available Slots',
  tracks: 'Tracks',
  offers_for_you: 'Offers for you',
  contact_us: 'Contact us at reception for info',
  available_time: 'Available time',

  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  sunday: 'Sunday',

  game_strength: 'Game strength',
  players: 'Players',

  app_description: 'Do you play tennis, badminton, table tennis, squash or paddle? Become a member of Matchi and make your everyday life more flexible.'
};
