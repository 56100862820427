<template>
  <div id="htmleditor" class="container">
    <div class="m-2">
      <h4 class="mb-3">Rikt innehåll</h4>
      <p>Editor</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HTMLEditor',
}
</script>
