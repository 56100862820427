<template>
  <SidebarContainer>
    <template v-if="!!organisation" v-slot:default>
      <div class="sidebar-container">
        <div class="form-group">
          <label for="OrganisationName">
            <div class="label">Organisation Name</div>
            <input
              type="text"
              name="OrganisationName"
              id="OrganisationName"
              required
              v-model="organisationName"
            />
          </label>
        </div>

        <button type="button" class="button-2-third" @click="goToManageLicenses">
          <i class="material-icons-outlined align-middle">view_list</i>
          Manage Licenses
        </button>

        <button type="button" class="button-2-third" @click="goToManageUsers">
          <i class="material-icons-outlined align-middle">supervisor_account</i>
          Manage Users
        </button>

        <div class="content-section">
          <div class="dropdown-section">
            <div class="dropdown" @click="showRoles = !showRoles">
              <span>Roles</span>
              <i class="material-icons-outlined align-middle" :class="{ 'text-red': showRoles }">{{
                showRoles ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
              }}</i>
            </div>

            <div v-if="showRoles" class="content-container">
              <RolesList />
            </div>

            <div class="dropdown" @click="showInfo = !showInfo">
              <span>Info</span>
              <i class="material-icons-outlined align-middle" :class="{ 'text-red': showInfo }">{{
                showInfo ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
              }}</i>
            </div>

            <div v-if="showInfo" class="content-container">
              <div class="description-container">
                <div class="description-item">
                  <span>Created: </span>{{ formatDate(organisation.created, 'date') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-if="!!organisation" v-slot:buttons>
      <button
        v-if="isAdmin"
        type="button"
        class="button-2-secondary"
        @click="openDeleteOrganisationModal"
      >
        <i class="material-icons-outlined align-middle">delete_outline</i>
        Delete Organisation
      </button>

      <button
        type="button"
        class="button-2-primary"
        @click="updateOrganisation"
        :disabled="isSaving"
      >
        <i v-if="isSaving" class="material-icons align-middle spin-animation">refresh</i>
        <i v-else class="material-icons-outlined align-middle">save</i>
        Save Settings
      </button>

      <DeleteOrganisationModal
        @closeModal="showDeleteOrganisationModal = false"
        :organisationId="organisation.organisation_id"
        v-if="showDeleteOrganisationModal"
        @deleted="$router.push({ name: 'Organisation' })"
      />
    </template>
  </SidebarContainer>
</template>

<script>
  import SidebarContainer from '@/components/common/SidebarContainer.vue';
  import DeleteOrganisationModal from '@/components/admin/DeleteOrganisationModal.vue';
  import RolesList from '@/components/admin/sidebar/RolesList.vue';

  import { ADMIN_ORGANISATION_UPDATE } from '@/store/actions/organisation';
  import { globalFiltersMixin } from '@/helpers/mixins';

  export default {
    name: 'OrganisationSidebar',

    components: { SidebarContainer, DeleteOrganisationModal, RolesList },

    mixins: [globalFiltersMixin],

    data() {
      return {
        isSaving: false,
        showRoles: false,
        showInfo: false,
        showRolesModal: false,
        selectedRole: null,
        showDeleteOrganisationModal: false,
        organisationName: this.organisation?.name || '',
      };
    },

    mounted() {
      this.organisationName = this.organisation?.name || '';
    },

    watch: {
      organisation() {
        this.organisationName = this.organisation?.name || '';
      },
    },

    computed: {
      organisation() {
        return this.$store.state.organisation.organisation;
      },

      isAdmin() {
        return this.$store.getters.isAdministrator;
      },

      isOrganizationAdmin() {
        return this.$store.getters.isOrganizationAdmin;
      },
    },

    methods: {
      async updateOrganisation() {
        this.isSaving = true;

        try {
          const data = {
            orgId: this.organisation.organisation_id,
            orgData: {
              name: this.organisationName,
            },
          };

          const [successful, error] = await this.$store.dispatch(ADMIN_ORGANISATION_UPDATE, data);

          if (successful) {
            this.$toasted.global.general_success({
              message: this.$lang.organizations.organization_updated,
            });
          } else {
            this.$toasted.global.general_error({
              message: error?.response?.data?.message ?? 'Ups, something went wrong',
            });
          }

          this.$emit('organisationUpdated', true);
        } catch (error) {
          this.$toasted.global.general_error({
            message: String(error),
          });
        }

        this.isSaving = false;
      },

      openDeleteOrganisationModal() {
        this.showDeleteOrganisationModal = true;
      },

      goToManageLicenses() {
        this.$router.push({
          name: 'Licenses',
          params: {
            organisationId: this.organisation.organisation_id,
          },
        });
      },

      goToManageUsers() {
        this.$router.push({
          name: 'Users',
          params: {
            organisationId: this.organisation.organisation_id,
          },
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .sidebar-container {
    display: flex;
    flex-direction: column;
    gap: 16px;

    button {
      margin-top: 8px;
    }
  }

  .content-section {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }

  .form-group {
    width: 100%;
    margin-bottom: 0px;

    label {
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
      gap: 8px;

      font-weight: 500;
      line-height: 24px;

      .label {
        display: flex;
        gap: 8px;
        align-items: center;

        font-size: 14px;
      }

      input {
        padding: 8px 16px;

        border: 2px solid $borderGrey;
        border-radius: 8px;
        font-size: 16px;
      }
    }
  }

  .dropdown-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 8px;
    gap: 8px;
    width: 100%;

    .dropdown {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      line-height: 24px;
      padding: 8px 0;
      font-weight: 500;
      cursor: pointer;
    }
  }

  .content-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 16px;
    border-bottom: 2px solid $lineGrey;
    overflow-x: hidden;
  }

  .description-container {
    display: flex;
    flex-direction: column;
    padding: 12px 2px;
    gap: 8px;

    .description-item {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: $primaryText;

      a {
        color: $primaryText;
      }

      span {
        color: $secondaryText;
        font-weight: 400;
      }
    }
  }
</style>
