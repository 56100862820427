<template>
  <SidebarContainer>
    <template v-if="!!user" v-slot:default>
      <div class="sidebar-container">
        <div class="avatar-section">
          <div class="avatar-container">
            <img
              v-if="user.avatar_url"
              class="rounded-circle"
              :src="user.avatar_url + '?' + Date.now()"
              alt="Avatar"
            />
            <div v-else class="img-placeholder">
              <i class="material-icons align-middle">account_circle</i>
            </div>
          </div>

          <div class="avatar-data">
            <div class="email">{{ user.email || user.username }}</div>
          </div>
        </div>
      </div>

      <div class="sidebar-container">
        <div class="form-group">
          <label for="name">
            <BaseText variant="inputLabel">Name</BaseText>
            <input type="text" name="name" id="name" v-model="name" />
          </label>
        </div>

        <div class="form-group">
          <label for="userRole">
            <BaseText variant="inputLabel">User Role</BaseText>
            <select type="text" name="userRole" id="userRole" v-model="userRole">
              <option v-for="(role, index) in roles" :key="index" :value="role.id">
                {{ role.name }}
              </option>
            </select>
          </label>
        </div>

        <div class="form-check">
          <input
            v-model="userIsLocalAdmin"
            type="checkbox"
            id="radioLocalAdmin"
            class="form-check-input mr-2"
          />
          <BaseText variant="inputLabel">{{ $lang.user_settings.local_admin }}</BaseText>
        </div>

        <div class="content-section">
          <div class="dropdown-section">
            <div class="dropdown" @click="showInfo = !showInfo">
              <span>Info</span>
              <i class="material-icons-outlined align-middle" :class="{ 'text-red': showInfo }">{{
                showInfo ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
              }}</i>
            </div>

            <div v-if="showInfo" class="content-container">
              <div class="description-container">
                <div class="description-item">
                  <span>Created: </span>{{ formatDate(user.created, 'date') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-if="user" v-slot:buttons>
      <button v-if="isAdmin" type="button" class="button-2-secondary" @click="handlePreviewMode">
        Preview PicFlow as this user
      </button>

      <button type="button" class="button-2-secondary" @click="openDeleteUserModal">
        <i class="material-icons-outlined align-middle">delete_outline</i>
        Delete User
      </button>

      <button type="button" class="button-2-primary" @click="updateUser" :disabled="isSaving">
        <i v-if="isSaving" class="material-icons align-middle spin-animation">refresh</i>
        <i v-else class="material-icons-outlined align-middle">save</i>
        Save
      </button>

      <DeleteUserModal
        @closeModal="showDeleteUserModal = false"
        :user="user"
        v-if="showDeleteUserModal"
        @deleted="$emit('userDeleted')"
      />
    </template>
  </SidebarContainer>
</template>

<script>
  import SidebarContainer from '@/components/common/SidebarContainer.vue';
  import DeleteUserModal from './DeleteUserModal.vue';
  import { SET_ORGANISATIONS } from '@/store/actions/organisation';
  import { VIEW_AS_USER } from '@/store/actions/auth';
  import { ADMIN_USER_UPDATE, USER_UPDATE } from '@/store/actions/user';
  import { SET_USER_LOCAL_ADMIN } from '@/store/actions/auth';
  import BaseText from '@ui/atoms/baseText/BaseText.vue';
  import { globalFiltersMixin } from '@/helpers/mixins';
  import { NOT_ORGANISATION_ADMIN_MESSAGE } from '@/constant/const';

  export default {
    name: 'UsersSidebar',

    components: { SidebarContainer, DeleteUserModal, BaseText },

    props: {
      user: {
        type: Object,
        default: () => null,
      },
    },

    mixins: [globalFiltersMixin],

    data() {
      return {
        isSaving: false,
        showRoles: false,
        showInfo: false,
        showRolesModal: false,
        selectedRole: null,
        showDeleteUserModal: false,
        userRole: this.user?.role,
        userIsLocalAdmin: this.user?.is_local_administrator,
        name: this.user?.name,
      };
    },

    watch: {
      user() {
        this.userRole = this.user?.role;
        this.name = this.user?.name;
        this.userIsLocalAdmin = this.user?.is_local_administrator;
      },
    },

    computed: {
      isOrganizationAdmin() {
        return this.$store.getters.isOrganizationAdmin;
      },

      roles() {
        return this.$store.state.roles.roles || [];
      },

      isAdmin() {
        return this.$store.getters.isAdministrator;
      },

      userId() {
        return this.$store.getters.getUserId;
      },
    },

    methods: {
      async updateUser() {
        this.isSaving = true;
        try {
          const data = {
            userData: {
              role: this.userRole,
              user_id: this.user.user_id,
              is_local_administrator: this.userIsLocalAdmin,
              name: this.name,
            },
            userId: this.user.user_id,
          };

          let updatedUser;
          if (this.isAdmin) {
            updatedUser = await this.$store.dispatch(ADMIN_USER_UPDATE, data);
          } else {
            updatedUser = await this.$store.dispatch(USER_UPDATE, data.userData);
          }

          if (updatedUser.data.user_id === this.userId) {
            await this.$store.dispatch(SET_USER_LOCAL_ADMIN, updatedUser.data);
            if (!this.isAdmin && !this.isOrganizationAdmin) {
              this.$router.push({ name: 'Dashboard' });
            }
            this.$toasted.global.general_error({
              message: NOT_ORGANISATION_ADMIN_MESSAGE,
            });
          }

          this.$toasted.global.general_success({
            message: this.$lang.organizations.user_updated,
          });
          this.$emit('userUpdated');
        } catch (error) {
          this.$toasted.global.general_error({
            message: String(error),
          });
        }
        this.isSaving = false;
      },

      openDeleteUserModal() {
        this.showDeleteUserModal = true;
      },

      handlePreviewMode() {
        this.$store.commit(SET_ORGANISATIONS, [this.selectedOrganisation]);
        this.$store.dispatch(VIEW_AS_USER, this.user).then(() => {
          this.$router.push({ name: 'Organisation' });
          window.location.reload();
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .sidebar-container {
    display: flex;
    flex-direction: column;
    gap: 16px;

    button {
      margin-top: 8px;
    }
  }

  .content-section {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }

  .form-group {
    width: 100%;
    margin-bottom: 0px;

    label {
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
      gap: 8px;

      font-weight: 500;
      line-height: 24px;

      .label {
        display: flex;
        gap: 8px;
        align-items: center;

        font-size: 14px;
      }

      input,
      select {
        padding: 8px 16px;

        border: 2px solid $borderGrey;
        border-radius: 8px;
        font-size: 16px;
      }
    }
  }

  .dropdown-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 8px;
    gap: 8px;
    width: 100%;

    .dropdown {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      line-height: 24px;
      padding: 8px 0;
      font-weight: 500;
      cursor: pointer;
    }
  }

  .content-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 16px;
    border-bottom: 2px solid $lineGrey;
    overflow-x: hidden;
  }

  .description-container {
    display: flex;
    flex-direction: column;
    padding: 12px 2px;
    gap: 8px;

    .description-item {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: $primaryText;

      a {
        color: $primaryText;
      }

      span {
        color: $secondaryText;
        font-weight: 400;
      }
    }
  }

  .avatar-section {
    display: flex;
    align-items: center;
    gap: 16px;
    overflow-x: hidden;
    margin-bottom: 16px;

    .avatar-container {
      min-width: 80px;
      min-height: 80px;

      img {
        width: 80px;
        background-color: $fillGrey;
      }

      i {
        font-size: 80px;
      }
    }

    .avatar-data {
      display: flex;
      flex-direction: column;
      gap: 8px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;

      .name {
        color: $primaryText;

        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .email {
        color: $secondaryText;
      }
    }
  }
</style>
