<template>
  <div>
    <div class="btn reset-button">
      <div class="dropdown">
        <i
          class="material-icons align-middle more-buttons dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          more_horiz
        </i>
        <div class="dropdown-menu dropdown-menu-right">
          <button type="button" class="dropdown-item" @click="showModal = true">
            <i class="material-icons align-middle">restore</i>
            Reset Widget
          </button>
        </div>
      </div>
    </div>

    <Modal
      @closeModal="closeModal"
      v-show="showModal"
      title="Remove Changes"
      :okFunction="deleteOverride"
    >
      <template v-slot:body>
        <div class="delete-description">
          Proceeding will reset the widget to its default state and remove all its overrides. Are
          you sure you want to continue?
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
  import Modal from '@/components/common/Modal.vue';

  export default {
    name: 'DeleteOverrideButton',

    components: { Modal },

    data() {
      return {
        showModal: false,
      };
    },

    methods: {
      async deleteOverride() {
        this.$emit('deleteOverride');
        this.closeModal(true);
      },

      closeModal() {
        this.showModal = false;
        this.$emit('closeModal');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .reset-button {
    &:hover {
      color: $primaryRed;
    }
  }
</style>
