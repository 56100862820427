module.exports = {
  welcome: 'Willkommen',
  to: 'zu',
  activities_title : 'Aktivitäten',
  courts_title: 'Gerichte',
  book_with: 'Buchen Sie mit',
  members: 'Mitglieder',
  playing_now: 'Spielt gerade',
  vacant_time: 'Ledig bana',
  vacant_times: 'Lediga banor',
  tracks: 'Spuren',
  offers_for_you: 'Angebote für Sie',
  contact_us: 'Kontaktieren Sie uns an der Rezeption für Informationen',
  available_time: 'Verfügbare Zeit',

  monday: 'Montag',
  tuesday: 'Dienstag',
  wednesday: 'Mittwoch',
  thursday: 'Donnerstag',
  friday: 'Freitag',
  saturday: 'Samstag',
  sunday: 'Sonntag',

  game_strength: 'Spielstärke',
  players: 'Spieler',

  app_description: 'Spielst du Tennis, Badminton, Tischtennis, Squash oder Paddel? Werden Sie Mitglied bei Matchi und gestalten Sie Ihren Alltag flexibler.',
};
