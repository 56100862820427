<template>
  <Modal
    @closeModal="closeModal"
    v-show="showModal"
    :isSaving="isSaving"
    :disabled="disableCreateButton"
    title="New Layout"
    :okFunction="createLayout"
    okButtonLabel="Create Layout"
    :modalStyles="{ width: '90vw', height: '90vh' }"
  >
    <template v-slot:body>
      <div class="main-container">
        <div class="cards-container scrollbar">
          <LayoutCard
            v-for="(layout, index) in [newLayout, ...layoutTemplates]"
            :key="index"
            :layout="layout"
            :selected="selectedLayout?.id === layout.id"
            @layoutSelected="selectedLayout = layout"
          />
        </div>
        <div class="preview-container">
          <div
            v-if="selectedLayout?.id !== 'new'"
            class="preview-content"
            :style="{
              aspectRatio: currentAspectRatio,
              width: previewWidth,
              height: previewHeight,
            }"
          >
            <div v-if="isLoadingChildren" class="loader">
              <div class="loader-background">
                <Loader />
              </div>
            </div>
            <Layout v-else-if="!!selectedLayout" :layout="selectedLayout" />
          </div>

          <div class="preview-footer"></div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
  import _ from 'lodash';

  import LayoutCard from '@/components/layouts/LayoutCard.vue';
  import Modal from '@/components/common/Modal.vue';
  import Layout from '@/components/player/Layout.vue';
  import Loader from '@/components/common/Loader.vue';

  import { emptyLayoutImage } from '@/models/layoutDesigner/templates';
  import { DEFAULT_LAYOUT_ASPECT_RATIO, generateDefaultLayoutData } from '@/models/layoutDesigner';

  import {
    PLAYER_LOAD_LAYOUT_CHILDREN,
    PLAYER_SET_LAYOUT,
    SET_LAYOUT_CHILDREN,
  } from '@/store/actions/player';
  import { CREATE_LAYOUT } from '@/store/actions/layoutDesigner';

  import { apiCreateLayoutFromTemplate, apiReadLayouts } from '@/api/layouts';

  export default {
    name: 'AddLayoutModal',

    components: {
      LayoutCard,
      Modal,
      Layout,
      Loader,
    },

    data() {
      return {
        layoutTemplates: [],

        showModal: false,
        isSaving: false,
        selectedLayout: null,
        aspectRatio: DEFAULT_LAYOUT_ASPECT_RATIO,
        isLoadingChildren: false,
        newLayout: {
          ...generateDefaultLayoutData('New Layout'),
          id: 'new',
          image: require('@/assets/img/plus.svg'),
          class: { 'new-card': true },
        },
      };
    },

    async created() {
      this.showModal = true;

      this.getLayouts();
    },

    watch: {
      selectedLayout() {
        if (this.selectedLayout.id === 'new') return;

        this.$store.commit(SET_LAYOUT_CHILDREN, {});
        this.getWidgets();
      },
    },

    computed: {
      disableCreateButton() {
        return !this.selectedLayout;
      },

      currentAspectRatio() {
        return this.$store.getters.getAspectRatio;
      },

      previewWidth() {
        const aspectRatio = this.aspectRatio.split(':');

        return !this.selectedLayout?.settings.isHorizontal
          ? `${(350 * aspectRatio[1]) / aspectRatio[0]}px`
          : '100%';
      },

      previewHeight() {
        const aspectRatio = this.aspectRatio.split(':');

        return this.selectedLayout?.settings.isHorizontal
          ? `${(350 * aspectRatio[1]) / aspectRatio[0]}px`
          : '350px';
      },
    },

    methods: {
      async getLayouts() {
        this.isLoading = true;

        try {
          const response = await apiReadLayouts({}, true);
          let layouts = _.orderBy(response?.data?.items, 'created', ['desc']);

          if (!layouts) {
            this.$toasted.global.general_error({ message: 'Error Loading Layouts' });
          }

          this.layoutTemplates = layouts.map((layout) => ({
            id: layout.layout_id,
            layout_id: layout.layout_id,
            name: layout.settings.name,
            image: layout.settings.image || emptyLayoutImage,
            settings: layout.settings,
          }));
        } catch (error) {
          console.log(error);
          this.$toasted.global.general_error({
            message:
              error.response?.data.message || 'Error getting layout templates. Please try again',
          });

          this.closeModal();
        }

        this.isLoading = false;
      },

      async getWidgets() {
        if (!this.selectedLayout) return;

        this.isLoadingChildren = true;
        this.$store.commit(PLAYER_SET_LAYOUT, this.selectedLayout);

        await this.$store.dispatch(PLAYER_LOAD_LAYOUT_CHILDREN, {
          layoutId: this.selectedLayout?.layout_id,
        });

        this.isLoadingChildren = false;
      },

      async createLayout() {
        this.isSaving = true;

        try {
          let response = null;

          if (this.selectedLayout.id === 'new') {
            const { name, resolution, isHorizontal, aspectRatio } = this.selectedLayout;

            const layoutData = {
              name,
              image: emptyLayoutImage,
              resolution,
              isHorizontal,
              aspectRatio,
            };

            response = await this.$store.dispatch(CREATE_LAYOUT, { layoutData });
          } else {
            response = await apiCreateLayoutFromTemplate(this.selectedLayout.layout_id);
          }

          if (response) {
            this.$router.push({
              name: 'Layout',
              params: { layout_id: response.layout_id || response.data.layout_id },
            });

            this.closeModal();
          }
        } catch (error) {
          this.$toasted.global.general_error({
            message: String(error),
          });
        }

        this.isSaving = false;
      },

      closeModal() {
        this.showModal = false;
        this.$emit('closeModal');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .cards-container {
    display: flex;
    flex-wrap: wrap;
    align-self: flex-start;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    margin: 16px 0 16px 16px;
    padding: 12px;
    overflow-y: auto;

    .layout-card {
      border-color: $lineGrey;
    }
  }

  .form-group {
    width: 50%;
    margin-top: 24px;

    label {
      display: flex;
      flex-direction: column;
      margin-bottom: 8px;
      gap: 8px;

      font-weight: 500;
      line-height: 24px;
      font-size: 16px;

      input {
        padding: 8px 16px;

        border: 2px solid $borderGrey;
        border-radius: 8px;
        font-size: 16px;
      }
    }
  }

  .main-container {
    display: grid;
    grid-template-columns: 1fr 350px;
    height: 100%;
    overflow: hidden;
  }

  .preview-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    height: 100%;
    padding: 16px 4px 16px 32px;
    background-color: white;

    .preview-content {
      min-height: 205px;
      max-height: 350px;
      background-color: $fillGrey;
      position: relative;
      cursor: pointer;
      overflow: hidden;
      box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
    }

    .preview-footer {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      i {
        color: $secondaryText;

        &:hover {
          color: $primaryText;
        }
      }
    }
  }

  .loader {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;
    width: 100%;

    .loader-background {
      background-color: rgba(0, 0, 0, 0.25);

      border-radius: 10px;

      height: min(100px, 80%);
      width: min(100px, 80%);
    }
  }
</style>
