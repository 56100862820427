<template>
  <button
    :class="['ui-button', `ui-button-${theme} ui-button-${size} ui-button-${variant}`]"
    :disabled="disabled || loading"
    :title="title"
    @click="$emit('click')"
  >
    <i v-if="icon && !loading" class="material-icons-outlined align-middle icon">{{ icon }}</i>
    <span v-if="loading" class="loading">
      <i class="material-icons-outlined align-middle icon spin" v-if="!loadingText">autorenew</i>
      <span v-if="loadingText">{{ loadingText }}</span>
    </span>
    <span class="text"><slot></slot></span>
  </button>
</template>

<script>
  export default {
    name: 'BaseButton',
    emits: ['click'],
    props: {
      icon: {
        type: String,
        default: null,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      loadingText: {
        type: String,
        default: null,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      title: {
        type: String,
        default: null,
      },
      size: {
        type: String,
        default: 'md',
        validator: (value) => ['sm', 'md', 'full'].includes(value),
      },
      theme: {
        type: String,
        default: 'primary',
        validator: (value) => ['primary', 'secondary', 'grey'].includes(value),
      },
      variant: {
        type: String,
        default: 'solid',
        validator: (value) => ['solid', 'outline'].includes(value),
      },
    },
  };
</script>

<style lang="scss" scoped>
  .ui-button {
    padding: 6px 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;

    border-radius: 8px;
    border: 2px solid transparent;

    transition: all 0.2s ease-in-out;

    .text {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: inherit;
    }

    .icon {
      font-size: 20px;
      color: inherit;
    }
    .loading {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: inherit;

      .spin {
        animation: spin 1s linear infinite;
      }
    }

    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }

    // Primary
    &.ui-button-primary {
      color: white;
      background: #e3003a;
      &:not(:disabled):hover {
        border-color: #ae002b;
      }

      &.ui-button-outline {
        background: transparent;
        color: #e3003a;
        border-color: #e3003a;
        &:not(:disabled):hover {
          background: #e3003a;
          color: white;
        }
      }
    }

    // Secondary
    &.ui-button-secondary {
      background-color: white;
      border: 2px solid #d4d4d4;
      color: #6a6b6a;

      &:not(:disabled):hover {
        border-color: #afafaf;
        color: #545454;
      }
    }

    &.ui-button-grey {
      background-color: $backgroundGrey2;
      border: none;
      color: $tableText;
      outline: none;

      &:not(:disabled):hover {
        color: $primaryRed;
      }
    }

    // Small
    &.ui-button-sm {
      padding: 4px 32px;
      .text {
        font-size: 14px;
        line-height: 20px;
      }
      .loading {
        line-height: 20px;
      }
    }

    // full width
    &.ui-button-full {
      width: 100%;
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>
