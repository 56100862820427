<template>
  <Modal
    @closeModal="closeModal"
    v-show="showModal"
    :isLoading="isLoading"
    title="Delete Confirmation"
    :okFunction="deleteLicense"
  >
    <template v-slot:body>
      <div class="delete-description">Are you sure you want to delete this license?</div>
    </template>
  </Modal>
</template>

<script>
  import Modal from '@/components/common/Modal.vue';
  import { deleteLicenses } from '@/api/organisation';
  import { ADMIN_ORGANISATIONS_GET } from '@/store/actions/organisation';

  export default {
    name: 'DeleteLicenseModal',

    components: { Modal },

    props: {
      license: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        showModal: false,
        isLoading: false,
      };
    },

    mounted() {
      this.showModal = true;
    },

    methods: {
      async deleteLicense() {
        this.isLoading = true;

        try {
          await deleteLicenses(this.license.organisation, [this.license.id]);
          this.$toasted.global.general_success({ message: 'License removed successfully' });
          this.$emit('deleted', this.license.id);
          await this.$store.dispatch(ADMIN_ORGANISATIONS_GET);
          this.closeModal(true);
        } catch {
          this.$toasted.global.general_error({
            message: 'Failed to delete the license',
          });
        }
        this.isLoading = false;
      },

      closeModal() {
        this.showModal = false;
        this.$emit('closeModal');
      },
    },
  };
</script>
