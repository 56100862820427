<template>
  <vue-draggable-resizable
    v-if="!hideParent"
    :parent="true"
    :x="position.left"
    :y="position.top"
    :w="position.width"
    :h="position.height"
    :draggable="!isSavingLayout && !isWidgetLocked"
    :resizable="!isSavingLayout && !isWidgetLocked"
    :grid="gridScale"
    :snap="isSnapActive"
    :active="isWidgetSelected"
    :z="(isWidgetSelected && keepZIndexUp ? 1000 : 100) + widget.position[rotation].zIndex"
    :onDragStart="(x, y) => onChangePosition(true, x, y)"
    :onResizeStart="() => onChangePosition(false)"
    @dragstop="onWidgetDrag"
    @resizestop="onWidgetResize"
    @activated="() => onSelected(widget)"
    @deactivated="() => (keepZIndexUp = false)"
    @refLineParams="getRefLineParams"
  >
    <div class="image-container" :style="style">
      <div class="button-layer">
        <div class="button-container">
          <i class="material-icons align-middle"> play_arrow </i>
        </div>
      </div>
      <img
        class="image-widget"
        :src="widget.position.data.thumbnail_url"
        :alt="widget.position.name"
        :style="imageStyle"
      />
    </div>
  </vue-draggable-resizable>
</template>

<script>
  import VueDraggableResizable from '@/components/common/VueDraggableResizable.vue';

  import playerMixins from '@/models/player';
  import draggableWidget from '@/components/layoutDesigner/mixins/draggableWidget';

  import { simpleTypeMixin } from '@/helpers';

  export default {
    name: 'VideoWidget',

    mixins: [playerMixins, simpleTypeMixin, draggableWidget],

    components: {
      VueDraggableResizable,
    },

    props: {
      widget: {
        type: Object,
        default: () => ({}),
      },
    },

    computed: {
      style() {
        return {
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
        };
      },

      imageStyle() {
        return {
          objectFit: this.widget.position.data.imageMode || 'cover',
        };
      },

      position() {
        return this.getPosition(this.widget);
      },
    },

    methods: {
      showImage() {
        //this.$emit('show-enlarged', this.widget)
      },
    },
  };
</script>

<style lang="scss" scoped>
  .image-container {
    background-color: $fillGrey;
    position: relative;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }

    .button-layer {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
      width: 100%;
      height: 100%;

      .button-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 64px;
        height: 64px;

        border-radius: 100%;
        background: rgba(255, 255, 255, 0.45);
        border: 2px solid rgba(255, 255, 255, 0.15);

        i {
          font-size: 32px;
          color: $primaryRed;
        }
      }
    }
  }
</style>
