export interface RichText {
  DefaultFontSize: number;
  DefaultFontColor: string;
  DefaultFontFamily: string;
  DefaultLineHeight: number;
  DefaultLetterSpacing: number;
}

export const RICH_TEXT: RichText = {
  DefaultFontSize: 18, // px
  DefaultFontColor: '#000000',
  DefaultFontFamily: 'Arial',
  DefaultLineHeight: 1.2,
  DefaultLetterSpacing: 0, // percent
} as const;
