<template>
  <Modal
    @closeModal="closeModal"
    :isSaving="isLoading"
    title="Reconnect Screen"
    :okButtonLabel="codeActionButtonLabel"
    :disabled="!isActionButtonActive"
    :okFunction="handleCodeAction"
    cancelButtonLabel="Close"
    class="regenerate-code-modal"
  >
    <template v-slot:body>
      <div class="screen-code-generated">
        <BaseText class="screen-code-content-text">
          <img class="icon" src="@/assets/icon/warning.svg" alt="Warning" />
          {{ SCREEN_CODE_REGENERATION_WARNING_MESSAGE }}
        </BaseText>
        <ScreenCodeTabs
          @tab:tabChange="handleTabChange"
          ref="screenCodeTabs"
          :screenId="screenId"
        />
      </div>
    </template>
  </Modal>
</template>

<script>
  import Modal from '@/components/common/Modal.vue';
  import { BaseText } from '../common/ui/atoms/baseText';
  import {
    SCREEN_CODE_USE_MESSAGE,
    SCREEN_CODE_REGENERATION_WARNING_MESSAGE,
    GENERATE_CODE_LABEL,
    CONNECT_SCREEN_LABEL,
  } from '@/constant/screens';
  import ScreenCodeTabs from '@/components/screens/ScreenCodeTabs';

  export default {
    name: 'RegenerateScreenCodeModal',
    components: { Modal, BaseText, ScreenCodeTabs },

    props: {
      screenId: {
        type: String | Number,
        default: '',
      },
    },

    data() {
      return {
        isLoading: false,
        SCREEN_CODE_USE_MESSAGE,
        SCREEN_CODE_REGENERATION_WARNING_MESSAGE,
        isGenerateTab: true,
        isActionButtonActive: true,
        codeActionButtonLabel: GENERATE_CODE_LABEL,
      };
    },

    methods: {
      closeModal() {
        this.$emit('close-regenerate-screen-code-modal');
      },

      handleTabChange(isGenerateTab) {
        this.isGenerateTab = isGenerateTab;
        isGenerateTab
          ? (this.codeActionButtonLabel = GENERATE_CODE_LABEL)
          : (this.codeActionButtonLabel = CONNECT_SCREEN_LABEL);
      },

      async handleCodeAction() {
        const isSuccessful = await this.$refs.screenCodeTabs.actionClicked();
        if (isSuccessful) {
          this.isActionButtonActive = false;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .regenerate-code-modal {
    ::v-deep button {
      padding: 10px 12px !important;
      min-width: 50px !important;
    }
  }
</style>
