<template>
  <div>
    <div class="modal fade overflow-auto" :class="open ? 'show d-block' : ''" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content shadow-lg">
          <div class="px-3">
            <div class="modal-header">
              <h5 class="modal-title">Create Tenants Widget</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.prevent="closeModal">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
          <div class="modal-body">
            <TableContent v-if="currentStep === 2"
                          :rows="rows" :columns="columns"
                          @onSave="updateContent" ref="contents" />
            <TableSchedule v-else
                           @onChange="syncSchedulerData"
                           @onSave="updateScheduler" ref="scheduler"
                           :default-data="{name, folder, schedule}" />
          </div>
          <div class="modal-footer justify-content-between align-items-center">
            <p class="my-0 mr-3">Step: {{ currentStep }}/{{ totalSteps }}</p>
            <div class="d-flex">
              <span class="btn btn-sm bg-white border cursor-pointer mr-2 px-4 rounded-xl" v-show="currentStep === 2" @click="closeModal">Close</span>
              <span class="btn btn-sm bg-primary-red border cursor-pointer text-light px-4 rounded-xl mr-2" @click="prevStep" v-show="currentStep > 2">&larr; Previous</span>
              <span class="btn btn-sm bg-primary-red border cursor-pointer text-light px-4 rounded-xl" @click="updateCurrentStep" v-show="currentStep < totalSteps">Next &rarr;</span>
              <span class="btn btn-sm bg-success border cursor-pointer text-light px-4 rounded-xl" @click="updateCurrentStep" v-show="currentStep >= totalSteps">Save</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show" v-if="open"></div>
  </div>
</template>

<script>
import TableSchedule from "@/components/apps/table/TableSchedule.vue";
import TableContent from "@/components/apps/table/TableContent.vue";

export default {
  name: "TableSettingsModal",
  components: {TableContent, TableSchedule},
  props: {
    open: {
      type: Boolean,
      default: false
    },
    settingData: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      currentStep: 2,
      totalSteps: 3,
      schedule: null,
      name: null,
      folder: null,
      columns: null,
      rows: null
    }
  },

  created() {
    if(this.settingData) {
      this.columns = this.settingData?.columns;
      this.rows = this.settingData?.rows;
    }
  },

  methods: {
    updateContent({columns, rows}) {
      this.columns = columns;
      this.rows = rows;
      this.nextStep();
    },
    syncSchedulerData({name, folder, schedule}) {
      this.schedule = schedule;
      this.name = name;
      this.folder = folder;
    },
    updateScheduler(appData) {
      this.syncSchedulerData(appData);
      const data = {
        schedule: this.schedule,
        name: this.name,
        folder: this.folder,
        columns: this.columns,
        rows: this.rows
      }
      this.$emit('onSave', data);
    },
    updateCurrentStep() {
      switch (this.currentStep) {
        case 2:
          this.$refs.contents.save();
          break;
        default:
          this.$refs.scheduler.save();
      }
    },
    closeModal() {
      this.$emit('onClose');
      this.reset();
    },
    reset() {
      this.currentStep = 2;
      this.schedule = null
      this.name = null;
      this.folder = null;
      this.columns = null;
      this.rows = null;
    },
    nextStep() {
      this.currentStep = Math.min(this.currentStep + 1, this.totalSteps);
    },
    prevStep() {
      this.currentStep = Math.max(this.currentStep - 1, 2);
    }
  }
}
</script>

<style scoped>

</style>
