<script lang="ts">
  export default {
    name: 'CodeInput',
  };
</script>

<script setup lang="ts">
  import { ref, nextTick, defineProps, defineEmits, onMounted, computed } from 'vue';
  import { isSingleDigit } from '@/helpers/utils';

  const props = defineProps<{
    length: number;
    codeValue?: string;
    isEditable: Boolean;
  }>();

  const emit = defineEmits<{
    (e: 'update:code', activationCode: string): void;
  }>();

  const code = computed<Array<string>>(() => {
    return props.codeValue?.split('') || Array(props.length).fill('');
  });

  const inputs = ref<HTMLInputElement[]>([]);

  function handleInput(index: number) {
    const inputVal = code.value[index];
    if (isSingleDigit(inputVal)) {
      if (index < props.length - 1) {
        nextTick(() => {
          inputs.value[index + 1].focus();
        });
      }
      emit('update:code', code.value.join(''));
    } else {
      code.value[index] = '';
    }
  }

  function handleBackspace(index: number, event: KeyboardEvent) {
    if ((event.target as HTMLInputElement).value === '' && index > 0) {
      nextTick(() => {
        inputs.value[index - 1].focus();
      });
    }
  }

  function isNumber(evt: KeyboardEvent) {
    const char = evt.key;
    if (!isSingleDigit(char)) {
      evt.preventDefault();
    } else {
      return true;
    }
  }
</script>

<template>
  <div class="code-input">
    <input
      v-for="(value, index) in code"
      :key="index"
      type="text"
      maxlength="1"
      v-model="code[index]"
      @keydown.backspace="handleBackspace(index, $event)"
      @keypress="isNumber($event)"
      @input="handleInput(index)"
      ref="inputs"
      :disabled="!isEditable"
    />
  </div>
</template>

<style scoped lang="scss">
  .code-input {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: center;
    input {
      width: 75px;
      height: 100px;
      border: 2px solid #d1d1d1;
      text-align: center;
      font-size: 3rem;
      font-weight: 700;
      line-height: 4rem;
      border-radius: 8px;
    }
  }
</style>
