<template>
  <div id="root-layer" class="web-player">
    <div class="initializing-overlay text-center pt-5" v-show="isLoading || isRendering">
      <h1>Initializing playlist. Please wait...</h1>
    </div>

    <div class="initializing-overlay text-center pt-5" v-show="unauthorizedScreen">
      <div>
        <h1>Unable to connect this screen to the playlist you are trying to load.</h1>
        <div>
          <p>Detach the already attached screen to this playlist and reload this page.</p>
          <button @click.prevent="handleLinkReload" class="btn btn-warning">Reload</button>
        </div>
      </div>
    </div>

    <div id="matchi-playlist-player" :style="playlistStyling">
      <alert
        v-if="playlistItemRequestStatus === 'error'"
        class="alert-danger"
        :message="`Misslyckades att hämta spellista: ${playlistItemRequestError}`"
      ></alert>

      <div
        id="matchiDashboard"
        v-show="!isLoading && matchiEnabled && currentState === 'show-matchi'"
        :class="[
          'bg-matchi-playlist',
          `bg-matchi-playlist-${this.playlistMatchiSettings.color_theme}`,
        ]"
      >
        <MatchiContainer
          :time="currentTime"
          :playlistMatchiFacility="playlistMatchiFacility"
          :playlistMatchiSlots="playlistMatchiSlots"
          :playlistId="playlistId"
          :bookedCourts="bookedCourts"
          :getPlayerNames="getPlayerNames"
          :playlistMatchiSettings="playlistMatchiSettings"
          :validOffers="validOffers"
          :validActivities="validActivities"
          :currentState="currentState"
          :playListLength="playlistItems.length"
          :isLoading="isLoading"
          :timezone="playlistMatchiFacility.timezone"
          v-if="!isLoading && validActivities"
        />
      </div>

      <div
        :class="[
          'm-0 p-0 w-100 h-100 playlist-items-overlay playlist-widget-layer',
          !isLoading && currentState === 'show-playlist-item' ? inTransition : outTransition,
        ]"
        id="playlist-player"
        v-show="playlistItems && !isLoading && currentState === 'show-playlist-item'"
      >
        <transition-group
          :name="enableTransitionAnimations ? getPlaylistTransitionClass() : 'empty'"
        >
          <div
            :class="['playlist-item-container', { 'current-slide': index === slideCurrentIndex }]"
            v-for="(currentSlide, index) in playlistItems"
            :key="currentSlide.item_id"
            v-show="index === slideCurrentIndex"
          >
            <div
              :class="['playlist-item-image', pictureMode]"
              v-if="getSimpleType(currentSlide.item_type) === 'image'"
              :style="{
                backgroundImage: `url('${getEncodedURL(baseUrl, currentSlide.item_url)}')`,
              }"
            ></div>

            <div v-if="getSimpleType(currentSlide.item_type) === 'video'" class="video-container">
              <video
                loop
                :muted="isMuted(currentSlide)"
                :ref="`playlist_video_${index}`"
                :class="['playlist-item-video', pictureMode]"
              >
                <source
                  :src="getEncodedURL(baseUrl, currentSlide.item_url)"
                  :type="currentSlide.item_type"
                />
              </video>
            </div>

            <div v-if="currentSlide.item_type === 'app/rss'" class="playlist-item-img">
              <rss-viewer
                :setting="getRSSAppSettings(currentSlide.app)"
                :key="slideCurrentUKey"
              ></rss-viewer>
            </div>

            <div v-if="currentSlide.item_type === 'app/table'" class="playlist-item-img">
              <table-app-web-view :settings="currentSlide.app" :key="slideCurrentUKey" />
            </div>

            <div
              v-if="currentSlide.item_type === 'app/trafiklab'"
              class="matchi-playlist-item-trafiklab"
            >
              <TimeTableViewer
                :showAfterRow="currentSlide.app.overall.show"
                class="p-0 m-0"
                :viewerStyles="currentSlide.app.colours"
                :stops="currentSlide.app.timetableRows"
                alt="Trafiklab preview"
              />
            </div>

            <div
              v-if="currentSlide.item_type === 'youtube'"
              class="embed-responsive embed-responsive-16by9"
            >
              <iframe
                class="embed-responsive-item"
                :src="getYoutubeUrl(currentSlide)"
                allowfullscreen
                scrolling="no"
              ></iframe>
            </div>
          </div>
        </transition-group>
        <widget-node
          v-if="playlistRoot && playlistRoot.data.wid"
          @show-enlarged="showEnlarged"
          :node="playlistRoot"
        ></widget-node>
      </div>
    </div>

    <widget-node
      @show-enlarged="showEnlarged"
      v-if="widgetTree"
      v-for="node in widgetTree.children"
      :key="node.id"
      :node="node"
    ></widget-node>

    <div
      class="modal content-media-modal"
      id="contentMediaModal"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        v-if="contentModalCloseBtn"
        class="content-media-modal-close-btn"
        :style="getContentCloseButtonStyle(contentModalCloseBtn)"
        @click="closeContentMediaModal"
      >
        <div class="close-btn-text-wrapper">
          <div class="material-icons">close</div>
        </div>
      </div>
      <div class="modal-dialog modal-full h-100 w-100" role="document">
        <div
          v-if="contentType === 'image'"
          class="content-image"
          :style="{
            backgroundImage: `url('${contentLink}')`,
          }"
        ></div>

        <div v-if="contentType === 'video'" class="video-container">
          <video autoplay loop :muted="true" class="video">
            <source :src="contentLink" :type="contentObj.mime" />
          </video>
        </div>
      </div>
    </div>

    <div v-if="openWebPage" class="content-iframe">
      <div
        v-if="contentModalCloseBtn"
        class="content-media-modal-close-btn"
        :style="getContentCloseButtonStyle(contentModalCloseBtn)"
        @click.prevent.stop="closeContentMediaModal"
      >
        <div class="close-btn-text-wrapper">
          <div class="material-icons">close</div>
        </div>
      </div>

      <iframe :src="contentLink" frameborder="0"></iframe>
    </div>

    <!--        <div class="version-tag">Ver 1.0.1</div>-->
  </div>
</template>

<script>
  import textFit from 'textfit/textFit';
  import { mapState } from 'vuex';
  import { orderBy } from 'natural-orderby';

  import TimeTableViewer from '@/components/common/timetable/TimeTableViewer.vue';

  import _ from 'lodash';
  import {
    PLAYLIST_ATTACH_SCREEN,
    PLAYLIST_CLEAR_LINK,
    GET_PLAYLIST_ITEMS,
    PLAYLIST_MATCHI_ACTIVITIES,
    PLAYLIST_MATCHI_EVENTS,
    PLAYLIST_MATCHI_FACILITY_REQUEST,
    PLAYLIST_MATCHI_OFFERS,
    PLAYLIST_MATCHI_REQUEST,
    PLAYLIST_MATCHI_SLOTS_REQUEST,
    PLAYLIST_RELOAD_CHECK,
    PLAYLIST_UNSET_BROWSER_REFRESH_FLAG,
    PLAYLIST_UNSET_RELOAD_FLAG,
  } from '../store/actions/playlist';

  import config from '../config';
  import LoadingSpinner from './LoadingSpinner.vue';
  import Alert from './Alert.vue';
  import RssViewer from './rssViewer/Index';
  import TableAppWebView from './tableApp/TableAppWebView';
  import { simpleTypeMixin } from '../helpers';
  import WidgetNode from './playerWidgets/WidgetNode';
  import MatchiContainer from './matchiPages/MatchiContainer';
  import EventBus from '../models/EventBus';
  import { getRBGAColor } from '@/helpers/utils';

  const moment = require('moment-timezone');

  const $ = window.jQuery;
  const TIME_24H = 'HH:mm';

  const PlaylistState = {
    SHOW_PLAYLIST: 'show-playlist-item',
    SHOW_MATCHI: 'show-matchi',
  };

  export default {
    name: 'MatchiPlaylistPlayer',

    mixins: [simpleTypeMixin],

    components: {
      LoadingSpinner,
      Alert,
      RssViewer,
      TimeTableViewer,
      TableAppWebView,
      WidgetNode,
      MatchiContainer,
    },

    props: {
      playlist: {
        type: String,
        default: '',
      },
    },

    data() {
      return {
        showLocation: false,
        slideCurrentIndex: null,
        slideCurrentUKey: Date.now(),
        selectedVideo: null,
        slideStartTime: new Date().getTime(),
        slideIndex: 0,
        reloadItems: false,
        isLoading: true,
        isMatchiSlotsLoading: true,
        isMatchiFacilityLoading: true,

        currentTime: moment().tz(config.timezone).format(TIME_24H),
        availableCourts: [],
        bookedCourts: [],
        matchiCoolDownPeriod: 10000,
        slideCoolDownPeriod: null,
        currentTick: 0,
        currentState: PlaylistState.SHOW_PLAYLIST,
        matchiEnabled: null,
        advTransitionImminent: false,

        reloadTimer: null,
        scheduleUpdateTimer: null,
        matchiSlotsUpdateTimer: null,
        matchiEventsUpdateTimer: null,
        matchiOffersUpdateTimer: null,
        matchiActivitiesUpdateTimer: null,
        matchiFacilityUpdateTimer: null,
        baseTimer: null,

        enableTransitionAnimations: false,
        enableSidebar: true,
        enableAvailableCourts: false,
        enableAvailableCourtsCard: false,
        enableEvents: true,
        enableActivities: true,
        canShowFourSlots: false,
        enableOffers: true,

        courtsColumnStyling: 'col-3',

        playlistStyling: {
          height: '100vh',
          width: '100vw',
          position: 'absolute',
          left: '0%',
          top: '0%',
        },
        playlistLayout: {},
        playlistData: {},

        contentLink: null,
        contentType: null,
        contentObj: {},
        contentModalCloseBtn: null,
        contentPopupModelInitialIdleTime: null,
        contentPopupModelCoolDown: 0,
        contentPopupModelTimer: null,
        contentPopupModelObj: null,

        widgetTree: [],
        playlistRoot: {
          id: 'playlist',
          data: {},
          children: [],
        },
        isRendering: true,

        pictureMode: 'stretch',
        loadedPlaylistData: {},
        openWebPage: false,
      };
    },

    computed: {
      ...mapState({
        playlistItemRequestStatus: (state) => state.playlist.playlistItemRequestStatus,
        playlistMatchiSlotsRequestStatus: (state) =>
          state.playlist.playlistMatchiSlotsRequestStatus,
        playlistItemRequestError: (state) => state.playlist.playlistItemRequestError,

        playlistItems: (state) =>
          state.playlist.playlistItems ? state.playlist.playlistItems : [],
        playlistMatchiSettings: (state) => state.playlist.playlistMatchiSettings,
        playlistMatchiFacility: (state) => state.playlist.playlistMatchiFacility,
        playlistMatchiSlots: (state) => state.playlist.playlistMatchiSlots,
        playlistMatchiEvents: (state) => state.playlist.playlistMatchiEvents,
        playlistMatchiOffers: (state) => state.playlist.playlistMatchiOffers,
        playlistMatchiActivities: (state) => state.playlist.playlistMatchiActivities,
        playlistMatchiOffersRequestStatus: (state) =>
          state.playlist.playlistMatchiOffersRequestStatus,
        playlistScreenLinked: (state) => state.playlist.playlistScreenLinked,
        playlistAttachStatus: (state) => state.playlist.playlistAttachStatus,
      }),

      validOffers() {
        return this.playlistMatchiOffers.offers.filter((offer) => {
          const endDate = moment(offer.endDate).tz(this.playlistMatchiFacility.timezone);
          const currentTime = moment(offer.endDate).tz(this.playlistMatchiFacility.timezone);
          return endDate.isSameOrAfter(currentTime);
        });
      },

      validActivities() {
        if (
          !this.playlistMatchiSettings.activities ||
          !this.playlistMatchiActivities ||
          !this.playlistMatchiActivities.activities
        ) {
          return [];
        }

        const validActivities = [];

        this.playlistMatchiActivities.activities.forEach((activity) => {
          const occasions = [];
          let firstDate = '';
          activity.occasions.forEach((occasion) => {
            if (occasion.registeredParticipants < occasion.maxParticipants) {
              if (!firstDate || firstDate === new Date(occasion.start).toDateString()) {
                occasions.push(occasion);
                if (!firstDate) firstDate = new Date(occasion.start).toDateString();
              }
            }
          });
          if (occasions.length) {
            validActivities.push({ ...activity, occasions });
          }
        });
        validActivities.sort(
          (a, b) =>
            new Date(a.occasions[0].start).getTime() - new Date(b.occasions[0].start).getTime(),
        );
        return validActivities;
      },

      baseUrl() {
        return config.baseUrl;
      },

      playlistId() {
        return !!this.playlist ? this.playlist : this.$route.params.playlist_id;
      },

      inTransition() {
        if (!this.enableTransitionAnimations) {
          return '';
        }
        if (this.playlistData.transition_mode) {
          return `${this.playlistData.transition_mode}-in`;
        }

        return 'slide-in';
      },

      outTransition() {
        if (!this.enableTransitionAnimations) {
          return '';
        }
        if (this.playlistData.transition_mode) {
          return `${this.playlistData.transition_mode}-out`;
        }

        return 'slide-out';
      },
      unauthorizedScreen() {
        return (
          !this.$store.getters.isAuthenticated &&
          this.playlistAttachStatus === 'error' &&
          !this.playlistScreenLinked
        );
      },
    },
    watch: {
      matchiEnabled(newVal, oldVal) {
        if (newVal !== oldVal && oldVal !== null) {
          this.reloadPlayer();
        }
      },

      currentState(val) {
        if (val === PlaylistState.SHOW_MATCHI && this.selectedVideo) {
          // window.logToScreen('pause prv video - current state change');
          this.selectedVideo.pause();
        }
      },

      slideCurrentIndex(val, oldVal) {
        const prevVideo =
          this.$refs[`playlist_video_${oldVal}`] !== undefined
            ? this.$refs[`playlist_video_${oldVal}`][0]
            : null;
        const currVideo =
          this.$refs[`playlist_video_${val}`] !== undefined
            ? this.$refs[`playlist_video_${val}`][0]
            : null;

        if (prevVideo) {
          // window.logToScreen('pause prv video');
          prevVideo.pause();
        }

        if (currVideo) {
          const vidData = this.playlistItems[val];
          currVideo.currentTime = 0;
          this.selectedVideo = currVideo;

          setTimeout(() => {
            // window.logToScreen('trigger play on current video');
            currVideo.play().then(() => {
              currVideo.muted = vidData.mute;
            });
          }, 200);
        }
      },
      unauthorizedScreen(val, oldVal) {
        if (!this.isLoading && val !== oldVal) {
          this.reloadWithCacheBusted();
        }
      },
    },
    methods: {
      getContentCloseButtonStyle(btnStyle) {
        return btnStyle;
      },

      getPlaylistTransitionClass() {
        if (this.playlistData.transition_mode) {
          return `slide-${this.playlistData.transition_mode}`;
        }

        return 'slide-fade';
      },

      isMuted(itemData) {
        if (this.isLoading && !itemData) {
          return true;
        }
        return itemData.mute;
      },

      getYoutubeUrl(itemData) {
        let url = itemData.item_url;
        if (this.isMuted(itemData)) {
          const urlParts = url.split('#');
          if (urlParts.length) {
            const urlQueryStrings = urlParts[0].split('?');
            if (urlQueryStrings.length > 1) {
              urlQueryStrings[1] += '&mute=1';
            } else {
              urlQueryStrings[0] += '?mute=1';
            }
            urlParts[0] = urlQueryStrings.join('?');

            url = urlParts.join('#');
          } else {
            url += '&mute=1';
          }
        }
        return url;
      },

      getDateObj(dateString = null) {
        const momentObj = dateString ? moment(dateString) : moment();
        // hard coding facility timezone to Europe/Zurich for the facility 1146
        // as for the client request on 28th Sept 2021 (via skype)
        let facilityTimezone = null;
        if (this.playlistMatchiFacility && this.playlistMatchiFacility.timezone) {
          facilityTimezone =
            this.playlistMatchiFacility.id === 1146
              ? 'Europe/Zurich'
              : this.playlistMatchiFacility.timezone;
        }
        return facilityTimezone ? momentObj.tz(facilityTimezone) : momentObj;
      },

      getPlayerNames(slot) {
        if (!slot.booked) {
          return this.$lang.player.available_time;
        }

        if (slot.booking.comments) {
          return slot.booking.comments;
        }

        if (slot.booking.type !== 'SUBSCRIPTION' && slot.booking.players.length) {
          return slot.booking.players[0];
        }

        return slot.booking.name;
      },

      getRSSAppSettings(appSettings) {
        return {
          rssLink: appSettings.link,
          rssDuration: parseInt(appSettings.duration, 10) || 5,
          rssTheme: appSettings.theme,
        };
      },

      getDateOnly(dateString) {
        return this.getDateObj(dateString).format('D/M');
      },

      getDateFull(dateString) {
        return this.getDateObj(dateString).format('YYYY-MM-DD');
      },

      getTime(dateString) {
        return this.getDateObj(dateString).format(TIME_24H);
      },

      formatTime(timeString) {
        return this.getDateObj(timeString).format(TIME_24H);
      },

      getNextPlaylistItem(nextSlideIndex) {
        if (nextSlideIndex > this.playlistItems.length - 1) {
          nextSlideIndex = 0;
        }

        if (nextSlideIndex === this.slideCurrentIndex) {
          return null;
        }

        return { data: this.playlistItems[nextSlideIndex], index: nextSlideIndex };
      },

      clockTick() {
        // set current time
        this.currentTime = this.getDateObj().format(TIME_24H);
        this.currentTick += 1000;

        if (this.currentState === PlaylistState.SHOW_MATCHI) {
          const coolDownDiff = this.matchiCoolDownPeriod - this.currentTick;
          if (coolDownDiff < 5000) {
            this.advTransitionImminent = true;
          }

          if (this.currentTick >= this.matchiCoolDownPeriod) {
            if (this.slideCurrentIndex === null) {
              if (this.showNextSlide(this.getNextPlaylistItem(0))) {
                this.switchToPlaylist();
              }
            } else if (this.showNextSlide(this.getNextPlaylistItem(this.slideCurrentIndex + 1))) {
              this.switchToPlaylist();
            }
          }
        } else if (this.currentTick >= this.slideCoolDownPeriod) {
          if (this.matchiEnabled) {
            const coolDownDiff = this.slideCoolDownPeriod - this.currentTick;
            if (coolDownDiff < 1000) {
              this.advTransitionImminent = false;
            }
            this.switchToMatchi();
          } else if (this.slideCurrentIndex === null) {
            this.showNextSlide(this.getNextPlaylistItem(0));
          } else {
            this.showNextSlide(this.getNextPlaylistItem(this.slideCurrentIndex + 1));
          }
        }
      },

      switchToPlaylist() {
        this.currentState = PlaylistState.SHOW_PLAYLIST;
        this.currentTick = 0;
      },

      switchToMatchi() {
        this.currentState = PlaylistState.SHOW_MATCHI;
        this.currentTick = 0;
        if (this.slideCurrentIndex === this.playlistItems.length - 1) {
          setTimeout(() => {
            this.slideCurrentIndex = null;
          }, 3);
        }
      },

      showNextSlide(nextPlaylistItem) {
        if (!nextPlaylistItem) {
          return false;
        }
        this.slideCurrentUKey = Date.now();
        if (nextPlaylistItem.data) {
          this.slideCoolDownPeriod = nextPlaylistItem.data.display_timer * 1000;
          this.slideCurrentIndex = nextPlaylistItem.index;
          this.currentTick = 0;
          return true;
        }

        return false;
      },

      processCourts(courts) {
        this.availableCourts = [];
        this.bookedCourts = [];
        this.canShowFourSlots = this.isPortrait() ? courts.length <= 8 : courts.length <= 12;
        for (const i in courts) {
          const court = JSON.parse(JSON.stringify(courts[i]));

          const sportTag = court.sport.type.toLowerCase();

          if (this.playlistMatchiSettings.sports_courts) {
            if (
              !this.playlistMatchiSettings.sports_courts[sportTag] ||
              !Array.isArray(this.playlistMatchiSettings.sports_courts[sportTag]) ||
              !this.playlistMatchiSettings.sports_courts[sportTag].includes(court.id)
            ) {
              continue;
            }
          } else {
            console.log(
              'Cannot find sports_courts object in the Matchi playlist settings',
              this.playlistMatchiSettings,
            );
          }

          const upcomingSlots = [];
          const availableSlots = [];
          const currentTime = this.getDateObj();

          for (const i in court.slots) {
            const slot = JSON.parse(JSON.stringify(court.slots[i]));
            const startTime = this.getDateObj(slot.start);
            const endTime = this.getDateObj(slot.end);
            court.availableSlots = [];

            if (startTime >= currentTime || (startTime < currentTime && endTime > currentTime)) {
              slot.start = startTime.format(TIME_24H);
              slot.end = endTime.format(TIME_24H);

              if (startTime < currentTime) {
                slot.active = true;
              }

              if (slot.booked && slot.booking && slot.booking.players) {
                const tmpPlayers = [];
                for (const pI in slot.booking.players) {
                  const playerName = slot.booking.players[pI];
                  if (playerName.match(/player/i)) {
                    continue;
                  }
                  tmpPlayers.push(playerName);
                }

                slot.booking.players = tmpPlayers;
              }

              upcomingSlots.push(slot);

              if (!slot.booked && availableSlots.length < 2) {
                // slot.start = startTime;
                // slot.end = endTime;
                availableSlots.push(slot);
              }
            }
            if (upcomingSlots.length >= 12) {
              break;
            }
          }

          if (upcomingSlots.length === 0) {
            continue;
          }

          court.slot1 = upcomingSlots[0] ? upcomingSlots[0] : null;
          court.slot2 = upcomingSlots[1] ? upcomingSlots[1] : null;
          court.slot3 = upcomingSlots[2] ? upcomingSlots[2] : null;
          court.slot4 = upcomingSlots[3] ? upcomingSlots[3] : null;

          court.upcomingSlots = upcomingSlots;

          this.bookedCourts.push(court);

          if (availableSlots.length) {
            court.availableSlots = availableSlots;
            this.availableCourts.push(court);
          }
        }

        this.bookedCourts = orderBy(this.bookedCourts, [(v) => v.name]);
        this.availableCourts = orderBy(this.availableCourts, [(v) => v.name]);
      },

      initPlaylistItems() {
        const _self = this;
        return this.loadPlaylistItems().then((res) => {
          _self.loadedPlaylistData = res.data;

          if (typeof res.data.playlist !== undefined) {
            _self.playlistData = res.data.playlist;
          }

          if (typeof res.data.layout !== undefined) {
            _self.playlistLayout = res.data.layout;
          }
          _self.enableTransitionAnimations = res.data.playlist.transition_animation;
        });
      },

      loadMatchiSlots() {
        const _self = this;
        return this.$store.dispatch(PLAYLIST_MATCHI_SLOTS_REQUEST, this.playlistId).then(() => {
          _self.processCourts(_self.playlistMatchiSlots.courts);
        });
      },

      loadMatchiEvents() {
        return this.$store.dispatch(PLAYLIST_MATCHI_EVENTS, this.playlistId);
      },

      loadMatchiOffers() {
        return this.$store.dispatch(PLAYLIST_MATCHI_OFFERS, this.playlistId);
      },

      loadMatchiActivities() {
        return this.$store.dispatch(PLAYLIST_MATCHI_ACTIVITIES, this.playlistId).then(() => {});
      },

      loadMatchiFacility() {
        return this.$store
          .dispatch(PLAYLIST_MATCHI_FACILITY_REQUEST, this.playlistId)
          .then(() => {});
      },

      loadMatchi() {
        const _self = this;
        return this.$store
          .dispatch(PLAYLIST_MATCHI_REQUEST, this.playlistId)
          .then(async (res) => {
            if (res.credentials_id) {
              _self.matchiCoolDownPeriod = res.cooldown_period
                ? parseInt(res.cooldown_period, 10) * 1000
                : 10000;
              _self.matchiEnabled = true;

              _self.enableOffers = !!res.offers;
              _self.enableEvents = !!res.events;
              _self.enableActivities = !!res.activities;

              if (!res.language) {
                _self.$lang.setLang('sw');
              } else {
                _self.$lang.setLang(res.language);
              }

              _self.isMatchiSlotsLoading = true;
              _self.isMatchiEventsLoading = true;
              await _self.loadMatchiFacility().then(() => {
                (_self.currentTime = moment()
                  .tz(_self.playlistMatchiFacility.timezone)
                  .format(TIME_24H)),
                  (_self.isMatchiFacilityLoading = false);
              });
              await _self.loadMatchiSlots().then(() => {
                _self.isMatchiSlotsLoading = false;
              });
              await _self.loadMatchiEvents().then(() => {
                _self.isMatchiEventsLoading = false;
              });

              await _self.loadMatchiOffers();
              await _self.loadMatchiActivities();

              _self.setMatchiSlotsUpdateTimeout();
              _self.setMatchiEventsUpdateTimeout();
              _self.setMatchiOffersUpdateTimeout();
              _self.setMatchiActivitiesUpdateTimeout();
              _self.setMatchiFacilityUpdateTimeout();
              _self.currentState = PlaylistState.SHOW_MATCHI;

              _self.$nextTick(_self.postMatchiProcessing);
            }
          })
          .catch((err) => {
            console.log('Setting Matchi disabled due to the error', err);
            _self.matchiEnabled = false;
            _self.switchToPlaylist();
          });
      },

      refreshCourtCardDOMs() {
        // Old browser fix to mimic css3 flex fill
        let maxHeight = -1;
        let maxHeaderHight = -1;
        // let multipleLines = false;
        // const refDom = $('.court-cards-collection .court-card-wrapper .court-card .header').first();
        // const headerFontSize = refDom.css('font-size');
        // const headerLineHeight = headerFontSize ? Math.floor(parseInt(headerFontSize.replace('px', '')) * 1.25) : 0;

        $('.court-cards-collection .court-card-wrapper .court-card .header').each((index, dom) => {
          const eleHeight = $(dom).height();
          if (eleHeight >= maxHeaderHight) {
            maxHeaderHight = eleHeight;
          }
        });

        // if (maxHeaderHight > (2 * headerLineHeight)) {
        //   multipleLines = true;
        // }

        $('.court-cards-collection .court-card-wrapper')
          .each((index, dom) => {
            $(dom).find('.header').height(maxHeaderHight);
            const eleHeight = $(dom).height();
            if (eleHeight >= maxHeight) {
              maxHeight = eleHeight;
            }
          })
          .height(maxHeight)
          .hide()
          .show();
      },

      setCourtElementHeights() {
        setTimeout(() => {
          this.refreshCourtCardDOMs();
          const screenHeight = $(window).height();
          const matchiRealEstate = this.isPortrait() ? screenHeight * 0.6 - 96 : screenHeight - 96;
          if ($('.court-cards-collection').outerHeight() > matchiRealEstate) {
            $('.court-cards-collection .court-card-wrapper').css({ height: 'auto' });
            this.canShowFourSlots = false;
          }

          setTimeout(this.refreshCourtCardDOMs, 100);
        }, 50);
      },

      loadPlaylistItems() {
        const requestArgs = {
          playlist_id: this.playlistId,
          params: {},
        };

        return this.$store.dispatch(GET_PLAYLIST_ITEMS, requestArgs);
      },

      unsetReloadFlag() {
        const requestArgs = {
          playlist_id: this.playlistId,
        };

        const _self = this;
        this.$store.dispatch(PLAYLIST_UNSET_RELOAD_FLAG, requestArgs).then(() => {
          _self.initPlaylist(_self);
        });
      },

      unsetRefreshBrowserFlag() {
        const requestArgs = {
          playlist_id: this.playlistId,
        };

        this.$store.dispatch(PLAYLIST_UNSET_BROWSER_REFRESH_FLAG, requestArgs).then(() => {
          console.log('reloading with browser refresh flag');
          this.reloadPlayer();
        });
      },

      checkReloadFlag() {
        const requestArgs = {
          playlist_id: this.playlistId,
        };

        const _self = this;

        this.$store.dispatch(PLAYLIST_RELOAD_CHECK, requestArgs).then((resp) => {
          if (!!resp.reload || !!resp.browser_refresh) {
            if (resp.reload) {
              _self.unsetReloadFlag();
            }
            if (resp.browser_refresh) {
              _self.unsetRefreshBrowserFlag();
            }
          } else {
            _self.setReloadTimeout();
          }
        });
      },

      reloadPlayer() {
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      },

      checkScheduleUpdates() {
        const _self = this;
        this.loadPlaylistItems()
          .then((res) => {
            const newItems = JSON.stringify(res.data);
            const oldItems = JSON.stringify(_self.loadedPlaylistData);
            if (newItems !== oldItems) {
              if (
                JSON.stringify(res.data.layout) !== JSON.stringify(_self.loadedPlaylistData.layout)
              ) {
                console.log('reloading with scheduled updated');
                this.reloadPlayer();
                return;
              }

              _self.loadedPlaylistData = res.data;
            }
          })
          .finally(() => {
            _self.setScheduleUpdateTimeout();
          });
      },

      setReloadTimeout() {
        if (this.reloadTimer) {
          clearTimeout(this.reloadTimer);
        }

        this.reloadTimer = setTimeout(this.checkReloadFlag, 5000);
      },

      setScheduleUpdateTimeout() {
        if (this.scheduleUpdateTimer) {
          clearTimeout(this.scheduleUpdateTimer);
        }

        this.scheduleUpdateTimer = setTimeout(this.checkScheduleUpdates, 30000);
      },

      setMatchiSlotsUpdateTimeout() {
        if (this.matchiSlotsUpdateTimer) {
          clearInterval(this.matchiSlotsUpdateTimer);
        }

        this.matchiSlotsUpdateTimer = setInterval(this.loadMatchiSlots, 300000); // every 5 mins
      },

      setMatchiEventsUpdateTimeout() {
        if (this.matchiEventsUpdateTimer) {
          clearInterval(this.matchiEventsUpdateTimer);
        }

        this.matchiEventsUpdateTimer = setInterval(this.loadMatchiEvents, 900000); // every 15 mins
      },

      setMatchiOffersUpdateTimeout() {
        if (this.matchiOffersUpdateTimer) {
          clearInterval(this.matchiOffersUpdateTimer);
        }

        this.matchiOffersUpdateTimer = setInterval(this.loadMatchiOffers, 900000); // every 15 mins
      },

      setMatchiActivitiesUpdateTimeout() {
        if (this.matchiActivitiesUpdateTimer) {
          clearInterval(this.matchiActivitiesUpdateTimer);
        }

        this.matchiActivitiesUpdateTimer = setInterval(this.loadMatchiActivities, 60000); // every 1 mins
      },

      setMatchiFacilityUpdateTimeout() {
        if (this.matchiFacilityUpdateTimer) {
          clearInterval(this.matchiFacilityUpdateTimer);
        }

        this.matchiFacilityUpdateTimer = setInterval(this.loadMatchiFacility, 60000); // every 1 mins
      },

      setColorTheme() {
        if (this.playlistMatchiSettings && this.playlistMatchiSettings.color_theme) {
          $('body').removeClass().addClass(`web-player-wrapper bg-matchi-playlist`);
        }
      },

      resetLayout() {
        $('.widget-item').remove();
        this.playlistStyling = {
          height: '100vh',
          width: '100vw',
          left: '0%',
          top: '0%',
        };
      },

      renderLayout() {
        this.isRendering = true;

        if (!this.playlistLayout || !this.playlistLayout.settings) {
          this.$nextTick(() => {
            this.widgetTree = [];
            this.playlistRoot = {
              id: 'playlist',
              data: {},
              children: [],
            };
            this.playlistStyling.height = `100%`;
            this.playlistStyling.width = `100%`;
            this.playlistStyling.left = `0`;
            this.playlistStyling.top = `0`;
            this.pictureMode = 'stretch';

            this.isRendering = false;
          });
          return;
        }

        let hData = null;
        let vData = null;

        if (this.playlistLayout.settings.isHorizontal) {
          hData = this.playlistLayout.settings.widgets;
          vData = this.playlistLayout.settings.verticalWidgets;
        } else {
          hData = this.playlistLayout.settings.horizontalWidgets;
          vData = this.playlistLayout.settings.widgets;
        }

        const widgetRef = this.isPortrait() ? vData : hData;

        let widgets = Object.values(widgetRef);

        const widgetTreeRoot = {
          id: 'root',
          data: {},
          children: [],
        };

        const objRefs = {
          root: widgetTreeRoot,
        };

        const processed = [];
        let playlistSettings = {};

        while (widgets.length) {
          _.each(widgets, (widget) => {
            if (widget.parentWid === null || widget.parentWid === 'root') {
              const child = { id: widget.wid, data: widget, children: [] };
              if (widget.type === 'playlist') {
                this.playlistRoot = child;
                objRefs[widget.wid] = this.playlistRoot;
                playlistSettings = widget;
              } else {
                objRefs.root.children.push(child);
                objRefs[widget.wid] = child;
              }

              processed.push(widget.wid);
            } else if (processed.indexOf(widget.parentWid) > -1) {
              const child = { id: widget.wid, data: widget, children: [] };
              objRefs[widget.parentWid].children.push(child);
              objRefs[widget.wid] = child;
              processed.push(widget.wid);
            }
          });

          widgets = widgets.filter((i) => processed.indexOf(i.wid) === -1);
        }

        this.widgetTree = widgetTreeRoot;
        this.pictureMode = playlistSettings.pictureMode;

        this.$nextTick(() => {
          this.playlistStyling.height = `${playlistSettings.height}%`;
          this.playlistStyling.width = `${playlistSettings.width}%`;
          this.playlistStyling.left = `${playlistSettings.x}%`;
          this.playlistStyling.top = `${playlistSettings.y}%`;
          this.playlistStyling.zIndex = 100 + playlistSettings.zIndex;

          setTimeout(() => {
            $('.widget-item.auto-fit-text, .widget-item .auto-fit-text').textfill({
              maxFontPixels: 200,
            });
            textFit(document.getElementsByClassName('auto-fit-multiline-text'), {
              multiLine: true,
              alignVert: false,
            });
            this.isRendering = false;
          }, 1000);
        });
      },

      initPlaylist: async (_self) => {
        try {
          await _self.connectScreen(_self);
        } catch (e) {
          return;
        }

        _self.isLoading = true;
        _self.currentTick = 0;

        // load playlist
        // await _self.loadPlaylist();

        // load playlist items
        await _self.initPlaylistItems();
        await _self.loadMatchi();
        _self.renderLayout();
        _self.$nextTick(_self.postInitPlaylist);
      },

      postInitPlaylist() {
        this.isLoading = false;
        // update current time every second
        if (this.baseTimer) {
          clearInterval(this.baseTimer);
        }

        this.baseTimer = setInterval(this.clockTick, 1000);
        this.setReloadTimeout();
        this.setScheduleUpdateTimeout();
        this.setColorTheme();
        this.setCourtElementHeights();
      },

      registerEvents() {
        EventBus.$on('MATCHI_END', () => {
          this.switchToPlaylist();
        });

        EventBus.$on('MATCHI_TOTAL_TIME', (totalTime) => {
          this.matchiCoolDownPeriod = totalTime;
        });

        const activityEvents = [
          'mousedown',
          'mousemove',
          'keydown',
          'scroll',
          'touchstart',
          'click',
        ];

        activityEvents.forEach((eventName) => {
          document.addEventListener(eventName, this.handleAllUserInteraction, true);
        });
      },

      handleAllUserInteraction() {
        if (this.contentPopupModelCoolDown && this.contentPopupModelInitialIdleTime) {
          this.resetContentModelRemainingTime();
        }
      },

      showEnlarged(refW) {
        this.contentPopupModelObj = refW;

        if (refW.contentType === 'image' || refW.contentType === 'video') {
          this.contentType = false;
          this.contentLink = this.getEncodedURL(refW.contentLink);

          this.contentObj = refW.contentObj;

          if (refW.closeButton) {
            this.contentModalCloseBtn = {
              backgroundColor: getRBGAColor(
                refW.closeButtonBackgroundColor,
                refW.closeButtonBackgroundOpacity / 100,
              ),
              width: `${refW.closeButtonDiameter}%`,
              color: getRBGAColor(refW.closeButtonSymbolColor, refW.closeButtonSymbolOpacity / 100),
              top: `${refW.closeButtonY}%`,
              left: `${refW.closeButtonX}%`,
              fontSize: `${refW.closeButtonDiameter}px`,
            };
          } else {
            this.contentModalCloseBtn = null;
          }

          this.$nextTick(() => {
            this.contentType = refW.contentType;
            $('#contentMediaModal')
              .modal({
                backdrop: refW.closeButton ? 'static' : true,
                keyboard: !refW.closeButton,
              })
              .on('hidden.bs.modal', () => {
                $('#contentMediaModal').modal('dispose');
              });

            const width = $('#contentMediaModal .content-media-modal-close-btn').width();
            $('#contentMediaModal .content-media-modal-close-btn').height(width);
            $('#contentMediaModal .content-media-modal-close-btn .material-icons').css({
              'font-size': width,
            });

            $('#contentMediaModal .close-btn-text-wrapper span').css({
              fontSize: `${width}px`,
            });
          });
        } else if (refW.contentType === 'url') {
          this.contentLink = this.getEncodedURL(refW.contentLink);
          this.openWebPage = true;

          if (refW.closeButton) {
            this.contentModalCloseBtn = {
              backgroundColor: getRBGAColor(
                refW.closeButtonBackgroundColor,
                refW.closeButtonBackgroundOpacity / 100,
              ),
              width: `${refW.closeButtonDiameter}%`,
              color: getRBGAColor(refW.closeButtonSymbolColor, refW.closeButtonSymbolOpacity / 100),
              top: `${refW.closeButtonY}%`,
              left: `${refW.closeButtonX}%`,
              fontSize: `${refW.closeButtonDiameter}px`,
            };
          } else {
            this.contentModalCloseBtn = null;
          }

          this.$nextTick(() => {
            const btnElement = $('.content-iframe .content-media-modal-close-btn');
            const width = btnElement.width();
            btnElement.height(width);
            btnElement.find('.material-icons').css({ 'font-size': width });

            btnElement.find('.close-btn-text-wrapper span').css({
              fontSize: `${width}px`,
            });
          });
        }

        const idleTimeout = moment(refW.idleTimeout, 'HH:mm:ss');
        if (idleTimeout.isValid()) {
          this.contentPopupModelInitialIdleTime = idleTimeout;
          if (this.contentPopupModelTimer != null) {
            clearInterval(this.contentPopupModelTimer);
          }

          this.resetContentModelRemainingTime();
          this.contentPopupModelTimer = setInterval(this.countDownContentModalIdleTime, 1000);
        }
      },
      resetContentModelRemainingTime() {
        this.contentPopupModelCoolDown =
          this.contentPopupModelInitialIdleTime.hour() * 360 +
          this.contentPopupModelInitialIdleTime.minute() * 60 +
          this.contentPopupModelInitialIdleTime.seconds();
      },
      countDownContentModalIdleTime() {
        this.contentPopupModelCoolDown--;

        if (this.contentPopupModelCoolDown <= 0) {
          clearInterval(this.contentPopupModelTimer);
          this.closeContentMediaModal();
        }
      },
      closeContentMediaModal() {
        if (this.contentPopupModelObj.contentType === 'url') {
          this.openWebPage = false;
        } else {
          $('#contentMediaModal').modal('hide');
        }

        if (this.contentPopupModelTimer != null) {
          clearInterval(this.contentPopupModelTimer);
        }

        this.contentPopupModelInitialIdleTime = 0;
        this.contentPopupModelCoolDown = 0;
      },

      isPortrait() {
        return window.innerHeight > window.innerWidth;
      },
      connectScreen: async (_self) => {
        if (_self.$store.getters.isAuthenticated) {
          return;
        }

        const requestArgs = {
          playlist_id: _self.playlistId,
        };

        await _self.$store.dispatch(PLAYLIST_ATTACH_SCREEN, requestArgs);
      },
      handleLinkReload() {
        this.$store.dispatch(PLAYLIST_CLEAR_LINK);
        this.reloadWithCacheBusted();
      },
      reloadWithCacheBusted() {
        const port = window.location.port.trim() ? `:${window.location.port}` : '';
        const cacheBust = `?cacheBust=${Date.now()}`;

        window.location = `${window.location.protocol}//${window.location.hostname}${port}${cacheBust}${window.location.hash}`;
      },
    },

    mounted() {
      this.initPlaylist(this);
      $('body').removeClass().addClass('bg-matchi-playlist');

      this.registerEvents();
    },
  };
</script>

<style lang="scss">
  .matchi-playlist-item-trafiklab {
    width: 100%;
    height: 100%;
    background: white;

    .header-after,
    .header-next {
      font-size: 20px;
    }

    .code,
    .name,
    .next,
    .after {
      font-size: 1rem !important;
    }
  }
</style>
