<template>
  <div class="template-panel d-flex flex-column">
    <div v-if="template" class="details flex-grow-1">
      <div class="form-group template-input name">
        <label for="template-name">Name</label>
        <input
          class="form-control"
          type="text"
          id="template-name"
          placeholder="Name"
          maxlength="40"
          v-model="templateName"
          @change="updateTemplate"
          :disabled="inOverrideMode"
        />
      </div>

      <div class="sub-section-title">Dimensions (px)</div>
      <div class="form-group hidden position">
        <div class="form-row">
          <div class="input-group col">
            <div class="input-label">
              <span class="input-group-text">W</span>
            </div>
            <input
              type="number"
              class="form-control"
              id="layer-h"
              :disabled="inOverrideMode"
              v-model="width"
            />
          </div>

          <div class="input-group col">
            <div class="input-label">
              <span class="input-group-text">H</span>
            </div>
            <input
              type="number"
              class="form-control"
              id="layer-w"
              :disabled="inOverrideMode"
              v-model="height"
            />
          </div>
        </div>
      </div>

      <div class="sub-section-title">Grid (px)</div>
      <div class="form-group hidden position">
        <div class="form-row">
          <div class="input-group col">
            <div class="input-label">
              <span class="input-group-text">X</span>
            </div>
            <input
              type="number"
              class="form-control"
              id="layer-x"
              :disabled="inOverrideMode"
              v-model="gridX"
            />
          </div>

          <div class="input-group col">
            <div class="input-label">
              <span class="input-group-text">Y</span>
            </div>
            <input
              type="number"
              class="form-control"
              id="layer-y"
              :disabled="inOverrideMode"
              v-model="gridY"
            />
          </div>
        </div>
      </div>

      <div class="sub-section-title">Background</div>
      <div class="form-group hidden size">
        <div class="form-row">
          <ColorPicker
            input-el-id="text-color"
            :disabled="inOverrideMode"
            v-model="backgroundColor"
            @input="updateTemplate"
          />

          <div class="input-group col">
            <div class="input-label">
              <span class="input-group-text">
                <i class="material-icons-outlined align-middle">opacity</i>
              </span>
            </div>
            <input
              type="number"
              class="form-control"
              :min="0"
              :max="100"
              id="layer-opacity"
              :disabled="inOverrideMode"
              v-model="opacity"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ColorPicker from '@/components/ColorPicker.vue';

  import { restrictToBounds } from '@/helpers/draggable';

  import {
    TEMPLATE_DESIGNER_SET_TEMPLATE,
    UPDATE_TEMPLATE_WIDGET_STATE,
  } from '@/store/actions/templateDesigner';

  export default {
    name: 'TemplatePanel',

    components: { ColorPicker },

    data() {
      return {
        templateName: '',
        backgroundColor: '#FFFFFF',
        grid: null,
      };
    },

    watch: {
      template() {
        this.setTemplateData();
      },
    },

    mounted() {
      this.setTemplateData();
    },

    computed: {
      template() {
        return this.$store.state.templateDesigner.template;
      },

      inOverrideMode() {
        const { groupId, screenId } = this.$store.state.templateDesigner;

        return !!screenId || !!groupId;
      },

      opacity: {
        get() {
          return this.template.settings.opacity;
        },
        set(value) {
          const numberValue = Number(value);
          const newValue =
            typeof numberValue === 'number' ? restrictToBounds(numberValue, 0, 100) : 100;

          this.$set(this.template.settings, 'opacity', newValue);

          this.updateTemplate();
        },
      },

      width: {
        get() {
          return parseInt(this.template.settings.width).toFixed(0);
        },
        set(value) {
          const numberValue = Number(value);
          const newValue =
            typeof numberValue === 'number' ? restrictToBounds(numberValue, 1, 3340) : 1;

          this.$set(this.template.settings, 'width', newValue);

          this.updateWidgetsPositions();
        },
      },

      height: {
        get() {
          return parseInt(this.template.settings.height).toFixed(0);
        },
        set(value) {
          const numberValue = Number(value);
          const newValue =
            typeof numberValue === 'number' ? restrictToBounds(numberValue, 1, 3340) : 1;

          this.$set(this.template.settings, 'height', newValue);

          this.updateWidgetsPositions();
        },
      },

      gridX: {
        get() {
          return this.grid?.x;
        },
        set(value) {
          const xValue = Number(value);
          const gridX = xValue && xValue > 1 ? xValue : 1;
          this.$set(this.grid, 'x', gridX);

          this.updateTemplate();
        },
      },

      gridY: {
        get() {
          return this.grid?.y;
        },
        set(value) {
          const yValue = Number(value);
          const gridY = yValue && yValue > 1 ? yValue : 1;
          this.$set(this.grid, 'y', gridY);

          this.updateTemplate();
        },
      },
    },

    methods: {
      setTemplateData() {
        const {
          name,
          grid,
          backgroundColor = '#FFFFFF',
        } = this.template ? this.template.settings : {};

        this.templateName = this.$route.query?.name || name;
        this.backgroundColor = backgroundColor;
        this.grid = grid || {
          x: 20,
          y: 20,
        };
      },

      async updateWidgetsPositions() {
        const templateChildren = Object.values(this.$store.state.templateDesigner.widgets);
        const { width, height } = this.template.settings;

        templateChildren.forEach((widget) => {
          const newPosition = {
            ...widget.position,
            ...this.calculatePosition(widget.position, {
              templateWidth: width,
              templateHeight: height,
            }),
          };

          const updatedWidget = {
            ...widget,
            position: newPosition,
          };

          this.$store.commit(UPDATE_TEMPLATE_WIDGET_STATE, {
            widget: updatedWidget,
            selectWidget: false,
          });
        });

        this.updateTemplate();
      },

      calculatePosition(position, { templateWidth, templateHeight }) {
        const { height, width, x, y } = position;

        return {
          widthPixels: (width * templateWidth) / 100,
          heightPixels: (height * templateHeight) / 100,
          xPixels: (x * templateWidth) / 100,
          yPixels: (y * templateHeight) / 100,
        };
      },

      updateTemplate() {
        if (this.templateName === '') {
          this.$toasted.global.general_error({
            message: 'Template name cannot be empty',
          });
          return;
        }

        const template = {
          ...this.template,
          name: this.templateName,
          settings: {
            ...this.template.settings,
            name: this.templateName,
            backgroundColor: this.backgroundColor,
            grid: this.grid,
          },
        };

        this.$store.commit(TEMPLATE_DESIGNER_SET_TEMPLATE, { template });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .template-panel {
    .used-widget-list-expand {
      cursor: pointer;
    }

    input:disabled {
      background: none;
    }
  }
</style>
