<template>
  <SimpleModal title="Delete Folder" ref="deleteFolderModal">
    <template>
      <span class="message">Are you sure you want to delete this folder?</span>
      <BaseText variant="body2" color="#6a6b6a">
        By deleting this folder, its templates will move to "Templates without folder".</BaseText
      >
    </template>
    <template #footer>
      <BaseButton
        size="sm"
        class="mr-4"
        theme="secondary"
        @click.native="close"
        :disabled="isDeleting"
      >
        Cancel
      </BaseButton>

      <BaseButton size="sm" @click.native="deleteFolder" :loading="isDeleting"> Delete </BaseButton>
    </template>
  </SimpleModal>
</template>

<script>
  import SimpleModal from '@/components/common/SimpleModal.vue';
  import { BaseText } from '@/components/common/ui/atoms/baseText';
  import { TEMPLATE_FOLDER_DELETE } from '@/store/actions/templateDesigner';

  export default {
    name: 'DeleteTemplateFolderModal',
    props: ['folder'],
    emits: ['folder-deleted'],
    components: { SimpleModal, BaseText },
    data() {
      return {
        isDeleting: false,
      };
    },
    methods: {
      async deleteFolder() {
        this.isDeleting = true;
        try {
          await this.$store.dispatch(TEMPLATE_FOLDER_DELETE, this.folder);
          this.$toasted.global.general_success({ message: 'Folder deleted successfully' });
          this.$emit('folder-deleted');
        } catch {
          this.$toasted.global.general_error({
            message: 'Unable to delete folder. Please try again',
          });
        }
        this.isDeleting = false;
        this.close();
      },

      open() {
        this.$refs.deleteFolderModal.open();
      },
      close() {
        this.$refs.deleteFolderModal.close();
      },
    },
  };
</script>

<style lang="scss" scoped>
  ::v-deep .ui-modal-content {
    width: 500px;
  }

  .message {
    font-size: 16px;
    font-weight: 400;
    color: #000000;
  }
</style>
