import Vue from 'vue';
import VueRouter from 'vue-router';
import ScreenView from '@/components/screens/screen/ScreenView.vue';
import ScreenGroupView from '@/components/screens/screen/ScreenGroupView.vue';
import { GET_USER_FONTS } from '@/store/actions/fonts';
import store from '@/store';

import config from '@/config';
import { AUTH_LOGOUT } from '@/store/actions/auth';

import Login from '@/components/Login.vue';
import Home from '@/components/Home.vue';
import Screens from '@/components/screens/ScreensView.vue';
import ContentView from '@/components/content/ContentView.vue';
import Organisation from '@/components/admin/Organisation.vue';
import UsersView from '@/components/admin/UsersView';
import LicensesView from '@/components/admin/LicensesView';
import Player from '@/components/player/Index.vue';
import Account from '@/components/Account.vue';
import HTMLEditor from '@/components/HTMLEditor.vue';
import Debug from '@/components/Debug.vue';
import Apps from '@/components/apps/Index.vue';

import LayoutDesigner from '@/components/layoutDesigner/Index.vue';
import TemplateDesigner from '@/components/templates/TemplateDesigner.vue';
import Layouts from '@/components/layouts/Index.vue';
import TemplatesFolderView from '@/components/templates/TemplatesFolderView.vue';
import ContactUs from '@/components/ContactUs.vue';

Vue.use(VueRouter);

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next('/');
};

const checkFonts = async () => {
  if (!store.state.app.fontsLoaded) {
    store.dispatch(GET_USER_FONTS);
  }
};

const ifAuthenticated = (to, from, next) => {
  if (config.playerSite) {
    store.dispatch(AUTH_LOGOUT).then(() => {
      window.href = '/';
    });
  }

  if (store.getters.isAuthenticated) {
    checkFonts();
    next();

    return;
  }
  next('/login');
};

// eslint-disable-next-line
const ifAdministrator = (to, from, next) => {
  if (
    store.getters.isAuthenticated &&
    (store.getters.isAdministrator || store.getters.isOrganizationAdmin)
  ) {
    next();
    return;
  }
  next('/');
};

const appTitle = 'PicFlow';

const router = new VueRouter({
  routes: [
    {
      path: '/login',
      component: Login,
      name: 'Login',
      beforeEnter: ifNotAuthenticated,
      meta: {
        title: () => `${appTitle}`,
        layout: 'empty',
      },
    },
    {
      path: '/',
      name: 'Dashboard',
      component: Home,
      beforeEnter: ifAuthenticated,
      meta: {
        title: () => `${appTitle} - ${router.app.$lang.common.home}`,
      },
    },
    {
      path: '/screens',
      name: 'Screens',
      component: Screens,
      beforeEnter: ifAuthenticated,
      meta: {
        title: () => `${appTitle} - ${router.app.$lang.common.screens}`,
        layout: 'full',
      },
    },
    {
      path: '/screens/:screenId',
      name: 'Screen',
      component: ScreenView,
      beforeEnter: ifAuthenticated,
      meta: {
        title: () => `${appTitle} - ${router.app.$lang.common.screen}`,
        layout: 'full',
      },
    },
    {
      path: '/screens/groupLayout/:groupId/:layoutId',
      name: 'ScreenLayout',
      component: ScreenGroupView,
      beforeEnter: ifAuthenticated,
      meta: {
        title: () => `${appTitle} - Group`,
        layout: 'full',
      },
    },
    {
      path: '/content/library',
      name: 'Content',
      beforeEnter: ifAuthenticated,
      component: ContentView, // former MediaLibrary
      meta: {
        title: () => `${appTitle} - ${router.app.$lang.common.content}`,
        layout: 'full',
      },
    },
    {
      path: '/content/html',
      beforeEnter: ifAuthenticated,
      component: HTMLEditor,
      meta: {
        title: () => `${appTitle} - Rikt innehåll`,
      },
    },
    {
      path: '/organisation',
      name: 'Organisation',
      component: Organisation,
      beforeEnter: ifAdministrator,
      meta: {
        title: () => `${appTitle} - ${router.app.$lang.common.organization}`,
        layout: 'full',
      },
    },
    {
      path: '/organisation/:organisation_id',
      name: 'Admin Organisation',
      component: Organisation,
      beforeEnter: ifAdministrator,
      meta: {
        title: () => `${appTitle} - ${router.app.$lang.common.organization}`,
        layout: 'full',
      },
    },
    {
      path: '/organisation/:organisationId/licenses',
      name: 'Licenses',
      component: LicensesView,
      beforeEnter: ifAdministrator,
      meta: {
        title: () => `${appTitle} - ${router.app.$lang.common.organization}`,
        layout: 'full',
      },
    },
    {
      path: '/organisation/:organisationId/users',
      name: 'Users',
      component: UsersView,
      beforeEnter: ifAdministrator,
      meta: {
        title: () => `${appTitle} - ${router.app.$lang.common.organization}`,
        layout: 'full',
      },
    },
    {
      path: '/account',
      component: Account,
      name: 'Settings',
      beforeEnter: ifAuthenticated,
      meta: {
        title: () => `${appTitle} - ${router.app.$lang.common.user_settings}`,
      },
    },
    {
      path: '/layouts/:layout_id/player',
      name: 'Layout Player',
      component: Player,
      beforeEnter: ifAuthenticated,
      meta: {
        title: () => `${appTitle} - Player`,
        layout: 'empty',
      },
    },
    {
      path: '/debug',
      component: Debug,
      meta: {
        title: () => `${appTitle} - Debug`,
      },
    },
    {
      path: '/apps',
      component: Apps,
      name: 'Applications',
      beforeEnter: ifAuthenticated,
      meta: {
        title() {
          return `${appTitle} - Applications`;
        },
        layout: 'full',
      },
    },
    {
      path: '/templates',
      name: 'Templates',
      component: TemplatesFolderView,
      beforeEnter: ifAuthenticated,
      meta: {
        title: () => `${appTitle} - Templates`,
        layout: 'full',
      },
    },
    {
      path: '/layouts',
      name: 'Layouts',
      component: Layouts,
      beforeEnter: ifAuthenticated,
      meta: {
        title: () => `${appTitle} - Layouts`,
        layout: 'full',
      },
    },
    {
      path: '/templates/designer/:templateId',
      name: 'Template',
      component: TemplateDesigner,
      beforeEnter: ifAuthenticated,
      meta: {
        title: () => `${appTitle} - Template Designer`,
        layout: 'full',
      },
    },
    {
      path: '/layouts/designer/:layout_id',
      name: 'Layout',
      component: LayoutDesigner,
      beforeEnter: ifAuthenticated,
      meta: {
        title: () => `${appTitle} - Layout Designer`,
        name: 'Layouts',
        layout: 'full',
      },
    },
    {
      path: '/contact-us',
      name: 'Contact Us',
      beforeEnter: ifAuthenticated,
      component: ContactUs,
      meta: {
        title: () => `${appTitle} - Contact Us`,
      },
    },
  ],
});

router.beforeResolve((to, from, next) => {
  if (to.name) {
    //
  }
  next();
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title(to);
  });
});

export default router;
