<template>
  <div class="main-container" id="template-designer-screen">
    <div class="content">
      <div class="content-body">
        <div class="Templates">
          <TemplateDesignerHeader @openDeleteModal="showDeleteTemplateModal = true" />

          <div class="body scrollbar" @mousedown="eventHandler">
            <TemplateLayout v-show="template" :template="template" />
          </div>
        </div>
      </div>
    </div>
    <Editor :overrideMode="useOverrideMode" />

    <DeleteTemplateModal
      v-if="showDeleteTemplateModal"
      @closeModal="showDeleteTemplateModal = false"
      :templateId="template ? template.template_id : null"
      @deleted="handleDeleteTemplate"
    />
  </div>
</template>

<script>
  import TemplateLayout from '@/components/templates/designer/TemplateLayout.vue';
  import Editor from '@/components/templates/editor/Index.vue';
  import TemplateDesignerHeader from '@/components/templates/TemplateDesignerHeader.vue';
  import DeleteTemplateModal from '@/components/templates/DeleteTemplateModal.vue';

  import {
    TEMPLATE_DESIGNER_DESELECT_WIDGET,
    LOAD_TEMPLATE,
    LOAD_TILE,
    RESET_TEMPLATE,
    SET_SAVE_TEMPLATE,
  } from '@/store/actions/templateDesigner';
  import { SET_IS_PLAYLIST_PREVIEW_ON } from '@/store/actions/playlist';

  export default {
    name: 'TemplateDesigner',

    components: {
      DeleteTemplateModal,
      Editor,
      TemplateLayout,
      TemplateDesignerHeader,
    },

    data() {
      return {
        showDeleteTemplateModal: false,
        showPreviewModal: false,
        layoutId: null,
        useOverrideMode: !!this.$route.query.group || !!this.$route.query.screen,
      };
    },

    async created() {
      const { group, screen, layout, tile, playlist } = this.$route.query;

      this.$store.commit(SET_IS_PLAYLIST_PREVIEW_ON, false);
      this.$store.commit(RESET_TEMPLATE, {
        groupId: group,
        layoutId: layout,
        screenId: screen,
        playlistId: playlist,
      });

      tile ? this.getTileData() : this.getTemplateData();
    },

    beforeRouteLeave(to, from, next) {
      if (this.allowLeave) {
        next();
        return;
      }

      next(window.confirm('Are you sure to leave without saving your changes?'));
    },

    beforeDestroy() {
      this.removeListener();
    },

    watch: {
      allowLeave(allow) {
        if (allow) {
          this.removeListener();
          return;
        }

        window.addEventListener('beforeunload', this.leaving);
      },
    },

    computed: {
      template() {
        return this.$store.state.templateDesigner.template;
      },

      allowLeave() {
        return !this.$store.state.templateDesigner.saveTemplate;
      },
    },

    methods: {
      removeListener() {
        window.removeEventListener('beforeunload', this.leaving);
      },

      leaving(event) {
        event.preventDefault();

        // Included for legacy support, e.g. Chrome/Edge < 119
        event.returnValue = true;
      },

      deselectWidget() {
        this.$store.commit(TEMPLATE_DESIGNER_DESELECT_WIDGET);
      },

      async getTemplateData() {
        const result = await this.$store.dispatch(LOAD_TEMPLATE, {
          templateId: this.$route.params.templateId,
        });

        if (!result) {
          this.$toasted.global.general_error({
            message: 'Template not found',
          });

          this.$router.push({ name: 'Templates' });
        }
      },

      async getTileData() {
        const result = await this.$store.dispatch(LOAD_TILE, {
          tileId: this.$route.query.tile,
        });

        if (!result) {
          this.$toasted.global.general_error({
            message: 'Template not found',
          });

          this.$router.push({ name: 'Templates' });
        }
      },

      async handleDeleteTemplate() {
        this.$store.commit(SET_SAVE_TEMPLATE, false);
        $router.push({ name: 'Templates' });
      },

      eventHandler(event) {
        if (!event.target.className?.includes('template-wrapper')) return;

        this.deselectWidget();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .Templates {
    width: 100%;
    padding: 0 16px 40px;
    display: flex;
    flex-direction: column;

    .body {
      display: flex;
      flex-direction: column;
      margin-right: -40px;
      margin-top: -16px;

      height: 100%;
      align-items: center;
      justify-content: center;
    }
  }
</style>
