<template>
  <div class="sidebar-settings">
    <div class="sidebar-content scrollbar">
      <slot name="default"> </slot>
    </div>

    <div class="sidebar-buttons">
      <slot name="buttons"> </slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SidebarContainer',
  };
</script>

<style lang="scss" scoped>
  .sidebar-settings {
    display: grid;
    gap: 16px;
    grid-auto-flow: row;
    grid-template-rows: auto fit-content(60px);
    grid-template-columns: 1fr;

    justify-content: space-between;
    background-color: #fff;
    height: 100vh;
    padding: 32px 20px 40px;

    .sidebar-content {
      overflow-y: auto;
      margin-right: -20px;
      padding-right: 20px;

      margin-left: -20px;
      padding-left: 20px;

      .section-title {
        display: flex;
        align-items: center;
        font-size: 16px;
        gap: 10px;
        margin-bottom: 8px;

        i {
          color: $primaryText;
        }

        &.title {
          margin-bottom: 24px;
        }
      }
    }
  }

  .dropdown-toggle {
    &:hover {
      cursor: pointer;
    }

    &::after {
      display: none !important;
    }
  }

  .sidebar-section {
    padding-top: 16px;
    padding-bottom: 24px;

    & + .sidebar-section {
      border-top: 1px solid $lineGrey;
    }
  }

  .content-section {
    display: flex;
    flex-direction: column;
    gap: 16px;

    label {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      font-weight: bold;
    }
  }

  .sidebar-buttons {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
</style>
