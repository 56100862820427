<template>
  <SimpleModal title="Create Template Folder" ref="createFolderModal" @close="reset">
    <template #default>
      <div class="content-wrapper">
        <BaseInput v-model="folderName" label="Name" placeholder="Enter folder name" />
      </div>
    </template>
    <template #footer>
      <BaseButton size="sm" class="mr-4" theme="secondary" @click.native="close">
        Cancel
      </BaseButton>

      <BaseButton size="sm" @click.native="createFolder" :loading="isSaving">
        Create Folder
      </BaseButton>
    </template>
  </SimpleModal>
</template>

<script>
  import SimpleModal from '../common/SimpleModal.vue';
  import { TEMPLATE_FOLDER_CREATE } from '@/store/actions/templateDesigner';

  export default {
    props: ['parent_id'],
    emits: ['folder-created'],
    name: 'CreateTemplateFolderModal',
    components: { SimpleModal },
    data() {
      return {
        isSaving: false,
        folderName: '',
      };
    },
    methods: {
      async createFolder() {
        if (!this.folderName) {
          this.$toasted.global.general_error({ message: 'Folder name is required!' });
          return;
        }
        if (this.isSaving) {
          this.$toasted.global.general_error({ message: 'Folder is being created!' });
          return;
        }
        this.isSaving = true;
        try {
          await this.$store.dispatch(TEMPLATE_FOLDER_CREATE, {
            parent_id: this.parent_id,
            folderName: this.folderName,
          });
          this.$toasted.global.general_success({ message: 'Folder created successfully' });
          this.$emit('folder-created');
          this.close();
          this.reset();
        } catch {
          this.$toasted.global.general_error({ message: 'Error creating folder' });
          this.close();
        }
        this.isSaving = false;
      },
      reset() {
        this.folderName = '';
      },
      open() {
        this.$refs.createFolderModal.open();
      },
      close() {
        this.$refs.createFolderModal.close();
      },
    },
  };
</script>

<style lang="scss" scoped>
  ::v-deep .ui-modal-content {
    width: 500px !important;
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
</style>
