<template>
  <div class="trafiklab-container h-100 d-flex flex-column justify-content-between">
    <div class="px-3">
      <!-- <AppInfo>Display timetables for traffic stopos all over Sweden with GTFS Sweden. Traffic data provided by Trafiklab and Resrobot.</AppInfo> -->
      <div class="d-flex mt-4">
        <img :src="trafikLabImg" alt="Trafiklab image" class="mr-4">
        <div class="d-flex flex-column justify-content-center">
          <div class="integration-status">Status: <span>Connected</span></div>
        </div>
      </div>
    </div>
<!--    <SourcesSettings-->
<!--      v-if="currentSettings === 'SOURCES'"-->
<!--      :trafiklabData="trafiklabData"-->
<!--      @sourcesSaved="handleSourcesSave"-->
<!--    />-->
<!--    <TimeTableSettings-->
<!--      v-if="currentSettings === 'TIMETABLE'"-->
<!--      :timeTableData="timeTableData"-->
<!--      :trafiklabData="trafiklabData"-->
<!--      @openSourcesSettings="setCurrentSetting('SOURCES')"-->
<!--      @saveTimeTable="handleTimeTableSave"-->
<!--      @openScheduleSettings="setCurrentSetting('SCHEDULER')"-->
<!--    />-->
<!--    <TrafiklabScheduler-->
<!--      v-if="currentSettings === 'SCHEDULER'"-->
<!--      :savedSchedulerData="schedulerData"-->
<!--      @createTrafiklabApp="sendData"-->
<!--      @returnToTimeTable="openTimeTable"-->
<!--    />-->
    <div class="px-3">
      <span class="btn w-100 create-widget-btn rounded-lg" @click="$emit('onTrafiklabCreate')"><img :src="plusIcon" alt="Plus icon"> Create Widget</span>
    </div>
  </div>
</template>

<script>
  import AppInfo from "@/components/apps/AppInfo.vue";
  import SourcesSettings from './SourcesSettings.vue';
  import TimeTableSettings from '@/components/apps/trafiklab/TimeTableSettings.vue';
  import TrafiklabScheduler from '@/components/apps/trafiklab/TrafiklabScheduler.vue';
  import { APP_CREATE } from '@/store/actions/apps';
  import trafikLabImg from '@/assets/img/apps/trafiklab-103x72.jpg';
  import plusIcon from '@/assets/icon/icon-plus-square.png';

  export default {
    name: 'TrafiklabSettings',

    components: {AppInfo, SourcesSettings, TimeTableSettings, TrafiklabScheduler },

    data() {
      return {
        currentSettings: 'SOURCES',
        trafiklabData: null,
        timeTableData: null,
        schedulerData: null,
        trafikLabImg,
        plusIcon
      };
    },

    methods: {
      handleSourcesSave(data) {
        this.trafiklabData = data;
        this.setCurrentSetting('TIMETABLE');
      },

      openTimeTable(data) {
        this.schedulerData = data;
        this.setCurrentSetting('TIMETABLE');
      },

      handleTimeTableSave(data) {
        this.timeTableData = data;
      },

      async sendData(schedulerData) {
        try {
          let data = {
            type: 'trafiklab',
            overall: this.trafiklabData,
            schedulerData,
            ...this.timeTableData,
          };

          await this.$store.dispatch(APP_CREATE, data);
          this.$toasted.global.general_success({ message: 'Trafiklab app added successfully' });

          this.$emit('closeAppSidebar');
        } catch (error) {
          this.$toasted.global.general_error({
            message: 'Error adding app, please try again later',
          });
        }
      },

      setCurrentSetting(name) {
        this.currentSettings = name;
      },
    },
  };
</script>

<style lang="scss" scoped>
.integration-status {
  color: $darkgrey;
  font-weight: 500;
  font-size: 16px;

  span {
    color: $greenLight;
  }
}
.integration-button {
  color: $secondaryText;
  padding: 5px 19px;
  font-size: 12px;
  letter-spacing: 1px;
  border-radius: 10px;
  border: 1px solid $secondaryText;
}
.create-widget-btn {
  background: $primaryRed;
  color: #ffffff;
}
</style>
