<template>
  <SidebarContainer>
    <template v-if="!!license" v-slot:default>
      <div class="sidebar-container">
        <div class="form-group">
          <BaseText variant="inputLabel">Licence Name</BaseText>
          <input
            type="text"
            name="licenseName"
            id="licenseName"
            class="form-control"
            required
            v-model="licenseName"
          />
        </div>

        <div class="form-group">
          <BaseText variant="inputLabel">Start Date</BaseText>
          <input
            type="date"
            name="startDate"
            class="form-control"
            id="startDate"
            disabled
            :value="formatDate(license.started_at, 'YYYY-MM-DD')"
          />
        </div>

        <div class="form-group">
          <BaseText variant="inputLabel">Expiry Time (days)</BaseText>
          <input
            type="text"
            name="expirtTime"
            class="form-control"
            id="expirtTime"
            :value="license.expired_time"
            disabled
          />
        </div>

        <div class="form-group">
          <BaseText variant="inputLabel">Description</BaseText>
          <textarea
            name="licenseDescription"
            id="licenseDescription"
            class="form-control"
            required
            v-model="licenseDescription"
          />
        </div>

        <div class="list-container">
          <div class="title">
            <BaseText variant="inputLabel">Linked Screen</BaseText>
            <i class="material-icons-outlined align-middle">link_off</i>
          </div>

          <div v-if="license.screen_details" class="list-item">
            <div class="list-item-title">
              <i class="material-icons-outlined align-middle">monitor</i>
              <span>{{ license.screen_details.name }}</span>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-if="!!license" v-slot:buttons>
      <button
        v-if="isAdmin && license.status !== 'removed'"
        type="button"
        class="button-2-secondary"
        @click="openDeleteLicenseModal"
      >
        <i class="material-icons-outlined align-middle">delete_outline</i>
        Delete License
      </button>

      <button
        type="button"
        class="button-2-primary"
        v-if="isAdmin"
        @click="updateLicense"
        :disabled="isSaving"
      >
        <i v-if="isSaving" class="material-icons align-middle spin-animation">refresh</i>
        <i v-else class="material-icons-outlined align-middle">save</i>
        Save Settings
      </button>

      <DeleteLicenseModal
        @closeModal="showDeleteLicenseModal = false"
        :license="license"
        v-if="showDeleteLicenseModal"
        @deleted="$emit('licenseDeleted')"
      />
    </template>
  </SidebarContainer>
</template>

<script>
  import moment from 'moment';
  import SidebarContainer from '@/components/common/SidebarContainer.vue';
  import DeleteLicenseModal from '@/components/admin/DeleteLicenseModal.vue';
  import { updateLicense } from '@/api/organisation';
  import BaseText from '@ui/atoms/baseText/BaseText.vue';

  export default {
    name: 'LicensesSidebar',

    components: { SidebarContainer, DeleteLicenseModal, BaseText },

    props: {
      license: {
        type: Object,
        default: () => null,
      },
    },

    data() {
      return {
        isSaving: false,
        showInfo: false,
        showDeleteLicenseModal: false,
        licenseName: '',
        licenseDescription: '',
      };
    },

    mounted() {
      this.licenseName = this.license?.name;
      this.licenseDescription = this.license?.description;
    },

    watch: {
      license() {
        this.licenseName = this.license?.name;
        this.licenseDescription = this.license?.description;
      },
    },

    computed: {
      isAdmin() {
        return this.$store.getters.isAdministrator;
      },
    },

    methods: {
      async updateLicense() {
        this.isSaving = true;

        try {
          const data = {
            ...this.license,
            name: this.licenseName,
            description: this.licenseDescription,
          };
          await updateLicense(this.license.organisation, this.license.id, data);
          this.$toasted.global.general_success({
            message: 'License successfully updated',
          });
          this.$emit('licenseUpdated');
        } catch (error) {
          this.$toasted.global.general_error({
            message: error?.response?.data?.message || 'Failed to update the license',
          });
        }

        this.isSaving = false;
      },

      openDeleteLicenseModal() {
        this.showDeleteLicenseModal = true;
      },

      goToScreen(screen) {
        this.$router.push({
          name: 'Screen',
          params: {
            screenId: screen.id,
          },
        });
      },

      formatDate(date, format) {
        return moment(date).format(format);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .sidebar-container {
    display: flex;
    flex-direction: column;
    gap: 16px;

    button {
      margin-top: 8px;
    }
  }

  .content-section {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }

  .form-group {
    width: 100%;
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    .form-control {
      &:not([disabled]) {
        color: $primaryText;
      }
    }
  }

  .dropdown-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 8px;
    gap: 8px;
    width: 100%;

    .dropdown {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      line-height: 24px;
      padding: 8px 0;
      font-weight: 500;
      cursor: pointer;
    }
  }

  .content-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 16px;
    border-bottom: 2px solid $lineGrey;
    overflow-x: hidden;
  }

  .description-container {
    display: flex;
    flex-direction: column;
    padding: 12px 2px;
    gap: 8px;

    .description-item {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: $primaryText;

      a {
        color: $primaryText;
      }

      span {
        color: $secondaryText;
        font-weight: 400;
      }
    }
  }

  .title {
    display: flex;
    justify-content: space-between;
    margin-bottom: -8px;
    color: $primaryText;

    i {
      color: $secondaryText;
    }
  }
</style>
