<template>
  <div class="table-app-fill-form">
    <p class="t-sm font-poppins text-secondary">Setup, fill and edit tenants content</p>
    <div class="shadow px-1 pb-3" style="background-color: #fafafa">
      <table class="table mb-0" id="table-app-fill-table">
        <tr class="d-table w-100">
          <td class="border-0" style="width: 27px"></td>
          <td class="border-0 text-secondary" style="width: 44%">Header</td>
          <td class="border-0 text-secondary">Header</td>
          <td class="border-0"></td>
        </tr>
        <draggable v-model="settings.rows" group="rows" @start="drag = true" @end="drag = false">
          <tr
            v-for="(row, rowIndex) in settings.rows"
            :key="rowIndex"
            :class="[row.type === 'heading' ? 'row-heading' : 'row-body']"
          >
            <td class="align-middle p-1">
              <svg
                style="cursor: move"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 19V17H9V19H7ZM11 19V17H13V19H11ZM15 19V17H17V19H15ZM7 15V13H9V15H7ZM11 15V13H13V15H11ZM15 15V13H17V15H15ZM7 11V9H9V11H7ZM11 11V9H13V11H11ZM15 11V9H17V11H15ZM7 7V5H9V7H7ZM11 7V5H13V7H11ZM15 7V5H17V7H15Z"
                  fill="#6A6B6A"
                />
              </svg>
            </td>
            <td
              :class="[row.type === 'heading' ? 'col-heading' : 'col-body']"
              v-for="colIndex in settings.columns"
              style="padding: 0.25rem 0.12rem"
              :key="colIndex"
            >
              <input
                class="form-control focus-danger"
                type="text"
                v-model="row.data[colIndex - 1].content"
              />
            </td>
            <td class="p-0 align-middle">
              <button @click="removeRow(rowIndex)" class="btn btn-sm p-1">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_1928_18907)">
                    <path
                      d="M5.00008 17.5H15.0001V5.83333H5.00008V17.5ZM6.66675 7.5H13.3334V15.8333H6.66675V7.5ZM12.9167 3.33333L12.0834 2.5H7.91675L7.08341 3.33333H4.16675V5H15.8334V3.33333H12.9167Z"
                      fill="#B7B8B7"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1928_18907">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
            </td>
          </tr>
        </draggable>
      </table>
    </div>
    <div class="d-flex mt-3">
      <button
        type="button"
        class="btn btn-outline-danger rounded-xl btn-sm font-poppins"
        @click.prevent="addBodyRow"
        style="border-width: 2px; font-weight: 500"
      >
        + Add Row
      </button>
      <button
        type="button"
        class="btn btn-outline-danger rounded-xl btn-sm ml-3 font-poppins"
        @click.prevent="addHeadingRow"
        style="border-width: 2px; font-weight: 500"
      >
        + Add Header Row
      </button>
    </div>
  </div>
</template>

<script>
  import draggable from 'vuedraggable';

  const MODE_CREATE = 'create';

  export default {
    name: 'TableContent',

    props: {
      rows: {
        type: Array,
        default: [],
      },
      columns: {
        type: Number,
        default: null,
      },
    },

    components: {
      draggable,
    },

    data() {
      return {
        originalName: null,
        MODE_CREATE: MODE_CREATE,
        ...this.getInitialData(),
      };
    },

    created() {
      if (this.rows) {
        this.settings.rows = this.rows;
      }
      if (this.columns) {
        this.settings.columns = this.columns;
      }
    },

    methods: {
      removeRow(rowIndex) {
        this.settings.rows.splice(rowIndex, 1);
      },
      createRow() {
        const row = {
          data: [],
        };
        for (let i = 0; i < this.settings.columns; i++) {
          row.data.push({
            content: '',
          });
        }

        return row;
      },
      addHeadingRow() {
        const row = this.createRow();
        row.type = 'heading';
        this.settings.rows.push(row);
      },
      addBodyRow() {
        const row = this.createRow();
        row.type = 'body';
        this.settings.rows.push(row);
      },
      save() {
        this.$emit('onSave', this.settings);
      },
      getInitialData: function () {
        return {
          settings: {
            columns: 2,
            rows: [
              {
                type: 'heading',
                data: [
                  {
                    content: 'Col 1',
                  },
                  {
                    content: 'Col 2',
                  },
                ],
              },
              {
                type: 'body',
                data: [
                  {
                    content: '',
                  },
                  {
                    content: '',
                  },
                ],
              },
            ],
          },
        };
      },
    },
  };
</script>

<style lang="scss" scoped>
  .table-app-fill-form {
    table {
      tr:hover {
        background-color: #f0c996;
        cursor: pointer;
      }

      .row-heading {
        background: #ebebeb;
      }

      //.row-body {
      //  background: #fff;
      //}
      .focus-danger {
        &:focus,
        &:active {
          border: 1px solid $primaryRed !important;
          box-shadow: unset !important;
        }
      }
      th,
      td {
        border-top: 0 !important;
      }
    }
  }
</style>
