<template>
  <div class="layer-panel scrollbar">
    <ul class="layers list-unstyled">
      <li v-for="(widget, index) in widgets" :key="index" class="layer">
        <span
          class="layer-name"
          :title="widget.position.data?.name || widget.position.name || widget.object.name"
          @click="selectLayer(widget)"
        >
          <i class="material-icons-outlined">{{ getIconType(widget) }}</i>
          {{ widget.position.data?.name || widget.position.name || widget.object.name }}
        </span>
        <i class="material-icons delete-widget" @click="openDeleteWidgetModal(widget)">
          delete_outline
        </i>
      </li>
    </ul>

    <div v-if="Object.keys(widgets).length === 0" class="no-data">No widgets</div>

    <DeleteWidgetModal
      v-if="showDeleteWidgetModal"
      :widget="selectedWidget"
      @closeModal="closeModal"
    />
  </div>
</template>

<script>
  import DeleteWidgetModal from '@/components/layoutDesigner/designer/DeleteWidgetModal.vue';

  import { LAYOUT_DESIGNER_SELECT_WIDGET } from '@/store/actions/layoutDesigner';

  import { getWidgetIcons } from '@/helpers/mixins';

  export default {
    name: 'LayerPanel',

    components: { DeleteWidgetModal },

    data() {
      return {
        showDeleteWidgetModal: false,
        selectedWidget: null,
      };
    },

    computed: {
      widgets() {
        return this.$store.state.layoutDesigner.widgets;
      },
    },

    methods: {
      openDeleteWidgetModal(widget) {
        this.selectedWidget = widget;
        this.showDeleteWidgetModal = true;
      },

      selectLayer(widget) {
        this.$store.commit(LAYOUT_DESIGNER_SELECT_WIDGET, widget);
      },

      getIconType(widget) {
        return getWidgetIcons(widget.itemType || widget.object.type);
      },

      closeModal() {
        this.showDeleteWidgetModal = false;
        this.selectedWidget = null;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .layer-panel {
    overflow-y: auto;
    overflow-x: hidden;

    .layers {
      .layer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;

        padding: 6px 16px;
        cursor: pointer;

        &:hover {
          background-color: $highlightGrey;

          .layer-name {
            color: $primaryRed;
          }

          i.delete-widget {
            display: block;
          }
        }
      }

      .layer-name {
        display: flex;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        gap: 16px;
        color: $primaryText;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;

        i {
          color: $secondaryText;
        }
      }

      i.delete-widget {
        display: none;
        cursor: pointer;
        width: 24px;
        color: $iconsBlack;
      }
    }
  }
</style>
