<template>
  <BaseModal ref="modal" class="permissions-modal" title="Permissions" :scrollable="true">
    <template v-if="isLoading">
      <Loader />
    </template>
    <template v-else>
      <div class="roles">
        <div v-for="role in roles" class="role-item" :key="role.id">
          <div class="left">
            <span v-if="role.icon" class="material-symbols-outlined icon">{{ role.icon }}</span>
            <span class="name">{{ role.name }}</span>
          </div>
          <div class="right">
            <div class="users-count">
              <span class="count">{{ role.userCount }}</span>
              <span class="material-icons-outlined icon">person_outline</span>
            </div>
            <div class="toggle">
              <SwitchToggle
                :value="selectedRoles[role.id]"
                @input="(value) => handlePermissionChange(role.id, value)"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <BaseButton theme="secondary" size="sm" @click="() => close()">Cancel</BaseButton>
      <BaseButton size="sm" :loading="isSaving" @click="handleOnSave">Save</BaseButton>
    </template>
  </BaseModal>
</template>

<script>
  import Vue from 'vue';
  import Switch from '@/components/common/Switch.vue';
  import Loader from '@/components/common/Loader.vue';

  export default {
    name: 'PermissionsModal',

    props: {
      isLoading: {
        type: Boolean,
        default: false,
      },
      isSaving: {
        type: Boolean,
        default: false,
      },
      roles: {
        type: Array,
        default: () => [],
      },
      currentAccess: {
        type: Object,
        default: () => ({}),
      },
    },

    components: {
      SwitchToggle: Switch,
      Loader,
    },

    data() {
      return {
        selectedRoles: {},
      };
    },

    watch: {
      isLoading(value) {
        if (!value) {
          this.selectedRoles = { ...this.currentAccess };
        }
      },
    },

    methods: {
      open() {
        this.$refs.modal.open();
      },
      close() {
        this.$refs.modal.close();
      },

      handlePermissionChange(roleId, value) {
        Vue.set(this.selectedRoles, roleId, value);
      },

      handleOnSave() {
        this.$emit(
          'onSave',
          Object.keys(this.selectedRoles)
            .filter((key) => this.selectedRoles[key])
            .map((key) => +key),
        );
      },
    },
  };
</script>

<style lang="scss" scoped>
  .permissions-modal {
    ::v-deep .ui-modal-content {
      width: 400px !important;
      min-height: 556px;

      .ui-modal-footer {
        gap: 16px;
      }
    }

    .roles {
      display: flex;
      flex-direction: column;

      .role-item {
        width: 100%;
        border-bottom: 1px solid #f0f0f0;
        padding: 14px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left {
          display: flex;
          align-items: center;
          gap: 6px;
          .icon {
            font-size: 20px;
            color: #e3003a;
          }
          .name {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            display: flex;
            align-items: center;
            color: #151515;
          }
        }
        .right {
          display: flex;
          align-items: center;
          gap: 26px;

          .users-count {
            display: flex;
            align-items: center;
            gap: 6px;
            .count {
              font-weight: 500;
              font-size: 14px;
              height: 18px;
              color: #151515;
            }

            .icon {
              font-size: 20px;
              color: #333332;
            }
          }

          .toggle {
            height: 24px;
          }
        }
      }
    }
  }
</style>
