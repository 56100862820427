module.exports = {
  welcome: 'Velkommen',
  to: 'til',
  activities_title : 'Aktiviteter',
  courts_title: 'Baner',
  book_with: 'Book med',
  members: 'Medlemmer',
  playing_now: 'Spiller lige nu',
  vacant_time: 'Ledig Bane',
  vacant_times: 'Ledige baner',
  tracks: 'Baner',
  offers_for_you: 'Tilbud til medlemmer',
  contact_us: 'Kontakt os i receptionen for info',
  available_time: 'Tilgængelig bane',

  monday: 'Mandag',
  tuesday: 'Tirsdag',
  wednesday: 'Onsdag',
  thursday: 'Torsdag',
  friday: 'Fredag',
  saturday: 'Lørdag',
  sunday: 'Søndag',

  game_strength: 'Spilstyrke',
  players: 'Spiller',

  app_description: 'Spiller du tennis, badminton, bordtennis, squash eller padle? Bliv medlem af Matchi og gør din hverdag mere fleksibel.',
};
