var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-view-container"},[(!_vm.multipleFolder)?_c('ContentAddModal',{ref:"contentAddModal",attrs:{"folder":_vm.selectedFolder},on:{"new-content-added":_vm.reloadContent}}):_vm._e(),(_vm.requestStatus === 'loading')?_c('div',{staticClass:"loader"},[_c('div',[_vm._v("Loading....")])]):_c('div',{staticClass:"content"},[_c('div',{staticClass:"content-body"},[_c('div',{staticClass:"folders"},[_c('div',{staticClass:"folders-header section-header"},[_c('div',{staticClass:"header-top"},[_c('div',{staticClass:"title"},[_vm._v("Folders")]),_c('i',{directives:[{name:"show",rawName:"v-show",value:(!_vm.multipleFolder && !_vm.multipleContent),expression:"!multipleFolder && !multipleContent"}],staticClass:"material-icons-outlined align-middle button",on:{"click":_vm.handleFolderAddModal}},[_vm._v(" add ")])]),_c('div',{staticClass:"header-bottom"},[_c('i',{staticClass:"filter-icon material-icons-outlined button",class:{ active: _vm.showFolderFilters },on:{"click":_vm.toggleFolderFilter}},[_vm._v(" filter_alt ")]),_c('SearchButton',{model:{value:(_vm.folderSearchTerm),callback:function ($$v) {_vm.folderSearchTerm=$$v},expression:"folderSearchTerm"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.multipleContent),expression:"!multipleContent"}],staticClass:"material-icons-outlined button inline-block cursor-pointer ml-auto",class:{ 'text-primary-danger': _vm.multipleFolder },attrs:{"title":"Multiple selection"},on:{"click":_vm.handleMultipleFolderSelection}},[_vm._v(" select_all ")])],1),(_vm.showFolderFilters)?_c('div',{staticClass:"filters"},[_c('SelectFilter',{attrs:{"label":"File Type","options":[
                { label: 'All', value: '' },
                { label: 'Apps', value: 'apps' },
                { label: 'Videos', value: 'video' },
                { label: 'Images', value: 'image' },
                { label: 'PDFs', value: 'pdf_file' },
              ]},model:{value:(_vm.folderFilterTerm),callback:function ($$v) {_vm.folderFilterTerm=$$v},expression:"folderFilterTerm"}})],1):_vm._e()]),_c('div',{staticClass:"folders-body scrollbar"},[(!_vm.multipleFolder)?_c('FolderItem',{attrs:{"totals":_vm.withoutFolderTotals,"active":!_vm.selectedRoot,"focused":!_vm.selectedRoot && !_vm.selectedItem},on:{"select-folder":_vm.selectPath}}):_vm._e(),_vm._l((_vm.folders),function(folder){return _c('FolderItem',{key:folder.full_path,attrs:{"folder":folder,"isFolder":"","active":_vm.isFolderActive(folder),"focused":_vm.isFolderFocused(folder),"selectedFolder":_vm.selectedFolder,"selectedRoot":_vm.selectedRoot},on:{"select-folder":_vm.selectPath,"onDelete":_vm.handleDeleteUpdateFolder,"onEdit":_vm.handleDeleteUpdateFolder}})})],2)]),(!_vm.multipleFolder)?_c('div',{staticClass:"items"},[_c('div',{staticClass:"items-header section-header"},[_c('div',{staticClass:"header-top"},[_c('div',{staticClass:"title"},[_vm._v("Items")]),_c('i',{staticClass:"material-icons-outlined align-middle button",on:{"click":_vm.handleAddFile}},[_vm._v(" add ")])]),_c('div',{staticClass:"header-bottom"},[_c('i',{staticClass:"filter-icon material-icons-outlined button",class:{ active: _vm.showContentFilters },on:{"click":_vm.toggleContentFilter}},[_vm._v(" filter_alt ")]),_c('SearchButton',{model:{value:(_vm.contentSearchTerm),callback:function ($$v) {_vm.contentSearchTerm=$$v},expression:"contentSearchTerm"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.multipleFolder),expression:"!multipleFolder"}],staticClass:"material-icons-outlined button inline-block cursor-pointer ml-auto",class:{ 'text-primary-danger': _vm.multipleContent },attrs:{"title":"Multiple selection"},on:{"click":_vm.handleMultipleItemSelection}},[_vm._v(" select_all ")])],1),(_vm.showContentFilters)?_c('div',{staticClass:"filters"},[_c('SelectFilter',{attrs:{"label":"File Type","options":[
                { label: 'All', value: '' },
                { label: 'Apps', value: 'apps' },
                { label: 'Videos', value: 'video' },
                { label: 'Images', value: 'image' },
                { label: 'PDFs', value: 'pdf_file' },
              ]},model:{value:(_vm.contentFilterTerm),callback:function ($$v) {_vm.contentFilterTerm=$$v},expression:"contentFilterTerm"}})],1):_vm._e()]),(_vm.filteredContent.length)?_c('div',{staticClass:"items-body scrollbar"},_vm._l((_vm.filteredContent),function(item){return _c('ContentItem',{key:item.app_id || item.uuid,staticClass:"item",class:_vm.itemSelectedClass(item),attrs:{"item":item},on:{"select-item":_vm.handleSelectItem}})}),1):_c('div',{staticClass:"items-body"},[_c('div',{staticClass:"empty"},[_vm._v("No items found")])])]):_vm._e()])]),(_vm.multipleFolder || _vm.multipleContent)?[_c('MultiSelectSettings',{attrs:{"items":_vm.multipleItems,"isFolder":_vm.multipleFolder},on:{"onClose":_vm.cancelMultiSelection,"onDelete":function($event){return _vm.cancelMultiSelection(true)},"onUpdate":_vm.onItemUpdated}})]:[(_vm.selectedItem && !_vm.multipleContent)?_c('ContentItemSetting',{attrs:{"item":_vm.selectedItem},on:{"onUpdate":_vm.onItemUpdated,"onDelete":_vm.handleDeletedItem}}):(_vm.selectedRoot)?_c('ContentFolderSetting',{attrs:{"folder":_vm.selectedFolder},on:{"onDelete":_vm.handleDeleteUpdateFolder}}):_c('ContentItemSettingPlaceholder',{on:{"onUpload":_vm.handleAddFile}})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }