import {
  LayoutRichTextWidget,
  RichTextWidgetObject,
  TemplateRichTextWidget,
  RichTextWidgetType,
} from '@/types/widgets/richTextWidget/richTextWidget';
import { generateWidgetId } from '@/models/layoutDesigner';
import { LayoutPosition, Position } from '@/types/widgets/widgets';
import cloneDeep from 'lodash/cloneDeep';

export function duplicateRichTextWidget<T extends RichTextWidgetType>(oldWidget: T): T {
  // we want to deep clone so the vuex store don't reference to the old widget
  const newWidget = cloneDeep(oldWidget);

  const newObject: RichTextWidgetObject = {
    wid: generateWidgetId(),
    zIndex: newWidget.object.zIndex,
    type: newWidget.object.type,
    textSize: newWidget.object.textSize,
    name: newWidget.object.name,
    userText: newWidget.object.userText,
    width: newWidget.object.width,
    height: newWidget.object.height,
    x: newWidget.object.x,
    y: newWidget.object.y,
  };

  var newPosition: Position | LayoutPosition;
  if ('horizontal' in newWidget.position && 'vertical' in newWidget.position) {
    newPosition = {
      horizontal: newWidget.position.horizontal,
      vertical: newWidget.position.vertical,
    } as LayoutPosition;
  } else {
    newPosition = newWidget.position as Position;
  }

  return {
    widgetData: {
      type: newObject.type,
      name: newObject.name,
      config: newObject,
      userText: newObject.userText,
    },
    type: newWidget.type,
    object: newObject,
    position: newPosition,
    itemType: newWidget.itemType,
  } as T;
}

// js component cannot use generics, so we use a wrapper function
export function duplicateTemplateRichTextWidget(oldWidget: any): TemplateRichTextWidget {
  return duplicateRichTextWidget<TemplateRichTextWidget>(oldWidget);
}

// js component cannot use generics, so we use a wrapper function
export function duplicateLayoutRichTextWidget(oldWidget: any): LayoutRichTextWidget {
  return duplicateRichTextWidget<LayoutRichTextWidget>(oldWidget);
}
