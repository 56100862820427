<template>
  <form v-show="showModal" ref="licenseModal" @submit.prevent="createLicense">
    <Modal
      @closeModal="closeModal"
      :isSaving="isSaving"
      title="Create License"
      :okFunction="createLicense"
      :modalStyles="{ width: '400px' }"
      submitForm
      avoidClickOutside
    >
      <template v-slot:body>
        <div class="group-body">
          <div class="form-group">
            <label for="lName">
              <span class="label">License Name</span>
              <input
                type="text"
                name="name"
                id="lName"
                required
                minlength="3"
                maxlength="40"
                v-model="name"
                placeholder="Set license name"
              />
            </label>
          </div>

          <div class="form-group">
            <label for="startTime">
              <span class="label">Start date</span>
              <input
                type="datetime-local"
                name="startedAt"
                id="startTime"
                required
                v-model="startedAt"
                placeholder="Set start time"
              />
            </label>
          </div>

          <div class="form-group">
            <label for="expiredTime">
              <span class="label">Expired time</span>
              <select name="expiredTime" id="expiredTime" v-model="expiredTime" required>
                <option :value="YEAR_IN_DAYS">1 Year</option>
                <option :value="YEAR_IN_DAYS * 2">2 Years</option>
                <option :value="YEAR_IN_DAYS * 3">3 Years</option>
              </select>
            </label>
          </div>

          <div class="form-group">
            <label for="licenseCount">
              <span class="label">Number of licenses</span>
              <input
                type="number"
                name="licenseCount"
                id="licenseCount"
                v-model="licenseCount"
                placeholder="Number of licenses"
                min="1"
                max="10000"
                required
              />
            </label>
          </div>

          <div class="form-group">
            <label for="desc">
              <span class="label">Description</span>
              <textarea name="desc" id="desc" class="form-control" v-model="description"></textarea>
            </label>
          </div>
        </div>
      </template>
    </Modal>
  </form>
</template>

<script>
  import moment from 'moment';
  import Modal from '@/components/common/Modal.vue';
  import { createBulkLicense, createLicense } from '@/api/organisation';
  import { ADMIN_ORGANISATIONS_GET } from '@/store/actions/organisation';

  export default {
    name: 'CreateLicenseModal',
    components: { Modal },
    props: {
      organisationId: {
        type: String,
        required: true,
      },
    },

    data() {
      const yearInDays = 365;

      return {
        showModal: false,
        isSaving: false,
        name: '',
        description: '',
        expiredTime: yearInDays,
        startedAt: null,
        licenseCount: 1,
        YEAR_IN_DAYS: yearInDays,
      };
    },

    created() {
      if (!this.startedAt) {
        this.startedAt = moment().format('yyyy-MM-DD\TH:mm');
      }
    },

    mounted() {
      this.showModal = true;
      console.log(this.expiredTime);
    },

    methods: {
      async createLicense() {
        if (!this.name) {
          this.$toasted.global.general_error({ message: 'Name is required' });
          return;
        }
        if (!this.startedAt) {
          this.$toasted.global.general_error({ message: 'Start time is required' });
          return;
        }
        if (this.licenseCount < 1) {
          this.$toasted.global.general_error({
            message: 'Minimum number of licenses should be 1.',
          });
          return;
        }
        this.isSaving = true;

        try {
          const data = {
            name: this.name,
            description: this.description,
            started_at: moment(this.startedAt).toISOString(),
            expired_time: this.expiredTime,
          };

          if (this.licenseCount > 1) {
            const bulkData = [];

            for (let i = 0; i < this.licenseCount; i++) {
              bulkData.push(data);
            }
            await createBulkLicense(this.organisationId, bulkData);
          } else {
            await createLicense(this.organisationId, data);
          }
          this.$emit('onCreated');
          this.$toasted.global.general_success({
            message: 'License created successfully',
          });
          await this.$store.dispatch(ADMIN_ORGANISATIONS_GET);
          this.closeModal();
        } catch {
          this.$toasted.global.general_error({
            message: 'Failed to create license',
          });
        }
        this.isSaving = false;
      },

      closeModal() {
        this.showModal = false;
        this.$emit('closeModal');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .group-body {
    display: grid;
    row-gap: 24px;
    column-gap: 16px;

    .form-group {
      width: 100%;
      margin-bottom: 0;

      label {
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
        gap: 8px;

        font-weight: 500;
        line-height: 24px;

        .label {
          display: flex;
          gap: 8px;
          align-items: center;

          font-size: 14px;
        }

        input,
        select {
          padding: 8px 16px;

          border: 2px solid $borderGrey;
          border-radius: 8px;
          font-size: 16px;
        }
      }
    }

    .description {
      grid-column: 1/3;
    }
  }
</style>
