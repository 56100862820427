<template>
  <div class="layout-panel d-flex flex-column">
    <div v-if="layout" class="details flex-grow-1">
      <div class="form-group layout-input name">
        <label for="layout-name">Name</label>
        <input
          class="form-control"
          type="text"
          id="layout-name"
          placeholder="Name"
          maxlength="40"
          v-model="layoutName"
          @change="updateLayout"
        />
      </div>

      <div class="form-group aspect-ratio layout-input">
        <label for="layout-aspect-ratio">Aspect ratio</label>
        <select
          class="custom-select"
          id="layout-aspect-ratio"
          v-model="aspectRatio"
          @change="settingDefaultResolution"
          style="zoom: 1.1"
        >
          <option :value="LAYOUT_ASPECT_RATIOS.FOUR_THREE" style="zoom: 1.1">4 : 3</option>
          <option :value="LAYOUT_ASPECT_RATIOS.FIVE_FOUR" style="zoom: 1.1">5 : 4</option>
          <option :value="LAYOUT_ASPECT_RATIOS.SIXTEEN_NINE" style="zoom: 1.1">16 : 9</option>
          <option :value="LAYOUT_ASPECT_RATIOS.SIXTEEN_TEN" style="zoom: 1.1">16 : 10</option>
          <option :value="LAYOUT_ASPECT_RATIOS.TWENTY_ONE" style="zoom: 1.1">21 : 9</option>
        </select>
      </div>

      <div class="form-group aspect-ratio layout-input">
        <label for="layout-aspect-ratio">Resolution</label>
        <select
          class="custom-select"
          id="layout-aspect-ratio"
          v-model="resolution"
          @change="updateResolution"
        >
          <option
            style="zoom: 1.1"
            v-for="resolution in resolutions"
            :key="resolution.name"
            :value="resolution.name"
          >
            {{ resolution.name }} - {{ resolution.width }} x {{ resolution.height }}
          </option>
        </select>
      </div>

      <div class="sub-section-title">Grid (px)</div>
      <div class="form-group hidden position">
        <div class="form-row">
          <div class="input-group col">
            <div class="input-label">
              <span class="input-group-text">X</span>
            </div>
            <input type="number" class="form-control" id="layer-x" v-model="gridX" />
          </div>

          <div class="input-group col">
            <div class="input-label">
              <span class="input-group-text">Y</span>
            </div>
            <input type="number" class="form-control" v-model="gridY" />
          </div>
        </div>
      </div>

      <div class="sub-section-title">Background Color</div>
      <div class="form-group hidden size">
        <div class="form-row">
          <ColorPicker input-el-id="text-color" v-model="backgroundColor" @input="updateLayout" />

          <div class="col input-group"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ColorPicker from '@/components/ColorPicker.vue';
  import { LAYOUT_ASPECT_RATIOS, LAYOUT_RESOLUTIONS } from '@/models/layoutDesigner';
  import {
    LAYOUT_DESIGNER_SET_LAYOUT,
    LAYOUT_DESIGNER_UPDATE_WIDGET,
  } from '@/store/actions/layoutDesigner';

  export default {
    name: 'LayoutPanel',

    components: { ColorPicker },

    data() {
      return {
        LAYOUT_ASPECT_RATIOS,
        layoutName: '',
        aspectRatio: '',
        resolution: '',
        backgroundColor: '#FFFFFF',
        grid: null,
      };
    },

    watch: {
      layout() {
        this.setLayoutData();
      },
    },

    mounted() {
      this.setLayoutData();
    },

    computed: {
      layout() {
        return this.$store.state.layoutDesigner.layout;
      },

      gridX: {
        get() {
          return this.grid?.x;
        },
        set(value) {
          const xValue = Number(value);
          this.grid.x = xValue && xValue > 1 ? xValue : 1;
          this.updateLayout();
        },
      },
      gridY: {
        get() {
          return this.grid?.y;
        },
        set(value) {
          const yValue = Number(value);
          this.grid.y = yValue && yValue > 1 ? yValue : 1;
          this.updateLayout();
        },
      },

      resolutions() {
        return LAYOUT_RESOLUTIONS.resolutions[this.aspectRatio];
      },
    },

    methods: {
      async updateResolution() {
        try {
          const layoutChildren = Object.values(this.$store.state.layoutDesigner.widgets);

          const resolution = this.resolutions[this.resolution];
          const { width: screenWidth, height: screenHeight } = resolution;

          layoutChildren.forEach((widget) => {
            const newPosition = {
              vertical: {
                ...widget.position.vertical,
                ...this.calculatePosition(widget.position.vertical, {
                  screenWidth: screenHeight,
                  screenHeight: screenWidth,
                }),
              },
              horizontal: {
                ...widget.position.horizontal,
                ...this.calculatePosition(widget.position.horizontal, {
                  screenWidth,
                  screenHeight,
                }),
              },
            };

            const updatedWidget = {
              ...widget,
              position: {
                ...widget.position,
                ...newPosition,
              },
            };

            this.$store.commit(LAYOUT_DESIGNER_UPDATE_WIDGET, {
              widget: updatedWidget,
              update: 'Layout Panel',
              selectWidget: false,
            });
          });

          this.updateLayout();
        } catch (error) {
          console.log('updateResolution ~ error:', error);
        }
      },

      calculatePosition(position, { screenWidth, screenHeight }) {
        const { height, width, x, y } = position;

        return {
          widthPixels: (width * screenWidth) / 100,
          heightPixels: (height * screenHeight) / 100,
          xPixels: (x * screenWidth) / 100,
          yPixels: (y * screenHeight) / 100,
        };
      },

      setLayoutData() {
        const {
          name,
          aspectRatio,
          resolution,
          grid,
          backgroundColor = '#FFFFFF',
        } = this.layout ? this.layout.settings : {};

        this.layoutName = name;
        this.aspectRatio = aspectRatio;
        this.backgroundColor = backgroundColor;
        this.resolution = resolution;
        this.grid = grid || {
          x: 20,
          y: 20,
        };
      },

      settingDefaultResolution(event) {
        const newAspectRatio = event.target.value;
        this.resolution = LAYOUT_RESOLUTIONS.defaultValues[newAspectRatio];

        this.updateResolution();
      },

      async updateLayout() {
        if (this.layoutName === '') {
          this.$toasted.global.general_error({
            message: 'Layout name cannot be empty',
          });
          return;
        }

        this.$store.commit(LAYOUT_DESIGNER_SET_LAYOUT, {
          layout: {
            ...this.layout,
            name: this.layoutName,
            settings: {
              ...this.layout?.settings,
              name: this.layoutName,
              aspectRatio: this.aspectRatio,
              backgroundColor: this.backgroundColor,
              resolution: this.resolution,
              grid: this.grid,
              settings: undefined, //Fixing Recursive problem
            },
          },
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .layout-panel {
    .used-widget-list-expand {
      cursor: pointer;
    }
  }
</style>
