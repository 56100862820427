<template>
  <div class="settings">
    <form action="POST" class="trafiklab-form" @submit.prevent="saveSteps">
      <div class="setting-block">
        <h5 class="font-weight-md">Sources</h5>
        <p class="mb-0">
          Search a stop and add as source to be able to show it in a widget on a screen.
        </p>

        <div class="subtitle">Add source</div>
        <label for="sourcesInput" class="w-100">
          <div class="d-flex align-items-center">
            <StopSearchInput @update:input="onSelectedStop" />
          </div>
        </label>
        <div class="pt-3 border-top">
          <div class="subtitle">Selected sources</div>
          <div class="sources-section">
            <div class="sources-container">
              <div v-if="stops.length === 0" class="no-sources">No sources added yet!</div>
              <div v-for="(stop, index) in stops" class="source-item" :key="index">
                <div class="source-content">
                  <div class="source-label">Source name</div>
                  <div class="source">
                    {{ stop.name }}
                  </div>
                </div>
                <div class="dropdown">
                  <i
                    class="material-icons align-middle more-buttons dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    >more_horiz</i
                  >
                  <div class="role-options dropdown-menu dropdown-menu-right">
                    <button type="button" class="dropdown-item" @click="removeStop(stop)">
                      <i class="material-icons align-middle">delete</i>
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
  import SendButton from '@/components/common/SendButton.vue';
  import SearchInput from '@/components/common/SearchInput.vue';
  import StopSearchInput from '@/components/apps/trafiklab/StopSearchInput.vue';

  export default {
    name: 'SourcesSettings',

    components: { StopSearchInput, SendButton, SearchInput },

    props: {
      stopData: {
        type: Array,
        default: [],
      },
    },

    data() {
      return {
        trafiklabStops: [],
        selectedStop: '',
        stops: [],
      };
    },

    mounted() {
      if (this.stopData) {
        this.stops = this.stopData;
      }
    },

    methods: {
      onSelectedStop(value) {
        this.selectedStop = value;
        this.addStop();
      },

      addStop() {
        if (!this.selectedStop) {
          return;
        }
        const isSelectedStopInTheList = this.stops.find((stop) => stop.id === this.selectedStop.id);

        if (!isSelectedStopInTheList) {
          this.stops.push(this.selectedStop);
        }
        this.selectedStop = '';
      },

      removeStop(selectedStop) {
        this.stops = this.stops.filter((stop) => stop.id !== selectedStop.id);
      },

      save() {
        if (!this.stops.length) {
          this.$toasted.global.general_error({ message: 'No stop added!' });
          return;
        }
        this.$emit('onUpdate', this.stops);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .settings {
    padding: 14px;

    .trafiklab-form {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 40px;
    }

    input,
    select {
      color: black;
    }

    .integration-container {
      display: flex;
      width: 100%;
      align-items: center;
      box-shadow: -2px 1px 4px 0 rgba(0, 0, 0, 0.2);

      .integration-image {
        display: block;
        height: 100%;
        width: 100px;
        padding: 20px;
        background: url('../../../assets/img/apps/trafiklab.png') no-repeat center;
        background-size: 60px;
      }

      .integration-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        padding: 16px;
        gap: 20px;

        .integration-status {
          color: $darkgrey;
          font-weight: bold;
          margin-left: -10px;

          span {
            color: $green;
          }
        }

        .integration-button {
          background-color: $red;
          color: white;

          padding: 4px 10px;
          font-size: 12px;
          letter-spacing: 1px;
          border-radius: 10px;
        }
      }

      @media screen and (max-width: 900px) {
        flex-direction: column;
      }
    }
  }
  .setting-block {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    h3 {
      font-size: 32px;
      font-weight: normal;
    }

    .subtitle {
      font-size: 14px;
      font-weight: 500;
      color: black;
      position: relative;
      padding-left: 25px;
    }
    .subtitle::before {
      content: url('../../../assets/icon/sub-title-icon.png');
      position: absolute;
      left: 0;
    }
  }
  .sources-section {
    width: 100%;

    .sources-container {
      .no-sources {
        padding-top: 6px;
      }

      .source-item {
        display: flex;
        align-items: center;
        background-color: $lightgrey;
        border-bottom: 1px solid $grey300;
        padding: 8px 12px 10px 8px;
        gap: 12px;
        justify-content: space-between;

        .source-content {
          border: 1px solid #eee;
          border-radius: 10px;
          padding: 4px 10px;
          background-color: #fdfdfd;
          width: 80%;

          .source-label {
            font-size: 12px;
            margin-bottom: 6px;
          }

          .source {
            color: black;
            font-size: 14px;
            letter-spacing: 1px;
            font-weight: bold;

            overflow-wrap: break-word;
            word-wrap: break-word;
            -ms-word-break: break-word;
            word-break: break-word;
          }
        }
      }
    }

    .title {
      font-weight: bold;
    }
  }

  .send-button {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .dropdown-toggle {
    &:hover {
      cursor: pointer;
      color: #3c9cfc;
    }

    &::after {
      display: none !important;
    }
  }
  .stop-add-btn {
    min-width: 104px;
  }
</style>
