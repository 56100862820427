import { render, staticRenderFns } from "./OrientationTabs.vue?vue&type=template&id=56e96062&scoped=true"
import script from "./OrientationTabs.vue?vue&type=script&lang=js"
export * from "./OrientationTabs.vue?vue&type=script&lang=js"
import style0 from "./OrientationTabs.vue?vue&type=style&index=0&id=56e96062&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56e96062",
  null
  
)

export default component.exports