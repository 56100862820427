<template>
  <div class="button-widget-panel scrollbar">
    <div class="measurements-container">
      <div class="measurements">
        <div class="form-group hidden position">
          <div class="form-row">
            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">X</span>
              </div>
              <input
                type="number"
                class="form-control"
                id="layer-x"
                v-model="xPosition"
                :disabled="isWidgetLocked"
              />
            </div>

            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">Y</span>
              </div>
              <input
                type="number"
                class="form-control"
                v-model="yPosition"
                :disabled="isWidgetLocked"
              />
            </div>
          </div>

          <div class="form-row">
            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">W</span>
              </div>
              <input
                type="number"
                class="form-control"
                id="layer-width"
                v-model="width"
                :disabled="isWidgetLocked"
              />
            </div>

            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">H</span>
              </div>
              <input
                type="number"
                class="form-control"
                v-model="height"
                :disabled="isWidgetLocked"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="extra-icons">
        <LockIcon :locked="isWidgetLocked" />

        <PixelIcon :usePixels="usePixels" @clicked="changeValuesMode" />
      </div>
    </div>

    <div class="form-group template-input">
      <label for="button-name">Widget Name</label>
      <input
        class="form-control"
        type="text"
        id="button-name"
        placeholder="Name"
        maxlength="40"
        v-model="value.object.name"
        :disabled="isWidgetLockedByOverride"
      />
    </div>

    <div class="form-group">
      <div class="form-label">Widget Type</div>
      <div class="buttons-container">
        <button
          type="button"
          :class="{
            'button-2-primary': !value.object.imageAsButton,
            'button-2-secondary': value.object.imageAsButton,
          }"
          @click="setImageAsButtonState(false)"
        >
          Button
        </button>

        <button
          type="button"
          :class="{
            'button-2-primary': value.object.imageAsButton,
            'button-2-secondary': !value.object.imageAsButton,
          }"
          @click="setImageAsButtonState(true)"
        >
          Image
        </button>
      </div>
    </div>

    <template v-if="value.object.imageAsButton">
      <div class="button-image-container">
        <div class="preview-container">
          <div class="image-thumbnail">
            <div v-if="!value.object.imageUrl" class="no-image">No Image Selected</div>
            <img v-else class="image" :src="value.object.thumbnailUrl" alt="Preview Pop-up" />
          </div>
          <button type="button" class="button-2-secondary" @click="openContentBrowser(false)">
            Select Image
          </button>
        </div>

        <ImageMode
          class="picture-mode"
          v-model="value.object.imageMode"
          label="Pictures display mode"
        />
      </div>
    </template>

    <template v-else>
      <div class="color-row caption-color-container">
        <div class="template-input form-group">
          <label for="button-caption">Button Caption</label>
          <input
            class="form-control"
            type="text"
            id="button-caption"
            placeholder="Add a Caption"
            maxlength="40"
            v-model="value.object.text"
            :disabled="isWidgetLockedByOverride"
          />
        </div>

        <div class="color-section" v-click-outside="closeButtonColorPicker">
          <div
            class="color-button"
            @click="openColorPicker('backgroundColor', true)"
            title="Background Color"
          >
            <img class="logo w-165" src="@/assets/icon/Colors.svg" alt="Background Color" />
            <div class="color-bar" :style="{ backgroundColor: value.object.backgroundColor }"></div>
          </div>
          <div
            class="color-button"
            @click="openColorPicker('borderColor', true)"
            title="Border Color"
          >
            <i class="material-icons-outlined align-middle">square</i>
            <div class="color-bar" :style="{ backgroundColor: value.object.borderColor }"></div>
          </div>

          <Sketch
            v-if="showButtonColorPicker"
            class="picker"
            :value="value.object[pickerColorKey]"
            @input="updateButtonColor"
          />
        </div>
      </div>

      <div class="form-group template-input font-container">
        <div class="form-label">Font</div>
        <FontSelector
          :disabled="isWidgetLockedByOverride"
          :value="value.object.textFont"
          :fonts="userFonts"
          @update:value="updateButtonFont"
        ></FontSelector>
      </div>

      <div class="text-row">
        <div class="template-input form-group">
          <label for="text-size">Text Size</label>

          <input
            type="number"
            class="form-control"
            id="text-size"
            :disabled="isWidgetLockedByOverride"
            v-model="textSize"
          />
        </div>

        <div class="template-input form-group">
          <label class="font-poppins t-sm" for="font-weight">Font Weight</label>

          <select class="form-control" id="font-weight" v-model="value.object.fontWeight">
            <option :value="ButtonTextWidthType.thin">Thin</option>
            <option :value="ButtonTextWidthType.medium">Medium</option>
            <option :value="ButtonTextWidthType.bold">Bold</option>
          </select>
        </div>

        <div class="color-section" v-click-outside="closeTextColorPicker" title="Text Color">
          <div class="color-button" @click="openColorPicker('textColor', false)">
            <i class="material-icons-outlined align-middle">text_fields</i>
            <div class="color-bar" :style="{ backgroundColor: value.object.textColor }"></div>
          </div>

          <Sketch
            v-if="showTextColorPicker"
            class="picker"
            :value="value.object[pickerColorKey]"
            @input="updateButtonColor"
          />
        </div>
      </div>

      <div class="form-group form-range layout-input text-size">
        <div class="slider-top">
          <div class="form-label">Corner Radius</div>
          <div class="value">{{ value.object.borderRadius }}</div>
        </div>

        <div class="slider-container">
          <VueSlider
            v-model="value.object.borderRadius"
            id="button-radius"
            :dotStyle="sliderStyle.dot"
            :railStyle="sliderStyle.baseLine"
            :processStyle="sliderStyle.valueLine"
            :disabled="isWidgetLockedByOverride"
            :interval="1"
            :min="1"
            :max="100"
            tooltip="none"
            useKeyboard
          ></VueSlider>
        </div>
      </div>

      <div class="form-group form-range layout-input text-size">
        <div class="slider-top">
          <div>
            <div class="custom-control custom-switch layouts">
              <input
                type="checkbox"
                class="custom-control-input"
                id="text-border"
                v-model="borderDisplay"
              />
              <label class="custom-control-label" for="text-border">Border</label>
            </div>
          </div>

          <div class="value">{{ value.object.borderWidth }}</div>
        </div>

        <div class="slider-container">
          <VueSlider
            v-model="value.object.borderWidth"
            id="button-width"
            :dotStyle="sliderStyle.dot"
            :railStyle="sliderStyle.baseLine"
            :processStyle="sliderStyle.valueLine"
            :disabled="isWidgetLockedByOverride"
            :interval="1"
            :min="1"
            :max="100"
            tooltip="none"
            useKeyboard
          ></VueSlider>
        </div>
      </div>

      <div class="form-group">
        <div class="form-label">Effects</div>
        <div class="buttons-container">
          <button
            type="button"
            :class="{
              'button-2-primary': !value.object.embossed,
              'button-2-secondary': value.object.embossed,
            }"
            @click="setButtonDesign(false)"
          >
            Flat
          </button>

          <button
            type="button"
            :class="{
              'button-2-primary': value.object.embossed,
              'button-2-secondary': !value.object.embossed,
            }"
            @click="setButtonDesign(true)"
          >
            Embossed
          </button>
        </div>
      </div>

      <div class="form-group hidden button-css">
        <div class="form-group">
          <div class="custom-control custom-switch layouts">
            <input
              type="checkbox"
              class="custom-control-input"
              id="shadow-switch"
              v-model="value.object.displayShadow"
            />
            <label class="custom-control-label" for="shadow-switch">Shadow</label>
          </div>
        </div>

        <div class="form-row">
          <div class="col">
            <color-picker
              input-el-id="button-color"
              :disabled="isWidgetLockedByOverride"
              v-model="value.object.shadowColor"
            />
          </div>

          <div class="col input-group">
            <div class="input-label">
              <span class="input-group-text">
                <i class="material-icons-outlined align-middle">opacity</i>
              </span>
            </div>
            <input
              type="number"
              class="form-control"
              id="button-opacity"
              min="1"
              max="100"
              :disabled="isWidgetLockedByOverride"
              v-model="shadowOpacity"
            />
          </div>
        </div>
      </div>

      <div class="form-group form-range layout-input text-size">
        <div class="slider-top">
          <div class="form-label">Blur</div>
          <div class="value">{{ value.object.shadowBlur }}</div>
        </div>

        <div class="slider-container">
          <VueSlider
            v-model="value.object.shadowBlur"
            id="button-blur"
            :dotStyle="sliderStyle.dot"
            :railStyle="sliderStyle.baseLine"
            :processStyle="sliderStyle.valueLine"
            :disabled="isWidgetLockedByOverride"
            :interval="1"
            :min="1"
            :max="100"
            tooltip="none"
            useKeyboard
          ></VueSlider>
        </div>
      </div>
    </template>

    <div class="sidebar-dropdown">
      <div
        class="sidebar-dropdown-handle"
        :class="{ isOpen: isDropdownOpen }"
        @click="toggleDropdown"
      >
        <span class="title">Pop-up content</span>
        <span class="icon">
          <i class="material-icons-outlined align-middle button">
            {{ isDropdownOpen ? 'expand_less' : 'expand_more' }}
          </i>
        </span>
      </div>

      <div v-show="isDropdownOpen" class="dropdown-body" ref="contentContainer">
        <div class="template-input form-group">
          <label class="font-poppins t-sm" for="font-weight">Content Type</label>

          <select
            class="form-control"
            id="font-weight"
            v-model="value.object.contentType"
            @change="resetContentLink"
          >
            <option :value="ButtonContentType.IMAGE">Image</option>
            <option :value="ButtonContentType.VIDEO">Video</option>
            <option :value="ButtonContentType.URL">URL</option>
          </select>
        </div>

        <div
          v-if="value.object.contentType === ButtonContentType.URL"
          class="form-group template-input"
        >
          <label for="content-link">Link</label>
          <input
            class="form-control"
            type="text"
            id="content-link"
            placeholder="https://..."
            maxlength="500"
            v-model="value.object.contentLink"
            :disabled="isWidgetLockedByOverride"
          />
        </div>

        <button
          v-else-if="!value.object.contentLink"
          type="button"
          class="button-2-primary"
          @click="openContentBrowser(true)"
        >
          Select Content
        </button>

        <div class="preview-container" v-else>
          <div class="image-thumbnail" @click="openPlayerModal">
            <img class="image" :src="value.object.contentThumbnailLink" alt="Preview Pop-up" />

            <div v-if="isVideoContent" class="button-layer">
              <div class="button-container">
                <i class="material-icons align-middle">play_arrow</i>
              </div>
            </div>
          </div>
          <button type="button" class="button-2-secondary" @click="openContentBrowser(true)">
            {{ `Change ${isVideoContent ? 'Video' : 'Image'}` }}
          </button>
        </div>

        <div class="template-input form-group">
          <div class="input-title">
            <label for="modal-time">Idling time-out</label>
            <div class="sub-title">sec</div>
          </div>

          <input
            type="number"
            class="form-control"
            id="modal-time"
            :disabled="isWidgetLockedByOverride"
            v-model="idleTime"
          />
        </div>
      </div>
    </div>

    <ContentBrowserModal
      ref="content_browser_modal"
      @submitted="handleItemSelect"
      :allow-multiple-item-select="false"
      :filterItemTypes="filterItemTypes"
    >
    </ContentBrowserModal>
  </div>
</template>

<script>
  import { Sketch } from 'vue-color';
  import ClickOutside from 'vue-click-outside';
  import VueSlider from 'vue-slider-component';
  import 'vue-slider-component/theme/default.css';

  import ColorPicker from '@/components/ColorPicker.vue';
  import PixelIcon from '@/components/layoutDesigner/editor/settingPanel/PixelIcon.vue';
  import LockIcon from '@/components/layoutDesigner/editor/settingPanel/LockIcon.vue';
  import ContentBrowserModal from '@/components/content/ContentBrowserModal.vue';
  import ImageMode from '@/components/templates/editor/settingPanel/formControls/ImageMode';
  import FontSelector from '@/components/common/fontSelector/FontSelector.vue';

  import widgetMixin from './widgetMixin';

  import { restrictToBounds } from '@/helpers/draggable';
  import {
    ButtonContentType,
    ButtonTextWidthType,
  } from '@/types/widgets/buttonWidget/buttonWidget';
  import { getCloudfrontUrl } from '@/helpers/utils';
  import { OPEN_PLAYER_MODAL } from '@/store/actions/player';

  export default {
    name: 'ButtonWidgetPanel',

    mixins: [widgetMixin],

    components: {
      ColorPicker,
      ContentBrowserModal,
      PixelIcon,
      ImageMode,
      Sketch,
      FontSelector,
      LockIcon,
      VueSlider,
    },

    directives: { ClickOutside },

    data() {
      return {
        showButtonColorPicker: false,
        showTextColorPicker: false,
        isDropdownOpen: !this.value.object.contentLink,
        showContentBrowser: false,
        pickerColorKey: null,
        modalForContentMode: true,
        ButtonTextWidthType,
        sliderStyle: {
          baseLine: { backgroundColor: '#F0F0F0', borderRadius: 0, marginLeft: '6px' },
          valueLine: { backgroundColor: '#E3003A', borderRadius: 0 },
          dot: {
            backgroundColor: '#E3003A',
            border: '2px solid white',
            boxShadow: 'none',
            marginTop: '-4px',
            height: '22px',
            width: '22px',
          },
        },
        ButtonContentType,
        filterItemTypes: [this.value.object.contentType],
      };
    },

    computed: {
      userFonts() {
        return this.$store.getters.userFonts;
      },

      isVideoContent() {
        return this.value.object.contentType === ButtonContentType.VIDEO;
      },

      borderDisplay: {
        get() {
          return this.value.object.displayBorder;
        },
        set(value) {
          this.$set(this.value.object, 'displayBorder', value);
        },
      },

      shadowOpacity: {
        get() {
          return this.value.object.shadowOpacity;
        },
        set(value) {
          if (Number(value) < 0) return 1;

          this.$set(this.value.object, 'shadowOpacity', value);
        },
      },

      textSize: {
        get() {
          return this.value.object.textSize;
        },
        set(value) {
          this.$set(this.value.object, 'textSize', restrictToBounds(Number(value), 8, 120));
        },
      },

      idleTime: {
        get() {
          return this.value.object.idleTimeout;
        },
        set(value) {
          if (Number(value) < 0) return 1;

          this.$set(this.value.object, 'idleTimeout', restrictToBounds(Number(value), 1, 3600));
        },
      },
    },

    methods: {
      setImageAsButtonState(imageMode = false) {
        this.$set(this.value.object, 'imageAsButton', imageMode);
      },

      updateButtonFont(newFont) {
        this.$set(this.value.object, 'textFont', newFont);
      },

      setButtonDesign(embossed = false) {
        this.$set(this.value.object, 'embossed', embossed);
      },

      openColorPicker(colorKey, isButton = true) {
        if (this.isWidgetLockedByOverride) return;

        if (isButton) {
          this.showButtonColorPicker = true;
        } else {
          this.showTextColorPicker = true;
        }

        this.pickerColorKey = colorKey;
      },

      closeButtonColorPicker() {
        if (!this.pickerColorKey || !this.showButtonColorPicker) return;

        this.showButtonColorPicker = false;
        this.pickerColorKey = null;
      },

      closeTextColorPicker() {
        if (!this.pickerColorKey || !this.showTextColorPicker) return;

        this.showTextColorPicker = false;
        this.pickerColorKey = null;
      },

      updateButtonColor(color) {
        if (!this.pickerColorKey) return;

        this.$set(this.value.object, this.pickerColorKey, color.hex);
      },

      toggleDropdown() {
        this.isDropdownOpen = !this.isDropdownOpen;
        const { contentContainer } = this.$refs;

        if (this.isDropdownOpen && contentContainer.childNodes[0]) {
          setTimeout(function () {
            contentContainer.childNodes[0].scrollIntoView({
              behavior: 'smooth',
            });
          }, 100);
        }
      },

      openContentBrowser(forContentMode = true) {
        this.modalForContentMode = forContentMode;
        this.filterItemTypes = [
          forContentMode ? this.value.object.contentType : ButtonContentType.IMAGE,
        ];

        this.$refs.content_browser_modal.open();
      },

      closeContentBrowser() {
        this.$refs.content_browser_modal.close();
      },

      resetContentLink() {
        this.$set(this.value.object, 'contentLink', '');
        this.$set(this.value.object, 'contentThumbnailLink', '');
      },

      handleItemSelect([item]) {
        const itemUrl = getCloudfrontUrl(item.item_url);
        const thumbnailUrl = getCloudfrontUrl(item.thumbnail_url);

        this.closeContentBrowser();

        if (!this.modalForContentMode) {
          this.$set(this.value.object, 'imageUrl', itemUrl);
          this.$set(this.value.object, 'thumbnailUrl', thumbnailUrl);
          return;
        }

        this.$set(this.value.object, 'contentLink', itemUrl);
        this.$set(this.value.object, 'contentThumbnailLink', thumbnailUrl);
      },

      openPlayerModal() {
        this.$store.commit(OPEN_PLAYER_MODAL, this.value.object);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .button-widget-panel {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .color-picker-container {
      position: relative;
      top: 0;
      left: 0;

      #button-text-color {
        cursor: pointer;
      }

      .color-picker {
        position: absolute;
        top: 40px;
        z-index: 20;
      }
    }

    .sub-section-title {
      padding-top: 4px;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      width: 100%;
    }
  }

  .button-widget-panel {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: 0px;
    padding-right: 10px;
  }

  .buttons-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }

  .color-button {
    background-color: $lineGrey;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: 40px;
    padding: 8px 6px 6px 6px;
    width: 40px;

    i {
      color: $tableText;
      font-size: 18px;
      height: 18px;
      width: 18px;
    }

    img {
      margin-top: -1px;
      height: 20px;
      width: 20px;
    }

    .color-bar {
      border-radius: 5px;
      height: 4px;
      width: 100%;
      background-color: black;
    }
  }

  .caption-color-container {
    display: grid;
    grid-template-columns: 1fr 90px;
    gap: 10px;
  }

  .color-section {
    display: flex;
    gap: 10px;
    align-items: end;
    position: relative;
  }

  .picker {
    position: absolute;
    top: 40px;
    right: 0;
    z-index: 20;
  }

  .text-row,
  .color-row {
    display: grid;
    position: relative;
    gap: 10px;
    margin: 0 0 1rem;

    .form-group {
      margin-bottom: 0;
    }
  }

  .text-row {
    grid-template-columns: 1fr 1fr 40px;
  }

  .color-row {
    grid-template-columns: 1fr 90px;
  }

  .form-range {
    display: flex;
    gap: 16px;
    flex-direction: column;

    &.form-group {
      margin-bottom: 20px;
    }

    .slider-top {
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: 1fr 48px;

      .form-label {
        margin: 0;
      }

      .value {
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        text-align: center;
      }
    }
  }

  .slider-container {
    padding-right: 12px;
  }

  .sidebar-dropdown {
    width: 100%;
    display: flex;
    flex-direction: column;

    .sidebar-dropdown-handle {
      cursor: pointer;
      user-select: none;
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;

      &.isOpen {
        .icon {
          color: #e3003a;
        }
      }

      .title {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;

        color: #333332;
      }

      .icon {
        color: #333332;
      }
    }
  }

  .dropdown-body {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .form-group {
      margin: 0;
    }
  }

  .button-image-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 16px;

    .preview-container {
      .no-image {
        display: flex;
        align-items: center;
        justify-content: center;

        background-color: $lineGrey2;
        min-height: 100px;
        width: 100%;
      }

      .image-thumbnail:hover {
        cursor: auto;
      }

      img {
        max-height: 300px;
      }
    }
  }

  .input-title {
    display: flex;
    justify-content: space-between;

    .sub-title {
      color: $secondaryText;
      font-size: 14px;
    }
  }

  .preview-container {
    display: flex;
    flex-direction: column;
    gap: 16px;

    button {
      width: 140px;
      padding: 10px;
    }
  }

  .image-thumbnail {
    display: flex;
    justify-content: center;
    position: relative;

    &:hover {
      cursor: zoom-in;
    }

    img {
      max-height: 500px;
    }
  }

  .button-layer {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2000;
    width: 100%;
    height: 100%;

    .button-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 64px;
      height: 64px;

      border-radius: 100%;
      background: rgba(255, 255, 255, 0.45);
      border: 2px solid rgba(255, 255, 255, 0.15);

      i {
        font-size: 32px;
        color: $primaryRed;
      }
    }
  }
</style>
