<template>
  <Modal
    @closeModal="closeModal"
    v-show="showModal"
    :isSaving="isSaving"
    title="Add linked slide"
    :disabled="!validInput"
    :okFunction="addLink"
    okButtonLabel="Add to Playlist"
    :modalStyles="{ width: '400px' }"
  >
    <template v-slot:body>
      <div class="form-group">
        <div class="form-group template-input name">
          <label for="urlName">Name</label>
          <input class="form-control" type="text" id="urlName" maxlength="60" v-model="linkName" />
        </div>

        <div class="form-group template-input name">
          <label for="urlLink">Link to website</label>
          <input
            class="form-control"
            type="text"
            id="urlLink"
            maxlength="260"
            placeholder="http://..."
            v-model="linkUrl"
          />
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
  import Modal from '@/components/common/Modal.vue';

  import { WIDGET_TYPES } from '@/models/layoutDesigner';

  import { apiCreateLayerWidget } from '@/api/layouts';

  export default {
    name: 'AddLinkModal',

    components: {
      Modal,
    },

    props: {
      playlist: {
        type: Object,
        default: () => null,
      },
      isOverride: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        showModal: false,
        isSaving: false,
        linkUrl: '',
        linkName: 'Link',
      };
    },

    mounted() {
      this.showModal = true;
    },

    computed: {
      validInput() {
        const validLink = !!this.linkUrl && this.linkUrl.length > 3;
        const validName = !!this.linkName && this.linkName.length > 3;

        return validLink && validName;
      },
    },

    methods: {
      async addLinkOverride() {
        this.isSaving = true;

        try {
          const layerData = {
            wid: `${Date.now()}${Math.random()}`,

            name: this.linkName,
            type: WIDGET_TYPES.LAYER,
            playlist: this.playlist.playlist_id,

            linkUrl: this.linkUrl,
            backgroundColor: 'none',
            opacity: 100,
          };

          const response = await apiCreateLayerWidget(layerData);
          const newLayer = response.data;

          this.$emit('addLayerToPlaylist', newLayer);
        } catch (error) {
          this.$toasted.global.general_error({
            message: String(error),
          });
        }

        this.isSaving = false;
        this.closeModal();
      },

      addLink() {
        this.isOverride ? this.addLinkOverride() : this.addLinkToPlaylist();
      },

      addLinkToPlaylist() {
        const layerData = {
          display_timer: 15,
          item_type: WIDGET_TYPES.LAYER,

          name: this.linkName,
          type: WIDGET_TYPES.LAYER,
          playlist: this.playlist.playlist_id,

          linkUrl: this.linkUrl,
          backgroundColor: 'none',
          opacity: 100,
        };

        this.$emit('addLayerToPlaylist', layerData);

        this.closeModal();
      },

      closeModal() {
        this.showModal = false;
        this.$emit('closeModal');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .form-group {
    width: 100%;
    margin-top: 24px;

    label {
      display: flex;
      flex-direction: column;
      margin-bottom: 8px;
      gap: 8px;

      font-weight: 500;
      line-height: 24px;
      font-size: 16px;

      input {
        padding: 8px 16px;

        border: 2px solid $borderGrey;
        border-radius: 8px;
        font-size: 16px;
      }
    }
  }
</style>
