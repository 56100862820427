<template>
  <button :type="type" :class="classes" :disabled="isLoading || disabled" @click="$emit('click')">
    <i v-if="isLoading" class="material-icons align-middle spin-animation">refresh</i>
    <span v-else>{{ title }}</span>
  </button>
</template>

<script>
  export default {
    name: 'SendButton',

    props: {
      isLoading: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      title: {
        type: String,
        default: '',
      },
      classes: {
        type: String,
        default: 'button-2-primary',
      },
      type: {
        type: String,
        default: 'button',
      },
    },
  };
</script>
