<template>
  <div id="settings-view" class="container" v-if="loaded">
    <div class="text-center picture-container">
      <div class="row">
        <div class="col-md-12">
          <div class="img-placeholder">
            <i v-if="user.avatar_url === null" class="material-icons align-middle"
              >account_circle</i
            >
            <img class="img-rounded" v-else :src="user.avatar_url + '?' + Date.now()" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <a href="#" class="update-picture" v-on:click.prevent="updatePicture">Update Picture</a>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="offset-lg-4 offset-md-3 col-lg-4 col-md-12">
          <form
            class="form-group settings-form"
            enctype="multipart/form-data"
            @submit.prevent="updateSettings"
          >
            <div class="row">
              <div class="col-md-10 offset-md-1">
                <div class="form-label-group mb-3">
                  <input
                    v-model="userData.name"
                    type="text"
                    id="inputName"
                    class="form-control"
                    placeholder="Name"
                    maxlength="40"
                    name="name"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-10 offset-md-1">
                <div class="form-label-group mb-3">
                  <input
                    v-model="userData.phone"
                    type="tel"
                    id="inputPhone"
                    class="form-control"
                    placeholder="Phone"
                    maxlength="20"
                    name="phone"
                  />
                </div>
              </div>
            </div>

            <div class="row" v-if="is_administrator">
              <div class="col-md-10 offset-md-1">
                <div class="form-label-group mb-3">
                  <div class="form-check">
                    <input
                      v-model="user.is_administrator"
                      type="checkbox"
                      id="radioGlobalAdmin"
                      class="form-check-input mr-2"
                      disabled
                    />
                    <label class="form-check-label" for="radioGlobalAdmin">{{
                      $lang.user_settings.global_admin
                    }}</label>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" v-if="is_local_administrator">
              <div class="col-md-10 offset-md-1">
                <div class="form-label-group mb-3">
                  <div class="form-check">
                    <input
                      v-model="user.is_local_administrator"
                      type="checkbox"
                      id="radioLocalAdmin"
                      class="form-check-input mr-2"
                      disabled
                    />
                    <label class="form-check-label" for="radioLocalAdmin">{{
                      $lang.user_settings.local_admin
                    }}</label>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 password-container">
                <a href="#" class="update-password" @click.prevent="showUpdatePasswordModal"
                  >Update Password</a
                >
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-center">
                <button class="btn border-primary-red update-settings" type="submit">
                  Update Settings
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <BaseModal class="update-picture-modal" ref="updatePictureRef" title="Update Picture">
        <form
          ref="avatarForm"
          id="uploadFileForm"
          enctype="multipart/form-data"
          v-on:submit.prevent="uploadFile"
        >
          <div class="modal-body">
            <p>{{ $lang.user_settings.image_types_allowed }}</p>
            <ul>
              <li>jpg/jpeg</li>
              <li>png</li>
            </ul>

            <div class="form-group form-label-group">
              <div class="custom-file">
                <input
                  v-on:change="showFiles"
                  ref="uploadFiles"
                  type="file"
                  class="custom-file-input"
                  id="uploadFile"
                  accept="image/png, image/jpeg"
                  lang="se"
                  :file="file"
                />
                <label class="custom-file-label" for="uploadFile">{{
                  $lang.user_settings.select_files
                }}</label>

                <div class="invalid-feedback">Ogiltig filtyp</div>
              </div>
            </div>

            <span
              v-if="!!file"
              v-bind:key="file.name"
              class="badge badge-pill badge-secondary text-truncate"
              style="max-width: 100%"
              >{{ file.name }}</span
            >
          </div>

          <div class="modal-footer">
            <div class="modal-buttons">
              <BaseButton
                size="sm"
                theme="secondary"
                @click="closeAvatarModal"
                :disabled="objectPutStatus === 'loading'"
              >
                {{ !this.file ? 'Close' : 'Cancel' }}
              </BaseButton>

              <BaseButton
                v-if="this.file"
                size="sm"
                @click.native="$refs.avatarForm.$el.submit()"
                :loading="objectPutStatus === 'loading'"
              >
                Upload
              </BaseButton>
            </div>
          </div>
        </form>
      </BaseModal>

      <!-- Password update modal -->
      <div
        class="modal fade"
        id="updatePasswordModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="updatePasswordModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="px-3">
              <div class="modal-header px-0">
                <h5 class="modal-title" id="updatePasswordModalLabel">Update Password</h5>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>

            <form
              id="updatePasswordForm"
              v-if="!updatedPassword"
              v-on:submit.prevent="updatePassword()"
            >
              <div class="modal-body">
                <alert
                  class="alert-danger"
                  v-if="!validatePasswords()"
                  icon="error"
                  :dismissible="false"
                  message="Password and confirmed password are not identical"
                ></alert>

                <div class="form-group form-label-group">
                  <div class="form-label-group mb-3">
                    <!-- <label for="inputPassword">New Password</label>-->
                    <input
                      v-model="userData.password"
                      type="password"
                      id="inputPassword"
                      @change.prevent="validatePasswords()"
                      class="form-control"
                      placeholder="Password"
                      maxlength="50"
                    />
                  </div>
                  <div class="form-label-group mb-3">
                    <!--<label for="inputConfirmPassword">Confirm Password</label>-->
                    <input
                      v-model="userData.confirmPassword"
                      type="password"
                      @change.prevent="validatePasswords()"
                      id="inputConfirmPassword"
                      class="form-control"
                      placeholder="Confirm Password"
                      maxlength="50"
                    />
                  </div>
                </div>
              </div>

              <div class="modal-footer">
                <div class="modal-buttons">
                  <button type="button" class="btn bg-white btn-sm border" data-dismiss="modal">
                    Cancel
                  </button>
                  <button
                    type="submit"
                    class="btn bg-primary-red btn-sm ml-4 border"
                    :disabled="!validatePasswords()"
                  >
                    Update
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import NavBar from '@/components/NavBar.vue';
  import Alert from '@/components/Alert.vue';

  import { USER_REQUEST, USER_UPDATE, USER_UPLOAD_AVATAR } from '../store/actions/user';
  import { ALLOWED_UPLOAD_IMAGES } from '@/constant/const';

  const $ = window.jQuery;

  export default {
    name: 'Account',

    components: {
      NavBar,
      Alert,
    },

    data() {
      return {
        objectPutStatus: '',
        userData: {
          user_id: '',
          username: '',
          name: '',
          phone: '',
          password: '',
          photo: '',
          confirmPassword: '',
        },
        loaded: false,
        updatedPassword: false,
        file: null,
      };
    },

    computed: {
      ...mapState({
        user: (state) => state.user.user,

        user_id: (state) => state.auth.user_id,
        username: (state) => state.user.user.username,
        phone: (state) => state.user.user.phone,
        password: (state) => state.auth.password,

        created: (state) => state.auth.created,
        session_token: (state) => state.auth.session_token,
        is_administrator: (state) => state.auth.is_administrator,
        is_local_administrator: (state) => state.auth.is_local_administrator,
      }),
    },
    methods: {
      showFiles(event) {
        this.file = null;

        try {
          const uploadedFile = event.target.files[0];
          const fileExtension = uploadedFile.name.split('.').pop().toLowerCase();

          if (ALLOWED_UPLOAD_IMAGES.includes(fileExtension)) {
            this.file = uploadedFile;
          } else {
            this.$toasted.global.general_error({
              message: 'File extension not allowed',
            });
          }
        } catch (error) {
          this.$toasted.global.general_error({
            message: 'Error updating your picture. Please try again later.',
          });
        }

        this.$refs.uploadFiles.value = null;
      },

      closeAvatarModal() {
        this.$refs.updatePictureRef.close();
      },

      async uploadFile() {
        this.objectPutStatus = 'loading';

        try {
          await this.$store.dispatch(USER_UPLOAD_AVATAR, { user: this.user, avatar: this.file });

          await this.$store.dispatch(USER_REQUEST, this.user_id);

          this.loaded = true;

          this.closeAvatarModal();

          this.file = null;

          this.$toasted.global.general_success({
            message: this.$lang.user_settings.avatar_updated,
          });
        } catch (error) {
          this.$toasted.global.general_error({
            message: 'Error updating your picture. Please try again later.',
          });
        }

        this.objectPutStatus = '';
      },

      updatePicture() {
        this.$refs.updatePictureRef.open();
      },

      showUpdatePasswordModal() {
        $('#updatePasswordModal').modal('toggle');
      },
      updatePassword() {
        if (this.validatePasswords()) {
          this.user.password = this.userData.password;
          this.$store
            .dispatch(USER_UPDATE, this.user)
            .then((resp) => {
              this.$toasted.global.general_success({
                message: this.$lang.user_settings.password_updated,
              });
            })
            .catch((e) => {
              this.$toasted.global.general_error({
                message: 'Error updating password',
              });
            });
        }
        $('#updatePasswordModal').modal('toggle');
      },

      updateSettings() {
        if (this.$store.getters.isAuthenticated) {
          const data = { ...this.user, ...this.userData };

          this.$store.dispatch(USER_UPDATE, data).then((resp) => {
            this.$toasted.global.general_success({
              message: this.$lang.user_settings.settings_updated,
            });
          });
        }
      },
      validatePasswords() {
        return this.userData.password === this.userData.confirmPassword;
      },
    },
    created() {
      if (this.$store.getters.isAuthenticated) {
        this.$store.dispatch(USER_REQUEST, this.user_id).then(() => {
          this.loaded = true;
          this.$set(this.userData, 'user_id', this.user_id);
          this.$set(this.userData, 'username', this.username);
          this.$set(this.userData, 'phone', this.user.phone);
          this.$set(this.userData, 'name', this.user.name);
        });
      }
    },
  };
</script>

<style lang="scss" scoped>
  @import '../sass/custom-bootstrap.scss';

  #settings-view {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
  }

  .update-picture-modal {
    ::v-deep .ui-modal-content {
      max-width: 400px;
    }

    .modal-footer {
      display: flex;
      justify-content: flex-end;
      margin-left: -32px;
      margin-right: -32px;

      .modal-buttons {
        display: flex;
        gap: 16px;
        margin: 0;
      }
    }

    .modal-body {
      ul {
        color: $secondaryText;
      }
    }
  }
  .badge {
    padding: 10px;
    font-weight: normal;
    font-size: 12px;
  }

  .header-text {
    margin-top: 6rem;
  }

  .picture-container {
    margin-bottom: 29px;
    margin-top: 46px;

    i {
      color: $primaryRed;
      font-size: 150px;
    }

    img {
      border-radius: 100px;
      width: 126px;
      height: 126px;
      overflow: hidden;
      margin-bottom: 20px;
    }
  }

  .update-picture,
  .update-password {
    font-size: 0.9em;
    font-weight: bold;
    color: $primaryRed;

    &:hover {
      color: #fb386aff;
    }
  }

  .password-container {
    text-align: center;
    margin-top: 16px;
    margin-bottom: 14px;
  }

  .btn {
    &.update-settings {
      border-width: 2px;
      font-weight: 600;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      background: transparent;
      color: $primaryRed;

      &:hover {
        background-color: $primaryRed;
        color: #fff;
      }
    }
  }

  .chat {
    position: fixed;
    bottom: 5%;
    right: 2%;
    height: 100px;
    width: 100px;
    border-radius: 50px;

    .chat-link {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
    }
  }

  .contact-btn {
    background: url('../assets/img/message-circle.svg') no-repeat;
    height: 30px;
    width: 30px;
    display: block;
    text-indent: -9999px;
    background-size: 30px 30px;

    &:hover {
      background: url('../assets/img/message-circle-hover.svg') no-repeat;
      background-size: 30px 30px;
    }
  }

  .modal-title {
    line-height: 1;
  }

  .modal-footer {
    background: #fafafa;
    border-top: none;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    padding: 24px 32px;
  }

  .modal-dialog {
    width: 436px;
  }

  //.modal-title {
  //  color: $textpurple;
  //}

  .modal-header h5 {
    font-size: 1.6em;
  }
  .modal {
    & input[type='password'],
    & input[type='text'],
    & input[type='email'] {
      height: 36px;
      font-size: 1em;
    }
  }

  .modal-buttons {
    .btn {
      border-radius: 0.5rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem;

      &.bg-primary-red {
        background-color: $primaryRed;
        color: #fff;
      }
    }
  }
</style>
