import { DEFAULT_TEXT_WIDGET, generateWidgetId } from '@/models/layoutDesigner';
import { LayoutPosition, Position } from '@/types/widgets/widgets';
import {
  LayoutTextWidget,
  TemplateTextWidget,
  TextWidgetObject,
  TextWidgetType,
} from '@/types/widgets/textWidget/textWidget';
import cloneDeep from 'lodash/cloneDeep';

export function duplicateTextWidget<T extends TextWidgetType>(oldWidget: T): T {
  // we want to deep clone so the vuex store don't reference to the old widget
  const newWidget = cloneDeep(oldWidget);

  const newObject: TextWidgetObject = {
    wid: generateWidgetId(),
    zIndex: newWidget.object?.zIndex,
    type: newWidget.object?.type,
    name: newWidget.object?.name,
    ticker: newWidget.object?.ticker,
    width: newWidget.object?.width,
    height: newWidget.object?.height,
    x: newWidget.object?.x,
    y: newWidget.object?.y,
    headerOn: newWidget.object?.headerOn,
    headerTextColor: newWidget.object?.headerTextColor,
    headerFont: newWidget.object?.headerFont,
    headerTextAlignment: newWidget.object?.headerTextAlignment,
    headerTextOpacity: newWidget.object?.headerTextOpacity,
    headerBackgroundColor: newWidget.object?.headerBackgroundColor,
    headerBackgroundOpacity: newWidget.object?.headerBackgroundOpacity,
    headerTextSize: newWidget.object?.headerTextSize,
    headerTextSeparation: newWidget.object?.headerTextSeparation,
    paddingHeader: newWidget.object?.paddingHeader,
    headerText: newWidget.object?.headerText,
    textColor: newWidget.object?.textColor,
    textFont: newWidget.object?.textFont,
    opacity: newWidget.object?.opacity,
    paddingText: newWidget.object?.paddingText,
    backgroundColor: newWidget.object?.backgroundColor,
    backgroundOpacity: newWidget.object?.backgroundOpacity,
    mode: newWidget.object?.mode,
    userText: newWidget.object?.userText,
    alignment: newWidget.object?.alignment,
    maximalTextSize: newWidget.object?.maximalTextSize,
    textSize: newWidget.object?.textSize,
    tickerDuration: newWidget.object?.tickerDuration,
    tickerReverse: newWidget.object?.tickerReverse,
    marqueeMode: newWidget.object?.marqueeMode ?? DEFAULT_TEXT_WIDGET.marqueeMode,
    marqueeSpeed: newWidget.object?.marqueeSpeed ?? DEFAULT_TEXT_WIDGET.marqueeSpeed,
  };

  var newPosition: Position | LayoutPosition;
  if ('horizontal' in newWidget.position && 'vertical' in newWidget.position) {
    newPosition = {
      horizontal: newWidget.position.horizontal,
      vertical: newWidget.position.vertical,
    } as LayoutPosition;
  } else {
    newPosition = newWidget.position as Position;
  }

  return {
    widgetData: {
      type: newObject.type,
      name: newObject.name,
      config: newObject,
      userText: newObject.userText,
    },
    type: newWidget.type,
    object: newObject,
    position: newPosition,
    itemType: newWidget.itemType,
  } as T;
}

// js component cannot use generics, so we use a wrapper function
export function duplicateTemplateTextWidget(oldWidget: any): TemplateTextWidget {
  return duplicateTextWidget<TemplateTextWidget>(oldWidget);
}

// js component cannot use generics, so we use a wrapper function
export function duplicateLayoutTextWidget(oldWidget: any): LayoutTextWidget {
  return duplicateTextWidget<LayoutTextWidget>(oldWidget);
}
