<template>
  <div class="text-widget-panel scrollbar">
    <div class="form-group layout-input name">
      <label for="text-name">Name</label>
      <input
        class="form-control"
        type="text"
        id="text-name"
        placeholder="Name"
        maxlength="40"
        v-model="value.object.name"
      />
    </div>

    <div class="measurements-container">
      <div class="measurements">
        <div class="form-group hidden position">
          <div class="form-row">
            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">X</span>
              </div>
              <input
                type="number"
                class="form-control"
                id="layer-x"
                v-model="xPosition"
                :disabled="isWidgetLocked"
              />
            </div>

            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">Y</span>
              </div>
              <input
                type="number"
                class="form-control"
                v-model="yPosition"
                :disabled="isWidgetLocked"
              />
            </div>
          </div>
        </div>

        <div class="form-group hidden size">
          <div class="form-row">
            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">W</span>
              </div>
              <input
                type="number"
                class="form-control"
                id="layer-width"
                v-model="width"
                :disabled="isWidgetLocked"
              />
            </div>

            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">H</span>
              </div>
              <input
                type="number"
                class="form-control"
                v-model="height"
                :disabled="isWidgetLocked"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="extra-icons">
        <LockIcon :locked="isWidgetLocked" />

        <PixelIcon :usePixels="usePixels" @clicked="changeValuesMode" />
      </div>
    </div>
    <div class="form-group layout-input font-input">
      <label for="text-font-family">
        <span>Font</span>
        <div class="import-link" @click.prevent="showFontsModal = true">
          <i class="material-icons-outlined align-middle button">upload</i>
          Import fonts
        </div>
      </label>
    </div>
    <FontsModal @closeModal="showFontsModal = false" v-if="showFontsModal" />
  </div>
</template>

<script>
  import _ from 'lodash';

  import PixelIcon from '@/components/layoutDesigner/editor/settingPanel/PixelIcon.vue';
  import LockIcon from '@/components/layoutDesigner/editor/settingPanel/LockIcon.vue';
  import FontsModal from '@/components/common/FontsModal.vue';
  import widgetMixin from '@/components/layoutDesigner/editor/settingPanel/widgetMixin';

  import { DEFAULT_FONTS } from '@/constant/const';

  export default {
    name: 'RichTextWidgetPanel',

    mixins: [widgetMixin],

    components: { PixelIcon, FontsModal, LockIcon },

    data() {
      return {
        defaultFontFamilyOptions: DEFAULT_FONTS,
        showFontsModal: false,
      };
    },

    computed: {
      headerTextSize: {
        get() {
          return this.value.object.headerTextSize;
        },
        set(value) {
          this.value.object.headerTextSize = Number(value);
        },
      },

      headerTextSeparation: {
        get() {
          return this.value.object.headerTextSeparation;
        },
        set(value) {
          this.value.object.headerTextSeparation = Number(value);
        },
      },

      paddingHeader: {
        get() {
          return this.value.object.paddingHeader;
        },
        set(value) {
          this.value.object.paddingHeader = Number(value);
        },
      },

      paddingText: {
        get() {
          return this.value.object.paddingText;
        },
        set(value) {
          this.value.object.paddingText = Number(value);
        },
      },

      userFonts() {
        return this.$store.getters.userFonts;
      },
    },

    filters: {
      capitalize(v) {
        return _.capitalize(v);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .text-widget-panel {
    display: flex;
    flex-direction: column;
    gap: 4px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 10px;

    .rss-mode {
      background-color: #ecf0f1;
    }

    .alignments {
      display: flex;
      gap: 8px;

      .alignment-mode {
        width: 26px;
        margin: 0 2px;

        input:checked + label {
          color: $primaryText;
          background: #f5f5f5;
          padding: 16px;
        }

        input + label {
          width: 30px;
          height: 30px;
          border-radius: 4px;
          color: $secondaryText;
        }

        .alignment-label {
          cursor: pointer;
        }
      }
    }

    .content-section,
    .header-section {
      padding-top: 6px;

      .section-title {
        font-weight: bold;
        padding-top: 16px;
        font-size: 16px;
        border-top: 1px solid $lineGrey;
        margin-bottom: 10px;
      }
    }

    .sub-section-title {
      padding-top: 4px;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      width: 100%;
    }

    .custom-switch {
      margin-left: 4px;
    }
  }

  .form-group.mode {
    display: flex;
    align-items: center;
    padding-top: 16px;

    .custom-control-inline {
      display: flex;
      align-items: center;
    }
    .custom-control-label {
      cursor: pointer;

      &::before {
        border: none;
        background-color: $primaryRed;
        top: 2px;
      }

      &::after {
        top: 1.5px;
      }
    }

    .custom-control-input:focus ~ .custom-control-label::before {
      box-shadow: none;
    }
  }

  .font-input {
    label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .import-link {
        cursor: pointer;
        border-bottom: 2px solid transparent;

        &:hover {
          border-bottom-color: $primaryText;
        }
      }
    }
  }
</style>
