import * as PDFJS from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

PDFJS.GlobalWorkerOptions.workerSrc = pdfjsWorker;

// Read pdf file
function loadPdf(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      const pdfData = new Uint8Array(reader.result);
      const doc = PDFJS.getDocument({ data: pdfData });
      doc.promise.then((pdf) => resolve(pdf));
    };
    reader.readAsArrayBuffer(file);
  });
}

function readPage(doc, pageNumber) {
  return new Promise((resolve) => {
    doc.getPage(pageNumber).then((page) => {
      const viewport = page.getViewport({ scale: 1 });
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      const renderContext = {
        canvasContext: context,
        viewport,
      };
      const renderTask = page.render(renderContext);
      renderTask.promise.then(() => {
        const img = canvas.toDataURL('image/jpeg');
        resolve(img);
      });
    });
  });
}

function srcToFile(src, fileName, mimeType) {
  return fetch(src)
    .then((res) => res.arrayBuffer())
    .then((buf) => new File([buf], fileName, { type: mimeType }));
}

// PDF To base 64 images
export const pdfToBase64 = async (file) => {
  // Read file;
  const pdf = await loadPdf(file);
  const pagesPromises = [];
  const noOfPages = pdf.numPages;
  for (let i = 1; i <= noOfPages; i++) {
    pagesPromises.push(readPage(pdf, i));
  }
  const pages = await Promise.all(pagesPromises);

  return pages;
};

export const pdfToFiles = async (file) => {
  const pages = await pdfToBase64(file);

  const files = await Promise.all(
    pages.map((page, index) => srcToFile(page, `page-${index}.jpg`, 'image/jpeg')),
  );

  return files;
};
