<template>
  <div class="header">
    <LayoutsPreviewModal v-if="showPreviewModal" @closeModal="closeLayoutPreviewModal" />

    <div class="header-top">
      <div class="title">
        <template v-if="!!layout && layout.settings.name">
          <i class="material-icons-outlined align-middle">dashboard</i>
          {{ layout && layout.settings.name }}
        </template>
      </div>

      <div class="header-buttons">
        <i
          class="material-icons-outlined align-middle button"
          :class="{ active: gridIsOn }"
          @click="toggleGrid"
          title="Grid"
        >
          grid_on
        </i>

        <i
          class="material-icons-outlined align-middle button"
          :class="{ active: snapIsOn }"
          @click="toggleSnap"
          title="Snapping Objects"
        >
          align_horizontal_right
        </i>

        <ScreenRotationIcons
          class="button"
          :class="{ active: !isLayoutHorizontal }"
          :verticalScreens="1"
          hideNumbers
          @selected="changeLayoutOrientation(false)"
        />

        <ScreenRotationIcons
          class="button"
          :class="{ active: isLayoutHorizontal }"
          :horizontalScreens="1"
          hideNumbers
          @selected="changeLayoutOrientation(true)"
        />

        <i
          class="material-icons-outlined align-middle button"
          :class="{ active: !selectedWidget }"
          title="Layout Settings"
          @click="openLayoutSettings"
        >
          tune
        </i>

        <i
          class="material-icons-outlined align-middle button"
          title="Fullscreen"
          @click="openPreviewModal"
        >
          fullscreen
        </i>

        <button
          type="button"
          class="button-2-primary"
          :class="{ disabled: !layoutNeedSaving }"
          @click="saveChanges"
          :disabled="isSavingLayout || !layoutNeedSaving"
        >
          {{ isSavingLayout ? 'Saving...' : 'Save Changes' }}
        </button>

        <div class="dropdown">
          <i
            class="material-icons-outlined align-middle more-buttons dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            >more_vert</i
          >
          <div class="dropdown-menu dropdown-menu-right">
            <button type="button" class="dropdown-item" @click="$emit('openDeleteModal')">
              <i class="material-icons-outlined align-middle">delete</i>
              Delete
            </button>

            <button type="button" class="dropdown-item" v-if="isAdmin" @click="openPreviewer">
              <i class="material-icons-outlined align-middle">launch</i>
              Preview Mode
            </button>

            <button
              type="button"
              class="dropdown-item"
              v-if="isAdmin"
              @click="openCreateTemplateLayoutModal"
            >
              <i class="material-icons-outlined align-middle">dashboard_customize</i>
              Save as Layout Template
            </button>
          </div>
        </div>
      </div>
    </div>

    <CreateTemplateLayoutModal
      v-if="showCreateTemplateLayoutModal"
      :layoutId="layout?.layout_id"
      @closeModal="showCreateTemplateLayoutModal = false"
    />
  </div>
</template>

<script>
  import CreateTemplateLayoutModal from '@/components/layouts/CreateTemplateLayoutModal.vue';
  import LayoutsPreviewModal from '@/components/layouts/LayoutsPreviewModal.vue';
  import ScreenRotationIcons from '@/components/common/screens/ScreenRotationIcons.vue';

  import {
    LAYOUT_DELETE_REPLACED_WIDGET,
    LAYOUT_DESIGNER_DESELECT_WIDGET,
    LAYOUT_DESIGNER_TOGGLE_GRID,
    LAYOUT_DESIGNER_TOGGLE_SNAP,
    LAYOUT_REMOVE_MODIFIED_WIDGET,
    LAYOUT_ROTATION,
    REMOVE_DELETED_CHILDREN,
    REMOVE_FROM_PLAYLIST_ITEMS_CHANGES,
    REPLACE_DUMMMY_CHILD,
    SET_SAVE_LAYOUT,
    SET_SAVING_LAYOUT,
    UPDATE_SAVED_PLAYLIST_ITEM,
    UPDATE_WIDGET_THUMBNAIL,
  } from '@/store/actions/layoutDesigner';
  import { SET_PLAYLIST_UPDATE_STATE } from '@/store/actions/player';

  import { WIDGET_TYPES } from '@/models/layoutDesigner';

  import {
    apiAttachLayoutChild,
    apiCreateAppWidget,
    apiCreateLayerWidget,
    apiUpdateLayerWidget,
    apiUpdateLayout,
    apiUpdateLayoutChild,
    apiRemoveChild,
    apiReplaceChildItem,
    apiUpdateAppWidget,
  } from '@/api/layouts';
  import {
    apiAddAreaItems,
    apiCreatePlaylist,
    apiDeleteAreaItems,
    apiUpdatePlaylist,
  } from '@/api/playlist';

  import { IMAGE_MODE } from '@/models/layoutDesigner';
  import { CONTENT_TYPE_LOCAL_TEMPLATE, CONTENT_TYPE_TEMPLATE_APP } from '@/constant/const';
  import { PLAYLIST_ITEM_UPDATE } from '@/store/actions/playlist';

  export default {
    name: 'LayoutDesignerHeader',

    components: {
      CreateTemplateLayoutModal,
      LayoutsPreviewModal,
      ScreenRotationIcons,
    },

    data() {
      return {
        showPreviewModal: false,
        showCreateTemplateLayoutModal: false,
        errorsSavingLayout: false,
      };
    },

    computed: {
      layout() {
        return this.$store.state.layoutDesigner.layout;
      },

      layoutId() {
        return this.layout.layout_id;
      },

      widgets() {
        return this.$store.state.layoutDesigner.widgets;
      },

      gridIsOn() {
        return this.$store.state.layoutDesigner.showLayoutGrid;
      },

      snapIsOn() {
        return this.$store.state.layoutDesigner.isSnapGridActive;
      },

      isLayoutHorizontal() {
        return this.$store.state.layoutDesigner.isLayoutHorizontal;
      },

      isAdmin() {
        return this.$store.getters.isAdministrator;
      },

      selectedWidget() {
        return this.$store.state.layoutDesigner.selectedWidget;
      },

      layoutNeedSaving() {
        return this.$store.state.layoutDesigner.saveLayout;
      },

      widgetsToSave() {
        return this.$store.state.layoutDesigner.modifiedWidgets;
      },

      widgetsToReplace() {
        return this.$store.state.layoutDesigner.replacedWidgets;
      },

      isSavingLayout: {
        get() {
          return this.$store.state.layoutDesigner.isSavingLayout;
        },
        set(value) {
          this.$store.commit(SET_SAVING_LAYOUT, value);
        },
      },
    },

    methods: {
      async saveChanges() {
        this.isSavingLayout = true;
        this.errorsSavingLayout = false;

        let saved = await this.saveLayout();

        const shouldUpdateWidgets = Object.values(this.widgetsToSave).length;

        if (saved && shouldUpdateWidgets) {
          saved = await this.saveLayoutChildren();
        }

        if (saved) {
          this.$store.commit(SET_SAVE_LAYOUT, false);

          const warningType = !this.errorsSavingLayout ? 'general_success' : 'general_warning';
          this.$toasted.global[warningType]({
            message: !this.errorsSavingLayout
              ? 'Layout saved successfully'
              : 'Layout saved with some problems',
          });

          this.$store.commit(SET_PLAYLIST_UPDATE_STATE, true);
        }

        this.isSavingLayout = false;
      },

      async saveLayout() {
        try {
          const layoutImage = await this.$store.dispatch(UPDATE_WIDGET_THUMBNAIL);

          return await apiUpdateLayout(this.layoutId, {
            ...this.layout.settings,
            image: layoutImage,
          });
        } catch (error) {
          console.log('saveLayout error:', error);

          this.$toasted.global.general_error({
            message: 'Unable to save the Layout',
          });
        }
      },

      async saveLayoutChildren() {
        try {
          const createWidgetsPromises = [];
          const updateWidgetsPromises = [];
          const deteleWidgetsPromises = [];

          // CREATE/UPDATE WIDGETS
          const widgetsArray = Object.values(this.widgets);

          widgetsArray.forEach((widget) => {
            const widgetId = widget.assoc_id;

            if (!widgetId) {
              createWidgetsPromises.push(this.createWidget(widget));
              return;
            }

            const updateWidget = this.widgetsToSave[widgetId];

            if (updateWidget) {
              updateWidgetsPromises.push(this.updateWidget(widget));
            }
          });

          // DELETE WIDGETS
          const deletedWidgetsArray = Object.values(
            this.$store.state.layoutDesigner.deletedWidgets,
          );

          deletedWidgetsArray.forEach((widget) => {
            deteleWidgetsPromises.push(this.deleteWidget(widget));
          });

          await Promise.all([...updateWidgetsPromises, ...createWidgetsPromises]);

          return true;
        } catch (error) {
          console.log('saveLayoutChildren ~ error:', error);
          this.$toasted.global.general_error({
            message: 'Unable to save one or more widget(s)',
          });

          return false;
        }
      },

      async createWidget(widget) {
        switch (widget.itemType) {
          case WIDGET_TYPES.BUTTON:
          case WIDGET_TYPES.TEXT:
          case WIDGET_TYPES.RICH_TEXT:
          case WIDGET_TYPES.CLOCK:
            return this.createCommonWidget(widget);

          case WIDGET_TYPES.PDF:
          case WIDGET_TYPES.IMAGE:
          case WIDGET_TYPES.TEMPLATE:
          case WIDGET_TYPES.VIDEO:
          case WIDGET_TYPES.APP:
            const widgetData = {
              type: widget.type,
              position: widget.position,
              id:
                widget.type === WIDGET_TYPES.TEMPLATE
                  ? widget.object.template_id
                  : widget.object.item_id,
            };

            if (widget.itemType === WIDGET_TYPES.APP) {
              apiUpdateAppWidget(widget.object.item_id, widget.object);
            }

            return this.attachWidgetToLayout({
              widgetData,
              widgetType: widget.type,
              widget: widget.object,
            });

          case WIDGET_TYPES.PLAYLIST:
            return this.createPlaylist(widget);
          case WIDGET_TYPES.LAYER:
            return this.createLayerWidget(widget);
        }
      },

      async createLayerWidget(widget) {
        const createResponse = await apiCreateLayerWidget(widget.object);

        const widgetData = {
          type: WIDGET_TYPES.LAYOUT_ITEM,
          id: createResponse.data.item_id,
          position: widget.position,
        };

        await this.attachWidgetToLayout({
          widgetData,
          widgetType: WIDGET_TYPES.LAYER,
          widget: { ...createResponse.data, wid: widget.object.wid },
        });
      },

      async createCommonWidget(widget) {
        const response = await apiCreateAppWidget(widget.widgetData);

        const widgetData = {
          type: WIDGET_TYPES.LAYOUT_ITEM,
          id: response.data.item_id,
          position: widget.position,
        };

        const newWidget = {
          ...widget.object,
          ...response.data,
          ...response.data.config,
          type: widget.itemType,
          config: undefined,
        };

        return await this.attachWidgetToLayout({ widgetData, widget: newWidget });
      },

      async attachWidgetToLayout({ widgetData, widgetType = WIDGET_TYPES.LAYOUT_ITEM, widget }) {
        const updateResponse = await apiAttachLayoutChild({ layoutId: this.layoutId, widgetData });

        this.$store.commit(REPLACE_DUMMMY_CHILD, {
          widget: {
            ...updateResponse.data,
            object: widget,
            itemType: widgetType,
            type: widget.item_type || widgetType,
            assoc_id: updateResponse.data.id,
          },
        });
      },

      async updateWidget(widget) {
        switch (widget.itemType) {
          case WIDGET_TYPES.BUTTON:
          case WIDGET_TYPES.CLOCK:
          case WIDGET_TYPES.RICH_TEXT:
          case WIDGET_TYPES.TEXT:
            await apiUpdateAppWidget(widget.object.item_id, {
              config: widget.object,
            });
            break;
          case WIDGET_TYPES.IMAGE:
          case WIDGET_TYPES.PDF:
          case WIDGET_TYPES.TEMPLATE:
          case WIDGET_TYPES.VIDEO:
          case WIDGET_TYPES.APP:
            const widgetReplace = this.widgetsToReplace[widget.assoc_id];

            if (widget.itemType === WIDGET_TYPES.APP) {
              await apiUpdateAppWidget(widget.object.item_id, widget.object);
            }

            if (!widgetReplace) break;

            await apiReplaceChildItem(this.layout.layout_id, {
              id_new: widgetReplace,
              type: widget.type,
              assoc_id: widget.assoc_id,
            });

            this.$store.commit(LAYOUT_DELETE_REPLACED_WIDGET, widget.assoc_id);

            break;
          case WIDGET_TYPES.PLAYLIST:
            await this.updatePlaylist(widget);

            break;
          case WIDGET_TYPES.LAYER:
            await apiUpdateLayerWidget(widget.object.item_id, widget.object);
            break;
        }

        await apiUpdateLayoutChild(this.layoutId, {
          type: this.getWidgetTypeForAPI(widget),
          position: widget.position,
          assoc_id: widget.assoc_id,
        });

        this.$store.commit(LAYOUT_REMOVE_MODIFIED_WIDGET, widget.assoc_id);
      },

      async createPlaylist(playlistWidget) {
        try {
          const { name, description, imageMode, transition_mode, wid } = playlistWidget.object;

          const newPlaylist = {
            description,
            imageMode,
            name,
            transition_mode,
            wid,
          };

          const playlistItems = this.$store.getters.getPlaylistItems(wid);

          const playlistCreationData = {
            layout_id: this.layout.layout_id,
            playlist: newPlaylist,
            items: this.preparePlaylistItems(playlistItems),
            position: playlistWidget.position,
          };

          const response = await apiCreatePlaylist(playlistCreationData);

          const { items, playlist, playlist_layout_association } = response.data;

          // Save association ids on store (playlist, and the playlist's items)
          this.$store.commit(REPLACE_DUMMMY_CHILD, {
            widget: {
              ...playlistWidget,
              assoc_id: playlist_layout_association.id,
              object: {
                ...playlistWidget.object,
                playlist_id: playlist.playlist_id,
              },
            },
          });

          this.saveNewPlaylistItemsInStore(wid, items);
        } catch (error) {
          console.log('creatingPlaylistItems ~ error:', error);

          this.$toasted.global.general_error({
            message: `We had problems saving ${playlistWidget.object.name} area's items`,
          });

          this.errorsSavingLayout = true;
        }
      },

      async updatePlaylist(widget) {
        const {
          name,
          description,
          imageMode = IMAGE_MODE.CROP,
          playlist_id,
          transition_mode,
          wid,
        } = widget.object;

        const response = await apiUpdatePlaylist(playlist_id, {
          description,
          name,
          transition_mode,
          imageMode,
        });

        this.createPlaylistItems(playlist_id, wid);
        this.updatePlaylistItems(playlist_id, wid);
        this.deletePlaylistItems(playlist_id, wid);

        this.$store.commit(REMOVE_FROM_PLAYLIST_ITEMS_CHANGES, wid);
      },

      async createPlaylistItems(playlistId, playlistWid) {
        const playlistItemsToCreate = this.$store.getters.getPlaylistItemsToSave(
          playlistWid,
          'create',
        );

        if (!playlistItemsToCreate || !playlistItemsToCreate.length) return;

        const itemsToCreate = playlistItemsToCreate.map((item) => {
          return this.$store.state.layoutDesigner.playlistsItems[playlistWid][item.id];
        });

        const response = await apiAddAreaItems(
          playlistId,
          this.preparePlaylistItems(itemsToCreate),
        );

        return this.saveNewPlaylistItemsInStore(playlistWid, response.data.items);
      },

      updatePlaylistItems(playlistId, playlistWid) {
        const playlistItemsToUpdate = this.$store.getters.getPlaylistItemsToSave(
          playlistWid,
          'update',
        );

        if (!playlistItemsToUpdate || !playlistItemsToUpdate.length) return;

        const itemsToUpdate = playlistItemsToUpdate.map(
          (item) => this.$store.state.layoutDesigner.playlistsItems[playlistWid][item.id],
        );

        return this.$store.dispatch(PLAYLIST_ITEM_UPDATE, {
          playlist_id: playlistId,
          itemList: itemsToUpdate,
        });
      },

      deletePlaylistItems(playlistId, playlistWid) {
        const playlistItemsToDelete = this.$store.getters.getPlaylistItemsToSave(
          playlistWid,
          'delete',
        );

        if (!playlistItemsToDelete || !playlistItemsToDelete.length) return;

        const idsToDelete = playlistItemsToDelete.map((item) => item.data.assoc_id);

        return apiDeleteAreaItems(playlistId, idsToDelete);
      },

      saveNewPlaylistItemsInStore(playlistWid, newItems) {
        newItems.forEach((item) => {
          const currentItem =
            this.$store.state.layoutDesigner.playlistsItems[playlistWid][item.wid];

          const newItem = {
            ...currentItem,
            assoc_id: item.id,
            item_id: item.playlistitem,
            item_type: currentItem.item_type.includes(WIDGET_TYPES.TEMPLATE)
              ? CONTENT_TYPE_LOCAL_TEMPLATE
              : currentItem.item_type,
            ...(currentItem.item_type.includes(WIDGET_TYPES.TEMPLATE) && {
              template: item.template,
            }),
          };

          this.$store.commit(UPDATE_SAVED_PLAYLIST_ITEM, {
            playlistItem: newItem,
            playlistWid,
          });
        });
      },

      preparePlaylistItems(playlistItems) {
        const itemsSavingData = playlistItems.map((playlistItem) => {
          let itemData = {
            name: playlistItem.name || playlistItem.item_name,
            type: playlistItem.item_type,
          };

          if (playlistItem.template) {
            itemData.template_id = playlistItem.template.template_id;
            itemData.type = CONTENT_TYPE_TEMPLATE_APP; // "app/template"
          } else if (playlistItem.item_type !== WIDGET_TYPES.LAYER) {
            itemData.item_id = playlistItem.item_id;
          } else if (playlistItem.item_type === WIDGET_TYPES.LAYER) {
            itemData = {
              type: WIDGET_TYPES.LAYER,
              linkUrl: playlistItem.linkUrl,
              backgroundColor: playlistItem.backgroundColor,
              opacity: playlistItem.opacity,
            };
          }

          itemData = {
            ...itemData,
            assoc_config: {
              scheduler: playlistItem.scheduler,
              display_priority: playlistItem.display_priority,
              local_name: playlistItem.name || playlistItem.item_name,
              display_timer: playlistItem.display_timer,
              wid: playlistItem.wid,
              item_priority: playlistItem.item_priority,
              mute: playlistItem.mute,
              schedule_end: playlistItem.schedule_end,
              schedule_start: playlistItem.schedule_start,
            },
          };

          return itemData;
        });

        return itemsSavingData;
      },

      async deleteWidget(widget) {
        await apiRemoveChild(this.layoutId, {
          type: this.getWidgetTypeForAPI(widget),
          assoc_id: widget.assoc_id,
        });

        this.$store.commit(REMOVE_DELETED_CHILDREN, widget.assoc_id);
      },

      getWidgetTypeForAPI(widget) {
        if (widget.object.playlist_id) {
          return WIDGET_TYPES.PLAYLIST;
        }

        if (widget.itemType === WIDGET_TYPES.TEMPLATE) {
          return WIDGET_TYPES.TEMPLATE;
        }

        return WIDGET_TYPES.LAYOUT_ITEM;
      },

      toggleGrid() {
        this.$store.commit(LAYOUT_DESIGNER_TOGGLE_GRID, !this.gridIsOn);
      },

      toggleSnap() {
        this.$store.commit(LAYOUT_DESIGNER_TOGGLE_SNAP, !this.snapIsOn);
      },

      openCreateTemplateLayoutModal() {
        this.showCreateTemplateLayoutModal = true;
      },

      async openPreviewer() {
        const layoutUrl = `#/layouts/${this.layout.layout_id}/player`;

        window.open(layoutUrl, '_blank');
      },

      openPreviewModal() {
        this.$store.commit(LAYOUT_DESIGNER_DESELECT_WIDGET);
        this.showPreviewModal = true;
      },

      closeLayoutPreviewModal() {
        this.showPreviewModal = false;
      },

      openLayoutSettings() {
        this.$store.commit(LAYOUT_DESIGNER_DESELECT_WIDGET);
      },

      changeLayoutOrientation(horizontal) {
        if (this.isSavingLayout) return;

        this.$store.dispatch(LAYOUT_ROTATION, horizontal);
      },
    },
  };
</script>

<style lang="scss">
  .header {
    margin-bottom: 0;

    .header-top {
      flex-wrap: wrap;
      font-family: 'Poppins';
      gap: 16px;

      .title {
        display: flex;
        gap: 8px;
        align-items: center;
        color: $primaryText;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }

      .button-2-primary {
        padding: 8px 12px;
        height: 36px;
      }

      button.disabled {
        opacity: 0.5;
      }
    }

    .header-buttons {
      display: flex;
      align-items: center;
      gap: 16px;

      .button {
        cursor: pointer;

        i {
          &:hover {
            color: $primaryRed;
          }
        }

        &:hover {
          color: $primaryRed;
        }

        &.active {
          i {
            color: $primaryRed;
          }
        }
      }
    }
  }
</style>
