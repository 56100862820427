<template>
  <Modal
    @closeModal="closeModal"
    v-show="showModal"
    title="Delete Confirmation"
    :okFunction="deleteWidget"
  >
    <template v-slot:body>
      <div class="delete-description">Are you sure you want to delete this widget?</div>
    </template>
  </Modal>
</template>

<script>
  import Modal from '@/components/common/Modal.vue';

  import { TEMPLATE_DESIGNER_DELETE_WIDGET } from '@/store/actions/templateDesigner';

  export default {
    name: 'DeleteWidgetModal',

    components: { Modal },

    props: {
      widget: {
        type: Object,
        default: () => ({
          assoc_id: 'fake',
        }),
      },
    },

    data() {
      return {
        showModal: false,
      };
    },

    mounted() {
      this.showModal = true;
    },

    methods: {
      deleteWidget() {
        this.$store.commit(TEMPLATE_DESIGNER_DELETE_WIDGET, {
          widget: this.widget,
          saveOnDeletedWidgets: !!this.widget.assoc_id,
        });

        this.closeModal(true);
      },

      closeModal() {
        this.showModal = false;
        this.$emit('closeModal');
      },
    },
  };
</script>
