import '@tiptap/extension-text-style';

import { Extension } from '@tiptap/core';
import { MarkType, NodeType } from '@tiptap/pm/model';

export interface FontSizeOptions {
  types: string[];
  getStyle: (fontSize: string) => string;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    fontSize: {
      /**
       * Set the font size attribute
       */
      setFontSize: (fontSize: string) => ReturnType;
      /**
       * Unset the font size attribute
       */
      unsetFontSize: () => ReturnType;
      /**
       * Set the font size of a node or mark
       */
      setNodeFontSize: (typeOrName: string | NodeType | MarkType, fontSize: string) => ReturnType;
      /**
       * Unset the font size of a node or mark
       */
      unsetNodeFontSize: (typeOrName: string | NodeType | MarkType) => ReturnType;
    };
  }
}

export const FontSize = Extension.create<FontSizeOptions>({
  name: 'fontSize',

  addOptions(): FontSizeOptions {
    return {
      types: ['textStyle'],
      getStyle: (fontSize: string) => {
        return `font-size: ${fontSize}`;
      },
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          fontSize: {
            default: null,
            parseHTML: (element) => element.style.fontSize.replace(/['"]+/g, ''),
            renderHTML: (attributes) => {
              if (!attributes.fontSize) {
                return {};
              }

              return {
                style: this.options.getStyle(attributes.fontSize),
              };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setFontSize:
        (fontSize) =>
        ({ chain }) => {
          return chain().setMark('textStyle', { fontSize }).run();
        },
      unsetFontSize:
        () =>
        ({ chain }) => {
          return chain().setMark('textStyle', { fontSize: null }).removeEmptyTextStyle().run();
        },
      setNodeFontSize:
        (typeOrName, fontSize) =>
        ({ commands }) => {
          return commands.updateAttributes(typeOrName, { fontSize: fontSize });
        },
      unsetNodeFontSize:
        (typeOrName) =>
        ({ commands }) => {
          return commands.resetAttributes(typeOrName, 'fontSize');
        },
    };
  },
});
