<template>
  <WidgetNode :node="getWidgetNode(widget)" />
</template>

<script>
  import WidgetNode from '@/components/layoutDesigner/designer/widgets/WidgetNode.vue';
  import { WIDGET_TYPES } from '@/models/layoutDesigner';

  export default {
    props: {
      widget: {
        type: Object,
        default: () => null,
      },
    },

    components: {
      WidgetNode,
    },

    methods: {
      getWidgetNode(widget) {
        return {
          data: widget,
          id: widget.object.type || WIDGET_TYPES.PLAYLIST,
        };
      },
    },
  };
</script>

<style lang="scss" scoped></style>
