<template>
  <div
    :style="style"
    :class="[
      {
        [classNameActive]: enabled,
        [classNameDragging]: dragging,
        [classNameResizing]: resizing,
        [classNameDraggable]: draggable,
        [classNameResizable]: resizable,
      },
      className,
    ]"
    @mousedown="elementMouseDown"
    @touchstart="elementTouchDown"
    @contextmenu="onContextMenu"
  >
    <div
      v-for="handle in actualHandles"
      :key="handle"
      :class="[classNameHandle, classNameHandle + '-' + handle]"
      :style="handleStyle(handle)"
      @mousedown.stop.prevent="handleDown(handle, $event)"
      @touchstart.stop.prevent="handleTouchDown(handle, $event)"
    >
      <slot :name="handle"></slot>
    </div>
    <slot></slot>
  </div>
</template>

<script>
  import {
    matchesSelectorToParentElements,
    getComputedSize,
    addEvent,
    removeEvent,
    computeWidth,
    computeHeight,
    restrictToBounds,
  } from '@/helpers/draggable';

  const events = {
    mouse: {
      start: 'mousedown',
      move: 'mousemove',
      stop: 'mouseup',
    },
    touch: {
      start: 'touchstart',
      move: 'touchmove',
      stop: 'touchend',
    },
  };
  // Disable user selection
  const userSelectNone = {
    userSelect: 'none',
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
    MsUserSelect: 'none',
  };
  // user selects auto
  const userSelectAuto = {
    userSelect: 'auto',
    MozUserSelect: 'auto',
    WebkitUserSelect: 'auto',
    MsUserSelect: 'auto',
  };
  let eventsFor = events.mouse;
  export default {
    replace: true,
    name: 'VueDraggableResizable',
    props: {
      className: {
        type: String,
        default: 'vdr',
      },
      classNameDraggable: {
        type: String,
        default: 'draggable',
      },
      classNameResizable: {
        type: String,
        default: 'resizable',
      },
      classNameDragging: {
        type: String,
        default: 'dragging',
      },
      classNameResizing: {
        type: String,
        default: 'resizing',
      },
      classNameActive: {
        type: String,
        default: 'active',
      },
      classNameHandle: {
        type: String,
        default: 'handle',
      },
      disableUserSelect: {
        type: Boolean,
        default: true,
      },
      enableNativeDrag: {
        type: Boolean,
        default: false,
      },
      preventDeactivation: {
        type: Boolean,
        default: false,
      },
      active: {
        type: Boolean,
        default: false,
      },
      draggable: {
        type: Boolean,
        default: true,
      },
      resizable: {
        type: Boolean,
        default: true,
      },
      // lock aspect ratio
      lockAspectRatio: {
        type: Boolean,
        default: false,
      },
      w: {
        type: [Number, String],
        default: 200,
        validator: (val) => {
          if (typeof val === 'number') {
            return val >= 0;
          }
          return val === 'auto';
        },
      },
      h: {
        type: [Number, String],
        default: 200,
        validator: (val) => {
          if (typeof val === 'number') {
            return val >= 0;
          }
          return val === 'auto';
        },
      },
      minWidth: {
        type: Number,
        default: 0,
        validator: (val) => val >= 0,
      },
      minHeight: {
        type: Number,
        default: 0,
        validator: (val) => val >= 0,
      },
      maxWidth: {
        type: Number,
        default: null,
        validator: (val) => val >= 0,
      },
      maxHeight: {
        type: Number,
        default: null,
        validator: (val) => val >= 0,
      },
      x: {
        type: Number,
        default: 0,
      },
      y: {
        type: Number,
        default: 0,
      },
      z: {
        type: [String, Number],
        default: 'auto',
        validator: (val) => (typeof val === 'string' ? val === 'auto' : val >= 0),
      },
      handles: {
        type: Array,
        default: () => ['tl', 'tm', 'tr', 'mr', 'br', 'bm', 'bl', 'ml'],
        validator: (val) => {
          const s = new Set(['tl', 'tm', 'tr', 'mr', 'br', 'bm', 'bl', 'ml']);
          return new Set(val.filter((h) => s.has(h))).size === val.length;
        },
      },
      dragHandle: {
        type: String,
        default: null,
      },
      dragCancel: {
        type: String,
        default: null,
      },
      axis: {
        type: String,
        default: 'both',
        validator: (val) => ['x', 'y', 'both'].includes(val),
      },
      grid: {
        type: Array,
        default: null,
      },
      parent: {
        type: [Boolean, String],
        default: false,
      },
      onDragStart: {
        type: Function,
        default: () => true,
      },
      onDrag: {
        type: Function,
        default: () => true,
      },
      onResizeStart: {
        type: Function,
        default: () => true,
      },
      onResize: {
        type: Function,
        default: () => true,
      },
      // conflict detection
      isConflictCheck: {
        type: Boolean,
        default: false,
      },

      // element alignment
      snap: {
        type: Boolean,
        default: false,
      },
      snapToGridTolerance: {
        type: Number,
        default: 20,
        validator: function (val) {
          return typeof val === 'number';
        },
      },

      // When the alignment is called, it is used to set the alignment distance between components, in pixels
      snapTolerance: {
        type: Number,
        default: 5,
        validator: function (val) {
          return typeof val === 'number';
        },
      },

      // scaling ratio
      scaleRatio: {
        type: Number,
        default: 1,
        validator: (val) => typeof val === 'number',
      },

      // Whether the handle is scaled
      handleInfo: {
        type: Object,
        default: () => {
          return {
            size: 8,
            offset: -5,
            switch: true,
          };
        },
      },
    },
    data: function () {
      return {
        left: this.x,
        top: this.y,
        right: null,
        bottom: null,
        width: null,
        height: null,
        widthTouched: false,
        heightTouched: false,
        aspectFactor: null,
        parentWidth: null,
        parentHeight: null,
        minW: this.minWidth,
        minH: this.minHeight,
        maxW: this.maxWidth,
        maxH: this.maxHeight,
        handle: null,
        enabled: this.active,
        resizing: false,
        dragging: false,
        zIndex: this.z,
        gridSnapTolerance: null,
      };
    },
    created: function () {
      // eslint-disable-next-line invalid prop: minWidth cannot be greater than maxWidth
      if (this.maxWidth && this.minWidth > this.maxWidth)
        console.warn('[Vdr warn]: Invalid prop: minWidth cannot be greater than maxWidth');

      // eslint-disable-next-line invalid prop: minHeight cannot be greater than maxHeight'
      if (this.maxWidth && this.minHeight > this.maxHeight)
        console.warn('[Vdr warn]: Invalid prop: minHeight cannot be greater than maxHeight');
      this.resetBoundsAndMouseState();
    },
    mounted: function () {
      if (!this.enableNativeDrag) {
        this.$el.ondragstart = () => false;
      }
      const [parentWidth, parentHeight] = this.getParentSize();
      this.parentWidth = parentWidth;
      this.parentHeight = parentHeight;
      const [width, height] = getComputedSize(this.$el);
      this.aspectFactor =
        (this.w !== 'auto' ? this.w : width) / (this.h !== 'auto' ? this.h : height);
      this.width = this.w !== 'auto' ? this.w : width;
      this.height = this.h !== 'auto' ? this.h : height;
      this.right = this.parentWidth - this.width - this.left;
      this.bottom = this.parentHeight - this.height - this.top;

      this.setGrid();
      this.settingAttribute();

      // Optimization: the unchecked behavior is preferentially bound to the parent node
      const parentElement = this.$el.parentNode;
      addEvent(parentElement || document.documentElement, 'mousedown', this.deselect);
      addEvent(parentElement || document.documentElement, 'touchend touchcancel', this.deselect);
      addEvent(window, 'resize', this.checkParentSize);
    },
    beforeDestroy: function () {
      removeEvent(document.documentElement, 'mousedown', this.deselect);
      removeEvent(document.documentElement, 'touchstart', this.handleUp);
      removeEvent(document.documentElement, 'mousemove', this.move);
      removeEvent(document.documentElement, 'touchmove', this.move);
      removeEvent(document.documentElement, 'mouseup', this.handleUp);
      removeEvent(document.documentElement, 'touchend touchcancel', this.deselect);
      removeEvent(window, 'resize', this.checkParentSize);
    },
    methods: {
      // right click menu
      onContextMenu(e) {
        this.$emit('contextmenu', e);
      },

      // reset bounds and mouse state
      resetBoundsAndMouseState() {
        this.mouseClickPosition = { mouseX: 0, mouseY: 0, x: 0, y: 0, w: 0, h: 0 };
        this.bounds = {
          minLeft: null,
          maxLeft: null,
          minRight: null,
          maxRight: null,
          minTop: null,
          maxTop: null,
          minBottom: null,
          maxBottom: null,
        };
      },

      // check parent element size
      checkParentSize() {
        if (this.parent) {
          const [newParentWidth, newParentHeight] = this.getParentSize();

          // After fixing the size of the parent element, the activity is abnormal when the component is resizing
          this.right = newParentWidth - this.width - this.left;
          this.bottom = newParentHeight - this.height - this.top;
          this.parentWidth = newParentWidth;
          this.parentHeight = newParentHeight;
        }
      },

      // Get the size of the parent element
      getParentSize() {
        if (this.parent === true) {
          const style = window.getComputedStyle(this.$el.parentNode, null);
          return [
            parseFloat(style.getPropertyValue('width')),
            parseFloat(style.getPropertyValue('height')),
          ];
        }
        if (typeof this.parent === 'string') {
          const parentNode = document.querySelector(this.parent);
          if (!(parentNode instanceof HTMLElement)) {
            throw new Error(`The selector ${this.parent} does not match any element`);
          }
          return [parentNode.offsetWidth, parentNode.offsetHeight];
        }
        return [null, null];
      },

      // element touch down
      elementTouchDown(e) {
        eventsFor = events.touch;
        this.elementDown(e);
      },
      elementMouseDown(e) {
        eventsFor = events.mouse;
        this.elementDown(e);
      },

      // element pressed
      elementDown(e) {
        if (e instanceof MouseEvent && e.which !== 1) {
          return;
        }
        const target = e.target || e.srcElement;
        if (this.$el.contains(target)) {
          if (this.onDragStart(this.left, this.top, e) === false) {
            return;
          }
          if (
            (this.dragHandle &&
              !matchesSelectorToParentElements(target, this.dragHandle, this.$el)) ||
            (this.dragCancel && matchesSelectorToParentElements(target, this.dragCancel, this.$el))
          ) {
            this.dragging = false;
            return;
          }
          if (!this.enabled) {
            this.enabled = true;
            this.$emit('activated');
            this.$emit('update:active', true);
          }
          if (this.draggable) {
            this.dragging = true;
          }
          this.mouseClickPosition.mouseX = e.touches ? e.touches[0].pageX : e.pageX;
          this.mouseClickPosition.mouseY = e.touches ? e.touches[0].pageY : e.pageY;
          this.mouseClickPosition.left = this.left;
          this.mouseClickPosition.right = this.right;
          this.mouseClickPosition.top = this.top;
          this.mouseClickPosition.bottom = this.bottom;
          this.mouseClickPosition.w = this.width;
          this.mouseClickPosition.h = this.height;

          if (this.parent) {
            this.bounds = this.calcDragLimits();
          }
          addEvent(document.documentElement, eventsFor.move, this.move);
          addEvent(document.documentElement, eventsFor.stop, this.handleUp);
        }
      },

      // Calculate the range of movement
      calcDragLimits() {
        return {
          minLeft: 0,
          maxLeft: this.parentWidth - this.width,
          minRight: 0,
          maxRight: this.parentWidth - this.width,
          minTop: 0,
          maxTop: this.parentHeight - this.height,
          minBottom: 0,
          maxBottom: this.parentHeight - this.height,
        };
      },

      // Cancel
      deselect(e) {
        const target = e.target || e.srcElement;
        const regex = new RegExp(this.className + '-([trmbl]{2})', '');
        if (!this.$el.contains(target) && !regex.test(target.className)) {
          if (this.enabled && !this.preventDeactivation) {
            this.enabled = false;
            this.$emit('deactivated');
            this.$emit('update:active', false);
          }
          removeEvent(document.documentElement, eventsFor.move, this.handleResize);
        }
        this.resetBoundsAndMouseState();
      },

      // handle touch down
      handleTouchDown(handle, e) {
        eventsFor = events.touch;
        this.handleDown(handle, e);
      },

      // handle pressed
      handleDown(handle, e) {
        if (e instanceof MouseEvent && e.which !== 1) {
          return;
        }
        if (this.onResizeStart(handle, e) === false) {
          return;
        }
        if (e.stopPropagation) e.stopPropagation();
        // Here we avoid a dangerous recursion by faking
        // corner handles as middle handles
        if (this.lockAspectRatio && !handle.includes('m')) {
          this.handle = 'm' + handle.substring(1);
        } else {
          this.handle = handle;
        }
        this.resizing = true;
        this.mouseClickPosition.mouseX = e.touches ? e.touches[0].pageX : e.pageX;
        this.mouseClickPosition.mouseY = e.touches ? e.touches[0].pageY : e.pageY;
        this.mouseClickPosition.left = this.left;
        this.mouseClickPosition.right = this.right;
        this.mouseClickPosition.top = this.top;
        this.mouseClickPosition.bottom = this.bottom;
        this.mouseClickPosition.w = this.width;
        this.mouseClickPosition.h = this.height;
        this.bounds = this.calcResizeLimits();
        addEvent(document.documentElement, eventsFor.move, this.handleResize);
        addEvent(document.documentElement, eventsFor.stop, this.handleUp);
      },

      // Calculate the resize range
      calcResizeLimits() {
        let minW = this.minW;
        let minH = this.minH;
        let maxW = this.maxW;
        let maxH = this.maxH;
        const aspectFactor = this.aspectFactor;
        const width = this.width;
        const height = this.height;
        const left = this.left;
        const top = this.top;
        const right = this.right;
        const bottom = this.bottom;
        if (this.lockAspectRatio) {
          if (minW / minH > aspectFactor) {
            minH = minW / aspectFactor;
          } else {
            minW = aspectFactor * minH;
          }
          if (maxW && maxH) {
            maxW = Math.min(maxW, aspectFactor * maxH);
            maxH = Math.min(maxH, maxW / aspectFactor);
          } else if (maxW) {
            maxH = maxW / aspectFactor;
          } else if (maxH) {
            maxW = aspectFactor * maxH;
          }
        }
        const limits = {
          minLeft: null,
          maxLeft: null,
          minTop: null,
          maxTop: null,
          minRight: null,
          maxRight: null,
          minBottom: null,
          maxBottom: null,
        };
        if (this.parent) {
          limits.minLeft = 0;
          limits.maxLeft = this.parentWidth - minW;
          limits.minTop = 0;
          limits.maxTop = this.parentHeight - minH;
          limits.minRight = 0;
          limits.maxRight = this.parentWidth - minW;
          limits.minBottom = 0;
          limits.maxBottom = this.parentHeight - minH;
          if (maxW) {
            limits.minLeft = Math.max(limits.minLeft, this.parentWidth - right - maxW);
            limits.minRight = Math.max(limits.minRight, this.parentWidth - left - maxW);
          }
          if (maxH) {
            limits.minTop = Math.max(limits.minTop, this.parentHeight - bottom - maxH);
            limits.minBottom = Math.max(limits.minBottom, this.parentHeight - top - maxH);
          }
          if (this.lockAspectRatio) {
            limits.minLeft = Math.max(limits.minLeft, left - top * aspectFactor);
            limits.minTop = Math.max(limits.minTop, top - left / aspectFactor);
            limits.minRight = Math.max(limits.minRight, right - bottom * aspectFactor);
            limits.minBottom = Math.max(limits.minBottom, bottom - right / aspectFactor);
          }
        } else {
          limits.minLeft = 0;
          limits.maxLeft = left + (width - minW);
          limits.minTop = 0;
          limits.maxTop = top + (height - minH);
          limits.minRight = 0;
          limits.maxRight = right + (width - minW);
          limits.minBottom = 0;
          limits.maxBottom = bottom + (height - minH);
          if (maxW) {
            limits.minLeft = -(right + maxW);
            limits.minRight = -(left + maxW);
          }
          if (maxH) {
            limits.minTop = -(bottom + maxH);
            limits.minBottom = -(top + maxH);
          }
          if (this.lockAspectRatio && maxW && maxH) {
            limits.minLeft = Math.min(limits.minLeft, -(right + maxW));
            limits.minTop = Math.min(limits.minTop, -(maxH + bottom));
            limits.minRight = Math.min(limits.minRight, -left - maxW);
            limits.minBottom = Math.min(limits.minBottom, -top - maxH);
          }
        }
        return limits;
      },

      // move
      move(e) {
        if (this.resizing) {
          this.handleResize(e);
        } else if (this.dragging) {
          this.handleDrag(e);
        }
      },

      // move the element
      async handleDrag(e) {
        const axis = this.axis;
        const bounds = this.bounds;
        const mouseClickPosition = this.mouseClickPosition;
        const deltaX =
          axis && axis !== 'y'
            ? mouseClickPosition.mouseX - (e.touches ? e.touches[0].pageX : e.pageX)
            : 0;
        const deltaY =
          axis && axis !== 'x'
            ? mouseClickPosition.mouseY - (e.touches ? e.touches[0].pageY : e.pageY)
            : 0;

        let left;
        let right;
        let top;
        let bottom;

        if (this.grid) {
          const { snapLeft, snapRight } = this.snapToGridX(deltaX);
          const { snapTop, snapBottom } = this.snapToGridY(deltaY);

          left = snapLeft;
          top = snapTop;
          right = snapRight;
          bottom = snapBottom;
        } else {
          left = restrictToBounds(mouseClickPosition.left - deltaX, bounds.minLeft, bounds.maxLeft);
          top = restrictToBounds(mouseClickPosition.top - deltaY, bounds.minTop, bounds.maxTop);
          right = restrictToBounds(
            mouseClickPosition.right + deltaX,
            bounds.minRight,
            bounds.maxRight,
          );
          bottom = restrictToBounds(
            mouseClickPosition.bottom + deltaY,
            bounds.minBottom,
            bounds.maxBottom,
          );
        }

        if (this.onDrag(left, top, right, bottom) === false) {
          return;
        }

        this.left = left;
        this.top = top;
        this.right = right;
        this.bottom = bottom;
        await this.snapCheck();
        this.$emit('dragging', this.left, this.top);
      },

      moveHorizontally(val) {
        const left = restrictToBounds(val, this.bounds.minLeft, this.bounds.maxLeft);
        this.left = left;
        this.right = this.parentWidth - this.width - left;
      },

      moveVertically(val) {
        const top = restrictToBounds(val, this.bounds.minTop, this.bounds.maxTop);
        this.top = top;
        this.bottom = this.parentHeight - this.height - top;
      },

      // handle moves
      handleResize(e) {
        let left = this.left;
        let top = this.top;
        let right = this.right;
        let bottom = this.bottom;
        const mouseClickPosition = this.mouseClickPosition;
        const lockAspectRatio = this.lockAspectRatio;
        const aspectFactor = this.aspectFactor;
        const deltaX = mouseClickPosition.mouseX - (e.touches ? e.touches[0].pageX : e.pageX);
        const deltaY = mouseClickPosition.mouseY - (e.touches ? e.touches[0].pageY : e.pageY);

        if (!this.widthTouched && deltaX) {
          this.widthTouched = true;
        }

        if (!this.heightTouched && deltaY) {
          this.heightTouched = true;
        }

        if (this.handle.includes('b')) {
          bottom = restrictToBounds(
            mouseClickPosition.bottom + deltaY,
            this.bounds.minBottom,
            this.bounds.maxBottom,
          );

          bottom = !!this.grid
            ? this.parentHeight -
              this.snapHandle(
                this.parentHeight - bottom,
                this.grid[1],
                mouseClickPosition.top,
                this.parentHeight,
                this.parentHeight,
              )
            : bottom;

          if (this.lockAspectRatio && this.resizingOnY) {
            right = this.right - (this.bottom - bottom) * aspectFactor;
          }
        } else if (this.handle.includes('t')) {
          top = restrictToBounds(
            mouseClickPosition.top - deltaY,
            this.bounds.minTop,
            this.bounds.maxTop,
          );

          top = !!this.grid
            ? this.snapHandle(
                top,
                this.grid[1],
                0,
                this.parentHeight - mouseClickPosition.bottom,
                this.parentHeight,
              )
            : top;

          if (this.lockAspectRatio && this.resizingOnY) {
            left = this.left - (this.top - top) * aspectFactor;
          }
        }
        if (this.handle.includes('r')) {
          right = restrictToBounds(
            mouseClickPosition.right + deltaX,
            this.bounds.minRight,
            this.bounds.maxRight,
          );

          right = !!this.grid
            ? this.parentWidth -
              this.snapHandle(
                this.parentWidth - right,
                this.grid[0],
                mouseClickPosition.left,
                this.parentWidth,
                this.parentWidth,
              )
            : right;

          if (this.lockAspectRatio && this.resizingOnX) {
            bottom = this.bottom - (this.right - right) / aspectFactor;
          }
        } else if (this.handle.includes('l')) {
          left = restrictToBounds(
            mouseClickPosition.left - deltaX,
            this.bounds.minLeft,
            this.bounds.maxLeft,
          );

          left = !!this.grid
            ? this.snapHandle(
                left,
                this.grid[0],
                0,
                this.parentWidth - mouseClickPosition.right,
                this.parentWidth,
              )
            : left;

          if (this.lockAspectRatio && this.resizingOnX) {
            top = this.top - (this.left - left) / aspectFactor;
          }
        }

        const width = computeWidth(this.parentWidth, left, right);
        const height = computeHeight(this.parentHeight, top, bottom);

        if (this.onResize(this.handle, left, top, width, height) === false) {
          return;
        }

        this.left = left;
        this.top = top;
        this.right = right;
        this.bottom = bottom;
        this.width = width;
        this.height = height;
        this.$emit('resizing', this.left, this.top, this.width, this.height);
      },

      changeWidth(newWidth) {
        let right = restrictToBounds(
          this.parentWidth - newWidth - this.left,
          this.bounds.minRight,
          this.bounds.maxRight,
        );
        let bottom = this.bottom;
        if (this.lockAspectRatio) {
          bottom = this.bottom - (this.right - right) / this.aspectFactor;
        }
        const width = computeWidth(this.parentWidth, this.left, right);
        const height = computeHeight(this.parentHeight, this.top, bottom);
        this.right = right;
        this.bottom = bottom;
        this.width = width;
        this.height = height;
      },

      changeHeight(newHeight) {
        let bottom = restrictToBounds(
          this.parentHeight - newHeight - this.top,
          this.bounds.minBottom,
          this.bounds.maxBottom,
        );
        let right = this.right;
        if (this.lockAspectRatio) {
          right = this.right - (this.bottom - bottom) * this.aspectFactor;
        }
        const width = computeWidth(this.parentWidth, this.left, right);
        const height = computeHeight(this.parentHeight, this.top, bottom);
        this.right = right;
        this.bottom = bottom;
        this.width = width;
        this.height = height;
      },

      // release from the handle
      async handleUp(e) {
        this.handle = null;

        // Initialize auxiliary line data
        const temArr = new Array(3).fill({
          display: false,
          position: '',
          origin: '',
          lineLength: '',
        });
        const refLine = { vLine: [], hLine: [] };
        for (let i in refLine) {
          refLine[i] = JSON.parse(JSON.stringify(temArr));
        }
        if (this.resizing) {
          this.resizing = false;
          await this.conflictCheck();
          this.$emit('refLineParams', refLine);
          this.$emit('resizestop', this.left, this.top, this.width, this.height);
        }
        if (this.dragging) {
          this.dragging = false;
          await this.conflictCheck();
          this.$emit('refLineParams', refLine);
          this.$emit('dragstop', this.left, this.top);
        }
        this.resetBoundsAndMouseState();
        removeEvent(document.documentElement, eventsFor.move, this.handleResize);
      },

      // New method ↓↓↓
      // set properties
      settingAttribute() {
        // set conflict detection
        this.$el.setAttribute('data-is-check', `${this.isConflictCheck}`);
        // set the alignment element
        this.$el.setAttribute('data-is-snap', `${this.snap}`);
      },

      // conflict detection
      conflictCheck() {
        const top = this.top;
        const left = this.left;
        const width = this.width;
        const height = this.height;
        if (this.isConflictCheck) {
          const nodes = this.$el.parentNode.childNodes; // Get all child nodes under the current parent node
          for (let item of nodes) {
            if (
              item.className !== undefined &&
              !item.className.includes(this.classNameActive) &&
              item.getAttribute('data-is-check') !== null &&
              item.getAttribute('data-is-check') !== 'false'
            ) {
              const tw = item.offsetWidth;
              const th = item.offsetHeight;

              // Regularly get left and right
              let [tl, tt] = this.formatTransformVal(item.style.transform);
              // upper left corner overlaps with lower right corner
              const tfAndBr =
                (top >= tt && left >= tl && tt + th > top && tl + tw > left) ||
                (top <= tt && left < tl && top + height > tt && left + width > tl);
              // upper right corner overlaps with lower left corner
              const brAndTf =
                (left <= tl && top >= tt && left + width > tl && top < tt + th) ||
                (top < tt && left > tl && top + height > tt && left < tl + tw);

              // Bottom overlaps top
              const bAndT =
                (top <= tt && left >= tl && top + height > tt && left < tl + tw) ||
                (top >= tt && left <= tl && top < tt + th && left > tl + tw);

              // The top and bottom overlap (different widths)
              const tAndB =
                (top <= tt && left >= tl && top + height > tt && left < tl + tw) ||
                (top >= tt && left <= tl && top < tt + th && left > tl + tw);
              // left overlaps right
              const lAndR =
                (left >= tl && top >= tt && left < tl + tw && top < tt + th) ||
                (top > tt && left <= tl && left + width > tl && top < tt + th);
              // The left overlaps the right (different heights)
              const rAndL =
                (top <= tt && left >= tl && top + height > tt && left < tl + tw) ||
                (top >= tt && left <= tl && top < tt + th && left + width > tl);
              // If there is a conflict, it will fall back to the position before the move
              if (tfAndBr || brAndTf || bAndT || tAndB || lAndR || rAndL) {
                this.top = this.mouseClickPosition.top;
                this.left = this.mouseClickPosition.left;
                this.right = this.mouseClickPosition.right;
                this.bottom = this.mouseClickPosition.bottom;
                this.width = this.mouseClickPosition.w;
                this.height = this.mouseClickPosition.h;
                this.$emit('resizing', this.left, this.top, this.width, this.height);
              }
            }
          }
        }
      },

      // detect aligned elements
      async snapCheck() {
        let width = this.width;
        let height = this.height;
        if (this.snap) {
          let activeLeft = this.left;
          let activeRight = this.left + width;
          let activeTop = this.top;
          let activeBottom = this.top + height;

          // Initialize auxiliary line data
          const temArr = new Array(3).fill({
            display: false,
            position: '',
            origin: '',
            lineLength: '',
          });
          const refLine = { vLine: [], hLine: [] };
          for (let i in refLine) {
            refLine[i] = JSON.parse(JSON.stringify(temArr));
          }

          // Get all child nodes under the current parent node
          const nodes = this.$el.parentNode.childNodes;
          let tem = {
            value: { x: [[], [], []], y: [[], [], []] },
            display: [],
            position: [],
          };
          const { groupWidth, groupHeight, groupLeft, groupTop, bln } =
            await this.getActiveAll(nodes);
          if (!bln) {
            width = groupWidth;
            height = groupHeight;
            activeLeft = groupLeft;
            activeRight = groupLeft + groupWidth;
            activeTop = groupTop;
            activeBottom = groupTop + groupHeight;
          }
          for (let item of nodes) {
            if (
              item.className !== undefined &&
              !item.className.includes(this.classNameActive) &&
              item.getAttribute('data-is-snap') !== null &&
              item.getAttribute('data-is-snap') !== 'false'
            ) {
              const w = item.offsetWidth;
              const h = item.offsetHeight;
              const [l, t] = this.formatTransformVal(item.style.transform);

              const r = l + w; // align target right
              const b = t + h; // Align the bottom of the target
              const hc = Math.abs(activeTop + height / 2 - (t + h / 2)) <= this.snapTolerance; // horizontal midline
              const vc = Math.abs(activeLeft + width / 2 - (l + w / 2)) <= this.snapTolerance; // vertical center line
              const ts = Math.abs(t - activeBottom) <= this.snapTolerance; // from top to bottom
              const TS = Math.abs(b - activeBottom) <= this.snapTolerance; // from top to bottom
              const bs = Math.abs(t - activeTop) <= this.snapTolerance; // bottom to top
              const BS = Math.abs(b - activeTop) <= this.snapTolerance; // bottom to top
              const ls = Math.abs(l - activeRight) <= this.snapTolerance; // outer left
              const LS = Math.abs(r - activeRight) <= this.snapTolerance; // outer left
              const rs = Math.abs(l - activeLeft) <= this.snapTolerance; // outer right
              const RS = Math.abs(r - activeLeft) <= this.snapTolerance; // outer right

              tem['display'] = [ts, TS, bs, BS, hc, hc, ls, LS, rs, RS, vc, vc];
              tem['position'] = [
                t,
                b,
                t,
                b,
                t + h / 2,
                t + h / 2,
                l,
                r,
                l,
                r,
                l + w / 2,
                l + w / 2,
              ];
              // fix: The center line is automatically aligned, and the element may exceed the parent element boundary
              if (ts) {
                if (bln) {
                  this.top = Math.max(t - height, this.bounds.minTop);
                  this.bottom = this.parentHeight - this.top - height;
                }
                tem.value.y[0].push(l, r, activeLeft, activeRight);
              }
              if (bs) {
                if (bln) {
                  this.top = t;
                  this.bottom = this.parentHeight - this.top - height;
                }
                tem.value.y[0].push(l, r, activeLeft, activeRight);
              }
              if (TS) {
                if (bln) {
                  this.top = Math.max(b - height, this.bounds.minTop);
                  this.bottom = this.parentHeight - this.top - height;
                }
                tem.value.y[1].push(l, r, activeLeft, activeRight);
              }
              if (BS) {
                if (bln) {
                  this.top = b;
                  this.bottom = this.parentHeight - this.top - height;
                }
                tem.value.y[1].push(l, r, activeLeft, activeRight);
              }
              if (ls) {
                if (bln) {
                  this.left = Math.max(l - width, this.bounds.minLeft);
                  this.right = this.parentWidth - this.left - width;
                }
                tem.value.x[0].push(t, b, activeTop, activeBottom);
              }
              if (rs) {
                if (bln) {
                  this.left = l;
                  this.right = this.parentWidth - this.left - width;
                }
                tem.value.x[0].push(t, b, activeTop, activeBottom);
              }
              if (LS) {
                if (bln) {
                  this.left = Math.max(r - width, this.bounds.minLeft);
                  this.right = this.parentWidth - this.left - width;
                }
                tem.value.x[1].push(t, b, activeTop, activeBottom);
              }
              if (RS) {
                if (bln) {
                  this.left = r;
                  this.right = this.parentWidth - this.left - width;
                }
                tem.value.x[1].push(t, b, activeTop, activeBottom);
              }
              if (hc) {
                if (bln) {
                  this.top = Math.max(t + h / 2 - height / 2, this.bounds.minTop);
                  this.bottom = this.parentHeight - this.top - height;
                }
                tem.value.y[2].push(l, r, activeLeft, activeRight);
              }
              if (vc) {
                if (bln) {
                  this.left = Math.max(l + w / 2 - width / 2, this.bounds.minLeft);
                  this.right = this.parentWidth - this.left - width;
                }
                tem.value.x[2].push(t, b, activeTop, activeBottom);
              }

              // An array corresponding to the auxiliary line coordinates and whether to display (display), easy to loop through
              const arrTem = [0, 1, 0, 1, 2, 2, 0, 1, 0, 1, 2, 2];
              for (let i = 0; i <= arrTem.length; i++) {
                // The first 6 are Y auxiliary lines, the last 6 are X auxiliary lines
                const xory = i < 6 ? 'y' : 'x';
                const horv = i < 6 ? 'hLine' : 'vLine';
                if (tem.display[i]) {
                  const { origin, length } = this.calcLineValues(tem.value[xory][arrTem[i]]);
                  refLine[horv][arrTem[i]].display = tem.display[i];
                  refLine[horv][arrTem[i]].position = tem.position[i] + 'px';
                  refLine[horv][arrTem[i]].origin = origin;
                  refLine[horv][arrTem[i]].lineLength = length;
                }
              }
            }
          }
          this.$emit('refLineParams', refLine);
        }
      },
      calcLineValues(arr) {
        const length = Math.max(...arr) - Math.min(...arr) + 'px';
        const origin = Math.min(...arr) + 'px';
        return { length, origin };
      },
      async getActiveAll(nodes) {
        const activeAll = [];
        const XArray = [];
        const YArray = [];
        let groupWidth = 0;
        let groupHeight = 0;
        let groupLeft = 0;
        let groupTop = 0;
        for (let item of nodes) {
          if (item.className !== undefined && item.className.includes(this.classNameActive)) {
            activeAll.push(item);
          }
        }
        const AllLength = activeAll.length;
        if (AllLength > 1) {
          for (let i of activeAll) {
            const l = i.offsetLeft;
            const r = l + i.offsetWidth;
            const t = i.offsetTop;
            const b = t + i.offsetHeight;
            XArray.push(t, b);
            YArray.push(l, r);
          }
          groupWidth = Math.max(...YArray) - Math.min(...YArray);
          groupHeight = Math.max(...XArray) - Math.min(...XArray);
          groupLeft = Math.min(...YArray);
          groupTop = Math.min(...XArray);
        }
        const bln = AllLength === 1;
        return { groupWidth, groupHeight, groupLeft, groupTop, bln };
      },

      // Regularly get left and top

      formatTransformVal(string) {
        let [left, top] = string.replace(/[^0-9\-,]/g, '').split(',');
        if (top === undefined) top = 0;
        return [+left, +top];
      },

      setGrid() {
        if (!!this.grid) {
          const minGridValue = this.grid[0] <= this.grid[1] ? this.grid[0] : this.grid[1];
          this.gridSnapTolerance =
            this.snapToGridTolerance <= minGridValue / 2
              ? this.snapToGridTolerance
              : minGridValue / 2;
        } else {
          this.gridSnapTolerance = null;
        }
      },

      snapToGridY(deltaY) {
        let y = deltaY;
        let yTop = restrictToBounds(this.mouseClickPosition.top - y, 0, this.bounds.maxTop);

        if (yTop === 0) {
          return {
            snapTop: 0,
            snapBottom: this.parentHeight - this.height,
          };
        }

        let yBottom = restrictToBounds(
          this.parentHeight - (yTop + this.height),
          this.bounds.minBottom,
          this.bounds.maxBottom,
        );

        if (yBottom === this.parentHeight) {
          return {
            snapTop: this.parentHeight - this.height,
            snapBottom: 0,
          };
        }

        const topBorderToGridUp = yTop % this.grid[1];
        const topBorderToGridDown = this.grid[1] - topBorderToGridUp;
        const bottonBorderToGridUp = (yTop + this.height) % this.grid[1];
        const bottonBorderToGridDown = this.grid[1] - bottonBorderToGridUp;

        const gapsY = [
          {
            delta: yBottom,
            value: yBottom,
          },
          { delta: Math.abs(topBorderToGridUp), value: topBorderToGridUp * -1 },
          { delta: Math.abs(topBorderToGridDown), value: topBorderToGridDown },
          { delta: Math.abs(bottonBorderToGridUp), value: bottonBorderToGridUp * -1 },
          { delta: Math.abs(bottonBorderToGridDown), value: bottonBorderToGridDown },
        ];

        const validGapsY = gapsY.filter((gapY) => {
          const value = gapY.delta < this.gridSnapTolerance;
          return value;
        });

        if (validGapsY.length !== 0) {
          let minValue = { delta: 5000 };

          validGapsY.forEach((gapY) => {
            minValue = gapY.delta < minValue.delta ? { ...gapY } : { ...minValue };
          });

          yTop = restrictToBounds(yTop + minValue.value, 0, this.bounds.maxTop);
        }

        return {
          snapTop: yTop,
          snapBottom: yTop + this.height,
        };
      },

      snapToGridX(deltaX) {
        let x = deltaX;
        let xLeft = restrictToBounds(
          this.mouseClickPosition.left - x,
          this.bounds.minLeft,
          this.bounds.maxLeft,
        );

        if (xLeft === 0) {
          return {
            snapLeft: 0,
            snapRight: this.parentWidth - this.width,
          };
        }

        let xRight = restrictToBounds(
          this.parentWidth - (xLeft + this.width),
          this.bounds.minRight,
          this.bounds.maxRight,
        );

        if (xRight === 0) {
          return {
            snapLeft: this.parentWidth - this.width,
            snapRight: 0,
          };
        }

        const leftBorderToGridOnTheLeft = xLeft % this.grid[0];
        const leftBorderToGridOnTheRight = this.grid[0] - leftBorderToGridOnTheLeft;
        const rightBorderToGridOnTheLeft = (xLeft + this.width) % this.grid[0];
        const rightBorderToGridOnTheRight = this.grid[0] - rightBorderToGridOnTheLeft;

        const gapsX = [
          {
            delta: xRight,
            value: xRight,
          },
          { delta: Math.abs(leftBorderToGridOnTheLeft), value: leftBorderToGridOnTheLeft * -1 },
          { delta: Math.abs(leftBorderToGridOnTheRight), value: leftBorderToGridOnTheRight },
          { delta: Math.abs(rightBorderToGridOnTheLeft), value: rightBorderToGridOnTheLeft * -1 },
          { delta: Math.abs(rightBorderToGridOnTheRight), value: rightBorderToGridOnTheRight },
        ];

        const validGapsX = gapsX.filter((gapX) => {
          const value = gapX.delta < this.gridSnapTolerance;
          return value;
        });

        if (validGapsX.length !== 0) {
          let minValue = { delta: 5000 };

          validGapsX.forEach((gapX) => {
            minValue = gapX.delta < minValue.delta ? { ...gapX } : { ...minValue };
          });

          xLeft = restrictToBounds(
            xLeft + minValue.value,
            this.bounds.minLeft,
            this.bounds.maxLeft,
          );
        }

        return {
          snapLeft: xLeft,
          snapRight: this.parentWidth - (xLeft + this.width),
        };
      },

      snapHandle(coordinate, gridValue, minimum, maximum, containerLength) {
        const distanceToBorder = coordinate;
        const distanceToGrid = coordinate % gridValue;
        const distanceOverGrid = gridValue - distanceToGrid;

        const gaps = [
          { delta: distanceToBorder, value: distanceToBorder * -1 },
          { delta: containerLength - coordinate, value: containerLength - coordinate },
          { delta: Math.abs(distanceToGrid), value: distanceToGrid * -1 },
          { delta: Math.abs(distanceOverGrid), value: distanceOverGrid },
        ];

        const validGaps = gaps.filter((gap) => {
          const value = gap.delta < this.gridSnapTolerance;
          return value;
        });

        if (validGaps.length !== 0) {
          let minValue = { delta: 5000 };

          validGaps.forEach((gap) => {
            minValue = gap.delta < minValue.delta ? { ...gap } : { ...minValue };
          });

          return restrictToBounds(coordinate + minValue.value, minimum, maximum);
        }

        return coordinate;
      },
    },
    computed: {
      handleStyle() {
        return (stick) => {
          if (!this.handleInfo.switch) return { display: this.enabled ? 'block' : 'none' };
          const size = (this.handleInfo.size / this.scaleRatio).toFixed(2);
          const offset = (this.handleInfo.offset / this.scaleRatio).toFixed(2);
          const center = (size / 2).toFixed(2);
          const styleMap = {
            tl: {
              top: `${offset}px`,
              left: `${offset}px`,
            },
            tm: {
              top: `${offset}px`,
              left: `calc(50% - ${center}px)`,
            },
            tr: {
              top: `${offset}px`,
              right: `${offset}px`,
            },
            mr: {
              top: `calc(50% - ${center}px)`,
              right: `${offset}px`,
            },
            br: {
              bottom: `${offset}px`,
              right: `${offset}px`,
            },
            bm: {
              bottom: `${offset}px`,
              right: `calc(50% - ${center}px)`,
            },
            bl: {
              bottom: `${offset}px`,
              left: `${offset}px`,
            },
            ml: {
              top: `calc(50% - ${center}px)`,
              left: `${offset}px`,
            },
          };
          const stickStyle = {
            width: `${size}px`,
            height: `${size}px`,
            top: styleMap[stick].top,
            left: styleMap[stick].left,
            right: styleMap[stick].right,
            bottom: styleMap[stick].bottom,
          };
          stickStyle.display = this.enabled ? 'block' : 'none';
          return stickStyle;
        };
      },
      style() {
        return {
          transform: `translate(${this.left}px, ${this.top}px)`,
          width: this.computedWidth,
          height: this.computedHeight,
          zIndex: this.zIndex,
          ...(this.dragging && this.disableUserSelect ? userSelectNone : userSelectAuto),
        };
      },
      // Whether the handle is displayed or not
      actualHandles() {
        if (!this.resizable) return [];
        return this.handles;
      },
      computedWidth() {
        if (this.w === 'auto') {
          if (!this.widthTouched) {
            return 'auto';
          }
        }
        return this.width + 'px';
      },
      computedHeight() {
        if (this.h === 'auto') {
          if (!this.heightTouched) {
            return 'auto';
          }
        }
        return this.height + 'px';
      },
      resizingOnX() {
        return Boolean(this.handle) && (this.handle.includes('l') || this.handle.includes('r'));
      },
      resizingOnY() {
        return Boolean(this.handle) && (this.handle.includes('t') || this.handle.includes('b'));
      },
      isCornerHandle() {
        return Boolean(this.handle) && ['tl', 'tr', 'br', 'bl'].includes(this.handle);
      },
    },
    watch: {
      active(val) {
        this.enabled = val;
        if (val) {
          this.$emit('activated');
        } else {
          this.$emit('deactivated');
        }
      },

      grid() {
        this.setGrid();
      },

      z(val) {
        if (val >= 0 || val === 'auto') {
          this.zIndex = val;
        }
      },

      x(val) {
        if (this.resizing || this.dragging) {
          return;
        }
        if (this.parent) {
          this.bounds = this.calcDragLimits();
        }
        this.moveHorizontally(val);
      },

      y(val) {
        if (this.resizing || this.dragging) {
          return;
        }
        if (this.parent) {
          this.bounds = this.calcDragLimits();
        }
        this.moveVertically(val);
      },

      lockAspectRatio(val) {
        if (val) {
          this.aspectFactor = this.width / this.height;
        } else {
          this.aspectFactor = undefined;
        }
      },

      minWidth(val) {
        if (val > 0 && val <= this.width) {
          this.minW = val;
        }
      },

      minHeight(val) {
        if (val > 0 && val <= this.height) {
          this.minH = val;
        }
      },

      maxWidth(val) {
        this.maxW = val;
      },

      maxHeight(val) {
        this.maxH = val;
      },

      w(val) {
        if (this.resizing || this.dragging) {
          return;
        }
        if (this.parent) {
          this.bounds = this.calcResizeLimits();
        }
        this.changeWidth(val);
      },

      h(val) {
        if (this.resizing || this.dragging) {
          return;
        }
        if (this.parent) {
          this.bounds = this.calcResizeLimits();
        }
        this.changeHeight(val);
      },
    },
  };
</script>
