<template>
  <vue-draggable-resizable
    v-if="!hideParent"
    :parent="true"
    :x="position.left"
    :y="position.top"
    :w="position.width"
    :h="position.height"
    :draggable="!isSavingLayout && !isWidgetLocked"
    :resizable="!isSavingLayout && !isWidgetLocked"
    :grid="gridScale"
    :snap="isSnapActive"
    :active="isWidgetSelected"
    :z="(isWidgetSelected && keepZIndexUp ? 1000 : 100) + widget.position[rotation].zIndex"
    :onDragStart="(x, y) => onChangePosition(true, x, y)"
    :onResizeStart="() => onChangePosition(false)"
    @dragstop="onWidgetDrag"
    @resizestop="onWidgetResize"
    @activated="() => onSelected(widget)"
    @deactivated="() => (keepZIndexUp = false)"
    @refLineParams="getRefLineParams"
  >
    <div class="image-container" :style="style">
      <img :src="backgroundImageUrl" :style="imageStyle" :alt="widget.position.name" />
    </div>
  </vue-draggable-resizable>
</template>

<script>
  import VueDraggableResizable from '@/components/common/VueDraggableResizable.vue';

  import playerMixins from '@/models/player';
  import draggableWidget from '@/components/layoutDesigner/mixins/draggableWidget';

  import { simpleTypeMixin } from '@/helpers';
  import config from '@/config';

  export default {
    name: 'ImageWidget',

    mixins: [playerMixins, simpleTypeMixin, draggableWidget],

    components: {
      VueDraggableResizable,
    },

    props: {
      widget: {
        type: Object,
        default: () => ({}),
      },
    },

    computed: {
      style() {
        return {
          backgroundRepeat: 'no-repeat',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          opacity: Number(this.widget.position.opacity) / 100,
        };
      },

      imageStyle() {
        return {
          objectFit: this.widget.position.imageMode || 'cover',
        };
      },

      backgroundImageUrl() {
        return this.getEncodedURL(config.baseUrl, this.widget.object.item_url);
      },

      position() {
        return this.getPosition(this.widget);
      },
    },

    methods: {
      showImage() {
        //this.$emit('show-enlarged', this.widget)
      },
    },
  };
</script>

<style lang="scss" scoped>
  .image-container {
    position: relative;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }
  }
</style>
