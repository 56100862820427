<template>
  <div>
    <div
      class="modal fade overflow-auto"
      :class="open ? 'show d-block' : ''"
      tabindex="-1"
      role="dialog"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content shadow-lg">
          <div class="px-3">
            <div class="modal-header">
              <h5 class="modal-title">Create Trafiklab Widget</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click.prevent="closeModal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
          <div class="modal-body">
            <SourcesSettings
              v-if="currentStep === 1"
              :stop-data="trafiklabData"
              ref="sourceSettings"
              @onUpdate="updateStops"
            />
            <TimeTableSettings
              v-if="currentStep === 2"
              :trafik-lab-data="trafiklabData"
              :time-table-data="timeTableData"
              ref="timeTableSettings"
              @onUpdate="updateTimetableSettings"
            />
            <CustomSettings
              v-if="currentStep === 3"
              :viewer-styles="viewerStyles"
              :timetable-data="timeTableData"
              ref="customSettings"
              @onUpdate="updateViewerStyles"
            />
            <TrafiklabScheduler
              v-if="currentStep === 4"
              :saved-scheduler-data="schedule"
              :app-name="name"
              :folder="folder"
              ref="trafikLabScheduler"
              @onUpdate="updateScheduler"
            />
          </div>
          <div class="modal-footer justify-content-between align-items-center">
            <p class="m-0 mr-3">Step: {{ currentStep }}/{{ totalSteps }}</p>
            <div class="d-flex">
              <span
                class="btn btn-sm bg-white border cursor-pointer mr-2 px-4 rounded-xl"
                v-show="currentStep <= 1"
                @click="closeModal"
                >Close</span
              >
              <span
                class="btn btn-sm bg-primary-red border cursor-pointer text-light px-4 rounded-xl mr-2"
                @click="prevStep"
                v-show="currentStep > 1"
                >&larr; Previous</span
              >
              <span
                class="btn btn-sm bg-primary-red border cursor-pointer text-light px-4 rounded-xl"
                @click="updateCurrentStep"
                v-show="currentStep < totalSteps"
                >Next &rarr;</span
              >
              <span
                class="btn btn-sm bg-success border cursor-pointer text-light px-4 rounded-xl"
                @click="updateCurrentStep"
                v-show="currentStep >= totalSteps"
                >Save</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show" v-if="open"></div>
  </div>
</template>

<script>
  import TimeTableSettings from '@/components/apps/trafiklab/TimeTableSettings.vue';
  import SourcesSettings from '@/components/apps/trafiklab/SourcesSettings.vue';
  import CustomSettings from '@/components/apps/trafiklab/CustomSettings.vue';
  import TrafiklabScheduler from '@/components/apps/trafiklab/TrafiklabScheduler.vue';
  import { APP_CREATE } from '@/store/actions/apps';
  import { DEFAULT_TRAFIKLAB_VIEWER_STYLE } from '@/constant/trafiklabWidget';

  export default {
    name: 'TrafiklabSettingsModal',
    components: {
      TrafiklabScheduler,
      CustomSettings,
      SourcesSettings,
      TimeTableSettings,
    },
    props: {
      open: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        currentStep: 1,
        totalSteps: 4,
        trafiklabData: [],
        timeTableData: [],
        viewerStyles: DEFAULT_TRAFIKLAB_VIEWER_STYLE,
        schedule: null,
        name: null,
        folder: null,
      };
    },

    methods: {
      updateStops(stops) {
        this.trafiklabData = stops;
        this.nextStep();
      },
      updateTimetableSettings(settings) {
        this.timeTableData = settings;
        this.nextStep();
      },
      updateViewerStyles(styles) {
        this.viewerStyles = styles;
        this.nextStep();
      },
      updateScheduler(appData) {
        const { name, folder, scheduler } = appData;
        this.schedule = scheduler;
        this.name = name;
        this.folder = folder;
        const data = {
          timetable: this.timeTableData,
          viewerStyles: this.viewerStyles,
          schedule: this.schedule,
          name: this.name,
          folder: this.folder,
          type: 'trafiklab',
        };
        this.$store
          .dispatch(APP_CREATE, data)
          .then(() => {
            this.$toasted.global.general_success({ message: 'App created successfully!' });
            this.closeModal();
          })
          .catch(() => {
            this.$toasted.global.general_error({ message: 'Failed to create the app!' });
          });
      },
      updateCurrentStep() {
        switch (this.currentStep) {
          case 1:
            this.$refs.sourceSettings.save();
            break;
          case 2:
            this.$refs.timeTableSettings.save();
            break;
          case 3:
            this.$refs.customSettings.save();
            break;
          default:
            this.$refs.trafikLabScheduler.save();
        }
      },
      closeModal() {
        this.$emit('onClose');
        this.currentStep = 1;
        this.timeTableData = [];
        this.trafiklabData = [];
        this.schedule = null;
        this.name = null;
        this.viewerStyles = null;
        this.folder = null;
      },
      nextStep() {
        this.currentStep = Math.min(this.currentStep + 1, this.totalSteps);
      },
      prevStep() {
        this.currentStep = Math.max(this.currentStep - 1, 1);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .modal-dialog {
    max-width: 650px;
  }
</style>
