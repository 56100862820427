module.exports = {
  delete_playlist: 'Delete Playlist',
  deleting_playlist: 'Deleting Playlist',
  delete_warning: 'You will delete the playlist!',
  delete_confirmation: 'To confirm the deletion please check the checkbox below',
  media_library: 'Browse Media Library',
  embed_media: 'Embedded media from another site, such as YouTube',
  example_link: 'Example link',
  embed_youtube_instructions:
    'Read more about parameters to use for embedded youtube videos (such as autoplay and controls, for example)',
  edit_rights: 'EDIT RIGHTS FOR',
  edit_rights_info: 'Click on the status toggle to change access rights',
  playlist_name: 'Playlist Name',
  playlist_description: 'Description of the playlist',
  playlist_address: 'Address of the player',
  duration_info:
    'Set how long or when an item is displayed in the player. Schedules are used in the same priority as shown here, top to bottom. First schedule that is valid takes priority',
  schedule_warning: 'No schedules yet',
  create_playlist: 'Create playlist',
  edit_playlist: 'Edit playlist',
  playlist: 'Playlist',
  playlists: 'Playlists',

  playlist_created: 'Playlist created',
  playlist_updated: 'Playlist updated',
  playlist_removed: 'Playlist removed',
  playlist_published: 'Playlist published',
  playlist_not_published: 'Playlist not published',
  playlist_category_created: 'Category created',
  playlist_category_removed: 'Category removed',
  item_added: 'Item added',
  item_removed: 'Item removed',
  item_updated: 'Item updated',

  schedule_updated: 'Schedule updated',
  schedule_deleted: 'Schedule deleted',
  schedule_start_date_error: 'Need Start date and Start time',
  schedule_end_date_error: 'Need End date and End time',
  schedule_date_diff_error: 'End time must be greater than start time',

  media_updated: 'Media updated',
  media_update_failed: 'Media update failed',

  replace_with_a_url: 'Replace with a URL',
  replace_with_a_media: 'Replace with a media',

  reload_signal_dispatched: 'Reload signal dispatched',

  permissions_loading: 'Permissions loading',

  update_playlist_tags: 'Update Playlist Tags',

  content_push_successful: 'Content pushed to the playlist successfully',

  mon: 'Mon',
  tue: 'Tue',
  wed: 'Wed',
  thu: 'Thr',
  fri: 'Fri',
  sat: 'Sat',
  sun: 'Sun',

  preview_modal_aspect_ratio: 'Aspect ratio',

  screen_detach_success: 'Screen detached successfully',
  screen_detach_error: 'Screen is already detached or an error occurred',
};
