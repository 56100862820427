<template>
  <div :class="'alert fade show ' + styleclass" role="alert">
    <slot>
      <slot name="icon">
        <i v-if="!!icon" class="material-icons align-middle">{{ icon }}</i>
      </slot>

      <slot name="text">
        {{ message }}
      </slot>
    </slot>

    <button v-if="dismissible" type="button" class="close" data-dismiss="alert" aria-label="Close">
      <i aria-hidden="true" class="material-icons-outlined">close</i>
    </button>
  </div>
</template>

<script>
  export default {
    name: 'Alert',

    props: {
      message: {
        type: String,
        default() {
          return '';
        },
      },

      icon: {
        type: String,
        default() {
          return undefined;
        },
      },
      dismissible: {
        type: Boolean,
        default() {
          return true;
        },
      },
      styleclass: {
        type: String,
        default: 'alert-danger',
      },
    },
  };
</script>
