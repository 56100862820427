<template>
  <div class="scheduler-container">
    <div class="description">Pick a name for your new widget.</div>

    <label for="trafiklab-name" class="w-100">
      Give your widget a name
      <input type="text" id="trafiklab-name" placeholder="" v-model="name" class="form-control" />
    </label>
    <!-- <label for="mediaFolders" class="w-100">
      In what folder do you want to save the widget?
      <select id="mediaFolders" v-model="folderSelected" class="form-control">
        <option :value="{ name: 'Main Folder', path: '/' }">Main Folder</option>
        <option v-for="(folder, index) in folders" :key="index" :value="folder">
          {{ folder.name }}
        </option>
      </select>
    </label> -->
  </div>
</template>

<script>
  import Scheduler from '@/components/common/scheduler/Scheduler.vue';
  import { CONTENT_REQUEST } from '@/store/actions/content';

  export default {
    name: 'TrafiklabScheduler',

    components: { Scheduler },

    props: {
      savedSchedulerData: {
        type: Object,
        default: () => null,
      },
      appName: {
        type: String,
        default: '',
      },
      folder: {
        type: Object,
        default: () => null,
      },
    },

    data() {
      return {
        name: '',
        folderSelected: {
          name: 'Main Folder',
          path: '/',
        },
        schedulerData: {
          available: 'always',
        },
      };
    },

    created() {
      if (this.savedSchedulerData) {
        const { name, schedulerData, folder } = this.savedSchedulerData;

        this.name = name;
        this.folderSelected = folder;
        this.schedulerData = schedulerData || {
          available: 'always',
        };
      }

      if (this.folder) {
        this.folderSelected = this.folder;
      }

      this.name = this.appName;

      if (this.$store.getters.getFolders.length === 0) {
        this.getFolders();
      }
    },

    computed: {
      folders() {
        return this.$store.getters.getFolders;
      },
    },

    methods: {
      async getFolders() {
        await this.$store.dispatch(CONTENT_REQUEST);
      },

      save() {
        if (!this.name) {
          this.$toasted.global.general_error({ message: 'Name is required.' });
          return;
        }

        if (!this.folderSelected) {
          this.$toasted.global.general_error({ message: 'Folder is required' });
          return;
        }

        const scheduleData = {
          scheduler: this.schedulerData,
          folder: this.folderSelected,
          name: this.name,
        };

        this.$emit('onUpdate', scheduleData);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .scheduler-container {
    display: flex;
    flex-direction: column;
    padding: 14px;
    gap: 16px;

    .description {
      font-size: 16px;
      margin-bottom: 8px;
    }

    select option {
      zoom: 1.1;
    }
  }

  input,
  select {
    color: black;
  }
</style>
