export function getGroupChildrenTree(groups, groupId) {
  // Throw error if group is not an array
  if (!Array.isArray(groups)) {
    throw new Error(`groups must be an array. ${typeof groups} given. ${groups?.toString()}`);
  }

  // Throw error if groups is not an array
  if (!groups) {
    throw new Error('groups is required');
  }

  // Throw error if groupId is not an array
  if (!groupId) {
    throw new Error('groupId is required');
  }

  // Find group that matches groupId
  let group = groups.find((group) => group.id === groupId);

  // Throw error if group is not found
  if (!group) {
    throw new Error('groupId not found');
  }

  // Find group's children
  let children = groups.filter((group) => group.parent_id === groupId);

  // If there are no children, return group
  if (children.length === 0) return group;

  // If there are children, return group with children
  return {
    ...group,
    children: children.map((child) => getGroupChildrenTree(groups, child.id)),
  };
}

export function getGroupFlatten(groups, groupId) {
  let tree = getGroupChildrenTree(groups, groupId);
  let flatten = [];
  let traverse = (node) => {
    flatten.push(node.id);
    if (node.children) {
      node.children.forEach((child) => traverse(child));
    }
  };
  traverse(tree);
  return flatten;
}

export function getRelatedScreens(groups, allScreens, groupId) {
  let groupIds = getGroupFlatten(groups, groupId);
  let screens = [];

  groupIds.forEach((id) => {
    let groupScreens = allScreens.filter((screen) => screen.screen_group === id);
    screens.push(...groupScreens);
  });

  return screens;
}
