import '@tiptap/extension-text-style';

import { Extension } from '@tiptap/core';
import { MarkType, NodeType } from '@tiptap/pm/model';

export interface LineHeightOptions {
  types: string[];
  getStyle: (lineHeight: string) => string;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    lineHeight: {
      /**
       * Set the line height
       */
      setLineHeight: (lineHeight: string) => ReturnType;
      /**
       * Unset the line height
       */
      unsetLineHeight: () => ReturnType;
      /**
       * Set the line height of a node or mark
       */
      setNodeLineHeight: (
        typeOrName: string | NodeType | MarkType,
        lineHeight: string,
      ) => ReturnType;
      /**
       * Unset the line height of a node or mark
       */
      unsetNodeLineHeight: (typeOrName: string | NodeType | MarkType) => ReturnType;
    };
  }
}

export const LineHeight = Extension.create<LineHeightOptions>({
  name: 'lineHeight',

  addOptions(): LineHeightOptions {
    return {
      types: ['textStyle'],
      getStyle: (lineHeight: string) => {
        return `line-height: ${lineHeight}`;
      },
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          lineHeight: {
            default: null,
            parseHTML: (element) => element.style.lineHeight?.replace(/['"]+/g, ''),
            renderHTML: (attributes) => {
              if (!attributes.lineHeight) {
                return {};
              }

              return {
                style: this.options.getStyle(attributes.lineHeight),
              };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setLineHeight:
        (lineHeight) =>
        ({ chain }) => {
          return chain().setMark('textStyle', { lineHeight }).run();
        },
      unsetLineHeight:
        () =>
        ({ chain }) => {
          return chain().setMark('textStyle', { lineHeight: null }).removeEmptyTextStyle().run();
        },
      setNodeLineHeight:
        (typeOrName, lineHeight) =>
        ({ commands }) => {
          return commands.updateAttributes(typeOrName, { lineHeight: lineHeight });
        },
      unsetNodeLineHeight:
        (typeOrName) =>
        ({ commands }) => {
          return commands.resetAttributes(typeOrName, 'lineHeight');
        },
    };
  },
});
