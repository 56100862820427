<template>
  <Transition name="ui-modal">
    <div class="ui-modal" v-if="isOpen">
      <div class="ui-modal-overlay"></div>
      <div class="ui-modal-content-container" @click="close">
        <div class="ui-modal-content" @click.stop>
          <!-- Header -->
          <div class="ui-modal-header">
            <div class="ui-modal-header-top">
              <div class="ui-modal-title">
                <i class="material-icons-outlined" v-if="icon">{{ icon }}</i>
                <div class="text">{{ title }}</div>
              </div>
              <div class="ui-modal-close" @click="close">
                <i class="material-icons-outlined">close</i>
              </div>
            </div>
            <div class="ui-modal-header-bottom" v-if="$slots.subTitle">
              <slot name="subTitle" />
            </div>
          </div>

          <!-- Body -->
          <div class="ui-modal-body" :class="[{ scrollable }]">
            <div class="ui-modal-body-fixed" v-if="$slots.bodyFixed">
              <slot name="bodyFixed" />
            </div>
            <div class="ui-modal-body-scroll scrollbar" v-if="scrollable">
              <slot />
            </div>
            <slot v-else />
          </div>

          <!-- Footer -->
          <div class="ui-modal-footer" v-if="this.$slots.footer">
            <slot name="footer" />
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
  export default {
    name: 'BaseModal',
    props: {
      title: {
        type: String,
        required: true,
      },
      icon: {
        type: String,
        default: '',
      },
      scrollable: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        isOpen: false,
      };
    },
    methods: {
      open() {
        this.isOpen = true;
      },
      close() {
        this.isOpen = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .ui-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;

    &.ui-modal {
      &-enter {
        .ui-modal-overlay {
          opacity: 0;
        }
        .ui-modal-content {
          transform: translateY(-100px);
          opacity: 0;
        }
      }
      &-enter-to {
        .ui-modal-overlay {
          opacity: 1;
        }

        .ui-modal-content {
          transform: scale(1) translateY(0);
          opacity: 1;
        }
      }
      &-enter-active {
        transition: all 0.3s ease-in;
        .ui-modal-overlay {
          transition: all 0.1s ease-in;
        }
        .ui-modal-content {
          transition: all 0.2s ease-out;
        }
      }

      &-leave-to {
        .ui-modal-overlay {
          opacity: 0;
        }

        .ui-modal-content {
          transform: scale(0.95) translateY(100px);
          opacity: 0;
        }
      }
      &-leave-active {
        transition: all 0.2s ease-out;
        .ui-modal-overlay {
          transition: all 0.2s ease-out;
        }
        .ui-modal-content {
          transition: all 0.1s ease-in;
        }
      }
    }

    .ui-modal-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.45);
      z-index: 999;
    }
    .ui-modal-content-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1000;
      display: flex;
      align-items: center;
      justify-content: center;
      .ui-modal-content {
        display: flex;
        flex-direction: column;
        width: 752px;
        background-color: #fff;
        box-sizing: border-box;
        max-height: 80%;
        .ui-modal-header {
          display: flex;
          flex-direction: column;
          margin: 0px 32px;
          padding-top: 24px;
          padding-bottom: 16px;
          border-bottom: 2px solid #f0f0f0;

          .ui-modal-header-top {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .ui-modal-header-bottom {
            margin-top: 8px;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #6a6b6a;
          }
          .ui-modal-title {
            display: flex;
            i {
              font-size: 24px;
              margin-right: 6px;
            }
            .text {
              font-family: 'Whyte Inktrap';
              font-style: normal;
              font-weight: 400;
              font-size: 20px;
              height: 26px;
            }
          }
          .ui-modal-close {
            cursor: pointer;
            height: 24px;
            color: #323232;
            transition: all 0.2s ease-in;
            i {
              color: inherit;
              font-size: 24px;
            }
            &:hover {
              color: #e3003a;
            }
          }
        }

        .ui-modal-body {
          padding: 8px 32px;
          flex: 1;

          &.scrollable {
            display: flex;
            flex-direction: column;
            overflow: hidden;

            .ui-modal-body-scroll {
              flex: 1;
              overflow-y: auto;
              overflow-x: hidden;
              margin: -10px -32px;
              padding: 10px 32px;
            }
          }

          &:last-child {
            padding-bottom: 0px !important;
          }
        }

        .ui-modal-footer {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding: 24px 32px;
          background-color: #fafafa;
        }
      }
    }
  }
</style>
