<template>
  <Modal
    @closeModal="closeModal"
    v-show="showModal"
    :isSaving="isLoading"
    :title="content ? 'Move Content' : 'Move Contents'"
    :okFunction="updateDirectory"
  >
    <template v-slot:body>
      <div>
        <label for="mediaFolders" style="font-size: 16px">
          {{
            `In what folder do you want to save the selected ${content ? 'content' : 'contents'}?`
          }}
        </label>
        <select id="mediaFolders" class="form-control" v-model="folderId">
          <option value="">Select folder</option>
          <option v-for="(folder, index) in folders" :key="index" :value="folder.id">
            {{ folder.name }}
          </option>
        </select>
      </div>
    </template>
  </Modal>
</template>

<script>
  import Modal from '@/components/common/Modal.vue';
  import { apiMoveFile, apiMultiMoveFiles } from '@/api/content';
  import { mapGetters } from 'vuex';

  export default {
    name: 'DirectoryChangeModal',

    components: { Modal },

    props: {
      content: {
        type: Object,
        default: null,
      },
      contents: {
        type: Array,
        default: null,
      },
    },

    data() {
      return {
        showModal: false,
        isLoading: false,
        folderId: '',
      };
    },

    computed: {
      ...mapGetters({ folders: 'getAllContentFolders' }),
    },

    mounted() {
      this.showModal = true;
      this.setSelectedFolder();
    },

    methods: {
      async updateDirectory() {
        if (!this.folderId) {
          this.$toasted.global.general_error({ message: 'Select a folder first' });
          return;
        }

        this.isLoading = true;

        try {
          if (this.content) {
            await apiMoveFile(this.content.item_id, this.folderId);
          } else if (this.contents) {
            await apiMultiMoveFiles(
              this.contents.map((item) => item.item_id),
              this.folderId,
            );
          }
          this.$emit('onUpdate');
          this.$toasted.global.general_success({ message: 'Content successfully moved' });
          this.$emit('closeModal');
        } catch {
          this.$toasted.global.general_error({ message: 'Failed to move the content' });
        }

        this.isLoading = false;
      },

      setSelectedFolder() {
        if (this.content) {
          if (this.content.item_folder === this.$store.state.content.rootId) return;
          this.folderId = this.content.item_folder;
        } else if (this.contents) {
          this.folderId = this.contents[0].item_folder;
        }
      },

      closeModal() {
        this.showModal = false;
        this.$emit('closeModal');
      },
    },
  };
</script>
