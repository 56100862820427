import { getWidgetId, round } from '@/helpers/utils';
import { REFRESH_TEMPLATE_STATUS } from '@/config/constants';
import {
  TEMPLATE_DESIGNER_SELECT_WIDGET,
  UPDATE_TEMPLATE_WIDGET_STATE,
  SET_GUIDE_LINES,
  SET_REFRESHING_TEMPLATE_STATE,
} from '@/store/actions/templateDesigner';
import { restrictToBounds } from '@/helpers/draggable';

export default {
  data() {
    return {
      hideParent: false,
      keepZIndexUp: false,
      startPosition: null,
    };
  },

  watch: {
    templateDimensions() {
      this.updateVueDraggable();
    },
  },

  computed: {
    showGrid() {
      return this.$store.state.templateDesigner.showTemplateGrid;
    },

    gridScale() {
      const { x, y } = this.$store.state.templateDesigner.template?.settings.grid || {
        x: 20,
        y: 20,
      };

      return this.showGrid ? [x * this.templateScaleFactor, y * this.templateScaleFactor] : null;
    },

    isSnapActive() {
      return this.$store.state.templateDesigner.isSnapGridActive;
    },

    templateDimensions() {
      return this.$store.getters.getTemplateDimensions;
    },

    templateScaleFactor() {
      return this.$store.getters.getTemplateDimensions.ratio;
    },

    isWidgetSelected() {
      if (!this.$store.state.templateDesigner.selectedWidget) {
        return false;
      }

      const selectedWidgetId = getWidgetId(this.$store.state.templateDesigner.selectedWidget);

      return selectedWidgetId === getWidgetId(this.widget);
    },

    isWidgetLocked() {
      return !!this.widget.position.isLocked;
    },

    isWidgetLockedByOverride() {
      const isScreenOverrideMode = !!this.$route.query.screen && !this.$route.query.group;
      const isGroupOverride = this.widget.object.override?.screen_group;

      return isScreenOverrideMode && isGroupOverride;
    },

    isSavingTemplate() {
      return this.$store.state.templateDesigner.isSavingTemplate;
    },
  },

  methods: {
    onWidgetDrag(x, y) {
      if (this.startPosition && x === this.startPosition.x && y === this.startPosition.y) {
        this.startPosition = null;
        this.setRefreshingStatus(REFRESH_TEMPLATE_STATUS.STAND_BY);
        return;
      } // Avoids updating widget on simple selection

      this.startPosition = null;

      const {
        ratio,
        height: templateHeight,
        width: templateWidth,
        layoutWidth,
        layoutHeight,
      } = this.templateDimensions;

      const updatedWidget = {
        ...this.widget,
        position: {
          ...this.widget.position,
          x: restrictToBounds(round((x * 100) / layoutWidth, 2), 0, 100),
          y: restrictToBounds(round((y * 100) / layoutHeight, 2), 0, 100),
          xPixels: restrictToBounds(round(x / ratio, 2), 0, templateWidth),
          yPixels: restrictToBounds(round(y / ratio, 2), 0, templateHeight),
        },
      };

      this.updateWidget(updatedWidget);
    },

    updateWidget(widget) {
      this.$store.commit(UPDATE_TEMPLATE_WIDGET_STATE, { widget, update: 'mixing' });
    },

    onWidgetResize(x, y, width, height) {
      const {
        width: templateWidth,
        height: templateHeight,
        layoutWidth,
        layoutHeight,
        ratio,
      } = this.templateDimensions;
      const xScaling = 100 / layoutWidth;
      const yScaling = 100 / layoutHeight;

      const newPosition = {
        x: restrictToBounds(round(x * xScaling, 2), 0, 100),
        y: restrictToBounds(round(y * yScaling, 2), 0, 100),
        width: restrictToBounds(round(width * xScaling, 2), 0, 100),
        height: restrictToBounds(round(height * yScaling, 2), 0, 100),

        xPixels: restrictToBounds(round(x / ratio, 2), 0, templateWidth),
        yPixels: restrictToBounds(round(y / ratio, 2), 0, templateHeight),
        widthPixels: restrictToBounds(round(width / ratio, 2), 0, templateWidth),
        heightPixels: restrictToBounds(round(height / ratio, 2), 0, templateHeight),

        zIndex: this.widget.position.zIndex,
      };

      this.updateWidget({
        ...this.widget,
        position: {
          ...this.widget.position,
          ...newPosition,
        },
      });
    },

    setRefreshingStatus(status) {
      this.$store.commit(SET_REFRESHING_TEMPLATE_STATE, status);
    },

    getPosition(widget) {
      const { layoutWidth, layoutHeight, ratio } = this.templateDimensions;
      const xScaling = layoutWidth / 100;
      const yScaling = layoutHeight / 100;

      const { position } = widget;
      const usePixels = this.widget.position.usePixels;

      return {
        left: usePixels ? position.xPixels * ratio : position.x * xScaling,
        top: usePixels ? position.yPixels * ratio : position.y * yScaling,
        height: usePixels ? position.heightPixels * ratio : position.height * yScaling,
        width: usePixels ? position.widthPixels * ratio : position.width * xScaling,
      };
    },

    onSelected(widget) {
      this.keepZIndexUp = true;
      this.setRefreshingStatus(REFRESH_TEMPLATE_STATUS.SELECTING);
      this.$store.commit(TEMPLATE_DESIGNER_SELECT_WIDGET, widget);
    },

    onChangePosition(isMoving = true, x = null, y = null) {
      if (this.isWidgetLockedByOverride) return;

      this.startPosition = isMoving ? { x, y } : null;

      const widgetStatus = isMoving
        ? REFRESH_TEMPLATE_STATUS.MOVING
        : REFRESH_TEMPLATE_STATUS.RESIZING;

      this.setRefreshingStatus(
        this.isWidgetLocked ? REFRESH_TEMPLATE_STATUS.STAND_BY : widgetStatus,
      );
    },

    getRefLineParams(linesData) {
      this.$store.commit(SET_GUIDE_LINES, linesData);
    },

    scaleTemplateValue(value) {
      return this.templateScaleFactor * value;
    },

    updateVueDraggable() {
      /* ---- THIS IS IMPORTANT---
        DRAGGABLE SAVES THE PARENT WIDTH AND HEIGHT WHEN THE COMPONENT IS LOADED
        AND THE ONLY WAY I FOUND TO UPDATE THOSE DIMENSIONS WAS FORCING MOUNTING
        THE DRAGGABLE COMPONENT AGAIN EVENT. EVERY OTHER WAY BREAKS THE WIDGET'S
        POSITIONING
      */
      this.hideParent = true;

      const _this = this;

      setTimeout(function () {
        _this.hideParent = false;
      }, 1);
    },
  },
};
