<template>
  <SidebarContainer class="screen-placeholder-sidebar">
    <template>
      <div class="placeholder">
        <div class="icon">
          <span class="material-icons-outlined align-middle">monitor</span>
        </div>
        <h2>Add & Manage Screens</h2>
        <p>
          Create playlists and categories, change order and duration of items, add triggers and
          widgets to your playlist
        </p>

        <div class="actions">
          <Button theme="primary" icon="add_to_queue" @click="openScreenModal"> Add Screen </Button>
          <Button theme="primary" icon="add" @click.native="openGroupModal"> Create Group </Button>
        </div>
      </div>
    </template>
  </SidebarContainer>
</template>

<script>
  import SidebarContainer from '@/components/common/SidebarContainer.vue';
  import Button from '@/components/common/Button.vue';

  export default {
    emits: ['addScreen', 'createGroup'],
    inject: ['openScreenModal', 'openGroupModal'],
    components: {
      SidebarContainer,
      Button,
    },
  };
</script>

<style lang="scss" scoped>
  .screen-placeholder-sidebar {
    display: flex;
    align-items: center;
    text-align: center;
    .icon {
      margin-bottom: 24px;
      span {
        font-size: 64px;
        color: #e3003a;
      }
    }

    h2 {
      font-family: 'Whyte Inktrap';
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 8px;
    }

    p {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #6a6b6a;
    }
    .actions {
      display: flex;
      flex-direction: column;
      gap: 16px;
      ::v-deep .ui-button {
        width: 100%;
      }
    }
  }
</style>
