<template>
  <div class="clearable-input">
    <input
      type="text"
      class="form-control"
      :placeholder="placeholder"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      v-model="searchValue"
      @input="emitSearch"
    />
    <span v-if="searchValue.length > 0 && loading" class="loading-icon" @click="cleanInput"> </span>
    <span v-else-if="searchValue.length > 0 && !loading" class="clear-input" @click="cleanInput">
      &times;
    </span>
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'SearchInput',
    emits: ['search'],

    props: {
      value: {
        type: String,
        default: '',
      },
      placeholder: {
        type: String,
        default: 'Search',
      },
      loading: {
        type: Boolean,
        default: false,
      },
    },

    data: function () {
      return {
        searchValue: '',
      };
    },

    watch: {
      value(val) {
        if (val !== this.searchValue) {
          this.searchValue = val;
        }
      },
    },

    mounted() {
      this.searchValue = this.value;
    },

    methods: {
      emitSearch() {
        this.$emit('search', this.searchValue);
      },

      cleanInput() {
        this.searchValue = '';
      },
    },
  };
</script>

<style lang="scss" scoped>
  .clearable-input {
    position: relative;
    display: inline-block;
  }

  .clear-input {
    position: absolute;
    top: 10px;
    right: 10px;
    margin: auto;
    font-weight: bold;
    font-size: 1.4em;
    padding: 0 0.2em;
    line-height: 1em;
    cursor: pointer;
    background-color: white;
  }

  .clearable-input > input::-ms-clear {
    display: none;
  }

  .loading-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid #ccc;
    border-top-color: #333;
    animation: spin 1s ease-in-out infinite;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
