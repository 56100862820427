<template>
  <Transition name="ui-modal">
    <div class="ui-modal" v-if="isOpen">
      <div class="ui-modal-overlay"></div>
      <div class="ui-modal-close" @click="close">
        <span class="material-icons-outlined">close</span>
      </div>
      <div class="ui-modal-content-container">
        <div class="ui-modal-content" @click.stop>
          <slot />
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
  import { defineComponent } from 'vue';

  export default defineComponent({
    emits: ['close'],

    data() {
      return {
        isOpen: false,
      };
    },

    methods: {
      open() {
        this.isOpen = true;
      },
      close() {
        this.$emit('close');
        this.isOpen = false;
      },
    },
  });
</script>

<style lang="scss" scoped>
  .ui-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;

    &.ui-modal {
      &-enter {
        .ui-modal-overlay {
          opacity: 0;
        }
        .ui-modal-content {
          transform: translateY(-100px);
          opacity: 0;
        }
      }
      &-enter-to {
        .ui-modal-overlay {
          opacity: 1;
        }

        .ui-modal-content {
          transform: scale(1) translateY(0);
          opacity: 1;
        }
      }
      &-enter-active {
        transition: all 0.3s ease-in;
        .ui-modal-overlay {
          transition: all 0.1s ease-in;
        }
        .ui-modal-content {
          transition: all 0.2s ease-out;
        }
      }

      &-leave-to {
        .ui-modal-overlay {
          opacity: 0;
        }

        .ui-modal-content {
          transform: scale(0.95) translateY(100px);
          opacity: 0;
        }
      }
      &-leave-active {
        transition: all 0.2s ease-out;
        .ui-modal-overlay {
          transition: all 0.2s ease-out;
        }
        .ui-modal-content {
          transition: all 0.1s ease-in;
        }
      }
    }

    .ui-modal-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.45);
      z-index: 999;
    }

    .ui-modal-close {
      position: absolute;
      top: 0;
      right: 0;
      margin-top: 20px;
      margin-right: 20px;
      color: white;
      z-index: 1001;
      height: 30px;
      padding: 3px;
      cursor: pointer;
      transition: all 200ms ease-in-out;
      border-radius: 20px;

      &:hover {
        background-color: rgba(255, 255, 255, 0.3);
      }
    }
    .ui-modal-content-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1000;
      display: flex;
      align-items: center;
      justify-content: center;
      .ui-modal-content {
        position: relative;
        width: 80%;
        height: 80%;
        background-color: #d9d9d9;
        box-sizing: border-box;
      }
    }
  }
</style>
