<template>
  <Modal
    @closeModal="closeModal"
    v-show="showModal"
    :isLoading="isLoading"
    title="Copy Confirmation"
    :okFunction="copyTemplate"
  >
    <template v-slot:body>
      <div class="copy-description">Do you want to make a copy of this template?</div>
    </template>
  </Modal>
</template>

<script>
  import { apiAttachTemplateChild, apiCreateTemplate, apiUpdateTemplate } from '@/api/templates';
  import Modal from '@/components/common/Modal.vue';
  import { WIDGET_TYPES } from '@/models/layoutDesigner';
  import {
    CREATE_TEMPLATE_COMMON_WIDGET,
    CREATE_TEMPLATE_LAYER,
  } from '@/store/actions/templateDesigner';

  export default {
    name: 'CopyTemplateModal',

    components: { Modal },

    props: {
      template: {
        type: Object,
        default: null,
      },
      widgets: {
        type: Array,
        default: () => [],
      },
    },

    data() {
      return {
        showModal: false,
        isLoading: false,
      };
    },

    mounted() {
      this.showModal = true;
    },

    methods: {
      async updateTemplate(templateData) {
        try {
          const templateSettings = {
            ...templateData.settings,
            appId: templateData.app_id,
          };

          const successful = await apiUpdateTemplate(templateData.template_id, templateSettings);
        } catch (error) {
          console.log('TemplatesView.vue - updateTemplate error: ', error);
        }
      },

      async copyTemplate() {
        this.isLoading = true;

        try {
          const templateData = {
            ...this.template.settings,
            name: `${this.template.settings.name} copy`,
          };

          const response = await apiCreateTemplate(templateData);
          this.updateTemplate(response.data);

          const newTemplate = response.data;

          const createWidgetsPromises = [];
          const widgetsArray = this.widgets;

          for (let i = 0; i < widgetsArray.length; i++) {
            const widget = widgetsArray[i];
            const widgetData = {
              type: widget.type,
              position: widget.position,
              item_id:
                widget.type === WIDGET_TYPES.PLAYLIST
                  ? widget.object.playlist_id
                  : widget.object.item_id,
            };

            let attachApiCall;
            const appExtraField = {};

            switch (widget.itemType) {
              case WIDGET_TYPES.BUTTON:
                appExtraField.buttonText = widget.object.buttonText;
              case WIDGET_TYPES.TEXT:
              case WIDGET_TYPES.RICH_TEXT:
                appExtraField.userText = widget.object.userText;
              case WIDGET_TYPES.CLOCK:
                appExtraField.clockName = widget.object.clockName;

                const appData = {
                  type: widget.itemType,
                  name: widget.object.name,
                  config: { ...widget.object, config: undefined, item_id: undefined },
                  ...appExtraField,
                };

                attachApiCall = this.$store.dispatch(CREATE_TEMPLATE_COMMON_WIDGET, {
                  templateId: newTemplate.template_id,
                  widget,
                  widgetData: appData,
                  position: widget.position,
                });
                break;
              case WIDGET_TYPES.PDF:
              case WIDGET_TYPES.IMAGE:
              case WIDGET_TYPES.VIDEO:
              case WIDGET_TYPES.APP:
                // case WIDGET_TYPES.PLAYLIST:
                attachApiCall = apiAttachTemplateChild({
                  templateId: newTemplate.template_id,
                  widgetData,
                });
                break;

              case WIDGET_TYPES.LAYER:
                attachApiCall = this.$store.dispatch(CREATE_TEMPLATE_LAYER, {
                  templateId: newTemplate.template_id,
                  widget: { ...widget.object, position: widget.position, item_id: undefined },
                });

                break;
            }

            createWidgetsPromises.push(attachApiCall);
          }

          await Promise.all(createWidgetsPromises);

          this.$router.push({
            name: 'Template',
            params: { templateId: newTemplate.template_id },
          });
        } catch (error) {
          this.$toasted.global.general_error({
            message: String(error),
          });
        }

        this.isLoading = false;
        this.closeModal();
      },

      closeModal() {
        this.showModal = false;
        this.$emit('closeModal');
      },
    },
  };
</script>
