<template>
  <Transition name="ui-modal">
    <div class="ui-modal" v-if="isOpen">
      <div class="ui-modal-overlay"></div>
      <div class="ui-modal-content-container" @click="close">
        <div class="ui-modal-content" @click.stop :style="modelContentStyle">
          <!-- Header -->
          <div class="ui-modal-header">
            <div class="ui-modal-title">
              <i class="material-icons-outlined" v-if="icon">{{ icon }}</i>
              <div class="text">{{ title }}</div>
            </div>
            <div v-if="showCloseIcon" class="ui-modal-close" @click="close">
              <i class="material-icons-outlined">close</i>
            </div>
          </div>

          <div class="separator"></div>

          <!-- Body -->
          <div class="ui-modal-body">
            <Loader v-if="isLoading" />
            <slot v-else />
          </div>

          <!-- Footer -->
          <div class="ui-modal-footer" v-if="this.$slots.footer">
            <slot name="footer" />
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
  import Loader from '@/components/common/Loader.vue';

  export default {
    components: {
      Loader,
    },
    emits: ['close'],
    expose: ['open', 'close'],
    props: {
      clickOutsideClose: {
        type: Boolean,
        default: true,
      },
      title: {
        type: String,
        required: true,
      },
      icon: {
        type: String,
        default: '',
      },
      showCloseIcon: {
        type: Boolean,
        default: true,
      },
      isLoading: { type: Boolean, default: false },
      modelContentStyle: {
        type: Object,
        default: () => ({}),
      },
    },

    data() {
      return {
        isOpen: false,
      };
    },

    methods: {
      open() {
        this.isOpen = true;
      },
      close() {
        if (!this.clickOutsideClose) return;

        this.$emit('close');
        this.isOpen = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .ui-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;

    &.ui-modal {
      &-enter {
        .ui-modal-overlay {
          opacity: 0;
        }
        .ui-modal-content {
          transform: translateY(-100px);
          opacity: 0;
        }
      }
      &-enter-to {
        .ui-modal-overlay {
          opacity: 1;
        }

        .ui-modal-content {
          transform: scale(1) translateY(0);
          opacity: 1;
        }
      }
      &-enter-active {
        transition: all 0.3s ease-in;
        .ui-modal-overlay {
          transition: all 0.1s ease-in;
        }
        .ui-modal-content {
          transition: all 0.2s ease-out;
        }
      }

      &-leave-to {
        .ui-modal-overlay {
          opacity: 0;
        }

        .ui-modal-content {
          transform: scale(0.95) translateY(100px);
          opacity: 0;
        }
      }
      &-leave-active {
        transition: all 0.2s ease-out;
        .ui-modal-overlay {
          transition: all 0.2s ease-out;
        }
        .ui-modal-content {
          transition: all 0.1s ease-in;
        }
      }
    }

    .ui-modal-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.45);
      z-index: 999;
    }
    .ui-modal-content-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1000;
      display: flex;
      align-items: center;
      justify-content: center;
      .ui-modal-content {
        width: 752px;
        background-color: #fff;
        box-sizing: border-box;
        padding: 32px 32px 32px 32px;
        .ui-modal-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .ui-modal-title {
            display: flex;
            i {
              font-size: 24px;
              margin-right: 6px;
            }
            .text {
              font-style: normal;
              font-weight: 400;
              font-size: 20px;
              display: inline;
              vertical-align: 10px;
            }
          }
          .ui-modal-close {
            cursor: pointer;
            i {
              font-size: 24px;
            }
          }
        }

        .separator {
          margin-top: 16px;
        }

        .ui-modal-body {
          padding: 24px 0px;

          &:last-child {
            padding-bottom: 0px !important;
          }
        }

        .ui-modal-footer {
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      }
    }
  }
</style>
