<template>
  <Modal
    @closeModal="closeModal"
    v-show="showModal"
    :disabled="isDisabled"
    :title="'Item Schedule'"
    :okFunction="submitSchedule"
    okButtonLabel="Save"
    thirdButtonLabel="Reset"
    :thirdFunction="resetScheduler"
    :modalStyles="{ width: '600px' }"
  >
    <template v-slot:body>
      <div class="scheduler-container">
        <div class="form-group">
          <label for="" class="d-block w-100">
            Schedule your new object to limit when it's available.
          </label>
          <div class="schedule-buttons mx-n1">
            <div class="flex-1 button">
              <input class="d-none" type="radio" id="always" value="always" v-model="available" />
              <label
                class="w-100 btn-radio"
                :class="available === 'always' && 'selected'"
                for="always"
              >
                <span class="icon material-icons-outlined"> all_inclusive </span>
                Always show
              </label>
            </div>
            <div class="flex-1 button">
              <input
                class="d-none"
                type="radio"
                id="schedule"
                value="schedule"
                v-model="available"
              />
              <label
                class="w-100 btn-radio"
                :class="available === 'schedule' && 'selected'"
                for="schedule"
              >
                <span class="icon material-icons-outlined">event</span>
                Schedule
              </label>
            </div>
          </div>
        </div>

        <p v-if="available === 'always'" class="mb-4">Content will be shown all the time.</p>

        <div v-if="available === 'schedule'">
          <div class="form-group">
            <label for="schedule_type">How do you want to schedule this object</label>
            <select name="schedule_type" id="schedule_type" class="form-control" v-model="type">
              <option value="time-to-time">From & to date</option>
              <option value="advanced">Advanced schedule</option>
            </select>
          </div>

          <div v-if="type === 'advanced'" class="mb-3">
            <div class="form-group">
              <label for="days_hours_type">Days and hours type</label>
              <select
                name="days_hours_type"
                id="days_hours_type"
                class="form-control"
                v-model="daysHourType"
              >
                <option value="same-hours">Same hours each day</option>
                <option value="diff-hours">Different hours</option>
              </select>
            </div>

            <label for="">Days</label>
            <div class="d-flex mx-n1 mb-3">
              <div class="flex-1 mx-0.5">
                <input class="d-none" type="checkbox" id="monday" value="monday" v-model="days" />
                <label
                  class="w-100 btn-radio btn-radio-sm"
                  :class="days.includes('monday') && 'selected'"
                  for="monday"
                  >M</label
                >
              </div>
              <div class="flex-1 mx-0.5">
                <input class="d-none" type="checkbox" id="tuesday" value="tuesday" v-model="days" />
                <label
                  class="w-100 btn-radio btn-radio-sm"
                  :class="days.includes('tuesday') && 'selected'"
                  for="tuesday"
                  >T</label
                >
              </div>
              <div class="flex-1 mx-0.5">
                <input
                  class="d-none"
                  type="checkbox"
                  id="wednesday"
                  value="wednesday"
                  v-model="days"
                />
                <label
                  class="w-100 btn-radio btn-radio-sm"
                  :class="days.includes('wednesday') && 'selected'"
                  for="wednesday"
                  >W</label
                >
              </div>
              <div class="flex-1 mx-0.5">
                <input
                  class="d-none"
                  type="checkbox"
                  id="thursday"
                  value="thursday"
                  v-model="days"
                />
                <label
                  class="w-100 btn-radio btn-radio-sm"
                  :class="days.includes('thursday') && 'selected'"
                  for="thursday"
                  >T</label
                >
              </div>
              <div class="flex-1 mx-0.5">
                <input class="d-none" type="checkbox" id="friday" value="friday" v-model="days" />
                <label
                  class="w-100 btn-radio btn-radio-sm"
                  :class="days.includes('friday') && 'selected'"
                  for="friday"
                  >F</label
                >
              </div>
              <div class="flex-1 mx-0.5">
                <input
                  class="d-none"
                  type="checkbox"
                  id="saturday"
                  value="saturday"
                  v-model="days"
                />
                <label
                  class="w-100 btn-radio btn-radio-sm"
                  :class="days.includes('saturday') && 'selected'"
                  for="saturday"
                  >S</label
                >
              </div>
              <div class="flex-1 mx-0.5">
                <input class="d-none" type="checkbox" id="sunday" value="sunday" v-model="days" />
                <label
                  class="w-100 btn-radio btn-radio-sm"
                  :class="days.includes('sunday') && 'selected'"
                  for="sunday"
                  >S</label
                >
              </div>
            </div>

            <div v-if="daysHourType === 'diff-hours'">
              <div class="form-group mb-3" v-for="day in days" :key="day">
                <label class="text-capitalize">{{ day }}</label>
                <time-slider
                  @input="(v) => (daysTimes[day] = v)"
                  :value="daysTimes[day] || [2 * 60, 22 * 60]"
                />
              </div>
            </div>
            <div v-else>
              <div class="form-group mb-3">
                <label class="text-capitalize">Each day:</label>
                <time-slider
                  @input="(v) => (daysTime = v)"
                  :value="daysTime || [2 * 60, 22 * 60]"
                />
              </div>
            </div>
          </div>
          <div>
            <div class="form-group">
              <label for="from">When will it start to appear?</label>
              <datetime v-model="timeToTime.from" input-class="form-control" type="datetime" />
            </div>
            <div class="form-group">
              <label for="to">When will it stop to appear?</label>
              <datetime v-model="timeToTime.to" input-class="form-control" type="datetime" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
  import moment from 'moment';
  import { Datetime } from 'vue-datetime';

  import 'vue-datetime/dist/vue-datetime.css';

  import Modal from '@/components/common/Modal.vue';
  import TimeSlider from '@/components/common/scheduler/TimeSlider.vue';

  export default {
    name: 'Scheduler',

    components: {
      Datetime,
      TimeSlider,
      Modal,
    },

    props: {
      playlistItem: {
        type: Object,
        default: () => null,
      },
    },

    data() {
      return {
        available: 'always',
        type: 'time-to-time',
        timeToTime: {
          from: undefined,
          to: undefined,
        },
        daysHourType: 'same-hours',
        days: [],
        daysTime: null,
        daysTimes: {
          monday: null,
          tuesday: null,
          wednesday: null,
          thursday: null,
          friday: null,
          saturday: null,
          sunday: null,
        },
        showModal: false,
      };
    },

    mounted() {
      this.showModal = true;
    },

    created() {
      if (this.playlistItem.scheduler) {
        this.assignInitialData(this.playlistItem.scheduler);
      }
    },

    computed: {
      isDisabled() {
        if (this.available === 'always') return false;

        if (this.type === 'time-to-time' && !this.timeToTime.from && !this.timeToTime.to) {
          return !this.timeToTime.from && !this.timeToTime.to;
        }

        if (this.daysHourType === 'diff-hours' && this.days.length === 0) {
          return true;
        }

        const fromDate = this.timeToTime.from ? moment(this.timeToTime.from).toDate() : null;
        const toDate = this.timeToTime.to ? moment(this.timeToTime.to).toDate() : null;

        let isToDateValid = toDate ? moment(toDate).isAfter() : true;

        if (!isToDateValid) return true;
        if (!fromDate) return false;

        isToDateValid = moment(toDate).isAfter(moment(fromDate));

        return !isToDateValid;
      },
    },

    methods: {
      async submitSchedule() {
        const data = this.getSchedulerData();

        this.$emit('setSchedule', {
          assocId: this.playlistItem.assoc_id,
          overrideId: this.playlistItem.override?.id,
          scheduler: data,
          wid: this.playlistItem.wid,
        });

        this.closeModal();
      },

      handleCancel() {
        const data = this.getSchedulerData();

        this.$emit('returnToTimeTable', data);
      },

      getSchedulerData() {
        return {
          available: this.available,
          daysHourType: this.daysHourType,
          days: this.days,
          daysTime: this.daysTime,
          daysTimes: this.daysTimes,
          timeToTime: this.timeToTime,
          type: this.type,
        };
      },

      assignInitialData(data) {
        this.available = data.available;
        this.daysHourType = data.daysHourType;
        this.days = data.days ? [...data.days] : [];
        this.daysTime = data.daysTime ? [...data.daysTime] : null;
        this.type = data.type;

        if (data.daysTimes) this.daysTimes = { ...data.daysTimes };
        if (data.timeToTime) this.timeToTime = { ...data.timeToTime };
      },

      resetScheduler() {
        this.available = 'always';
        this.type = 'time-to-time';
        this.timeToTime = {
          from: undefined,
          to: undefined,
        };
        this.daysHourType = 'same-hours';
        this.days = [];
        this.daysTime = null;
        this.daysTimes = {
          monday: null,
          tuesday: null,
          wednesday: null,
          thursday: null,
          friday: null,
          saturday: null,
          sunday: null,
        };
      },

      closeModal() {
        this.$emit('closeModal');
        this.showModal = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .scheduler-container {
    min-height: 300px;
    padding: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }

  .flex-1 {
    flex: 1;
  }

  .mx-0\.5 {
    margin-left: 0.125rem;
    margin-right: 0.125rem;
  }
  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }

  .btn-radio {
    display: flex;
    border-radius: 3px;
    background-color: #ededed;
    padding: 0.35rem 0.75rem;
    justify-content: center;
    align-items: center;
    line-height: 1.5rem;

    &.btn-radio-sm {
      padding: 0.25rem 0.5rem;
    }

    cursor: pointer;
    .icon {
      font-size: 1rem;
      margin-right: 0.5rem;
    }
    &:hover {
      background-color: #e5e5e5;
    }
    &.selected {
      background-color: $primaryRed;
      color: white;
    }
  }

  .schedule-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;

    .button {
      min-width: 125px;
    }

    label {
      margin: 0;
    }
  }

  .buttons-container {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;

    button {
      min-width: 120px;
      max-width: 200px;
    }
  }
</style>
