<template>
  <div class="form-group layout-input image-mode-input">
    <label for="image-mode">{{ label }}</label>
    <select
      class="custom-select"
      id="image-mode"
      :value="value"
      :disabled="disabled"
      @change="onSelected"
    >
      <option :value="IMAGE_MODE.ORIGIN" default>Original</option>
      <option :value="IMAGE_MODE.CONTAIN" default>Contain</option>
      <option :value="IMAGE_MODE.STRETCH">Fill</option>
      <option :value="IMAGE_MODE.CROP">Fit</option>
    </select>
  </div>
</template>

<script>
  import { IMAGE_MODE } from '@/models/layoutDesigner';

  export default {
    name: 'ImageMode',
    props: {
      value: {
        type: String,
        default: IMAGE_MODE.ORIGIN,
      },
      label: {
        type: String,
        required: false,
        default: 'Display Mode',
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        IMAGE_MODE,
      };
    },

    methods: {
      onSelected(e) {
        this.$emit('input', e.target.value);
      },
    },
  };
</script>
