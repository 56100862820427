<template>
  <div class="form-group add-content">
    <div
      v-if="value.contentObj && value.contentObj.type"
      class="item-list row border-bottom rounded"
    >
      <div class="col">
        <div class="item d-flex flex-row p-2 my-2" @click.prevent="addContent()">
          <div class="img-container mr-2">
            <div class="hovereffect">
              <img
                v-if="getSimpleType(value.contentObj.mime || value.contentObj.type) === 'image'"
                :src="value.contentObj.url"
                :alt="value.contentObj.name"
              />
              <img
                v-if="getSimpleType(value.contentObj.mime || value.contentObj.type) === 'rss'"
                src="@/assets/img/apps/rss.png"
                :alt="value.contentObj.name"
              />
              <img
                v-if="getSimpleType(value.contentObj.mime || value.contentObj.type) === 'trafiklab'"
                src="@/assets/img/apps/trafiklab.png"
                :alt="value.contentObj.name"
              />
              <img
                v-if="getSimpleType(value.contentObj.mime || value.contentObj.type) === 'table'"
                src="@/assets/img/TableApp.png"
                :alt="value.contentObj.name"
              />
              <img
                v-if="getSimpleType(value.contentObj.mime || value.contentObj.type) === 'video'"
                src="@/assets/img/content/video-placeholder.svg"
                :alt="value.contentObj.name"
              />
            </div>
          </div>

          <div class="desc d-flex flex-column justify-content-around">
            <div class="title">
              {{ value.contentObj.name }}
            </div>

            <div class="subtitle text-secondary">
              <em>{{ getSimpleType(value.contentObj.mime || value.contentObj.type) }}</em>
            </div>
          </div>
        </div>
      </div>
      <a @click.prevent="removeContent()" href="#" class="text-danger col-2 d-flex flex-row my-3">
        <span><i class="material-icons">delete_outline</i></span>
      </a>
    </div>

    <button
      v-if="!value.contentObj || !value.contentObj.type"
      class="button-2-secondary w-100"
      type="button"
      @click.prevent="addContent"
    >
      Add {{ label }}
    </button>

    <ContentBrowserModal
      ref="content_browser_modal"
      @submitted="handleItemSelect"
      :allow-multiple-item-select="false"
      :filterItemTypes="filterItemTypes"
    >
    </ContentBrowserModal>
  </div>
</template>

<script>
  import ContentBrowserModal from '@/components/content/ContentBrowserModal';
  import { simpleTypeMixin } from '@/helpers/mixins';
  import { WIDGET_CONTENT_TYPE } from '@/models/layoutDesigner';
  import rssAppImg from '@/assets/img/apps/rss.png';
  import trafiklabImg from '@/assets/img/apps/trafiklab.png';
  import tableAppImg from '@/assets/img/TableApp.png';

  export default {
    name: 'ContentSelector',

    mixins: [simpleTypeMixin],

    props: {
      value: {
        required: false,
        default: () => {},
      },

      filterItemTypes: {
        type: Array,
        default: () => [],
      },

      label: {
        type: String,
        required: false,
        default: 'Content',
      },
    },

    components: {
      ContentBrowserModal,
    },

    methods: {
      handleItemSelect(selectedItems) {
        if (selectedItems.length) {
          this.value.contentObj = selectedItems[0];
          this.value.contentType = this.getSimpleType(
            selectedItems[0].mime || selectedItems[0].type,
          );

          if (this.value.contentType === 'rss') {
            this.value.contentType = 'app';
            this.value.contentLink = rssAppImg;
          } else if (this.value.contentType === 'table') {
            this.value.contentType = 'app';
            this.value.contentLink = tableAppImg;
          } else if (this.value.contentType === 'trafiklab') {
            this.value.contentType = 'app';
            this.value.contentLink = trafiklabImg;
          } else {
            this.value.contentLink = selectedItems[0].url;
          }
        } else {
          this.value.contentObj = {};
          this.value.contentType = WIDGET_CONTENT_TYPE.NONE;
          this.value.contentLink = '';
        }

        this.updateContent();
        this.$refs.content_browser_modal.close();
      },

      removeContent() {
        this.value.contentObj = {};
        this.value.contentType = WIDGET_CONTENT_TYPE.NONE;
        this.value.contentLink = '';
        this.updateContent();
      },

      addContent() {
        this.$refs.content_browser_modal.open();
      },

      updateContent: function () {
        this.$emit('update', this.value);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .item-list {
    .item {
      cursor: pointer;
      height: 56px;

      .title {
        font-size: 14px;
      }

      .subtitle {
        font-size: 12px;
      }

      .img-container {
        width: 40px;
        height: 40px;

        img {
          width: 40px;
          height: 40px;
          object-fit: cover;
        }
      }
    }
  }
</style>
