import axios from 'axios';

// API docs: https://nominatim.org/release-docs/develop/api/Search/
export const apiSearchLocation = (query) =>
  new Promise((resolve, reject) => {
    const apiUrl = `https://nominatim.openstreetmap.org/search?format=json&q=${query}`;

    axios
      .get(apiUrl)
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
