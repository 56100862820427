var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('SidebarContainer',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticClass:"setting-content-item"},[_c('div',{staticClass:"content-preview"},[(
              _vm.item.content_type !== 'folder' &&
              ['video', 'image', 'pdf'].includes(_vm.getSimpleType(_vm.item.item_type))
            )?_c('div',{staticClass:"preview-body scrollbar"},[(_vm.getSimpleType(_vm.item.item_type) === 'image')?_c('img',{attrs:{"src":_vm.item.img,"alt":"preview"}}):(_vm.getSimpleType(_vm.item.item_type) === 'video')?_c('video',{staticClass:"w-100 h-100",staticStyle:{"object-fit":"fill"},attrs:{"muted":"","preload":"metadata","controls":"","id":"right-sidebar-preview-video"},domProps:{"muted":true}},[_c('source',{attrs:{"src":`${_vm.getEncodedURL(_vm.baseUrl, _vm.item.item_url)}`,"type":_vm.item.item_type}})]):(_vm.item.item_type.includes('pdf'))?_c('PdfView',{attrs:{"pdfImages":_vm.item.pdf_content.thumbnail_url}}):_vm._e()],1):(_vm.item?.item_type?.startsWith('app'))?_c('div',{staticClass:"preview-body scrollbar"},[(_vm.item.item_type === 'app/rss')?_c('RssViewer',{staticClass:"w-100 h-100",attrs:{"setting":_vm.rssAppSetting,"isPreview":""}}):(_vm.item.item_type === 'app/table')?_c('TableAppWebView',{staticClass:"w-100 h-100",attrs:{"settings":_vm.item}}):(_vm.item.item_type === 'app/trafiklab')?_c('TimeTableViewer',{attrs:{"viewerStyles":_vm.item.viewerStyles,"timetable":_vm.item.timetable,"name":_vm.item.name,"app-id":_vm.item.app_id}}):(_vm.item.item_type === 'app/weather')?_c('WeatherView',{attrs:{"app":_vm.item,"isPreview":""}}):_vm._e()],1):_vm._e(),_c('div',{staticClass:"preview-footer"},[_c('div',{staticClass:"type"},[_c('i',{staticClass:"material-icons-outlined align-middle"},[_vm._v(" crop_original ")]),(_vm.item.item_type === _vm.CONTENT_TYPE_TABLE_APP)?_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.CONTENT_TYPE_TENANT))]):_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.item.item_type ? _vm.getSimpleType(_vm.item.item_type) : _vm.getSimpleType(_vm.item.type)))])])])]),_c('div',{staticClass:"content-body"},[_c('Input',{attrs:{"label":"File Name","value":_vm.item.name,"id":"name"}},[_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(_vm.item.name))])]),_c('SidebarDropdown',{attrs:{"title":"Info","default-open":true,"items":[
              {
                name: 'Size',
                value: _vm.item.size ? _vm.humanFileSize(_vm.item.size) : false,
              },
              {
                name: 'Last Modified',
                value: _vm.item.last_modified ? _vm.formattedDate(_vm.item.last_modified) : false,
              },
              {
                name: 'Path',
                value: _vm.item.path ? _vm.item.path : false,
              },
            ]}})],1)])]},proxy:true},{key:"buttons",fn:function(){return [_c('Button',{attrs:{"theme":"secondary","icon":"arrow_right_alt"},nativeOn:{"click":function($event){_vm.showDirectoryModal = true}}},[_vm._v(" Move File ")]),_c('Button',{attrs:{"theme":"secondary","icon":"delete_outline"},nativeOn:{"click":function($event){return _vm.handleDelete.apply(null, arguments)}}},[_vm._v(" Delete File ")])]},proxy:true}])}),(_vm.showDeleteModal)?_c('ContentDeleteModal',{attrs:{"content":_vm.item},on:{"onDelete":function($event){return _vm.$emit('onDelete')},"closeModal":function($event){_vm.showDeleteModal = false}}}):_vm._e(),(_vm.showDirectoryModal)?_c('DirectoryChangeModal',{attrs:{"content":_vm.item},on:{"closeModal":function($event){_vm.showDirectoryModal = false},"onUpdate":function($event){return _vm.$emit('onUpdate')}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }