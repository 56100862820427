<template>
  <div class="sidebar-setting-placeholder">
    <div class="px-3 weather-preview">
      <WeatherView
        v-if="weather.location"
        :location="weather.location?.coordinates"
        :app="{ weather }"
        :style="{ height: '130px', width: '320px' }"
      />
      <div v-else class="no-location">Please select a location</div>
    </div>

    <div class="weather-settings">
      <div class="sidebar-setting-content">
        <div v-show="!weather.location" class="location-picker mb-4">
          <Transition>
            <LocationPicker @updateLocation="updateLocation" />
          </Transition>
        </div>

        <template v-if="weather.location">
          <div class="inputs-container">
            <div class="form-group settings-input" style="border-bottom: 0">
              <label for="template-name">Location</label>
              <input
                class="form-control"
                type="text"
                id="template-name"
                placeholder="Name"
                v-model="weather.location.label"
              />
              <div v-show="weather.location" class="remove-button" @click="removeLocation">
                Change Location
              </div>
            </div>

            <div class="form-group settings-input">
              <label for="template-name">Widget Name</label>
              <input
                class="form-control"
                type="text"
                id="template-name"
                placeholder="Name"
                v-model="weather.name"
              />
            </div>

            <div class="form-group settings-input">
              <label for="app-name">Widget Folder</label>

              <select class="form-control" id="app-name" v-model="weather.folder">
                <option :value="null" default>Main Folder</option>
                <option v-for="folder in folders" :key="folder.id" :value="folder">
                  {{ folder.name }}
                </option>
              </select>
            </div>

            <div class="form-group layout-input content-type-input">
              <label for="content-mode">Widget Type</label>

              <select class="form-control" id="content-mode" v-model="weather.type">
                <option :value="WEATHER_DISPLAY_TYPE?.NOW.value">
                  {{ WEATHER_DISPLAY_TYPE?.NOW.label }}
                </option>
                <option :value="WEATHER_DISPLAY_TYPE?.HOURS.value">
                  {{ WEATHER_DISPLAY_TYPE?.HOURS.label }}
                </option>
                <option :value="WEATHER_DISPLAY_TYPE?.DAYS.value">
                  {{ WEATHER_DISPLAY_TYPE?.DAYS.label }}
                </option>
              </select>
            </div>

            <div class="form-group settings-input">
              <label class="t-sm" for="language">Language</label>

              <select class="form-control input-underline" id="language" v-model="weather.language">
                <option :value="WEATHER_LANGUAGES.ENGLISH.value">
                  {{ WEATHER_LANGUAGES.ENGLISH.label }}
                </option>
                <option :value="WEATHER_LANGUAGES.SWEDISH.value">
                  {{ WEATHER_LANGUAGES.SWEDISH.label }}
                </option>
              </select>
            </div>

            <div class="form-group layout-input font-color-container">
              <div class="font-container">
                <label>Font</label>
                <FontSelector
                  :value="weather.textFont"
                  :fonts="userFonts"
                  @update:value="updateWeatherFont"
                ></FontSelector>
              </div>

              <div class="color-section" v-click-outside="closeColorPicker">
                <div class="color-button" @click="toggleColorPicker('fontColor')">
                  <i class="material-icons-outlined align-middle">text_fields</i>
                  <div class="color-bar" :style="{ backgroundColor: weather.fontColor }"></div>
                </div>
                <div class="color-button" @click="toggleColorPicker('backgroundColor')">
                  <img class="logo w-165" src="@/assets/icon/Colors.svg" alt="Background Color" />
                  <div
                    class="color-bar"
                    :style="{ backgroundColor: weather.backgroundColor }"
                  ></div>
                </div>
                <div class="color-button" @click="toggleColorPicker('iconColor')">
                  <i class="material-icons-outlined align-middle">light_mode</i>
                  <div class="color-bar" :style="{ backgroundColor: weather.iconColor }"></div>
                </div>

                <Sketch
                  v-if="showColorPicker"
                  class="picker"
                  :value="weather[pickerColorKey]"
                  @input="updateColor"
                />
              </div>
            </div>

            <div class="form-group settings-input">
              <label class="t-sm" for="temperature">Temperature</label>
              <select
                class="form-control input-underline"
                id="temperature"
                v-model="weather.temperatureUnit"
              >
                <option :value="WEATHER_TEMPERATURE.CELSIUS.value">
                  {{ WEATHER_TEMPERATURE.CELSIUS.label }}
                </option>
                <option :value="WEATHER_TEMPERATURE.FAHRENHEIT.value">
                  {{ WEATHER_TEMPERATURE.FAHRENHEIT.label }}
                </option>
              </select>
            </div>

            <div
              v-if="weather.type === WEATHER_DISPLAY_TYPE.DAYS.value"
              class="form-group settings-input"
            >
              <label class="t-sm" for="time-format">Weekday Format</label>
              <select
                class="form-control input-underline"
                id="time-format"
                v-model="weather.dayFormat"
              >
                <option :value="WEATHER_DAY_FORMAT.SHORT.value">
                  {{ WEATHER_DAY_FORMAT.SHORT.label }}
                </option>
                <option :value="WEATHER_DAY_FORMAT.WORD.value">
                  {{ WEATHER_DAY_FORMAT.WORD.label }}
                </option>
                <option :value="WEATHER_DAY_FORMAT.DAY_OF_MONTH.value">
                  {{ WEATHER_DAY_FORMAT.DAY_OF_MONTH.label }}
                </option>
              </select>
            </div>

            <div
              v-if="weather.type === WEATHER_DISPLAY_TYPE.HOURS.value"
              class="form-group settings-input"
            >
              <label class="t-sm" for="time-format">Time Format</label>
              <select
                class="form-control input-underline"
                id="time-format"
                v-model="weather.timeFormat"
              >
                <option :value="WEATHER_HOURS_FORMAT.TWELVE.value">
                  {{ WEATHER_HOURS_FORMAT.TWELVE.label }}
                </option>
                <option :value="WEATHER_HOURS_FORMAT.TWENTY_FOUR.value">
                  {{ WEATHER_HOURS_FORMAT.TWENTY_FOUR.label }}
                </option>
              </select>
            </div>

            <button
              type="button"
              @click="createWeatherApp"
              class="btn btn-danger bg-primary-red app-setting-submit-btn btn-lg rounded-xl"
            >
              <i class="material-icons-outlined align-middle">add</i>
              Create Widget
            </button>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import { Sketch } from 'vue-color';
  import ClickOutside from 'vue-click-outside';

  import WeatherView from '@/components/apps/weather/WeatherView.vue';
  import LocationPicker from '@/components/apps/weather/LocationPicker.vue';
  import AppInfo from '@/components/apps/AppInfo.vue';
  import ColorPicker from '@/components/ColorPicker.vue';
  import FontSelector from '@/components/common/fontSelector/FontSelector.vue';

  import { APP_CREATE } from '@/store/actions/apps';

  import {
    weatherDefaultOptions,
    WEATHER_DAY_FORMAT,
    WEATHER_DISPLAY_TYPE,
    WEATHER_HOURS_FORMAT,
    WEATHER_LANGUAGES,
    WEATHER_TEMPERATURE,
  } from '@/constant/weatherApp';

  import { apiGetRootContent } from '@/api/content';
  import { getFoldersFromNestedArray } from '@/helpers/utils';
  import { restrictToBounds } from '@/helpers/draggable';

  export default {
    name: 'WeatherAppSetting',

    directives: {
      ClickOutside,
    },

    components: {
      AppInfo,
      FontSelector,
      WeatherView,
      LocationPicker,
      ColorPicker,
      Sketch,
    },

    data() {
      return {
        WEATHER_DAY_FORMAT,
        WEATHER_DISPLAY_TYPE,
        WEATHER_HOURS_FORMAT,
        WEATHER_LANGUAGES,
        WEATHER_TEMPERATURE,
        weather: {
          ...weatherDefaultOptions,
        },
        showColorPicker: false,
        pickerColorKey: null,
        pickerCurrentColor: null,
        folders: [],
      };
    },

    async mounted() {
      await this.getFolders();
    },

    computed: {
      userFonts() {
        return this.$store.getters.userFonts;
      },

      isWeatherTypeNow() {
        return this.weather?.type === WEATHER_DISPLAY_TYPE.NOW.value;
      },

      weatherTextSize: {
        get() {
          return this.isWeatherTypeNow
            ? this.weather.simpleTextSizeSimple
            : this.weather.multipleTextSize;
        },
        set(value) {
          this.$set(
            this.weather,
            this.isWeatherTypeNow ? 'simpleTextSizeSimple' : 'multipleTextSize',
            restrictToBounds(Number(value), 8, 120),
          );
        },
      },

      weatherNumberSize: {
        get() {
          return this.isWeatherTypeNow
            ? this.weather.simpleNumberSize
            : this.weather.multipleNumberSize;
        },
        set(value) {
          this.$set(
            this.weather,
            this.isWeatherTypeNow ? 'simpleNumberSize' : 'multipleNumberSize',
            restrictToBounds(Number(value), 6, 120),
          );
        },
      },
    },

    methods: {
      async getFolders() {
        try {
          const response = await apiGetRootContent();
          this.folders = getFoldersFromNestedArray(response.data.contents).map((folder) => ({
            id: folder.id,
            name: folder.name,
          }));
        } catch (error) {
          console.log('Error getting folders:', error);
          this.folders = [];
        }
      },

      updateWeatherFont(newFont) {
        this.$set(this.weather, 'textFont', newFont);
      },

      updateLocation(data) {
        this.weather.location = data;

        if (!this.weather.name) {
          this.$set(this.weather, 'name', data.name);
        }
      },

      removeLocation() {
        this.weather.location = null;
      },

      resetApp() {
        this.weather = { ...weatherDefaultOptions };
      },

      async createWeatherApp() {
        if (!this.isWeatherFormValid()) return;

        const data = {
          item_folder: this.weather.folder?.id || null,
          name: this.weather.name,
          schedule: {
            available: 'always',
          },
          type: 'weather',
          weather: this.weather,
        };

        try {
          this.$store.dispatch(APP_CREATE, data);
          this.resetApp();

          this.$toasted.global.general_success({
            message: 'Weather app added successfully',
          });
        } catch (error) {
          console.log('createWeatherApp:', error);

          this.$toasted.global.general_error({
            message: 'Unable to add weather app',
          });
        }
      },

      isWeatherFormValid() {
        if (!this.weather.name) {
          this.$toasted.global.general_notification({
            message: 'You must add a Widget Name.',
          });

          return false;
        } else if (!this.weather.location) {
          this.$toasted.global.general_notification({
            message: 'You must select location.',
          });

          return false;
        }

        return true;
      },

      toggleColorPicker(colorKey) {
        if (this.isWidgetLockedByOverride) return;

        this.showColorPicker = !this.showColorPicker;
        this.pickerColorKey = colorKey;
      },

      updateColor(color) {
        if (!this.pickerColorKey) return;

        this.$set(this, 'pickerCurrentColor', color);
      },

      closeColorPicker() {
        if (!this.pickerColorKey || !this.showColorPicker || !this.pickerCurrentColor) return;

        this.$set(this.weather, this.pickerColorKey, this.pickerCurrentColor.hex8);
        this.showColorPicker = false;
        this.pickerColorKey = null;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .picker {
    position: absolute;
    top: 40px;
    right: 0;
    z-index: 20;
  }

  .sidebar-setting-placeholder {
    height: 100%;
    width: 100%;
    overflow-y: auto;

    .weather-preview {
      text-align: center;
      font-size: 14px;
      line-height: 20px;
    }

    .weather-settings {
      margin: 1rem 0rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      .location-picker {
        width: 100%;
      }
    }
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: $primaryRed;
    border-color: $primaryRed;
  }

  .font-color-container {
    display: flex;
    gap: 12px;
    width: 100%;

    .font-container {
      width: 50%;
    }

    .color-section {
      display: flex;
      position: relative;
      align-items: flex-end;
      gap: 10px;
      min-width: 140px;
    }
  }

  .color-button {
    background-color: $lineGrey;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: 40px;
    padding: 8px 6px 6px 6px;
    width: 40px;

    i {
      color: $tableText;
      font-size: 18px;
      height: 18px;
      width: 18px;
    }

    img {
      margin-top: -1px;
      height: 20px;
      width: 20px;
    }

    .color-bar {
      border-radius: 5px;
      height: 4px;
      width: 100%;
      background-color: black;
    }
  }

  .remove-button {
    width: 100%;
    text-align: right;

    &:hover {
      cursor: pointer;
      color: $primaryRed;
    }
  }

  .v-enter-active,
  .v-leave-active {
    transition: opacity 1s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }
</style>
