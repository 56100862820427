var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-container scrollbar"},[(_vm.licenses && _vm.licenses.length > 0)?_c('table',{staticClass:"table"},[_c('thead',[_c('tr',[(_vm.renewMode)?_c('th'):_vm._e(),_c('th',{staticClass:"name"},[_vm._v("License Name")]),_c('th',[_vm._v("Status")]),_c('th',[_vm._v("Created")]),_c('th',[_vm._v("Expiry Date")]),(_vm.isAdmin)?_c('th'):_vm._e()])]),_c('tbody',_vm._l((_vm.licenses),function(license){return _c('tr',{key:license.id,class:{
          selected: _vm.isSelected(license.id) && !_vm.renewMode,
        },on:{"click":function($event){return _vm.setSelectedLicense(license)}}},[(_vm.renewMode)?_c('td',{staticStyle:{"width":"64px"}},[(_vm.isSelected(license.id))?_c('i',{staticClass:"material-icons align-middle button text-red"},[_vm._v(" check_box ")]):_c('i',{staticClass:"material-icons-outlined align-middle button"},[_vm._v("check_box_outline_blank")])]):_vm._e(),_c('td',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm._f("alwaysShowStr")(license.name))+" ")]),_c('td',{class:{
            'text-green': license.status === 'active',
            'text-expired': license.status === 'expired',
            'text-danger': license.status === 'removed'
          },staticStyle:{"width":"20%"}},[_vm._v(" "+_vm._s(_vm.getStatusLabel(license.status))+" ")]),_c('td',{staticStyle:{"width":"20%"}},[_vm._v(" "+_vm._s(_vm.formattedDate(license.started_at, 'l'))+" ")]),_c('td',{class:_vm.expirationDateColor(_vm.expiredDate(license)),staticStyle:{"width":"20%"}},[_vm._v(" "+_vm._s(_vm.formattedDate(_vm.expiredDate(license), 'l'))+" ")]),(_vm.isAdmin)?_c('td',{staticStyle:{"width":"64px"}},[_c('div',{staticClass:"dropdown"},[_c('i',{staticClass:"material-icons-outlined align-middle more-buttons dropdown-toggle",attrs:{"data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_vm._v("more_vert")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(license.status !== 'removed'),expression:"license.status !== 'removed'"}],staticClass:"dropdown-menu dropdown-menu-right"},[(_vm.isAdmin)?_c('button',{staticClass:"dropdown-item",attrs:{"type":"button"},on:{"click":function($event){return _vm.openDeleteLicenseModal(license)}}},[_c('i',{staticClass:"material-icons-outlined align-middle"},[_vm._v("delete")]),_vm._v(" Delete ")]):_vm._e()])])]):_vm._e()])}),0)]):_c('div',{staticClass:"no-data"},[_vm._v("License does not exist, use the add button to create a new one.")]),(_vm.showDeleteLicenseModal)?_c('DeleteLicenseModal',{attrs:{"license":_vm.selectedLicense},on:{"closeModal":function($event){_vm.showDeleteLicenseModal = false},"deleted":function($event){return _vm.$emit('onDelete')}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }