<template>
  <SidebarContainer>
    <div class="saving-placeholder" :class="{ effect: isSavingLayout }">
      <img class="logo w-165" src="@/assets/img/sidebar/picflow-logo.svg" alt="PicFlow logo" />
      <div class="placeholder-text">Saving Layout</div>
    </div>

    <PlaylistItemsPanel v-if="showPlaylistItemsPanel && !isSavingLayout" />

    <div v-else class="editor-container" :class="{ effect: isSavingLayout }">
      <WidgetToolbar />

      <Tabs class="flex-grow-1" :widget="selectedWidget" />
    </div>

    <template v-if="!isSavingLayout" v-slot:buttons>
      <button
        v-if="!!selectedWidget?.assoc_id && isAdmin && selectedWidget.type != 'template'"
        class="button-2-secondary"
        @click="openPermissionsModal"
      >
        <i class="material-icons-outlined align-middle">edit_attributes</i>
        Manage Permissions
      </button>

      <PermissionsModal
        ref="permissionsModal"
        :isLoading="fetchingWidgetRoles"
        :isSaving="savingWidgetRoles"
        :roles="widgetRoles"
        :currentAccess="currentWidgetAccess"
        @onSave="saveWidgetRoles"
      />

      <template v-if="!selectedWidget && isWidgetSettingTab">
        <button type="button" class="button-2-secondary" @click="openDeleteLayoutModal">
          <i class="material-icons-outlined align-middle">delete_outline</i>
          Delete Layout
        </button>
      </template>

      <DeleteLayoutModal
        v-if="showDeleteLayoutModal"
        :layoutId="layout?.layout_id"
        @closeModal="showDeleteLayoutModal = false"
        @deleted="$router.push({ name: 'Layouts' })"
      />
    </template>
  </SidebarContainer>
</template>

<script>
  import Tabs from '@/components/layoutDesigner/editor/Tabs';
  import WidgetToolbar from '@/components/layoutDesigner/editor/WidgetToolbar.vue';
  import PlaylistItemsPanel from '@/components/layoutDesigner/editor/settingPanel/playlist/PlaylistItemsPanel.vue';
  import SidebarContainer from '@/components/common/SidebarContainer.vue';
  import DeleteLayoutModal from '@/components/layouts/DeleteLayoutModal.vue';
  import PermissionsModal from '@/components/common/PermissionsModal.vue';

  import { WIDGET_TYPES } from '@/models/layoutDesigner';
  import { apiGetLayoutWidgetRoles, apiSaveLayoutWidgetRoles } from '@/api/layouts';

  export default {
    name: 'Editor',

    components: {
      Tabs,
      WidgetToolbar,
      SidebarContainer,
      DeleteLayoutModal,
      PermissionsModal,
      PlaylistItemsPanel,
    },

    data() {
      return {
        showDeleteLayoutModal: false,
        fetchingWidgetRoles: false,
        savingWidgetRoles: false,
        widgetRoles: [],
        currentWidgetAccess: null,
      };
    },

    computed: {
      selectedWidget() {
        return this.$store.state.layoutDesigner.selectedWidget;
      },

      showPlaylistItemsPanel() {
        const { playlistOnEditMode } = this.$store.state.layoutDesigner;

        return playlistOnEditMode && this.selectedWidget?.type === WIDGET_TYPES.PLAYLIST;
      },

      layout() {
        return this.$store.state.layoutDesigner.layout;
      },

      isWidgetSettingTab() {
        return this.$store.state.layoutDesigner.isWidgetSettingTab;
      },

      isAdmin() {
        return this.$store.getters.isAdministrator || this.$store.getters.isOrganizationAdmin;
      },

      isSavingLayout() {
        return this.$store.state.layoutDesigner.isSavingLayout;
      },
    },

    methods: {
      openDeleteLayoutModal() {
        this.showDeleteLayoutModal = true;
      },

      openPermissionsModal() {
        if (!this.selectedWidget) return;

        this.getWidgetRoles();
        this.$refs.permissionsModal.open();
      },

      closePermissionsModal() {
        this.$refs.permissionsModal.close();
      },

      async getWidgetRoles() {
        try {
          this.fetchingWidgetRoles = true;
          const { type } = this.selectedWidget;
          const itemId =
            type === WIDGET_TYPES.PLAYLIST
              ? this.selectedWidget.object.playlist_id
              : this.selectedWidget.object.item_id;

          const response = await apiGetLayoutWidgetRoles(itemId, type);

          this.widgetRoles = response.data.access_roles;

          let widgetAccess = {};
          response.data.access_roles.forEach((access) => {
            widgetAccess[access.id] = access.is_active;
          });

          this.currentWidgetAccess = widgetAccess;
        } catch (error) {
          console.log('error: ', error);
        }

        this.fetchingWidgetRoles = false;
      },

      async saveWidgetRoles(selectedRoles) {
        try {
          this.savingWidgetRoles = true;

          const { type, itemId } = this.getWidgetsRoleData();

          await apiSaveLayoutWidgetRoles(itemId, type, selectedRoles);
        } catch (error) {
          console.log('error: ', error);

          this.$toasted.global.general_error({
            message: 'Error updating permissions',
          });
        }

        this.savingWidgetRoles = false;
        this.closePermissionsModal();
      },

      getWidgetsRoleData() {
        const { type } = this.selectedWidget;

        const itemId =
          type === WIDGET_TYPES.PLAYLIST
            ? this.selectedWidget.object.playlist_id
            : this.selectedWidget.object.item_id;

        return {
          type,
          itemId,
        };
      },
    },
  };
</script>

<style lang="scss" scoped>
  .sidebar-settings .editor-container {
    display: flex;
    flex-direction: column;
    gap: 32px;
    height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;

    &.effect {
      display: none;
    }
  }

  .saving-placeholder {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    animation: skeleton-content 3s infinite;

    img {
      width: 80%;
    }

    .placeholder-text {
      font-size: 24px;
    }

    &.effect {
      display: flex;
    }
  }
</style>
