<template>
  <div class="main-container" id="LicensesView">
    <div class="content">
      <div class="content-body">
        <div class="Licenses">
          <div class="return-header">
            <div class="left">
              <i class="material-icons-outlined align-middle">business_center</i>
              {{ organisation?.name }}
            </div>

            <div class="right">
              <router-link
                :to="{
                  name: 'Admin Organisation',
                  params: { organisation_id: $route.params.organisationId },
                }"
              >
                <i class="material-icons-outlined align-middle">arrow_back</i>
                <div>Back to Organisations</div>
              </router-link>
            </div>
          </div>

          <div class="header">
            <div class="header-top">
              <div class="title">Licenses</div>
              <div v-if="isAdmin" class="header-buttons">
                <div class="header-button" title="New License" @click="openCreateLicenseModal">
                  <i class="material-icons-outlined align-middle"> add </i>
                  Add
                </div>
                <div
                  class="header-button"
                  :class="{ 'text-red': renewSelected }"
                  title="Renew Licenses"
                  @click="openRenewSidebar"
                >
                  <i class="material-icons-outlined align-middle"> autorenew </i>
                  Renew
                </div>
              </div>
            </div>
            <div class="header-bottom">
              <SearchButton
                v-model="licensesSearchText"
                @blur="showLicensesSearchBox = !!licensesSearchText"
                @input="onDebouncedSearchChanged"
              />
            </div>
            <!--            <div v-if="showLicensesFilters" class="filters" style="max-width: 200px">-->
            <!--              <label for="groupsStatus" class="status">-->
            <!--                <span>Status</span>-->
            <!--                <select-->
            <!--                  ref="groupsStatus"-->
            <!--                  name="groupsStatus"-->
            <!--                  id="groupsStatus"-->
            <!--                  @change="getLicenses"-->
            <!--                  v-model="filteredStatus"-->
            <!--                >-->
            <!--                  <option value="all">All</option>-->
            <!--                  <option value="active">Active</option>-->
            <!--                  <option value="expired">Expired</option>-->
            <!--                  <option value="removed">Removed</option>-->
            <!--                </select>-->
            <!--              </label>-->
            <!--            </div>-->
          </div>
          <div class="body">
            <LicensesList
              ref="LicensesList"
              :licenses="licenses"
              :renewMode="renewSelected"
              @licenseSelected="setSelectedLicenses"
              @onDelete="getLicenses(true)"
            />
          </div>
        </div>
      </div>
    </div>

    <CreateLicenseModal
      v-if="showCreateLicenseModal"
      :organisation-id="selectedOrganisationId"
      @onCreated="getLicenses"
      @closeModal="showCreateLicenseModal = false"
    />

    <LicensesSidebar
      v-if="!renewSelected"
      :license="selectedLicense"
      @licenseUpdated="getLicenses(true)"
      @licenseDeleted="getLicenses(true)"
    />

    <RenewLicensesSidebar
      v-if="renewSelected"
      :selectedLicenses="selectedLicenses"
      @licensesUpdated="getLicenses"
      @closeSidebar="closeRenewSidebar"
      @removeLicense="removeSelectedLicense"
    />
  </div>
</template>

<script>
  import LicensesList from '@/components/admin/LicensesList.vue';
  import LicensesSidebar from '@/components/admin/LicensesSidebar.vue';
  import RenewLicensesSidebar from '@/components/admin/RenewLicensesSidebar.vue';
  import { getOrganisationLicenses, getLicenses } from '@/api/organisation';
  import * as ORGANISATION from '@/store/actions/organisation';
  import CreateLicenseModal from '@/components/admin/CreateLicenseModal.vue';
  import _ from 'lodash';
  import { ADMIN_ORGANISATIONS_GET } from '@/store/actions/organisation';
  import SearchButton from '../common/SearchButton.vue';

  export default {
    name: 'LicensesView',

    components: {
      CreateLicenseModal,
      LicensesList,
      LicensesSidebar,
      RenewLicensesSidebar,
      SearchButton,
    },

    data() {
      return {
        showCreateLicenseModal: false,
        showLicensesFilters: false,
        showLicensesSearchBox: false,
        licensesSearchText: '',
        renewSelected: false,
        selectedLicense: null,
        selectedLicenses: [],
        licenses: [],
        selectedOrganisationId: null,
        filteredStatus: 'all',
      };
    },

    computed: {
      isOrganizationAdmin() {
        return this.$store.getters.isOrganizationAdmin;
      },

      isAdmin() {
        return this.$store.getters.isAdministrator;
      },

      organisation() {
        return this.$store.state.organisation.organisation;
      },

      organisationIdParam() {
        return this.$route.params.organisationId;
      },
    },

    created() {
      this.getLicenses();
    },

    methods: {
      async getLicenses(refresh = false) {
        if (!refresh) {
          this.selectedLicense = null;
        }
        if (!this.organisation) {
          if (this.isAdmin) {
            await this.$store.dispatch(ADMIN_ORGANISATIONS_GET, this.organisationIdParam);
          } else {
            await this.$store.dispatch(ORGANISATION.ORGANISATION_REQUEST);
          }
        }
        this.selectedOrganisationId = this.organisation.organisation_id;

        try {
          const data = {};

          if (this.filteredStatus !== 'all') {
            data['status'] = this.filteredStatus;
          }
          if (this.licensesSearchText) {
            data['name'] = this.licensesSearchText;
          }
          if (this.isAdmin) {
            const res = await getOrganisationLicenses(this.selectedOrganisationId, data);
            this.licenses = res.data;
          } else {
            const res = await getLicenses(data);
            this.licenses = res.data;
          }
        } catch {
          this.$toasted.global.general_error({ message: 'Failed to load licenses' });
        }
      },

      openCreateLicenseModal() {
        this.showCreateLicenseModal = true;
      },

      openRenewSidebar() {
        this.selectedLicense = null;
        this.selectedLicenses = [];
        this.renewSelected = true;
      },

      closeRenewSidebar() {
        this.selectedLicenses = [];
        this.selectedLicense = null;
        this.renewSelected = false;
      },

      licensesFilterIconClick() {
        this.showLicensesFilters = !this.showLicensesFilters;
      },

      licensesSearchIconClick() {
        this.showLicensesSearchBox = !this.showLicensesSearchBox;
      },

      setSelectedLicenses(data) {
        if (this.renewSelected) {
          this.selectedLicenses = data;
          return;
        }

        this.selectedLicense = data;
      },
      removeSelectedLicense(license) {
        this.$refs.LicensesList.setSelectedLicense(license);
      },
      onDebouncedSearchChanged: _.debounce(function (e) {
        const value = e;

        if (!value || (value && value.length >= 3)) {
          this.getLicenses();
        }
      }, 700),
    },
  };
</script>

<style lang="scss" scoped>
  .header-buttons {
    display: flex;
    align-items: center;

    .header-button {
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 24px;
      gap: 8px;
      padding: 0 12px;
      height: 16px;
      cursor: pointer;
      color: $secondaryText;

      &:hover {
        color: $primaryText;
      }

      & + .header-button {
        border-left: 1px solid #e6e6e6;
      }
    }
  }

  .header {
    padding-top: 16px;
  }

  .Licenses {
    width: 100%;
    padding: 0 16px 40px;
    display: flex;
    flex-direction: column;

    .body {
      display: flex;
      flex-direction: column;
      overflow: hidden;

      padding-right: 2rem;
      margin-right: -2rem;
    }
  }

  .return-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
    font-weight: 500;
    align-items: center;
    border-bottom: 1px solid $lineGrey;

    .left,
    .right {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    .left {
      display: flex;
      gap: 8px;

      font-size: 20px;
      line-height: 30px;
    }

    .right {
      font-size: 16px;
      line-height: 24px;
      color: $secondaryText;

      i,
      a {
        display: flex;
        justify-items: center;
        gap: 8px;
        color: $secondaryText;
      }

      a:hover {
        text-decoration: none !important;
      }
    }
  }

  .filters {
    label {
      &.status {
        select {
          padding-left: 70px;
        }
      }
    }
  }
</style>
