import { REFRESH_LAYOUT_STATUS } from '@/config/constants';
import { restrictToBounds } from '@/helpers/draggable';
import { round } from '@/helpers/utils';
import {
  LAYOUT_DESIGNER_UPDATE_WIDGET,
  SET_REFRESHING_LAYOUT_STATE,
} from '@/store/actions/layoutDesigner';

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },

  watch: {
    value: {
      deep: true,
      async handler(widget) {
        if (
          this.refreshingState === REFRESH_LAYOUT_STATUS.SELECTING ||
          this.refreshingState === REFRESH_LAYOUT_STATUS.UPDATING
        ) {
          this.$store.commit(SET_REFRESHING_LAYOUT_STATE, REFRESH_LAYOUT_STATUS.STAND_BY);
          return;
        }

        this.$store.commit(SET_REFRESHING_LAYOUT_STATE, REFRESH_LAYOUT_STATUS.UPDATING);
        this.$store.commit(LAYOUT_DESIGNER_UPDATE_WIDGET, { widget });
      },
    },
  },

  computed: {
    rotation() {
      return this.$store.getters.getLayoutRotation;
    },

    screenDimensions() {
      return this.$store.getters.getScreenDimensions;
    },

    isWidgetLocked() {
      return !!this.value.position[this.rotation].isLocked;
    },

    refreshingState() {
      return this.$store.state.layoutDesigner.refreshingLayoutStatus;
    },

    storedWidget() {
      return this.$store.state.layoutDesigner.selectedWidget;
    },

    usePixels: {
      get() {
        return !!this.value.position[this.rotation].usePixels;
      },

      set(value) {
        this.$set(this.value.position[this.rotation], 'usePixels', value);
      },
    },

    width: {
      get() {
        const value = this.usePixels
          ? this.value.position[this.rotation].widthPixels
          : this.value.position[this.rotation].width;
        return round(value);
      },

      set(value) {
        const numberValue = Number(value);

        this.setDimensions(
          numberValue,
          this.screenDimensions.width,
          this.xPosition,
          'width',
          'widthPixels',
        );
      },
    },

    height: {
      get() {
        const value = this.usePixels
          ? this.value.position[this.rotation].heightPixels
          : this.value.position[this.rotation].height;
        return round(value);
      },

      set(value) {
        const numberValue = Number(value);

        this.setDimensions(
          numberValue,
          this.screenDimensions.height,
          this.yPosition,
          'height',
          'heightPixels',
        );
      },
    },

    xPosition: {
      get() {
        const value = this.usePixels
          ? this.value.position[this.rotation].xPixels
          : this.value.position[this.rotation].x;
        return round(value);
      },

      set(value) {
        const numberValue = Number(value);

        this.setDimensions(numberValue, this.screenDimensions.width, this.width, 'x', 'xPixels', 0);
      },
    },

    yPosition: {
      get() {
        const value = this.usePixels
          ? this.value.position[this.rotation].yPixels
          : this.value.position[this.rotation].y;
        return round(value);
      },

      set(value) {
        const numberValue = Number(value);

        this.setDimensions(
          numberValue,
          this.screenDimensions.height,
          this.height,
          'y',
          'yPixels',
          0,
        );
      },
    },
  },

  methods: {
    changeValuesMode() {
      const { xPixels, yPixels, heightPixels, widthPixels } = this.value.position[this.rotation];

      if (!this.usePixels) {
        this.updatePositionIntoPixels();
      }

      this.usePixels = !this.usePixels;
    },

    setDimensions(
      value,
      layoutDimmension,
      widgetDimmension,
      percentageKey,
      pixelsKey,
      defaultValue = 1,
    ) {
      let percentageValue;
      let pixelsValue;

      if (this.usePixels) {
        pixelsValue =
          typeof value === 'number'
            ? restrictToBounds(value, 0, layoutDimmension - widgetDimmension)
            : defaultValue;

        percentageValue = (pixelsValue * 100) / layoutDimmension;
      } else {
        percentageValue =
          typeof value === 'number'
            ? restrictToBounds(value, 0, 100 - widgetDimmension)
            : defaultValue;

        pixelsValue = (layoutDimmension * percentageValue) / 100;
      }

      this.$set(this.value.position[this.rotation], percentageKey, round(percentageValue, 3));
      this.$set(this.value.position[this.rotation], pixelsKey, pixelsValue);
      this.$forceUpdate();
    },

    updatePositionIntoPixels() {
      const { x, y, width, height } = this.value.position[this.rotation];

      const { width: canvasWidth, height: canvasHeight } = this.$store.getters.getResolution;

      const layoutWidth = this.rotation === 'horizontal' ? canvasWidth : canvasHeight;
      const layoutHeight = this.rotation === 'horizontal' ? canvasHeight : canvasWidth;

      const positionUpdated = {
        ...this.value.position[this.rotation],
        xPixels: (layoutWidth * x) / 100,
        yPixels: (layoutHeight * y) / 100,
        heightPixels: (layoutHeight * height) / 100,
        widthPixels: (layoutWidth * width) / 100,
      };

      this.$set(this.value.position, this.rotation, positionUpdated);
    },
  },
};
