module.exports = {
  organizations : 'ORGANISATIONER',
  logged_in_info_message : 'Du är inloggad som global administratör!',
  create_organization : 'Skapa organisation',
  synchronize_s3 : 'Synkronisera S3',
  all_organizations : 'Alla organisationer',
  manage_organization_info: 'Klicka på en organisation för att administrera den',
  edit_organization: 'REDIGERA ORGANISATION',
  update_organization: 'Uppdatera organisation',
  edit_organization_info: 'Gråa fält får inte uppdateras av slutanvändare',
  edit_aws_link_text: 'AWS uppgifter for lagring av media',
  create_user:'Skapa användare',
  create_user_popup_email_label: 'Namn (e-postadress)',
  create_user_popup_email_placeholder: 'Anvandarens namn',
  create_user_popup_password_label: 'Lösenord',
  create_user_popup_password_placeholder: 'Anvandarens lösenord',
  allow_login: 'Får logga in?',
  local_admin: 'Lokal administratör',
  name_of_the_organization: 'Namn på organisation',
  organization_user_warning: 'Anvandare i inaktiva organisationer far inte logga in',
  organization_delete_warning: 'Du är på väg att radera en hel organisation!',
  organization_delete_warning_list_1: 'Spellistor och alla deras objekt-referenser',
  organization_delete_warning_list_2: 'Användarkonton',
  organization_delete_warning_list_3: 'Moln-uppgifter för åtkomst till uppladdad media',
  organization_delete_warning_list_4: 'Uppladdad media sparas i molnet upp till 3 månader efter radering',
  organization_delete_warning_list_5: 'Av säkerhetsskäl går det inte att radera organisationen du är inloggad med',
  organization_delete_warning_list_6: 'Bekräfta radering genom att klicka på Radera-knappen.',

  s3_bucket_synced: 'S3 Buckets synkade',
  organization_updated: 'Organisationen uppdaterades',
  organization_removed: 'Organisation raderad',
  organization_created: 'Organisation skapadeas',
  user_updated: 'Användare uppdaterades',
  user_created: 'Användare skapadeas',
  user_removed: 'Användare raderades',

  create_cloud_data: 'Skapa moln-uppgifter',
  no_cloud_data: 'Inga moln-uppgifter hittades, tryck på knappen ovan för att skapa',
};