<template>
  <div class="main-container" id="UsersView">
    <div class="content">
      <div class="content-body">
        <div class="users">
          <div class="return-header">
            <div class="left">
              <i class="material-icons-outlined align-middle">business_center</i>
              {{ organisation?.name }}
            </div>

            <div class="right">
              <router-link
                :to="{
                  name: 'Admin Organisation',
                  params: { organisation_id: organisationIdParam },
                }"
              >
                <i class="material-icons-outlined align-middle">arrow_back</i>
                <div>Back to Organisations</div>
              </router-link>
            </div>
          </div>

          <div class="header">
            <div class="header-top">
              <div class="title">Users</div>
              <i
                class="material-icons-outlined align-middle button"
                title="Add User"
                @click="openCreateUsersModal"
              >
                add
              </i>
            </div>
            <div class="header-bottom">
              <!-- <i
                class="material-icons-outlined align-middle button"
                :class="{ active: showUsersFilters }"
                title="Filter Users"
                @click="usersFilterIconClick"
              >
                filter_alt
              </i> -->

              <SearchButton v-model="usersSearchText" />
            </div>
            <div v-if="showUsersFilters" class="filters">
              <!-- TODO: Example (No filters yet) -->
              <!-- <label for="groupsStatus" class="status">
                <span>Playlists</span>
                <select
                  ref="groupsStatus"
                  name="groupsStatus"
                  id="groupsStatus"
                  @change="manageFilters('groupsStatus')"
                  v-model="filters.groupsStatus"
                >
                  <option value="all">All</option>
                  <option value="online">Online</option>
                  <option value="offline">Offline</option>
                  <option value="unknown">Unknown</option>
                </select>
              </label> -->
            </div>
          </div>
          <div class="body">
            <UsersList
              v-if="organisationIdParam === organisation?.organisation_id"
              @userSelected="selectUser"
              :search-text="usersSearchText"
              ref="userList"
            />
          </div>
        </div>
      </div>
    </div>

    <UserModal
      @closeModal="showCreateUserModal = false"
      @getUsers="getUsers"
      v-if="showCreateUserModal"
      :title="$lang.organizations.userModal.addTitle"
    />

    <UsersSidebar :user="selectedUser" @userUpdated="getUsers" @userDeleted="getUsers" />
  </div>
</template>

<script>
  import { GET_ORGANISATION_ROLES } from '@/store/actions/roles';
  import {
    ADMIN_ORGANISATIONS_GET,
    ORGANISATION_REQUEST,
    SET_ORGANISATIONS,
  } from '@/store/actions/organisation';

  import UsersList from '@/components/admin/UsersList.vue';
  import UserModal from '@/components/admin/sidebar/UserModal.vue';
  import UsersSidebar from '@/components/admin//UsersSidebar.vue';
  import SearchButton from '@/components/common/SearchButton.vue';

  export default {
    name: 'UsersView',

    components: { UsersList, UserModal, UsersSidebar, SearchButton },

    data() {
      return {
        showCreateUserModal: false,
        showUsersFilters: false,
        showUsersSearchBox: false,
        usersSearchText: '',
        selectedUser: null,
      };
    },

    computed: {
      isAdmin() {
        return this.$store.getters.isAdministrator;
      },

      organisation() {
        return this.$store.state.organisation.organisation;
      },

      organisationIdParam() {
        return this.$route.params.organisationId;
      },

      isOrganisationLoaded() {
        const organisationsLoaded = this.$store.state.organisation.organisations.length !== 0;
        return (
          organisationsLoaded &&
          this.organisationIdParam &&
          this.organisation &&
          this.organisation.organisation_id === this.organisationIdParam
        );
      },
    },

    async mounted() {
      if (!this.isOrganisationLoaded) {
        await this.getOrganizations();
      }
      await this.getOrganisationRoles();
    },

    methods: {
      async getOrganizations(refreshData = false) {
        if (this.isAdmin) {
          await this.$store.dispatch(ADMIN_ORGANISATIONS_GET, this.organisationIdParam);
          return;
        }

        if (!this.organisation || refreshData) {
          await this.$store.dispatch(ORGANISATION_REQUEST);
          this.$store.commit(SET_ORGANISATIONS, [this.organisation]);
        }
      },

      async getUsers() {
        await this.$refs.userList.getUsers();
      },

      openCreateUsersModal() {
        this.showCreateUserModal = true;
      },

      usersFilterIconClick() {
        this.showUsersFilters = !this.showUsersFilters;
      },

      usersSearchIconClick() {
        this.showUsersSearchBox = !this.showUsersSearchBox;
      },

      selectUser(user) {
        this.selectedUser = user;
      },

      async getOrganisationRoles() {
        const fetchedSuccessfully = await this.$store.dispatch(GET_ORGANISATION_ROLES);

        if (!fetchedSuccessfully) {
          this.$toasted.global.general_error({
            message: "Ups, we weren't able to get data. Please refresh the page.",
          });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .users {
    width: 100%;
    padding: 0 16px 40px;
    display: flex;
    flex-direction: column;

    .header {
      padding-top: 16px;
    }

    .body {
      display: flex;
      flex-direction: column;
      overflow: hidden;

      padding-right: 2rem;
      margin-right: -2rem;
    }
  }

  .return-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
    font-weight: 500;
    align-items: center;
    border-bottom: 1px solid $lineGrey;

    .left,
    .right {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    .left {
      display: flex;
      gap: 8px;

      font-size: 20px;
      line-height: 30px;
    }

    .right {
      font-size: 16px;
      line-height: 24px;
      color: $secondaryText;

      i,
      a {
        display: flex;
        justify-items: center;
        gap: 8px;
        color: $secondaryText;
      }

      a:hover {
        text-decoration: none !important;
      }
    }
  }
</style>
