<template>
  <div>
    <div
      class="modal fade overflow-auto"
      :class="open ? 'show d-block' : ''"
      tabindex="-1"
      role="dialog"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content shadow-lg">
          <div class="px-3">
            <div class="modal-header">
              <h5 class="modal-title">Create {{ appName }} Widget</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click.prevent="closeModal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
          <div class="modal-body">
            <div class="scheduler-container">
              <div class="description">Pick a name for your new widget.</div>

              <label for="widget-name" class="w-100">
                Give your widget a name
                <input
                  type="text"
                  id="widget-name"
                  placeholder=""
                  v-model="name"
                  class="form-control"
                />
              </label>
              <!-- <label for="mediaFolders" class="w-100">
                In what folder do you want to save the widget?
                <select id="mediaFolders" v-model="folderSelected" class="form-control">
                  <option :value="{ name: 'Main Folder', path: '/' }">Main Folder</option>
                  <option v-for="(folder, index) in folders" :key="index" :value="folder">
                    {{ folder.name }}
                  </option>
                </select>
              </label> -->
            </div>
          </div>
          <div class="modal-footer justify-content-between align-items-center">
            <p class="m-0">Step: {{ currentStep }}/{{ totalSteps }}</p>
            <div class="d-flex">
              <span
                class="btn btn-sm bg-white border cursor-pointer mr-2 px-4 rounded-xl"
                @click="closeModal"
                >Close</span
              >
              <span
                class="btn btn-sm bg-success border cursor-pointer text-light px-4 rounded-xl"
                @click="save"
                >Save</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show" v-if="open"></div>
  </div>
</template>

<script>
  import Scheduler from '@/components/common/scheduler/Scheduler.vue';
  import { CONTENT_REQUEST } from '@/store/actions/content';

  export default {
    name: 'AppWidgetModal',
    components: {
      Scheduler,
    },

    props: {
      open: {
        type: Boolean,
        default: false,
      },
      appName: {
        type: String,
        required: true,
      },
      defaultData: {
        type: Object,
        default: {
          name: '',
          folder: null,
          schedule: {
            available: 'always',
          },
        },
      },
    },

    data() {
      return {
        currentStep: 2,
        totalSteps: 2,
        name: '',
        folderSelected: {
          name: 'Main Folder',
          path: '/',
        },
        schedulerData: {
          available: 'always',
        },
      };
    },

    created() {
      if (this.defaultData) {
        this.name = this.defaultData?.name;
        this.folderSelected = this.defaultData?.folder
          ? this.defaultData?.folder
          : this.folderSelected;
        this.schedulerData = this.defaultData?.schedule
          ? this.defaultData.schedule
          : this.schedulerData;
      }

      if (this.$store.getters.getFolders.length === 0) {
        this.getFolders();
      }
    },

    watch: {
      defaultData(val) {
        this.name = val?.name;
        this.folderSelected = val?.folder ? val.folder : this.folderSelected;
        this.schedulerData = val?.schedule ? val.schedule : this.schedulerData;
      },
    },

    computed: {
      folders() {
        return this.$store.getters.getFolders;
      },
    },

    methods: {
      async getFolders() {
        await this.$store.dispatch(CONTENT_REQUEST);
      },
      save() {
        if (!this.name) {
          this.$toasted.global.general_error({ message: 'Name is required.' });
          return;
        }
        if (!this.folderSelected) {
          this.$toasted.global.general_error({ message: 'Folder is required' });
          return;
        }
        const data = {
          schedule: this.schedulerData,
          folder: this.folderSelected,
          name: this.name,
        };
        this.$emit('onSave', data);
      },
      closeModal() {
        this.$emit('onClose');
        this.name = '';
        this.folderSelected = null;
        this.schedulerData = {
          available: 'always',
        };
      },
    },
  };
</script>

<style lang="scss" scoped>
  .scheduler-container {
    display: flex;
    flex-direction: column;
    padding: 14px;
    gap: 16px;

    .description {
      font-size: 16px;
      margin-bottom: 8px;
    }

    select option {
      zoom: 1.1;
    }
  }

  input,
  select {
    color: black;
  }
</style>
