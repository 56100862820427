<template>
  <div id="debug-page">
    <p>Hejsan</p>
  </div>
</template>

<script>
export default {
  name: 'Debug',

  mounted() {
    setTimeout(() => {
      window.$('#debug-page').addClass('rotate-90-left')
    }, 2000)
  },

}
</script>

<style lang="scss" scoped>
#debug-page {
  position: fixed; 
  top: 0; 
  left: 0; 
	
  /* Preserve aspet ratio */
  min-width: 100%;
  min-height: 100%;
}

.rotate-90-left {
  transform: rotate(-90deg);
  transform-origin:middle center;

  background: url(http://s3.eu-central-1.amazonaws.com/cleversign-media-dev/1fe765b897b043d583788af8a6166037/tentacles.png) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
</style>
