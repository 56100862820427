import { Content, EditorOptions } from '@tiptap/vue-2';
import {
  createEditor,
  setLetterSpacing,
  setLineHeight,
  setTextSize,
  setNodeTextSize,
  setNodeLineHeight,
} from '../tiptap';
import { RICH_TEXT } from '@/constant/richText';
interface NodeInformation {
  startPos: number;
  endPos: number;
  nodeType: string;
  headingLevel: number;
  size: number;
}

// unit as em
const CONTENT_STYLE_FONT_SIZE = {
  h1: 2,
  h2: 1.5,
  h3: 1.17,
  h4: 1,
  h5: 0.83,
  h6: 0.67,
  p: 1,
} as const;

// unit as em
const CONTENT_STYLE_LINE_HEIGHT = 1.2;

function getNodeFontSize(nodeType: string, headingLevel: number, baseTextSize: number) {
  let textSize = baseTextSize * CONTENT_STYLE_FONT_SIZE.p;

  if (nodeType === 'heading') {
    switch (headingLevel) {
      case 1:
        textSize = baseTextSize * CONTENT_STYLE_FONT_SIZE.h1;
        break;
      case 2:
        textSize = baseTextSize * CONTENT_STYLE_FONT_SIZE.h2;
        break;
      case 3:
        textSize = baseTextSize * CONTENT_STYLE_FONT_SIZE.h3;
        break;
      case 4:
        textSize = baseTextSize * CONTENT_STYLE_FONT_SIZE.h4;
        break;
      case 5:
        textSize = baseTextSize * CONTENT_STYLE_FONT_SIZE.h5;
        break;
      case 6:
        textSize = baseTextSize * CONTENT_STYLE_FONT_SIZE.h6;
        break;
      default:
        break;
    }
  }

  return textSize;
}

export function preProcessRichTextContentWithBaseTextSize(
  content: Content,
  baseTextSize: number,
): string | null {
  if (!content || baseTextSize === -1) return null;

  const editor = createEditor({ content: content } as EditorOptions);

  // get relevant node information from the prosemirror document
  const nodeInformations: NodeInformation[] = [];
  editor.state.doc.content.forEach((node, offset, index) => {
    const startPos = offset;
    let endPos = offset + 1;

    if (node?.content?.size) {
      endPos = offset + node.content.size + 1;
    }

    nodeInformations.push({
      startPos: startPos,
      endPos: endPos,
      nodeType: node.type.name,
      headingLevel: node.type.name === 'heading' ? (node.attrs.level as number) : -1,
      size: node?.content?.size ?? 0,
    });
  });

  // update all text by selecting every node and adding relevant attributes
  for (const i of nodeInformations) {
    const textFontSize = getNodeFontSize(i.nodeType, i.headingLevel, baseTextSize);

    // has text content
    if (i.size !== 0) {
      editor.commands.setTextSelection({ from: i.startPos, to: i.endPos });
      setTextSize(editor, textFontSize);

      editor.commands.setTextSelection({ from: i.startPos, to: i.endPos });
      setLineHeight(editor, CONTENT_STYLE_LINE_HEIGHT);

      editor.commands.setTextSelection({ from: i.startPos, to: i.endPos });
      setLetterSpacing(editor, RICH_TEXT.DefaultLetterSpacing / 100);
    } else if (i.size === 0 && (i.nodeType === 'paragraph' || i.nodeType === 'heading')) {
      editor.commands.setTextSelection({ from: i.startPos, to: i.endPos });
      setNodeTextSize(editor, i.nodeType, textFontSize);

      editor.commands.setTextSelection({ from: i.startPos, to: i.endPos });
      setNodeLineHeight(editor, i.nodeType, CONTENT_STYLE_LINE_HEIGHT);
    }
  }

  return editor.getHTML();
}
