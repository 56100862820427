export const SCREENS_OVERRIDE_ACTIONS = {
  ADD: 'add',
  EDIT: 'edit',
  REMOVE: 'remove',
};

export const SCREEN_CODE_USE_MESSAGE =
  'Please note that you can either generate the code here and use it in the screen or use the code generated in the screen here. Both the codes have a validity of 24 hours.';
export const SCREEN_CODE_REGENERATION_WARNING_MESSAGE =
  'Please note that once you generate a new code, device connected to this screen will be logged out.';

export const GENERATE_CODE_LABEL = 'Generate';
export const CONNECT_SCREEN_LABEL = 'Connect';

export const SCREEN_GROUP_TYPE = {
  UNGROUPED: 0,
};
