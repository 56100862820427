<template>
  <div class="screen-card" :class="{ selected: selected }" @click="$emit('selectScreen', screen)">
    <div class="screen-section">
      <div class="left">
        <ConnectionLights
          :unknown="connStatus === 'unknown' ? 1 : 0"
          :connected="connStatus === 'connected' ? 1 : 0"
          :disconnected="connStatus === 'disconnected' ? 1 : 0"
          :size="12"
          hideNumbers
        />
        <div class="title">{{ screen.name }}</div>
      </div>

      <div class="right">
        <div class="dropdown">
          <i
            class="material-icons-outlined align-middle more-buttons dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            @click.prevent
            >more_vert</i
          >
          <div class="dropdown-menu dropdown-menu-right">
            <button type="button" class="dropdown-item" @click.prevent="openDeleteModal">
              <i class="material-icons-outlined align-middle">delete</i>
              Delete
            </button>
            <button
              type="button"
              v-if="isAdmin"
              class="dropdown-item"
              @click.prevent="openRegenerateCodeModal"
            >
              <i class="material-icons-outlined align-middle">refresh</i>
              Reconnect Screen
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="screen-section" v-if="screenGroupName">
      <div class="left">
        <i class="material-icons-outlined align-middle">subdirectory_arrow_left</i>
        <div class="sub-title">{{ groupPath }}</div>
      </div>
    </div>

    <div class="screen-section">
      <div class="right">
        <ScreenRotationIcons
          :horizontalScreens="isHorizontalScreen ? 1 : 0"
          :verticalScreens="isHorizontalScreen ? 0 : 1"
          hideNumbers
        />
      </div>
    </div>

    <DeleteScreenModal
      @closeModal="closeScreenModal"
      :screenId="screen?.id"
      v-if="showDeleteScreenModal"
    />

    <RegenerateScreenCodeModal
      @close-regenerate-screen-code-modal="closeRegenerateCodeModal"
      v-if="showRegenerateScreenCodeModal"
      :screenId="screen?.id"
    />
  </div>
</template>

<script>
  import DeleteScreenModal from '@/components/screens/DeleteScreenModal.vue';
  import RegenerateScreenCodeModal from '@/components/screens/RegenerateScreenCodeModal.vue';
  import ConnectionLights from './ConnectionLights.vue';
  import ScreenRotationIcons from './ScreenRotationIcons.vue';

  export default {
    name: 'ScreenCard',
    inject: ['getGroups', 'openDeleteScreenModal'],
    components: {
      ScreenRotationIcons,
      ConnectionLights,
      DeleteScreenModal,
      RegenerateScreenCodeModal,
    },

    props: {
      selected: {
        type: Boolean,
        default: false,
      },

      screen: {
        type: Object,
        default: () => null,
      },
      screenGroupName: {
        type: String,
        default: 'Ungrouped',
      },
    },

    data() {
      return {
        showDeleteScreenModal: false,
        showRegenerateScreenCodeModal: false,
      };
    },

    computed: {
      screenInfo() {
        return this.screen?.screen_information;
      },
      connStatus() {
        let status = this.$store.getters.getScreenStatus(this.screen.id);

        switch (status) {
          case 0:
            return 'disconnected';
          case 1:
            return 'unknown';
          case 2:
            return 'connected';
          default:
            return 'unknown';
        }
      },

      groups() {
        return this.getGroups();
      },

      group() {
        return this.groups.find((group) => group.id === this.screen?.screen_group);
      },

      groupPath() {
        let group = this.group;
        let pathList = [];
        if (!group) return 'Ungrouped';

        while (group.parent_id) {
          pathList.push(group.name);
          group = this.groups.find((g) => g.id === group.parent_id);
        }

        pathList.push(group.name);

        return pathList.reverse().join(' / ');
      },

      isHorizontalScreen() {
        return (
          !!this.screenInfo?.orientation &&
          ['180d', 'landscape'].includes(this.screenInfo?.orientation)
        );
      },

      isAdmin() {
        return this.$store.getters.isOrganizationAdmin || this.$store.getters.isAdministrator;
      },
    },

    methods: {
      openDeleteModal() {
        this.openDeleteScreenModal(this.screen);
      },

      closeScreenModal() {
        this.showDeleteScreenModal = false;
      },

      openRegenerateCodeModal() {
        this.showRegenerateScreenCodeModal = true;
      },

      closeRegenerateCodeModal() {
        this.showRegenerateScreenCodeModal = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .screen-card {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 16px;

    border: 1px solid white;
    font-size: 14px;
    line-height: 21px;

    &.selected {
      border-color: $primaryRed;
    }

    .screen-section {
      display: flex;
      justify-content: space-between;

      .left {
        display: flex;
        align-items: center;
        color: $secondaryText;
        gap: 8px;
        font-size: 14px;

        i {
          font-size: 14px;
        }
      }
      .right {
        margin-left: auto;
      }
    }

    .sub-title {
      font-size: 14px;
      line-height: 21px;
      color: $secondaryText;
    }

    .title {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #151515;
    }
  }

  .dropdown-toggle {
    &:hover {
      cursor: pointer;
    }

    &::after {
      display: none !important;
    }
  }

  .dropdown-menu {
    border-radius: 0;
    padding: 0;
    font-family: 'Poppins';

    .dropdown-item {
      display: flex;
      align-items: center;
      padding: 8px 16px;
      color: $secondaryText;
      gap: 8px;

      &:hover {
        background-color: white;
        color: $primaryRed;
      }
    }
  }
</style>
