<template>
  <div class="card-grid">
    <div class="card-decks">
      <div class="card-column" v-for="(item, cardIndex) in items" :key="cardIndex">
        <div
          v-if="item && item.content_type !== CONTENT_TYPE_FOLDER"
          :class="{
            active: isActiveItem(cardIndex) || isActiveTemplate(cardIndex, item),
            disabled: item.hasOwnProperty('selectable') && !item.selectable,
          }"
          class="card content-item"
          :style="style"
          @click.prevent.stop="onItemClicked($event, cardIndex)"
        >
          <div class="hovereffect">
            <div
              v-if="['image', 'rss', 'table', 'trafiklab', 'weather'].includes(getType(item))"
              class="thumbnail"
              :style="getThumbnailStyle(item)"
              :class="{
                'trafiklab-bg': item.item_type === 'app/trafiklab',
              }"
            ></div>

            <div
              v-else-if="getType(item) === 'pdf'"
              class="thumbnail"
              :style="getThumbnailStyle(item)"
            >
              <div class="card-img-overlay d-flex align-items-center justify-content-center">
                <i class="material-icons play-icon">picture_as_pdf</i>
              </div>
            </div>

            <div
              v-if="getType(item) === 'video'"
              class="thumbnail item-preview-video"
              :style="getVideoStyle(item)"
            >
              <div class="card-img-overlay d-flex align-items-center justify-content-center">
                <i class="material-icons play-icon">play_circle_outline</i>
              </div>
            </div>

            <div v-if="isTemplate(item)" class="template-thumbnail">
              <img :src="item.img" :alt="item.name" />
            </div>
          </div>

          <div class="card-body file">
            <div class="card-title">{{ item.name }}</div>
            <div class="item-description">
              {{ getDescription(item) }}
            </div>
          </div>
        </div>
        <div
          v-if="item && item.content_type === CONTENT_TYPE_FOLDER"
          :class="getItemClass(cardIndex)"
          :style="style"
          @click.prevent="onItemClicked($event, cardIndex)"
          class="card"
        >
          <img src="../../assets/img/content/folder.svg" :alt="`folder ${item.name}`" />
          <div class="card-body folder">
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { THUMBNAIL_WIDTH } from '@/config/constants';
  import {
    CONTENT_TYPE_FOLDER,
    CONTENT_TYPE_TEMPLATE,
    CONTENT_TYPE_TABLE_APP,
    CONTENT_TYPE_TENANT,
  } from '@/constant/const';
  import { simpleTypeMixin } from '@/helpers';

  const THUMBNAIL_HEIGHT = 76;

  export default {
    name: 'CardGrid',

    mixins: [simpleTypeMixin],

    props: {
      items: {
        type: Array,
        required: true,
      },

      itemsPerRow: {
        type: Number,
        default: 3,
        required: false,
      },

      value: {
        type: Number,
        required: false,
        default: -1,
      },

      itemDesc: {
        required: false,
        type: String,
        default: 'App',
      },

      activeItems: {
        type: Array,
        required: false,
        default: () => [],
      },

      multiSelectMode: {
        type: Boolean,
        required: false,
        default: true,
      },
    },

    data() {
      return {
        THUMBNAIL_HEIGHT,
        THUMBNAIL_WIDTH,
        CONTENT_TYPE_FOLDER,
      };
    },

    watch: {
      items() {
        const videos = document.querySelectorAll('video');
        videos.forEach((video) => {
          video.load();
        });
      },
    },

    computed: {
      style() {
        return {
          minWidth: `calc(90% / ${this.itemsPerRow})`,
        };
      },

      rows() {
        return Math.ceil(this.items.length / this.itemsPerRow);
      },
    },

    methods: {
      getType(item) {
        return this.getSimpleType(item.item_type);
      },

      getThumbnailStyle(item) {
        return `background-image:url('${item.img}');` + `height:${this.THUMBNAIL_HEIGHT}px;`;
      },

      getVideoStyle(item) {
        return `background-image:url('${item.img}');
        height:${this.THUMBNAIL_HEIGHT}px;object-fit: cover;`;
      },

      onItemClicked(event, index) {
        this.$emit('select', { event, index });
      },

      isActiveItem(index) {
        return this.multiSelectMode && this.activeItems.indexOf(index) > -1;
      },
      isActiveTemplate(index, item) {
        return item.content_type === CONTENT_TYPE_TEMPLATE && this.activeItems.indexOf(index) > -1;
      },
      getItemClass(index) {
        let itemClass = '';

        if (this.activeItems.indexOf(index) !== -1) {
          itemClass += 'active ';
        }

        return itemClass;
      },

      getDescription(item) {
        if (item.item_type === CONTENT_TYPE_TEMPLATE) {
          return `${item.settings.width} x ${item.settings.height}`;
        } else if (item.item_type === CONTENT_TYPE_TABLE_APP) {
          return CONTENT_TYPE_TENANT;
        }
        return this.getSimpleType(item.item_type) || this.itemDesc;
      },
      isTemplate(item) {
        return item.item_type === CONTENT_TYPE_TEMPLATE;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .card-grid {
    .card-decks {
      margin-bottom: 1rem;
      gap: 16px 24px;
      display: flex;
      flex-wrap: wrap;
    }

    .card {
      transition: border 0.3s ease-in;
      border: 2px solid transparent;
      display: flex;
      align-items: center;
      position: relative;
      width: 112px;
      height: 140px;

      img {
        margin: 14px 0;
        height: 48px;
        width: 56px;
      }

      .hovereffect {
        height: auto;
      }

      .card-body {
        padding: 4px 0 0;
        text-overflow: ellipsis;
        font-size: 13px;
        line-height: 20px;
        font-weight: bold;
        text-align: center;
        max-width: 112px;

        .card-title {
          margin-bottom: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          max-height: 40px;
          -webkit-box-orient: vertical;
          display: flex;
          -webkit-line-clamp: 2;
          display: -webkit-box;
          word-break: break-all;
        }

        &:hover {
          cursor: pointer;
        }
      }

      &.active {
        border-color: $primaryRed;

        .file {
          background-color: $highlightGrey;
        }
      }

      .thumbnail {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }
      .template-thumbnail {
        img {
          aspect-ratio: 16 / 9;
          width: 100%;
          object-fit: contain;
        }
      }

      .trafiklab-bg {
        background-size: contain;
        background-color: black;
      }

      .item-preview-video {
        background: #2e3c43;
        background-size: cover;
        .card-img-overlay {
          background-color: rgba(0, 0, 0, 0.3);
          .play-icon {
            font-size: 5rem;
            color: rgba(255, 255, 255, 0.8);
          }
        }
      }

      .file {
        width: 100%;
        padding: 4px;

        .card-title {
          text-align: left;
        }

        .item-description {
          text-transform: capitalize;
          font-size: 12px;
          line-height: 18px;
          text-align: left;
          color: $secondaryText;
          font-weight: normal;
        }
      }
    }
    .disabled {
      opacity: 0.5;
      .hovereffect,
      .card-body {
        cursor: not-allowed !important;
      }
    }

    .category {
      background: url('../../assets/img/content/folder.svg') no-repeat;
      background-size: cover;
      border-radius: 0.5rem;
      border: none;

      .category-name {
        padding-left: 1.25rem;
        padding-top: 3rem;
      }

      .card-body {
        border-radius: 1rem;
      }
    }
  }
</style>
