<template>
  <div class="matchi-welcome">
    <div class="matchi-welcome-main card-shadow">
      <div class="matchi-welcome-main-header"></div>
      <div class="matchi-welcome-main-title">
        {{welcomeMessage()}} <span>{{playlistMatchiFacility.name}}</span>
      </div>
      <div class="matchi-welcome-main-description">
        {{playlistMatchiFacility.description}}
        <div class="matchi-welcome-main-description-footer"></div>
      </div>
      <div class="matchi-welcome-main-numbers">
        <div class="matchi-welcome-main-item">
          <div>{{playlistMatchiFacility.freeCurrentSlots}}</div>
          <div>{{
            playlistMatchiFacility.freeCurrentSlots === 1 ?
            this.$lang.player.vacant_time :
            this.$lang.player.vacant_times
          }}</div>
        </div>
        <div class="matchi-welcome-main-item">
          <div>{{playlistMatchiFacility.totalCurrentSlots}}</div>
          <div>{{this.$lang.player.tracks}}</div>
        </div>
      </div>
    </div>
    <MatchiSidebar
     :openingHours="playlistMatchiFacility.openingHours"
     :timezone="playlistMatchiFacility.timezone" />
  </div>
</template>

<script>
import MatchiSidebar from "./MatchiSidebar";

export default {
  name: "MatchiWelcome",
  props: ["playlistMatchiFacility", "playlistMatchiSlots"],
  components:{
    MatchiSidebar,
  },
  methods:{
    welcomeMessage(){
      return `${this.$lang.player.welcome} ${this.$lang.player.to}`
    }
  }
};
</script>
