module.exports = {
  delete_playlist: 'Radera spellista',
  deleting_playlist: 'Raderar spellista',
  delete_warning: 'Du kommer att radera spellistan!',
  delete_confirmation: 'Bekräfta raderingen genom att klicka på Radera nedan',
  media_library: 'Mediabibliotek',
  embed_media: 'Inbäddad media från en annan site, som t.ex. YouTube',
  example_link: 'Exempel på länk',
  embed_youtube_instructions:
    'Läs mer om parametrar att använda för inbäddade youtube-videos (som autoplay och controls t.ex.)',
  edit_rights: 'REDIGERA RÄTTIGHETER FÖR',
  edit_rights_info: 'Tryck på status-symbolen för att ändra åtkomst-rättigheter',
  playlist_name: 'Spellistans namn',
  playlist_description: 'Beskrivning av spellistan',
  playlist_address: 'Adress till spelaren',
  duration_info:
    'Ställ in hur länge eller när ett objekt ska visas i spelaren. Scheman används i samma prioritet som de visas här, uppifrån och ner. Första schema som är giltigt tar prioritet',
  schedule_warning: 'Inga scheman ännu',
  create_playlist: 'Skapa spellista',
  edit_playlist: 'Redigera spellista',
  playlist: 'Spellista',
  playlists: 'Spellistor',

  playlist_created: 'Spellistan skapadeas',
  playlist_updated: 'Spellistan uppdaterades',
  playlist_removed: 'Spellistan raderades',
  playlist_category_created: 'Kategorin skapades',
  playlist_category_removed: 'Kategorin raderades',
  item_added: 'Objekt lades til',
  item_removed: 'Objekt raderades',
  item_updated: 'Objekt uppdaterades',

  schedule_updated: 'Scheman uppdaterade',
  schedule_deleted: 'Schema raderat',
  schedule_start_date_error: 'Behöver startdatum och starttid',
  schedule_end_date_error: 'Behöver slutdatum och sluttid',
  schedule_date_diff_error: 'Sluttiden måste vara större än starttiden',

  media_updated: 'Media uppdaterade',
  media_update_failed: 'Media uppdatering misslyckades',

  replace_with_a_url: 'Ersätt med en URL',
  replace_with_a_media: 'Byt ut med media',

  reload_signal_dispatched: 'Ladda om signal',

  permissions_loading: 'Hämtar rättigheter',

  mon: 'Mån',
  tue: 'Tis',
  wed: 'Ons',
  thu: 'Tors',
  fri: 'Fre',
  sat: 'Lör',
  sun: 'Sön',

  screen_detach_success: 'Screen detached successfully',
  screen_detach_error: 'Screen is already detached or an error occurred',
};
