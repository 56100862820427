<template>
  <vue-draggable-resizable
    v-if="!hideParent"
    :parent="true"
    :x="position.left"
    :y="position.top"
    :w="position.width"
    :h="position.height"
    :draggable="!isSavingTemplate && !isWidgetLocked && !isWidgetLockedByOverride"
    :resizable="!isSavingTemplate && !isWidgetLocked && !isWidgetLockedByOverride"
    :grid="gridScale"
    :snap="isSnapActive"
    :active="isWidgetSelected"
    :z="(isWidgetSelected && keepZIndexUp ? 1000 : 100) + widget.position.zIndex"
    :onDragStart="(x, y) => onChangePosition(true, x, y)"
    :onResizeStart="() => onChangePosition(false)"
    @dragstop="onWidgetDrag"
    @resizestop="onWidgetResize"
    @activated="() => onSelected(widget)"
    @deactivated="() => (keepZIndexUp = false)"
    @refLineParams="getRefLineParams"
  >
    <div v-if="showPlaceholder" class="app-placeholder">
      <i class="material-icons-outlined align-middle">apps</i>
    </div>
    <div v-else class="widget-item app-widget" :id="widget.object.wid" :style="style">
      <RssViewer
        v-if="widget.object.item_type === `app/${APP_TYPES.RSS}`"
        :setting="getRSSAppSettings(widget.object)"
        :style="{ zoom: scaleTemplateValue(1) }"
      ></RssViewer>

      <TableAppWebView
        v-if="widget.object.item_type === `app/${APP_TYPES.TABLE}`"
        :settings="widget.object"
        :style="{ zoom: scaleTemplateValue(1) }"
        :height="widget.position.heightPixels"
      ></TableAppWebView>

      <TimeTableViewer
        v-if="widget.object.item_type === `app/${APP_TYPES.TRAFIKLAB}`"
        :viewerStyles="widget.object.viewerStyles"
        :timetable="widget.object.timetable"
        :style="{ zoom: scaleTemplateValue(1) }"
        :appId="widget.object.item_id"
        :name="widget.object.name"
        alt="Trafiklab app"
        @error="showPlaceholder = true"
      />

      <WeatherView
        class="weather-app"
        :style="{ zoom: scaleTemplateValue(1) }"
        v-else-if="isWeatherApp && !isOldWeatherApp"
        :app="widget.object"
      />

      <WeatherViewOld
        class="weather-app"
        :style="{ zoom: scaleTemplateValue(1) }"
        v-else-if="isOldWeatherApp"
        :app="widget.object"
      />
    </div>
  </vue-draggable-resizable>
</template>

<script>
  import VueDraggableResizable from '@/components/common/VueDraggableResizable.vue';

  import { APP_TYPES } from '@/models/layoutDesigner';
  import { simpleTypeMixin } from '@/helpers';

  import draggableWidget from '@/components/templates/mixins/draggableWidget';

  import RssViewer from '@/components/rssViewer/Index.vue';
  import TableAppWebView from '@/components/tableApp/TableAppWebView.vue';
  import TimeTableViewer from '@/components/common/timetable/TimeTableViewer.vue';
  import WeatherView from '@/components/apps/weather/WeatherView.vue';
  import WeatherViewOld from '@/components/apps/weather/WeatherViewOld.vue';

  import { CONTENT_TYPE_WEATHER_APP } from '@/constant/const';

  export default {
    name: 'AppWidget',

    components: {
      RssViewer,
      TableAppWebView,
      VueDraggableResizable,
      TimeTableViewer,
      WeatherView,
      WeatherViewOld,
    },

    mixins: [simpleTypeMixin, draggableWidget],

    props: {
      widget: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        APP_TYPES,
        showPlaceholder: false,
      };
    },

    computed: {
      style() {
        return {
          width: '100%',
          height: '100%',
        };
      },

      position() {
        return this.getPosition(this.widget);
      },

      isOldWeatherApp() {
        return (
          this.isWeatherApp &&
          !this.widget.object.weather &&
          (this.widget.object.visibility || this.widget.object.background)
        );
      },

      isWeatherApp() {
        return this.widget.object.item_type === CONTENT_TYPE_WEATHER_APP;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .app-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #fff;
    color: $secondaryText;
    overflow: hidden;

    i {
      font-size: 60px;
    }
  }

  .rss-viewer {
    overflow: hidden;
  }

  .app-widget {
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
</style>
