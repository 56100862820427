export const CONTENT_TYPE_CATEGORY = 'category';
export const CONTENT_TYPE_FOLDER = 'folder';
export const CONTENT_TYPE_RSS_APP = 'app/rss';
export const CONTENT_TYPE_TABLE_APP = 'app/table';
export const CONTENT_TYPE_TRAFIKLAB_APP = 'app/trafiklab';
export const CONTENT_TYPE_WEATHER_APP = 'app/weather';
export const CONTENT_TYPE_LOCAL_TEMPLATE = 'app/local-template';
export const CONTENT_TYPE_TEMPLATE_APP = 'app/template';
export const CONTENT_TYPE_TEMPLATE = 'templateItem';

export const CONTENT_TYPE_GENERAL_APP = 'app';
export const CONTENT_TYPE_GENERAL_IMAGE = 'image';
export const CONTENT_TYPE_GENERAL_VIDEO = 'video';
export const CONTENT_TYPE_PDF = 'pdf';
export const CONTENT_TYPE_TENANT = 'tenant';

export const DEFAULT_FONTS = [
  'Arial',
  'Arial Black',
  'Brush Script MT',
  'Comic Sans MS',
  'Courier',
  'Georgia',
  'Garamond',
  'Helvetica',
  'Impact',
  'Lucida Console',
  'Tahoma',
  'Times New Roman',
  'Trebuchet MS',
  'Verdana',
];

export const ALLOWED_UPLOAD_IMAGES = ['png', 'jpg', 'jpeg'];
export const ALLOWED_UPLOAD_FONTS = ['woff', 'woff2'];
export const ALLOWED_PLAYLIST_FILE_EXTENSIONS = [
  'image/png',
  'image/jpeg',
  'application/pdf',
  'video/mp4',
  'video/webm',
];

export const DAYS_OF_THE_WEEK = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

export const TIME_UNITS = {
  DAYS: {
    title: 'days',
    value: 'days',
    shortValue: 'dd',
  },
  HOURS: {
    title: 'hours',
    value: 'hours',
    shortValue: 'hh',
  },
  MINUTES: {
    title: 'minutes',
    value: 'minutes',
    shortValue: 'mm',
  },
  SECONDS: {
    title: 'seconds',
    value: 'seconds',
    shortValue: 'ss',
  },
};

export const SCHEDULE_TYPES = {
  ADVANCED: 'advanced',
  ALWAYS: 'always',
  DIFFERENT_HOURS: 'diff-hours',
  SAME_HOURS: 'same-hours',
  TIME_TO_TIME: 'time-to-time',
};

export const PLAYLIST_SCHEDULE_STATUS = {
  ACTIVE: 'Active',
  FINISHED: 'Finished',
  INACTIVE: 'Hidden',
  SCHEDULED: 'Scheduled',
  DEPRIORITIZED: 'Deprioritized',
};

export const ALLOWED_MAX_FILE_SIZE_IN_MB = 200;

export const DISPLAY_PRIORITY = {
  PRIORITY: 'priority',
  STANDARD: 'standard',
};

export const LOGIN_FORMS = {
  LOGIN: 'login',
  FORGOT_PASSWORD: 'forgot-password',
  RESET_PASSWORD: 'reset-password',
};

export const LOGIN_FORM_BUTTON_LABELS = {
  LOGIN: 'Log in',
  SEND_EMAIL: 'Send Email',
  RESET_PASSWORD: 'Reset Password',
};

export const NOT_ORGANISATION_ADMIN_MESSAGE =
  'Redirecting to home because you no longer are organisation admin.';
