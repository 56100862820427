<template>
  <div class="stat-card" :class="theme">
    <div class="stat-progress">
      <radial-progress-bar
        ref="progressBar"
        :diameter="52"
        :total-steps="total || 0"
        :completed-steps="value || 0"
        :stroke-width="6"
        :inner-stroke-width="6"
        start-color="#000000"
        stop-color="#000000"
        inner-stroke-color="rgb(21, 21, 21, 0.2)"
      >
      </radial-progress-bar>
    </div>
    <div class="stat-info">
      <div class="stat-value">
        <i class="material-icons-outlined">{{ icon }}</i>
        <span class="text">{{ value }}</span>
      </div>
      <div class="stat-label">{{ label }}</div>
    </div>
  </div>
</template>

<script>
  import RadialProgressBar from 'vue-radial-progress';

  export default {
    props: ['theme', 'total', 'completed', 'label', 'value', 'icon'],
    components: {
      RadialProgressBar,
    },

    mounted() {
      const { progressBar } = this.$refs;
      const circles = progressBar.$el.querySelectorAll('circle');
      // set endcap to flat
      circles.forEach((circle) => {
        circle.setAttribute('stroke-linecap', 'butt');
      });
    },
  };
</script>

<style lang="scss" scoped>
  .stat-card {
    background: #fff7bc;
    padding: 24px;
    width: 100%;
    display: flex;
    min-height: 213px;
    .stat-progress {
      width: 64px;
      height: 64px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #feef93;

      ::v-deep .radial-progress-container {
        transform: rotate(180deg);
      }
    }

    .stat-info {
      margin-left: 16px;

      .stat-value {
        display: flex;
        align-items: center;

        i {
          font-size: 20px;
          margin-right: 5px;
          color: $secondaryText;
        }

        .text {
          font-size: 24px;
          font-style: medium;
          font-weight: 500;
          line-height: 36px;
          font-family: 'Poppins', sans-serif;
          color: #151515;
        }
      }

      .stat-label {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $secondaryText;
      }
    }

    &.green {
      background: #ccffcc;
      .stat-progress {
        background: #bce5bc;
      }
    }

    &.grey {
      background: #dddddd;
      .stat-progress {
        background: #c6c6c6;
      }
    }

    &.red {
      background: #ffd9d9;
      .stat-progress {
        background: #ffcccc;
      }
    }

    &.purple {
      background: #e2d9ff;
      .stat-progress {
        background: #cebfff;
      }
    }
  }
</style>
