module.exports = {
  organizations: 'ORGANIZATIONS',
  logged_in_info_message: 'You are logged in as a global administrator!',
  create_organization: '+ Add Organization',
  synchronize_s3: 'Synchronize S3',
  all_organizations: 'All Organizations',
  manage_organization_info: 'Click on an organization to manage it',
  edit_organization: 'EDIT ORGANIZATION',
  update_organization: 'Update organization',
  edit_organization_info: 'Gray fields cannot be updated by end users',
  edit_aws_link_text: 'AWS data storage media',
  create_user: 'Create User',
  create_user_popup_email_label: 'Name (email address)',
  create_user_popup_email_placeholder: 'User name',
  create_user_popup_password_label: 'Password',
  create_user_popup_password_placeholder: 'User password',
  allow_login: 'Allow login?',
  local_admin: 'Local administrator',
  name_of_the_organization: 'Name of organization',
  organization_user_warning: 'Users of inactive organizations cannot log in',
  organization_delete_warning: 'You are about to delete an entire organization!',
  organization_delete_warning_list_1: 'Playlists and all their object references',
  organization_delete_warning_list_2: 'User accounts',
  organization_delete_warning_list_3: 'Cloud data for access to uploaded media',
  organization_delete_warning_list_4:
    'Uploaded media is stored in the cloud for up to 3 months after deletion',
  organization_delete_warning_list_5:
    'For security reasons, you cannot delete the organization you are logged in with',
  organization_delete_warning_list_6: 'Confirm deletion by clicking the Delete button.',
  rolesModal: {
    placeholder: 'Role name',
    nameLabel: 'Name',
    addTitle: 'Add Role',
    addRole: 'Add Role',
    editButton: 'Save',
    editTitle: 'Edit Role',
    description: 'Add layers this role can edit',
    selectDefault: 'Choose here',
    editSuccess: 'Role updated',
    createSuccess: 'Role created',
  },
  userModal: {
    addUser: 'Add User',
    addTitle: 'Create User',
    allowLogin: 'Allow login?',
    editButton: 'Save',
    editTitle: 'Edit User',
    emailLabel: 'Email',
    emailPlaceholder: 'email@domain.com',
    localAdmin: 'Local administrator',
    passwordLabel: 'Password',
    passwordPlaceholder: 'User password',
    selectDefault: 'Choose a role',
    fullNameLabel: 'User name',
    fullNamePlaceholder: 'Name and surname',
    rolesLabel: 'Assign a Role',
    editSuccess: 'Saved',
    createSuccess: 'User created',
  },
  rolesList: {
    headers: {
      role: 'Role',
      users: 'Users',
      editLayers: 'Add Role',
    },
    contextMenu: {
      rename: 'Rename',
      editLayers: 'Edit Layers',
      remove: 'Remove',
    },
    noLayers: 'No layers added yet',
    noRoles: 'No roles',
  },
  removeRole: {
    title: 'Delete Role',
    description: 'Are you sure you want to delete it?',
    delete: 'Delete',
    success: 'Role deleted successfully',
  },
  table: {
    headers: {
      name: 'Name',
      created: 'Created',
      online: 'Online',
      licenses: 'Licenses',
      expirationDate: 'License Expiry Date',
      users: 'Users',
      remove: 'Remove',
    },
  },

  s3_bucket_synced: 'S3 Buckets synchronized',
  organization_updated: 'Organization updated',
  organization_removed: 'Organization removed',
  organization_created: 'Organization created',
  user_updated: 'User updated',
  user_created: 'User created',
  user_removed: 'User removed',

  create_cloud_data: 'Create cloud data',
  no_cloud_data: 'No cloud data found, press button above to create',
};
