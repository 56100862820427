<!-- eslint-disable vuejs-accessibility/label-has-for -->
<template>
  <div class="form-group">
    <label :for="id">
      <span v-if="icon" class="material-icons-outlined align-middle icon">{{ icon }}</span>
      <span class="text">{{ label }}</span>
      <span v-if="small" class="small">{{ small }}</span>
    </label>
    <slot>
      <input
        type="text"
        :class="['form-control', { 'is-invalid': !!error }]"
        :id="id"
        :value="value"
        :placeholder="placeholder"
        @input="$emit('input', $event.target.value)"
      />
    </slot>
    <div class="invalid-feedback" v-if="error">
      {{ error }}
    </div>
  </div>
</template>

<script>
  export default {
    name: 'BaseInput',
    emits: ['input'],
    props: ['id', 'label', 'type', 'error', 'value', 'icon', 'small', 'placeholder'],
  };
</script>

<style lang="scss" scoped>
  .form-group {
    width: 100%;
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    gap: 8px;

    label {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 0;

      .icon {
        color: #151515;
        font-size: 22px;
        margin-right: 8px;
      }
      .text {
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
      }

      .small {
        font-size: 12px;
        color: #6a6b6a;
        margin-left: 3px;
      }
    }
    input.form-control,
    select.form-control {
      padding: 6px 16px;
      height: auto;
      border: 2px solid $borderGrey;
      border-radius: 8px;
      font-size: 16px;
      line-height: 24px;

      color: #151515;

      &:focus {
        box-shadow: none;
        border-color: #696969;
      }
    }
  }
</style>
