<template>
  <div class="table-container scrollbar">
    <table v-if="licenses && licenses.length > 0" class="table">
      <thead>
        <tr>
          <th v-if="renewMode"></th>
          <th class="name">License Name</th>
          <th>Status</th>
          <th>Created</th>
          <th>Expiry Date</th>
          <th v-if="isAdmin"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="license in licenses"
          :key="license.id"
          :class="{
            selected: isSelected(license.id) && !renewMode,
          }"
          @click="setSelectedLicense(license)"
        >
          <td v-if="renewMode" style="width: 64px">
            <i v-if="isSelected(license.id)" class="material-icons align-middle button text-red">
              check_box
            </i>
            <i v-else class="material-icons-outlined align-middle button"
              >check_box_outline_blank</i
            >
          </td>

          <td class="name">
            {{ license.name | alwaysShowStr }}
          </td>

          <td
            style="width: 20%"
            :class="{
              'text-green': license.status === 'active',
              'text-expired': license.status === 'expired',
              'text-danger': license.status === 'removed'
            }"
          >
            {{ getStatusLabel(license.status) }}
          </td>

          <td style="width: 20%">
            {{ formattedDate(license.started_at, 'l') }}
          </td>

          <td style="width: 20%" :class="expirationDateColor(expiredDate(license))">
            {{ formattedDate(expiredDate(license), 'l') }}
          </td>

          <td v-if="isAdmin" style="width: 64px">
            <div class="dropdown">
              <i
                class="material-icons-outlined align-middle more-buttons dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                >more_vert</i
              >
              <div class="dropdown-menu dropdown-menu-right" v-show="license.status !== 'removed'">
                <button
                  v-if="isAdmin"
                  type="button"
                  class="dropdown-item"
                  @click="openDeleteLicenseModal(license)"
                >
                  <i class="material-icons-outlined align-middle">delete</i>
                  Delete
                </button>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div v-else class="no-data">License does not exist, use the add button to create a new one.</div>

    <DeleteLicenseModal
      @closeModal="showDeleteLicenseModal = false"
      :license="selectedLicense"
      v-if="showDeleteLicenseModal"
      @deleted="$emit('onDelete')"
    />
  </div>
</template>

<script>
  import moment from 'moment';

  import { LICENSE_STATUSES } from '@/config/constants';

  import DeleteLicenseModal from '@/components/admin/DeleteLicenseModal.vue';

  export default {
    name: 'LicensesList',

    components: {
      DeleteLicenseModal,
    },

    props: {
      licenses: {
        type: Array,
        default: () => [],
      },

      renewMode: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        showDeleteLicenseModal: false,
        selectedLicense: null,
        selectedLicenses: [],
      };
    },

    watch: {
      renewMode(value) {
        this.selectedLicense = null;
        this.selectedLicenses = [];
      },
    },

    computed: {
      isAdmin() {
        return this.$store.getters.isAdministrator;
      }
    },

    methods: {
      checkDate(date) {
        return moment(date).isBefore();
      },

      setSelectedLicense(license = null) {
        if (!this.renewMode) {
          this.selectedLicense = license;
          this.$emit('licenseSelected', license);
          return;
        }

        const removeLicense = this.selectedLicenses.some(
          (selectedLicense) => selectedLicense.id === license.id,
        );

        if (removeLicense) {
          this.selectedLicenses = this.selectedLicenses.filter(
            (selectedLicense) => selectedLicense.id !== license.id,
          );
        } else {
          this.selectedLicenses.push(license);
        }

        this.$emit('licenseSelected', this.selectedLicenses);
      },

      openDeleteLicenseModal(license) {
        this.setSelectedLicense(license);
        this.showDeleteLicenseModal = true;
      },

      getStatusLabel(statusKey) {
        return LICENSE_STATUSES[statusKey];
      },

      isSelected(licenseId) {
        if (this.renewMode) {
          return this.selectedLicenses.some((l) => l.id === licenseId);
        }

        return this.selectedLicense?.id === licenseId;
      },

      expirationDateColor(date) {
        const newDate = moment(date, 'DD/MM/YYYY').toDate();
        const colorClass = moment(newDate).isBefore() ? 'text-expired' : '';

        // TODO: orange condition

        return colorClass;
      },

      formattedDate(date, format) {
        return moment(date).format(format);
      },
      expiredDate(license) {
        const startDate = moment(license.started_at);
        return startDate.add(license.expired_time, 'days');
      }
    },
  };
</script>
<style lang="scss" scoped>
  .table-container {
    overflow-y: auto;
    overflow-x: visible;

    padding-right: 2rem;
    margin-right: -2rem;

    .table {
      border: none;
      border-spacing: 0 8px;
      border-collapse: separate;
      margin-bottom: 0;

      thead {
        th {
          position: sticky;
          top: 0;
          box-shadow: 0px 0px 0 8px $backgroundGrey;
          z-index: 2;
          background-color: $backgroundGrey;
        }
      }

      th,
      td {
        border: none;

        &.name {
          text-align: left;
        }
      }

      th {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;

        & + th {
          text-align: center;
        }
      }

      td {
        padding: 14px 16px;

        font-size: 16px;
        line-height: 24px;

        background: none;
        border-top: 2px solid transparent;
        border-bottom: 2px solid transparent;

        &:first-child {
          border-left: 2px solid transparent;
        }

        &:last-child {
          border-right: 2px solid transparent;
        }

        & + td {
          text-align: center;
        }
      }

      tbody {
        overflow-y: auto;
        height: 100%;

        tr {
          cursor: pointer;
          background-color: #fff;

          &:hover,
          &.selected {
            td {
              border-top: 2px solid $primaryRed;
              border-bottom: 2px solid $primaryRed;

              &:first-child {
                border-left: 2px solid $primaryRed;
              }

              &:last-child {
                border-right: 2px solid $primaryRed;
              }
            }
          }
        }
      }
    }
  }
</style>
