<template>
  <Modal
    @closeModal="closeModal"
    v-show="showModal"
    :isLoading="isLoading"
    title="Copy Confirmation"
    :okFunction="copyLayout"
  >
    <template v-slot:body>
      <div class="delete-description">Do you want to make a copy of this layout?</div>
    </template>
  </Modal>
</template>

<script>
  import Modal from '@/components/common/Modal.vue';
  import { CLONE_LAYOUT } from '@/store/actions/layoutDesigner';

  export default {
    name: 'CopyLayoutModal',

    components: { Modal },

    props: {
      layout: {
        type: Object,
        default: null,
      },
      widgets: {
        type: Object,
        default: () => ({}),
      },
    },

    data() {
      return {
        showModal: false,
        isLoading: false,
      };
    },

    mounted() {
      this.showModal = true;
    },

    methods: {
      async copyLayout() {
        this.isLoading = true;

        try {
          const response = await this.$store.dispatch(CLONE_LAYOUT, {
            layout: this.layout,
            widgets: this.widgets,
          });

          if (response) {
            this.$router.push({
              name: 'Layout',
              params: { layout_id: response.layout_id },
            });
          }
        } catch (error) {
          this.$toasted.global.general_error({
            message: String(error),
          });
        }

        this.isLoading = false;
        this.closeModal();
      },

      closeModal() {
        this.showModal = false;
        this.$emit('closeModal');
      },
    },
  };
</script>
