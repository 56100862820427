<script lang="ts">
  export default {
    name: 'ScreenCodeTabs',
  };
</script>

<script setup lang="ts">
  import { defineEmits, ref, watch } from 'vue';
  import BaseText from '@ui/atoms/baseText/BaseText.vue';
  import CodeInput from '@/components/screens/ScreenCodeInput.vue';
  import { apiGenerateScreenCode, apiConnectToScreenWithCode } from '@/api/screens';
  import { useToasted } from '@/helpers';
  import { AxiosError } from 'axios';
  import { ScreenCodeConnect } from '@/types/api/screens';

  const props = defineProps<{
    screenId: number;
  }>();

  const toasted = useToasted();
  const generatedCode = ref<string>('------');
  const codeGenerated = ref<Boolean>(false);
  const activateCode = ref<string>('');
  const isGenerateTab = ref<Boolean>(true);
  const codeSize = ref<number>(6);

  const emit = defineEmits<{
    (e: 'tab:tabChange', tab: Boolean): void;
  }>();

  watch(isGenerateTab, (value) => {
    emit('tab:tabChange', value);
  });

  function setGenerateTab(generateTab: Boolean) {
    isGenerateTab.value = generateTab;
  }
  function setInputCode(code: string) {
    activateCode.value = code;
  }
  async function actionClicked() {
    try {
      if (isGenerateTab.value) {
        const response = await apiGenerateScreenCode(props.screenId);
        generatedCode.value = response.data.code as string;
        codeGenerated.value = true;
        return true;
      }
      if (activateCode.value.length !== codeSize.value) {
        toasted.global.general_error({
          message: 'Incomplete screen code',
        });
        return false;
      }
      const res = await apiConnectToScreenWithCode(props.screenId, activateCode.value);
      toasted.global.general_success({
        message: (res.data as ScreenCodeConnect).message,
      });
      return true;
    } catch (error) {
      console.log('code generation:', error);
      toasted.global.general_error({
        message: (error as AxiosError)?.response?.data?.message ?? String(error),
      });
      return false;
    }
  }

  defineExpose({
    actionClicked,
  });
</script>

<template>
  <div class="tabs-container">
    <div class="tabs">
      <div :class="['tab', { active: isGenerateTab }]" @click.prevent="setGenerateTab(true)">
        <span class="text">Generate Code</span>
      </div>

      <div :class="['tab', { active: !isGenerateTab }]" @click.prevent="setGenerateTab(false)">
        <span class="text">Activate Code</span>
      </div>
    </div>

    <div class="tab-content generate-tab" v-show="isGenerateTab">
      <BaseText variant="title1">
        {{
          !!codeGenerated
            ? 'Enter the code below in your screen'
            : 'Please click generate to generate a screen code'
        }}
      </BaseText>
      <CodeInput :length="codeSize" :codeValue="generatedCode" :isEditable="false" />
    </div>
    <div class="tab-content activate-tab" v-show="!isGenerateTab">
      <BaseText variant="title1">Enter the code from your screen below</BaseText>
      <CodeInput :length="codeSize" @update:code="setInputCode" :isEditable="true" />
    </div>
  </div>
</template>

<style scoped lang="scss">
  .tabs-container {
    width: 100%;
  }

  .tabs {
    display: flex;
    .tab {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      border-bottom: 2px solid $lineGrey;
      padding: 8px 5px;
      transition: border-color 200ms ease-in-out;
      color: $secondaryText;
      cursor: pointer;

      &:hover {
        border-color: #d6d6d6;
        color: #555555;
      }
      &.active {
        border-color: $primaryRed;
        color: $primaryText;
      }

      .text {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: inherit;
      }
    }
  }

  .tab-content {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
</style>
