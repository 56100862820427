<template>
  <PermissionsModal
    ref="permissionsModal"
    :isLoading="isLoading"
    :isSaving="isSaving"
    :roles="roles"
    :currentAccess="rolesPermissions"
    @onSave="save"
  />
</template>

<script>
  import PermissionsModal from '@/components/common/PermissionsModal.vue';
  import { getApiOrganisationRoles, getRoles } from '@/api/roles';
  import { apiGetGroupRolesAccess, apiPostGroupRolesAccess } from '@/api/screens';
  import { mapState, mapGetters } from 'vuex';

  export default {
    inject: ['getCurrentScreenGroup'],
    components: { PermissionsModal },
    data() {
      return {
        isLoading: false,
        isSaving: false,
        roles: [],
        rolesPermissions: {},
      };
    },
    computed: {
      ...mapState({
        orgId: (state) => state.auth.organisation,
      }),
      ...mapGetters({
        isAdmin: 'isAdministrator',
        isLocalAdmin: 'isOrganizationAdmin',
      }),

      screenGroup() {
        return this.getCurrentScreenGroup();
      },
    },
    methods: {
      async fetchData() {
        this.isLoading = true;
        try {
          let res = this.isAdmin ? await getApiOrganisationRoles(this.orgId) : await getRoles();
          let access = await apiGetGroupRolesAccess(this.screenGroup.id);
          this.roles = res.data;
          this.rolesPermissions = this.orderAccess(access);
        } catch (e) {
          console.log(e);
        }
        this.isLoading = false;
      },

      async save(newAccess) {
        this.isSaving = true;
        try {
          let res = await apiPostGroupRolesAccess(this.screenGroup.id, newAccess);
          this.rolesPermissions = this.orderAccess(res);

          this.$toasted.global.general_success({
            message: 'Permissions updated successfully',
          });
        } catch (e) {
          console.log(e);
          this.$toasted.global.general_error({
            message: 'Error updating permissions',
          });
        }
        this.close();
        this.isSaving = false;
      },

      orderAccess(access) {
        let o = {};
        access.access_roles?.forEach((a) => {
          o[a.id] = a.is_active;
        });
        return o;
      },
      open() {
        this.fetchData();
        this.$refs.permissionsModal.open();
      },
      close() {
        this.$refs.permissionsModal.close();
      },
    },
  };
</script>
