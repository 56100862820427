<template>
  <div class="content-item" @click="selectItem">
    <div
      class="preview"
      v-if="
        item.item_type?.includes('image') ||
        item.item_type?.includes('video') ||
        item.item_type?.includes('pdf')
      "
    >
      <!-- Image -->
      <img
        v-if="item.item_type?.includes('image') || item.item_type?.includes('video')"
        :src="item.img"
        alt=""
      />
      <div class="icon" v-else-if="item.item_type?.includes('pdf')" :src="item.img" alt="">
        <i class="material-icons play-icon">picture_as_pdf</i>
      </div>
    </div>
    <div v-else-if="item.item_type?.startsWith('app/')" class="preview">
      <img :src="item.img" alt="" />
    </div>
    <div class="body">
      <div class="name">{{ item.name }}</div>
      <!-- Renaming table to tenant-->
      <div v-if="item.item_type === CONTENT_TYPE_TABLE_APP" class="type">
        {{ CONTENT_TYPE_TENANT }}
      </div>
      <div v-else class="type">{{ getSimpleType(item.item_type) }}</div>
    </div>
  </div>
</template>

<script>
  import { simpleTypeMixin } from '@/helpers';
  import { CONTENT_TYPE_TABLE_APP, CONTENT_TYPE_TENANT } from '@/constant/const';

  export default {
    props: ['item'],
    emits: ['selectItem'],
    mixins: [simpleTypeMixin],
    data() {
      return {
        CONTENT_TYPE_TABLE_APP,
        CONTENT_TYPE_TENANT,
      };
    },
    methods: {
      selectItem() {
        this.$emit('select-item', this.item);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .content-item {
    background-color: white;
    cursor: pointer;
    border: 1px solid transparent;
    &.active {
      border: 1px solid #e3003a;
      box-shadow:
        0px 0px 0px 2px rgba(227, 0, 58, 0.2),
        0px 0px 16px rgb(21 21 21 / 2%);
    }
    .preview {
      height: 76px;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }

      .icon {
        background-color: #ffbf5f;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        i {
          font-size: 40px;
          color: #202020;
        }
      }
    }
    .body {
      padding: 5px;
      .name {
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        color: #333332;
        word-wrap: break-word;
      }
      .type {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #6a6b6a;
        text-transform: capitalize;
      }
    }
  }
</style>
