<template>
  <Modal
    @closeModal="closeModal"
    v-show="showModal"
    :isSaving="isLoading"
    title="Delete Confirmation"
    :okFunction="deleteContent"
  >
    <template v-slot:body>
      <div class="delete-description">Are you sure you want to delete selected contents?</div>
    </template>
  </Modal>
</template>

<script>
  import Modal from '@/components/common/Modal.vue';
  import { apiDeleteFiles } from '@/api/content';
  import { CONTENT_REQUEST } from '@/store/actions/content';

  export default {
    name: 'MultipleContentDeleteModal',

    components: { Modal },

    props: {
      contents: {
        type: Array,
        required: true,
      },
    },

    data() {
      return {
        showModal: false,
        isLoading: false,
      };
    },

    mounted() {
      this.showModal = true;
    },

    methods: {
      async deleteContent() {
        this.isLoading = true;

        try {
          await apiDeleteFiles(this.contents.map((file) => file.item_id));
          this.$toasted.global.general_success({ message: 'Items successfully deleted' });
          this.$store.dispatch(CONTENT_REQUEST);
          this.$emit('onDelete');
          this.closeModal(true);
        } catch (error) {
          this.$toasted.global.general_error({
            message: error.response?.data?.message || 'Failed to delete the items',
          });
        }
        this.isLoading = false;
      },

      closeModal() {
        this.showModal = false;
        this.$emit('onClose');
      },
    },
  };
</script>
