<template>
  <div class="date">
    <i
      v-if="itemStatus === PLAYLIST_SCHEDULE_STATUS.ACTIVE"
      :class="[
        'material-icons-outlined',
        'align-middle',
        hasActivePriorityItem && playlistItem.display_priority !== DISPLAY_PRIORITY.PRIORITY
          ? 'text-secondary'
          : 'text-success',
      ]"
      :title="
        hasActivePriorityItem && playlistItem.display_priority !== DISPLAY_PRIORITY.PRIORITY
          ? 'Deprioritized as priority item exists'
          : 'Visible'
      "
    >
      {{
        hasActivePriorityItem && playlistItem.display_priority !== DISPLAY_PRIORITY.PRIORITY
          ? 'lock_reset'
          : 'update'
      }}
    </i>

    <i
      v-else-if="itemStatus === PLAYLIST_SCHEDULE_STATUS.SCHEDULED"
      class="material-icons align-middle text-info"
      title="Scheduled"
    >
      access_time
    </i>

    <i
      v-else-if="itemStatus === PLAYLIST_SCHEDULE_STATUS.INACTIVE"
      class="material-icons-outlined align-middle text-secondary"
      title="Temporarily hidden"
    >
      visibility_off
    </i>

    <i v-else class="material-icons-outlined align-middle text-danger" title="Finished">
      timer_off
    </i>

    <span
      :class="{
        'text-success':
          itemStatus === PLAYLIST_SCHEDULE_STATUS.ACTIVE &&
          !isDeprioritized(playlistItem, itemStatus),
        'text-danger': itemStatus === PLAYLIST_SCHEDULE_STATUS.FINISHED,
        'text-info': itemStatus === PLAYLIST_SCHEDULE_STATUS.SCHEDULED,
        'text-primary':
          itemStatus === PLAYLIST_SCHEDULE_STATUS.INACTIVE ||
          isDeprioritized(playlistItem, itemStatus),
      }"
    >
      {{
        isDeprioritized(playlistItem, itemStatus)
          ? PLAYLIST_SCHEDULE_STATUS.DEPRIORITIZED
          : itemStatus
      }}
    </span>
  </div>
</template>

<script>
  import { PLAYLIST_SCHEDULE_STATUS, DISPLAY_PRIORITY } from '@/constant/const';
  export default {
    name: 'PlaylistItemStatus',

    props: {
      itemStatus: {
        type: String,
        default: PLAYLIST_SCHEDULE_STATUS.ACTIVE,
      },
      hasActivePriorityItem: {
        type: Boolean,
        default: false,
      },
      playlistItem: {
        type: Object,
        default: {},
      },
    },

    data() {
      return {
        PLAYLIST_SCHEDULE_STATUS,
        DISPLAY_PRIORITY,
      };
    },

    methods: {
      isDeprioritized() {
        return (
          this.hasActivePriorityItem &&
          this.playlistItem.display_priority !== DISPLAY_PRIORITY.PRIORITY &&
          this.itemStatus === PLAYLIST_SCHEDULE_STATUS.ACTIVE
        );
      },
    },
  };
</script>
<style scoped></style>
