<template>
  <div class="row">
    <div class="page-container col-12" id="home">
      <div class="row mx-n3">
        <div class="col-md-4 px-3 mb-3">
          <StatCard
            icon="check_circle"
            :value="dashboard.data.connectedScreens"
            :total="dashboard.data.totalScreens"
            :completed="
              completedPercent(dashboard.data.totalScreens, dashboard.data.connectedScreens)
            "
            label="Connected screens."
            theme="green"
          />
        </div>
        <div class="col-md-4 px-3 mb-3">
          <StatCard
            icon="check_circle"
            :value="dashboard.data.idleOrDisconnectedScreens"
            :total="dashboard.data.totalScreens"
            :completed="
              completedPercent(
                dashboard.data.totalScreens,
                dashboard.data.idleOrDisconnectedScreens,
              )
            "
            label="Idle or disconnected screens."
            theme="grey"
          />
        </div>
        <div class="col-md-4 px-3 mb-3">
          <StatCard
            icon="error_outline"
            :value="dashboard.data.notConnectedScreens"
            :total="dashboard.data.totalScreens"
            :completed="
              completedPercent(dashboard.data.totalScreens, dashboard.data.notConnectedScreens)
            "
            label="Not connected screens."
            theme="red"
          />
        </div>
        <div class="col-md-4 px-3 mb-3">
          <StatCard
            icon="check_circle"
            :value="dashboard.data.liveLayouts"
            :total="dashboard.data.totalLayouts"
            :completed="completedPercent(dashboard.data.totalLayouts, dashboard.data.liveLayouts)"
            label="Live layouts."
            theme="purple"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import StatCard from './StatCard.vue';
  import { EVENTLOG_REQUEST } from '../store/actions/eventlog';
  import { DASHBOARD_STATISTICS_REQUEST } from '@/store/actions/dashboard';
  import { StoreStatus } from '@/types/store';

  export default {
    name: 'Home',
    components: {
      StatCard,
    },

    computed: {
      ...mapState({
        eventlog: (state) => state.eventlog.eventlog,
        status: (state) => state.eventlog.status,
        eventlogRequestStatus: (state) => state.eventlog.eventlogRequestStatus,
        eventlogRequestError: (state) => state.eventlog.eventlogRequestError,
        dashboard: (state) => state.dashboard.dashboardStatistics,
      }),
    },

    mounted() {
      if (this.$store.getters.isAuthenticated) {
        this.$store.dispatch(DASHBOARD_STATISTICS_REQUEST);
        this.$store.dispatch(EVENTLOG_REQUEST);
      }
    },

    methods: {
      completedPercent(total, value) {
        if (total === 0) return 0;

        return Math.round((100 * value) / total);
      },
    },

    watch: {
      dashboard(newValue) {
        if (newValue.status === StoreStatus.IsError) {
          this.$toasted.global.general_error({ message: 'Failed to load the dashboard info' });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  #home {
    &.page-container {
      margin-top: 20px;
      padding: 0px 24px 0px 40px;
    }
  }
</style>
