import '@tiptap/extension-text-style';

import { Extension } from '@tiptap/core';
import { MarkType, NodeType } from '@tiptap/pm/model';

export type LetterSpacingOptions = {
  types: string[];
  getStyle: (letterSpacing: string) => string;
};

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    letterSpacing: {
      /**
       * Set the letter spacing
       */
      setLetterSpacing: (letterSpacing: string) => ReturnType;
      /**
       * Unset the letter spacing
       */
      unsetLetterSpacing: () => ReturnType;
      /**
       * Set the letter spacing of a node or mark
       */
      setNodeLetterSpacing: (
        typeOrName: string | NodeType | MarkType,
        letterSpacing: string,
      ) => ReturnType;
      /**
       * Unset the letter spacing of a node or mark
       */
      unsetNodeLetterSpacing: (typeOrName: string | NodeType | MarkType) => ReturnType;
    };
  }
}

export const LetterSpacing = Extension.create<LetterSpacingOptions>({
  name: 'letterSpacing',

  addOptions(): LetterSpacingOptions {
    return {
      types: ['textStyle'],
      getStyle: (letterSpacing: string) => {
        return `letter-spacing: ${letterSpacing}`;
      },
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          letterSpacing: {
            default: null,
            parseHTML: (element) => element.style.letterSpacing?.replace(/['"]+/g, ''),
            renderHTML: (attributes) => {
              if (!attributes.letterSpacing) {
                return {};
              }

              return {
                style: this.options.getStyle(attributes.letterSpacing),
              };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setLetterSpacing:
        (letterSpacing) =>
        ({ chain }) => {
          return chain().setMark('textStyle', { letterSpacing }).run();
        },
      unsetLetterSpacing:
        () =>
        ({ chain }) => {
          return chain().setMark('textStyle', { letterSpacing: null }).removeEmptyTextStyle().run();
        },
      setNodeLetterSpacing:
        (typeOrName, letterSpacing) =>
        ({ commands }) => {
          return commands.updateAttributes(typeOrName, { letterSpacing: letterSpacing });
        },
      unsetNodeLetterSpacing:
        (typeOrName) =>
        ({ commands }) => {
          return commands.resetAttributes(typeOrName, 'letterSpacing');
        },
    };
  },
});
