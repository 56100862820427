<template>
  <Modal
    @closeModal="closeModal"
    v-show="showModal"
    :isSaving="isLoading"
    title="Delete Confirmation"
    :okFunction="deleteFiles"
  >
    <template v-slot:body>
      <div class="delete-description">Are you sure you want to delete selected folders?</div>
    </template>
  </Modal>
</template>

<script>
  import Modal from '@/components/common/Modal.vue';
  import { apiDeleteFolder } from '@/api/content';
  import { CONTENT_REQUEST } from '@/store/actions/content';

  export default {
    name: 'MultipleFolderDeleteModal',

    components: { Modal },

    props: {
      files: {
        type: Array,
        required: true,
      },
    },

    data() {
      return {
        showModal: false,
        isLoading: false,
      };
    },

    mounted() {
      this.showModal = true;
    },

    methods: {
      async deleteFiles() {
        this.isLoading = true;

        try {
          const promises = [];

          for (let i = 0; i < this.files.length; i++) {
            promises.push(apiDeleteFolder(this.files[i]?.id));
          }

          await Promise.all(promises);

          this.$toasted.global.general_success({ message: 'Folders successfully deleted' });
          this.$store.dispatch(CONTENT_REQUEST);
          this.$emit('onDelete');
          this.closeModal(true);
        } catch (error) {
          this.$toasted.global.general_error({
            message: error.response?.data?.message || 'Failed to delete all the folders',
          });
        }
        this.isLoading = false;
      },

      closeModal() {
        this.showModal = false;
        this.$emit('onClose');
      },
    },
  };
</script>
