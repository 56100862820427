<template>
  <vue-draggable-resizable
    v-if="!hideParent"
    :parent="true"
    :x="position.left"
    :y="position.top"
    :w="position.width"
    :h="position.height"
    :draggable="!isSavingLayout && !isWidgetLocked"
    :resizable="!isSavingLayout && !isWidgetLocked"
    :grid="gridScale"
    :snap="isSnapActive"
    :active="isWidgetSelected"
    :z="(isWidgetSelected && keepZIndexUp ? 1000 : 100) + widget.position[rotation].zIndex"
    :onDragStart="(x, y) => onChangePosition(true, x, y)"
    :onResizeStart="() => onChangePosition(false)"
    @dragstop="onWidgetDrag"
    @resizestop="onWidgetResize"
    @activated="() => onSelected(widget)"
    @deactivated="() => (keepZIndexUp = false)"
    @refLineParams="getRefLineParams"
  >
    <div
      @click.prevent="showImage"
      :class="['widget-item button-widget', { 'auto-fit-text': widget.object.maximalTextSize }]"
      :id="widget.object.wid"
      :style="style"
    >
      <span class="button-text">{{ widget.object.text }}</span>
    </div>
  </vue-draggable-resizable>
</template>

<script>
  import VueDraggableResizable from '@/components/common/VueDraggableResizable.vue';

  import playerMixins from '@/models/player';
  import draggableWidget from '@/components/layoutDesigner/mixins/draggableWidget';

  import { simpleTypeMixin } from '@/helpers';
  import { getRBGAColor } from '@/helpers/utils';

  export default {
    name: 'ButtonWidget',

    components: {
      VueDraggableResizable,
    },

    mixins: [playerMixins, simpleTypeMixin, draggableWidget],

    props: {
      widget: {
        type: Object,
        required: true,
      },
    },

    computed: {
      style() {
        let newStyles = {
          overflow: 'hidden',
          backgroundColor: getRBGAColor(
            this.widget.object.buttonColor,
            Number(this.widget.object.buttonOpacity) / 100,
          ),
          color: getRBGAColor(
            this.widget.object.textColor,
            Number(this.widget.object.opacity) / 100,
          ),
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontWeight: 'bold',
          width: '100%',
          height: '100%',
          fontSize: `${this.scaleLayoutValue(this.widget.object.textSize)}px`,
          top: 0,
          left: 0,
          borderRadius: `${this.scaleLayoutValue(this.widget.object.borderRadius)}px`,
        };

        if (this.widget.object.loadImage) {
          newStyles = {
            ...newStyles,
            backgroundImage: `url("${this.widget.object.imageUrl}")`,
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          };
        }

        return newStyles;
      },

      position() {
        return this.getPosition(this.widget);
      },
    },

    methods: {
      showImage() {
        this.$emit('show-enlarged', this.widget);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .button-text {
    white-space: -moz-nowrap; /* Firefox */
    white-space: -o-nowrap; /* Opera */
    white-space: nowrap; /* Chrome */
    word-wrap: break-word; /* IE */
  }
</style>
