var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"date"},[(_vm.itemStatus === _vm.PLAYLIST_SCHEDULE_STATUS.ACTIVE)?_c('i',{class:[
      'material-icons-outlined',
      'align-middle',
      _vm.hasActivePriorityItem && _vm.playlistItem.display_priority !== _vm.DISPLAY_PRIORITY.PRIORITY
        ? 'text-secondary'
        : 'text-success',
    ],attrs:{"title":_vm.hasActivePriorityItem && _vm.playlistItem.display_priority !== _vm.DISPLAY_PRIORITY.PRIORITY
        ? 'Deprioritized as priority item exists'
        : 'Visible'}},[_vm._v(" "+_vm._s(_vm.hasActivePriorityItem && _vm.playlistItem.display_priority !== _vm.DISPLAY_PRIORITY.PRIORITY ? 'lock_reset' : 'update')+" ")]):(_vm.itemStatus === _vm.PLAYLIST_SCHEDULE_STATUS.SCHEDULED)?_c('i',{staticClass:"material-icons align-middle text-info",attrs:{"title":"Scheduled"}},[_vm._v(" access_time ")]):(_vm.itemStatus === _vm.PLAYLIST_SCHEDULE_STATUS.INACTIVE)?_c('i',{staticClass:"material-icons-outlined align-middle text-secondary",attrs:{"title":"Temporarily hidden"}},[_vm._v(" visibility_off ")]):_c('i',{staticClass:"material-icons-outlined align-middle text-danger",attrs:{"title":"Finished"}},[_vm._v(" timer_off ")]),_c('span',{class:{
      'text-success':
        _vm.itemStatus === _vm.PLAYLIST_SCHEDULE_STATUS.ACTIVE &&
        !_vm.isDeprioritized(_vm.playlistItem, _vm.itemStatus),
      'text-danger': _vm.itemStatus === _vm.PLAYLIST_SCHEDULE_STATUS.FINISHED,
      'text-info': _vm.itemStatus === _vm.PLAYLIST_SCHEDULE_STATUS.SCHEDULED,
      'text-primary':
        _vm.itemStatus === _vm.PLAYLIST_SCHEDULE_STATUS.INACTIVE ||
        _vm.isDeprioritized(_vm.playlistItem, _vm.itemStatus),
    }},[_vm._v(" "+_vm._s(_vm.isDeprioritized(_vm.playlistItem, _vm.itemStatus) ? _vm.PLAYLIST_SCHEDULE_STATUS.DEPRIORITIZED : _vm.itemStatus)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }