module.exports = {
  welcome: 'Välkommen',
  to: 'till',
  activities_title : 'Aktiviteter',
  courts_title: 'Pulsen i hallen',
  book_with: 'Boka med',
  members: 'Medlemmar',
  playing_now: 'Spelar just nu',
  vacant_time: 'Ledig bana',
  vacant_times: 'Lediga banor',
  tracks: 'Banor',
  offers_for_you: 'Erbjudanden för dig',
  contact_us: 'Ta kontakt med oss i reception för info',
  available_time: 'Ledig bana',

  monday: 'Måndag',
  tuesday: 'Tisdag',
  wednesday: 'Onsdag',
  thursday: 'Torsdag',
  friday: 'Fredag',
  saturday: 'Lördag',
  sunday: 'Söndag',

  game_strength: 'Spelstyrka',
  players: 'Spelare',

  app_description: 'Spelar du tennis, badminton, bordtennis, squash eller padel? Bli medlem i Matchi och gör din vardag mer flexibel.',
};
