var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"section-container"},[_c('div',{staticClass:"grid-row"},[_c('div',{staticClass:"section"},[_c(_setup.BaseText,{staticClass:"heading-text",attrs:{"variant":"subtitle1"}},[_vm._v("Line Box")]),_c('div',{staticClass:"section-content-row"},[_c('div',{staticClass:"section-content-row-item"},[_c(_setup.BaseText,[_vm._v("Background Color")]),_c(_setup.ColorPicker,{attrs:{"value":_setup.line.backgroundColor},on:{"input":(hex) => {
                _setup.line.backgroundColor = hex;
                _setup.handleUpdate();
              }}})],1),_c('div',{staticClass:"section-content-row-item"},[_c(_setup.BaseText,[_vm._v("Text Color")]),_c(_setup.ColorPicker,{attrs:{"value":_setup.line.fontColor},on:{"input":(hex) => {
                _setup.line.fontColor = hex;
                _setup.handleUpdate();
              }}})],1)])],1),_c('div',{staticClass:"section"},[_c(_setup.BaseText,{staticClass:"heading-text",attrs:{"variant":"subtitle1"}},[_vm._v("Title")]),_c('div',{staticClass:"section-content"},[_c(_setup.BaseText,[_vm._v("Font")]),_c(_setup.FontSelector,{attrs:{"fonts":_setup.fonts,"value":_setup.title.fontType},on:{"update:value":(value) => {
              _setup.title.fontType = value;
              _setup.handleUpdate();
            }}}),_c('div',{staticClass:"section-content-row"},[_c('div',{staticClass:"section-content-row-item"},[_c(_setup.BaseText,[_vm._v("Text Color")]),_c(_setup.ColorPicker,{staticClass:"styled-color-picker",attrs:{"value":_setup.title.fontColor},on:{"input":(hex) => {
                  _setup.title.fontColor = hex;
                  _setup.handleUpdate();
                }}})],1),_c('div',{staticClass:"section-content-row-item"},[_c(_setup.BaseText,[_vm._v("Text Size")]),_c('input',{staticClass:"form-control input",attrs:{"type":"number","min":"1","max":"1000"},domProps:{"value":_setup.title.fontSize},on:{"input":function($event){$event.preventDefault();return ((event) => {
                  _setup.title.fontSize = Math.round(Number(event.target.value));
                  _setup.handleUpdate();
                }).apply(null, arguments)}}})],1)])],1)],1)]),_c(_setup.BaseDivider),_c('div',{staticClass:"grid-row"},[_c('div',{staticClass:"section"},[_c(_setup.BaseText,{staticClass:"heading-text",attrs:{"variant":"subtitle1"}},[_vm._v("Content")]),_c('div',{staticClass:"section-content"},[_c(_setup.BaseText,[_vm._v("Font")]),_c(_setup.FontSelector,{attrs:{"fonts":_setup.fonts,"value":_setup.content.fontType},on:{"update:value":(value) => {
              _setup.content.fontType = value;
              _setup.handleUpdate();
            }}}),_c('div',{staticClass:"section-content-row"},[_c('div',{staticClass:"section-content-row-item"},[_c(_setup.BaseText,[_vm._v("Text Color")]),_c(_setup.ColorPicker,{staticClass:"styled-color-picker",attrs:{"value":_setup.content.fontColor},on:{"input":(hex) => {
                  _setup.content.fontColor = hex;
                  _setup.handleUpdate();
                }}})],1),_c('div',{staticClass:"section-content-row-item"},[_c(_setup.BaseText,[_vm._v("Text Size")]),_c('input',{staticClass:"form-control input",attrs:{"type":"number","min":"1","max":"1000"},domProps:{"value":_setup.content.fontSize},on:{"input":function($event){$event.preventDefault();return ((event) => {
                  _setup.content.fontSize = Math.round(Number(event.target.value));
                  _setup.handleUpdate();
                }).apply(null, arguments)}}})],1)])],1)],1),_c('div',{staticClass:"section"},[_c(_setup.BaseText,{staticClass:"heading-text",attrs:{"variant":"subtitle1"}},[_vm._v("Departures Background")]),_c('div',{staticClass:"section-content-row"},[_c('div',{staticClass:"section-content-row-item"},[_c(_setup.BaseText,[_vm._v("Even Color")]),_c(_setup.ColorPicker,{staticClass:"styled-color-picker",attrs:{"value":_setup.even},on:{"input":(hex) => {
                _setup.even = hex;
                _setup.handleUpdate();
              }}})],1),_c('div',{staticClass:"section-content-row-item"},[_c(_setup.BaseText,[_vm._v("Odd Color")]),_c(_setup.ColorPicker,{staticClass:"styled-color-picker",attrs:{"value":_setup.odd},on:{"input":(hex) => {
                _setup.odd = hex;
                _setup.handleUpdate();
              }}})],1)])],1)]),_c(_setup.BaseDivider),_c('div',{staticClass:"grid-row"},[_c('div',{staticClass:"section"},[_c(_setup.BaseText,{staticClass:"heading-text",attrs:{"variant":"subtitle1"}},[_vm._v("Background")]),_c('div',{staticClass:"section-content-row"},[_c('div',{staticClass:"section-content-row-item"},[_c(_setup.BaseText,[_vm._v("Color")]),_c(_setup.ColorPicker,{staticClass:"styled-color-picker",attrs:{"value":_setup.background.color},on:{"input":(hex) => {
                _setup.background.color = hex;
                _setup.handleUpdate();
              }}})],1)])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }