<template>
  <vue-draggable-resizable
    v-if="!hideParent"
    :parent="true"
    :x="position.left"
    :y="position.top"
    :w="position.width"
    :h="position.height"
    :draggable="!isSavingTemplate && !isWidgetLocked && !isWidgetLockedByOverride"
    :resizable="!isSavingTemplate && !isWidgetLocked && !isWidgetLockedByOverride"
    :grid="gridScale"
    :snap="isSnapActive"
    :active="isWidgetSelected"
    :z="(isWidgetSelected && keepZIndexUp ? 1000 : 100) + widget.position.zIndex"
    :onDragStart="(x, y) => onChangePosition(true, x, y)"
    :onResizeStart="() => onChangePosition(false)"
    @dragstop="onWidgetDrag"
    @resizestop="onWidgetResize"
    @activated="() => onSelected(widget)"
    @deactivated="() => (keepZIndexUp = false)"
    @refLineParams="getRefLineParams"
  >
    <div class="widget-item layer scrollbar" :id="widget.object.wid" :style="style">
      <div v-if="widget.object.useLink" class="layer-link" :style="{ zoom: templateScaleFactor }">
        <i class="material-icons-outlined align-middle">language</i>
        Website Link
      </div>
    </div>
  </vue-draggable-resizable>
</template>

<script>
  import VueDraggableResizable from '@/components/common/VueDraggableResizable.vue';

  import draggableWidget from '@/components/templates/mixins/draggableWidget';

  import RssViewer from '@/components/rssViewer/Index';
  import TableAppWebView from '@/components/tableApp/TableAppWebView';

  import { simpleTypeMixin } from '@/helpers';
  import { getRBGAColor } from '@/helpers/utils';

  export default {
    name: 'LayerWidget',

    components: {
      RssViewer,
      TableAppWebView,
      VueDraggableResizable,
    },

    mixins: [simpleTypeMixin, draggableWidget],

    props: {
      widget: {
        type: Object,
        required: true,
      },
    },

    computed: {
      style() {
        return {
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          backgroundColor: getRBGAColor(
            this.widget.object.backgroundColor,
            this.widget.object.opacity / 100,
          ),
        };
      },

      position() {
        return this.getPosition(this.widget);
      },
    },
  };
</script>

<style lang="scss" scoped>
  #layerIframe {
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  .layer {
    overflow: hidden;
  }

  .layer-link {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: $secondaryText;
    font-size: 32px;

    i {
      font-size: 64px;
    }
  }
</style>
