<template>
  <RichTextEditorModal @saveClicked="handleSave">
    <div v-if="editor" class="rich-text-editor" :style="style">
      <div class="toolbar">
        <div class="toolbar-group">
          <div class="select-container">
            <button
              class="select-button"
              ref="fontFamilyButton"
              @click="showFontFamily(!toolbar.fontFamily.isActive)"
            >
              <BaseText :style="{ fontFamily: toolbar.fontFamily.selectedValue }">{{
                toolbar.fontFamily.selectedValue
              }}</BaseText>
              <i class="material-icons">keyboard_arrow_down</i>
            </button>
            <transition name="fade-top">
              <OnClickOutside
                class="select-list scrollbar"
                v-if="toolbar.fontFamily.isActive"
                :options="{ ignore: [$refs.fontFamilyButton] }"
                @trigger="
                  () => {
                    setFontFamily(toolbar.fontFamily.selectedValue);
                    showFontFamily(false);
                  }
                "
              >
                <div class="fontfamily-section">
                  <BaseText class="fontfamlily-header" variant="caption1">Default</BaseText>
                  <div
                    class="select-list-item"
                    v-for="v in toolbar.fontFamily.defaultValues"
                    :key="v"
                    @click="
                      () => {
                        setFontFamily(v);
                        showFontFamily(false);
                      }
                    "
                  >
                    <BaseText :style="{ fontFamily: v }">{{ v }}</BaseText>
                  </div>
                  <BaseDivider />
                </div>

                <div class="fontfamily-section">
                  <BaseText class="fontfamlily-header" variant="caption1">Font</BaseText>
                  <div
                    class="select-list-item"
                    v-for="font in userFonts"
                    :key="font.id"
                    @click="
                      () => {
                        setFontFamily(font.name);
                        showFontFamily(false);
                      }
                    "
                  >
                    <BaseText :style="{ fontFamily: font.name }">{{ font.name }}</BaseText>
                  </div>
                </div>
              </OnClickOutside>
            </transition>
          </div>
          <div class="select-container">
            <NumberInput
              ref="textSizeInput"
              title="Font size"
              :min="toolbar.textSize.min"
              :max="toolbar.textSize.max"
              v-model="toolbar.textSize.selectedValue"
              @click="
                () => {
                  if (toolbar.textSize.isActive === true) {
                    setTextSize(toolbar.textSize.selectedValue);
                  }

                  showTextSize(!toolbar.textSize.isActive);
                }
              "
              @increase="setTextSize(toolbar.textSize.selectedValue)"
              @decrease="setTextSize(toolbar.textSize.selectedValue)"
            />
            <transition name="fade-top">
              <OnClickOutside
                class="select-list scrollbar"
                :style="{ left: '32px' }"
                v-if="toolbar.textSize.isActive"
                :options="{ ignore: [$refs.textSizeInput] }"
                @trigger="
                  () => {
                    setTextSize(toolbar.textSize.selectedValue);
                    showTextSize(false);
                  }
                "
              >
                <div
                  class="select-list-item"
                  v-for="v in toolbar.textSize.values"
                  :key="v"
                  @click="
                    () => {
                      setTextSize(v);
                      showTextSize(false);
                    }
                  "
                >
                  <BaseText> {{ v }} </BaseText>
                </div>
              </OnClickOutside>
            </transition>
          </div>
          <div class="select-container">
            <button
              class="styled-button"
              ref="colorPickerButton"
              title="Text color"
              @click="showTextColor(!toolbar.textColor.isActive)"
            >
              <BaseText
                class="text-color-format-icon"
                :style="{ borderBottomColor: toolbar.textColor.selectedValue }"
                variant="h6"
                >A
              </BaseText>
            </button>
            <transition name="fade-top">
              <OnClickOutside
                class="color-picker-popover"
                v-if="toolbar.textColor.isActive"
                :options="{ ignore: [$refs.colorPickerButton] }"
                @trigger="showTextColor(false)"
              >
                <Sketch
                  :disableAlpha="true"
                  :value="toolbar.textColor.selectedValue"
                  @input="
                    (color) => {
                      setTextColor(color.hex);
                    }
                  "
                />
              </OnClickOutside>
            </transition>
          </div>
        </div>
        <div class="toolbar-group">
          <!-- Bold -->
          <button
            class="typography-button"
            :class="[{ active: isBold }]"
            title="Bold"
            @click="setBold(!isBold)"
          >
            <i class="material-icons">format_bold</i>
          </button>
          <!-- Italic -->
          <button
            class="typography-button"
            :class="[{ active: isItalic }]"
            title="Italic"
            @click="setItalic(!isItalic)"
          >
            <i class="material-icons">format_italic</i>
          </button>
          <!-- Underline -->
          <button
            class="typography-button"
            :class="[{ active: isUnderline }]"
            title="Underline"
            @click="setUnderline(!isUnderline)"
          >
            <i class="material-icons">format_underlined</i>
          </button>
        </div>
        <div class="toolbar-group">
          <div class="select-container">
            <button
              class="styled-button"
              ref="alignButton"
              title="Alignment"
              @click="showAlign(!toolbar.align.isActive)"
            >
              <i class="material-icons">{{ textAlignIcon }}</i>
            </button>
            <transition name="fade-top">
              <OnClickOutside
                class="select-list scrollbar"
                v-if="toolbar.align.isActive"
                :options="{ ignore: [$refs.alignButton] }"
                @trigger="showAlign(false)"
              >
                <div
                  class="select-list-item"
                  v-for="v in toolbar.align.values"
                  @click="setTextAlign(v.id)"
                >
                  <i class="material-icons">{{ v.icon }}</i>
                </div>
              </OnClickOutside>
            </transition>
          </div>
          <div class="select-container">
            <button
              class="styled-button"
              ref="spacingButton"
              title="Spacing"
              @click="showTextSpacing(!toolbar.textSpacing.isActive)"
            >
              <span class="material-symbols-outlined"> format_line_spacing </span>
            </button>
            <transition name="fade-top">
              <OnClickOutside
                class="select-list scrollbar"
                v-if="toolbar.textSpacing.isActive"
                :options="{ ignore: [$refs.spacingButton] }"
                @trigger="showTextSpacing(false)"
              >
                <div class="text-spacing-item">
                  <BaseText>Letter spacing</BaseText>
                  <NumberInput
                    :min="toolbar.letterSpacing.min"
                    :max="toolbar.letterSpacing.max"
                    :step="1"
                    v-model="toolbar.letterSpacing.selectedValue"
                    @increase="setLetterSpacing(toolbar.letterSpacing.selectedValue)"
                    @decrease="setLetterSpacing(toolbar.letterSpacing.selectedValue)"
                  />
                </div>
                <div class="text-spacing-item">
                  <BaseText>Line Height</BaseText>
                  <NumberInput
                    :min="toolbar.lineHeight.min"
                    :max="toolbar.lineHeight.max"
                    :step="0.01"
                    v-model="toolbar.lineHeight.selectedValue"
                    @increase="setLineHeight(toolbar.lineHeight.selectedValue)"
                    @decrease="setLineHeight(toolbar.lineHeight.selectedValue)"
                  />
                </div>
              </OnClickOutside>
            </transition>
          </div>
        </div>
        <div class="toolbar-group">
          <button class="styled-button" title="Clear text format" @click="clearTextFormat">
            <span class="material-symbols-outlined"> format_clear </span>
          </button>
        </div>
        <div class="toolbar-group">
          <div class="toggle-button-wrapper">
            <div class="toggle-button" title="Change theme" @click="toggleDarkTheme">
              <span class="material-symbols-outlined toggle-icon">
                {{ darkTheme ? 'light_mode' : 'dark_mode' }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <EditorContent
        :editor="editor"
        class="editor-content content-styles scrollbar"
        :class="[{ isEditing: true, 'dark-theme': darkTheme }]"
        @keydown.native="onEnterKeyDown"
      />
    </div>
  </RichTextEditorModal>
</template>

<script>
  import { EditorContent } from '@tiptap/vue-2';
  import { BaseText } from '@ui/atoms/baseText';
  import { BaseDivider } from '@ui/atoms/baseDivider';
  import { Sketch } from 'vue-color';
  import { OnClickOutside } from '@vueuse/components';
  import { NumberInput } from '@/components/common/toolbar';
  import { RICH_TEXT } from '@/constant/richText.ts';
  import {
    setFontFamily,
    setTextSize,
    setTextColor,
    setLetterSpacing,
    setLineHeight,
    tryGetFontFamily,
    tryGetTextSize,
    tryGetTextColor,
    tryGetLetterSpacing,
    tryGetLineHeight,
    hasCompleteAttributes,
    isTextStyle,
    isActive,
    createEditor,
    clearTextFormat,
    isEmptyParentNode,
    getParentNode,
    clearNodeFormat,
    setBold,
    setItalic,
    setUnderline,
  } from '@/helpers/tiptap.ts';
  import { preProcessRichTextContentWithBaseTextSize } from '@/helpers/richText';
  import playerMixins from '@/models/player';
  import draggableWidget from '@/components/layoutDesigner/mixins/draggableWidget';
  import { simpleTypeMixin } from '@/helpers';
  import { REFRESH_LAYOUT_STATUS } from '@/config/constants';
  import _ from 'lodash';
  import RichTextEditorModal from '@/components/screens/screen/richTextEditor/RichTextEditorModal.vue';

  export default {
    name: 'RichTextEditor',

    mixins: [playerMixins, simpleTypeMixin, draggableWidget],
    emits: ['save'],
    components: {
      RichTextEditorModal,
      EditorContent,
      BaseText,
      BaseDivider,
      Sketch,
      OnClickOutside,
      NumberInput,
    },

    props: {
      widget: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        RICH_TEXT,
        darkTheme: false,
        editor: null,
        toolbar: {
          textColor: {
            isActive: false,
            selectedValue: RICH_TEXT.DefaultFontColor,
          },
          fontFamily: {
            isActive: false,
            selectedValue: RICH_TEXT.DefaultFontFamily,
            defaultValues: [RICH_TEXT.DefaultFontFamily],
          },
          textSize: {
            isActive: false,
            selectedValue: RICH_TEXT.DefaultFontSize, // px
            min: 1,
            max: 1000,
            values: [
              8, 10, 12, 14, 16, 18, 21, 24, 28, 32, 36, 42, 48, 56, 64, 72, 80, 88, 96, 104, 120,
              144,
            ],
          },
          textSpacing: {
            isActive: false,
          },
          letterSpacing: {
            selectedValue: RICH_TEXT.DefaultLetterSpacing, // %
            min: -0.2,
            max: 1000,
          },
          lineHeight: {
            selectedValue: RICH_TEXT.DefaultLineHeight,
            min: 0,
            max: 1000,
          },
          align: {
            isActive: false,
            values: [
              { id: 'left', icon: 'format_align_left' },
              { id: 'center', icon: 'format_align_center' },
              { id: 'right', icon: 'format_align_right' },
            ],
          },
        },
      };
    },

    async mounted() {
      let content = this.$props.widget.object.userText;

      // handle base text size migration
      if (this.widget.object.textSize !== -1) {
        const processedContent = preProcessRichTextContentWithBaseTextSize(
          content,
          this.widget.object.textSize,
        );

        if (processedContent) {
          content = processedContent;
        }
      }

      this.editor = createEditor({
        content: content,
        onUpdate: (_) => {
          this.handleNonEmptyNode();
        },
      });
    },

    beforeDestroy() {
      this.editor.destroy();
    },

    computed: {
      // Toolbar
      widgetsByZIndex() {
        return this.$store.getters.getWidgetsByZIndex;
      },

      widgetCurrentIndex() {
        return this.widgetsByZIndex.findIndex((widget) => this.widget.assoc_id === widget.assoc_id);
      },

      isMoveUpDisabled() {
        return this.widgetCurrentIndex === this.widgetsByZIndex.length - 1;
      },

      isMoveDownDisabled() {
        return this.widgetCurrentIndex === 0;
      },

      style() {
        return {
          width: '100%',
          height: '100%',
          lineHeight: 1,
          borderRadius: `${this.widget.object.borderRadius}px`,
          zIndex: 100 + this.widget.position.zIndex,
        };
      },

      position() {
        return this.getPosition(this.widget);
      },

      isMoving() {
        if (this.isWidgetSelected) {
          return (
            this.$store.state.layoutDesigner.refreshingLayoutStatus === REFRESH_LAYOUT_STATUS.MOVING
          );
        }
        return false;
      },

      getSelectedAlignment() {
        if (!this.editor) return 'format_align_left';
      },

      isBold() {
        return this.editor.isActive('bold');
      },

      isItalic() {
        return this.editor.isActive('italic');
      },

      isUnderline() {
        return this.editor.isActive('underline');
      },

      textAlignIcon() {
        if (!this.editor) return 'format_align_left';
        if (this.editor.isActive({ textAlign: 'left' })) {
          return 'format_align_left';
        }
        if (this.editor.isActive({ textAlign: 'center' })) {
          return 'format_align_center';
        }
        if (this.editor.isActive({ textAlign: 'right' })) {
          return 'format_align_right';
        }
        return 'format_align_left';
      },

      userFonts() {
        return this.$store.getters.userFonts;
      },

      currentCursorStyling() {
        let textStyleOrDefault = null;

        if (isActive(this.editor)) {
          let typeOrName = 'textStyle';
          if (isEmptyParentNode(this.editor)) {
            typeOrName = getParentNode(this.editor).name;
          }

          textStyleOrDefault = {
            fontFamily:
              tryGetFontFamily(this.editor, typeOrName) ?? this.toolbar.fontFamily.selectedValue,
            textSize:
              tryGetTextSize(this.editor, typeOrName) ?? this.toolbar.textSize.selectedValue,
            textColor:
              tryGetTextColor(this.editor, typeOrName) ?? this.toolbar.textColor.selectedValue,
            letterSpacing:
              tryGetLetterSpacing(this.editor, typeOrName) ??
              this.toolbar.letterSpacing.selectedValue,
            lineHeight:
              tryGetLineHeight(this.editor, typeOrName) ?? this.toolbar.lineHeight.selectedValue,
          };
        }

        return textStyleOrDefault;
      },
    },

    watch: {
      isMoving(val) {
        this.editor.setEditable(val);
      },

      currentCursorStyling(newTextStyle, oldTextStyle) {
        const shouldUpdateTextStyle =
          isTextStyle(this.editor) &&
          (!_.isEqual(newTextStyle, oldTextStyle) || !hasCompleteAttributes(this.editor));

        // explicitly apply text style when not already present such on new paragraph
        const addTextStyle = !isTextStyle(this.editor);

        if (shouldUpdateTextStyle || addTextStyle) {
          this.runTextStyleCommands(newTextStyle);
        }
      },
    },

    methods: {
      handleSave() {
        this.$emit('save');
      },

      toggleEditMode() {
        if (this.isEditMode) {
          this.widget.object.userText = this.editor.getHTML();
        }

        this.isEditMode = !this.isEditMode;
      },

      toggleDarkTheme() {
        this.darkTheme = !this.darkTheme;
      },

      showFontFamily(value) {
        this.toolbar.fontFamily.isActive = value;
      },

      showTextSize(value) {
        this.toolbar.textSize.isActive = value;
      },

      showTextSpacing(value) {
        const onInactivate = !this.toolbar.textSpacing.isActive;

        if (onInactivate) {
          this.setLetterSpacing(this.toolbar.letterSpacing.selectedValue);
          this.setLineHeight(this.toolbar.lineHeight.selectedValue);
        }

        this.toolbar.textSpacing.isActive = value;
      },

      showAlign(value) {
        this.toolbar.align.isActive = value;
      },

      showTextColor(value) {
        const onInactivate = !this.toolbar.textColor.isActive;

        if (onInactivate) {
          this.toolbar.textColor.selectedValue =
            tryGetTextColor(this.editor) ?? RICH_TEXT.DefaultFontColor;
        }

        this.toolbar.textColor.isActive = value;
      },

      setTextAlign(align) {
        this.editor.chain().focus().setTextAlign(align).run();
        this.toolbar.align.isActive = false;
      },

      setTextColor(hex) {
        setTextColor(this.editor, hex);
        this.toolbar.textColor.selectedValue = hex;
      },

      setTextSize(pixelSize) {
        setTextSize(this.editor, pixelSize);
        this.toolbar.textSize.selectedValue = pixelSize;
      },

      setLetterSpacing(letterSpacing) {
        setLetterSpacing(this.editor, letterSpacing / 100);
        this.toolbar.letterSpacing.selectedValue = letterSpacing;
      },

      setLineHeight(lineHeight) {
        setLineHeight(this.editor, lineHeight);
        this.toolbar.lineHeight.selectedValue = lineHeight;
      },

      setFontFamily(font) {
        setFontFamily(this.editor, font);
        this.toolbar.fontFamily.selectedValue = font;
      },

      setBold(value) {
        setBold(this.editor, value);
      },

      setItalic(value) {
        setItalic(this.editor, value);
      },

      setUnderline(value) {
        setUnderline(this.editor, value);
      },

      clearTextFormat() {
        clearTextFormat(this.editor);
        this.toolbar.fontFamily.selectedValue = RICH_TEXT.DefaultFontFamily;
        this.toolbar.textColor.selectedValue = RICH_TEXT.DefaultFontColor;
        this.toolbar.textSize.selectedValue = RICH_TEXT.DefaultFontSize;
        this.toolbar.letterSpacing.selectedValue = RICH_TEXT.DefaultLetterSpacing;
        this.toolbar.lineHeight.selectedValue = RICH_TEXT.DefaultLineHeight;
      },

      runTextStyleCommands(newTextStyle) {
        if (newTextStyle) {
          this.setFontFamily(newTextStyle.fontFamily);
          this.setTextSize(newTextStyle.textSize);
          this.setTextColor(newTextStyle.textColor);
          this.setLetterSpacing(newTextStyle.letterSpacing);
          this.setLineHeight(newTextStyle.lineHeight);
        }
      },

      onEnterKeyDown(event) {
        if (!event.shiftKey && event.key === 'Enter') {
          this.handleNewNode();
        }
      },

      handleNewNode() {
        const parentNode = getParentNode(this.editor);

        if (
          parentNode &&
          isEmptyParentNode(this.editor) &&
          (tryGetTextSize(this.editor, parentNode.type) === null ||
            tryGetLineHeight(this.editor, parentNode.type) === null ||
            tryGetLetterSpacing(this.editor, parentNode.type) === null ||
            tryGetFontFamily(this.editor, parentNode.type) === null ||
            tryGetTextColor(this.editor, parentNode.type) === null)
        ) {
          this.editor
            .chain()
            .focus()
            .updateAttributes(parentNode.type, {
              fontSize: `${this.toolbar.textSize.selectedValue}px`,
              lineHeight: `${this.toolbar.lineHeight.selectedValue}`,
              letterSpacing: `${this.toolbar.letterSpacing.selectedValue / 100}`,
              fontFamily: this.toolbar.fontFamily.selectedValue,
              color: this.toolbar.textColor.selectedValue,
            })
            .run();
        }
      },

      handleNonEmptyNode() {
        const parentNode = getParentNode(this.editor);
        // clear empty node "empty line" styling
        if (
          !isEmptyParentNode(this.editor) &&
          (tryGetTextSize(this.editor, parentNode.type) !== null ||
            tryGetLineHeight(this.editor, parentNode.type) !== null ||
            tryGetLetterSpacing(this.editor, parentNode.type) !== null ||
            tryGetFontFamily(this.editor, parentNode.type) !== null ||
            tryGetTextColor(this.editor, parentNode.type) !== null)
        ) {
          clearNodeFormat(this.editor, parentNode.type);
        }
      },

      getRichText() {
        if (!this.editor) return;

        return this.editor.getHTML();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .styled-button {
    display: flex;
    padding: 0;
    width: 32px;
    height: 32px;
    max-width: 32px;
    max-height: 32px;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 1px solid transparent;
    background-color: white;
    transition: all 0.2s ease-in-out;

    &:hover {
      background: #f1f1f1;
    }

    &:active {
      background-color: $borderGrey;
    }
  }

  .typography-button {
    @extend .styled-button;

    &.active {
      color: $primaryRed;
    }
  }

  .font-size-button {
    @extend .styled-button;
    min-width: 36px;
    padding: 2px;
    border-radius: 0;
    cursor: text;
  }

  .text-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .select-button {
    @extend .styled-button;
    padding: 4px;
    justify-content: space-between;
    min-width: 160px;
    max-width: 160px;
    box-shadow: 0 0 0 1px $borderGrey2;

    > :first-child {
      @extend .text-ellipsis;
      float: left;
      max-width: 85%;
    }

    > :last-child {
      float: right;
      max-width: 15%;
    }
  }

  .fontfamily-section {
    display: flex;
    flex-direction: column;
  }

  .fontfamily-header {
    display: flex;
    padding: 0 16px;
    margin-bottom: 4px;
    cursor: default;
    font-weight: bold;
  }

  .select-container {
    position: relative;

    .fade-top-enter-active,
    .fade-top-leave-active {
      transition: all 0.3s ease;
    }

    .fade-top-enter {
      opacity: 0;
      transform: translateY(-20px);
    }

    .fade-top-leave-to {
      opacity: 0;
      transform: translateY(20px);
    }
  }

  .select-list {
    z-index: 2100;
    position: absolute;
    top: 100%;
    left: 0;
    display: flex;
    flex-direction: column;
    max-height: 200px;
    overflow-y: auto;
    padding: 8px 0;
    border-radius: 4px;
    background-color: white;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  }

  .select-list-item {
    padding: 4px 16px;
    cursor: pointer;

    &:hover {
      background: #f1f1f1;
    }
  }

  .text-spacing-item {
    display: flex;
    flex-direction: column;
    padding: 4px 16px;
    row-gap: 4px;
  }

  .rich-text-editor {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
  }

  .toolbar {
    display: flex;
    flex-wrap: wrap;
    row-gap: 4px;
    padding: 8px 4px;
    box-shadow:
      0 2px 2px -2px rgba(34, 47, 62, 0.1),
      0 8px 8px -4px rgba(34, 47, 62, 0.07);

    > :not(:first-child) {
      border-left: 1px solid $borderGrey;
      padding: 0 4px;
    }

    > :first-child {
      padding-right: 4px;
    }

    > :last-child {
      padding-right: 0;
    }
  }

  .toolbar-group {
    display: flex;
    gap: 4px;
  }

  .color-picker-popover {
    user-select: none;
    z-index: 2101;
    position: relative;

    // v deep
    ::v-deep {
      .vc-sketch {
        position: absolute;
        right: 0;
      }
    }
  }

  .text-color-format-icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    line-height: 1rem;
    padding: 0 4px;
    border-bottom: 5px solid;
  }

  .content-styles {
    ::v-deep {
      /* Headings */
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-family: inherit;
        white-space: pre;
        margin-bottom: 0.1rem;

        &:empty:before {
          content: ' ';
          white-space: pre;
        }
      }

      h1 {
        font-size: 2em;
      }

      h2 {
        font-size: 1.5em;
      }

      h3 {
        font-size: 1.17em;
      }

      h4 {
        font-size: 1em;
      }

      h5 {
        font-size: 0.83em;
      }

      h6 {
        font-size: 0.67em;
      }

      /* Other Text Styling */
      p {
        margin-bottom: 0;
        line-height: 1.2em;
        white-space: pre;

        &:empty:before {
          content: ' ';
          white-space: pre;
        }
      }

      strong {
        font-weight: bold;
      }

      em {
        font-style: italic;
      }

      u {
        text-decoration: underline;
      }

      /* Lists */
      ul,
      ol {
        margin-bottom: 1em;
        padding-left: 2em;
      }

      li {
        margin: 0.5em 0;
      }
    }
  }

  .editor-content {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 24px 8px 16px 8px;
    overflow: auto;

    &.isEditing {
      cursor: auto;
    }

    ::v-deep .ProseMirror {
      white-space: normal;
      width: 100%;
      height: 100%;

      // Set basic styles for elements
      &:focus {
        outline: none;
      }
    }

    ::v-deep .ProseMirror h1,
    ::v-deep .ProseMirror h2,
    ::v-deep .ProseMirror h3,
    ::v-deep .ProseMirror h4,
    ::v-deep .ProseMirror h5,
    ::v-deep .ProseMirror h6,
    ::v-deep .ProseMirror p {
      text-wrap: wrap;
    }
  }

  .toggle-button-wrapper {
    display: flex;
    justify-content: flex-end;
    padding: 4px 8px;
  }

  .toggle-button {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;

    > .toggle-icon {
      width: 24px;
      height: 24px;
      color: $secondaryText;
    }

    &:hover .toggle-icon {
      color: $primaryText;
    }
  }

  .dark-theme {
    background-color: $secondaryText;

    ::v-deep .ProseMirror {
      background-color: $secondaryText;
    }
  }
</style>
