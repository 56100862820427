<template>
  <div class="default-card card">
    <div class="hovereffect">
      <img :src="item.img" class="card-img-top"
           :alt="item.name" :width="THUMBNAIL_WIDTH" :height="THUMBNAIL_HEIGHT">

      <div class="overlay"></div>
    </div>

    <div class="card-body">
      <h5 class="card-title text-truncate">{{ item.name }}</h5>
      <p class="card-text text-secondary">
        <em>{{ desc }}</em>
      </p>
    </div>
  </div>
</template>

<script>
import { THUMBNAIL_WIDTH, THUMBNAIL_HEIGHT } from "../../config/constants";

export default {
  name:  'DefaultCard',
  props: {
    item:     {
      type:     Object,
      required: true
    },
    itemDesc: {
      required: false,
      type:     String,
      default:  'App'
    }
  },

  data () {
    return {
      THUMBNAIL_WIDTH,
      THUMBNAIL_HEIGHT
    };
  },

  computed: {
    desc () {
      return this.item.desc || this.itemDesc;
    }
  }
};
</script>

<style lang="scss" scoped>
.default-card {
  background-color: #efefef;

  .card-img-top {
    height: 100%;
  }
}
</style>
