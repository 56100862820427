<template>
  <vue-draggable-resizable
    v-if="!hideParent"
    :parent="true"
    :x="position.left"
    :y="position.top"
    :w="position.width"
    :h="position.height"
    :draggable="!isSavingTemplate && !isWidgetLocked && !isWidgetLockedByOverride"
    :resizable="!isSavingTemplate && !isWidgetLocked && !isWidgetLockedByOverride"
    :grid="gridScale"
    :snap="isSnapActive"
    :active="isWidgetSelected && !widget.isDummyData"
    :z="(isWidgetSelected && keepZIndexUp ? 1000 : 100) + widget.position.zIndex"
    :onDragStart="(x, y) => onChangePosition(true, x, y)"
    :onResizeStart="() => onChangePosition(false)"
    @dragstop="onWidgetDrag"
    @resizestop="onWidgetResize"
    @activated="() => onSelected(widget)"
    @deactivated="() => (keepZIndexUp = false)"
    @refLineParams="getRefLineParams"
  >
    <div
      class="widget-item button-widget"
      :id="widget.object.wid"
      :title="widget.object.captionText"
      :style="widget.object.imageAsButton ? style : buttonStyle"
    >
      <img
        v-if="widget.object.imageAsButton && widget.object.imageUrl"
        :style="imageStyle"
        :src="widget.object.imageUrl"
      />
      <span v-else-if="!widget.object.imageAsButton" class="button-text">{{
        widget.object.text
      }}</span>
    </div>
  </vue-draggable-resizable>
</template>

<script>
  import VueDraggableResizable from '@/components/common/VueDraggableResizable.vue';

  import draggableWidget from '@/components/templates/mixins/draggableWidget';

  import { simpleTypeMixin } from '@/helpers';
  import { getRBGAColor } from '@/helpers/utils';

  export default {
    name: 'ButtonWidget',

    components: {
      VueDraggableResizable,
    },

    mixins: [simpleTypeMixin, draggableWidget],

    props: {
      widget: {
        type: Object,
        required: true,
      },
    },

    computed: {
      buttonStyle() {
        const button = this.widget.object;

        const embosseStyle = 'linear-gradient(#fff6 5%, #0003 100%)';
        const embosseTextStyle = '1px 1px 3px #666666';
        const borderStyle = `${this.scaleTemplateValue(button.borderWidth)}px solid ${getRBGAColor(
          button.borderColor,
        )}`;
        const shadowStyle = `${button.shadowBlur / 2}px ${button.shadowBlur / 2}px ${
          button.shadowBlur
        }px 0 ${getRBGAColor(button.shadowColor, button.shadowOpacity / 100)}`;

        return {
          backgroundColor: getRBGAColor(button.backgroundColor),
          border: button.displayBorder ? borderStyle : 'none',
          borderRadius: `${this.scaleTemplateValue(button.borderRadius)}px`,
          boxShadow: button.displayShadow ? shadowStyle : 'none',
          color: getRBGAColor(button.textColor),
          fontFamily: button.textFont,
          fontWeight: button.fontWeight,
          fontSize: `${this.scaleTemplateValue(button.textSize)}px`,
          overflow: 'hidden',
          textShadow: button.embossed ? embosseTextStyle : 'none',
          backgroundImage: button.embossed ? embosseStyle : 'none',
          zIndex: 100 + this.widget.position.zIndex,
        };
      },

      style() {
        return {
          zIndex: 100 + this.widget.position.zIndex,
        };
      },

      imageStyle() {
        return {
          objectFit: this.widget.object.imageMode,
          height: '100%',
          width: '100%',
        };
      },

      position() {
        return this.getPosition(this.widget);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .button-text {
    white-space: -moz-nowrap; /* Firefox */
    white-space: -o-nowrap; /* Opera */
    white-space: nowrap; /* Chrome */
    word-wrap: break-word; /* IE */

    font-weight: inherit;
    font-family: inherit;
  }

  .button-widget {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
