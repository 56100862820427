<template>
  <Transition name="tip">
    <div class="tip" v-show="visible">
      <div class="tip-header">
        <span class="material-icons-outlined align-middle icon">info</span>
        <span class="title">{{ title }}</span>
        <span
          class="material-icons-outlined align-middle ml-auto icon close"
          @click="() => (visible = false)"
          >close</span
        >
      </div>
      <div class="tip-body">
        <slot />
      </div>
    </div>
  </Transition>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        visible: true,
      };
    },
  };
</script>

<style lang="scss" scoped>
  .tip-enter-active,
  .tip-leave-active {
    transition: opacity 0.3s;
  }

  .tip-enter,
  .tip-leave-to {
    opacity: 0;
  }

  .tip {
    background-color: white;
    padding: 16px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 6px;

    .tip-header {
      display: flex;
      align-items: center;
      gap: 6px;

      .icon {
        font-size: 16px;
        color: #323232;
        opacity: 1 !important;

        &.close {
          font-size: 20px;
          cursor: pointer;
          &:hover {
            color: #e3003a;
          }
        }
      }

      .title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #333332;
      }
    }

    .tip-body {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #6a6b6a;
    }
  }
</style>
