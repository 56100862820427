<template>
  <div>
    <div class="d-flex justify-content-between">
      <span>{{ startTime }}</span> <span>{{ endTime }}</span>
    </div>
    <div ref="slider"></div>
  </div>
</template>
<script>
  import noUiSlider from 'nouislider';
  import 'nouislider/dist/nouislider.css';
  import moment from 'moment';

  const minutesInDay = 24 * 60;

  export default {
    name: 'TimeSlider',
    props: {
      value: {
        type: Array,
        default: () => [0, minutesInDay],
      },
    },

    emits: ['update'],

    computed: {
      startTime() {
        return moment().startOf('day').add(this.value[0], 'minutes').format('hh:mm a');
      },
      endTime() {
        return moment().startOf('day').add(this.value[1], 'minutes').format('hh:mm a');
      },
    },

    mounted() {
      const { slider } = this.$refs;
      noUiSlider.create(slider, {
        start: [this.value[0], this.value[1]],
        connect: true,
        step: 5,
        range: {
          min: 0,
          max: minutesInDay,
        },
      });

      slider.noUiSlider.on('update', (val) => {
        const [start, end] = val;
        this.$emit('input', [start, end]);
      });
    },
  };
</script>

<style lang="scss">
  .noUi-target {
    height: 5px;
    background: #dddddd;
    border: none;
    margin: 0.25rem 0;
  }
  .noUi-connects .noUi-connect {
    background: $primaryRed !important;
  }
  .noUi-handle {
    width: 20px !important;
    height: 20px !important;
    border-radius: 50% !important;
    background: #fff !important;
    box-shadow: 0 0px 3px 1px rgb(0 0 0 / 20%) !important;
    top: -7px !important;
    cursor: grab !important;
    transition: all 0.2s ease-in-out !important;
    &:before,
    &:after {
      display: none;
    }

    &.noUi-handle-upper {
      right: -0px !important;
    }

    &.noUi-handle-lower {
      right: -20px !important;
    }

    &.noUi-active {
      cursor: grabbing !important;
      background: $primaryRed !important;
    }
  }
</style>
