<template>
  <SidebarContainer>
    <div class="d-flex flex-column justify-content-center align-items-center h-100 px-1">
      <div class="icon">
        <span class="material-icons-outlined align-middle">photo_size_select_large</span>
      </div>
      <p class="font-whyte-ink title text-center mb-1">Create & Manage Templates</p>
      <p class="font-poppins description text-center mb-4">
        You can create templates to use in your layouts and manage them.
      </p>
      <Button class="w-100" theme="primary" icon="add" @click="$emit('onCreate')">
        Create Template
      </Button>
    </div>
  </SidebarContainer>
</template>

<script>
  import Button from '@/components/common/Button.vue';
  import SidebarContainer from '@/components/common/SidebarContainer.vue';

  export default {
    name: 'TemplatesSidebarPlaceholder',

    components: { SidebarContainer, Button },
  };
</script>

<style lang="scss" scoped>
  .icon {
    margin-bottom: 20px;

    span {
      font-size: 64px;
      color: #e3003a;
    }
  }
  .title {
    font-size: 24px;
    color: #000;
    font-weight: 400;
    max-width: 266px;
  }
  .description {
    font-size: 16px;
    color: #6a6b6a;
    font-weight: 500;
    max-width: 322px;
  }
</style>
