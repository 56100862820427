import { RICH_TEXT } from '@/constant/richText';
import { EditorOptions, Editor } from '@tiptap/vue-2';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import StarterKit from '@tiptap/starter-kit';
import TextStyle from '@tiptap/extension-text-style';
import { Color } from '@tiptap/extension-color';
import { FontFamily } from '@tiptap/extension-font-family';
import FontSize from '@/helpers/tiptap/extensions/fontSize';
import LineHeight from '@/helpers/tiptap/extensions/lineHeight';
import LetterSpacing from '@/helpers/tiptap/extensions/letterSpacing';
import { MarkType, Node, NodeType } from '@tiptap/pm/model';
import { ExtendedFontFamily } from '@/helpers/tiptap/extensions/extendedFontFamily';
import { ExtendedColor } from '@/helpers/tiptap/extensions/extendedColor';

function stripCssUnits(inputString: string): string {
  const regexPattern = /(\d*\.?\d+)/;
  const match = regexPattern.exec(inputString);

  return match ? match[0] : '';
}

export function createEditor(options: EditorOptions): Editor {
  return new Editor({
    ...options,
    extensions: [
      StarterKit,
      TextStyle, // order matter and TextStyle should be after StarterKit
      Underline,
      Color.configure({
        types: ['textStyle'],
      }),
      TextAlign.configure({
        types: ['heading', 'paragraph', 'textStyle'],
      }),
      FontFamily.configure({
        types: ['textStyle'],
      }),
      FontSize.configure({
        types: ['textStyle', 'heading', 'paragraph'],
      }),
      LineHeight.configure({
        types: ['textStyle', 'heading', 'paragraph'],
      }),
      LetterSpacing.configure({
        types: ['textStyle'],
      }),
      ExtendedFontFamily.configure({
        types: ['textStyle', 'heading', 'paragraph'],
      }),
      ExtendedColor.configure({
        types: ['textStyle', 'heading', 'paragraph'],
      }),
    ],
    editable: true,
    editorProps: options?.editorProps ?? {
      attributes: {
        style: `font-family: ${RICH_TEXT.DefaultFontFamily}, sans-serif;`,
      },
    },
    parseOptions: {
      preserveWhitespace: 'full',
    },
  });
}

export function isTextStyle(editor: Editor): boolean {
  return editor?.isActive('textStyle') ? true : false;
}

export function isActive(editor: Editor): boolean {
  return (
    editor?.isFocused &&
    editor?.state.selection.empty && // we don't want to listen for drag events
    (editor?.isActive('paragraph') || editor?.isActive('textStyle') || editor?.isActive('heading'))
  );
}

export function isEmptyParentNode(editor: Editor): boolean {
  return (
    editor?.state?.selection?.$anchor.parent?.content === null ||
    editor?.state?.selection?.$anchor.parent?.content?.size === 0
  );
}

export function getParentNode(editor: Editor): Node | null {
  return editor?.state?.selection?.$anchor?.parent;
}

export function hasCompleteAttributes(editor: Editor): boolean {
  const attribute = editor?.getAttributes('textStyle');
  if (!attribute) return false;

  return (
    attribute?.fontFamily &&
    attribute?.color &&
    attribute?.fontSize &&
    attribute?.letterSpacing &&
    attribute?.lineHeight
  );
}

export function clearNodeFormat(editor: Editor, nameOrType: string | NodeType | MarkType) {
  if (!editor) return;

  editor
    .chain()
    .focus()
    .unsetNodeFontSize(nameOrType)
    .unsetNodeLineHeight(nameOrType)
    .unsetNodeLetterSpacing(nameOrType)
    .unsetNodeFontFamily(nameOrType)
    .unsetNodeColor(nameOrType)
    .run();
}

export function clearTextFormat(editor: Editor) {
  if (!editor) return;

  editor
    .chain()
    .focus()
    .unsetBold()
    .unsetItalic()
    .unsetUnderline()
    .unsetTextAlign()
    .clearNodes()
    .run();

  // explicitly apply default text style
  setFontFamily(editor, RICH_TEXT.DefaultFontFamily);
  setTextColor(editor, RICH_TEXT.DefaultFontColor);
  setTextSize(editor, RICH_TEXT.DefaultFontSize);
  setLetterSpacing(editor, RICH_TEXT.DefaultLetterSpacing);
  setLineHeight(editor, RICH_TEXT.DefaultLineHeight);
}

export function setFontFamily(editor: Editor, font: string) {
  if (!editor) return;

  editor.chain().focus().setFontFamily(font).run();
}

export function tryGetFontFamily(
  editor: Editor,
  nameOrType: string | NodeType | MarkType = 'textStyle',
): string | null {
  let font = null;
  if (!editor) return font;

  const attribute = editor.getAttributes(nameOrType);

  if (attribute?.fontFamily) {
    font = attribute.fontFamily;
  }

  return font;
}

export function setTextColor(editor: Editor, hex: string) {
  if (!editor) return;

  editor.chain().focus().setColor(hex).run();
}

export function tryGetTextColor(
  editor: Editor,
  nameOrType: string | NodeType | MarkType = 'textStyle',
): string | null {
  let color = null;
  if (!editor) return color;

  const attribute = editor.getAttributes(nameOrType);

  if (attribute?.color) {
    color = attribute.color;
  }

  return color;
}

export function setNodeTextSize(
  editor: Editor,
  typeOrName: string | NodeType | MarkType,
  pixelSize: number,
) {
  if (!editor) return;

  editor.chain().focus().setNodeFontSize(typeOrName, `${pixelSize}px`).run();
}

export function setTextSize(editor: Editor, pixelSize: number) {
  if (!editor) return;

  editor.chain().focus().setFontSize(`${pixelSize}px`).run();
}

export function tryGetTextSize(
  editor: Editor,
  nameOrType: string | NodeType | MarkType = 'textStyle',
): number | null {
  let fontSize = null;
  if (!editor) return fontSize;

  const attribute = editor.getAttributes(nameOrType);

  if (attribute?.fontSize) {
    const parsed = parseFloat(stripCssUnits(attribute.fontSize));
    fontSize = !isNaN(parsed) ? parsed : null;
  }

  return fontSize;
}

export function setLetterSpacing(editor: Editor, decimalPercentage: number) {
  if (!editor) return;

  editor.chain().focus().setLetterSpacing(`${decimalPercentage}em`).run();
}

export function tryGetLetterSpacing(
  editor: Editor,
  nameOrType: string | NodeType | MarkType = 'textStyle',
): number | null {
  let letterSpacing = null;
  if (!editor) return letterSpacing;

  const attribute = editor.getAttributes(nameOrType);

  if (attribute?.letterSpacing) {
    const parsed = parseFloat(stripCssUnits(attribute.letterSpacing));
    letterSpacing = !isNaN(parsed) ? parsed * 100 : null;
  }

  return letterSpacing;
}

export function setNodeLineHeight(
  editor: Editor,
  typeOrName: string | NodeType | MarkType,
  lineHeight: number,
) {
  if (!editor) return;

  editor.chain().focus().setNodeLineHeight(typeOrName, `${lineHeight}`).run();
}

export function setLineHeight(editor: Editor, lineHeight: number) {
  if (!editor) return;

  editor.chain().focus().setLineHeight(`${lineHeight}`).run();
}

export function tryGetLineHeight(
  editor: Editor,
  nameOrType: string | NodeType | MarkType = 'textStyle',
): number | null {
  let lineHeight = null;
  if (!editor) return lineHeight;

  const attribute = editor.getAttributes(nameOrType);

  if (attribute?.lineHeight) {
    const parsed = parseFloat(stripCssUnits(attribute.lineHeight));
    lineHeight = !isNaN(parsed) ? parsed : null;
  }

  return lineHeight;
}

export function setBold(editor: Editor, active: boolean) {
  if (!editor) return;

  active ? editor.chain().focus().setBold().run() : editor.chain().focus().unsetBold().run();
}

export function setItalic(editor: Editor, active: boolean) {
  if (!editor) return;

  active ? editor.chain().focus().setItalic().run() : editor.chain().focus().unsetItalic().run();
}

export function setUnderline(editor: Editor, active: boolean) {
  if (!editor) return;

  active
    ? editor.chain().focus().setUnderline().run()
    : editor.chain().focus().unsetUnderline().run();
}
