<script setup>
  import { ref, defineEmits, watchEffect } from 'vue';
  import { BaseText } from '@ui/atoms/baseText';

  const props = defineProps({
    value: {
      type: Number,
      default: 1,
    },
    max: {
      type: Number,
      default: Infinity,
    },
    min: {
      type: Number,
      default: -Infinity,
    },
    step: {
      type: Number,
      default: 1,
    },
  });

  const emits = defineEmits(['input', 'increase', 'decrease', 'click']);

  const inputElement = ref(null);
  const inputValue = ref(props.value);

  const setValue = (newValue) => {
    const parsedValue = parseFloat(newValue);

    if (!isNaN(parsedValue) && parsedValue >= props.min && parsedValue <= props.max) {
      const sanitizedValue = Number.isInteger(parsedValue)
        ? parsedValue
        : parseFloat(parsedValue.toPrecision(3));

      inputValue.value = sanitizedValue;

      // also update input element
      inputElement.value = sanitizedValue;
      emits('input', sanitizedValue);
    }

    // reset input element to clean invalid inputs
    if (parsedValue !== inputValue.value) {
      inputElement.value = inputValue.value;
    }
  };

  const increase = () => {
    setValue(inputValue.value + props.step);
    emits('increase');
  };

  const decrease = () => {
    setValue(inputValue.value - props.step);
    emits('decrease');
  };

  const handleMouseWheel = (event) => {
    event.preventDefault();
    event.stopImmediatePropagation();

    if (event.deltaY < 0) {
      increase();
    } else {
      decrease();
    }
  };

  watchEffect(() => setValue(props.value));
</script>

<template>
  <div class="number-input-container">
    <button class="left-button" @click.prevent="decrease">
      <span class="material-symbols-outlined"> remove </span>
    </button>
    <BaseText class="number-input-button">
      <input
        ref="inputElement"
        class="input-wrapper"
        v-bind="{ ...$props, ...$attrs }"
        :value="inputValue"
        @input="(event) => setValue(event.target.value)"
        @click.stop="(_event) => emits('click')"
        @wheel="handleMouseWheel"
      />
    </BaseText>
    <button class="right-button" @click.prevent="increase">
      <span class="material-symbols-outlined"> add </span>
    </button>
  </div>
</template>

<style lang="scss" scoped>
  .number-input-container {
    display: flex;
    flex-direction: row;
    width: fit-content;
    border-radius: 4px;
    border: 1px solid $borderGrey2;
  }

  .number-input-button {
    display: flex;
    padding: 0;
    height: 32px;
    max-height: 32px;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 1px solid transparent;
    outline-offset: -1px;

    &:hover {
      background: #f1f1f1;
    }

    &:active {
      background-color: $borderGrey;
    }
  }

  .left-button {
    @extend .number-input-button;
    width: 32px;
    max-width: 32px;
    background-color: white;
    border-radius: 4px 0 0 4px;
    border-right: 1px solid $borderGrey2;
  }

  .right-button {
    @extend .number-input-button;
    width: 32px;
    max-width: 32px;
    background-color: white;
    border-radius: 0 4px 4px 0;
    border-left: 1px solid $borderGrey2;
  }

  input {
    @extend .number-input-button;
    width: 36px;
    max-width: 36px;
    border-radius: 0;
    text-align: center;
  }
</style>
