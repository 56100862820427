<template>
  <div
    class="icon"
    :title="usePixels ? 'Values in pixels (px)' : 'Values in percentage (%)'"
    @click="$emit('clicked')"
  >
    {{ usePixels ? 'px' : '%' }}
  </div>
</template>

<script>
  export default {
    name: 'PixelIcon',

    props: {
      usePixels: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style></style>
