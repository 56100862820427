<!-- added this to see component reference count-->
<script lang="ts">
  export default {
    name: 'TrafiklabSettingPanel',
  };
</script>

<script setup lang="ts">
  import { useStore } from '@/store';
  import { computed, ref } from 'vue';
  import FontSelector from '@/components/common/fontSelector/FontSelector.vue';
  import BaseText from '@ui/atoms/baseText/BaseText.vue';
  import ColorPicker from '@/components/ColorPicker.vue';
  import {
    ViewerStyleBackground,
    ViewerStyleDeparture,
    ViewerStyleLine,
    ViewerStyleTitle,
  } from '@/types/widgets/trafiklabWidget/trafiklabWidget';
  import { debounce } from 'lodash';
  import { DEFAULT_TRAFIKLAB_VIEWER_STYLE } from '@/constant/trafiklabWidget';
  import BaseDivider from '@ui/atoms/baseDivider/BaseDivider.vue';
  import {
    getDefaultFontType,
    mergeTrafiklabViewerStyles,
  } from '@/helpers/widgets/trafiklabWidget/trafiklabWidget';

  const { viewerStyles } = defineProps({
    viewerStyles: { type: Object, required: true, default: {} },
    disabled: { type: Boolean, default: false },
  });

  const emit = defineEmits<{
    (event: 'update:viewerStyles', viewerStyles: any): void;
  }>();

  const store = useStore();

  const title = ref({
    fontType: getDefaultFontType(store.getters.userFonts, viewerStyles.title.fontType),
    fontColor: viewerStyles.title.fontColor,
    fontSize: viewerStyles.title?.fontSize ?? DEFAULT_TRAFIKLAB_VIEWER_STYLE.title.fontSize,
  } as ViewerStyleTitle);

  const content = ref({
    fontType: getDefaultFontType(store.getters.userFonts, viewerStyles.departures.fontType),
    fontColor: viewerStyles.departures.fontColor,
    fontSize:
      viewerStyles.departures?.fontSize ?? DEFAULT_TRAFIKLAB_VIEWER_STYLE.departures.fontSize,
  } as ViewerStyleDeparture);

  const line = ref({
    fontColor: viewerStyles?.line?.fontColor ?? DEFAULT_TRAFIKLAB_VIEWER_STYLE.line.fontColor,
    backgroundColor:
      viewerStyles?.line?.backgroundColor ?? DEFAULT_TRAFIKLAB_VIEWER_STYLE.line.backgroundColor,
  } as ViewerStyleLine);

  const background = ref({
    color: viewerStyles.background.color,
    transparency: viewerStyles.background.transparency,
  } as ViewerStyleBackground);

  const even = ref(viewerStyles.evenDeparturesBackground);
  const odd = ref(viewerStyles.oddDeparturesBackground);

  const fonts = computed(() => {
    return store.getters.userFonts;
  });

  function handleUpdate() {
    const _debounce = debounce(() => {
      const newViewerStyles = mergeTrafiklabViewerStyles(
        viewerStyles,
        title.value,
        content.value,
        line.value,
        background.value,
        even.value,
        odd.value,
      );

      emit('update:viewerStyles', newViewerStyles);
    }, 300);

    _debounce();
  }
</script>

<template>
  <div class="section-container">
    <div class="section">
      <BaseText variant="subtitle1" class="heading-text">Line Box</BaseText>
      <div class="section-content-row">
        <div class="section-content-row-item">
          <BaseText>Background Color</BaseText>
          <ColorPicker
            :value="line.backgroundColor"
            :disabled="disabled"
            @input="
              (hex) => {
                line.backgroundColor = hex;
                handleUpdate();
              }
            "
          />
        </div>
        <div class="section-content-row-item">
          <BaseText>Text Color</BaseText>
          <ColorPicker
            :value="line.fontColor"
            :disabled="disabled"
            @input="
              (hex) => {
                line.fontColor = hex;
                handleUpdate();
              }
            "
          />
        </div>
      </div>
    </div>
    <BaseDivider />
    <div class="section">
      <BaseText variant="subtitle1" class="heading-text">Title</BaseText>
      <div class="section-content">
        <BaseText>Font</BaseText>
        <FontSelector
          :fonts="fonts"
          :value="title.fontType"
          :disabled="disabled"
          @update:value="
            (value) => {
              title.fontType = value;
              handleUpdate();
            }
          "
        />
        <div class="section-content-row">
          <div class="section-content-row-item">
            <BaseText>Text Color</BaseText>
            <ColorPicker
              class="styled-color-picker"
              :value="title.fontColor"
              :disabled="disabled"
              @input="
                (hex) => {
                  title.fontColor = hex;
                  handleUpdate();
                }
              "
            />
          </div>
          <div class="section-content-row-item">
            <BaseText>Text Size</BaseText>
            <input
              class="form-control input"
              type="number"
              min="1"
              max="1000"
              :value="title.fontSize"
              :disabled="disabled"
              @input.prevent="
                (event) => {
                  title.fontSize = Math.round(Number(event.target.value));
                  handleUpdate();
                }
              "
            />
          </div>
        </div>
      </div>
    </div>
    <BaseDivider />
    <div class="section">
      <BaseText variant="subtitle1" class="heading-text">Content</BaseText>
      <div class="section-content">
        <BaseText>Font</BaseText>
        <FontSelector
          :fonts="fonts"
          :value="content.fontType"
          :disabled="disabled"
          @update:value="
            (value) => {
              content.fontType = value;
              handleUpdate();
            }
          "
        />
        <div class="section-content-row">
          <div class="section-content-row-item">
            <BaseText>Text Color</BaseText>
            <ColorPicker
              class="styled-color-picker"
              :value="content.fontColor"
              :disabled="disabled"
              @input="
                (hex) => {
                  content.fontColor = hex;
                  handleUpdate();
                }
              "
            />
          </div>
          <div class="section-content-row-item">
            <BaseText>Text Size</BaseText>
            <input
              class="form-control input"
              type="number"
              min="1"
              max="1000"
              :value="content.fontSize"
              :disabled="disabled"
              @input.prevent="
                (event) => {
                  content.fontSize = Math.round(Number(event.target.value));
                  handleUpdate();
                }
              "
            />
          </div>
        </div>
      </div>
    </div>
    <BaseDivider />
    <div class="section">
      <BaseText variant="subtitle1" class="heading-text">Departures Background</BaseText>
      <div class="section-content-row">
        <div class="section-content-row-item">
          <BaseText>Even Color</BaseText>
          <ColorPicker
            class="styled-color-picker"
            :value="even"
            :disabled="disabled"
            @input="
              (hex) => {
                even = hex;
                handleUpdate();
              }
            "
          />
        </div>
        <div class="section-content-row-item">
          <BaseText>Odd Color</BaseText>
          <ColorPicker
            class="styled-color-picker"
            :value="odd"
            :disabled="disabled"
            @input="
              (hex) => {
                odd = hex;
                handleUpdate();
              }
            "
          />
        </div>
      </div>
    </div>
    <BaseDivider />
    <div class="section">
      <BaseText variant="subtitle1" class="heading-text">Background</BaseText>
      <div class="section-content-row">
        <div class="section-content-row-item">
          <BaseText>Color</BaseText>
          <ColorPicker
            class="styled-color-picker"
            :value="background.color"
            :disabled="disabled"
            @input="
              (hex) => {
                background.color = hex;
                handleUpdate();
              }
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .input {
    height: 38px;
    border-radius: 8px;
    padding: 4px 8px;
    border: 2px solid transparent;
    box-shadow: 0 0 0 1px $borderGrey;
  }
  .styled-color-picker {
    margin-top: -2px;
  }

  .heading-text {
    font-weight: 500;
    margin-bottom: 4px;
  }

  .section-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .section {
    display: flex;
    flex-direction: column;
  }

  .section-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .section-content-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .section-content-row-item {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }
</style>
