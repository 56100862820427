<template>
  <vue-draggable-resizable
    v-if="!hideParent"
    :parent="true"
    :x="position.left"
    :y="position.top"
    :w="position.width"
    :h="position.height"
    :draggable="!isSavingTemplate && !isWidgetLocked && !isWidgetLockedByOverride"
    :resizable="!isSavingTemplate && !isWidgetLocked && !isWidgetLockedByOverride"
    :grid="gridScale"
    :snap="isSnapActive"
    :active="isWidgetSelected"
    :z="(isWidgetSelected && keepZIndexUp ? 1000 : 100) + widget.position.zIndex"
    :onDragStart="(x, y) => onChangePosition(true, x, y)"
    :onResizeStart="() => onChangePosition(false)"
    @dragstop="onWidgetDrag"
    @resizestop="onWidgetResize"
    @activated="() => onSelected(widget)"
    @deactivated="() => (keepZIndexUp = false)"
    @refLineParams="getRefLineParams"
  >
    <PdfView
      :pdfImages="widget.position.data.thumbnail_url"
      :pageDuration="widget.position.data.pageDuration"
      :imageMode="widget.position.data.imageMode"
      :style="style"
      position="absolute"
    />
  </vue-draggable-resizable>
</template>

<script>
  import VueDraggableResizable from '@/components/common/VueDraggableResizable.vue';

  import PdfView from '@/components/content/PdfView.vue';
  import draggableWidget from '@/components/templates/mixins/draggableWidget';

  import { simpleTypeMixin } from '@/helpers';

  export default {
    name: 'PdfWidget',

    mixins: [simpleTypeMixin, draggableWidget],

    components: {
      VueDraggableResizable,
      PdfView,
    },

    props: {
      widget: {
        type: Object,
        default: () => ({}),
      },
    },

    computed: {
      style() {
        return {
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
        };
      },

      position() {
        return this.getPosition(this.widget);
      },

      pdfUrl() {
        return this.widget.position.data.url;
      },
    },

    methods: {
      showImage() {
        //this.$emit('show-enlarged', this.widget)
      },
    },
  };
</script>
