module.exports = {
  name: 'Name',
  created: 'Created',
  create: 'Create',
  sign_in: 'Sign in',
  login: 'Log in',
  cancel: 'Cancel',
  close: 'Close',
  delete: 'Delete',
  email: 'Email',
  password: 'Password',
  back: 'Back',
  active: 'Active',
  update: 'Update',
  save: 'Save',
  access: 'Access',
  accept: 'Accept',
  description: 'Description',
  transition_mode: 'Transition Mode',
  seconds: 'Seconds',
  send: 'Send',
  here: 'here',
  home: 'Home',
  layer: 'Layer',
  screens: 'Screens',
  screen: 'Screen',
  organization: 'Organization',
  edit_organization: 'Edit Organization',
  user_settings: 'User Settings',
  layout_designer: 'Layout Designer',
  content: 'Content',
  add: 'Add',
  update_failed: 'Update failed',
  delete_failed: 'Delete failed',
  saving: 'Saving...',
};
