<template>
  <div class="image" @click="openItemPreview">
    <img
      v-if="
        playlistItem.item_type.includes(WIDGET_TYPES.IMAGE) ||
        playlistItem.item_type.includes(WIDGET_TYPES.VIDEO)
      "
      :src="getEncodedURL(baseUrl, playlistItem.thumbnail_url || playlistItem.item_url)"
      @error="handleImageError"
      :alt="playlistItem.name"
      class="image-preview"
    />

    <img
      v-else-if="playlistItem.item_type.includes(WIDGET_TYPES.TEMPLATE)"
      :src="playlistItem.template.settings.image"
    />

    <div v-else class="icon">
      <i
        v-if="playlistItem.item_type.includes(APP_TYPES.TABLE)"
        class="material-icons-outlined align-middle"
      >
        table_rows
      </i>
      <i
        v-else-if="playlistItem.item_type.includes(WIDGET_TYPES.PDF)"
        class="material-icons-outlined align-middle"
      >
        picture_as_pdf
      </i>
      <i
        v-else-if="playlistItem.item_type.includes(APP_TYPES.RSS)"
        class="material-icons-outlined align-middle"
      >
        rss_feed
      </i>
      <i
        v-else-if="playlistItem.item_type.includes(APP_TYPES.WEATHER)"
        class="material-icons-outlined align-middle"
      >
        cloud
      </i>
      <i
        v-else-if="playlistItem.item_type.includes(APP_TYPES.TRAFIKLAB)"
        class="material-icons-outlined align-middle"
      >
        widgets
      </i>

      <i
        v-else-if="playlistItem.item_type.includes(WIDGET_TYPES.LAYER)"
        class="material-icons-outlined align-middle"
      >
        language
      </i>
      <i
        v-else-if="playlistItem.item_type.includes(WIDGET_TYPES.TILE)"
        class="material-icons-outlined align-middle"
      >
        now_wallpaper
      </i>
    </div>

    <div class="search">
      <i class="material-icons-outlined align-middle">search</i>
    </div>
    <div class="move">
      <i class="material-icons-outlined align-middle">open_with</i>
    </div>
  </div>
</template>

<script>
  import { APP_TYPES, WIDGET_TYPES } from '@/models/layoutDesigner';
  import { simpleTypeMixin } from '@/helpers';

  export default {
    name: 'PlaylistItemImage',
    mixins: [simpleTypeMixin],
    props: {
      playlistItem: {
        type: Object,
        default: () => null,
      },
    },

    data() {
      return {
        WIDGET_TYPES,
        APP_TYPES,
        imageErrorHandled: false,
      };
    },

    methods: {
      openItemPreview() {
        this.$emit('openItemModal', this.playlistItem);
      },

      handleImageError(event) {
        if (!this.imageErrorHandled) {
          event.target.src = this.getEncodedURL(this.baseUrl, this.playlistItem.item_url);
          this.imageErrorHandled = true;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .image {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 56px;
    position: relative;
    width: 100%;
    height: 100%;

    .image-preview {
      object-fit: cover;
      min-height: 56px;
      width: 100%;
      height: 100%;
    }

    .search,
    .move {
      position: absolute;
      display: none;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.55);
      top: 0;
      width: 100%;
      height: 100%;

      i {
        color: white;
      }
    }
  }
</style>
