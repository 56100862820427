<template>
    <div class="sidebar-setting-placeholder table-app-setting">
      <!-- <div class="px-3">
        <AppInfo>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam at eros dolor. Phasellus dictum velit vel nunc vestibulum.</AppInfo>
      </div> -->
        <div class="rss-preview">
            <TableAppWebView :settings="previewData"></TableAppWebView>
        </div>
        <div class="instructions mt-3 d-flex flex-column align-items-center">
            <form class="sidebar-setting-content" @submit.prevent="goToContent">
                <div class="form-group">
                  <label>Theme</label>
                  <select v-model="setting.styles.table.theme" name="theme" class="form-control " @change="updatePreviewData">
                    <option value="white-grey">White Text and Grey Background</option>
                    <option value="black-white">Black Text and White Background</option>
                  </select>
                </div>
                <div class="form-group">
                  <label>Rows per page</label>
                  <input type="text" class="form-control input-underline" v-model="setting.styles.pagination.per_page">
                </div>
                <div class="form-group">
                  <label>Interval (seconds)</label>
                  <input type="number" class="form-control input-underline" min="0" v-model="setting.styles.pagination.interval">
                </div>
              <button type="submit" class="btn btn-danger bg-primary-red w-100 mt-3 btn-lg rounded-xl" :disabled="appCreateStatus === 'loading'">
                <img :src="plusIcon" alt="Plus icon"> Create Widget
              </button>
            </form>
        </div>
      <TableSettingsModal :open="showModal" :setting-data="setting"
                          @onClose="closeModal" @onSave="onSettingSubmit" ref="modal" />
    </div>
</template>

<script>
    import plusIcon from '@/assets/icon/icon-plus-square.png';
    import AppInfo from "@/components/apps/AppInfo.vue";
    import TableAppWebView from "../../tableApp/TableAppWebView.vue";
    import {APP_CREATE} from '@/store/actions/apps'
    import {mapState} from 'vuex'

    const $ = window.jQuery;
    import _ from 'lodash';
    import TableSettingsModal from "@/components/apps/table/TableSettingsModal.vue";

    const sampleTableApp = {
        name: 'Test table app',
        columns: 2,
        column_width: {},
        styles: {
            heading: {},
            body: {},
            table: {
              theme: 'white-grey'
            },
            pagination: {
              per_page: 20,
              interval: 10
            }
        },
        rows: [
            {
                type: 'heading',
                data: [
                    {
                        content: 'BOENDE'
                    },
                    {
                        content: 'VANING'
                    },
                ]
            },

        ]
    };

    for (let i = 1; i <= 21; i++) {
        sampleTableApp.rows.push({
            type: 'body',
            data: [
                {
                    content: `Data ${i}`
                },
                {
                    content: `Value ${i}`
                },
            ]
        });
    }

    export default {
        name: 'TableAppSetting',

        components: {
            TableSettingsModal,
            TableAppWebView,
            AppInfo
        },
        props: {
            /*tableAppSetting: {
                type: Object,
                required: true
            },*/
        },
        data() {
            return {
                showModal: false,
                previewData: this.getInitialData().setting,
                plusIcon,
                ...this.getInitialData()
            };
        },

        methods: {
            closeModal() {
              this.showModal = false;
              this.$refs.modal.reset();
              Object.assign(this.$data, {
                  previewData: this.getInitialData().setting,
                  ...this.getInitialData()
                });
            },
            validateForm() {
                if (this.setting.columns <= 0) {
                    return 'Columns must be positive number';
                }
                if(this.setting.styles.pagination.per_page <= 0) {
                  return 'The minimum value of the rows per page should be 1';
                }
                if(this.setting.styles.pagination.interval <= 0) {
                  return 'The minimum value of interval should be 1';
                }

                /*if (this.setting.col <= 0) {
                    return 'Rows must be positive number';
                }*/

                return true;
            },
            fillTable(settings) {
                this.setting.rows = _.cloneDeep(settings.rows);
                this.previewData = _.cloneDeep(this.setting);
            },
            updatePreviewData(){
              this.previewData = _.cloneDeep(this.setting);
            },
          goToContent() {
            const error = this.validateForm();
            if (error.length) {
              this.$toasted.global.general_error({message: error});
              return;
            }
            this.showModal = true;
          },
          onSettingSubmit({name, folder, schedule, columns , rows}) {
            const data = {
              ...this.setting,
              type: 'table',
              name,
              folder,
              schedule
            }
            data.rows = rows;
            data.columns = columns;
            this.$store.dispatch(APP_CREATE, data)
              .then(() => {
                this.$toasted.global.general_success({message: 'Table app added successfully'});
                this.closeModal();
            }).catch(() => {
              this.$toasted.global.general_error({message: 'Error adding app, please try again later'});
            })
          },
          getInitialData: function () {
              return {
                  setting: {
                      columns: 2,
                      rows: [
                          {
                              type: 'heading',
                              data: [
                                  {
                                      content: 'Col 1'
                                  },
                                  {
                                      content: 'Col 2'
                                  },
                              ]
                          },
                          {
                              type: 'body',
                              data: [
                                  {
                                      content: ''
                                  },
                                  {
                                      content: ''
                                  },
                              ]
                          },
                      ],
                      styles: {
                          heading: {},
                          body: {},
                          table: {},
                          pagination: {
                            per_page: 20,
                            interval: 10
                          }
                      }
                  }
              };
          },
          pickHeaderColor() {
              let code = $('#header-color-color').val();
              $('#header-color-text').val(code);
          },
          pickTextColor() {
              let code = $('#text-color-color').val();
              $('#text-color-text').val(code);
          },
          pickHeaderBgColor() {
              let code = $('#header-bg-color-color').val();
              $('#header-bg-color-text').val(code);
          },
          changeContent() {
              return;
              let tableAppSetting = _.cloneDeep(this.tableAppSetting); // UI won't update without it
              let row = this.setting.row;
              let col = this.setting.col;

              for (let i = 0; i < row; i++) {

                  // initiate header row if not existed
                  if (typeof tableAppSetting.headers[0] === 'undefined') tableAppSetting.headers[0] = [];

                  // initiate data row if not existed
                  for (let j = 0; j < col; j++) {
                      if (typeof tableAppSetting.headers[0][j] === 'undefined') tableAppSetting.headers[0][j] = 'Header ' + (j + 1);

                      if (j === 0 && typeof tableAppSetting.content[i] === 'undefined') tableAppSetting.content[i] = [];
                      if (typeof tableAppSetting.content[i][j] === 'undefined') tableAppSetting.content[i][j] = '';
                  }
              }
              // cut data if number of rol, col reduced
              if (row < tableAppSetting.row) {
                  tableAppSetting.content.splice(row);
              }
              if (col < tableAppSetting.col) {
                  for (let i = 0; i < tableAppSetting.content.length; i++) {
                      tableAppSetting.content[i].splice(col);
                  }
                  for (let i = 0; i < tableAppSetting.headers.length; i++) {
                      tableAppSetting.headers[i].splice(col);
                  }
              }

              tableAppSetting.col = col;
              tableAppSetting.row = row;

              this.$set(this, 'setting', tableAppSetting);

          }
        },

        computed: {
            ...mapState({
                appCreateStatus: state => state.apps.appCreateStatus,
            }),
        },
    }
</script>

<style lang="scss" scoped>
    .table-app-setting {
        .rss-preview {
            height: 17vmax;
            overflow: hidden;
        }

        .color-picker-input {
            width: 1.5rem;
        }

        .color-picker-text {
            padding-left: 0.5rem !important;
        }

        #btn-fill-table-content {
            padding: 0.375rem 2.5rem;
            margin: 1rem 0;
        }

    }


</style>
