<template>
  <div class="template-wrapper scrollbar">
    <div class="background template-container" :style="templateStyle" @click.stop>
      <div class="template">
        <DesignerToolbar class="toolbar" :selectedWidget="selectedWidget" :show="showToolbar" />

        <div class="draggable-container noselect">
          <TemplateDrawer :widget="widget" v-for="widget in widgets" :key="widget.assoc_id" />

          <span
            class="ref-line v-line"
            v-for="(item, index) in vLine"
            :key="`vertical-${index}`"
            v-show="item.display"
            :style="{
              left: parseFloat(item.position) > 0 ? item.position : 0,
              top: parseFloat(item.origin) > 0 ? item.origin : 0,
              height: trimLength(item, 'height'),
              background: '#e3003a',
              border: '1px solid #e3003a',
            }"
          />
          <span
            class="ref-line h-line"
            v-for="(item, index) in hLine"
            :key="`horizontal-${index}`"
            v-show="item.display"
            :style="{
              top: parseFloat(item.position) > 0 ? item.position : 0,
              left: parseFloat(item.origin) > 0 ? item.origin : 0,
              width: trimLength(item, 'width'),
              background: '#e3003a',
              border: '1px solid #e3003a',
            }"
          />
        </div>

        <div class="template-grid" :style="gridStyle"></div>
      </div>
    </div>

    <DeleteWidgetModal
      v-if="showDeleteWidgetModal"
      :widget="selectedWidget"
      @closeModal="showDeleteWidgetModal = false"
    />
  </div>
</template>

<script>
  import 'vue-draggable-resizable-gorkys/dist/VueDraggableResizable.css';
  import VueDraggableResizable from '@/components/common/VueDraggableResizable.vue';

  import TemplateDrawer from '@/components/templates/designer/TemplateDrawer.vue';
  import DesignerToolbar from '@/components/templates/designer/DesignerToolbar.vue';
  import DeleteWidgetModal from '@/components/templates/designer/DeleteWidgetModal.vue';

  import { getRBGAColor } from '@/helpers/utils';
  import { WIDGET_TYPES } from '@/models/layoutDesigner';

  export default {
    name: 'TemplateLayout',

    props: {
      template: {
        type: Object,
        default: () => null,
      },
    },

    components: {
      TemplateDrawer,
      VueDraggableResizable,
      DesignerToolbar,
      DeleteWidgetModal,
    },

    data() {
      return {
        showDeleteWidgetModal: false,
      };
    },

    provide() {
      return {
        deleteWidget: this.openDeleteWidgetModal,
      };
    },

    computed: {
      showToolbar() {
        return this.selectedWidget?.object.type !== WIDGET_TYPES.RICH_TEXT;
      },

      templateStyle() {
        return {
          height: `${this.layoutDimensions.layoutHeight}px`,
          width: `${this.layoutDimensions.layoutWidth}px`,
          backgroundColor: getRBGAColor(
            this.template?.settings.backgroundColor || '#FFFFFF',
            (this.template?.settings.opacity >= 0 ? this.template?.settings.opacity : 100) / 100,
          ),
        };
      },

      widgets() {
        return this.$store.state.templateDesigner.widgets;
      },

      selectedWidget() {
        return this.$store.state.templateDesigner.selectedWidget;
      },

      vLine() {
        return this.$store.state.templateDesigner.vLine;
      },

      layoutDimensions() {
        return this.$store.getters.getTemplateDimensions;
      },

      hLine() {
        return this.$store.state.templateDesigner.hLine;
      },

      showGrid() {
        return this.$store.state.templateDesigner.showTemplateGrid;
      },

      grid() {
        return this.$store.state.templateDesigner.template?.settings.grid || { x: 20, y: 20 };
      },

      layoutScaleFactor() {
        return this.layoutDimensions.ratio || 1;
      },

      gridStyle() {
        const gridValues = `${this.grid.x * this.layoutScaleFactor}px ${
          this.grid.y * this.layoutScaleFactor
        }px`;

        return this.showGrid
          ? {
              background: `linear-gradient(-90deg, rgba(0, 0, 0, 0.1) 1px, transparent 1px) 0% 0% / ${gridValues}, linear-gradient(rgba(0, 0, 0, 0.1) 1px, transparent 1px) 0% 0% / ${gridValues}`,
            }
          : {};
      },
    },

    methods: {
      getRefLineParams(params) {
        const { vLine, hLine } = params;
        this.vLine = vLine;
        this.hLine = hLine;
      },

      trimLength(lineData, orientation) {
        if (!lineData.origin || !lineData.lineLength) return;

        const origin = parseFloat(lineData.origin) > 0 ? parseFloat(lineData.origin) : 0;
        const lineLength = parseFloat(lineData.lineLength);

        if (orientation === 'width') {
          const { layoutWidth } = this.layoutDimensions;
          const totalXCoordinate = origin + lineLength;
          const finalLineLength =
            totalXCoordinate > layoutWidth ? layoutWidth - origin : lineLength;

          return `${finalLineLength}px`;
        }

        const { layoutHeight } = this.layoutDimensions;
        const totalYCoordinate = origin + lineLength;

        const finalLineLength =
          totalYCoordinate > layoutHeight ? layoutHeight - origin : lineLength;

        return `${finalLineLength}px`;
      },

      openDeleteWidgetModal() {
        this.showDeleteWidgetModal = true;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .template {
    position: relative;
    height: 100%;
    margin: 0 auto;
    padding-bottom: 20px;

    ::v-deep .widget-item {
      position: absolute;
    }
  }

  .template-container {
    position: relative;
    cursor: grab;

    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  }

  .draggable-container {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .template-grid {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 200;
    pointer-events: none;
  }

  .template-wrapper {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 60px 0 20px 0;
    margin-right: 32px;
    overflow: auto;
  }
</style>
