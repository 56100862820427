<script setup lang="ts">
  import { computed, ref } from 'vue';
  import { useStore } from '@/store';

  import PlaylistPreview from '@/components/layoutDesigner/editor/settingPanel/playlist/PlaylistPreview.vue';
  import LayoutPlaylistItems from '@/components/layoutDesigner/editor/settingPanel/playlist/LayoutPlaylistItems.vue';

  import {
    LAYOUT_DESIGNER_UPDATE_WIDGET,
    SET_PLAYLIST_ITEMS_PANEL,
  } from '@/store/actions/layoutDesigner';

  const store = useStore();
  const PlaylistItems = ref<HTMLVideoElement | null>(null);

  const playlist = computed(function () {
    return store.state.layoutDesigner.selectedWidget;
  });

  function handleChange() {
    store.commit(LAYOUT_DESIGNER_UPDATE_WIDGET, { widget: playlist.value, selectWidget: false });
  }

  function goBackToPlaylist() {
    store.commit(SET_PLAYLIST_ITEMS_PANEL, false);
  }
</script>

<script lang="ts">
  export default {
    name: 'PlaylistItemsPanel',

    components: {
      LayoutPlaylistItems,
      PlaylistPreview,
    },
  };
</script>

<template>
  <div class="playlist-panel">
    <div class="section-title title">
      <i class="material-icons-outlined align-middle button" @click="goBackToPlaylist">
        arrow_back
      </i>

      <span class="title"> Back </span>
    </div>

    <div class="section-title playlist-title">
      <div class="playlist-name">
        <i class="material-icons-outlined align-middle">playlist_play</i>

        <span class="title">
          Area for <b>{{ playlist?.object.name }}</b> layer
        </span>
      </div>
    </div>

    <div class="panel-container">
      <PlaylistPreview :playlist="playlist" />

      <div class="form-group name layout-input mb-0">
        <label for="playlist-name">Area Name</label>
        <input
          class="form-control"
          type="text"
          id="playlist-name"
          placeholder="Name"
          v-model="playlist.object.name"
          @input="handleChange"
        />
      </div>

      <BaseSelect
        label="Transition Animation"
        id="transition-animation"
        type="text"
        v-model="playlist.object.transition_mode"
        :options="[
          { label: 'Fade In', value: 'fade' },
          { label: 'Slide in', value: 'slide' },
        ]"
        @input="handleChange"
      />

      <LayoutPlaylistItems
        ref="PlaylistItems"
        :playlist="{
          ...playlist.object,
          playlistId: playlist.object.wid,
          assocId: playlist.assoc_id,
        }"
        hideReturn
        designerMode
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .section-title {
    display: flex;
    align-items: center;
    font-size: 16px;
    gap: 10px;
    margin-bottom: 8px;

    i {
      color: $primaryText;
    }

    &.title {
      margin-bottom: 24px;
    }

    &.playlist-title {
      display: flex;
      justify-content: space-between;
    }
  }

  .playlist-name {
    display: flex;
    align-items: flex-start;
    gap: 10px;

    span {
      display: -webkit-box;
      word-wrap: break-word;
      word-break: break-all;
      max-height: 32px;
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    i {
      max-width: 24px;
    }
  }

  .panel-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
</style>
