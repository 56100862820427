<template>
  <Modal
    @closeModal="closeModal"
    v-show="showModal"
    :isLoading="isLoading"
    title="Delete Confirmation"
    :okFunction="deleteLayout"
  >
    <template v-slot:body>
      <div class="delete-description">Are you sure you want to delete this layout?</div>
    </template>
  </Modal>
</template>

<script>
  import Modal from '@/components/common/Modal.vue';
  import { apiDeleteLayout } from '@/api/layouts';

  export default {
    name: 'DeleteLayoutModal',

    components: { Modal },

    props: {
      layoutId: {
        type: String,
        required: true,
      },
    },

    data() {
      return {
        showModal: false,
        isLoading: false,
      };
    },

    mounted() {
      this.showModal = true;
    },

    methods: {
      async deleteLayout() {
        this.isLoading = true;

        try {
          await apiDeleteLayout(this.layoutId);

          this.$toasted.global.general_success({
            message: 'Layout deleted',
          });

          this.$emit('deleted');

          this.closeModal(true);
        } catch (error) {
          this.$toasted.global.general_error({
            message: error.data?.message ?? String(error),
          });
        }

        this.isLoading = false;
      },

      closeModal() {
        this.showModal = false;
        this.$emit('closeModal');
      },
    },
  };
</script>
