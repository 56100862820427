<template>
  <div class="table-container scrollbar">
    <table v-if="loaded && users && users.length > 0" class="table organizations-table">
      <thead>
        <tr>
          <th></th>
          <th class="name-col">Name</th>
          <th>Role</th>
          <th>{{ $lang.organizations.table.headers.created }}</th>
          <th>Last Login</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="user in users"
          :key="user.user_id"
          :class="{
            selected: user.user_id === selectedUser?.user_id,
          }"
          @click="selectUser(user)"
        >
          <td style="width: 64px">
            <div class="avatar-container" id="profile-drop-down">
              <img
                v-if="user.avatar_url"
                class="rounded-circle"
                :src="user.avatar_url + '?' + Date.now()"
                alt="Avatar"
              />
              <div v-else class="img-placeholder">
                <i class="material-icons align-middle">account_circle</i>
              </div>
            </div>
          </td>

          <td class="name-col">
            {{ user.name || user.username }}
          </td>

          <td style="width: 20%">
            {{ getRoleName(user.role) }}
          </td>

          <td style="width: 20%">
            {{ formatDate(user.created, 'full', 'DD/MM/YYYY') }}
          </td>

          <td style="width: 20%">
            {{ user.last_login ? formatDate(user.last_login, 'full', 'DD/MM/YYYY') : '' }}
          </td>

          <td style="width: 64px">
            <div class="dropdown">
              <i
                class="material-icons-outlined align-middle more-buttons dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                >more_vert</i
              >
              <div class="dropdown-menu dropdown-menu-right">
                <button type="button" class="dropdown-item" @click="openDeleteUserModal(user)">
                  <i class="material-icons-outlined align-middle">delete</i>
                  Delete
                </button>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div v-else class="no-data">No Users</div>

    <DeleteUserModal
      @closeModal="showDeleteUserModal = false"
      :user="selectedUser"
      v-if="showDeleteUserModal"
      @deleted="getUsers"
    />
  </div>
</template>

<script>
  import DeleteUserModal from '@/components/admin/DeleteUserModal.vue';

  import UserModal from './sidebar/UserModal.vue';
  import { ADMIN_USERS_REQUEST, USER_REQUEST } from '@/store/actions/user';
  import { globalFiltersMixin } from '@/helpers/mixins';

  export default {
    name: 'UsersList',

    components: {
      DeleteUserModal,
      UserModal,
    },

    props: {
      searchText: {
        type: String,
        default: '',
      },
    },

    mixins: [globalFiltersMixin],

    data() {
      return {
        showDeleteUserModal: false,
        selectedUser: null,
        organisationId: this.$route.params.organisationId,
        loaded: false,
      };
    },

    created() {
      this.getUsers();
    },

    computed: {
      organisation() {
        return this.$store.state.organisation.organisation;
      },

      users() {
        // filter
        return this.$store.state.user.users.filter((user) => {
          if (!this.searchText) return true;

          const searchText = this.searchText.toLowerCase();

          const { name = '', username = '' } = user;
          return (
            !!name?.toLowerCase().includes(searchText) ||
            !!username?.toLowerCase().includes(searchText)
          );
        });
      },

      isAdmin() {
        return this.$store.getters.isAdministrator;
      },

      roles() {
        return this.$store.state.roles.roles;
      },
    },

    methods: {
      async getUsers() {
        if (this.isAdmin) {
          await this.$store.dispatch(ADMIN_USERS_REQUEST, this.organisationId);
        } else {
          await this.$store.dispatch(USER_REQUEST);
        }
        if (!this.loaded) {
          this.loaded = true;
        }
      },

      selectUser(user) {
        this.selectedUser = user;
        this.$emit('userSelected', user);
      },

      openDeleteUserModal(organisation) {
        this.selectUser(organisation);
        this.showDeleteUserModal = true;
      },

      getRoleName(roleId) {
        return this.roles.find((role) => role.id === roleId)?.name;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .table-container {
    overflow-y: auto;
    overflow-x: visible;

    padding-right: 2rem;
    margin-right: -2rem;
  }

  .organizations-table {
    border: none;
    border-spacing: 0 8px;
    border-collapse: separate;
    margin-bottom: 0;

    .name-col {
      text-align: left;
    }

    thead {
      th {
        position: sticky;
        top: 0;
        box-shadow: 0px 0px 0 8px $backgroundGrey;
        z-index: 2;
        background-color: $backgroundGrey;
      }
    }

    th,
    td {
      border: none;
    }

    th {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;

      & + th {
        text-align: center;
      }
    }

    td {
      padding: 14px 16px;

      font-size: 16px;
      line-height: 24px;

      background: none;
      border-top: 2px solid transparent;
      border-bottom: 2px solid transparent;

      &:first-child {
        border-left: 2px solid transparent;
      }

      &:last-child {
        border-right: 2px solid transparent;
      }

      & + td {
        text-align: center;
      }
    }

    tbody {
      overflow-y: auto;
      height: 100%;

      tr {
        cursor: pointer;
        background-color: #fff;

        &:hover,
        &.selected {
          td {
            border-top: 2px solid $primaryRed;
            border-bottom: 2px solid $primaryRed;

            &:first-child {
              border-left: 2px solid $primaryRed;
            }

            &:last-child {
              border-right: 2px solid $primaryRed;
            }
          }
        }
      }
    }
  }

  .avatar-container img {
    width: 32px;
    height: 32px;
    background-color: $fillGrey;
    .img-placeholder {
      i {
        color: #2f6eae;
        font-size: 50px;
      }
    }
  }
</style>
