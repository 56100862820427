<template>
  <Modal
    v-show="showModal"
    title=""
    okButtonLabel="Create Layout"
    @closeModal="closeModal"
    :modalStyles="{
      maxWidth: 'calc(100% - 2rem)',
      maxHeight: 'calc(100% - 2rem)',
    }"
  >
    <template v-slot:body>
      <Loader v-if="isLoading && !layout" />
      <Layout v-if="layout" :layout="layout" :style="playerStyle" />
    </template>
    <template #footer><div></div></template>
  </Modal>
</template>

<script>
  import Modal from '@/components/common/Modal.vue';
  import Layout from '@/components/player/Layout.vue';
  import Loader from '@/components/common/Loader.vue';

  import {
    ALLOW_PLAYER_MODAL,
    PLAYER_LOAD_LAYOUT,
    SET_PLAYER_DEMO,
    SET_SCREEN_SIZE,
  } from '@/store/actions/player';

  export default {
    name: 'LayoutsPreviewModal',

    components: { Modal, Layout, Loader },

    props: {
      layoutId: {
        type: String,
        default: '',
      },
      screenId: {
        type: String | Number,
        default: null,
      },
      groupId: {
        type: String | Number,
        default: null,
      },
    },

    data() {
      return {
        showModal: false,
        isLoading: false,
        screenWidth:
          window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
        screenHeight:
          window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
      };
    },

    mounted() {
      this.showModal = true;
      this.$store.commit(SET_PLAYER_DEMO, true);
      this.$store.commit(ALLOW_PLAYER_MODAL, true);

      this.getData();
    },

    computed: {
      layout() {
        return this.$store.state.player.layout;
      },

      screenSize() {
        return this.$store.state.player.screenSize;
      },

      aspectRatio() {
        const { aspectRatio } = this.layout.settings;
        const aspectRatioValues = aspectRatio.split(':');
        const ratio = aspectRatioValues[0] / aspectRatioValues[1];

        return ratio;
      },

      playerStyle() {
        const { isHorizontal } = this.layout.settings;
        let maxWidth = this.screenWidth - 64;
        let maxHeight = this.screenHeight - 64;

        const currentScreenRatio = isHorizontal ? maxWidth / maxHeight : maxHeight / maxWidth;

        if (currentScreenRatio > this.aspectRatio) {
          const factorToReduceScreen = this.aspectRatio / currentScreenRatio;

          maxWidth = isHorizontal ? maxWidth * factorToReduceScreen : maxWidth;
          maxHeight = isHorizontal ? maxHeight : maxHeight * factorToReduceScreen;
        }

        const playerWidth = isHorizontal ? maxWidth : maxHeight / this.aspectRatio;
        const playerHeight = isHorizontal ? maxWidth / this.aspectRatio : maxHeight;

        if (this.screenSize.width !== playerWidth || this.screenSize.height !== playerHeight) {
          this.$store.commit(SET_SCREEN_SIZE, {
            width: playerWidth,
            height: playerHeight,
          });
        }

        return { width: `${playerWidth}px`, height: `${playerHeight}px`, position: 'relative' };
      },
    },

    methods: {
      async getData() {
        this.isLoading = true;

        try {
          await this.$store.dispatch(PLAYER_LOAD_LAYOUT, {
            layoutId: this.layoutId || this.$route.params.layout_id,
            screenId: this.screenId,
            groupId: this.groupId,
            loadChildren: true,
          });
        } catch (error) {
          console.log('error: ', error);
          this.$toasted.global.general_error({ message: 'Unable to load player' });
          this.closeModal();
        }

        this.isLoading = false;
      },

      closeModal() {
        this.$store.commit(ALLOW_PLAYER_MODAL, false);
        this.showModal = false;
        this.$emit('closeModal');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .modal-mask {
    &.py-5 {
      padding: 0 !important;
    }
  }

  ::v-deep .modal-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;

    .modal-body {
      display: flex;
      align-items: center;
      justify-items: center;
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;

      .loading-placeholder {
        width: 100%;
      }
    }

    .modal-container {
      width: auto !important;
      border-radius: 8px;
      padding: 8px;
      min-width: 200px;
      min-height: 200px;
    }

    .modal-header {
      padding: 0;
      border: none;

      .close-button {
        right: -2rem;
        z-index: 1000;
      }
    }
  }
</style>
