<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<template>
  <div class="loc-container">
    <div class="search-box-container">
      <div class="search-box form-group template-input">
        <div class="icon">
          <!-- Add material search icon -->
          <span class="material-icons-outlined"> search </span>
        </div>
        <input
          id="loc-search"
          type="text"
          class=""
          placeholder="Search for a location"
          v-model="search.value"
          @input.prevent="debouncedSearch"
          @keydown.enter.prevent="onEnter"
        />
        <div class="icon loading" v-if="search.loading">
          <span class="material-icons-outlined"> refresh </span>
        </div>
      </div>
      <div class="search-results">
        <div v-if="search.results.length > 0" class="results">
          <!-- eslint-disable-next-line vuejs-accessibility/interactive-supports-focus -->
          <div
            v-for="result in search.results"
            :key="result.place_id"
            class="result"
            @click="onSelect(result)"
          >
            {{ result.display_name }}
          </div>
        </div>
        <div v-if="search.results.length < 0 && search.error" class="results">
          <div class="result result-error">
            {{ search.error }}
          </div>
        </div>
      </div>
    </div>
    <div style="background: rgba(142, 227, 179, 0.522)">
      <l-map
        :style="mapStyle"
        @dblclick="onMapClick"
        :zoom="zoom"
        :center="center"
        :maxZoom="15"
        :options="{ zoomControl: false }"
      >
        <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
        <l-marker :lat-lng="userLocation || defaultLocation" :icon="icon"></l-marker>
        <l-control-zoom position="bottomleft"></l-control-zoom>
      </l-map>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash';
  import L from 'leaflet';
  import 'leaflet/dist/leaflet.css';
  import { LMap, LTileLayer, LMarker, LControlZoom } from 'vue2-leaflet';
  import IconShadowImg from 'leaflet/dist/images/marker-shadow.png';
  import LocationMarkerIcon from '@/assets/img/location-marker.png';
  import { apiSearchLocation } from '@/api/location';

  export default {
    name: 'LocationPicker',

    emits: ['updateLocation'],

    components: {
      LMap,
      LTileLayer,
      LMarker,
      LControlZoom,
    },

    props: {
      mapStyle: {
        type: Object,
        default: () => ({ height: '200px' }),
      },
    },

    data() {
      return {
        search: {
          loading: false,
          error: '',
          value: '',
          results: [],
          shouldSelectFirst: false,
        },
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        attribution:
          '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        zoom: 15,
        defaultLocation: [51.505, -0.159],
        center: [51.505, -0.159],
        userLocation: null,
        icon: L.icon({
          iconUrl: LocationMarkerIcon,
          iconShadow: IconShadowImg,
          iconSize: [30, 30],
          iconAnchor: [15, 30],
          popupAnchor: [0, -30],
          shadowSize: [30, 30],
        }),
      };
    },

    methods: {
      onMapClick(e) {
        this.userLocation = e.latlng;
        this.center = e.latlng;
      },
      onSearch(e) {
        const query = e.target.value;
        if (query.trim() !== '') {
          this.fetchResults(query);
        } else {
          this.resetSearch();
        }
      },

      resetSearch() {
        this.search = {
          loading: false,
          value: '',
          results: [],
          shouldSelectFirst: false,
        };
      },

      onSelect(result) {
        this.userLocation = [result.lat, result.lon];
        this.center = [result.lat, result.lon];

        this.resetSearch();

        const longName = result.display_name.split(', ');
        const label =
          longName.length > 3
            ? `${longName[0]}, ${longName[1]}, ${longName[longName.length - 1]}`
            : longName.join(', ');

        this.$emit('updateLocation', {
          label,
          name: `${longName[0]}, ${longName.pop()}`,
          coordinates: this.userLocation,
        });
      },

      onEnter() {
        if (this.search.results.length > 0) {
          this.onSelect(this.search.results[0]);
        } else {
          this.search.shouldSelectFirst = true;
        }
      },

      async fetchResults(q) {
        this.search.loading = true;
        // Fetch Data from nominatim
        try {
          const res = await apiSearchLocation(q);
          this.search.results = res;

          if (this.search.shouldSelectFirst) {
            this.onSelect(this.search.results[0]);
          }
        } catch (err) {
          this.search.error = "Couldn't find any results";
        }

        this.search.loading = false;
      },
    },

    created() {
      this.debouncedSearch = _.debounce(this.onSearch, 500);
    },
  };
</script>

<style lang="scss" scoped>
  @import '../../../sass/custom-bootstrap.scss';

  .loc-container {
    height: 100%;
    width: 100%;
    position: relative;
  }

  .search-box-container {
    .search-box {
      width: 100%;
      height: 100%;
      background-color: #fff;
      border-radius: 8px;
      display: flex;
      align-items: center;
      padding: 0.35rem 0.5rem;
      transition: all 0.2s ease-in-out;
      border: 1px solid $borderGrey2;

      &:focus-within {
        border-color: #5e5e5e;
        div.icon:not(.loading) {
          color: black;
        }
      }
      div.icon {
        height: 24px;
        color: #757575;

        &.loading {
          height: 20px;
          width: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          animation: spin 1s linear infinite;
          span {
            font-size: 16px;
          }
        }
      }
      input {
        border: none;
        flex: 1;
        outline: none;
      }
    }

    .search-results {
      padding: 0.25rem 0rem;

      .results {
        max-height: 150px;
        overflow-y: auto;
        background-color: #fff;
        border-radius: 0.25rem;
        box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.2);
        // padding: 0.15rem 0.15rem;
        transition: all 0.2s ease-in-out;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.357);
        border: 1px solid transparent;

        .result {
          font-size: 0.8rem;
          color: black;
          font-family: $font-family-sans-serif;
          padding: 0.25rem 0.5rem;
          cursor: pointer;
          // border-radius: 0.25rem;
          transition: all 0.2s ease-in-out;
          font-family: $font-family-sans-serif;
          font-weight: 500;
          border-bottom: 1px solid #dbdbdb;
          &:hover {
            background-color: #dbdbdb;
          }

          &.result-error {
            color: #ff0000;
            border-bottom: none;
            &:hover {
              background-color: initial;
            }
          }
        }
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  ::v-deep .leaflet-control-zoom {
    border: none;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.357);

    a {
      width: 20px;
      height: 20px;
      line-height: 20px;
      font-size: 16px;
    }
  }
</style>
