module.exports = {
  name: 'Namn',
  created: 'Skapad',
  create: 'Skapa',
  sign_in: 'Logga in',
  login: 'Logga in',
  cancel: 'Stang',
  close: 'Stänga',
  delete: 'Radera',
  email : 'E-postadress',
  password : 'Lösenord',
  back : 'Tillbaka',
  active : 'Aktiv',
  update : 'Uppdatera',
  save : 'Spara',
  access : 'Åtkomst',
  description: 'Beskrivning',
  transition_mode: 'Transition Mode',
  seconds: 'Sekunder',
  here : 'här',
  home: 'Hem',
  organization: 'Organisation',
  edit_organization: 'Administrera Organisation',
  user_settings: 'Konto',
  layout_designer: 'Layout Designer',
  content: 'Innehåll',
  add: 'Lägg till',
  update_failed: 'Uppdatering misslyckades',
  delete_failed: 'Radering misslyckades',
  saving: 'Sparar...',
};