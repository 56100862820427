<template>
  <div class="app-wrapper">
    <LeftSidebar />
    <div class="main-content">
      <nav-bar page_name="Apps" />

      <div class="container-fluid w-100 d-flex flex-column main-content-sub-container">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
  import NavBar from '@/components/NavBar.vue';
  import LeftSidebar from '@/components/LeftSidebar.vue';

  import { USER_REQUEST } from '@/store/actions/user';

  export default {
    name: 'Dashboard',

    components: {
      NavBar,
      LeftSidebar,
    },

    methods: {},

    mounted() {
      this.$store.dispatch(USER_REQUEST, this.$store.state.auth.user_id);
    },
  };
</script>
