<template>
  <div class="matchi-offers card-shadow">
    <div class="matchi-offers-header">
      <div>{{$lang.player.offers_for_you}}</div>
      <div v-if="$lang.getLang() !== 'da'">{{$lang.player.contact_us}}</div>
    </div>
    <div class="matchi-offers-items">
      <div v-for="offer in validOffers" :key="offer.id" class="item">
        <div class="icon" v-if="getRandomInt(3) > 0">
          <img v-if="getRandomInt(2) == 1" src="../../assets/img/matchi/offers-icon-1.png">
          <img v-else src="../../assets/img/matchi/offers-icon-2.png">
        </div>
        <div>
          <div>{{offer.name}}</div>
          <div>{{offer.description}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MatchiOffers",
  props: ["validOffers"],
  methods: {
    getRandomInt(max) {
      return Math.floor(Math.random() * Math.floor(max));
    }
  }
};
</script>
