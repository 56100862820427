<template>
  <div class="timetable-container">
    <div
      v-if="this.timetableSettings[this.selectedStop?.id]"
      class="preview-container border-bottom"
    >
      <TimeTableViewer
        :viewerStyles="colors"
        :timetable="Object.values(timetableSettings)"
        :name="selectedStop.name"
      />
    </div>

    <slot></slot>

    <div class="settings">
      <div class="accordion">
        <div class="accordion-item">
          <div class="accordion-header">
            <h5 class="collapsible-title">General Settings</h5>
          </div>
          <div class="accordion-body open">
            <div class="input">
              <label for="sourcesInput" class="w-100">
                Select Stop
                <select id="sourcesInput" v-model="selectedStop" class="form-control">
                  <option v-for="(stop, index) in trafiklabStops" :key="index" :value="stop">
                    {{ stop.name }}
                  </option>
                </select>
              </label>
            </div>
            <div class="row row-cols-2">
              <div class="input col">
                <label for="playTime" class="w-100">
                  Object play time
                  <select id="playTime" v-model="playTime" class="form-control">
                    <option
                      v-for="(time, index) in playTimesOptions"
                      :key="index"
                      :value="time.value"
                    >
                      {{ time.label }}
                    </option>
                  </select>
                </label>
              </div>
              <div class="input col">
                <label for="playTime" class="w-100">
                  Language
                  <select id="playTime" v-model="language" class="form-control">
                    <option
                      v-for="key in Object.keys(languages)"
                      :key="key"
                      :value="languages[key]"
                    >
                      {{ key }}
                    </option>
                  </select>
                </label>
              </div>
              <div class="col">
                Hide departures that depart within X minutes
                <label for="hideTimeInMin" class="w-100">
                  <input
                    type="number"
                    class="form-control"
                    v-model="hideTime"
                    min="1"
                    max="60"
                    id="hideTimeInMin"
                  />
                </label>
              </div>
              <div class="toggle-group col pt-4">
                <ToggleButton :width="25" :height="15" v-model="show" :value="show" :sync="true" />
                Show 'after' arrival time
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <div class="accordion-header">
            <h5 class="collapsible-title">TRAFFIC TYPES</h5>
          </div>

          <div class="accordion-body open">
            <div class="toggle-group" key="BUS">
              <ToggleButton
                :width="25"
                :height="15"
                :sync="true"
                :disabled="!selectedStop?.availableForBus"
                v-model="trafficTypes['BUS']"
                :value="trafficTypes['BUS']"
              />
              BUS
            </div>
            <div class="toggle-group" key="METRO">
              <ToggleButton
                :width="25"
                :height="15"
                :sync="true"
                :disabled="!selectedStop?.availableForMetro"
                v-model="trafficTypes['METRO']"
                :value="trafficTypes['METRO']"
              />
              METRO
            </div>
            <div class="toggle-group" key="SHIP">
              <ToggleButton
                :width="25"
                :height="15"
                :sync="true"
                :disabled="!selectedStop?.availableForShip"
                v-model="trafficTypes['SHIP']"
                :value="trafficTypes['SHIP']"
              />
              SHIP
            </div>
            <div class="toggle-group" key="TRAIN">
              <ToggleButton
                :width="25"
                :height="15"
                :sync="true"
                :disabled="!selectedStop?.availableForTrain"
                v-model="trafficTypes['TRAIN']"
                :value="trafficTypes['TRAIN']"
              />
              TRAIN
            </div>
            <div class="toggle-group" key="TRAM">
              <ToggleButton
                :width="25"
                :height="15"
                :sync="true"
                :disabled="!selectedStop?.availableForTram"
                v-model="trafficTypes['TRAM']"
                :value="trafficTypes['TRAM']"
              />
              TRAM
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <span
          class="btn px-3 py-1 d-inline-block text-white border-success text-success rounded-xl"
          :class="{ disabled: !this.selectedStop }"
          @click="addSettings"
        >
          Save settings
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  import SendButton from '@/components/common/SendButton.vue';
  import ColorPicker from '@/components/ColorPicker.vue';
  import TimeTableViewer from '@/components/common/timetable/TimeTableViewer.vue';
  import { TL_LANGUAGES, TL_TRAFFIC_TYPES } from '@/config/constants';
  import { ToggleButton } from 'vue-js-toggle-button/src';

  export default {
    name: 'TimeTableSettings',

    components: { SendButton, ColorPicker, TimeTableViewer, ToggleButton },

    props: {
      trafikLabData: {
        type: Array,
        default: [],
      },
      timeTableData: {
        type: Array,
        default: [],
      },
      editMode: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        timetableSettings: {},
        selectedStop: null,

        show: false,
        hideTime: 0,
        playTime: '30',
        stop: null,
        language: 'Swedish',
        trafficTypes: null,

        playTimesOptions: [
          {
            label: '15 : 00',
            value: '15',
          },
          {
            label: '30 : 00',
            value: '30',
          },
          {
            label: '1 : 00 : 00',
            value: '60',
          },
        ],
        colors: {
          title: {
            fontType: 'Open Sans',
            fontStyle: 'Regular',
            fontColor: '#000000',
          },
          departures: {
            fontType: 'Open Sans',
            fontStyle: 'Regular',
            fontColor: '#000000',
          },
          underline: '#FFFFFF',
          evenDeparturesBackground: '#FFFFFF',
          oddDeparturesBackground: '#EAEAEA',
          background: {
            transparency: 0,
            color: '#FFFFFF',
          },
        },
        trafiklabStops: [],
        availableTrafficTypes: TL_TRAFFIC_TYPES,
        languages: TL_LANGUAGES,
      };
    },

    created() {
      this.trafiklabStops = this.trafikLabData;
      this.selectedStop = this.trafikLabData[0];

      if (this.timeTableData) {
        this.timeTableData.forEach((timetable) => {
          this.timetableSettings[timetable.stop.id] = timetable;
        });
      }
    },

    beforeMount() {
      if (!this.trafficTypes) {
        this.trafficTypes = {};
      }
      this.availableTrafficTypes.forEach((type) => {
        const value = this.trafficTypes[type] ? this.trafficTypes[type] : false;
        this.$set(this.trafficTypes, type, value);
      });
    },

    watch: {
      selectedStop: function (value) {
        if (value) {
          this.stop = value;
          const settings = this.selectedSettings;
          this.show = settings?.show === undefined ? false : settings.show;
          this.hideTime = settings?.hideTime || 0;
          this.playTime = settings?.playTime || '30';
          this.language = settings?.language || this.languages.Swedish;
          this.trafficTypes = {};
          this.availableTrafficTypes.forEach((type) => {
            this.$set(this.trafficTypes, type, settings?.trafficTypes[type] === true);
          });
        }
      },
    },

    computed: {
      selectedSettings() {
        if (this.selectedStop && this.timetableSettings[this.selectedStop.id]) {
          return this.timetableSettings[this.selectedStop.id];
        }
        return null;
      },
    },

    methods: {
      addSettings() {
        if (!this.selectedStop) {
          this.$toasted.global.general_error({ message: 'Select a stop first.' });
          return;
        }
        const hasTransportType = this.availableTrafficTypes.some((type) => this.trafficTypes[type]);

        if (!hasTransportType) {
          this.$toasted.global.general_error({ message: 'Select at least one traffic type' });
          return;
        }
        this.timetableSettings[this.selectedStop.id] = {
          show: this.show,
          hideTime: this.hideTime,
          playTime: this.playTime,
          stop: this.stop,
          language: this.language,
          trafficTypes: this.trafficTypes,
        };
        this.$forceUpdate();
        this.$toasted.global.general_success({
          message: 'Settings updated for the stop ' + this.stop.name + ' - ' + this.stop.id,
        });
      },
      save() {
        if (this.timetableSettings) {
          const values = Object.values(this.timetableSettings);
          if (values.length) {
            this.$emit('onUpdate', values);
            return;
          }
        }
        this.$toasted.global.general_error({ message: 'No settings are found!' });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .preview-container {
    min-height: 200px;
    margin-bottom: 10px;
  }

  .settings {
    padding: 14px;

    select {
      color: black;
      cursor: pointer;
    }
  }

  .toggle-group {
    display: flex;
    gap: 12px;
    align-items: center;

    label {
      margin: 0;
    }
  }

  .accordion {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .accordion-header {
      display: flex;
      width: 100%;
      justify-content: space-between;
      cursor: pointer;

      h5 {
        font-size: 20px;
        color: $primaryDark;
        font-weight: 400;
      }

      i {
        justify-self: flex-end;
      }
    }

    .accordion-body {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      padding: 0 6px;
      max-height: 0px;

      transition: all 0.5s ease-in-out;
      overflow: hidden;

      &.open {
        max-height: 2000px;
        padding-top: 12px;
        padding-bottom: 12px;
        overflow: inherit;
      }
    }
  }

  .slider-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 12px;

    .slider {
      width: 100%;
      margin: 0;

      input[type='range'] {
        &::-webkit-slider-thumb {
          -webkit-appearance: none; /* Override default look */
          appearance: none;
          background: $darkblue;
        }

        &::-moz-range-thumb {
          background: $darkblue;
        }
      }
    }

    #hideTimeBox,
    #transparencyBox {
      width: 70px;
      padding: 6px;
    }

    input {
      text-align: center;
    }
  }

  .buttons-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 24px;
  }

  .font-style-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    column-gap: 12px;

    label {
      min-width: 130px;
    }

    .color-picker {
      display: flex;
    }
  }
</style>
