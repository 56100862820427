var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"layout-wrapper scrollbar"},[_c('div',{staticClass:"background layout-container",style:({
      height: `${_vm.layoutDimensions.height}px`,
      width: `${_vm.layoutDimensions.width}px`,
      backgroundColor: _vm.layout?.settings.backgroundColor || '#FFFFFF',
    }),on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"layout"},[_c('DesignerToolbar',{staticClass:"toolbar",attrs:{"selectedWidget":_vm.selectedWidget,"show":_vm.showToolbar}}),_c('div',{staticClass:"draggable-container noselect"},[_vm._l((_vm.widgets),function(widget){return _c('Layer',{key:widget.assoc_id,attrs:{"widget":widget}})}),_vm._l((_vm.vLine),function(item,index){return _c('span',{directives:[{name:"show",rawName:"v-show",value:(item.display),expression:"item.display"}],key:`vertical-${index}`,staticClass:"ref-line v-line",style:({
            left: parseFloat(item.position) > 0 ? item.position : 0,
            top: parseFloat(item.origin) > 0 ? item.origin : 0,
            height: _vm.trimLength(item, 'height'),
            background: '#e3003a',
            border: '1px solid #e3003a',
          })})}),_vm._l((_vm.hLine),function(item,index){return _c('span',{directives:[{name:"show",rawName:"v-show",value:(item.display),expression:"item.display"}],key:`horizontal-${index}`,staticClass:"ref-line h-line",style:({
            top: parseFloat(item.position) > 0 ? item.position : 0,
            left: parseFloat(item.origin) > 0 ? item.origin : 0,
            width: _vm.trimLength(item, 'width'),
            background: '#e3003a',
            border: '1px solid #e3003a',
          })})})],2),_c('div',{staticClass:"layout-grid",style:(_vm.gridStyle)})],1)]),(_vm.showDeleteWidgetModal)?_c('DeleteWidgetModal',{attrs:{"widget":_vm.selectedWidget},on:{"closeModal":function($event){_vm.showDeleteWidgetModal = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }