<template>
  <div class="divider" :style="{ border: `solid ${thickness}px  ${color}` }" v-bind="$attrs" />
</template>

<script>
  export default {
    name: 'BaseDivider',
    props: {
      color: {
        type: String,
        default: '#f0f0f0',
      },
      thickness: {
        type: Number,
        default: 1,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .divider {
    margin: 4px;
  }
</style>
