<script lang="ts">
  export default {
    name: 'RichTextEditorModal',
  };
</script>

<script setup lang="ts">
  import { ref } from 'vue';

  const emit = defineEmits<{ (event: 'saveClicked'): void }>();

  const show = ref(false);

  function showModal(value: boolean) {
    show.value = value;
  }

  function handleSaveButtonClicked() {
    emit('saveClicked');
    show.value = false;
  }
</script>

<template>
  <div>
    <BaseButton class="edit-button" theme="secondary" @click="showModal(true)">Edit</BaseButton>
    <Transition name="fade">
      <div v-if="show" class="modal-container">
        <div class="content-section">
          <slot></slot>
        </div>
        <div class="footer-section">
          <BaseButton theme="secondary" size="sm" @click="showModal(false)">Close</BaseButton>
          <BaseButton theme="primary" size="sm" icon="save" @click="handleSaveButtonClicked()">
            Save Changes
          </BaseButton>
        </div>
      </div>
    </Transition>
  </div>
</template>

<style scoped lang="scss">
  .modal-container {
    position: absolute;
    z-index: 2000;
    display: flex;
    flex-direction: column;
    padding: 24px 34px 0 34px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95vw;
    height: 95vh;
    max-width: 95vw;
    max-height: calc(-64px + 95vh);
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow:
      rgba(9, 30, 66, 0.15) 0px 8px 12px 0px,
      rgba(9, 30, 66, 0.31) 0px 0px 1px 0px;

    // laptop
    @media (min-width: 1280px) {
      width: calc(-248px + 95vw);
      max-width: calc(-248px + 95vw);
    }

    // desktop
    @media (min-width: 1440px) {
      width: calc(-548px + 95vw);
      max-width: calc(-548px + 95vw);
    }
  }

  .content-section {
    display: flex;
    width: 100%;
    flex-grow: 1;
    overflow: hidden;
  }

  .footer-section {
    display: flex;
    flex-direction: row;
    padding: 24px 0;
    gap: 12px;
    justify-content: flex-end;
    width: 100%;
    bottom: 0;
    border-top: solid 1px #e3e3e3;
  }

  .edit-button {
    width: 100%;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>
