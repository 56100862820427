module.exports = {
  welcome: 'Welcome',
  to: 'to',
  activities_title : 'Actividades',
  courts_title: 'Pistas',
  book_with: 'Reserva con',
  members: 'Members',
  playing_now: 'Jugando ahora mismo',
  vacant_time: 'Ledig bana',
  vacant_times: 'Lediga banor',
  tracks: 'Pistas',
  offers_for_you: 'Ofertas para ti',
  contact_us: 'Contáctenos en recepción para más información',
  available_time: 'Disponible',

  monday: 'Lunes',
  tuesday: 'Martes',
  wednesday: 'Miércoles',
  thursday: 'Jueves',
  friday: 'Viernes',
  saturday: 'Sábado',
  sunday: 'Domingo',

  game_strength: 'Nivel de habilidad',
  players: 'Jugadores',

  app_description: 'Do you play tennis, badminton, table tennis, squash or paddle? Become a member of Matchi and make your everyday life more flexible.'
};
