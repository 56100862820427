<template>
  <div class="item-toolbar search-background form-row pt-4 pb-4">
    <div class="form-group col-8 align-middle mb-0">
      <div class="input-group flex-nowrap">
        <div class="input-group-prepend">
          <div class="valign-center pr-2 cursor-pointer" @click="focusSearch">
            <!--<i class="material-icons search-icon">search</i>-->
            <div class="search-icon"></div>
          </div>
        </div>
        <input
          type="search"
          name="search-query"
          id="search-query"
          ref="searchInput"
          class="search-background"
          @input.prevent="onDebouncedSearchChanged"
        />
      </div>
    </div>

    <div class="form-group col-1 offset-3 valign-center mb-0">
      <input
        class="custom-range"
        type="range"
        name="list-type"
        id="list-type"
        min="0"
        :max="sliderMax"
        :value="defaultListType"
        step="1"
        @change.prevent="onListTypeChanged"
      />
    </div>
  </div>
</template>

<script>
  import _ from 'lodash';

  export default {
    name: 'ItemToolbar',

    props: {
      // how many different type of list displays
      listTypeCount: {
        type: Number,
        required: false,
        default: 3,
      },

      defaultListType: {
        type: Number,
        required: false,
        default: 0,
      },
    },

    computed: {
      sliderMax() {
        return this.listTypeCount - 1; // 0-index
      },
    },

    data() {
      return {
        query: '',
      };
    },

    methods: {
      onSearchChanged(event) {
        this.$emit('search', event.target.value);
      },

      onListTypeChanged(event) {
        this.$emit('list-type', parseInt(event.target.value));
      },

      focusSearch() {
        this.$refs.searchInput.focus();
      },
    },

    created() {
      this.onDebouncedSearchChanged = _.debounce(this.onSearchChanged, 1000);
    },
  };
</script>

<style lang="scss" scoped>
  .search-icon {
    background: transparent;
    background: url('../assets/img/search.svg') no-repeat;
    background-size: 26px 26px;
    height: 26px;
    width: 26px;
  }
</style>
