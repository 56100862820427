<template>
  <Modal
    @closeModal="closeModal"
    v-show="showModal"
    :title="'Schedule'"
    :isSaving="isLoading"
    :disabled="itemDuration < 1"
    :okFunction="updateDuration"
    :modalStyles="{ width: '400px' }"
    avoidClickOutside
  >
    <template v-slot:body>
      <div class="group-body">
        <div class="form-group">
          <label for="itemDuration">
            <div class="label">Item display duration (seconds)</div>
            <input
              type="number"
              name="itemDuration"
              id="itemDuration"
              required
              min="0"
              max="32000000"
              v-model="itemDuration"
              @input="checkDuration"
              placeholder="Set time"
            />
          </label>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
  import Modal from '@/components/common/Modal.vue';

  import { restrictToBounds } from '@/helpers/draggable';

  export default {
    name: 'PlaylistItemDurationModal',

    components: { Modal },

    props: {
      playlistItem: {
        type: Object,
        default: () => null,
      },
    },

    data() {
      return {
        itemDuration: 15,
        showModal: false,
        isLoading: false,
      };
    },

    watch: {
      playlistItem() {
        this.itemDuration = this.playlistItem ? this.playlistItem.display_timer : 15;
      },
    },

    mounted() {
      this.showModal = true;
      this.itemDuration = this.playlistItem ? this.playlistItem.display_timer : 15;
    },

    methods: {
      async updateDuration() {
        this.isLoading = true;

        this.$emit('setSchedule', {
          assocId: this.playlistItem.assoc_id,
          overrideId: this.playlistItem.override?.id,
          duration: this.itemDuration,
          wid: this.playlistItem.wid,
        });

        this.closeModal();
        this.isLoading = false;
      },

      closeModal() {
        this.showModal = false;
        this.$emit('closeModal');
      },

      checkDuration() {
        const value = this.itemDuration ? parseInt(this.itemDuration) : 15;
        this.itemDuration = restrictToBounds(value, 1, 32000000);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .group-body {
    display: grid;
    row-gap: 24px;
    column-gap: 16px;

    .form-group {
      width: 100%;
      margin-bottom: 0px;

      label {
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
        gap: 8px;

        font-weight: 500;
        line-height: 24px;

        .label {
          display: flex;
          gap: 8px;
          align-items: center;

          font-size: 14px;
        }

        input,
        select {
          padding: 8px 16px;

          border: 2px solid #e8e8e8;
          border-radius: 8px;
          font-size: 16px;
        }
      }
    }

    .description {
      grid-column: 1/3;
    }
  }
</style>
