<template>
  <Modal
    @closeModal="closeModal"
    v-show="showModal"
    :disabled="this.playlistName.length < 3"
    title="New Area"
    :okFunction="createPlaylist"
    okButtonLabel="Create"
    :modalStyles="{ width: '350px' }"
  >
    <template v-slot:body>
      <div class="group-body">
        <BaseInput type="text" label="Name" v-model="playlistName" />
      </div>
    </template>
  </Modal>
</template>

<script>
  import Modal from '@/components/common/Modal.vue';

  export default {
    name: 'CreatePlaylistModal',

    components: {
      Modal,
    },

    data() {
      return {
        showModal: false,
        playlistName: '',
      };
    },

    mounted() {
      this.showModal = true;
    },

    methods: {
      async createPlaylist() {
        const data = {
          name: this.playlistName,
          description: null,
          transition_mode: 'fade',
        };

        this.$emit('createPlaylist', data);
        this.closeModal();
      },

      reset() {
        this.playlistName = '';
      },

      closeModal() {
        this.showModal = false;
        this.$emit('closeModal');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .group-body {
    display: grid;
    row-gap: 24px;
    column-gap: 16px;

    .form-group {
      width: 100%;
      margin-bottom: 0px;

      label {
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
        gap: 8px;

        font-weight: 500;
        line-height: 24px;

        .label {
          display: flex;
          gap: 8px;
          align-items: center;

          font-size: 14px;
        }

        input,
        select {
          padding: 8px 16px;

          border: 2px solid $borderGrey;
          border-radius: 8px;
          font-size: 16px;
        }
      }
    }

    .description {
      grid-column: 1/3;
    }
  }
</style>
