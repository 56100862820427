import '@tiptap/extension-text-style';

import { Extension } from '@tiptap/core';
import { MarkType, NodeType } from '@tiptap/pm/model';

export interface ExtendedColorOptions {
  types: string[];
  getStyle: (color: string) => string;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    extendedColor: {
      /**
       * Set the color of a node or mark
       */
      setNodeColor: (typeOrName: string | NodeType | MarkType, color: string) => ReturnType;
      /**
       * Unset the color of a node or mark
       */
      unsetNodeColor: (typeOrName: string | NodeType | MarkType) => ReturnType;
    };
  }
}

export const ExtendedColor = Extension.create<ExtendedColorOptions>({
  name: 'extendedColor',

  addOptions() {
    return {
      types: ['textStyle'],
      getStyle: (color: string) => {
        return `color: ${color}`;
      },
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          color: {
            default: null,
            parseHTML: (element) => element.style.color?.replace(/['"]+/g, ''),
            renderHTML: (attributes) => {
              if (!attributes.color) {
                return {};
              }

              return {
                style: this.options.getStyle(attributes.color),
              };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setNodeColor:
        (typeOrName, color) =>
        ({ commands }) => {
          return commands.updateAttributes(typeOrName, { color: color });
        },
      unsetNodeColor:
        (typeOrName) =>
        ({ commands }) => {
          return commands.resetAttributes(typeOrName, 'color');
        },
    };
  },
});
