<template>
  <div class="card-grid">
    <div class="row card-decks mx-n2" v-for="(r, index) in rows" :key="index">
      <div :class="[colCss]" class="px-2" v-for="(c, cIndex) in itemsPerRow" :key="cIndex">
        <div
          v-if="items[index * itemsPerRow + cIndex]"
          :class="[
            getItemClass(index, itemsPerRow, cIndex),
            'border border-white mb-3 flex-grow-1 card-container',
          ]"
          @click.prevent="onItemClicked(index * itemsPerRow + cIndex)"
        >
          <slot :item="items[index * itemsPerRow + cIndex]" :index="index * itemsPerRow + cIndex">
            <DefaultCard :item="items[index * itemsPerRow + cIndex]" :item-desc="itemDesc" />
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import DefaultCard from './DefaultCard';

  export default {
    name: 'CardGrid',

    components: {
      DefaultCard,
    },

    props: {
      items: {
        type: Array,
        required: true,
      },

      itemsPerRow: {
        type: Number,
        default: 3,
        required: false,
      },

      value: {
        type: Number,
        required: false,
        default: -1,
      },

      itemDesc: {
        required: false,
        type: String,
        default: 'App',
      },

      activeItems: {
        type: Array,
        required: false,
        default: () => [],
      },
    },

    computed: {
      colCss() {
        return `col-${Math.round(12 / this.itemsPerRow)}`;
      },
      rows() {
        return Math.ceil(this.items.length / this.itemsPerRow);
      },
    },

    methods: {
      onItemClicked(index) {
        this.$emit('input', index);
      },
      getItemClass(index, itemsPerRow, cIndex) {
        let itemClass = '';

        if (this.activeItems.indexOf(index) !== -1) {
          itemClass += 'active ';
        }

        if (index * itemsPerRow + cIndex === this.value) {
          itemClass += 'border-primary-red';
        }

        return itemClass;
      },
      desc(item) {
        return item.desc || this.itemDesc;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .card-grid {
    .card-container {
      background-color: #efefef;
    }
  }
</style>
