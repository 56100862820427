<template>
  <SidebarContainer>
    <div class="d-flex flex-column justify-content-center align-items-center h-100 px-1">
      <div class="icon">
        <span class="material-icons-outlined align-middle">dashboard</span>
      </div>

      <p class="font-whyte-ink title text-center mb-1">Create & Manage Layouts</p>

      <p class="font-poppins description text-center mb-4">
        You can create different types of layouts and manage them.
      </p>

      <div class="buttons-container w-100">
        <Button class="w-100" theme="primary" icon="add" @click="$emit('onCreate')">
          Create Layout
        </Button>

        <Button
          v-show="templatesButton"
          class="w-100"
          theme="secondary"
          icon="dashboard_customize"
          @click="$emit('manageTemplates')"
        >
          Manage Layout Templates
        </Button>
      </div>
    </div>
  </SidebarContainer>
</template>

<script>
  import Button from '@/components/common/Button.vue';
  import SidebarContainer from '@/components/common/SidebarContainer.vue';

  export default {
    name: 'LayoutSidebarPlaceholder',

    components: { SidebarContainer, Button },

    props: {
      templatesButton: {
        type: Boolean,
        default: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .icon {
    margin-bottom: 20px;

    span {
      font-size: 64px;
      color: #e3003a;
    }
  }
  .title {
    font-size: 24px;
    color: #000;
    font-weight: 400;
    max-width: 266px;
  }
  .description {
    font-size: 16px;
    color: $secondaryText;
    font-weight: 500;
    max-width: 322px;
  }

  .buttons-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
</style>
