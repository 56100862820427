<template>
  <div :class="courtClasses">
    <div :class="[`matchi-courts`] ">
      <div class="matchi-card card-shadow" v-for="court in courtList" :key="court.index">
        <div class="matchi-card-header">
          <div class="matchi-card-header-icon">
            <img v-if="court.sport.type === 'BADMINTON'" src="../../assets/icon/icon-w-bg/BadmintonIcon.png">
            <img v-if="court.sport.type === 'PADEL'" src="../../assets/icon/icon-w-bg/PadelIcon.png">
            <img v-if="court.sport.type === 'PICKLEBALL'" src="../../assets/icon/icon-w-bg/PickleballIcon.png">
            <img v-if="court.sport.type === 'SQUASH'" src="../../assets/icon/icon-w-bg/SquashIcon.png">
            <img v-if="court.sport.type === 'TABLETENNIS'" src="../../assets/icon/icon-w-bg/TableTennisIcon.png">
            <img v-if="court.sport.type === 'TENNIS'" src="../../assets/icon/icon-w-bg/TennisIcon.png">
<!--            <img v-if="court.sport.type === 'BOWLING'" src="../../assets/icon/icon-w-bg/BowlingIcon.png">-->
            <img v-if="court.sport.type === 'GOLF'" src="../../assets/icon/icon-w-bg/GolfIcon.png">
            <img v-if="court.sport.type === 'FLOORBALL'" src="../../assets/icon/icon-w-bg/FloorballIcon.png">
<!--            <img v-if="court.sport.type === 'SHUFFLEBOARD'" src="../../assets/icon/icon-w-bg/ShuffleboardIcon.png">-->
            <img v-if="court.sport.type === 'TRAMPOLINE'" src="../../assets/icon/icon-w-bg/TrampolineIcon.png">
            <img v-if="court.sport.type === 'YOGA'" src="../../assets/icon/icon-w-bg/YogaIcon.png">
            <img v-if="court.sport.type === 'CAGEBALL'" src="../../assets/icon/icon-w-bg/TennisIcon.png">
            <img v-if="court.sport.type === 'OTHER'" src="../../assets/icon/icon-w-bg/OtherIcon.svg">
          </div>
          <div class="matchi-card-header-title">
            <div>{{court.name}}</div>
            <div>
              {{court.sport.type.toLowerCase() | capitalize}}.
              {{court.indoor ? "Indoor" : "Outdoor"}}.
              {{court.surface.toLowerCase() | capitalize}}.
            </div>
          </div>
        </div>
        <div class="matchi-card-body">
          <div
            v-for="slot in courtsLenght > 4 ? court.upcomingSlots.slice(0, 2) : court.upcomingSlots"
            :class="['matchi-card-body-item', {'active': slot.active}]"
            :key="slot.id"
          >
            <div class="time">{{slot.start}} - {{slot.end}}</div>
            <div class="name">{{getPlayerNames(slot)}}</div>
          </div>
        </div>
      </div>

    </div>
    <MatchiOffers v-if="matchiSettings.offers && validOffers.length > 0" :validOffers="validOffers" />
  </div>
</template>

<script>
import MatchiOffers from "./MatchiOffers";

export default {
  name: "MatchiCourts",
  props: [
    "getPlayerNames",
    "timeOut",
    "matchiSettings",
    "validOffers",
    "courtList",
    "courtsLenght"
    ],
  components:{
    MatchiOffers,
  },
  data() {
    return {
      timer: null,
      isNextCourts: false,
      courtClasses: {
        'courtsWrapper': this.matchiSettings.offers && this.validOffers.length > 0,
        'fourCourts': this.courtsLenght <= 4
      }
    };
  },
  mounted() {
    this.timer = setTimeout(() => {
      this.isNextCourts = true;
    }, this.timeOut / 2);
  },
  watch:{
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
};
</script>
