<template>
  <Modal
    @closeModal="closeModal"
    v-show="showModal"
    :isSaving="isSaving"
    :disabled="templateName.length < 3 || templateName.length > 40"
    :modalStyles="{ width: '400px' }"
    title="Saving copy as template"
    :okFunction="saveAsTemplate"
    okButtonLabel="Save"
  >
    <template v-slot:body>
      <div class="form-group">
        <div class="form-group template-input name">
          <label for="template-name">Name</label>
          <input
            class="form-control"
            type="text"
            id="template-name"
            placeholder="Template's name"
            minlength="3"
            maxlength="40"
            v-model="templateName"
          />
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
  import Modal from '@/components/common/Modal.vue';

  import { apiCreateTemplateFromTile } from '@/api/tiles';
  import { UPDATE_TEMPLATE_WIDGET_THUMBNAIL } from '@/store/actions/templateDesigner';

  export default {
    name: 'SaveTileAsTemplateModal',

    components: {
      Modal,
    },

    props: {
      tile: {
        type: Object,
        default: () => null,
      },
      overrideIds: {
        type: Array,
        default: () => [],
      },
      screenId: {
        type: Number,
        default: null,
      },
      groupId: {
        type: Number,
        default: null,
      },
    },

    data() {
      return {
        showModal: false,
        isSaving: false,
        templateName: '',
      };
    },

    mounted() {
      this.showModal = true;
    },

    methods: {
      async saveAsTemplate() {
        this.isSaving = true;

        try {
          const payload = {
            name: this.templateName,
            tileId: this.tile.template_id,
            overrideIds: this.overrideIds,
            thumbnail: await this.$store.dispatch(UPDATE_TEMPLATE_WIDGET_THUMBNAIL),
            screenId: this.screenId,
            groupId: this.groupId,
          };

          await apiCreateTemplateFromTile(payload);

          this.$toasted.global.general_success({
            message: `Template successfully saved`,
          });
        } catch (error) {
          console.log(String(error));
          this.$toasted.global.general_error({
            message: 'Sorry, unable to save as a template',
          });
        }

        this.isSaving = false;
        this.closeModal();
      },

      closeModal() {
        this.showModal = false;
        this.$emit('closeModal');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .form-group {
    width: 100%;
    margin-top: 24px;

    label {
      display: flex;
      flex-direction: column;
      margin-bottom: 8px;
      gap: 8px;

      font-weight: 500;
      line-height: 24px;
      font-size: 16px;

      input {
        padding: 8px 16px;

        border: 2px solid $borderGrey;
        border-radius: 8px;
        font-size: 16px;
      }
    }
  }
</style>
