<template>
  <BaseModal title="Layout Swap" ref="modal" class="swap-layout-modal" :scrollable="true">
    <template #subTitle>
      This module lets you to swap all existing group layout to new one.
    </template>
    <template #bodyFixed v-if="!isLoading">
      <div class="mb-3">
        <SearchBox v-model="searchQuery" />
      </div>
    </template>
    <template #default v-if="isLoading">
      <div class="mt-2"><Loader /></div>
    </template>
    <template #default v-else>
      <LayoutsList
        :layouts="filteredLayouts"
        :showArrow="false"
        :activeLayoutId="activeLayoutId"
        v-on:select="(id) => (activeLayoutId = id)"
      />
    </template>

    <template #footer v-if="!isLoading">
      <BaseButton theme="secondary" size="sm" @click.native="close" class="mr-3">Cancel</BaseButton>
      <BaseButton theme="primary" size="sm" @click="swapLayouts" :loading="swappingLayouts"
        >Accept</BaseButton
      >
    </template>
  </BaseModal>
</template>

<script>
  import LayoutsList from './LayoutsList.vue';
  import Loader from '@/components/common/Loader.vue';
  import SearchBox from '../common/SearchBox.vue';
  import { apiReadLayouts } from '@/api/layouts';
  import { apiAddScreenLayout } from '@/api/screens';

  export default {
    components: { LayoutsList, Loader, SearchBox },
    inject: ['getGroupScreens', 'reloadScreensData'],
    data() {
      return {
        isLoading: true,
        swappingLayouts: false,
        searchQuery: '',
        layouts: [],
        activeLayoutId: null,
      };
    },
    computed: {
      groupScreens() {
        return this.getGroupScreens();
      },
      filteredLayouts() {
        return this.layouts.filter((layout) => {
          return layout.settings.name.toLowerCase().includes(this.searchQuery.toLowerCase());
        });
      },
    },
    methods: {
      async fetchData() {
        this.isLoading = true;
        await this.fetchLayouts();
        this.isLoading = false;
      },
      async fetchLayouts() {
        let res = await apiReadLayouts();
        this.layouts = res.data.items;
      },

      async swapLayouts() {
        if (!this.activeLayoutId) return;

        this.swappingLayouts = true;
        try {
          let promises = [];

          this.groupScreens
            .filter((screen) => screen.layout !== this.activeLayoutId)
            .forEach((screen) => {
              promises.push(apiAddScreenLayout(screen.id, this.activeLayoutId));
            });

          await Promise.all(promises);
          // Success toast
          this.$toasted.global.general_success({
            message: 'Layouts swapped successfully',
          });
          this.$emit('swap');
          this.reloadScreensData();
          this.close();
        } catch (e) {
          // Error toast
          this.$toasted.global.general_error({
            message: e.toString(),
          });
        }
        this.swappingLayouts = false;
      },
      open() {
        this.fetchData();
        this.$refs.modal.open();
      },
      close() {
        this.$refs.modal.close();
      },
    },

    created() {
      this.fetchData();
    },
  };
</script>

<style lang="scss" scoped>
  .swap-layout-modal {
    .list-container {
      max-height: 100%;
    }
    ::v-deep .ui-modal-content {
      width: 391px !important;
      height: 640px;

      .ui-modal-body {
        padding-top: 16px !important;
        padding-bottom: 16px !important;
      }
    }
  }
</style>
