<template>
  <div class="sidebar-setting-placeholder">
    <div class="rss-preview">
      <RssViewer :setting="rssSetting" isPreview />
    </div>
    <div class="instructions mt-3 d-flex flex-column align-items-center">
      <form
        class="sidebar-setting-content"
        @change="onInputChange"
        @submit.prevent="goToWidgetSettings"
      >
        <div class="form-group">
          <label class="t-sm font-poppins" for="rss-duration">Duration</label>
          <input
            type="number"
            class="form-control input-underline"
            v-model="rssDuration"
            id="rss-duration"
            min="0"
            placeholder="Duration"
          />
        </div>
        <div class="form-group">
          <label class="t-sm font-poppins" for="rss-link">Link</label>
          <input
            type="text"
            class="form-control input-underline"
            v-model="rssLink"
            id="rss-link"
            placeholder="Link"
          />
        </div>
        <div>
          <div>
            <label class="t-sm font-poppins">Theme</label>
          </div>
          <div class="custom-control custom-radio custom-control-inline">
            <input
              type="radio"
              class="custom-control-input"
              id="themeColorWhite"
              name="white"
              value="white"
              v-model="rssTheme"
            />
            <label class="custom-control-label t-sm font-poppins" for="themeColorWhite">
              White
            </label>
          </div>
          <div class="custom-control custom-radio custom-control-inline">
            <input
              type="radio"
              class="custom-control-input"
              id="themeColorBlack"
              name="black"
              value="black"
              v-model="rssTheme"
            />
            <label class="custom-control-label t-sm font-poppins" for="themeColorBlack">
              Black
            </label>
          </div>
        </div>
        <button
          type="submit"
          class="btn btn-danger bg-primary-red app-setting-submit-btn btn-lg rounded-xl"
          :disabled="appCreateStatus === 'loading'"
        >
          <img :src="plusIcon" alt="Plus icon" /> Create Widget
        </button>
      </form>
    </div>

    <AppWidgetModal
      app-name="RSS"
      :open="showWidgetModal"
      :default-data="{ name: rssName, folder, schedule }"
      @onSave="handleSubmit"
      @onClose="closeModal"
    />
  </div>
</template>

<script>
  import AppInfo from '@/components/apps/AppInfo.vue';
  import AppWidgetModal from '@/components/apps/AppWidgetModal.vue';
  import RssViewer from '../rssViewer/Index.vue';
  import plusIcon from '@/assets/icon/icon-plus-square.png';
  import { RSS_APP_CREATE } from '@/store/actions/apps';
  import { mapState } from 'vuex';
  import { getRSSAppSettings } from '@/helpers/utils';

  export default {
    name: 'RssAppSetting',

    components: {
      RssViewer,
      AppInfo,
      AppWidgetModal,
    },

    data() {
      return {
        plusIcon,
        showWidgetModal: false,
        ...this.getInitialData(),
      };
    },

    methods: {
      validateForm() {
        if (this.rssDuration <= 0) {
          return 'Duration must be positive number';
        }

        if (this.rssLink <= 0) {
          return 'Please enter a rss link';
        }

        return '';
      },

      onInputChange(e) {
        let id = e.target.id;
        // trigger event for preview slider if we have enough data
        if (
          id === 'rss-duration' ||
          id === 'rss-link' ||
          id === 'themeColorWhite' ||
          id === 'themeColorBlack'
        ) {
          if (this.rssDuration > 0 && this.rssLink.length) {
            this.rssSetting = {
              rssDuration: this.rssDuration,
              rssLink: this.rssLink,
              rssTheme: this.rssTheme,
              display: {
                ...this.rssSetting.display,
                duration: this.rssDuration,
              },
            };
          }
        }
      },

      goToWidgetSettings() {
        const error = this.validateForm();

        if (error.length) {
          this.$toasted.global.general_error({ message: error });
          return;
        }
        this.showWidgetModal = true;
      },

      handleSubmit({ name, folder, schedule }) {
        this.rssName = name;
        this.folder = folder;
        this.schedule = schedule;

        let data = {
          type: 'rss',
          name: this.rssName,
          duration: this.rssSetting.rssDuration,
          link: this.rssSetting.rssLink,
          theme: this.rssSetting.rssTheme,
          folder: this.folder,
          schedule: this.schedule,
          display: {
            ...this.rssSetting.display,
            duration: this.rssSetting.rssDuration,
          },
        };

        this.$store
          .dispatch(RSS_APP_CREATE, data)
          .then(() => {
            this.$toasted.global.general_success({ message: 'Rss app added successfully' });
            this.closeModal();
            Object.assign(this.$data, this.getInitialData());
          })
          .catch((error) => {
            this.$toasted.global.general_error({
              message: 'Error adding app, please try again later',
            });
          });
      },

      closeModal() {
        this.showWidgetModal = false;
      },

      getInitialData: function () {
        return {
          rssName: '',
          rssDuration: 5,
          rssLink: '',
          rssTheme: 'white',
          folder: null,
          schedule: null,
          rssSetting: getRSSAppSettings({
            link: '',
            theme: 'white',
            time: 10,
          }),
        };
      },
    },

    computed: {
      ...mapState({
        appCreateStatus: (state) => state.apps.appCreateStatus,
      }),
    },

    watch: {
      appCreateStatus: function (newVal) {
        // watch it
        if (newVal === 'success') {
          Object.assign(this.$data, this.getInitialData());
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .sidebar-setting-placeholder {
    .rss-preview {
      height: 17vmax;
      overflow: hidden;
    }
    .app-setting-submit-btn {
      margin-top: 3rem;
      width: 100%;
    }
  }

  .input-underline {
    padding-left: 0;
    border: none;
    border-bottom: 1px solid lightgray;

    &:focus {
      outline: none;
      box-shadow: none !important;
    }
  }
</style>
