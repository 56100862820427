export enum ContentStatus {
  Undefined = 'undefined',
  Draft = 'draft',
  Published = 'published',
}

export enum PublicationType {
  ScreenPublication = 'screen_publication',
  ScreenGroupPublication = 'screen_group_publication',
}

export interface ScreenPublicationTypeData {
  screen_ids: number[];
}

export interface ScreenGroupPublicationTypeData {
  screen_group_id: number;
  layout_id: string | null;
}

export type ScreenCodeResponse = {
  code: string;
};

export type ScreenCodeConnect = {
  message: string;
};
