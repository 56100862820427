<template>
  <div class="roles-container">
    <div v-if="roles?.length > 0" class="roles-list">
      <div class="role" v-for="role in roles" v-bind:key="role.role_id" :id="role.role_id">
        <div class="name">
          {{ role.name }}
        </div>

        <div class="right">
          <div class="users">
            {{ role.users }}
            <i class="material-icons-outlined align-middle">person_outline</i>
          </div>

          <div class="dropdown">
            <i
              class="material-icons align-middle more-buttons dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              more_vert
            </i>

            <div class="role-options dropdown-menu dropdown-menu-right">
              <button type="button" class="dropdown-item" @click="openRolesModal(role)">
                <i class="material-icons align-middle">edit</i>
                <!-- {{ $lang.organizations.rolesList.contextMenu.editLayers }} -->
                Edit Role
              </button>

              <button type="button" class="dropdown-item" @click="openRemoveRoleModal(role)">
                <i class="material-icons align-middle">delete</i>
                {{ $lang.organizations.rolesList.contextMenu.remove }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="no-data">No roles added</div>

    <button type="button" class="button-2-third w-100" @click="openRolesModal()">
      <i class="material-icons-outlined align-middle">add</i>
      Add Role
    </button>

    <RolesModal
      @closeModal="closeRolesModal"
      :role="selectedRole"
      v-if="showRoleModal"
      :title="$lang.organizations.rolesModal.editTitle"
    />

    <RemoveRoleModal
      @closeModal="showRemoveRoleModal = false"
      :role="selectedRole"
      v-if="showRemoveRoleModal"
    />
  </div>
</template>

<script>
  import RolesModal from './RolesModal.vue';
  import RemoveRoleModal from './RemoveRoleModal.vue';
  import {GET_ORGANISATION_ROLES} from "@/store/actions/roles";

  export default {
    name: 'RolesList',

    components: { RolesModal, RemoveRoleModal },

    data() {
      return {
        selectedRole: null,
        showRoleModal: false,
        showRemoveRoleModal: false,
      };
    },

    computed: {
      roles() {
        return this.$store.state.roles.roles.map((role) => ({
          users: role.total_users,
          ...role,
        }));
      },
      isAdmin() {
        return this.$store.getters.isAdministrator;
      }
    },

    async created() {
      await this.$store.dispatch(GET_ORGANISATION_ROLES);
    },

    methods: {
      openRemoveRoleModal(role) {
        this.selectedRole = role;
        this.showRemoveRoleModal = true;
      },

      toggleRoleLayers(value) {
        const stepExist = this.openLayers.includes(value);

        if (stepExist) {
          this.openLayers = this.openLayers.filter((step) => step !== value);
        } else {
          this.openLayers.push(value);
        }
      },

      openRolesModal(role = null) {
        this.selectedRole = role;
        this.showRoleModal = true;
      },

      closeRolesModal() {
        this.selectedRole = null;
        this.showRoleModal = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .roles-container {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .role {
      display: flex;
      justify-content: space-between;
      padding: 8px;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      border-bottom: 1px solid $lineGrey;
      align-items: center;

      &:hover {
        background-color: $lineGrey;
        cursor: pointer;
      }

      .name {
        flex-grow: 1;
      }

      .right {
        display: flex;
        gap: 16px;
      }

      .users {
        display: flex;
        align-items: center;
        gap: 8px;
        flex-grow: 1;
      }
    }
  }
</style>
