<template>

  <div id="loading-spinner">
    <div class="alert alert-info">
      <i class="material-icons align-middle spin-animation">refresh</i>
      {{message}}
    </div>
  </div>

</template>

<script>
export default {
  name: 'LoadingSpinner',

  props: {
    message: {
      type: String,
      default() {
        return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../sass/animations.scss";
</style>
