<template>
  <div v-if="isRotating">
    <loader :size="20" />
  </div>

  <div v-else class="dropdown container">
    <i
      class="material-icons-outlined align-middle button dropdown-toggle"
      :title="title"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      @click.prevent
    >
      screen_rotation
    </i>

    <div class="dropdown-menu dropdown-menu-right">
      <button
        type="button"
        class="dropdown-item"
        @click.prevent="$emit('rotateScreens', 'landscape')"
      >
        0º - Degree
      </button>
      <button
        type="button"
        class="dropdown-item"
        @click.prevent="$emit('rotateScreens', 'portrait')"
      >
        90º - Degree
      </button>
      <button type="button" class="dropdown-item" @click.prevent="$emit('rotateScreens', '180d')">
        180º - Degree
      </button>
      <button type="button" class="dropdown-item" @click.prevent="$emit('rotateScreens', '270d')">
        270º - Degree
      </button>
    </div>
  </div>
</template>

<script>
  import Loader from '@/components/common/Loader.vue';

  export default {
    name: 'RotateScreenButton',

    components: { Loader },

    props: {
      isRotating: {
        type: Boolean,
        default: false,
      },
      title: {
        type: String,
        default: 'Rotate Screens',
      },
    },
  };
</script>

<style lang="scss" scoped>
  .container {
    align-content: center;
    padding: 0;
  }
</style>
