<script setup lang="ts">
  import { useStore } from '@/store';
  import { computed, ref } from 'vue';

  import PlaylistPlayer from '@/components/player/PlaylistPlayer.vue';
  import PreviewModal from '@/components/common/PreviewModal.vue';
  import { BasePlaylistItem } from '@/types/widgets/playlistWidget/playlistWidget';

  import { SET_IS_PLAYLIST_PREVIEW_ON } from '@/store/actions/playlist';
  import { WIDGET_TYPES } from '@/models/layoutDesigner';

  import { transformSecondsToTimer } from '@/helpers/utils';

  const store = useStore();
  const playSlider = ref(false);
  const previewModal = ref<InstanceType<typeof PreviewModal> | null>(null);
  const previewPlayer = ref<InstanceType<typeof PlaylistPlayer> | null>(null);

  const { playlist } = defineProps({
    playlist: { type: Object, required: true, default: {} },
  });

  function setModalClosed() {
    store.commit(SET_IS_PLAYLIST_PREVIEW_ON, false);
  }

  const isPreviewModalOpen = computed(function () {
    return store.state.playlist.isPlaylistPreviewOn;
  });

  const playlistItems = computed<BasePlaylistItem[]>(function () {
    return store.getters.getPlaylistItems(playlist.object?.wid) || [];
  });

  const playlistTotalTime = computed<string>(function () {
    const totalTime = playlistItems.value.reduce((timeSum: number, item: BasePlaylistItem) => {
      return timeSum + item.display_timer;
    }, 0);

    return transformSecondsToTimer(totalTime || 0);
  });

  function getTypeAmount(type: string) {
    return playlistItems.value.filter((item) => item.item_type.includes(type)).length;
  }

  function handlePlaylistPlayer() {
    playSlider.value = !playSlider.value;

    playSlider ? previewPlayer.value?.initPlaylist() : previewPlayer.value?.pausePlayer();
  }

  function openPlaylistPreviewModal() {
    store.commit(SET_IS_PLAYLIST_PREVIEW_ON, true);

    previewModal.value?.open();
  }
</script>

<script lang="ts">
  export default {
    name: 'PlaylistPreview',

    components: {
      PlaylistPlayer,
      PreviewModal,
    },
  };
</script>

<template>
  <div class="preview-container">
    <div class="preview-content" :style="{ aspectRatio: '16/9' }" @click="handlePlaylistPlayer">
      <PlaylistPlayer
        v-if="!isPreviewModalOpen"
        ref="previewPlayer"
        :playlist="playlist.object"
        :playSlider="playSlider"
        isPlaylistPreview
        :trafiklabZoom="0.8"
        :transitionMode="playlist.transitionMode"
      />

      <div class="button-layer">
        <div class="button-container">
          <i class="material-icons align-middle">
            {{ playSlider ? 'pause' : 'play_arrow' }}
          </i>
        </div>
      </div>
    </div>

    <div class="preview-footer">
      <div class="footer-item time" title="Total Time">
        <i class="material-icons-outlined align-middle">timer</i>
        {{ playlistTotalTime }}
      </div>
      <div class="footer-item" title="Images">
        <i class="material-icons-outlined align-middle">image</i>
        {{ getTypeAmount(WIDGET_TYPES.IMAGE) }}
      </div>
      <div class="footer-item" title="Videos">
        <i class="material-icons-outlined align-middle">play_circle_outline</i>
        {{ getTypeAmount(WIDGET_TYPES.VIDEO) }}
      </div>
      <div class="footer-item" title="PDFs">
        <i class="material-icons-outlined align-middle">text_snippet</i>
        {{ getTypeAmount(WIDGET_TYPES.PDF) }}
      </div>
      <div class="footer-item expand" title="Full Screen" @click="openPlaylistPreviewModal">
        <i class="material-icons-outlined align-middle">fullscreen</i>
      </div>
    </div>

    <PreviewModal ref="previewModal" @close="setModalClosed">
      <PlaylistPlayer
        :playlist="{ ...playlist.object, wid: playlist.wid }"
        ref="previewPlayer"
        playSlider
        :trafiklabZoom="1"
      />
    </PreviewModal>
  </div>
</template>

<style lang="scss" scoped>
  .preview-container {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .preview-content {
      min-height: 205px;
      background-color: $fillGrey;
      position: relative;
      cursor: pointer;

      .button-layer {
        position: absolute;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2000;
        width: 100%;
        height: 100%;
        transition:
          visibility 0s,
          opacity 0.3s linear;
        visibility: hidden;
        opacity: 0;

        .button-container {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 64px;
          height: 64px;

          border-radius: 100%;
          background: rgba(255, 255, 255, 0.45);
          border: 2px solid rgba(255, 255, 255, 0.15);

          i {
            font-size: 32px;
            color: $primaryRed;
          }
        }
      }

      &:hover {
        .button-layer {
          visibility: visible;
          opacity: 1;
        }
      }
    }

    .preview-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;

      i {
        color: $secondaryText;
      }

      .footer-item:hover {
        cursor: pointer;

        &.expand {
          color: $primaryRed;
        }
      }
    }
  }
</style>
