<template>
  <div :class="[`matchi-sidebar ${!showSchedule ? 'hideSchedule' : ''}`]">
    <div class="matchi-sidebar-app card-shadow">
      <div class="matchi-sidebar-app-header"></div>
      <div class="matchi-sidebar-app-title">{{$lang.player.book_with}} <span>MATCHi</span></div>
      <div class="matchi-sidebar-app-description">
        {{$lang.player.app_description}}
      </div>
      <div class="matchi-sidebar-app-links">
        <img v-if="lang === 'sw'" src="../../assets/img/matchi/MatchiAppStore-sw.svg" alt="Download on Appstore">
        <img v-if="lang === 'sw'" src="../../assets/img/matchi/MatchiGooglePlay-sw.svg" alt="Download on Google play">
        <img v-if="lang === 'da'" src="../../assets/img/matchi/MatchiAppStore-da.svg" alt="Download on Appstore">
        <img v-if="lang === 'da'" src="../../assets/img/matchi/MatchiGooglePlay-da.svg" alt="Download on Google play">
        <img v-if="lang === 'no'" src="../../assets/img/matchi/MatchiAppStore-no.svg" alt="Download on Appstore">
        <img v-if="lang === 'no'" src="../../assets/img/matchi/MatchiGooglePlay-no.svg" alt="Download on Google play">
        <img v-if="lang === 'en' || lang === 'ge'" src="../../assets/img/matchi/MatchiAppStore-en.svg" alt="Download on Appstore">
        <img v-if="lang === 'en' || lang === 'ge'" src="../../assets/img/matchi/MatchiGooglePlay-en.svg" alt="Download on Google play">
      </div>
      <div class="matchi-sidebar-app-logo">
        <img src="../../assets/img/matchi/MatchiPlayMore.svg" alt="Matchi play more">
      </div>
    </div>
    <div class="matchi-sidebar-schedule card-shadow">
      <div class="matchi-sidebar-schedule-header"></div>
      <div :class="['matchi-sidebar-schedule-day', {'active' : dayOfWeek === '1'}]">
        <div>{{$lang.player.monday}}</div>
        <div>{{openingTime(0)}}</div>
      </div>
      <div :class="['matchi-sidebar-schedule-day', {'active' : dayOfWeek === '2'}]">
        <div>{{$lang.player.tuesday}}</div>
        <div>{{openingTime(1)}}</div>
      </div>
      <div :class="['matchi-sidebar-schedule-day', {'active' : dayOfWeek === '3'}]">
        <div>{{$lang.player.wednesday}}</div>
        <div>{{openingTime(2)}}</div>
      </div>
      <div :class="['matchi-sidebar-schedule-day', {'active' : dayOfWeek === '4'}]">
        <div>{{$lang.player.thursday}}</div>
        <div>{{openingTime(3)}}</div>
      </div>
      <div :class="['matchi-sidebar-schedule-day', {'active' : dayOfWeek === '5'}]">
        <div>{{$lang.player.friday}}</div>
        <div>{{openingTime(4)}}</div>
      </div>
      <div :class="['matchi-sidebar-schedule-day', {'active' : dayOfWeek === '6'}]">
        <div>{{$lang.player.saturday}}</div>
        <div>{{openingTime(5)}}</div>
      </div>
      <div :class="['matchi-sidebar-schedule-day', {'active' : dayOfWeek === '0'}]">
        <div>{{$lang.player.sunday}}</div>
        <div>{{openingTime(6)}}</div>
      </div>
    </div>
  </div>
</template>

<script>
const moment = require('moment-timezone');

export default {
  name: "MatchiSidebar",
  props: {
    showSchedule: {
      type: Boolean,
      default: true
    },
    openingHours: {
      type: Array,
    },
    timezone: {
      type: String,
    }
  },
  data() {
    return {
      lang: this.$lang.current_lang,
      dayOfWeek: moment().tz(this.timezone).format('d'),
    }
  },
  methods: {
    openingTime(day) {
      if (this.openingHours && this.openingHours[day].openingTime && this.openingHours[day].closingTime) {
        const start = this.openingHours[day].openingTime.slice(0, 5);
        const end = this.openingHours[day].closingTime.slice(0, 5);
        return `${start}-${end}`;
      } else {
        return '06:00-24:00';
      }
    }
  },
};
</script>
