import {
  ViewerStyleBackground,
  ViewerStyleDeparture,
  ViewerStyleLine,
  ViewerStyleTitle,
} from '@/types/widgets/trafiklabWidget/trafiklabWidget';

export function getDefaultFontType(fonts: any[], fontType: string): string {
  return fonts.some((font) => font.name === fontType) ? fontType : 'Arial';
}

export function mergeTrafiklabViewerStyles(
  viewerStyles: any,
  newTitle: ViewerStyleTitle,
  newDepartures: ViewerStyleDeparture,
  newLine: ViewerStyleLine,
  newBackground: ViewerStyleBackground,
  newEvenDeparturesBackground: string,
  newOddDeparturesBackground: string,
): any {
  return {
    ...viewerStyles,
    title: {
      ...viewerStyles.title,
      fontType: newTitle.fontType,
      fontColor: newTitle.fontColor,
      fontSize: newTitle.fontSize,
    },
    departures: {
      ...viewerStyles.departures,
      fontType: newDepartures.fontType,
      fontColor: newDepartures.fontColor,
      fontSize: newDepartures.fontSize,
    },
    line: {
      ...(viewerStyles?.line ? viewerStyles.line : {}),
      fontColor: newLine.fontColor,
      backgroundColor: newLine.backgroundColor,
    },
    background: {
      ...viewerStyles.background,
      color: newBackground.color,
      transparency: newBackground.transparency,
    },
    evenDeparturesBackground: newEvenDeparturesBackground,
    oddDeparturesBackground: newOddDeparturesBackground,
  };
}
