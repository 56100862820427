<template>
  <div class="main-container" id="OrganisationsView">
    <div class="content">
      <div class="content-body">
        <div class="organisations">
          <div class="header" v-if="isAdmin">
            <div class="header-top">
              <div class="title">All Organisations</div>
              <i
                class="material-icons-outlined align-middle button"
                title="New Organisation"
                @click="openCreateOrganisationModal"
              >
                add
              </i>
            </div>
            <div class="header-bottom">
              <!-- <i
                class="material-icons-outlined align-middle button"
                :class="{ active: showOrganisationsFilters }"
                title="Filter Organisations"
                @click="organisationFilterIconClick"
              >
                filter_alt
              </i> -->

              <SearchButton v-model="organisationSearchText" />
            </div>
            <div v-if="showOrganisationsFilters" class="filters">
              <!-- TODO: Example (No filters yet) -->
              <!-- <label for="groupsStatus" class="status">
                <span>Playlists</span>
                <select
                  ref="groupsStatus"
                  name="groupsStatus"
                  id="groupsStatus"
                  @change="manageFilters('groupsStatus')"
                  v-model="filters.groupsStatus"
                >
                  <option value="all">All</option>
                  <option value="online">Online</option>
                  <option value="offline">Offline</option>
                  <option value="unknown">Unknown</option>
                </select>
              </label> -->
            </div>
          </div>

          <div class="header-organization-admin" v-if="isOrganizationAdmin && !isAdmin">
            <div class="header-top">
              <i class="material-icons align-middle button">business_center</i>
              <div class="title">{{ organisation?.name || user?.username }}</div>
            </div>
            <div class="header-bottom">
              <div class="data">
                <span class="data-label">Users:</span>
                <span class="data-value">{{ organisation?.statistics?.num_of_users || 0 }}</span>
              </div>
              <div class="data">
                <span class="data-label">Active Licenses:</span>
                <span class="data-value"
                  >{{ organisation?.statistics?.num_of_active_licenses || 0 }}/{{
                    organisation?.statistics?.num_of_licenses || 0
                  }}</span
                >
              </div>
            </div>
          </div>

          <div class="body" v-if="isAdmin">
            <alert
              styleclass="alert-yellow"
              icon="warning"
              :message="$lang.organizations.logged_in_info_message"
              class="max-w-50 mr-2"
            >
              <template v-slot:icon>
                <img
                  class="logo"
                  src="@/assets/img/organization/picflow-logo-letter.svg"
                  alt="PicFlow logo"
                />
              </template>

              <template v-slot:text>
                You are logged as <span class="highlight">SUPER ADMIN</span>
              </template>
            </alert>

            <OrganisationsList v-if="isAdmin" :search-term="organisationSearchText" />
          </div>
        </div>
      </div>
    </div>

    <CreateOrganisationModal
      v-if="showCreateOrganisationModal"
      @closeModal="showCreateOrganisationModal = false"
    />

    <OrganisationSidebar v-if="!!organisation" @organisationUpdated="getOrganizations" />
    <OrganisationSidebarPlaceholder
      v-if="!organisation && isAdmin"
      @onCreate="openCreateOrganisationModal"
    />
  </div>
</template>

<script>
  import * as ORGANISATION from '@/store/actions/organisation';

  import Alert from '@/components/Alert.vue';
  import OrganisationsList from '@/components/admin/OrganisationsList.vue';
  import CreateOrganisationModal from '@/components/admin/CreateOrganisationModal.vue';
  import OrganisationSidebar from '@/components/admin/OrganisationSidebar.vue';
  import OrganisationSidebarPlaceholder from '@/components/admin/OrganisationSidebarPlaceholder.vue';
  import SearchButton from '@/components/common/SearchButton.vue';

  export default {
    name: 'Organisation',

    components: {
      OrganisationSidebarPlaceholder,
      Alert,
      OrganisationsList,
      CreateOrganisationModal,
      OrganisationSidebar,
      SearchButton,
    },

    data() {
      return {
        showCreateOrganisationModal: false,
        showOrganisationsFilters: false,
        showOrganisationSearchBox: false,
        organisationSearchText: '',
      };
    },

    computed: {
      // adminIsAllowed() {
      //   const userOrganisationId = this.$store.getters.getCurrentUser.organisation;

      //   return this.organisation?.organisation_id === userOrganisationId;
      // },

      isOrganizationAdmin() {
        return this.$store.getters.isOrganizationAdmin;
      },

      isAdmin() {
        return this.$store.getters.isAdministrator;
      },

      organisation() {
        return this.$store.state.organisation.organisation;
      },

      organisationIdParam() {
        return this.$route.params.organisation_id;
      },

      user() {
        return this.$store.getters.getCurrentUser;
      },

      // isViewAsModeActive() {
      //   return this.$store.getters.isViewAsModeActive;
      // },
    },

    async mounted() {
      const organisationsLoaded = this.$store.state.organisation.organisations.length !== 0;

      if (
        organisationsLoaded &&
        this.organisationIdParam &&
        this.organisation &&
        this.organisation?.organisation_id === this.organisationIdParam
      ) {
        return;
      }

      this.getOrganizations();
      this.onDebouncedSearchChanged = _.debounce(this.onSearch, 1000);
    },

    watch: {
      organisationIdParam(value) {
        if (!value) {
          this.$store.commit(ORGANISATION.SET_CURRENT_ORGANISATION, null, false);
        }
      },
      // isViewAsModeActive(value) {
      //   this.getOrganizations();
      // },
    },

    methods: {
      async getOrganizations(refreshData = false) {
        if (this.isAdmin) {
          await this.$store.dispatch(
            ORGANISATION.ADMIN_ORGANISATIONS_GET,
            this.organisationIdParam,
          );
          return;
        }

        if (!this.organisation || refreshData) {
          await this.$store.dispatch(ORGANISATION.ORGANISATION_REQUEST);
          this.$store.commit(ORGANISATION.SET_ORGANISATIONS, [this.organisation]);
        }
      },

      openCreateOrganisationModal() {
        this.showCreateOrganisationModal = true;
      },

      organisationFilterIconClick() {
        this.showOrganisationsFilters = !this.showOrganisationsFilters;
      },

      organisationSearchIconClick() {
        this.showOrganisationSearchBox = !this.showOrganisationSearchBox;
      },

      onSearch(value) {
        console.log(value.target.value);
        // this.$store.dispatch(SCREENS_FILTERS_SET, {
        // ...this.filters,
        // name: value,
        // });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .organisations {
    width: 100%;
    padding: 0 16px 40px;
    display: flex;
    flex-direction: column;

    .body {
      display: flex;
      flex-direction: column;
      overflow: hidden;

      padding-right: 2rem;
      margin-right: -2rem;
    }
  }

  .alert {
    img {
      width: 24px;
      margin-right: 16px;
    }
  }

  .header-organization-admin {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 16px 20px;
    border: 2px solid $primaryRed;
    background-color: #fff;

    .header-top {
      display: flex;
      align-items: center;
      gap: 8px;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      padding-bottom: 16px;
      border-bottom: 1px solid $lineGrey;
      color: $primaryText;
    }

    .header-bottom {
      display: flex;
      padding-top: 16px;
      gap: 24px;
      font-size: 14px;
      line-height: 21px;

      .data {
        display: flex;
        gap: 4px;

        .data-label {
          color: $secondaryText;
        }

        .data-value {
          color: $primaryText;
          font-weight: 500;
        }
      }
    }
  }
</style>
