<template>
  <div class="matchi-container">
    <div class="matchi-header" v-if="!this.isOneCourt && !(this.countTime >= 0 && this.countTime <= this.welcomeScreenTime) && !this.isLoading">
      <div :class="[`matchi-header-item${this.countTime > this.welcomeScreenTime && this.countTime <= this.courtTotalTime?'-active':''}`]">
        {{$lang.player.courts_title}}
        <span class="dots">
          <span v-for="i in this.courtDots" :class="[{'dots-active':countTime >= i && countTime < i + coolDown}]" :key="i"></span>
        </span>
      </div>
      <div v-if="this.activityTotalTime > 0" :class="[`matchi-header-item${this.countTime > this.courtTotalTime && this.countTime <= this.activityTotalTime?'-active':''}`]">
        {{$lang.player.activities_title}}
        <span class="dots">
          <span v-for="i in this.activityDots" :class="[{'dots-active':countTime >= i && countTime < i + coolDown }]" :key="i"></span>
        </span>
      </div>
    </div>
    <div class="matchi-header" v-if="this.isOneCourt && (this.countTime > this.courtTotalTime && this.countTime <= this.activityTotalTime) && !this.isLoading">
      <div v-if="this.activityTotalTime > 0" :class="[`matchi-header-item${this.countTime > this.courtTotalTime && this.countTime <= this.activityTotalTime?'-active':''}`]">
        {{$lang.player.activities_title}}
        <span class="dots">
          <span v-for="i in this.activityDots" :class="[{'dots-active':countTime >= i && countTime < i + coolDown}]" :key="i"></span>
        </span>
      </div>
    </div>
    <div class="matchi-content">
      <div >
        <transition name="fade">
          <MatchiWelcome
            :playlistMatchiFacility="playlistMatchiFacility"
            :playlistMatchiSlots="playlistMatchiSlots"
            v-if="!this.isLoading && this.isShowWelcomeScreen && this.countTime >= 0 && this.countTime <= (this.welcomeScreenTime-this.fadeTime)"
          />
        </transition>
        <transition name="fade">
          <MatchiCourts v-if="this.court1PageFade && !this.isOneCourt && this.countTime > this.welcomeScreenTime && this.countTime <= (this.courtTotalTime-this.fadeTime)"
            :getPlayerNames="getPlayerNames"
            :timeOut="timeOut"
            :matchiSettings="playlistMatchiSettings"
            :validOffers="validOffers"
            :courtList="courtsInOnePage"
            :courtCurrentPage="courtCurrentPage"
            :countTime="countTime"
            :courtsLenght="bookedCourts.length"
          />
        </transition>
        <transition name="fade">
          <MatchiOneCourt
          v-if="this.isOneCourt && this.countTime > this.welcomeScreenTime && this.countTime <= (this.courtTotalTime-this.fadeTime)"
          :playlistId="playlistId"
          :playlistMatchiFacility="playlistMatchiFacility"
          :timezone="this.timezone"
          />
        </transition>
        <transition name="fade">
          <MatchiActivities v-if="this.activity1PageFade && this.activityTotalTime > 0 && this.countTime > this.courtTotalTime && this.countTime <= (this.activityTotalTime-this.fadeTime)"
            :validActivities="activitiesInOnePage"
          />
        </transition>
      </div>
    </div>
    <div class="matchi-footer">
      <div class="matchi-footer-location">
        <div class="matchi-footer-icon">
          <img :src="playlistMatchiFacility.logo" />
        </div>
        <div class="matchi-footer-location-name">{{playlistMatchiFacility.name}}</div>
      </div>
      <div class="matchi-footer-time">
        <div>{{time}}</div>
        <div>{{convertDate(new Date()) | capitalize}}</div>
      </div>
      <div class="matchi-footer-title">
        MATCHi<span> powered by </span>CLEVERSIGN
      </div>
    </div>
  </div>
</template>

<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity 1s
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0
}
</style>
<script>
import {mapState} from 'vuex'
import MatchiCourts from "./MatchiCourts";
import MatchiActivities from "./MatchiActivities";
import MatchiWelcome from "./MatchiWelcome";
import MatchiOneCourt from "./MatchiOneCourt"
import EventBus from '../../models/EventBus'

export default {
  name: "MatchiContainer",
  components:{
      MatchiCourts,
      MatchiActivities,
      MatchiWelcome,
      MatchiOneCourt
  },
  props: [
    "time",
    "playlistMatchiFacility",
    "playlistMatchiSlots",
    "playlistId",
    "bookedCourts",
    "getPlayerNames",
    "playlistMatchiSettings",
    "validOffers",
    "validActivities",
    "currentState",
    "playListLength",
    "isLoading",
    "timezone"
  ],
  data() {
    return {
      fadeTime: 1,
      countTime: 0,
      timer: null,
      timeOut: 10000,
      isOneCourt: false,
      coolDown: 0,
      lang: this.$lang.current_lang,

      isShowWelcomeScreen: false,
      welcomeScreenTime: 0,

      //Court Pagination
      courtTotalTime: 0,
      courtPageTotal: 1,
      courtDots: [],
      courtStyle: 10,
      courtsInAllPage: [],
      courtsInOnePage: [],
      courtCurrentPage: 1,
      court1PageFade: true,

      //Activity Pagination
      activityTotalTime: 0,
      activityPageTotal: 0,
      activityDots: [],
      actStyle: 3,
      activitiesInAllPage: [],
      activitiesInOnePage: [],
      activityCurrentPage: 1,
      activity1PageFade: true,

      //Total Time for Matchi
      matchiTotalTime: 0,
    };
  },
  computed: {
    ...mapState({
      //playlistMatchiActivities: state => state.playlist.playlistMatchiActivities,
    }),
  },
  mounted() {
    this.init();
    this.runCoolDown();
  },
  methods:{
    populatePage(){
      if(!this.isLoading)
      {
        this.countTime++;
      }
      //Detect list court in 1 page
      for(let i = 0 ; i < this.courtDots.length ; i ++)
      {
        if(this.countTime > (this.courtDots[i]-this.fadeTime))
        {
          this.courtsInOnePage = [];
          for(let j = 0 ; j < this.courtsInAllPage[i].length; j ++)
          {
            this.courtsInOnePage.push(this.courtsInAllPage[i][j]);
          }

          if(i !== (this.courtDots.length - 1))
          {
            this.courtCurrentPage = this.courtDots[i+1];
          }
          if(this.countTime === (this.courtCurrentPage-this.fadeTime))
          {
            this.court1PageFade = false;
          }
          if(this.countTime === (this.courtCurrentPage))
          {
            this.court1PageFade = true;
          }
        }
      }

      //Detect list activities in 1 page
      for(let i = 0 ; i < this.activityDots.length ; i ++)
      {
        if(this.countTime > (this.activityDots[i]-this.fadeTime))
        {
          this.activitiesInOnePage = [];
          for(let j = 0 ; j < this.activitiesInAllPage[i].length; j ++)
          {
            this.activitiesInOnePage.push(this.activitiesInAllPage[i][j]);
          }

          if(i !== (this.activityDots.length - 1))
          {
            this.activityCurrentPage = this.activityDots[i+1];
          }
          if(this.countTime === (this.activityCurrentPage-this.fadeTime))
          {
            this.activity1PageFade = false;
          }
          if(this.countTime === (this.activityCurrentPage))
          {
            this.activity1PageFade = true;
          }
        }
      }

      //if no playlist items, reset the slides
      if(this.countTime >= this.matchiTotalTime && this.playListLength === 0)
      {
        this.init();
        this.countTime = 1;
      }
    },
    runCoolDown()
    {
      if(this.timer){
        clearInterval(this.timer)
      }
      this.populatePage()

      this.timer = setInterval(()=>{
        this.populatePage()
      }, 1000);
    },
    init(){
      this.courtsInOnePage = [];
      this.courtsInAllPage = [];
      this.activitiesInAllPage = [];
      this.activitiesInOnePage = [];
      this.courtDots = [];
      this.activityDots = [];
      this.courtTotalTime = 0;
      this.activityTotalTime = 0;
      this.matchiTotalTime = 0;
      this.courtPageTotal = 1;
      this.activityPageTotal = 0;


      this.isShowWelcomeScreen = this.playlistMatchiSettings.show_welcome;
      if(!this.isShowWelcomeScreen)
      {
        this.welcomeScreenTime = 0;
      }
      else
      {
        this.welcomeScreenTime = this.playlistMatchiSettings.cooldown_period;
      }

      if(this.playlistMatchiSlots != null)
      {
        this.coolDown = this.playlistMatchiSettings.cooldown_period;
        this.isOneCourt = this.playlistMatchiSettings.court_id != null;

        //Calculate Court Pagination
        if(!this.isOneCourt)
        {
          const cortsCount = window.innerHeight > window.innerWidth;
          //If offers enable then 10 courts in 1 page else 12 courts in 1 page
          this.courtStyle = this.playlistMatchiSettings.offers && this.validOffers.length ? (cortsCount ? 10 : 9) : 12;
          this.courtPageTotal = Math.ceil(this.bookedCourts.length / this.courtStyle);

          for(let i = 0 ; i < this.courtPageTotal ; i ++)
          {
            this.courtDots.push(i * this.coolDown + this.welcomeScreenTime);
          }

          //Calculate total time for Court Plus count down after multiply because welcome screen
          this.courtTotalTime = this.coolDown * this.courtPageTotal + this.welcomeScreenTime;

          //Push all court in to array with page
          for(let i = 0 ; i < this.courtDots.length ; i ++)
          {
            this.courtsInAllPage[i] = [];
            let start = i * this.courtStyle;
            for(let j = start ; j < this.bookedCourts.length ; j ++)
            {
              if(this.courtsInAllPage[i].length < this.courtStyle)
              {
                this.courtsInAllPage[i].push(this.bookedCourts[j]);
              }
            }
          }
          //First Load for Court
          if(this.courtsInAllPage.length > 0)
          {
            for(let j = 0 ; j < this.courtsInAllPage[0].length; j ++)
            {
              this.courtsInOnePage.push(this.courtsInAllPage[0][j]);
            }
          }
        }
        else{
          this.courtTotalTime = this.coolDown  + this.welcomeScreenTime;
        }


        //Calculate Activity Pagination

        if(this.validActivities.length > 0)
        {
          //TODO: check when style = 3 or 4
          this.actStyle = 4;
          if($( window ).width() > $( window ).height())
          {
            this.actStyle = 3;
          }
          this.activityPageTotal = Math.ceil(this.validActivities.length / this.actStyle);

          for(let i = 0 ; i < this.activityPageTotal ; i ++)
          {
            this.activityDots.push(i * this.coolDown + (this.coolDown * this.courtPageTotal) + this.welcomeScreenTime);
          }
          this.activityTotalTime = this.coolDown * this.activityPageTotal + this.courtTotalTime;


          //Push all activity in to array with page
          for(let i = 0 ; i < this.activityDots.length ; i ++)
          {
            this.activitiesInAllPage[i] = [];
            let start = i * this.actStyle;
            for(let j = start ; j < this.validActivities.length ; j ++)
            {
              if(this.activitiesInAllPage[i].length < this.actStyle)
              {
                this.activitiesInAllPage[i].push(this.validActivities[j]);
              }
            }
          }
        }

        //Calculate Total Time of Matchi - add 1 second for delay
        this.matchiTotalTime = (this.coolDown * this.courtPageTotal) + (this.coolDown * this.activityPageTotal) + this.welcomeScreenTime + 1;
        if (this.coolDown + 1 === this.matchiTotalTime)
          this.matchiTotalTime--;
        EventBus.$emit('MATCHI_TOTAL_TIME', this.matchiTotalTime * 1000);
      }
    },
    convertDate(date)
    {
      let locale = "";
      switch (this.lang) {
        case 'sw':
          locale = "sv-SE";
          break;
        case 'en':
          locale = "en-GB";
          break;
        case 'da':
          locale = "da-DK";
          break;
        case 'no':
          locale = "nb-NO";
          break;
        case 'ge':
          locale = "de-DE";
          break;
        case 'es':
          locale = "es-US";
          break;
        default:
          break;
      }
      return date.toLocaleString(locale, { weekday: 'long', month: 'short', day: 'numeric' });
    }
  },
  watch:{
    currentState(val)
    {
      if(val === "show-playlist-item")
      {
        clearInterval(this.timer);
      }
      else
      {
        console.log("Watch currentState: " + val);
        this.countTime = 0;
        this.init();
        this.runCoolDown();
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>
