<template>
  <div
    class="widget-lock"
    :class="locked ? 'lock' : 'unlocked'"
    :title="locked ? 'Widget Locked' : ''"
  >
    <i v-show="locked" class="material-icons-outlined button">
      {{ locked ? 'lock' : 'lock_open' }}
    </i>
  </div>
</template>

<script>
  export default {
    name: 'LockIcon',

    props: {
      locked: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style lang="scss">
  .widget-lock {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    cursor: pointer;
  }
</style>
