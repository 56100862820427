<template>
  <div class="select-filter" :class="[{ isOpen }]" @click="toggle">
    <div class="label">{{ label }}</div>
    <div class="value">{{ selectedOption.label }}</div>

    <i class="material-icons-outlined icon">expand_more</i>
    <Transition>
      <div class="option-list scrollbar" v-if="isOpen">
        <div
          v-for="option in options"
          :key="option.value"
          :class="['option', { selected: option.value == value }]"
          @click.stop="handleOptionClick(option)"
        >
          {{ option.label }}
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
  export default {
    props: ['value', 'options', 'label'],
    emits: ['input'],
    data() {
      return {
        isOpen: false,
      };
    },

    computed: {
      selectedOption() {
        return this.options.find((option) => option.value === this.value);
      },
    },

    methods: {
      toggle() {
        this.isOpen = !this.isOpen;
      },

      handleOptionClick(option) {
        this.$emit('input', option.value);
        this.isOpen = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .select-filter {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: #6a6b6a;
    font-family: 'Poppins';
    padding: 5px 11px;
    border: 1px solid #d9d9d9;
    line-height: 24px;
    border-radius: 8px;
    gap: 8px;
    transition: all 0.2s ease-in-out;
    position: relative;
    cursor: pointer;

    &:hover {
      background-color: #f0f0f0;
    }

    .label {
      opacity: 0.65;
    }
    .icon {
      transition: all 0.2s ease-in-out;
      margin-left: auto;
    }

    &.isOpen {
      background-color: white;
      border-radius: 8px 8px 0px 0px;

      .icon {
        transform: rotate(180deg);
      }
    }
    .option-list {
      display: flex;
      flex-direction: column;
      gap: 4px;
      position: absolute;
      z-index: 100;
      top: 100%;
      left: -1px;
      right: -1px;
      //   width: calc(100% + 2px);
      max-height: 130px;
      overflow-y: auto;
      background: white;
      border: 1px solid #d9d9d9;
      border-radius: 0px 0px 8px 8px;
      box-sizing: content-box;
      cursor: initial;

      box-shadow: 0px 4px 4px -3px rgba(115, 115, 115, 0.25);
      .option {
        font-size: 16px;
        color: #6a6a6a;
        transition: all 0.2s ease-in-out;
        padding: 4px 11px;
        &:hover,
        &.selected {
          background: #d9d9d9;
          color: black;
          cursor: pointer;
        }
      }
    }
  }

  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.2s ease-in-out;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0.2;
  }
</style>
