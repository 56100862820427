<template>
  <Modal @closeModal="closeModal" :title="title" :okFunction="saveChange">
    <template v-slot:body>
      <div class="content-wrapper">
        <BaseInput v-model="fieldValue" :label="label" :placeholder="placeholder" />
      </div>
    </template>
  </Modal>
</template>

<script>
  import Modal from '@/components/common/Modal.vue';

  export default {
    props: {
      title: {
        type: String,
        default: 'Edit Item',
      },
      label: {
        type: String,
        default: 'Edit',
      },
      placeholder: {
        type: String,
        default: 'Edit',
      },
      field: {
        type: String,
      },
      validation: {
        type: Function,
        required: false,
      },
    },
    emits: ['field-modified'],
    name: 'EditSingleItemModal',
    components: { Modal },
    data() {
      return {
        fieldValue: '',
      };
    },
    mounted() {
      this.fieldValue = this.field;
    },
    methods: {
      async saveChange() {
        const newValue = this.fieldValue.trim();
        if (!newValue) {
          this.$toasted.global.general_error({ message: 'Value is required!' });
          return;
        }
        if (this.validation) {
          const [isValid, message] = this.validation(newValue);
          if (!isValid) {
            this.$toasted.global.general_error({ message: message ?? 'Invalid Value' });
            return;
          }
        }

        this.$emit('field-modified', newValue);
      },

      closeModal() {
        this.$emit('closeModal');
      },
    },
  };
</script>

<style lang="scss" scoped>
  ::v-deep .ui-modal-content {
    width: 500px !important;
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
</style>
