<template>
  <div class="layout-wrapper scrollbar">
    <div
      class="background layout-container"
      :style="{
        height: `${layoutDimensions.height}px`,
        width: `${layoutDimensions.width}px`,
        backgroundColor: layout?.settings.backgroundColor || '#FFFFFF',
      }"
      @click.stop
    >
      <div class="layout">
        <DesignerToolbar class="toolbar" :selectedWidget="selectedWidget" :show="showToolbar" />
        <div class="draggable-container noselect">
          <Layer :widget="widget" v-for="widget in widgets" :key="widget.assoc_id" />
          <span
            class="ref-line v-line"
            v-for="(item, index) in vLine"
            :key="`vertical-${index}`"
            v-show="item.display"
            :style="{
              left: parseFloat(item.position) > 0 ? item.position : 0,
              top: parseFloat(item.origin) > 0 ? item.origin : 0,
              height: trimLength(item, 'height'),
              background: '#e3003a',
              border: '1px solid #e3003a',
            }"
          />
          <span
            class="ref-line h-line"
            v-for="(item, index) in hLine"
            :key="`horizontal-${index}`"
            v-show="item.display"
            :style="{
              top: parseFloat(item.position) > 0 ? item.position : 0,
              left: parseFloat(item.origin) > 0 ? item.origin : 0,
              width: trimLength(item, 'width'),
              background: '#e3003a',
              border: '1px solid #e3003a',
            }"
          />
        </div>

        <div class="layout-grid" :style="gridStyle"></div>
      </div>
    </div>

    <DeleteWidgetModal
      v-if="showDeleteWidgetModal"
      :widget="selectedWidget"
      @closeModal="showDeleteWidgetModal = false"
    />
  </div>
</template>

<script>
  import 'vue-draggable-resizable-gorkys/dist/VueDraggableResizable.css';

  import VueDraggableResizable from '@/components/common/VueDraggableResizable.vue';
  import Layer from '@/components/layoutDesigner/designer/Layer.vue';
  import DesignerToolbar from '@/components/layoutDesigner/designer/DesignerToolbar.vue';
  import DeleteWidgetModal from '@/components/layoutDesigner/designer/DeleteWidgetModal.vue';
  import { WIDGET_TYPES } from '@/models/layoutDesigner';

  export default {
    name: 'Layout',

    props: ['layout'],

    components: {
      Layer,
      VueDraggableResizable,
      DesignerToolbar,
      DeleteWidgetModal,
    },

    data() {
      return {
        showDeleteWidgetModal: false,
      };
    },

    provide() {
      return {
        deleteWidget: this.openDeleteWidgetModal,
      };
    },

    computed: {
      showToolbar() {
        return this.selectedWidget?.object.type !== WIDGET_TYPES.RICH_TEXT;
      },

      widgets() {
        return this.$store.state.layoutDesigner.widgets;
      },

      selectedWidget() {
        return this.$store.state.layoutDesigner.selectedWidget;
      },

      layoutDimensions() {
        return this.$store.getters.getLayoutDimensions;
      },

      vLine() {
        return this.$store.state.layoutDesigner.vLine;
      },

      hLine() {
        return this.$store.state.layoutDesigner.hLine;
      },

      showGrid() {
        return this.$store.state.layoutDesigner.showLayoutGrid;
      },

      grid() {
        return this.$store.state.layoutDesigner.layout?.settings.grid || { x: 20, y: 20 };
      },

      layoutScaleFactor() {
        return this.$store.getters.getResolution.scaleFactor;
      },

      gridStyle() {
        const gridValues = `${this.grid.x * this.layoutScaleFactor}px ${
          this.grid.y * this.layoutScaleFactor
        }px`;

        return this.showGrid
          ? {
              background: `linear-gradient(-90deg, rgba(0, 0, 0, 0.1) 1px, transparent 1px) 0% 0% / ${gridValues}, linear-gradient(rgba(0, 0, 0, 0.1) 1px, transparent 1px) 0% 0% / ${gridValues}`,
            }
          : {};
      },
    },

    methods: {
      getRefLineParams(params) {
        const { vLine, hLine } = params;
        this.vLine = vLine;
        this.hLine = hLine;
      },

      trimLength(lineData, orientation) {
        if (!lineData.origin || !lineData.lineLength) return;

        const origin = parseFloat(lineData.origin) > 0 ? parseFloat(lineData.origin) : 0;
        const lineLength = parseFloat(lineData.lineLength);

        if (orientation === 'width') {
          const layoutWidth = this.layoutDimensions.width;
          const totalXCoordinate = origin + lineLength;
          const finalLineLength =
            totalXCoordinate > layoutWidth ? layoutWidth - origin : lineLength;

          return `${finalLineLength}px`;
        }

        const layoutHeight = this.layoutDimensions.height;
        const totalYCoordinate = origin + lineLength;

        const finalLineLength =
          totalYCoordinate > layoutHeight ? layoutHeight - origin : lineLength;

        return `${finalLineLength}px`;
      },

      openDeleteWidgetModal() {
        this.showDeleteWidgetModal = true;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .layout {
    position: relative;
    height: 100%;
    margin: 0 auto;
    padding-bottom: 20px;

    ::v-deep .widget-item {
      position: absolute;
    }
  }

  .layout-container {
    position: relative;
    cursor: grab;

    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  }

  .draggable-container {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .layout-grid {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 200;
    pointer-events: none;
  }

  .layout-wrapper {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 60px 0 20px 0;
    margin-right: 32px;
    overflow: auto;
  }
</style>
