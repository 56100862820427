<script>
  import SearchInput from '@/components/common/SearchInput.vue';
  import _ from 'lodash';
  import { getStopLocations } from '@/api/transport';

  export default {
    name: 'StopSearchInput',
    components: { SearchInput },

    data() {
      return {
        stopSearchKey: '',
        isLoading: true,
        trafiklabStops: [],
        selectedStop: '',
        stops: [],
      };
    },

    emits: ['update:input'],

    methods: {
      handleUpdate(selectedStop) {
        this.selectedStop = selectedStop;
        this.stopSearchKey = selectedStop.name;
        this.$emit('update:input', selectedStop);
      },

      searchStop: _.debounce(function (searchValue) {
        this.stopSearchKey = searchValue;

        if (this.stopSearchKey) {
          this.isLoading = true;

          getStopLocations(this.stopSearchKey)
            .then((res) => {
              this.trafiklabStops = res;
            })
            .catch((err) => {
              this.$toasted.global.general_error({ message: 'Failed to fetch the stops.' });
            })
            .finally(() => {
              this.isLoading = false;
            });
        }
      }, 600),
    },
  };
</script>

<template>
  <SearchInput
    :value="stopSearchKey"
    :loading="isLoading"
    placeholder="Search a stop"
    @search="searchStop($event)"
  >
    <div class="dropdown-menu">
      <span
        class="dropdown-item cursor-pointer"
        v-for="stop in trafiklabStops"
        :key="stop.id"
        @click="handleUpdate(stop)"
      >
        {{ stop.name }}
      </span>
    </div>
  </SearchInput>
</template>

<style scoped lang="scss"></style>
