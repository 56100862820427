<template>
  <div class="main-container" v-if="isLoading">
    <Loader />
  </div>

  <div class="main-container" id="ScreenView" v-else>
    <div class="ui-content">
      <div class="ui-content-header">
        <div class="left">
          <div class="back-button" @click="goToScreens">
            <i class="material-icons-outlined align-middle">arrow_back</i>
          </div>

          <div class="breadcrumbs">
            <span>Screens / </span>
            <span v-for="(item, idx) in breadcrumbs" :key="idx">
              <b v-if="idx === breadcrumbs.length - 1">{{ item }}</b>
              <span class="breadcrumb-group" @click="() => goToParentGroup(item)" v-else>
                {{ item.name }} /
              </span>
            </span>
          </div>
        </div>

        <div class="right">
          <div class="online-status">
            <ConnectionLights
              :connected="isConnected ? 1 : 0"
              :unknown="isUnknown ? 1 : 0"
              :disconnected="isDisconnected ? 1 : 0"
              hideNumbers
            />

            <span
              :class="{ greenLight: isConnected, redLight: isDisconnected, greyLight: isUnknown }"
              title="Screen Status"
            >
              {{ screenStatusLabel }}
            </span>
          </div>
          <RotateScreenButton
            title="Rotate Screen/s"
            :isRotating="isRotating"
            @rotateScreens="rotateScreen"
          />
          <button
            v-if="screen?.screen_group"
            @click="goToParentGroup(null)"
            title="Edit Screen's Group"
            class="icon"
          >
            <i class="material-icons-outlined align-middle">auto_awesome_motion</i>
          </button>
          <button
            @click="toggleExpandPlayer"
            :title="expandPlayer ? 'Fit to page' : 'Expand'"
            class="icon"
          >
            <i class="material-icons-outlined align-middle">
              {{ expandPlayer ? 'fullscreen_exit' : 'fullscreen' }}
            </i>
          </button>
          <BaseButton
            icon="file_upload"
            :loading="isRefreshing"
            variant="outline"
            :disabled="isPublishButtonDisabled"
            :title="isPublishButtonDisabled ? 'No changes has been done' : 'Publish new changes'"
            @click="refreshScreen"
          >
            {{ isPublishButtonDisabled ? 'Published' : 'Publish' }}
          </BaseButton>
          <div class="dropdown">
            <i
              class="material-icons align-middle more-buttons dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              more_vert
            </i>

            <div class="role-options dropdown-menu dropdown-menu-right">
              <button type="button" class="dropdown-item" @click="openDeleteScreenModal">
                <i class="material-icons align-middle">delete</i>
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>

      <div ref="playerContainer" class="ui-content-body scrollbar">
        <div
          class="central-container"
          :class="{
            expanded: expandPlayer && layout?.settings.isHorizontal,
          }"
        >
          <div class="display" :style="playerStyle">
            <Player
              ref="player"
              :layout_id="layoutId"
              :screenId="screenId"
              class="h-100"
              :key="layoutId"
              isPreview
              highlight
            />
          </div>
        </div>
      </div>
    </div>

    <ScreenSidebar :screen="screen" :screens="[screen]" />

    <DeleteScreenModal
      v-if="screen"
      :screenId="screen.id"
      ref="deleteScreenModal"
      @screenDeleted="handleScreenDeleted"
    />
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  import DeleteScreenModal from '@/components/screens/DeleteScreenModal.vue';
  import SidebarContainer from '@/components/common/SidebarContainer.vue';
  import Loader from '@/components/common/Loader.vue';
  import Player from '@/components/player/Index.vue';
  import ScreenSidebar from '@/components/screens/screen/ScreenSidebar.vue';
  import RotateScreenButton from '@/components/common/screens/RotateScreenButton.vue';
  import ConnectionLights from '@/components/common/screens/ConnectionLights.vue';

  import {
    SCREEN_GET,
    SCREEN_GROUPS_FETCH,
    SET_SCREEN,
    SET_SCREEN_GROUP,
    SCREENS_STATUS_FETCH,
    SCREENS_RESTART,
  } from '@/store/actions/screens';
  import { ALLOW_PLAYER_MODAL, SET_SCREEN_SIZE } from '@/store/actions/player';

  import { rotateScreen } from '@/api/screens';
  import _ from 'lodash';
  import { ContentStatus, PublicationType } from '@/types/api/screens';
  import { SCREEN_CONTENT_CHANGED } from '@/store/actions/screenContentManagment';

  export default {
    name: 'ScreenView',

    provide() {
      return {
        handleContentChange: this.handleContentChange,
      };
    },

    components: {
      DeleteScreenModal,
      SidebarContainer,
      Loader,
      Player,
      ScreenSidebar,
      RotateScreenButton,
      ConnectionLights,
    },

    data() {
      return {
        isLoading: false,
        isSaving: false,
        isRotating: false,
        isRefreshing: false,
        screen: null,
        layoutChildren: [],
        showDeleteScreenModal: false,
        playerContainerSize: {
          height: 0,
          width: 0,
        },
        expandPlayer: false,
      };
    },

    async mounted() {
      this.$store.commit(ALLOW_PLAYER_MODAL, false);

      await Promise.all([this.fetchData(), this.fetchGroups(), this.fetchScreen()]);

      this.playerContainerSize = {
        height: this.$refs.playerContainer.clientHeight,
        width: this.$refs.playerContainer.clientWidth,
      };
    },

    computed: {
      ...mapState({
        groups: (state) => state.screens.screenGroups.data,
      }),

      layoutId() {
        return this.screen?.layout;
      },

      layout() {
        return this.$store.state.player.layout;
      },

      screenStatus() {
        return this.screen ? this.$store.getters.getScreenStatus(this.screen?.id) : 1;
      },

      isConnected() {
        return this.screenStatus === 2;
      },

      isDisconnected() {
        return this.screenStatus === 0;
      },

      isUnknown() {
        return this.screenStatus !== 0 && this.screenStatus !== 2;
      },

      isPublishButtonDisabled() {
        return this.screen?.content_status === ContentStatus.Published;
      },

      screenStatusLabel() {
        switch (this.screenStatus) {
          case 0:
            return 'Off';
          case 1:
            return 'Unknown';
          case 2:
            return 'On';

          default:
            return '';
        }
      },

      actionsDisabled() {
        return this.isRotating || this.isLoading || this.isSaving;
      },

      playlist() {
        // TODO: get layers info
        return this.$route.params.layerId
          ? this.layers.find((layer) => layer.playlist_id === this.$route.params.layerId)
          : null;
      },

      screenId() {
        return this.$route.params.screenId;
      },

      group() {
        return this.groups?.find((g) => g.id === this.screen?.screen_group);
      },

      breadcrumbs() {
        let path = [];
        let group = this.group;

        if (!group) return [this.screen?.name];

        path.push(this.screen?.name);

        while (group) {
          path.push(group);
          group = this.groups.find((g) => g.id === group.parent_id);
        }

        return path.reverse();
      },

      screenSize() {
        return this.$store.state.player.screenSize;
      },

      aspectRatio() {
        const { aspectRatio } = this.layout.settings;
        const aspectRatioValues = aspectRatio.split(':');
        const ratio = aspectRatioValues[0] / aspectRatioValues[1];

        return ratio;
      },

      playerWidthLimit() {
        const { isHorizontal } = this.layout.settings;
        const { width } = this.playerContainerSize;

        return isHorizontal || width < 800 ? width : 800;
      },

      playerHeightLimit() {
        const { isHorizontal } = this.layout.settings;
        const { height } = this.playerContainerSize;

        return isHorizontal || height < 800 ? height : 800;
      },

      playerSize() {
        if (!this.layout) return {};

        const { isHorizontal } = this.layout.settings;

        const { width, height } = this.playerContainerSize;

        let playerWidth = isHorizontal ? width : height / this.aspectRatio;
        let playerHeight = isHorizontal ? width / this.aspectRatio : height;

        if (this.expandPlayer) {
          playerWidth = isHorizontal
            ? this.playerWidthLimit * this.aspectRatio
            : this.playerWidthLimit;
          playerHeight = isHorizontal
            ? this.playerHeightLimit
            : this.playerHeightLimit * this.aspectRatio;
        }

        return {
          width: playerWidth,
          height: playerHeight,
        };
      },

      playerStyle() {
        const { width = this.screenSize.width, height = this.screenSize.height } = this.playerSize;

        return {
          width: `${width}px`,
          height: `${height}px`,
          position: 'relative',
        };
      },
    },

    methods: {
      async handleContentChange() {
        await this.$store.dispatch(SCREEN_CONTENT_CHANGED, this.screen.id);
        await this.fetchData(true);
      },

      getScreen() {
        return this.screen;
      },

      async fetchData(silent = false) {
        this.isLoading = !silent;

        if (silent) {
          await this.$refs.player.loadLayout();
          this.screen = await this.$store.dispatch(SCREEN_GET, this.screenId);
        }

        this.isLoading = false;
      },

      async fetchScreen() {
        try {
          this.screen = await this.$store.dispatch(SCREEN_GET, this.screenId);

          if (!this.screen.layout) {
            this.$toasted.global.general_error({ message: "Screen isn't connected to layout" });
            this.$router.push({ name: 'Screens' });
            return;
          }

          this.fetchScreensStatus();
        } catch (error) {
          this.$toasted.global.general_error({
            message:
              error.response?.status === 403
                ? "You aren't allowed to access this Screen"
                : 'Screen not found',
          });
          this.$router.push({ name: 'Screens' });
        }
      },

      async fetchGroups() {
        await this.$store.dispatch(SCREEN_GROUPS_FETCH);
      },

      async fetchScreensStatus() {
        await this.$store.dispatch(SCREENS_STATUS_FETCH);
      },

      async rotateScreen(orientation) {
        this.isRotating = true;
        try {
          await rotateScreen(this.screen.id, orientation);
          await this.handleContentChange();

          this.$toasted.global.general_success({
            message: `Screen rotated successfully`,
          });
        } catch (error) {
          console.log(error);
          this.$toasted.global.general_error({ message: 'Unable to rotate screen' });
        }
        this.isRotating = false;
      },

      async refreshScreen() {
        if (!_.isEmpty(this.$route.query)) {
          this.$router.replace({ query: null });
        }
        this.isRefreshing = true;

        try {
          await this.$store.dispatch(SCREENS_RESTART, {
            publicationType: PublicationType.ScreenPublication,
            data: { screen_ids: [this.screen.id] },
          });

          await this.fetchData(true);

          this.$toasted.global.general_success({
            message: `Screen/s refreshed successfully`,
          });
        } catch (error) {
          console.log(error);
          this.$toasted.global.general_error({ message: 'Unable to refresh screens' });
        }

        this.isRefreshing = false;
      },

      openContentModal() {
        this.$refs.PlaylistItems.openPlaylistContentModal();
      },

      openDeleteScreenModal() {
        this.$refs.deleteScreenModal.open();
      },

      handleScreenDeleted() {
        this.$router.push({ name: 'Screens' });
      },

      goToScreens() {
        if (this.screen) {
          this.$store.commit(SET_SCREEN, this.screen);
          this.$store.commit(SET_SCREEN_GROUP, this.group);
        }

        this.$router.push({ name: 'Screens' });
      },

      goToParentGroup(group) {
        this.$router.push({
          name: 'ScreenLayout',
          params: {
            groupId: group ? group.id : this.screen.screen_group,
            layoutId: this.screen.layout,
          },
        });
      },

      toggleExpandPlayer() {
        this.expandPlayer = !this.expandPlayer;

        this.$store.commit(SET_SCREEN_SIZE, {
          height: this.playerSize.height,
          width: this.playerSize.width,
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .main-container {
    grid-template-columns: minmax(100px, calc(100% - 376px)) 376px !important;

    .ui-content {
      display: flex;
      height: 100vh;
      flex-direction: column;
      overflow: hidden;
    }

    .breadcrumbs {
      color: $secondaryText;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;

      b {
        color: $primaryText;
      }

      .breadcrumb-group:hover {
        cursor: pointer;
        color: $primaryText;
      }
    }
  }

  .online-status {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    font-weight: bold;

    .greenLight {
      color: $greenLight;
    }

    .redLight {
      color: $redLight;
    }

    .greyLight {
      color: $secondaryText;
    }
  }

  .dropdown-toggle {
    &:hover {
      cursor: pointer;
    }

    &::after {
      display: none !important;
    }
  }

  .sidebar-section {
    padding-top: 16px;
    padding-bottom: 24px;

    & + .sidebar-section {
      border-top: 1px solid $lineGrey;
    }
  }

  .ui-content-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 60px 40px 20px;

    button.icon {
      border: none;
      background: none;
    }

    .right {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    .left {
      display: flex;
      gap: 20px;

      i {
        color: $primaryText;
      }
    }
  }

  .central-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    &.expanded {
      align-items: flex-start;
    }
  }

  .ui-content-body {
    min-height: 0;
    flex: 1;
    margin: 40px 40px 16px 40px;
    overflow: auto;

    &.horizontal {
      .display {
        width: 100%;
      }
    }

    &.vertical {
      .display {
        height: 100%;
        max-width: 100%;
      }
    }

    .display {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .description {
      display: grid;
      font-size: 14px;
      line-height: 21px;
      color: $secondaryText;
      margin-bottom: 2px;
    }

    ::v-deep .web-player-v2 {
      flex: 1;
    }
  }

  .content-section {
    display: flex;
    flex-direction: column;
    gap: 16px;

    label {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      font-weight: bold;
    }

    .layers {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding-left: 5px;
      padding-right: 5px;
      padding-bottom: 5px;
      padding-top: 8px;

      .layer {
        color: $primaryText;
        width: 100%;
        background: #fff;
        border: 1px solid $backgroundGrey3;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        padding: 8px 16px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  .back-button {
    &:hover {
      cursor: pointer;

      i {
        color: $primaryRed;
      }
    }
  }
</style>
