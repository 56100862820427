<template>
  <SidebarContainer>
    <template v-if="!!template" v-slot:default>
      <div class="sidebar-container">
        <div class="preview-container">
          <div class="preview-content" :style="previewStyle">
            <TemplateViewer ref="TemplateViewer" :template="template" :zoom="templateZoom" />
          </div>
        </div>

        <div class="form-group">
          <label for="templateName">
            <div class="label">Template Name</div>
            <input
              type="text"
              name="templateName"
              id="templateName"
              required
              v-model="templateName"
            />
          </label>
        </div>

        <button type="button" class="button-2-third" @click="goToTemplate">
          <i class="material-icons-outlined align-middle">edit</i>
          Edit Template
        </button>

        <button type="button" class="button-2-secondary" @click="openCopyTemplateModal">
          <i class="material-icons-outlined align-middle">content_copy</i>
          Make a Copy
        </button>

        <div class="content-section">
          <div v-if="template.created" class="dropdown-section">
            <div class="dropdown" @click="showInfo = !showInfo">
              <span>Info</span>
              <i class="material-icons-outlined align-middle" :class="{ 'text-red': showInfo }">{{
                showInfo ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
              }}</i>
            </div>

            <div v-if="showInfo" class="content-container">
              <div class="description-container">
                <div class="description-item">
                  <span>Created: </span>{{ formatDate(template.created, 'date') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-if="!!template" v-slot:buttons>
      <button type="button" class="button-2-secondary" @click="openChangeTemplateFolderModal">
        <i class="material-icons-outlined align-middle">arrow_right_alt</i>
        Move Template
      </button>

      <button
        v-if="isAdmin"
        type="button"
        class="button-2-secondary"
        @click="openDeleteTemplateModal"
      >
        <i class="material-icons-outlined align-middle">delete_outline</i>
        Delete Template
      </button>

      <button type="button" class="button-2-primary" @click="updateTemplate" :disabled="isSaving">
        <i v-if="isSaving" class="material-icons align-middle spin-animation">refresh</i>
        <i v-else class="material-icons-outlined align-middle">save</i>
        Save Settings
      </button>

      <DeleteTemplateModal
        @closeModal="showDeleteTemplateModal = false"
        :templateId="template?.template_id"
        v-if="showDeleteTemplateModal"
        @deleted="$emit('templateDeleted')"
      />

      <CopyTemplateModal
        v-if="showCopyTemplateModal"
        :template="template"
        :widgets="$refs.TemplateViewer?.widgets"
        @closeModal="showCopyTemplateModal = false"
      />

      <ChangeTemplateFolderModal
        v-if="showChangeTemplateFolderModal"
        :template="template"
        @closeModal="showChangeTemplateFolderModal = false"
        @onFolderUpdate="$emit('onFolderUpdate')"
      />
    </template>
  </SidebarContainer>
</template>

<script>
  import SidebarContainer from '@/components/common/SidebarContainer.vue';
  import DeleteTemplateModal from '@/components/templates/DeleteTemplateModal.vue';
  import CopyTemplateModal from '@/components/templates/CopyTemplateModal.vue';
  import ChangeTemplateFolderModal from '@/components/templates/ChangeTemplateFolderModal.vue';
  import TemplateViewer from '@/components/templates/viewer/TemplateViewer.vue';
  import { globalFiltersMixin } from '@/helpers/mixins';

  import { apiUpdateTemplate } from '@/api/templates';

  export default {
    name: 'TemplatesSidebar',

    components: {
      SidebarContainer,
      DeleteTemplateModal,
      TemplateViewer,
      CopyTemplateModal,
      ChangeTemplateFolderModal,
    },

    props: {
      template: {
        type: Object,
        default: () => null,
      },
    },

    mixins: [globalFiltersMixin],

    data() {
      return {
        isSaving: false,
        showInfo: false,
        showDeleteTemplateModal: false,
        showCopyTemplateModal: false,
        showChangeTemplateFolderModal: false,
        templateName: '',
      };
    },

    mounted() {
      this.templateName = this.template.settings?.name;
    },

    watch: {
      template() {
        this.templateName = this.template.settings?.name;
      },
    },

    computed: {
      isAdmin() {
        return this.$store.getters.isOrganizationAdmin || this.$store.getters.isAdministrator;
      },

      templateZoom() {
        const { width = 336, height = 350 } = this.template.settings;
        if (width < 336 && height < 350) {
          return 1;
        }

        const ratioX = 336 / width;
        const ratioY = 350 / height;

        return ratioX < ratioY ? ratioX : ratioY;
      },

      previewStyle() {
        const { width = 336, height = 350 } = this.template.settings;

        const templateWidth = width > height ? 336 : width * this.templateZoom;
        const templateHeight = height > width ? 350 : height * this.templateZoom;

        return {
          width: `${templateWidth}px`,
          height: `${templateHeight}px`,
        };
      },
    },

    methods: {
      async updateTemplate() {
        this.isSaving = true;

        try {
          // Check if template name is empty
          if (!this.templateName) {
            this.$toasted.global.general_error({
              message: 'Template Name is required',
            });
            this.isSaving = false;
            return;
          }

          const templateData = {
            ...this.template.settings,
            name: this.templateName,
          };

          const successful = await apiUpdateTemplate(this.template.template_id, templateData);

          if (successful) {
            this.$emit('templateUpdated');

            this.$toasted.global.general_success({
              message: 'Changes saved',
            });
          }
        } catch (error) {
          console.log('updateTemplate ~ error:', error);
          this.$toasted.global.general_error({
            message: 'Not able to Save Changes',
          });
        }

        this.isSaving = false;
      },

      openDeleteTemplateModal() {
        this.showDeleteTemplateModal = true;
      },

      openCopyTemplateModal() {
        this.showCopyTemplateModal = true;
      },

      openChangeTemplateFolderModal() {
        this.showChangeTemplateFolderModal = true;
      },

      goToTemplate() {
        this.$router.push({
          name: 'Template',
          params: {
            templateId: this.template.template_id,
          },
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .layout-player-link a {
    color: $primaryRed !important;
    font-size: 14px;
  }

  .sidebar-container {
    display: flex;
    flex-direction: column;
    gap: 16px;

    button {
      margin-top: 8px;
    }
  }

  .content-section {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }

  .form-group {
    width: 100%;
    margin-bottom: 0px;

    label {
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
      gap: 8px;

      font-weight: 500;
      line-height: 24px;

      .label {
        display: flex;
        gap: 8px;
        align-items: center;

        font-size: 14px;
      }

      input {
        padding: 8px 16px;

        border: 2px solid $borderGrey;
        border-radius: 8px;
        font-size: 16px;
      }
    }
  }

  .dropdown-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 8px;
    gap: 8px;
    width: 100%;

    .dropdown {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      line-height: 24px;
      padding: 8px 0;
      font-weight: 500;
      cursor: pointer;
    }
  }

  .content-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 16px;
    border-bottom: 2px solid $lineGrey;
    overflow-x: hidden;
  }

  .description-container {
    display: flex;
    flex-direction: column;
    padding: 12px 2px;
    gap: 8px;

    .description-item {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: $primaryText;

      a {
        color: $primaryText;
      }

      span {
        color: $secondaryText;
        font-weight: 400;
      }
    }
  }

  .title {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: 14px !important;
    line-height: 24px;
    margin-bottom: -8px;
    color: $primaryText;

    i {
      color: $secondaryText;
    }
  }

  .preview-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    justify-content: center;
    min-height: 205px;
    max-height: 350px;
    background-color: $backgroundGrey2;

    .preview-content {
      position: relative;
      cursor: pointer;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .preview-footer {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      i {
        color: $secondaryText;

        &:hover {
          color: $primaryText;
        }
      }
    }
  }
</style>
