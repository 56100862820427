<template>
  <div class="matchi-onecourt">
    <div class="matchi-onecourt-main">
      <div class="matchi-onecourt-court card-shadow">
        <div class="matchi-onecourt-court-header">
          <div class="icon">
            <img v-if="sportType === 'BADMINTON'" src="../../assets/icon/icon-w-bg/BadmintonIcon.png">
            <img v-if="sportType === 'PADEL'" src="../../assets/icon/icon-w-bg/PadelIcon.png">
            <img v-if="sportType === 'PICKLEBALL'" src="../../assets/icon/icon-w-bg/PickleballIcon.png">
            <img v-if="sportType === 'SQUASH'" src="../../assets/icon/icon-w-bg/SquashIcon.png">
            <img v-if="sportType === 'TABLETENNIS'" src="../../assets/icon/icon-w-bg/TableTennisIcon.png">
            <img v-if="sportType === 'TENNIS'" src="../../assets/icon/icon-w-bg/TennisIcon.png">
            <!--            <img v-if="sportType === 'BOWLING'" src="../../assets/icon/icon-w-bg/BowlingIcon.png">-->
            <img v-if="sportType === 'GOLF'" src="../../assets/icon/icon-w-bg/GolfIcon.png">
            <img v-if="sportType === 'FLOORBALL'" src="../../assets/icon/icon-w-bg/FloorballIcon.png">
            <!--            <img v-if="sportType === 'SHUFFLEBOARD'" src="../../assets/icon/icon-w-bg/ShuffleboardIcon.png">-->
            <img v-if="sportType === 'TRAMPOLINE'" src="../../assets/icon/icon-w-bg/TrampolineIcon.png">
            <img v-if="sportType === 'YOGA'" src="../../assets/icon/icon-w-bg/YogaIcon.png">
            <img v-if="sportType === 'OTHER'" src="../../assets/icon/icon-w-bg/TennisIcon.png">
          </div>
          <div class="name">
            {{ courtName }}
          </div>
        </div>
        <div class="matchi-onecourt-court-body">
          <div class="item" v-if="slots && slots.current">
            <div><span>LIVE</span>
              {{ slots.current.start }}-{{ slots.current.end }}
            </div>
            <div v-if="slots.current">
              {{ getBookingName(slots.current) }}
            </div>
          </div>
          <div class="item" v-if="slots && !slots.current">
            <div><span>LIVE</span>{{ $lang.player.available_time }}</div>
          </div>
          <div class="item" v-if="slots && slots.next">
            <div><span>NÄSTA</span>
              {{ slots.next.start }}-{{ slots.next.end }}
            </div>
            <div v-if="slots.next">
              {{ getBookingName(slots.next) }}
            </div>
          </div>
          <div class="item" v-if="slots && !slots.next">
            <div><span>NÄSTA</span>{{ $lang.player.available_time }}</div>
          </div>
        </div>
      </div>
      <div class="matchi-onecourt-slots-container">
        <div class="matchi-onecourt-slots card-shadow" v-if="slots">
          <div class="matchi-onecourt-slots-item" v-for="slot in slots.firstSlots" :key="slot.id">
            <div>{{ slot.start }}-{{ slot.end }}</div>
            <div>{{ getBookingName(slot) }}</div>
          </div>
          <div v-if="slots.firstSlots.length < 6" class="matchi-onecourt-slots-border">
            <div class="matchi-onecourt-slots-item" v-for="index in 6 - slots.firstSlots.length" :key="index">
              <div>{{ $lang.player.available_time }}</div>
              <div>Empty</div>
            </div>
          </div>
        </div>
        <div class="matchi-onecourt-slots card-shadow" v-if="slots">
          <div class="matchi-onecourt-slots-item" v-for="slot in slots.secondSlots" :key="slot.id">
            <div>{{ slot.start }}-{{ slot.end }}</div>
            <div>{{ getBookingName(slot) }}</div>
          </div>
          <div v-if="slots.secondSlots.length < 6" class="matchi-onecourt-slots-border">
            <div class="matchi-onecourt-slots-item" v-for="index in 6 - slots.secondSlots.length" :key="index">
              <div>{{ $lang.player.available_time }}</div>
              <div>Empty</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <MatchiSidebar
      :showSchedule="false"
      :openingHours="playlistMatchiFacility.openingHours"
      :timezone="playlistMatchiFacility.timezone"
    />
  </div>
</template>

<script>
import {PLAYLIST_MATCHI_ONECOURT_REQUEST} from '../../store/actions/playlist';
import {mapState} from 'vuex';
import MatchiSidebar from "./MatchiSidebar";

const moment = require('moment-timezone');

export default {
  name: "MatchiOneCourt",
  props: ["playlistId", "playlistMatchiFacility", "timezone"],
  mounted() {
    this.$store.dispatch(PLAYLIST_MATCHI_ONECOURT_REQUEST, this.playlistId)
  },
  components: {
    MatchiSidebar,
  },
  computed: {
    ...mapState({
      sportType: state =>
        state.playlist.playlistMatchiOneCourt ?
          state.playlist.playlistMatchiOneCourt.slots[0].court.sport.type :
          0,
      courtName: state =>
        state.playlist.playlistMatchiOneCourt ?
          state.playlist.playlistMatchiOneCourt.slots[0].court.name :
          'Court',
      slots(state) {
        if (state.playlist.playlistMatchiOneCourt) {
          let current = null;
          let next = null;
          const firstSlots = [];
          const secondSlots = [];
          state.playlist.playlistMatchiOneCourt.slots.forEach(element => {
            const time = +moment().tz(this.timezone).format('x');
            const start = new Date(element.start).getTime();
            const end = new Date(element.end).getTime();
            if (time <= end) {
              element.start = moment(element.start).tz(this.timezone).format('HH:mm');
              element.end = moment(element.end).tz(this.timezone).format('HH:mm');
              if (firstSlots.length < 6)
                firstSlots.push(element);
              else if (secondSlots.length < 6) {
                secondSlots.push(element);
              }
              if (current && !next) {
                next = element;
              }
              if (time >= start && time <= end) {
                current = element;
              }
            }
          });
          return {
            current,
            next,
            firstSlots,
            secondSlots
          };
        }
        return null;
      },
    })
  },
  methods: {
    getBookingName(slot) {
      if (!slot.booked) {
        return this.$lang.player.available_time;
      }

      if (slot.booking.comments) {
        return slot.booking.comments;
      }

      if (slot.booking.type !== 'SUBSCRIPTION' && slot.booking.players.length) {
        return slot.booking.players[0];
      }

      return slot.booking.name;
    },
  }
};
</script>
