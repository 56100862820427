<template>
  <div class="modal fade table-app-fill-form" ref="table_fill_form" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">

        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title" v-if="mode === MODE_CREATE">TABLE CONTENT</h4>
          <h4 class="modal-title" v-else>EDIT {{ this.originalName }}</h4>
        </div>

        <!-- Modal body -->
        <div class="modal-body">
          <div class="form-group" v-if="mode !== MODE_CREATE">
            <label>Name</label>
            <input type="text" class="form-control input-underline" v-model="settings.name" placeholder="Name">
          </div>
          <div class="form-group" v-if="mode !== MODE_CREATE">
            <label>Theme</label>
            <select v-model="settings.styles.table.theme" name="theme" class="form-control ">
              <option value="white-grey">White Text and Grey Background</option>
              <option value="black-white">Black Text and White Background</option>
            </select>
          </div>
          <div class="form-group" v-if="mode !== MODE_CREATE">
            <label>Rows per page</label>
            <input type="text" class="form-control input-underline" v-model="settings.styles.pagination.per_page">
          </div>
          <div class="form-group" v-if="mode !== MODE_CREATE">
            <label>Interval (seconds)</label>
            <input type="number" class="form-control input-underline" v-model="settings.styles.pagination.interval">
          </div>
          <table class="table" id="table-app-fill-table">
            <draggable v-model="settings.rows" group="rows" @start="drag=true" @end="drag=false">
              <tr v-for="(row, rowIndex) in settings.rows" :key="rowIndex"
                  :class="[row.type==='heading' ? 'row-heading' : 'row-body']">
                <td>
                  <span class="material-icons">swap_vert</span>
                </td>
                <td :class="[row.type==='heading' ? 'col-heading' : 'col-body']"
                    v-for="colIndex in settings.columns">
                  <input type="text" v-model="row.data[colIndex-1].content">
                </td>
                <td>
                  <button @click="removeRow(rowIndex)" class="btn btn-danger btn-sm">X</button>
                </td>
              </tr>
            </draggable>
          </table>
          <div class="modal-control d-flex justify-content-center">
            <button type="button" class="btn btn-transparent-blue" @click.prevent="addBodyRow">
              + Add Row
            </button>
            <button type="button" class="btn btn-transparent-blue"
                    @click.prevent="addHeadingRow">
              + Add Header Row
            </button>
          </div>
        </div>

        <!-- Modal footer -->
        <div class="modal-footer d-flex justify-content-center">
          <button type="button" class="btn btn-secondary btn-transparent-grey" data-dismiss="modal">
            Cancel
          </button>
          <button type="button" class="btn btn-secondary btn-transparent-blue" @click.prevent.stop="save">
            Save
          </button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import _ from "lodash";
import draggable from 'vuedraggable'

const $ = window.jQuery;
const MODE_CREATE = 'create';

export default {
  name: "TableAppFillModal",
  components: {
    draggable
  },
  computed: {
    ...mapState({}),
  },
  props: {
    mode: {
      required: false,
      default: MODE_CREATE,
      type: String
    }
  },
  data() {
    return {
      originalName: null,
      MODE_CREATE: MODE_CREATE,
      settings: {
        rows: [],
        styles: {
          heading: {},
          body: {},
          table: {
            theme: 'white-grey'
          },
          pagination: {
            per_page: null,
            interval: 10
          }
        }
      }
    }
  },
  methods: {
    removeRow(rowIndex) {
      this.settings.rows.splice(rowIndex, 1);
    },
    createRow() {
      const row = {
        data: []
      };
      for (let i = 0; i < this.settings.columns; i++) {
        row.data.push({
          content: ''
        })
      }

      return row;
    },
    addHeadingRow() {
      const row = this.createRow();
      row.type = 'heading';
      this.settings.rows.push(row)
    },
    addBodyRow() {
      const row = this.createRow();
      row.type = 'body';
      this.settings.rows.push(row)
    },
    open(settings) {
      this.settings = _.cloneDeep(settings);
      if(!this.settings.styles.table.theme){
        this.settings.styles.table.theme = this.getInitialData().styles.table.theme;
      }
      if(!this.settings.styles.pagination){
        this.settings.styles.pagination = this.getInitialData().styles.pagination;
      }

      if (this.mode !== MODE_CREATE) {
        this.originalName = this.settings.name;
      }
      $(this.$refs.table_fill_form).modal('show');
    },
    validateForm() {
      if (!this.settings.name) {
        return 'Name can\'t be empty';
      }

      return true;
    },
    save() {
      if (this.mode !== MODE_CREATE) {
        const error = this.validateForm();
        if (error.length) {
          this.$toasted.global.general_error({message: error});
          return;
        }
      }
      this.$emit('save-settings', this.settings);
      this.close();
    },
    close() {
      $(this.$refs.table_fill_form).modal('hide');
    },
    getInitialData: function () {
      return {
        columns: 2,
        rows: [
          {
            type: 'heading',
            data: [
              {
                content: 'Col 1'
              },
              {
                content: 'Col 2'
              },
            ]
          },
          {
            type: 'body',
            data: [
              {
                content: ''
              },
              {
                content: ''
              },
            ]
          },
        ],
        styles: {
          heading: {},
          body: {},
          table: {
            theme: 'white-grey'
          },
          pagination: {
            per_page: null,
            interval: 10
          }
        }
      };
    },
  }
}
</script>

<style lang="scss" scoped>
.table-app-fill-form {
  table {
    tr:hover {
      background-color: #f0c996;
      cursor: pointer;
    }

    .row-heading {
      background: #ebebeb;
    }

    .row-body {
      background: #fff;
    }
  }

  input {
    padding-left: 0.5rem;
  }

  .modal-control {
    & > :not(:first-child) {
      margin-left: .4rem;
    }

    .btn {
      width: 200px;
    }
  }

  .modal-footer {
    .btn {
      width: 200px;
    }
  }

}
</style>
