<template>
  <div class="layouts-list">
    <SearchBox v-if="showSearch" placeholder="Search Layout" v-model="searchQuery" />
    <div class="results" v-if="filteredLayouts.length > 0">
      <div
        v-for="layout in filteredLayouts"
        :class="['item', { active: layout.layout_id === activeLayoutId }]"
        :key="layout.id"
        @click="() => $emit('select', layout.layout_id)"
      >
        <span class="material-icons-outlined icon">dashboard</span>
        <span class="text">{{ layout.settings.name }}</span>
        <span class="material-icons-outlined right" v-if="showArrow">arrow_forward</span>
      </div>
    </div>
    <div class="results" v-else>
      <span class="text-none" v-if="searchQuery !== ''">No layouts found with name</span>
      <span class="text-none" v-else>{{ emptyText }}</span>
    </div>
  </div>
</template>

<script>
  import SearchBox from '../common/SearchBox.vue';

  export default {
    name: 'LayoutsList',
    emits: ['select'],
    props: {
      showSearch: {
        type: Boolean,
        default: false,
      },
      showArrow: {
        type: Boolean,
        default: true,
      },
      activeLayoutId: {
        type: String,
        default: null,
      },
      layouts: {
        type: Array,
        default: () => [],
      },
      emptyText: {
        type: String,
        default: 'No layouts found',
      },
    },
    components: {
      SearchBox,
    },
    data() {
      return { searchQuery: '' };
    },
    computed: {
      filteredLayouts() {
        return this.layouts.filter((layout) =>
          layout.settings.name.toLowerCase().includes(this.searchQuery.toLowerCase()),
        );
      },
    },
  };
</script>

<style lang="scss" scoped>
  .layouts-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;
    .results {
      display: flex;
      flex-direction: column;
      gap: 16px;
      transition: 100ms all ease;
      overflow-x: hidden;
      margin: -10px;
      padding: 10px;

      .text-none {
        text-align: center;
        color: red;
      }
      .item {
        display: flex;
        align-items: center;
        background-color: white;
        border: 1px solid rgba(0, 0, 0, 0.04);
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.06);
        gap: 8px;
        padding: 8px 16px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &.active {
          border-color: #e3003a;
        }
        .icon {
          font-size: 22px;
          color: #151515;
        }

        .text {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          color: #333332;
        }

        .right {
          margin-left: auto;
          color: #6a6b6a;
          font-size: 22px;
          transition: all 0.2s ease-in-out;
        }

        &:hover {
          background-color: #f5f5f5;
          box-shadow: none;
          .right {
            transform: translateX(4px);
          }
        }
      }
    }
  }
</style>
