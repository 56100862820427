<template>
  <div class="d-flex flex-column justify-content-center align-items-center h-100">
    <div class="icon">
      <span class="material-icons-outlined align-middle">apps</span>
    </div>
    <p class="font-whyte-ink title text-center mb-1">ADD APPS</p>
    <p class="font-poppins description text-center mb-0">
      Select one of suggested options. <br>
      Edit its settings and add app at your content.
      Then you’ll be able to add it to playlists.
    </p>
  </div>
</template>

<script>
export default {
  name: 'AppSettingPlaceholder'
}
</script>

<style lang="scss" scoped>
.icon {
  margin-bottom: 20px;

  span {
    font-size: 64px;
    color: #e3003a;
  }
}
.title {
  font-size: 24px;
  color: #000;
  font-weight: 400;
  max-width: 266px;
}
.description {
  font-size: 16px;
  color: #6A6B6A;
  font-weight: 500;
  max-width: 322px;
}
</style>
