<template>
  <div class="alert alert-light alert-dismissible fade show" role="alert">
    <div class="mb-1 flex align-items-center">
      <img :src="infoCircleIcon" alt="Icon info" class="mr-1"><strong>App Info!</strong>
    </div>
    <slot></slot>
    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</template>

<script>
import infoCircleIcon from '@/assets/icon/info-circle.png';
export default {
  name: "AppInfo",
  data() {
    return {
      infoCircleIcon
    }
  }
}
</script>
