<template>
  <SidebarContainer>
    <template v-if="selectedLicenses" v-slot:default>
      <div class="sidebar-container">
        <div class="form-group">
          <label for="expirationDate">
            <div class="label">New expiry date</div>
            <input
              type="date"
              name="expirationDate"
              id="expirationDate"
              required
              v-model="expirationDate"
            />
          </label>
        </div>

        <div class="list-container">
          <div v-for="(license, index) in selectedLicenses" :key="index" class="list-item">
            <div class="list-item-title">
              <span>{{ license.name }}</span>
              <span :class="expirationDateColor(license.expirationDate)">
                {{ license.expirationDate }}
              </span>
            </div>

            <i
              class="material-icons-outlined align-middle"
              @click="$emit('removeLicense', license)"
            >
              close
            </i>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:buttons>
      <button type="button" class="button-2-secondary" @click="closeRenewSidebar">Cancel</button>

      <button
        type="button"
        class="button-2-primary"
        @click="updateLicenses"
        :disabled="isSaving || selectedLicenses.length === 0"
      >
        <i v-if="isSaving" class="material-icons align-middle spin-animation">refresh</i>
        <i v-else class="material-icons-outlined align-middle">autorenew</i>
        Renew Selected Licenses
      </button>
    </template>
  </SidebarContainer>
</template>

<script>
  import moment from 'moment';
  import SidebarContainer from '@/components/common/SidebarContainer.vue';
  import DeleteLicenseModal from './DeleteLicenseModal.vue';
  import { updateLicenses } from '@/api/organisation';

  export default {
    name: 'RenewLicensesSidebar',

    components: { SidebarContainer, DeleteLicenseModal },

    props: {
      selectedLicenses: {
        type: Array,
        default: () => [],
      },
    },

    data() {
      return {
        isSaving: false,
        expirationDate: moment().add(1, 'day').format('yyyy-MM-DD'),
      };
    },

    computed: {
      organizationId() {
        if (this.selectedLicenses.length) {
          return this.selectedLicenses[0].organisation;
        }
        return null;
      },
    },
    methods: {
      async updateLicenses() {
        this.isSaving = true;
        if (!this.selectedLicenses.length) {
          this.$toasted.global.general_error({ message: 'No license selected' });
          return;
        }
        if (!this.expirationDate) {
          this.$toasted.global.general_error({ message: 'Expiration date is required' });
          return;
        }
        const today = moment().startOf('day');
        const started_at = moment().toISOString();
        const expired_time = moment(this.expirationDate).endOf('day').diff(today, 'day');
        const licenseIds = this.selectedLicenses.map((license) => license.id);

        try {
          const data = {
            started_at,
            expired_time,
            status: 'active',
          };
          await updateLicenses(this.organizationId, licenseIds, data);
          this.$toasted.global.general_success({
            message: 'Licenses are successfully renewed',
          });
          this.$emit('licensesUpdated');
          this.closeRenewSidebar();
        } catch (error) {
          this.$toasted.global.general_error({
            message: String(error),
          });
        }
        this.isSaving = false;
      },

      closeRenewSidebar() {
        this.$emit('closeSidebar');
      },

      expirationDateColor(date) {
        const newDate = moment(date, 'DD/MM/YYYY').toDate();

        const colorClass = moment(newDate).isBefore() ? 'text-expired' : 'text-orange';

        return colorClass;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .sidebar-container {
    display: flex;
    flex-direction: column;
    gap: 16px;

    button {
      margin-top: 8px;
    }
  }

  .content-section {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }

  .form-group {
    width: 100%;
    margin-bottom: 0;

    label {
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
      gap: 8px;

      font-weight: 500;
      line-height: 24px;

      .label {
        display: flex;
        gap: 8px;
        align-items: center;

        font-size: 14px;
      }

      input {
        padding: 8px 16px;

        border: 2px solid $borderGrey;
        border-radius: 8px;
        font-size: 16px;
      }
    }
  }

  .list-item-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-right: 16px;
  }
</style>
