import '@tiptap/extension-text-style';

import { Extension } from '@tiptap/core';
import { MarkType, NodeType } from '@tiptap/pm/model';

export interface ExtendedFontFamilyOptions {
  types: string[];
  getStyle: (fontFamily: string) => string;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    extendedFontFamily: {
      /**
       * Set the font family of a node or mark
       */
      setNodeFontFamily: (
        typeOrName: string | NodeType | MarkType,
        fontFamily: string,
      ) => ReturnType;
      /**
       * Unset the font family of a node or mark
       */
      unsetNodeFontFamily: (typeOrName: string | NodeType | MarkType) => ReturnType;
    };
  }
}

export const ExtendedFontFamily = Extension.create<ExtendedFontFamilyOptions>({
  name: 'extendedFontFamily',

  addOptions() {
    return {
      types: ['textStyle'],
      getStyle: (fontFamily: string) => {
        return `font-family: ${fontFamily}`;
      },
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          fontFamily: {
            default: null,
            parseHTML: (element) => element.style.fontFamily?.replace(/['"]+/g, ''),
            renderHTML: (attributes) => {
              if (!attributes.fontFamily) {
                return {};
              }

              return {
                style: this.options.getStyle(attributes.fontFamily),
              };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setNodeFontFamily:
        (typeOrName, fontFamily) =>
        ({ commands }) => {
          return commands.updateAttributes(typeOrName, { fontFamily: fontFamily });
        },
      unsetNodeFontFamily:
        (typeOrName) =>
        ({ commands }) => {
          return commands.resetAttributes(typeOrName, 'fontFamily');
        },
    };
  },
});
