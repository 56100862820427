<template>
  <div class="folder-item" :class="{ 'is-folder': isFolder, active: active, focused }">
    <div v-if="!isFolder" class="folder-header" @click="handleClick(false)">
      <div class="folder-name">
        Content without folders <span class="light">({{ mediaTotal }})</span>
      </div>
    </div>
    <div v-else-if="shouldShowBreadcrumb" class="folder-header folder-breadcrumb">
      <div class="back-icon" @click="navigateBack">
        <i class="material-icons-outlined align-middle button"> arrow_back </i>
      </div>
      <div class="folder-icon small">
        <img src="@/assets/img/content/folder.svg" alt="" />
      </div>
      <div class="folder-path">
        <div v-for="(pathItem, index) in path" :key="index + '_' + pathItem" class="path-item">
          {{ pathItem }}
        </div>
      </div>
      <div class="dropdown move-right">
        <i
          class="material-icons-outlined align-middle more-buttons dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          >more_vert</i
        >
        <div class="dropdown-menu dropdown-menu-right">
          <button type="button" class="dropdown-item" @click="showDeleteModal = true">
            <i class="material-icons-outlined align-middle">delete</i>
            Delete
          </button>
          <button type="button" class="dropdown-item" @click="openEditFolder">
            <i class="material-icons-outlined align-middle">edit</i>
            Edit
          </button>
        </div>
      </div>
    </div>
    <div v-else class="folder-header" @click="handleClick(folder)">
      <div class="folder-icon">
        <img src="@/assets/img/content/folder.svg" alt="" />
      </div>
      <div class="folder-details">
        <div class="folder-name">
          <span class="text">{{ folder.name }}</span>

          <div class="dropdown">
            <i
              class="material-icons-outlined align-middle more-buttons dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              >more_vert</i
            >
            <div class="dropdown-menu dropdown-menu-right">
              <button type="button" class="dropdown-item" @click="showDeleteModal = true">
                <i class="material-icons-outlined align-middle">delete</i>
                Delete
              </button>
              <button type="button" class="dropdown-item" @click="openEditFolder">
                <i class="material-icons-outlined align-middle">edit</i>
                Edit
              </button>
            </div>
          </div>
        </div>
        <!-- TODO: Add folder description to backend if approved -->
        <!-- <div class="folder-description">General assets, logos, etc.</div> -->
      </div>
    </div>

    <div class="folder-body">
      <div class="media-counts">
        <div class="media-count" v-if="mediaImageTotal && mediaImageTotal > 0">
          <i class="material-icons-outlined align-middle">crop_original</i>
          <span class="count">{{ mediaImageTotal }}</span>
        </div>
        <div class="media-count" v-if="mediaVideoTotal && mediaVideoTotal > 0">
          <i class="material-icons-outlined align-middle">play_circle_outline</i>
          <span class="count">{{ mediaVideoTotal }}</span>
        </div>
        <div class="media-count" v-if="mediaPdfTotal && mediaPdfTotal > 0">
          <i class="material-icons-outlined align-middle">text_snippet</i>
          <span class="count">{{ mediaPdfTotal }}</span>
        </div>
        <div class="media-count" v-if="mediaAppsTotal && mediaAppsTotal > 0">
          <i class="material-icons-outlined align-middle">widgets</i>
          <span class="count">{{ mediaAppsTotal }}</span>
        </div>
      </div>
      <div class="media-total">({{ mediaTotal }})</div>
    </div>

    <div class="folder-footer" v-if="subFolders.length > 0">
      <div
        v-for="subFolder in subFolders"
        :key="subFolder.path"
        class="sub-folder"
        @click="navigateForward(subFolder)"
      >
        <!-- folder icon -->
        <i class="material-icons-outlined align-middle">folder</i>
        <span class="name">
          {{ subFolder.name }}
        </span>
        <i class="material-icons-outlined align-middle">arrow_forward</i>
      </div>
    </div>

    <FolderDeleteModal
      :folder="selectedFolder"
      v-if="showDeleteModal"
      @onDelete="$emit('onDelete')"
      @closeModal="showDeleteModal = false"
    />

    <EditSingleItem
      v-if="showEditFolderModal"
      title="Edit Folder Name"
      label="Name"
      placeholder="Edit Name"
      :field="selectedFolder?.name"
      :validation="maxLength(30)"
      @field-modified="handleEditFolder"
      @closeModal="closeEditFolderModal"
    />
  </div>
</template>

<script>
  import FolderDeleteModal from '@/components/content/FolderDeleteModal.vue';
  import EditSingleItem from '@/components/common/EditSingleItem.vue';

  import { simpleTypeMixin } from '@/helpers';
  import { CONTENT_TYPE_FOLDER } from '@/constant/const';
  import { apiUpdateContentFolder } from '@/api/content';
  import { validationsMixin } from '@/helpers/mixins';

  export default {
    props: {
      isFolder: {
        type: Boolean,
        default: false,
      },
      folder: {
        type: Object,
        default: () => null,
      },
      totals: {
        type: Object,
        default: () => null,
      },
      active: {
        type: Boolean,
        default: false,
      },
      focused: {
        type: Boolean,
        default: false,
      },
      selectedFolder: {
        type: Object | Array,
        default: () => null,
      },
    },

    components: { FolderDeleteModal, EditSingleItem },

    emits: ['selectFolder'],

    mixins: [simpleTypeMixin, validationsMixin],

    data() {
      return {
        path: [],
        showDeleteModal: false,
        showEditFolderModal: false,
      };
    },

    watch: {
      active(value) {
        if (!value) this.path = [this.folder?.name];
      },
    },

    created() {
      this.path = [this.folder?.name];
      if (this.selectedFolder && this.folder?.path !== this.selectedFolder?.path) {
        const paths = this.selectedFolder?.path?.split('/') || [];

        if (paths.length) {
          this.path = paths;
        }
      }
    },

    computed: {
      subFolders() {
        if (this.folder) {
          return (
            this.findFolderWithPathArray()?.contents?.filter(
              (content) => content.content_type === CONTENT_TYPE_FOLDER,
            ) || []
          );
        }
        return [];
      },

      shouldShowBreadcrumb() {
        return this.path.length > 1;
      },

      mediaTotal() {
        if (!this.isFolder) return Object.values(this.totals).reduce((a, b) => a + b, 0);
        return this.folder.contents.length;
      },
      mediaImageTotal() {
        if (!this.isFolder) return this.totals.images;
        return this.getTypeTotalsDeep(this.folder, 'image');
      },
      mediaVideoTotal() {
        if (!this.isFolder) return this.totals.videos;
        return this.getTypeTotalsDeep(this.folder, 'video');
      },
      mediaPdfTotal() {
        if (!this.isFolder) return this.totals.pdfs;
        return this.getTypeTotalsDeep(this.folder, 'pdf');
      },
      mediaAppsTotal() {
        if (!this.isFolder) return this.totals.apps;
        return this.getAppTotalDeep(this.folder);
      },
    },

    methods: {
      getTypeTotalsDeep(folder, type) {
        let total = 0;
        folder.contents.forEach((content) => {
          if (content.content_type === CONTENT_TYPE_FOLDER) {
            total += this.getTypeTotalsDeep(content, type);
          } else if (this.getSimpleType(content.item_type) === type) {
            total += 1;
          }
        });
        return total;
      },

      getAppTotalDeep(folder) {
        let total = 0;
        folder.contents.forEach((content) => {
          if (content.content_type === CONTENT_TYPE_FOLDER) {
            total += this.getAppTotalDeep(content);
          } else if (
            content.item_type &&
            content.item_type.startsWith('app/') &&
            !content.item_type.includes('pdf')
          ) {
            total += 1;
          }
        });
        return total;
      },

      navigateForward(folder) {
        this.path.push(folder.name);
        this.$emit('select-folder', this.folder, folder);
      },

      navigateBack() {
        this.path.pop();
        const prevFolder = this.findFolderWithPathArray();
        this.$emit('select-folder', this.folder, prevFolder, this.subFolders);
      },

      handleClick(folder) {
        this.$emit('select-folder', this.folder, folder);
      },

      findFolderWithPathArray() {
        if (this.path.length === 1) return this.folder;
        let currentFolder = this.folder;
        for (let i = 1; i < this.path.length; i += 1) {
          const found = currentFolder?.contents?.find((content) => content.name === this.path[i]);

          if (found) {
            currentFolder = found;
          }
        }
        return currentFolder;
      },

      openEditFolder() {
        this.showEditFolderModal = true;
      },

      async handleEditFolder(newFolderName) {
        try {
          await apiUpdateContentFolder({
            name: newFolderName,
            folderId: this.selectedFolder?.id,
          });
          this.$toasted.global.general_success({ message: 'Folder name changed successfully' });
          this.$emit('onEdit');
          this.closeEditFolderModal();
        } catch (error) {
          this.$toasted.global.general_error({
            message: error.data?.message ?? 'Error Saving Folder Name',
          });
          console.log('FolderItem.vue - editFolder error: ', error);
        }
      },

      closeEditFolderModal() {
        this.showEditFolderModal = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .folder-item {
    background-color: white;
    box-shadow: 0px 0px 16px rgba(21, 21, 21, 0.02);
    padding: 16px 20px;
    border: 1px solid transparent;
    &.active {
      box-shadow:
        0px 0px 0px 2px rgba(227, 0, 58, 0.2),
        0px 0px 16px rgb(21 21 21 / 2%);
    }
    &.focused {
      border: 1px solid #e3003a;
    }
    .folder-header {
      cursor: pointer;
      .folder-name {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        .light {
          font-size: 14px;
          color: #6a6b6a;
        }
      }
      .move-right {
        margin-left: auto;
      }
    }
    .folder-body {
      padding-top: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .media-counts {
        display: flex;
        .media-count {
          display: flex;
          align-items: center;
          margin-right: 12px;
          i {
            line-height: 15px;
            vertical-align: middle;
            color: #6a6b6a;
            margin-right: 8px;
          }

          .count {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            vertical-align: middle;
          }
        }
      }

      .media-total {
        display: none;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #6a6b6a;
      }
    }

    &.is-folder {
      .folder-header {
        display: flex;
        gap: 16px;
        padding-bottom: 16px;
        &.folder-breadcrumb {
          // align-items: center;
          gap: 8px;
          .back-icon {
            cursor: pointer;
            i {
              font-size: 20px;
              color: #323232;
            }
          }
          .folder-path {
            display: flex;
            gap: 8px;
            .path-item {
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
              color: #6a6b6a;
              // not last ::after
              &:not(:last-child)::after {
                content: '/';
              }
              &:last-child {
                color: #151515;
              }
            }
          }
        }
        .folder-icon {
          &.small {
            height: 20px;
            img {
              height: 100%;
            }
          }
        }
        .folder-details {
          flex: 1;
          .folder-name {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 4px;
          }

          .folder-description {
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #6a6b6a;
          }
          i {
            color: #333332;
          }
        }
      }
      .folder-body {
        padding-top: 16px;
        border-top: 1px solid #f5f5f5;

        .media-total {
          display: block;
        }
      }

      .folder-footer {
        padding-top: 16px;
        margin-top: 16px;
        border-top: 1px solid #f5f5f5;
        display: flex;
        flex-direction: column;
        gap: 8px;

        .sub-folder {
          border: 1px solid rgba(0, 0, 0, 0.04);
          box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.06);
          padding: 8px 16px;
          display: flex;
          align-items: center;
          gap: 8px;
          cursor: pointer;
          &:hover {
            background-color: #ececec;
          }
          .name {
            flex: 1;
            font-size: 14px;
            line-height: 21px;
            color: #151515;
          }
        }
      }
    }
  }
</style>
