<template>
  <SidebarContainer>
    <template v-if="!!layout" v-slot:default>
      <div class="sidebar-container">
        <div class="preview-container">
          <LayoutsPreviewModal
            v-if="showPreviewModal"
            :layoutId="layout.layout_id"
            @closeModal="handleClosePreview"
          />

          <div
            class="preview-content"
            :style="{
              aspectRatio: currentAspectRatio,
              width: previewWidth,
              height: previewHeight,
            }"
          >
            <Layout v-if="!showPreviewModal" :layout="layout" />
          </div>

          <div class="preview-footer">
            <i
              class="material-icons-outlined align-middle button"
              @click="openPreviewModal"
              title="Full Screen"
            >
              fullscreen
            </i>
          </div>
        </div>

        <div class="form-group">
          <label for="layoutName">
            <div class="label">Layout Name</div>
            <input type="text" name="layoutName" id="layoutName" required v-model="layoutName" />
          </label>
        </div>

        <button type="button" class="button-2-third" @click="goToLayout">
          <i class="material-icons-outlined align-middle">edit</i>
          {{ layoutTemplatesMode ? 'Edit Layout Template' : 'Edit Layout' }}
        </button>

        <button
          v-show="!layoutTemplatesMode"
          type="button"
          class="button-2-secondary"
          @click="openCopyLayoutModal"
        >
          <i class="material-icons-outlined align-middle">content_copy</i>
          Make a Copy
        </button>

        <div class="content-section">
          <div v-if="layout.created" class="dropdown-section">
            <div class="dropdown" @click="showInfo = !showInfo">
              <span>Info</span>
              <i class="material-icons-outlined align-middle" :class="{ 'text-red': showInfo }">{{
                showInfo ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
              }}</i>
            </div>

            <div v-if="showInfo" class="content-container">
              <div class="description-container">
                <div class="description-item">
                  <span>Created: </span>{{ formatDate(layout.created, 'date') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-if="!!layout" v-slot:buttons>
      <button
        v-if="isAdmin"
        type="button"
        class="button-2-secondary"
        @click="openDeleteLayoutModal"
      >
        <i class="material-icons-outlined align-middle">delete_outline</i>
        {{ layoutTemplatesMode ? 'Delete Layout Template' : 'Delete Layout' }}
      </button>

      <button type="button" class="button-2-primary" @click="updateLayout" :disabled="isSaving">
        <i v-if="isSaving" class="material-icons align-middle spin-animation">refresh</i>
        <i v-else class="material-icons-outlined align-middle">save</i>
        Save Settings
      </button>

      <DeleteLayoutModal
        @closeModal="showDeleteLayoutModal = false"
        :layoutId="layout?.layout_id"
        v-if="showDeleteLayoutModal"
        @deleted="$emit('layoutDeleted')"
      />

      <CopyLayoutModal
        v-if="showCopyLayoutModal"
        :layout="$store.state.player.layout"
        :widgets="$store.state.player.widgets"
        @closeModal="showCopyLayoutModal = false"
        @copied="$emit('updateLayouts')"
      />
    </template>
  </SidebarContainer>
</template>

<script>
  import SidebarContainer from '@/components/common/SidebarContainer.vue';
  import DeleteLayoutModal from '@/components/layouts/DeleteLayoutModal.vue';
  import CopyLayoutModal from '@/components/layouts/CopyLayoutModal.vue';
  import Layout from '@/components/player/Layout.vue';
  import LayoutsPreviewModal from '@/components/layouts/LayoutsPreviewModal.vue';

  import { apiUpdateLayout } from '@/api/layouts';
  import { globalFiltersMixin } from '@/helpers/mixins';

  import {
    DEFAULT_LAYOUT_ASPECT_RATIO,
    DEFAULT_LAYOUT_RESOLUTION,
    LAYOUT_ASPECT_RATIOS,
    LAYOUT_RESOLUTIONS,
  } from '@/models/layoutDesigner';

  import { PLAYER_LOAD_LAYOUT_CHILDREN, PLAYER_SET_LAYOUT } from '@/store/actions/player';

  export default {
    name: 'LayoutsSidebar',

    components: {
      SidebarContainer,
      DeleteLayoutModal,
      Layout,
      CopyLayoutModal,
      LayoutsPreviewModal,
    },

    mixins: [globalFiltersMixin],

    props: {
      layout: {
        type: Object,
        default: () => null,
      },
      layoutTemplatesMode: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        isSaving: false,
        showInfo: false,
        showPreviewModal: false,
        showDeleteLayoutModal: false,
        showCopyLayoutModal: false,
        layoutName: '',
        aspectRatio: DEFAULT_LAYOUT_ASPECT_RATIO,
        resolution: DEFAULT_LAYOUT_RESOLUTION,
        LAYOUT_ASPECT_RATIOS,
      };
    },

    watch: {
      layout() {
        this.getWidgets();
      },
    },

    mounted() {
      this.getWidgets();
    },

    computed: {
      isAdmin() {
        return this.$store.getters.isAdministrator;
      },

      resolutions() {
        return LAYOUT_RESOLUTIONS.resolutions[this.aspectRatio];
      },

      isHorizontal() {
        return (
          this.$store.state.player.layout && this.$store.state.player.layout.settings.isHorizontal
        );
      },

      currentAspectRatio() {
        return this.$store.getters.getAspectRatio;
      },

      previewWidth() {
        const aspectRatio = this.aspectRatio.split(':');

        return !this.isHorizontal ? `${(350 * aspectRatio[1]) / aspectRatio[0]}px` : '100%';
      },

      previewHeight() {
        const aspectRatio = this.aspectRatio.split(':');

        return this.isHorizontal ? `${(350 * aspectRatio[1]) / aspectRatio[0]}px` : '350px';
      },
    },

    methods: {
      async getWidgets() {
        this.aspectRatio = this.layout.settings?.aspectRatio || DEFAULT_LAYOUT_ASPECT_RATIO;
        this.resolution = this.layout.settings?.resolution || DEFAULT_LAYOUT_RESOLUTION;
        this.layoutName = this.layout.settings?.name;

        this.$store.commit(PLAYER_SET_LAYOUT, this.layout);

        const response = await this.$store.dispatch(PLAYER_LOAD_LAYOUT_CHILDREN, {
          layoutId: this.layout?.layout_id,
        });

        if (!response) {
          this.$toasted.global.general_error({
            message: 'Layout content data not found. Please try again',
          });
        }
      },

      async updateLayout() {
        this.isSaving = true;

        try {
          if (!this.layoutName) {
            this.$toasted.global.general_error({
              message: 'Layout name cannot be empty',
            });

            this.isSaving = false;
            return;
          }

          const layoutData = {
            ...this.layout.settings,
            name: this.layoutName,
            aspectRatio: this.aspectRatio,
            resolution: this.resolution,
            settings: undefined,
          };

          await apiUpdateLayout(this.layout.layout_id, layoutData);

          this.$toasted.global.general_success({
            message: 'Layout updated successfully',
          });
          this.$emit('layoutUpdated');
        } catch (error) {
          this.$toasted.global.general_error({
            message: String(error),
          });
        }

        this.isSaving = false;
      },

      openDeleteLayoutModal() {
        this.showDeleteLayoutModal = true;
      },

      openCopyLayoutModal() {
        this.showCopyLayoutModal = true;
      },

      settingDefaultResolution(event) {
        const newAspectRatio = event.target.value;
        this.resolution = LAYOUT_RESOLUTIONS.defaultValues[newAspectRatio];
      },

      goToLayout() {
        this.$router.push({
          name: 'Layout',
          params: {
            layout_id: this.layout.layout_id,
          },
        });
      },

      async openPreviewModal() {
        this.showPreviewModal = true;
      },

      handleClosePreview() {
        this.showPreviewModal = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .layout-player-link a {
    color: $primaryRed !important;
    font-size: 14px;
  }

  .sidebar-container {
    display: flex;
    flex-direction: column;
    gap: 16px;

    button {
      margin-top: 8px;
    }
  }

  .content-section {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }

  .form-group {
    width: 100%;
    margin-bottom: 0px;

    select {
      color: $primaryText;
      background: white;
      border: none;
    }

    label {
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
      gap: 8px;

      font-weight: 500;
      line-height: 24px;

      .label {
        display: flex;
        gap: 8px;
        align-items: center;

        font-size: 14px;
      }

      input {
        padding: 8px 16px;

        border: 2px solid $borderGrey;
        border-radius: 8px;
        font-size: 16px;
      }
    }
  }

  .dropdown-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 8px;
    gap: 8px;
    width: 100%;

    .dropdown {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      line-height: 24px;
      padding: 8px 0;
      font-weight: 500;
      cursor: pointer;
    }
  }

  .content-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 16px;
    border-bottom: 2px solid $lineGrey;
    overflow-x: hidden;
  }

  .description-container {
    display: flex;
    flex-direction: column;
    padding: 12px 2px;
    gap: 8px;

    .description-item {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: $primaryText;

      a {
        color: $primaryText;
      }

      span {
        color: $secondaryText;
        font-weight: 400;
      }
    }
  }

  .title {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: 14px !important;
    line-height: 24px;
    margin-bottom: -8px;
    color: $primaryText;

    i {
      color: $secondaryText;
    }
  }

  .preview-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;

    .preview-content {
      min-height: 205px;
      max-height: 350px;
      background-color: $fillGrey;
      position: relative;
      cursor: pointer;
      overflow: hidden;
    }

    .preview-footer {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      i {
        color: $secondaryText;

        &:hover {
          color: $primaryText;
        }
      }
    }
  }
</style>
