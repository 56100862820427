<script lang="ts">
  export default {
    name: 'PlayerModal',
  };
</script>

<script setup lang="ts">
  import { computed, onMounted, onUnmounted, ref } from 'vue';
  import { useStore } from '@/store';

  import { ButtonContentType, ButtonWidgetObject } from '@/types/widgets/buttonWidget/buttonWidget';
  import { CLOSE_PLAYER_MODAL } from '@/store/actions/player';

  const { closeButton } = defineProps({
    closeButton: { type: Boolean, default: true },
  });

  const store = useStore();
  const emit = defineEmits<{ (event: 'closeModal'): void }>();
  const isPlaying = ref(false);
  const timer = ref<ReturnType<typeof setTimeout> | null>(null);
  const videoWidget = ref<HTMLVideoElement | null>(null);

  const content = computed<ButtonWidgetObject>(() => store.state.player.modalContent);
  const isVideoContent = computed(function () {
    return content.value.contentType === ButtonContentType.VIDEO;
  });
  const isImageContent = computed(function () {
    return content.value.contentType === ButtonContentType.IMAGE;
  });

  const playVideo = () => {
    if (!videoWidget.value) return;

    videoWidget.value.currentTime = 0;
    videoWidget.value.play();
    isPlaying.value = true;
  };

  const toggleVideo = () => {
    if (isPlaying) {
      playVideo();
    } else {
      pauseVideo();
    }
  };

  const pauseVideo = () => {
    if (!videoWidget.value) return;

    videoWidget.value.pause();
    isPlaying.value = false;
  };

  const closeModal = () => {
    store.commit(CLOSE_PLAYER_MODAL);
    emit('closeModal');
  };

  const handleClickOutside = () => {
    closeModal();
  };

  onMounted(() => {
    const closeTime = content.value.idleTimeout || 120;
    timer.value = setTimeout(closeModal, closeTime * 1000);

    if (isVideoContent.value) playVideo();
  });

  onUnmounted(() => {
    if (!timer.value) return;

    clearTimeout(timer.value);
  });
</script>

<template>
  <transition name="modal">
    <div class="modal-mask" @click="handleClickOutside">
      <div class="modal-wrapper">
        <div
          class="player-container"
          :class="{
            video: isVideoContent,
            image: isImageContent,
            url: !isImageContent && !isVideoContent,
          }"
          @click.stop
        >
          <div v-show="closeButton" class="modal-close-button">
            <i class="material-icons button close-button" @click="closeModal">close</i>
          </div>

          <video
            v-if="isVideoContent"
            class="widget-video player"
            muted
            loop
            ref="videoWidget"
            @click="toggleVideo()"
            :src="content.contentLink || ''"
          />

          <iframe
            class="content-iframe"
            v-else-if="content.contentType === ButtonContentType.URL"
            :src="content.contentLink || ''"
          />

          <img v-else :src="content.contentLink || ''" alt="Image" class="player" />
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    transition: opacity 0.3s ease;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
  }

  .player {
    display: flex;
    position: relative;
    padding: 0;
    margin: 0px auto;
    transition: all 0.8s ease;
  }

  .widget-video {
    max-width: 100%;
    max-height: calc(90vh - 90px);
  }

  .player-container {
    position: relative;

    &.url {
      height: calc(100vh - 160px);
      width: calc(100vw - 160px);
      background-color: white;
    }

    iframe {
      height: 100%;
      width: 100%;
      border: none;
    }

    img {
      max-width: calc(90vw - 90px);
      max-height: calc(90vh - 90px);
    }

    &.video {
      max-width: calc(90vw - 90px);
      max-height: calc(90vh - 90px);
    }

    &.image {
      max-width: calc(90vw - 90px);
      max-height: calc(90vh - 90px);
    }

    .close-button {
      color: white;
      font-weight: normal;
      position: absolute;
      background-color: rgba(240, 240, 240, 0.2);
      padding: 8px;
      right: -50px;
      top: -50px;
      font-size: 24px;
      border-radius: 8px;

      &:hover {
        background-color: $primaryRed;
      }
    }
  }

  /* Transitions */
  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
</style>
