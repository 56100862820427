<template>
  <div class="matchi-activities">
    <div class="matchi-activities-card card-shadow" v-for="activity in validActivities" :key="activity.id">
      <div>
        <div class="matchi-activities-card-image">
          <img :src="activity.img" v-if="activity.img"/>
          <img v-else src="../../assets/img/matchi/ActivitiesDefault.jpg"/>
        </div>
        <div class="matchi-activities-card-header">
          <div class="icon">
            <img v-if="getSportType(activity) === 'BADMINTON'" src="../../assets/icon/icon-w-bg/BadmintonIcon.png">
            <img v-if="getSportType(activity) === 'PADEL'" src="../../assets/icon/icon-w-bg/PadelIcon.png">
            <img v-if="getSportType(activity) === 'PICKLEBALL'" src="../../assets/icon/icon-w-bg/PickleballIcon.png">
            <img v-if="getSportType(activity) === 'SQUASH'" src="../../assets/icon/icon-w-bg/SquashIcon.png">
            <img v-if="getSportType(activity) === 'TABLETENNIS'" src="../../assets/icon/icon-w-bg/TableTennisIcon.png">
            <img v-if="getSportType(activity) === 'TENNIS'" src="../../assets/icon/icon-w-bg/TennisIcon.png">
            <!--            <img v-if="getSportType(activity) === 'BOWLING'" src="../../assets/icon/icon-w-bg/BowlingIcon.png">-->
            <img v-if="getSportType(activity) === 'GOLF'" src="../../assets/icon/icon-w-bg/GolfIcon.png">
            <img v-if="getSportType(activity) === 'FLOORBALL'" src="../../assets/icon/icon-w-bg/FloorballIcon.png">
            <!--            <img v-if="getSportType(activity) === 'SHUFFLEBOARD'" src="../../assets/icon/icon-w-bg/ShuffleboardIcon.png">-->
            <img v-if="getSportType(activity) === 'TRAMPOLINE'" src="../../assets/icon/icon-w-bg/TrampolineIcon.png">
            <img v-if="getSportType(activity) === 'YOGA'" src="../../assets/icon/icon-w-bg/YogaIcon.png">
            <img v-if="getSportType(activity) === 'CAGEBALL'" src="../../assets/icon/icon-w-bg/TennisIcon.png">
            <img v-if="getSportType(activity) === 'OTHER'" src="../../assets/icon/icon-w-bg/OtherIcon.svg">
          </div>
          <div class="title">
            {{activity.name}}
          </div>
        </div>
        <div class="matchi-activities-card-date">
          <div class="date">
            {{getDateObj(activity.occasions[0].start).format("ddd D/M") | capitalize}}
          </div>
          <div class="time">
            {{getAtivityTimes(activity)}}
          </div>
        </div>
        <div class="matchi-activities-card-description">
          {{activity.description}}
        </div>
      </div>
      <div class="matchi-activities-card-footer">
        <!--<div class="matchi-activities-card-players">
          <div class="palyers">
            <div>{{$lang.player.game_strength}}</div>
            <div>3-5</div>
          </div>
          <div class="palyers">
            <div>{{$lang.player.players}}</div>
            <div>{{activity.occasions[0].participants.length}}/{{activity.occasions[0].maxParticipants}}</div>
          </div>
        </div>-->
        <div class="matchi-activities-card-path">
          <div>{{$lang.player.tracks}}</div>
          <div class="name">{{activity.occasions[0].slots[0].court.name}}</div>
          <div>
            {{activity.occasions[0].slots[0].court.sport.type.toLowerCase() | capitalize}}.
            {{activity.occasions[0].slots[0].court.indoor ? "Indoor" : "Outdoor"}}.
            {{activity.occasions[0].slots[0].court.surface.toLowerCase() | capitalize}}.
          </div>
        </div>
        <div class="matchi-activities-card-users">
          <div class="players">
            <div>{{$lang.player.players}}</div>
            <div>{{activity.occasions[0].participants.length}}/{{activity.occasions[0].maxParticipants}}</div>
          </div>
          <div class="avatars">
            <div v-for="user in getUsers(activity)" :key="user.name" class="avatar">
              <div v-if="!user.profileImg">
                {{`${user.name[0]}`}}
              </div>
              <img v-else :src="user.profileImg" :alt="user.name">
            </div>
            <div v-if="activity.occasions[0].participants.length > 8" class="avatar">
              <div class="plus">
                {{`${activity.occasions[0].participants.length - 7}+`}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const moment = require('moment-timezone');
import config from '../../config';

export default {
  name: "MatchiActivities",
  props:["validActivities"],
  data() {
    return {};
  },
  methods: {
    getDateObj(dateString = null) {
      const momentObj = dateString ? moment(dateString) : moment();
      const _locale = this.$lang.current_lang === 'sw' ? 'sv' : this.$lang.current_lang;
      momentObj.locale(_locale);
      return momentObj.tz(config.timezone);
    },
    getAtivityTimes(activity) {
      const stringTime = activity.occasions.map(o => {
        return this.getDateObj(o.start).format("HH:mm") + '-'+ this.getDateObj(o.end).format("HH:mm");
      })
      return stringTime.slice(0, 3).join('; ');
    },
    getUsers(activity) {
      const users = activity.occasions[0].participants;
      if (users.length > 8) {
        return users.slice(0, 7);
      } else {
        return users;
      }
    },
    getSportType(activity) {
      return activity.occasions[0].slots[0].court.sport.type;
    }
  },
  mounted()
  {
  }
};
</script>
