<template>
  <div class="screens-rotation" @click="$emit('selected')">
    <div v-if="horizontalScreens > 0 || showEmptyScreens" class="screen">
      <i class="material-icons-outlined align-middle" title="Landscape">stay_current_landscape</i>
      <span v-show="!hideNumbers">
        {{ horizontalScreens }}
      </span>
    </div>
    <div v-if="verticalScreens > 0 || showEmptyScreens" class="screen">
      <i class="material-icons-outlined align-middle" title="Portrait">stay_current_portrait</i>
      <span v-show="!hideNumbers">
        {{ verticalScreens }}
      </span>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ScreenRotationIcons',

    props: {
      verticalScreens: {
        type: Number,
        default: 0,
      },
      horizontalScreens: {
        type: Number,
        default: 0,
      },
      showEmptyScreens: {
        type: Boolean,
        default: false,
      },
      hideNumbers: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .screens-rotation {
    display: flex;
    align-items: center;
    font-size: 14px;
    gap: 16px;
    color: $secondaryText;
    font-weight: 500;

    .screen {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
</style>
