<template>
  <div class="lights-container">
    <div v-if="connected > 0 || showEmptyLights" class="online-status" title="Online">
      <span class="light" :style="{ height: `${size}px`, width: `${size}px` }">
        <div
          class="interior-light"
          :style="{
            height: `${size / 2}px`,
            width: `${size / 2}px`,
            left: `${size / 4}px`,
            top: `${size / 4}px`,
          }"
        ></div>
      </span>

      <span v-if="!hideNumbers" class="value">
        {{ connected }}
      </span>

      <span v-if="showText" class="label green">On</span>
    </div>

    <div v-if="unknown > 0 || showEmptyLights" class="online-status" title="Unknown">
      <span class="light grey" :style="{ height: `${size}px`, width: `${size}px` }">
        <div
          class="interior-light"
          :style="{
            height: `${size / 2}px`,
            width: `${size / 2}px`,
            left: `${size / 4}px`,
            top: `${size / 4}px`,
          }"
        ></div>
      </span>

      <span v-if="!hideNumbers" class="value">
        {{ unknown }}
      </span>

      <span v-if="showText" class="label grey">Unknown</span>
    </div>

    <div v-if="disconnected > 0 || showEmptyLights" class="online-status" title="Offline">
      <span class="light red" :style="{ height: `${size}px`, width: `${size}px` }">
        <div
          class="interior-light"
          :style="{
            height: `${size / 2}px`,
            width: `${size / 2}px`,
            left: `${size / 4}px`,
            top: `${size / 4}px`,
          }"
        ></div>
      </span>

      <span v-if="!hideNumbers" class="value">
        {{ disconnected }}
      </span>

      <span v-if="showText" class="label red">Off</span>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ConnectionLights',

    props: {
      connected: {
        type: Number,
        default: 0,
      },
      disconnected: {
        type: Number,
        default: 0,
      },
      unknown: {
        type: Number,
        default: 0,
      },
      showEmptyLights: {
        type: Boolean,
        default: false,
      },
      hideNumbers: {
        type: Boolean,
        default: false,
      },
      size: {
        type: Number,
        default: 16,
      },
      showText: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .lights-container {
    display: flex;
    gap: 18px;
    font-size: 14px;
    line-height: 21px;
  }

  .online-status {
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;

    .light {
      background: rgba(36, 178, 71, 0.25);
      border-radius: 50%;
      position: relative;
      left: 0px;

      &.red {
        background: rgba(255, 0, 0, 0.25);

        .interior-light {
          background-color: $redLight;
        }
      }

      &.grey {
        background: rgba(0, 0, 0, 0.1);

        .interior-light {
          background-color: #a0a4b8;
        }
      }

      .interior-light {
        background-color: $greenLight;
        position: absolute;
        border-radius: 50%;
      }
    }

    label {
      font-weight: 400;

      &.green {
        color: $greenLight;
      }

      &.red {
        color: $redLight;
      }

      &.grey {
        color: $primaryText;
      }
    }
  }
</style>
