<template>
  <div class="image-widget-panel scrollbar">
    <div class="form-group layout-input name">
      <label for="image-name">Name</label>
      <input
        class="form-control"
        type="text"
        id="image-name"
        placeholder="Name"
        disabled
        v-model="value.position.name"
      />
    </div>

    <div class="measurements-container">
      <div class="measurements">
        <div class="form-group hidden position">
          <div class="form-row">
            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">X</span>
              </div>
              <input
                type="number"
                class="form-control"
                id="layer-x"
                v-model="xPosition"
                :disabled="isWidgetLocked"
              />
            </div>

            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">Y</span>
              </div>
              <input
                type="number"
                class="form-control"
                v-model="yPosition"
                :disabled="isWidgetLocked"
              />
            </div>
          </div>
        </div>

        <div class="form-group hidden size">
          <div class="form-row">
            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">W</span>
              </div>
              <input
                type="number"
                class="form-control"
                id="layer-width"
                v-model="width"
                :disabled="isWidgetLocked"
              />
            </div>

            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">H</span>
              </div>
              <input
                type="number"
                class="form-control"
                v-model="height"
                :disabled="isWidgetLocked"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="extra-icons">
        <LockIcon :locked="isWidgetLocked" />

        <PixelIcon :usePixels="usePixels" @clicked="changeValuesMode" />
      </div>
    </div>

    <button type="button" class="button-2-secondary" @click="openContentBrowser">
      <i class="material-icons-outlined align-middle">image</i>
      Replace Image
    </button>

    <image-mode v-model="value.position.imageMode" />

    <div class="form-group layout-input opacity">
      <label for="image-opacity">Opacity %</label>
      <input
        class="form-control"
        type="number"
        id="image-opacity"
        placeholder="Name"
        v-model.number="value.position.opacity"
      />
    </div>

    <ContentBrowserModal
      ref="content_browser_modal"
      @submitted="[replaceImage($event), closeModal()]"
      :allow-multiple-item-select="false"
      :filterItemTypes="imageFilter"
    />
  </div>
</template>

<script>
  import widgetMixin from '@/components/layoutDesigner/editor/settingPanel/widgetMixin';
  import ImageMode from '@/components/layoutDesigner/editor/settingPanel/formControls/ImageMode';
  import ContentBrowserModal from '@/components/content/ContentBrowserModal.vue';
  import PixelIcon from '@/components/layoutDesigner/editor/settingPanel/PixelIcon.vue';
  import LockIcon from '@/components/layoutDesigner/editor/settingPanel/LockIcon.vue';

  import { WIDGET_CONTENT_TYPE, WIDGET_TYPES } from '@/models/layoutDesigner';
  import { LAYOUT_REPLACE_CHILD_ITEM } from '@/store/actions/layoutDesigner';

  export default {
    name: 'ImageWidgetPanel',

    mixins: [widgetMixin],

    components: {
      ImageMode,
      ContentBrowserModal,
      PixelIcon,
      LockIcon,
    },

    data() {
      return {
        imageFilter: [WIDGET_CONTENT_TYPE.IMAGE],
      };
    },

    methods: {
      openContentBrowser() {
        this.$refs.content_browser_modal.open();
      },

      async replaceImage(selectedItems) {
        if (selectedItems.length === 0) return;

        const item = selectedItems[0];

        await this.$store.dispatch(LAYOUT_REPLACE_CHILD_ITEM, {
          widget: {
            ...this.value,
            object: {
              ...item,
              item_id: item.item_id,
              type: this.value.object.type,
              item_url: item.item_url,
              wid: this.value.position.wid,
              itemType: WIDGET_TYPES.IMAGE,
            },
          },
          newItemId: item.item_id,
        });
      },

      closeModal() {
        this.$refs.content_browser_modal.close();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .image-widget-panel {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: 0px;
    padding-right: 10px;

    .content {
      background-color: #ecf0f1;
    }

    .button-2-secondary {
      margin-top: 20px;
      margin-bottom: 16px;
      width: 100%;
    }
  }
</style>
