<template>
  <nav class="navbar navbar-expand-md">
    <div class="navbar-name">
      {{ $route.meta && $route.meta.name ? $route.meta.name : $route.name }}
    </div>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarCollapse"
      aria-controls="navbarCollapse"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse justify-content-end" id="navbarCollapse">
      <ul class="nav d-flex align-items-center">
        <li class="nav-item">
          <div class="trial">
            <div class="trial-info float-left"></div>
            <div class="float-left">
              <a
                v-if="false"
                href="https://forms.gle/mYyacBaHDXxWyydV8"
                target="_blank"
                class="btn btn-secondary btn-transparent-blue upgrade-button"
                >Upgrade Plan</a
              >
            </div>
          </div>
        </li>
        <li class="nav-item">
          <div class="nav-user nav-user d-flex align-items-center dropdown">
            <div class="user-info float-left">
              <div class="name">{{ name || organisation }}</div>
              <div class="user-company">{{ username }}</div>
            </div>
            <div class="avatar-container" id="profile-drop-down">
              <img
                v-if="avatar_url"
                class="rounded-circle"
                :src="avatar_url + '?' + Date.now()"
                alt="Avatar"
              />
              <div v-else class="img-placeholder">
                <i class="material-icons align-middle">account_circle</i>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'NavBar',
    props: {
      page_name: String,
    },

    computed: {
      ...mapState({
        user: (state) => state.user,
        name: (state) => (state.user.user ? state.user.user.name : ''),
        username: (state) =>
          state.user.user ? state.user.user.email || state.user.user.username : '',
        avatar_url: (state) => (state.user.user ? state.user.user.avatar_url : null),
      }),

      isViewAsModeActive() {
        return this.$store.getters.isViewAsModeActive;
      },

      organisation() {
        return this.$store.state.auth.organisation_name;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .img-placeholder {
    i {
      color: #2f6eae;
      font-size: 50px;
    }
  }

  .navbar .nav .nav-user .avatar-container img {
    width: 52px;
    height: 52px;
    background-color: $fillGrey;
  }
</style>
