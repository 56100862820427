<template>
  <SimpleModal title="Delete Screen" ref="deleteScreenModal">
    <template #default>
      <BaseText variant="body1">
        Are you sure you want to delete <strong>{{ screenName }}</strong> ?</BaseText
      >
    </template>
    <template #footer>
      <BaseButton size="sm" theme="secondary" @click="close" class="mr-3">
        {{ $lang.common.cancel }}
      </BaseButton>
      <BaseButton size="sm" @click="deleteScreen" :loading="isLoading">
        {{ $lang.common.delete }}
      </BaseButton>
    </template>
  </SimpleModal>
</template>

<script>
  import { SCREEN_DELETE } from '@/store/actions/screens';
  import SimpleModal from '@/components/common/SimpleModal.vue';
  import { BaseText } from '../common/ui/atoms/baseText';

  export default {
    name: 'DeleteScreenModal',
    emits: ['screenDeleted'],

    components: { SimpleModal, BaseText },

    props: {
      screenName: {
        type: String,
        default: '',
      },
      screenId: {
        type: String | Number,
        default: '',
      },
    },

    data() {
      return {
        isLoading: false,
      };
    },

    methods: {
      async deleteScreen() {
        this.isLoading = true;

        try {
          await this.$store.dispatch(SCREEN_DELETE, this.screenId);

          this.$toasted.global.general_success({
            message: 'Screen deleted successfully',
          });

          this.close();
          this.$emit('screenDeleted');
        } catch (error) {
          this.$toasted.global.general_error({
            message: String(error),
          });
        }

        this.isLoading = false;
      },

      open() {
        this.$refs.deleteScreenModal.open();
      },

      close() {
        this.$refs.deleteScreenModal.close();
      },
    },
  };
</script>

<style lang="scss" scoped>
  ::v-deep .ui-modal-content {
    width: 500px;
  }

  .message {
    font-size: 16px;
    font-weight: 400;
    color: #000000;
  }
</style>
