<template>
  <div class="item-list row">
    <div class="col">
      <div class="item d-flex flex-row p-2 my-2 border-bottom rounded" :class="
      [index === value ? 'shadow' : 'shadow-sm']"
        v-for="(item, index) in items" :key="index"
        @click.prevent="onItemClicked(index)">

        <div class="img-container mr-2">
          <div class="hovereffect">
            <img :src="item.img" :alt="item.name" />

            <div class="overlay"></div>
          </div>
        </div>

        <div class="desc d-flex flex-column justify-content-around">
          <div class="title">
            {{ item.name }}
          </div>

          <div class="subtitle text-secondary">
            <em>{{ desc(item) }}</em>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ItemList',

  props: {
    items: {
      type: Array,
      required: true
    },

    value: {
      type: Number,
      required: false,
      default: -1
    },

    itemDesc: {
      required: false,
      type: String,
      default: 'App'
    }
  },

  methods: {
    onItemClicked (index) {
      this.$emit('input', index);
    },

    desc (item) {
      return item.desc || this.itemDesc;
    }
  }
}
</script>

<style lang="scss" scoped>
.item-list {

  .item {
    cursor: pointer;
    height: 56px;

    .title {
      font-size: 14px;
    }

    .subtitle {
      font-size: 12px;
    }

    .img-container {
      width: 40px;
      height: 40px;

      img {
        width: 40px;
        height: 40px;
        object-fit: cover;
      }

    }

  }

}
</style>
