<template>
  <div class="tabs">
    <div
      :class="['tab', { active: activeTab === 'vertical' }]"
      @click="() => $emit('update:activeTab', 'vertical')"
    >
      <span class="material-icons-outlined align-middle">stay_current_portrait</span>
      <span class="text">Vertical ({{ verticalLayoutsCount }})</span>
    </div>

    <div
      :class="['tab', { active: activeTab === 'horizontal' }]"
      @click="$emit('update:activeTab', 'horizontal')"
    >
      <span class="material-icons-outlined align-middle">stay_primary_landscape</span>
      <span class="text">Horizontal ({{ horizontalLayoutsCount }})</span>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'OrientationTabs',

    emits: ['update:activeTab'],

    props: {
      activeTab: {
        type: String,
        default: 'vertical',
      },
      horizontalLayoutsCount: {
        type: Number,
        default: 0,
      },
      verticalLayoutsCount: {
        type: Number,
        default: 0,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .tabs {
    display: flex;

    .tab {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
      flex: 1;
      border-bottom: 2px solid #f0f0f0;
      padding: 8px 5px;
      transition: border-color 200ms ease-in-out;
      color: #6a6b6a;
      cursor: pointer;

      &:hover {
        border-color: #d6d6d6;
        color: #555555;
      }
      &.active {
        border-color: #e3003a;
        color: #151515;
      }

      .text {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: inherit;
      }
    }
  }
</style>
