var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lights-container"},[(_vm.connected > 0 || _vm.showEmptyLights)?_c('div',{staticClass:"online-status",attrs:{"title":"Online"}},[_c('span',{staticClass:"light",style:({ height: `${_vm.size}px`, width: `${_vm.size}px` })},[_c('div',{staticClass:"interior-light",style:({
          height: `${_vm.size / 2}px`,
          width: `${_vm.size / 2}px`,
          left: `${_vm.size / 4}px`,
          top: `${_vm.size / 4}px`,
        })})]),(!_vm.hideNumbers)?_c('span',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm.connected)+" ")]):_vm._e(),(_vm.showText)?_c('span',{staticClass:"label green"},[_vm._v("On")]):_vm._e()]):_vm._e(),(_vm.unknown > 0 || _vm.showEmptyLights)?_c('div',{staticClass:"online-status",attrs:{"title":"Unknown"}},[_c('span',{staticClass:"light grey",style:({ height: `${_vm.size}px`, width: `${_vm.size}px` })},[_c('div',{staticClass:"interior-light",style:({
          height: `${_vm.size / 2}px`,
          width: `${_vm.size / 2}px`,
          left: `${_vm.size / 4}px`,
          top: `${_vm.size / 4}px`,
        })})]),(!_vm.hideNumbers)?_c('span',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm.unknown)+" ")]):_vm._e(),(_vm.showText)?_c('span',{staticClass:"label grey"},[_vm._v("Unknown")]):_vm._e()]):_vm._e(),(_vm.disconnected > 0 || _vm.showEmptyLights)?_c('div',{staticClass:"online-status",attrs:{"title":"Offline"}},[_c('span',{staticClass:"light red",style:({ height: `${_vm.size}px`, width: `${_vm.size}px` })},[_c('div',{staticClass:"interior-light",style:({
          height: `${_vm.size / 2}px`,
          width: `${_vm.size / 2}px`,
          left: `${_vm.size / 4}px`,
          top: `${_vm.size / 4}px`,
        })})]),(!_vm.hideNumbers)?_c('span',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm.disconnected)+" ")]):_vm._e(),(_vm.showText)?_c('span',{staticClass:"label red"},[_vm._v("Off")]):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }