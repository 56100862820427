module.exports = {
  welcome: 'Velkommen',
  to: 'til',
  activities_title : 'Aktiviteter',
  courts_title: 'Pulsen i hallen',
  book_with: 'Bestill med',
  members: 'Medlemmer',
  playing_now: 'Spiller akkurat nå',
  vacant_time: 'Ledig bana',
  vacant_times: 'Lediga banor',
  tracks: 'Spor',
  offers_for_you: 'Tilbud for deg',
  contact_us: 'Kontakt oss i resepsjonen for info',
  available_time: 'Tilgjengelig bane',

  monday: 'Mandag',
  tuesday: 'Tirsdag',
  wednesday: 'Onsdag',
  thursday: 'Torsdag',
  friday: 'Fredag',
  saturday: 'Lørdag',
  sunday: 'Søndag',

  game_strength: 'Game strength',
  players: 'Spiller',

  app_description: 'Spiller du tennis, badminton, bordtennis, squash eller padle? Bli medlem av Matchi og gjør hverdagen din mer fleksibel.',
};
