<template>
  <div class="timetable-container">
    <div class="preview-container border-bottom">
      <TimeTableViewer :viewerStyles="currentViewerStyles" :timetable="timetableData" />
    </div>

    <div class="settings pt-3">
      <h5 class="title">Customize</h5>
      <TrafiklabPanelSetting
        :viewer-styles="currentViewerStyles"
        @update:viewerStyles="
          (newViewerStyles) => {
            updateViewerStyles(newViewerStyles);
          }
        "
      />
    </div>
  </div>
</template>

<script>
  import TimeTableViewer from '@/components/common/timetable/TimeTableViewer.vue';
  import ColorPicker from '@/components/ColorPicker.vue';
  import TrafiklabPanelSetting from '@/components/apps/trafiklab/TrafiklabPanelSetting.vue';
  import { DEFAULT_TRAFIKLAB_VIEWER_STYLE } from '@/constant/trafiklabWidget';

  export default {
    name: 'CustomSettings',
    components: { TrafiklabPanelSetting, ColorPicker, TimeTableViewer },

    props: {
      viewerStyles: {
        type: Object,
        default: () => DEFAULT_TRAFIKLAB_VIEWER_STYLE,
      },
      timetableData: {
        type: Array,
        default: () => [],
      },
    },

    data() {
      return {
        currentViewerStyles: DEFAULT_TRAFIKLAB_VIEWER_STYLE,
      };
    },

    mounted() {
      this.currentViewerStyles = this.viewerStyles;
    },

    methods: {
      updateViewerStyles(newViewerStyles) {
        this.currentViewerStyles = newViewerStyles;
      },

      save() {
        this.$emit('onUpdate', this.currentViewerStyles);
      },
    },
  };
</script>

<style lang="scss" scoped>
  h5.title {
    font-size: 20px;
    color: $primaryDark;
    font-weight: 400;
  }
</style>
