<template>
  <div>
    <SidebarContainer>
      <template>
        <div class="setting-content-folder">
          <div class="folder-icon">
            <img src="@/assets/img/content/folder.svg" alt="folder-icon" />
          </div>

          <Input label="Name" id="name" :value="folder.name" style="margin-bottom: 24px">
            <span style="font-size: 14px">{{ folder.name }}</span>
          </Input>

          <SidebarDropdown
            title="Info"
            :default-open="true"
            :items="[{ name: 'Path', value: folder.full_path }]"
          />
        </div>
      </template>

      <template #buttons>
        <Button theme="secondary" icon="delete" @click.native="handleDelete">Delete Folder</Button>
      </template>
    </SidebarContainer>

    <FolderDeleteModal
      v-if="showDeleteModal"
      :folder="folder"
      @onDelete="$emit('onDelete')"
      @closeModal="showDeleteModal = false"
    />
  </div>
</template>

<script>
  import FolderDeleteModal from '@/components/content/FolderDeleteModal.vue';
  import Input from '@/components/common/Input.vue';
  import Label from '@/components/common/Label.vue';
  import SidebarContainer from '@/components/common/SidebarContainer.vue';
  import SidebarDropdown from '@/components/common/SidebarDropdown.vue';
  import Button from '@/components/common/Button.vue';

  export default {
    props: ['folder'],

    components: {
      Input,
      Label,
      SidebarDropdown,
      SidebarContainer,
      Button,
      FolderDeleteModal,
    },

    data() {
      return {
        showDeleteModal: false,
      };
    },

    methods: {
      handleDelete() {
        this.showDeleteModal = true;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .setting-content-folder {
    display: flex;
    flex-direction: column;

    .folder-icon {
      margin-bottom: 20px;
    }
  }
</style>
