<template>
  <div class="search-box">
    <input type="text" :placeholder="placeholder" :value="value" @input="handleInput" />
    <span class="icon material-icons-outlined">search</span>
  </div>
</template>

<script>
  export default {
    name: 'SearchBox',
    emits: ['input'],
    props: {
      value: {
        type: String,
        default: '',
      },
      placeholder: {
        type: String,
        default: 'Search',
      },
    },
    methods: {
      handleInput(e) {
        this.$emit('input', e.target.value);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .search-box {
    border: 2px solid $borderGrey;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 6px 16px;
    box-sizing: border-box;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:focus-within {
      border-color: #696969;
    }
    input {
      border: none;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      outline: none;
      flex: 1;
      padding: 0;
      margin: 0;
    }

    span.icon {
      color: #6a6b6a;
      font-size: 24px;
    }
  }
</style>
