<template>
  <span v-if="value" class="switch material-symbols-outlined on" @click="toggleAndEmit"
    >toggle_on</span
  >
  <span v-else class="switch material-icons-outlined" @click="toggleAndEmit">toggle_off</span>
</template>

<script>
  export default {
    emits: ['input'],
    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      toggleAndEmit() {
        this.$emit('input', !this.value);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .switch {
    user-select: none;
    &.material-icons-outlined {
      font-size: 24px;
      color: #6a6b6a;
    }

    &.material-symbols-outlined {
      font-size: 24px;
    }

    &.on {
      color: #e3003a;
    }

    cursor: pointer;
  }
</style>
