<template>
  <SidebarContainer>
    <div class="d-flex flex-column justify-content-center align-items-center h-100">
      <div class="icon">
        <span class="material-icons-outlined align-middle">perm_media</span>
      </div>
      <p class="font-whyte-ink title text-center mb-1">Upload & Manage Content</p>
      <p class="font-poppins description text-center mb-4">You can upload jpg, jpeg and png images no larger than 15mb, MP4 files no larger than
        {{ ALLOWED_MAX_FILE_SIZE_IN_MB }}mb</p>
      <Button class="w-100" theme="primary" icon="upload" @click="$emit('onUpload')">Upload Media</Button>
    </div>
  </SidebarContainer>
</template>

<script>
import SidebarContainer from "@/components/common/SidebarContainer.vue";
import { ALLOWED_MAX_FILE_SIZE_IN_MB } from "../../constant/const";
import Button from "@/components/common/Button.vue";

export default {
  name: "ContentItemSettingPlaceholder",
  components: { Button, SidebarContainer },

  data() {
    return {
      ALLOWED_MAX_FILE_SIZE_IN_MB
    }
  }
}
</script>

<style lang="scss" scoped>
.icon {
  margin-bottom: 20px;

  span {
    font-size: 64px;
    color: #e3003a;
  }
}
.title {
  font-size: 24px;
  color: #000;
  font-weight: 400;
  max-width: 266px;
}
.description {
  font-size: 16px;
  color: #6A6B6A;
  font-weight: 500;
  max-width: 322px;
}
</style>
