<template>
  <div class="main-container">
    <div class="content">
      <div class="content-body">
        <div class="app-lists px-0">
          <div class="header">
            <div class="header-top">
              <div class="title">Applications</div>
            </div>
            <div class="header-bottom">
              <SearchButton v-model="searchTerm" />
            </div>
          </div>
          <div class="body scrollbar" v-if="apps">
            <div v-if="apps" class="app-body">
              <CardGrid
                v-show="isShowingThumbnails"
                v-model.number="selectedAppIndex"
                v-on:input="onCardClicked"
                class="flex-grow-1"
                :items="filteredApps"
                :itemsPerRow="2"
                v-slot="{ item }"
                :class="'shadow-0'"
              >
                <AppCard :item="item" />
              </CardGrid>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="position-relative bg-white">
      <div class="app-sidebar px-2 py-4">
        <div class="sidebar-setting">
          <RssAppSetting v-if="appSetting === 'RSS'" />
          <TableAppSetting
            v-else-if="appSetting === 'Tenants'"
            ref="table_app_setting"
            @open-fill-table-content-modal="openFillContentModal"
          />
          <WeatherAppSetting v-else-if="appSetting === 'Weather'" />
          <TrafiklabSettings
            v-else-if="appSetting === 'Transport'"
            @onTrafiklabCreate="trafiklabModalOpened = true"
            @closeAppSidebar="appSetting = ''"
          />
          <AppSettingPlaceholder v-else />
        </div>
      </div>
    </div>
    <table-app-fill-modal
      ref="table_app_fill_modal"
      @save-settings="fillTableApp"
    ></table-app-fill-modal>
    <TrafiklabSettingsModal :open="trafiklabModalOpened" @onClose="trafiklabModalOpened = false" />
  </div>
</template>

<script>
  import _ from 'lodash';
  import AppSettingPlaceholder from './AppSettingPlaceholder.vue';
  import CardGrid from '../cardGrid/Index.vue';
  import AppCard from '../cardGrid/AppCard.vue';
  import ItemList from '../ItemList.vue';
  import ItemToolbar from '../ItemToolbar.vue';
  import RssAppSetting from './RssAppSetting.vue';
  import TrafiklabSettings from './trafiklab/TrafiklabSettings.vue';
  import TableAppSetting from './table/TableAppSetting.vue';
  import TableAppFillModal from './table/TableAppFillModal.vue';
  import WeatherAppSetting from './weather/WeatherAppSetting.vue';
  import TrafiklabImg from '../../assets/img/apps/trafiklab.jpg';
  import TableImg from '../../assets/img/apps/table.jpg';
  import RssImg from '../../assets/img/apps/rss.jpg';
  import WeatherImg from '../../assets/img/apps/weather.jpg';
  import PigelloImg from '../../assets/img/apps/pigello.jpeg';
  import VitecImg from '../../assets/img/apps/vitec.jpg';
  import HomepalImg from '../../assets/img/apps/homepal.jpg';
  import AvyTemplImg from '../../assets/img/apps/avytempl.jpg';
  import TrafiklabSettingsModal from '@/components/apps/trafiklab/TrafiklabSettingsModal.vue';
  import SearchButton from '@/components/common/SearchButton.vue';

  const $ = window.jQuery;

  export default {
    name: 'Apps',

    components: {
      TrafiklabSettingsModal,
      AppSettingPlaceholder,
      CardGrid,
      AppCard,
      ItemList,
      ItemToolbar,
      RssAppSetting,
      TrafiklabSettings,
      TableAppSetting,
      TableAppFillModal,
      WeatherAppSetting,
      SearchButton,
    },

    data() {
      return {
        selectedListType: 1,
        showSearchBox: false,
        searchTerm: '',
        apps: [
          {
            name: 'RSS',
            img: RssImg,
            desc: 'Subscribe to any RSS feed and publish it in our feed widgets.',
          },
          {
            name: 'Tenants',
            img: TableImg,
            desc: 'Use to create and edit tenants lists.',
          },
          {
            name: 'Transport',
            img: TrafiklabImg,
            desc: 'Display timetables for traffic stops.',
          },
          {
            name: 'Weather',
            img: WeatherImg,
            desc: 'Display weather forecasts for any location.',
          },
          {
            name: 'Pigello',
            img: PigelloImg,
            desc: 'Connect',
          },
          {
            name: 'Vitec',
            img: VitecImg,
            desc: 'Connect',
          },
          {
            name: 'Homepal',
            img: HomepalImg,
            desc: 'Connect',
          },
          {
            name: 'AvyTmpl',
            img: AvyTemplImg,
            desc: 'Connect',
          },
        ],

        selectedAppIndex: -1,
        appSetting: false,
        tableAppSettingSaved: false,
        tableAppSettingFromChild: false,
        tableAppSetting: {
          row: 1,
          col: 1,
          headers: [],
          content: [],
        },
        trafiklabModalOpened: false,
      };
    },

    computed: {
      filteredApps() {
        return this.apps.filter((app) =>
          app.name.toLowerCase().includes(this.searchTerm.toLowerCase()),
        );
      },
      isShowingThumbnails() {
        return this.selectedListType > 0;
      },
      thumbnailItemsPerRow() {
        return 5 - this.selectedListType; // i.e. 3 or 4 items per row
      },
    },

    mounted() {
      // clear setting  that hasn't been saved
      const that = this;
      $('#fillTableContentModal_').on('hidden.bs.modal', () => {
        if (that.tableAppSettingSaved) return;
        that.$set(that, 'tableAppSetting', that.tableAppSettingFromChild);
      });
    },

    methods: {
      fillTableApp(settings) {
        this.$refs.table_app_setting.fillTable(settings);
      },
      onSearch(term) {
        // filter list of app
        this.searchTerm = term.target.value;
      },

      onListViewChanged(type) {
        this.selectedListType = type;
      },

      onCardClicked(index) {
        this.appSetting = this.filteredApps[index].name;
      },
      openFillContentModal(settings) {
        /* let settingClone1 = _.cloneDeep(setting);
                this.$set(this, 'tableAppSetting', settingClone1);
                let settingClone2 = _.cloneDeep(setting);
                this.$set(this, 'tableAppSettingFromChild', settingClone2);
                this.tableAppSettingSaved = false; */
        this.$refs.table_app_fill_modal.open(settings);
      },
      saveTableAppSetting() {
        this.tableAppSettingSaved = true;
        const settingClone = _.cloneDeep(this.tableAppSetting);
        this.$set(this, 'tableAppSetting', settingClone);
        $('#fillTableContentModal_').modal('hide');
      },
      showSearchArea() {
        this.showSearchBox = true;
        this.$nextTick(() => this.$refs.searchBox.focus());
      },
    },
  };
</script>

<style lang="scss" scoped>
  .content {
    height: 100vh;
  }

  .content-body {
    display: flex;
    height: 100%;
    padding: 48px 32px 0px 40px;

    .app-lists {
      width: 100%;
      padding: 0 16px 40px;
      display: flex;
      flex-direction: column;

      .header {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding-bottom: 4px;
        font-size: 20px;
        line-height: 32px;
        font-weight: 500;
        font-family: 'Whyte Inktrap';

        i {
          color: $secondaryText;
        }

        .header-top {
          display: flex;
          justify-content: space-between;
        }

        .header-bottom {
          display: flex;
          justify-content: flex-start;
          gap: 8px;
        }

        .active {
          color: $primaryRed;
        }

        .filters {
          display: flex;
          flex-wrap: wrap;
          gap: 12px;

          label {
            min-width: 170px;
            margin-bottom: none;
            flex-grow: 1;
            width: calc(50% - 12px);
            position: relative;

            span {
              display: block;
              left: 10px;
              position: absolute;
              font-size: 16px;
              color: $fillGrey;
              font-weight: 500;
              pointer-events: none;
            }
          }

          select {
            width: 100%;
            background: none;
            border: 2px solid $fillGrey;
            cursor: pointer;
            border-radius: 8px;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: $secondaryText;
            padding: 6px 8px;

            option {
              zoom: 1.1;
            }
          }
        }
      }
    }

    .body {
      display: flex;
      flex-direction: column;
      grid-template-columns: 1fr 1fr;
      gap: 16px;
      padding-top: 16px;
      margin-right: -32px;
      padding-right: 32px;
      overflow-y: auto;
    }
  }

  .search-box {
    display: flex;
    position: relative;
    height: 30px;

    input {
      display: none;
      height: 30px;
      width: 100%;
      border: none;
      outline: none;
      background: none;

      font-size: 14px;
      border-bottom: 2px solid $fillGrey;

      &:hover,
      &:focus {
        outline: none;
        box-shadow: none;
      }

      &.darkText {
        color: $primaryText;
        font-weight: 500;
      }
    }

    &.searching {
      width: 100%;

      input {
        display: block;
      }

      i {
        position: absolute;
        right: 0;
        color: #333332;
      }
    }
  }
  .app-sidebar {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    overflow-y: auto;
  }
</style>
