<template>
  <Modal
    @closeModal="closeModal"
    :title="'Item Display Priority'"
    :okFunction="submitPriority"
    okButtonLabel="Save"
    :modalStyles="{ width: '600px' }"
  >
    <template v-slot:body>
      <div class="priority-container">
        <div class="form-group">
          <label for="priority_type">How do you want to prioritize this object?</label>
          <select
            name="priority_type"
            id="priority_type"
            class="form-control"
            v-model="displayPriority"
          >
            <option :value="availablePriorities.STANDARD">Standard</option>
            <option :value="availablePriorities.PRIORITY">Priority</option>
          </select>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
  import Modal from '@/components/common/Modal.vue';
  import { DISPLAY_PRIORITY } from '@/constant/const';

  export default {
    name: 'PlaylistItemDisplayPriority',
    components: {
      Modal,
    },

    props: {
      playlistItem: {
        type: Object,
        default: () => null,
      },
    },

    data() {
      return {
        displayPriority: DISPLAY_PRIORITY.STANDARD,
        availablePriorities: DISPLAY_PRIORITY,
      };
    },

    mounted() {
      this.displayPriority = this.playlistItem.display_priority;
    },

    methods: {
      closeModal() {
        this.$emit('closeModal');
      },

      submitPriority() {
        this.$emit('setPriority', {
          assocId: this.playlistItem.assoc_id,
          overrideId: this.playlistItem.override?.id,
          priority: this.displayPriority,
          wid: this.playlistItem.wid,
        });

        this.closeModal();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .priority-container {
    padding: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }
</style>
