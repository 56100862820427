import { WIDGET_TYPES } from '@/models/layoutDesigner';
import moment from 'moment';
import { CONTENT_TYPE_FOLDER } from '@/constant/const';
import config from '@/config';

// https://stackoverflow.com/questions/11832914/round-to-at-most-2-decimal-places-only-if-necessary#answer-41716722
export function round(num, decimals = 1) {
  const scale = 10 ** decimals;
  return Math.round((num + Number.EPSILON) * scale) / scale;
}

export const getRBGAColor = (hexColor = '#FFFFFF', opacity = 1) => {
  var _hex = hexColor.replace('#', '');

  // handle backward compatibility for older widgets that uses hex3
  _hex = _hex.length === 3 ? _hex.replace(/./g, '$&$&') : _hex;

  const red = parseInt(_hex.substring(0, 2), 16);
  const green = parseInt(_hex.substring(2, 4), 16);
  const blue = parseInt(_hex.substring(4, 6), 16);

  return 'rgba(' + red + ', ' + green + ', ' + blue + ', ' + opacity + ')';
};

export function hex8ToRgba(hex8) {
  const _isHex8 = isHex8(hex8);

  const _hex8 = hex8.replace('#', '');

  const red = parseInt(_hex8.substring(0, 2), 16);
  const green = parseInt(_hex8.substring(2, 4), 16);
  const blue = parseInt(_hex8.substring(4, 6), 16);
  const alpha = _isHex8 ? parseInt(_hex8.substring(6, 8), 16) / 255 : 1;

  return 'rgba(' + red + ', ' + green + ', ' + blue + ', ' + alpha + ')';
}

export function isHex8(hex) {
  return hex[0] === '#' && hex.length === 9;
}

export function getAlphaFromHex8(hex8) {
  var alphaNormalized = 1;

  if (!isHex8(hex8)) {
    return alphaNormalized;
  }

  const alphaHex = hex8.substring(6, 8);
  alphaNormalized = parseInt(alphaHex, 16) / 255;

  return alphaNormalized;
}

export function getAlphaToHex(alpha) {
  var _alpha = Math.max(0, Math.min(1, alpha));
  var alphaHex = Math.round(_alpha * 255)
    .toString(16)
    .toUpperCase();

  if (alphaHex < 2) {
    alphaHex = `0${alphaHex}`;
  }

  return alphaHex;
}

export const transformSecondsToTimer = (seconds) => {
  const date = new Date(null);
  date.setSeconds(seconds);

  const hhmmssFormat = date.toISOString().substring(11, 19);

  return hhmmssFormat;
};

export const roundNumbers = (value) => Math.round(value * 100) / 100;

export const getPositionData = (widget) => {
  const { width = 100, height = 100, x = 0, y = 0, zIndex = 0 } = widget;

  return {
    zIndex,
    width,
    height,
    x,
    y,
  };
};

export const arrayToObjectWithIdsAsKeys = (itemsArray, key = 'id') => {
  return itemsArray.reduce((object, item) => ({ ...object, [item[key]]: item }), {});
};

export const getWidgetId = (widget) => widget.assoc_id || widget.object.wid;

export const getWidgetItemType = (widget) => {
  let itemType;

  if (widget.object.playlist_id) {
    itemType = WIDGET_TYPES.PLAYLIST;
  } else if (widget.type === WIDGET_TYPES.TEMPLATE) {
    itemType = WIDGET_TYPES.TEMPLATE;
  } else if (widget.object.config) {
    itemType = widget.object.config.type;
  } else if (widget.object) {
    if (widget.object.item_type && widget.object.item_type.includes('pdf')) {
      itemType = WIDGET_TYPES.PDF;
    } else if (widget.object.item_type && widget.object.item_type.includes('app')) {
      itemType = WIDGET_TYPES.APP;
    } else if (widget.object.item_type && widget.object.item_type.includes('video')) {
      itemType = WIDGET_TYPES.VIDEO;
    } else {
      itemType = widget.object.type || WIDGET_TYPES.IMAGE;
    }
  } else {
    itemType = null;
  }

  return itemType;
};

export const formattedDate = (date, format = 'DD/MM/YYYY HH:mm') => {
  return moment(date).format(format);
};

export const getFoldersFromNestedArray = (contents, filteredFolders = []) => {
  contents.forEach((item) => {
    if (item.content_type === CONTENT_TYPE_FOLDER) {
      filteredFolders.push(item);
      getFoldersFromNestedArray(item.contents, filteredFolders);
    }
  });
  return filteredFolders;
};

export const getCategoriesFromNestedArray = (categories, allCategories = []) => {
  categories?.forEach((item) => {
    if (item.category_id) {
      allCategories.push(item);
      getCategoriesFromNestedArray(item.children, allCategories);
    }
  });
  return allCategories;
};

export const getRSSAppSettings = (appSettings) => {
  const displayDefaultOptions = {
    rssName: true,
    content: true,
    image: true,
    imageWidth: 25,
    imageMode: 'cover',
    feedNameSize: 16,
    titleFontSize: 30,
    contentSize: 20,
    contentLength: 200,
    spacing: 16,
    padding: 16,
  };

  return {
    rssLink: appSettings.link,
    rssDuration: parseInt(appSettings.time || appSettings.display?.duration) || 10,
    rssTheme: appSettings.theme,
    display: appSettings.display || displayDefaultOptions,
  };
};

export const getCloudfrontUrl = (key) => {
  if (!key) {
    return key;
  }
  let cleanedKey = key;
  if (key.indexOf(config.s3BaseUrl) != -1) {
    const regex = new RegExp(`${config.s3BaseUrl}`, 'i');
    cleanedKey = key.replace(regex, '');
  } else if (key.indexOf(config.alternateS3BaseUrl) != -1) {
    const regex = new RegExp(`${config.alternateS3BaseUrl}`, 'i');
    cleanedKey = key.replace(regex, '');
  }
  return `${config.baseUrl}/${cleanedKey}`;
};

export const isSingleDigit = (value) => {
  return /^[0-9]$/.test(value);
};
