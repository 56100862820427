<template>
  <Modal
    id="contentAddModal"
    title="Upload new media"
    icon="upload"
    ref="modal"
    :saving="uploading"
  >
    <div class="mt-3 mb-4" v-if="createDirectory">
      <label for="folderName" style="font-size: 18px">Folder name</label>
      <input
        type="text"
        class="form-control"
        placeholder="Folder name"
        id="folderName"
        v-model="folderName"
      />
    </div>
    <div
      :class="['drop-area', { filesSelected: files.length > 0 }]"
      @click="handleDropAreaClick"
      @drop.prevent="handleFileDrop"
      @dragover.prevent
    >
      <div class="icon">
        <i class="material-icons-outlined">upload_file</i>
      </div>
      <div class="text"><span class="underline">Click to upload</span> or drag and drop</div>
      <div class="max-size">Maximum file size *{{ ALLOWED_MAX_FILE_SIZE_IN_MB }} MB.</div>
      <input
        type="file"
        id="file-input"
        :accept="ALLOWED_PLAYLIST_FILE_EXTENSIONS.toString()"
        @change.prevent="handleFilesChange"
        multiple
      />
    </div>

    <div class="progress-section" v-if="files.length > 0">
      <div v-for="{ id, file } in files" class="upload-item" :key="id">
        <div class="icon">
          <i class="material-icons-outlined" v-if="getSimpleType(file.type) === 'image'">
            crop_original
          </i>
          <i class="material-icons-outlined" v-if="getSimpleType(file.type) === 'video'">
            play_circle_outline
          </i>
          <i class="material-icons-outlined" v-if="getSimpleType(file.type) === 'pdf'">
            picture_as_pdf
          </i>
        </div>
        <div class="details">
          <div class="header">
            <div class="name">{{ file.name }}</div>
            <i class="material-icons-outlined" @click="removeFile(id)">close</i>
          </div>
          <div class="item-progress">
            <!-- Completed -->
            <!-- <div class="item-status">
              {{ humanFileSize(file.size) }} - <span class="text-success">Done</span>
            </div> -->

            <!-- Not Started -->
            <!-- <div class="item-status">
              {{ humanFileSize(file.size) }} - <span class="text-warning">Not started</span>
            </div> -->

            <!-- Uploading -->
            <!-- <div class="item-status">10MB / {{ humanFileSize(file.size) }}</div> -->

            <!-- Error -->
            <!-- <div class="item-status">
              <span class="text-danger">Upload Failed</span>
            </div> -->

            <!-- <div class="item-bar">
              <div class="progress">
                <div
                  class="progress-bar bg-success"
                  role="progressbar"
                  style="width: 25%"
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <div class="percentage">10%</div>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <template #footer v-if="files.length > 0 || (createDirectory && folderName)">
      <Button theme="secondary" size="sm" class="mr-3" @click.native="close" :disabled="uploading">
        Close
      </Button>
      <Button size="sm" @click.native="uploadFiles" :loading="uploading">Save</Button>
    </template>
  </Modal>
</template>

<script>
  import Modal from './Modal.vue';
  import { simpleTypeMixin } from '@/helpers';
  import { v4 } from 'uuid';
  import { humanFileSize } from '@/helpers/filters';
  import Button from '../common/Button.vue';
  import { FILE_UPLOAD_REQUEST, CONTENT_UPLOAD_PDF_REQUEST } from '@/store/actions/content';
  import { pdfToFiles } from '@/helpers/pdf';
  import { ALLOWED_MAX_FILE_SIZE_IN_MB, ALLOWED_PLAYLIST_FILE_EXTENSIONS } from '@/constant/const';
  import { apiCreateFolder } from '@/api/content';

  export default {
    props: {
      folder: {
        type: Object,
        default: () => null,
      },
    },

    mixins: [simpleTypeMixin],

    emits: ['new-content-added'],

    data() {
      return {
        files: [],
        filesProgress: {},
        uploading: false,
        createDirectory: false,
        folderName: '',
        ALLOWED_PLAYLIST_FILE_EXTENSIONS,
        ALLOWED_MAX_FILE_SIZE_IN_MB,
      };
    },

    components: {
      Modal,
      Button,
    },

    methods: {
      humanFileSize,
      open() {
        this.createDirectory = false;
        this.$refs.modal.open();
        this.files = [];
      },

      close(force = false) {
        this.$refs.modal.close(force);
      },

      handleClose(force = false) {
        this.folderName = '';
        this.uploading = false;
        this.close(force);
      },

      handleDropAreaClick() {
        document.getElementById('file-input').click();
      },

      handleFileDrop(e) {
        const droppedFiles = e.dataTransfer.files;
        if (!droppedFiles) return;
        const that = this;
        [...droppedFiles].forEach((file) => {
          if (ALLOWED_PLAYLIST_FILE_EXTENSIONS.includes(file.type)) {
            that.addFile(file);
          } else {
            that.$toasted.error('The file ' + file.name + ' can not be uploaded');
          }
        });
      },

      handleFilesChange() {
        const files = document.getElementById('file-input').files;
        if (!files) return;
        const that = this;
        [...files].forEach((file) => {
          if (!ALLOWED_PLAYLIST_FILE_EXTENSIONS.includes(file.type)) {
            that.$toasted.error('The file ' + file.name + ' can not be uploaded');
            return;
          }
          if (file.size > ALLOWED_MAX_FILE_SIZE_IN_MB * 1024 * 1024) {
            this.$toasted.global.general_error({ message: `The file ${file.name} is too large` });
            return;
          }
          that.addFile(file);
        });
      },

      addFile(file) {
        // Check if file is already added by name and type
        const fileAlreadyAdded = this.files.find(
          ({ file: f }) => f.name === file.name && f.type === file.type,
        );

        if (fileAlreadyAdded) return;

        // Add file to files array
        this.files.push({
          id: v4(),
          file,
        });
      },

      removeFile(fileId) {
        this.files = this.files.filter(({ id }) => id !== fileId);
      },

      async createFolder() {
        try {
          const response = await apiCreateFolder({
            name: this.folderName,
            parent_folder: this.folder?.id,
          });

          this.$toasted.global.general_success({ message: 'Folder successfully created' });

          return response.data;
        } catch (error) {
          this.$toasted.global.general_error({
            message: error.response?.data.message || 'Failed to create the folder',
          });

          return null;
        }
      },

      async uploadFiles() {
        let newFolder = null;

        this.uploading = true;

        if (this.createDirectory) {
          if (!this.folderName) {
            this.$toasted.global.general_error({ message: 'Folder name is required' });
            this.uploading = false;

            return;
          }

          const folderPattern = /^[a-zA-Z].*/;

          if (!folderPattern.test(this.folderName)) {
            this.$toasted.global.general_error({ message: 'Invalid folder name' });
            this.uploading = false;

            return;
          }

          newFolder = await this.createFolder();

          if (!newFolder) {
            this.handleClose(true);
            return;
          }
        }

        if (!this.files.length) {
          this.$emit('new-content-added');
          this.handleClose(true);
          return;
        }

        let self = this;
        self.uploading = true;
        let promises = [];

        this.files.forEach(({ id, file }) => {
          // const saveName = path ? `${path}/${file.name}` : file.name;
          // Check if file type is pdf
          if (file.type === 'application/pdf') {
            const convertAndUpload = async () => {
              const thumbs = await pdfToFiles(file);

              const pdfFile = await this.$store.dispatch(CONTENT_UPLOAD_PDF_REQUEST, {
                file,
                images: thumbs,
                folder: newFolder ? newFolder.id : self.folder?.id,
              });

              return {
                ...pdfFile.pdf_content,
                item_type: 'app/pdf',
                item_id: pdfFile.pdf_content.playlistitem,
              };
            };

            promises.push(convertAndUpload());
          } else {
            promises.push(
              this.$store.dispatch(FILE_UPLOAD_REQUEST, {
                name: file.name,
                file,
                folder: newFolder ? newFolder.id : self.folder?.id,
              }),
            );
          }
        });

        try {
          const responses = await Promise.all(promises);

          const filesData = responses;
          this.$emit('new-content-added', filesData);
          this.$toasted.global.general_success({ message: 'File successfully uploaded' });
          this.close(true);
        } catch (error) {
          const errorMsg = error.response?.data?.message || 'Unable to upload one or more files';
          this.$toasted.global.general_error({
            message: errorMsg,
          });
        }

        this.handleClose();
      },

      handleDirectoryUpload() {
        this.createDirectory = true;
        this.$refs.modal.open();
        this.files = [];
      },
    },
  };
</script>

<style lang="scss" scoped>
  .drop-area {
    width: 100%;
    min-height: 194px;
    background-color: #f3f3f3;
    border: 2px dashed #e3003a;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #ccc;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      background-color: #e1e1e1;
    }

    #file-input {
      display: none;
    }

    &.filesSelected {
      margin-bottom: 24px;
    }

    .icon {
      text-align: center;
      margin-bottom: 16px;
      i {
        font-size: 58px;
        color: #e3003a;
      }
    }

    .text {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #151515;
      margin-bottom: 8px;

      .underline {
        text-decoration: underline;
      }
    }

    .max-size {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #6a6b6a;
    }
  }

  .progress-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .upload-item {
      background: #ffffff;
      border: 2px solid rgba(0, 0, 0, 0.04);
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.06);
      padding: 16px;
      display: flex;
      gap: 8px;

      &.has-error {
        border: 2px solid rgba(242, 24, 24, 0.25);
        background: rgba(242, 24, 24, 0.02);
      }

      .icon {
        i {
          font-size: 32px;
          color: #6a6b6a;
        }
      }

      .details {
        display: flex;
        flex-direction: column;

        flex: 1;
        .header {
          display: flex;
          justify-content: space-between;

          .name {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #151515;
          }

          i {
            font-size: 20px;
            color: #323232;
            cursor: pointer;
            transition: all 0.3s ease;
            &:hover {
              color: #e3003a;
            }
          }
        }

        .item-progress {
          .item-status {
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #6a6b6a;
            margin-bottom: 6px;
            &.text-success {
              color: #24b247;
            }
            &.text-danger {
              color: #f21818;
            }
          }

          .item-bar {
            display: flex;
            gap: 12px;
            align-items: center;

            .progress {
              flex: 1;
              height: 8px;
              background: #f3f3f3;

              .progress-bar {
                background: #e3003a;
                border-radius: 4px;

                &.bg-success {
                  background: #24b247;
                }
              }
            }
            .percentage {
              min-width: 30px;
              font-weight: 500;
              font-size: 12px;
              color: #151515;
            }
          }
        }
      }
    }
  }
</style>
